import storage from '../utils/storage/storage'
import showToast from '../utils/toast'
import createActionType from '../utils/action'

import api from '../utils/api'
import jwt_decode from 'jwt-decode'
import { store } from '../store/configStore'
import _ from 'lodash'
import enLocale from '../utils/localization/locale-en'
import { updateStudyInStore } from './study'
import { updateSubjectInStore } from './subject'
import subject from '../reducers/subject'
import { Platform } from 'react-native'
import constants from '../constants/constants'
import { updateAppType, updateOnMobileStatus } from './storeAppStatus'
import Constants from 'expo-constants'
import RenderHTML from 'react-native-render-html'
import openapi from '../utils/openapi'
import NavigationService from '../containers/navigationService'

export const CLINRO_LOGIN_REQUEST = createActionType('CLINRO_LOGIN_REQUEST')
export const CLINRO_LOGIN_SUCCESS = createActionType('CLINRO_LOGIN_SUCCESS')
export const CLINRO_LOGIN_FAILURE = createActionType('CLINRO_LOGIN_FAILURE')
export const CLINRO_LOGOUT_REQUEST = createActionType('CLINRO_LOGOUT_REQUEST')
export const CLINRO_LOGOUT_SUCCESS = createActionType('CLINRO_LOGOUT_SUCCESS')
export const CLINRO_LOGOUT_FAILURE = createActionType('CLINRO_LOGOUT_FAILURE')
export const UPDATE_LOGGEDIN_USER = createActionType('UPDATE_LOGGEDIN_USER')

export const UPDATE_USERS = createActionType('UPDATE_USERS')

export const UPDATE_SELECTED_FORMTYPE = createActionType('UPDATE_SELECTED_FORMTYPE')
export const SESSION_TIMEOUT = createActionType('SESSION_TIMEOUT')

const loginRequest = () => ({
  type: CLINRO_LOGIN_REQUEST,
})

const loginSuccess = (data) => ({
  type: CLINRO_LOGIN_SUCCESS,
  userId: data.id,
})

const loginFailure = () => ({
  type: CLINRO_LOGIN_FAILURE,
})

export const updateUsers = (users) => ({
  type: UPDATE_USERS,
  users,
})

export const resetCurrentUser = () => {
  // storage.setItem('currentUser', {})
  return {
    type: CLINRO_LOGOUT_SUCCESS,
  }
}

export const sessionTimeout = (timeout) => ({
  type: SESSION_TIMEOUT,
  timeout,
})

export const storePin = (pin) => async (dispatch, getState) => {
  let {
    users: { currentUser },
    users,
  } = getState()
  users[currentUser.id] = {
    ...users[currentUser.id],
    pin,
  }
  dispatch(updateUsers(users))
}

export const logout = () => async (dispatch, getState) => {
  dispatch({ type: CLINRO_LOGOUT_REQUEST })
  try {
    // logout api
    let {
      users: { currentUser },
      users,
    } = getState()
    delete users[currentUser.id]
    dispatch(updateUsers(users))
    dispatch(resetCurrentUser())
  } catch (error) {
    dispatch({ type: CLINRO_LOGOUT_FAILURE })
  }
}

export const validate = (userName, Enteredpin, t) => (dispatch, getState) => {
  const user = storage.getUserByUsername(userName, getState().users)
  if (user) {
    if (user.pin === Enteredpin) {
      dispatch(loginSuccess({ id: user.id }))
      if (user.sessionInfo && user.sessionInfo.studyId) {
        dispatch(updateStudyInStore(user.sessionInfo.studyId))
      }
      if (user.sessionInfo && user.sessionInfo.subjectId) {
        dispatch(updateSubjectInStore(user.sessionInfo.subjectId))
      }
      return user.sessionInfo
    } else {
      showToast(t('InvUsrNmPIN'), 'danger', 3000)
    }
  } else {
    showToast(t('InvUsrNmPIN'), 'danger', 3000)
    throw new Error(`User name & PIN must be valid `)
  }
}

export const updateSelectedFormType = (selectedFormType) => ({
  type: UPDATE_SELECTED_FORMTYPE,
  selectedFormType,
})
const setUserDetails = (user) => {
  const userDetails = {
    id: user.sub,
    email: user.email,
    lastName: user['family_name'],
    firstName: user['given_name'],
    firstName: user['name'],
    username: user['preferred_username'],
  }
  return userDetails
}
const errorMessage = (t) => {
  Constants.expoConfig.extra.nativeApp
    ? showToast(t('InvalidUsernamePassword'), 'danger', 3000)
    : showToast(<RenderHTML source={{ html: t('InvalidUsernamePasswordWeb')}} />, 'danger', 3000)
}

export const login = (username, password, t) => async (dispatch, getState) => {
  dispatch(loginRequest())
  try {
    let { site, users } = getState()
    const data = {
      username,
      password,
      grantType: 'password',
    }
    const tokenResponse = await openapi.post(`/realms/${site.keycloakRealm}/users/login`, data)
    await dispatch(updateAppType(constants.AppType.SITESTAFF))
    const userResponse = await openapi.get(`/realms/${site.keycloakRealm}/users`, {
      headers: {
        Authorization: tokenResponse.data['access_token'],
        Realmname: site.keycloakRealm,
        PrimaryOrganizationCode: site.primaryOrganizationCode,
      },
    })
    const userData = await openapi.get(
      `/${site.primaryOrganizationCode}/organizations/${site.organizationCode}/users/${data.username}/userDetails`,
      {
        headers: {
          Authorization: tokenResponse.data['access_token'],
          Realmname: site.keycloakRealm,
          PrimaryOrganizationCode: site.primaryOrganizationCode,
        },
      }
    )
    const user = setUserDetails(userResponse.data)
    const userDetails = {
      ...user,
      tokenDetails: {
        ...tokenResponse.data,
      },
      userData,
    }
    if (!_.isEmpty(users[userDetails.id])) {
      delete users[userDetails.id]
    }
    users = {
      ...users,
      [userDetails.id]: {
        ...userDetails,
      },
    }
    dispatch(updateUsers(users))
    dispatch(loginSuccess({ id: user.id }))
  } catch (error) {
    console.log(error)
    if (error.response) {
      switch (error.response.status) {
        case 404:
          errorMessage(t)
          break
        case 400:
          errorMessage(t)
          break
        case 401:
          errorMessage(t)
          break
        case 403:
          showToast(t(Constants.expoConfig.extra.nativeApp ?  'InvalidUsernamePassword' : 'NoClinroAccessPrivilege'), 'danger', 3000)
          break
        case 500:
          if (error.response.data && Number(error.response.data)) {
            error.response.message = getMessage(Number(error.response.data))
            break
          }
          showToast(t('SomethingWentWrong'), 'danger', 3000)
          break
        default:
          showToast(t('NetworkError'), 'danger', 3000)
      }
    } else {
      showToast(t('NetworkError'), 'danger', 3000)
    }
    dispatch(loginFailure())
    throw error
  }
}

export const validateToken = async () => {
  try {
    const currentUser = store.getState().users ? store.getState().users.currentUser : {}
    const user = store.getState().users[currentUser.id]
    if (_.isEmpty(user)) {
      return {}
    }
    var decodedAccessToken = jwt_decode(user.tokenDetails['access_token'])
    var decodedRefreshToken = jwt_decode(user.tokenDetails['refresh_token'])
    if (
      Date.now() >= decodedAccessToken.exp * 1000 &&
      Date.now() >= decodedRefreshToken.exp * 1000
    ) {
      store.dispatch(logout(true))
      navigateToSessionTimeout()
    } else if (Date.now() >= decodedAccessToken.exp * 1000) {
      await getUpdatedTokenDetails()
      return getAccessToken()
    }
    return getAccessToken()
  } catch (error) {
    console.log(error)
  }
}

const navigateToSessionTimeout = () => {
  const onMobile = store.getState()?.appStatus?.onMobile
  if (Platform.OS === 'web') {
    storage.clear()
  }
  store.dispatch(sessionTimeout(true))
  store.dispatch(updateOnMobileStatus(onMobile))
  NavigationService.navigate('SessionTimeout')
}

const getUpdatedTokenDetails = async (t) => {
  try {
    let users = store.getState().users
    const currentUser = users ? store.getState().users.currentUser : {}
    const user = users[currentUser.id]
    const site = store.getState().site
    const data = {
      refreshToken: user.tokenDetails['refresh_token'],
      grantType: 'refresh_token',
    }
    const res = await openapi.post(`/realms/${site.keycloakRealm}/users/login`, data)
    user.tokenDetails = {
      ...res.data,
    }
    users[currentUser.id] = user
    store.dispatch(updateUsers(users))
  } catch (error) {
    console.log(error)
    if (error.response) {
      if (error.response.status === 400) {
        showToast(enLocale.SessionTimeoutMessage, 'danger', 3000)
      }
    } else {
      showToast(enLocale.SomethingWentWrong, 'danger', 3000)
    }
  }
}

export const getAccessToken = () => {
  const currentUser = store.getState().users ? store.getState().users.currentUser : {}
  const site = store.getState().site
  const user = store.getState().users[currentUser.id]
  if (!user) {
    return {}
  }
  return {
    accessToken: user.tokenDetails['access_token'],
    keycloakRealm: site.keycloakRealm,
    primaryOrganizationCode: site.primaryOrganizationCode,
  }
}

export const updateSession = (sessionInfo) => (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  let { users } = getState()
  users = {
    ...users,
    [id]: {
      ...users[id],
      sessionInfo,
    },
  }
  dispatch(updateUsers(users))
}

export const updatedLoggedinUser = (subjectData) => (dispatch) => {
  const user = {
    id: subjectData.id,
    firstName: subjectData.firstName,
    lastName: subjectData.lastName,
    age: subjectData.age,
    gender: subjectData.gender,
    dob: subjectData.dob,
    phoneNo: subjectData.phoneNo,
    email: subjectData.email,
    userId: subjectData.userId,
    role: subjectData.role,
    language: subjectData.subject?.language,
    resetPassword: subjectData.resetPassword,
    subjectId: subjectData.id,
    studySite: subjectData.studySite,
    primaryOrganizationCode: subjectData?.primaryOrganizationCode,
    studyId: subjectData?.studyId,
    siteId: subjectData?.siteId,
  }
  dispatch({
    type: UPDATE_LOGGEDIN_USER,
    user,
    privacyPolicy: { ...subjectData?.privacyPolicy },
  })
}

export const getDeviceStatus = () => {
  if (Platform.OS === 'web') {
    return navigator.onLine
  }
  return store.getState().appStatus.isDeviceOnline
}

export const handleLogout = (data) => async (dispatch) => {
  try {
    await api.post(
      `${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${data?.subject?.id}/logout`,
      data
    )
  } catch (error) {
    console.log(error)
  }
}

const getMessage = (messageKey) => {
  return _.filter(constants.errorMessageType.filter((ele) => ele.key == messageKey))[0].message
}
