import { Container, Content } from 'native-base'
import React, { Component } from 'react'
import { BackHandler, Platform, StatusBar, Text, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import styles from '../components/styles/homeStyles'
import CallJoinRequest from '../containers/CallJoinRequest'
import { backgroundColor } from '../containers/NavigationScreens'
import stylesss from '../styles'
import { Authorize } from '../utils/appPrivileges'
import WebAlert from '../utils/WebAlert'
import Compliance from './ProgressBar'
import Training from './training'

class Home extends Component {
  state = {}

  render() {
    const {
      subjectCompliance,
      isAlertModalVisible,
      hideModal,
      retrieveSubjectCompliance,
      screenProps: { t },
      isJoinCallModalVisible,
      closeCallJoinRequestModal,
      call,
      openCallLink,
      timeZone,
      isTrainingCompleted,
      screenProps,
      navigation,
    } = this.props
    const buttons = [ 
      { 'title': 'NO', 'action': () => hideModal() }, 
      { 'title': 'YES', 'action': () => BackHandler.exitApp() } 
  ]
    return (
      <Container style={stylesss.container}>
        {isAlertModalVisible && Platform.OS == 'web' && (
          <WebAlert
            headerText={''}
            hideModal={hideModal()}
            t={screenProps.t}
            action={() => {
              BackHandler.exitApp()
            }}
            message={'Exit ezpro?'}
            buttons={buttons}
          />
        )}
        <StatusBar
          backgroundColor={backgroundColor}
          barStyle={Platform.OS !== 'ios' ? 'light-content' : 'dark-content'}
        />
        <NavigationEvents
          onWillFocus={() => {
            retrieveSubjectCompliance()
          }}
        />
        <Content contentContainerStyle={styles.container}>
          <View style={styles.headingContainer}>
            <Text style={{ fontSize: 22, fontFamily: 'Inter', color: '#607d8b' }}>
              {t('HomeGM')}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                color: '#455a64',
                paddingHorizontal: 5,
              }}
            >
              {t('HomeTxt')}
            </Text>
          </View>
          {!isTrainingCompleted ? (
            <Training screenProps={screenProps} navigation={navigation} />
          ) : (
            <>
              <Training screenProps={screenProps} navigation={navigation} />
              <Authorize privilege="VIEW_COMPLIANCE">
                <React.Fragment>
                  <View style={styles.complianceHeading}>
                    <Text style={{ fontSize: 16, fontFamily: 'Inter', color: '#263238' }}>
                      {t('HomeCompli')}
                    </Text>
                  </View>
                  <View style={styles.complianceContainer}>
                    <View style={styles.complianceRow}>
                      <View styles={styles.compliance}>
                        <Compliance
                          color="#DF62D6"
                          bgColor="#653A85"
                          value={subjectCompliance.dayCompliance}
                          label={t('HomeToday')}
                        />
                      </View>
                      <View styles={styles.compliance}>
                        <Compliance
                          color="#1FC1FF"
                          bgColor="#2A396E"
                          value={subjectCompliance.weekCompliance}
                          label={t('HomeCrntWeek')}
                        />
                      </View>
                    </View>
                    <View style={styles.complianceRow}>
                      <View styles={styles.compliance}>
                        <Compliance
                          color="#9AE853"
                          bgColor="#3F3B50"
                          value={subjectCompliance.monthCompliance}
                          label={t('HomeCrntMon')}
                        />
                      </View>
                      <View styles={styles.compliance}>
                        <Compliance
                          color="#CC4A4D"
                          bgColor="#542C4F"
                          value={subjectCompliance.totalCompliance}
                          label={t('HomeTotal')}
                        />
                      </View>
                    </View>
                  </View>
                </React.Fragment>
              </Authorize>
            </>
          )}
          <CallJoinRequest
            visible={isJoinCallModalVisible}
            closePopup={closeCallJoinRequestModal}
            call={call}
            openCallLink={openCallLink}
            timeZone={timeZone}
          />
        </Content>
      </Container>
    )
  }
}

export default Home
