import React from "react"
import TrackMarkComponent from "./TrackMarkComponent"
import { View } from "react-native"
import TrackMarkNumbers from "./TrackMarkNumbers"
const VerticalTrackMarkContainer = (props) => {

  
    return (
        <View style = {{ flexDirection: 'row'}}>
            {/* ----------marks------------ */}
            <TrackMarkComponent {...props} />

            {/* ----------indication numbers------------ */}
            <View style = {{ justifyContent: 'flex-end', marginTop: 50}}>
            <TrackMarkNumbers {...props} />
            </View>
        </View>
    )
}

export default VerticalTrackMarkContainer