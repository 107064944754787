import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import _ from 'lodash';
import { alignments } from '../alignments';
import { getScaleProperties} from '../utils';
import PropTypes from 'prop-types'
import { AutoSizeText, ResizeTextMode } from 'react-native-auto-size-text';
import { useSelector } from 'react-redux';

const ScalableScale = (props) => {
  const { start, step, end, value, disabled, id, isPercentage, onNumberChange, orientation, fontSize} = props
  const { onMobile } = useSelector(state => state?.appStatus);
  const scaleProps = getScaleProperties(start, end, step, orientation, onMobile)
  const {widthOfBox, scale, margin} = scaleProps

  return (
    <View style={[styles.container, {flexDirection : orientation === alignments.HORIZONTAL ? 'row': 'column-reverse'}]}>
      {
        _.map(_.range(start, end + 1, step), (number, index) => {
          const textColor = (number === value) ? '#ffffff' : 'black'
          return (
            <TouchableOpacity key={index} 
            style={[styles.box, { 
              width: widthOfBox, 
              height: widthOfBox,
              transform: [{ scale}],
              backgroundColor: number === value ? '#9155FD': '#fff',
              borderColor: number === value ? '#9155FD' : '#9e9e9e',
              marginHorizontal: orientation === alignments.HORIZONTAL ? margin : 0,
              marginBottom: orientation === alignments.VERTICAL ? margin : 0,
              alignItems: 'center',
              justifyContent: 'center'
              }]}
              onPress={() => (disabled ? null : onNumberChange(number))}
              testID={`numeric-rating-scale-sliderElements-${id}-${number}`}
              accessible={!disabled}
              >
              <AutoSizeText
                fontSize={Platform.OS === 'web' ? Math.ceil(fontSize)-1 : Math.ceil(fontSize)}
                numberOfLines={1}
                minFontSize={2}
                mode={ResizeTextMode.min_font_size}
                style={[styles.text, styles.textMargin, { color: textColor, alignSelf: 'center' }]}
                testID={`numeric-rating-scale-sliderElements-number-${id}-${number}-text`}
                accessible
              >
                {number}{isPercentage ? '%' : null}
              </AutoSizeText>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  box: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  text: {
    fontWeight: '500', // Medium font weight
    fontSize: 5,
  },
  textMargin: {
    margin:0,
    padding:0
  },
  textView: {
    alignItems: 'center', 
    justifyContent: 'center'
  }
});

ScalableScale.defaultProps = {
  isPercentage: false,
  start: 0,
  end: 10,
  step: 1,
  value: null,
  onNumberChange: () => null,
  disabled: false,
  id: null,   
  orientation: alignments.HORIZONTAL,
  fontSize: 10
}

ScalableScale.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onNumberChange: PropTypes.func,
  isPercentage: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  orientation: PropTypes.string,
  fontSize: PropTypes.number
}

export default ScalableScale;
