import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Login from '../../Login'
import { retrieveLogin } from '../../../actions/login'
import { setCurrentScreen } from '../../../actions/storeAppStatus'
import { isDataExistsToSync } from '../../../selectors/syncQueue'
class LoginScreen extends Component {
  state = {
    deviceToken: '',
  }

  componentDidMount() {
    const { setCurrentScreen } = this.props
    // Commented out because it is not using now
    // if (Platform.OS != 'web') {
    //   cancelNotifications()
    //   setupPushNotification(this.setDevicePushToken)
    // }

    setCurrentScreen('LOGIN')
  }

  // setDevicePushToken = (deviceToken) => {
  //   this.setState({
  //     deviceToken,
  //   })
  // }

  render() {
    const {
      currentScreen,
      navigation,
      retrieveLogin: reqLogin,
      selectedLanguage,
      loading,
      screenProps,
      setCurrentScreen,
      deviceLocation,
      phoneNoWithCntryCode,
      isOfflineDataExists
    } = this.props
    const { deviceToken } = this.state
    console.log(screenProps)

    return (
      <Login
        setCurrentScreen={setCurrentScreen}
        currentScreen={currentScreen}
        navigation={navigation}
        retrieveLogin={reqLogin}
        selectedLanguage={selectedLanguage}
        deviceToken={deviceToken}
        loading={loading}
        screenProps={screenProps}
        deviceLocation={deviceLocation}
        isOfflineDataExists={isOfflineDataExists}
        phoneNoWithCntryCode={phoneNoWithCntryCode}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.changeLanguage.selectedLanguage,
  loading: state.loading,
  currentScreen: state.appStatus.currentScreen,
  deviceLocation: state.deviceLocation,
  phoneNoWithCntryCode: state.syncQueue.data?.sessionUser?.phoneNo,
  isOfflineDataExists: isDataExistsToSync(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveLogin,
      setCurrentScreen,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
