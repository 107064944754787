import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { backgroundColor } from '../../../../containers/NavigationScreens'
import Header from '../../header'
import applicationConstants from '../../../../constants/constants'
import Banner from '../../../Banner'
import ProfileHeader from '../../Profile/index'

const visitsHeader = (props) => {
  const { appType, navigation, screenProps, showBanner=true } = props

  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        height: 120,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: 5,
          backgroundColor:'white'
        }}
      >
        <Header
          navigation={navigation}
          canShowBackIcon={applicationConstants.AppType.SITESTAFF === appType ? true : false}
          title="Visits"
          appType={appType}
          onPress={
            applicationConstants.AppType.SITESTAFF === appType
              ? () => navigation.navigate('SubjectListPage')
              : () => navigation.goBack()
          }
        />
        <ProfileHeader navigation={navigation} screenProps={screenProps} />
      </View>
      {showBanner && (
        <View style={{ marginBottom: 10, marginTop: 10, zIndex: -1 }}>
          <Banner />
        </View>
      )}
    </View>
  )
}

const mapStateToProps = (state) => ({
  appType: state.appStatus.appType,
})

export default connect(mapStateToProps, null)(visitsHeader)
