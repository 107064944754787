import React, { Component } from 'react'
import StudyDetails from '../components/StudyDetails'
import { getUserData } from '../selectors/user'
import { connect } from 'react-redux'

class StudyDetailsPage extends Component {
  state = {}

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    title: t('StudyDetails'),
  })

  render() {
    const { subject, studyInfo, versionInfo, screenProps } = this.props
    return (
      <StudyDetails
        screenProps={screenProps}
        subject={subject}
        studyInfo={studyInfo}
        versionInfo={versionInfo}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  subject: state.subjectStudyMetaData.subject,
  studyInfo: state.subjectStudyMetaData.study,
  versionInfo: state.subjectStudyMetaData.subject.crfVersion,
})

export default connect(mapStateToProps)(StudyDetailsPage)
