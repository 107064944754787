export default {
    "Africa/Abidjan": "Африка/Абиджан",
"Africa/Accra": "Африка/Аккра",
"Africa/Addis_Ababa": "Африка/Аддис-Абеба",
"Africa/Algiers": "Африка/Алжир",
"Africa/Asmara": "Африка/Асмэра",
"Africa/Asmera": "Африка/Асмера",
"Africa/Bamako": "Африка/Бамако",
"Africa/Bangui": "Африка/Банги",
"Africa/Banjul": "Африка/Банжул",
"Africa/Bissau": "Африка/Бисау",
"Africa/Blantyre": "Африка/Блантайр",
"Africa/Brazzaville": "Африка/Браззавиль",
"Africa/Bujumbura": "Африка/Бужумбура",
"Africa/Cairo": "Африка/Каир",
"Africa/Casablanca": "Африка/Касабланка",
"Africa/Ceuta": "Африка/Сеута",
"Africa/Conakry": "Африка/Конакри",
"Africa/Dakar": "Африка/Дакар",
"Africa/Dar_es_Salaam": "Африка/Дар-эс-Салам",
"Africa/Djibouti": "Африка/Джибути",
"Africa/Douala": "Африка/Дуала",
"Africa/El_Aaiun": "Африка/Эль-Аюн",
"Africa/Freetown": "Африка/Фритаун",
"Africa/Gaborone": "Африка/Габороне",
"Africa/Harare": "Африка/Хараре",
"Africa/Johannesburg": "Африка/Йоханнесбург",
"Africa/Juba": "Африка/Джуба",
"Africa/Kampala": "Африка/Кампала",
"Africa/Khartoum": "Африка/Хартум",
"Africa/Kigali": "Африка/Кигали",
"Africa/Kinshasa": "Африка/Киншаса",
"Africa/Lagos": "Африка/Лагос",
"Africa/Libreville": "Африка/Либревиль",
"Africa/Lome": "Африка/Ломе",
"Africa/Luanda": "Африка/Луанда",
"Africa/Lubumbashi": "Африка/Лубумбаши",
"Africa/Lusaka": "Африка/Лусака",
"Africa/Malabo": "Африка/Малабо",
"Africa/Maputo": "Африка/Мапуту",
"Africa/Maseru": "Африка/Масеру",
"Africa/Mbabane": "Африка/Мбабане",
"Africa/Mogadishu": "Африка/Могадишо",
"Africa/Monrovia": "Африка/Монровия",
"Africa/Nairobi": "Африка/Найроби",
"Africa/Ndjamena": "Африка/Нджамена",
"Africa/Niamey": "Африка/Ниамей",
"Africa/Nouakchott": "Африка/Нуакшот",
"Africa/Ouagadougou": "Африка/Уагадугу",
"Africa/Porto-Novo": "Африка/Порто-Ново",
"Africa/Sao_Tome": "Африка/Сан-Томе",
"Africa/Timbuktu": "Африка/Тимбукту",
"Africa/Tripoli": "Африка/Триполи",
"Africa/Tunis": "Африка/Тунис",
"Africa/Windhoek": "Африка/Виндхук",
"America/Adak": "Америка/Адак",
"America/Anchorage": "Америка/Анкоридж",
"America/Anguilla": "Америка/Ангилья",
"America/Antigua": "Америка/Антигуа",
"America/Araguaina": "Америка/Арагуаина",
"America/Argentina/Buenos_Aires": "Америка/Аргентина/Буэнос-Айрес",
"America/Argentina/Catamarca": "Америка/Аргентина/Катамарка",
"America/Argentina/ComodRivadavia": "Америка/Аргентина/Комод Ривадавия",
"America/Argentina/Cordoba": "Америка/Аргентина/Кордова",
"America/Argentina/Jujuy": "Америка/Аргентина/Жужуй",
"America/Argentina/La_Rioja": "Америка/Аргентина/Ла_Риоха",
"America/Argentina/Mendoza": "Америка/Аргентина/Мендоса",
"America/Argentina/Rio_Gallegos": "Америка/Аргентина/Рио-Гальегос",
"America/Argentina/Salta": "Америка/Аргентина/Сальта",
"America/Argentina/San_Juan": "Америка/Аргентина/Сан-Хуан",
"America/Argentina/San_Luis": "Америка/Аргентина/Сан-Луис",
"America/Argentina/Tucuman": "Америка/Аргентина/Тукуман",
"America/Argentina/Ushuaia": "Америка/Аргентина/Ушуайя",
"America/Aruba": "Америка/Аруба",
"America/Asuncion": "Америка/Асунсьон",
"America/Atikokan": "Америка/Атикокан",
"America/Atka": "Америка/Атка",
"America/Bahia": "Америка/Баия",
"America/Bahia_Banderas": "Америка/Баия_Бандерас",
"America/Barbados": "Америка/Барбадос",
"America/Belem": "Америка/Белем",
"America/Belize": "Америка/Белиз",
"America/Blanc-Sablon": "Америка/Блан-Саблон",
"America/Boa_Vista": "Америка/Боа_Виста",
"America/Bogota": "Америка/Богота",
"America/Boise": "Америка/Бойсе",
"America/Buenos_Aires": "Америка/Буэнос-Айрес",
"America/Cambridge_Bay": "Америка/Кембриджский залив",
"America/Campo_Grande": "Америка/Кампо-Гранде",
"America/Cancun": "Америка/Канкун",
"America/Caracas": "Америка/Каракас",
"America/Catamarca": "Америка/Катамарка",
"America/Cayenne": "Америка/Кайенна",
"America/Cayman": "Америка/Кайман",
"America/Chicago": "Америка/Чикаго",
"America/Chihuahua": "Америка/Чихуахуа",
"America/Ciudad_Juarez": "Америка/Сьюдад-Хуарес",
"America/Coral_Harbour": "Америка/Коралловая гавань",
"America/Cordoba": "Америка/Кордова",
"America/Costa_Rica": "Америка/Коста-Рика",
"America/Creston": "Америка/Крестон",
"America/Cuiaba": "Америка/Куяба",
"America/Curacao": "Америка/Кюрасао",
"America/Danmarkshavn": "Америка/Данмарксхавн",
"America/Dawson": "Америка/Доусон",
"America/Dawson_Creek": "Америка/Доусон-Крик",
"America/Denver": "Америка/Денвер",
"America/Detroit": "Америка/Детройт",
"America/Dominica": "Америка/Доминика",
"America/Edmonton": "Америка/Эдмонтон",
"America/Eirunepe": "Америка/Эйрунепе",
"America/El_Salvador": "Америка/Сальвадор",
"America/Ensenada": "Америка/Энсенада",
"America/Fort_Nelson": "Америка/Форт-Нельсон",
"America/Fort_Wayne": "Америка/Форт-Уэйн",
"America/Fortaleza": "Америка/Форталеза",
"America/Glace_Bay": "Америка/Глейс-Бэй",
"America/Godthab": "Америка/Готхаб",
"America/Goose_Bay": "Америка/Гусиный залив",
"America/Grand_Turk": "Америка/Гранд-Тёрк",
"America/Grenada": "Америка/Гренада",
"America/Guadeloupe": "Америка/Гваделупа",
"America/Guatemala": "Америка/Гватемала",
"America/Guayaquil": "Америка/Гуаякиль",
"America/Guyana": "Америка/Гайана",
"America/Halifax": "Америка/Галифакс",
"America/Havana": "Америка/Гавана",
"America/Hermosillo": "Америка/Эрмосильо",
"America/Indiana/Indianapolis": "Америка/Индиана/Индианаполис",
"America/Indiana/Knox": "Америка/Индиана/Нокс",
"America/Indiana/Marengo": "Америка/Индиана/Маренго",
"America/Indiana/Petersburg": "Америка/Индиана/Петербург",
"America/Indiana/Tell_City": "Америка/Индиана/Телл-Сити",
"America/Indiana/Vevay": "Америка/Индиана/Вевей",
"America/Indiana/Vincennes": "Америка/Индиана/Винсеннес",
"America/Indiana/Winamac": "Америка/Индиана/Уинамак",
"America/Indianapolis": "Америка/Индианаполис",
"America/Inuvik": "Америка/Инувик",
"America/Iqaluit": "Америка/Икалуит",
"America/Jamaica": "Америка/Ямайка",
"America/Jujuy": "Америка/Жужуй",
"America/Juneau": "Америка/Джуно",
"America/Kentucky/Louisville": "Америка/Кентукки/Луисвилл",
"America/Kentucky/Monticello": "Америка/Кентукки/Монтичелло",
"America/Knox_IN": "Америка/Нокс_ин",
"America/Kralendijk": "Америка/Кралендейк",
"America/La_Paz": "Америка/Ла-Пас",
"America/Lima": "Америка/Лима",
"America/Los_Angeles": "Америка/Лос-Анджелес",
"America/Louisville": "Америка/Луисвилл",
"America/Lower_Princes": "Америка/Нижние принцы",
"America/Maceio": "Америка/Масейо",
"America/Managua": "Америка/Манагуа",
"America/Manaus": "Америка/Манаус",
"America/Marigot": "Америка/Мариго",
"America/Martinique": "Америка/Мартиника",
"America/Matamoros": "Америка/Матаморос",
"America/Mazatlan": "Америка/Мазатлан",
"America/Mendoza": "Америка/Мендоза",
"America/Menominee": "Америка/Меномини",
"America/Merida": "Америка/Мерида",
"America/Metlakatla": "Америка/Метлакатла",
"America/Mexico_City": "Америка/Мехико-Сити",
"America/Miquelon": "Америка/Микелон",
"America/Moncton": "Америка/Монктон",
"America/Monterrey": "Америка/Монтеррей",
"America/Montevideo": "Америка/Монтевидео",
"America/Montreal": "Америка/Монреаль",
"America/Montserrat": "Америка/Монтсеррат",
"America/Nassau": "Америка/Нассау",
"America/New_York": "Америка/Нью-Йорк",
"America/Nipigon": "Америка/Нипигон",
"America/Nome": "Америка/Дом",
"America/Noronha": "Америка/Норонья",
"America/North_Dakota/Beulah": "Америка/Северная Дакота/Бьюла",
"America/North_Dakota/Center": "Америка/Северная Дакота/Центр",
"America/North_Dakota/New_Salem": "Америка/Северная Дакота/Нью-Салем",
"America/Nuuk": "Америка/Нуук",
"America/Ojinaga": "Америка/Охинага",
"America/Panama": "Америка/Панама",
"America/Pangnirtung": "Америка/Пангниртунг",
"America/Paramaribo": "Америка/Парамарибо",
"America/Phoenix": "Америка/Феникс",
"America/Port-au-Prince": "Америка/Порт-о-Пренс",
"America/Port_of_Spain": "Америка/Порт-оф-Спейн",
"America/Porto_Acre": "Америка/Порту-Акри",
"America/Porto_Velho": "Америка/Порту-Велью",
"America/Puerto_Rico": "Америка/Пуэрто-Рико",
"America/Punta_Arenas": "Америка/Пунта_Аренас",
"America/Rainy_River": "Америка/Дождливая река",
"America/Rankin_Inlet": "Америка/Рейнкин_Инлет",
"America/Recife": "Америка/Ресифи",
"America/Regina": "Америка/Реджина",
"America/Resolute": "Америка/Решительность",
"America/Rio_Branco": "Америка/Рио-Бранко",
"America/Rosario": "Америка/Росарио",
"America/Santa_Isabel": "Америка/Санта-Изабель",
"America/Santarem": "Америка/Сантарем",
"America/Santiago": "Америка/Сантьяго",
"America/Santo_Domingo": "Америка/Санто-Доминго",
"America/Sao_Paulo": "Америка/Сан-Паулу",
"America/Scoresbysund": "Америка/Scoresbysund",
"America/Shiprock": "Америка/Шипрок",
"America/Sitka": "Америка/Ситка",
"America/St_Barthelemy": "Америка/Сен-Бартельми",
"America/St_Johns": "Америка/Сент-Джонс",
"America/St_Kitts": "Америка/Сент-Китс",
"America/St_Lucia": "Америка/Сент-Люсия",
"America/St_Thomas": "Америка/Сент-Томас",
"America/St_Vincent": "Америка/Сент-Винсент",
"America/Swift_Current": "Америка/Swift_Current",
"America/Tegucigalpa": "Америка/Тегусигальпа",
"America/Thule": "Америка/Туле",
"America/Thunder_Bay": "Америка/Тандер_Бэй",
"America/Tijuana": "Америка/Тихуана",
"America/Toronto": "Америка/Торонто",
"America/Tortola": "Америка/Тортола",
"America/Vancouver": "Америка/Ванкувер",
"America/Virgin": "Америка/Девственница",
"America/Whitehorse": "Америка/Уайтхорс",
"America/Winnipeg": "Америка/Виннипег",
"America/Yakutat": "Америка/Якутат",
"America/Yellowknife": "Америка/Йеллоунайф",
"Antarctica/Casey": "Антарктика/Кейси",
"Antarctica/Davis": "Антарктика/Дэвис",
"Antarctica/DumontDUrville": "Антарктика/Дюмон-Дюрвиль",
"Antarctica/Macquarie": "Антарктика/Маккуори",
"Antarctica/Mawson": "Антарктика/Моусон",
"Antarctica/McMurdo": "Антарктика/МакМердо",
"Antarctica/Palmer": "Антарктика/Палмер",
"Antarctica/Rothera": "Антарктика/Ротера",
"Antarctica/South_Pole": "Антарктика/Южный полюс",
"Antarctica/Syowa": "Антарктида/Сиова",
"Antarctica/Troll": "Антарктика/Тролль",
"Antarctica/Vostok": "Антарктика/Восток",
"Arctic/Longyearbyen": "Арктика/Лонгйир",
"Asia/Aden": "Азия/Аден",
"Asia/Almaty": "Азия/Алматы",
"Asia/Amman": "Азия/Амман",
"Asia/Anadyr": "Азия/Анадырь",
"Asia/Aqtau": "Азия/Актау",
"Asia/Aqtobe": "Азия/Актобе",
"Asia/Ashgabat": "Азия/Ашхабад",
"Asia/Ashkhabad": "Азия/Ашхабад",
"Asia/Atyrau": "Азия/Атырау",
"Asia/Baghdad": "Азия/Багдад",
"Asia/Bahrain": "Азия/Бахрейн",
"Asia/Baku": "Азия/Баку",
"Asia/Bangkok": "Азия/Бангкок",
"Asia/Barnaul": "Азия/Барнаул",
"Asia/Beirut": "Азия/Бейрут",
"Asia/Bishkek": "Азия/Бишкек",
"Asia/Brunei": "Азия/Бруней",
"Asia/Calcutta": "Азия/Калькутта",
"Asia/Chita": "Азия/Чита",
"Asia/Choibalsan": "Азия/Чойбалсан",
"Asia/Chongqing": "Азия/Чунцин",
"Asia/Chungking": "Азия/Чунцин",
"Asia/Colombo": "Азия/Коломбо",
"Asia/Dacca": "Азия/Дакка",
"Asia/Damascus": "Азия/Дамаск",
"Asia/Dhaka": "Азия/Дакка",
"Asia/Dili": "Азия/Дили",
"Asia/Dubai": "Азия/Дубай",
"Asia/Dushanbe": "Азия/Душанбе",
"Asia/Famagusta": "Азия/Фамагуста",
"Asia/Gaza": "Азия/Газа",
"Asia/Harbin": "Азия/Харбин",
"Asia/Hebron": "Азия/Хеврон",
"Asia/Ho_Chi_Minh": "Азия/Хошимин",
"Asia/Hong_Kong": "Азия/Гонконг",
"Asia/Hovd": "Азия/Ховд",
"Asia/Irkutsk": "Азия/Иркутск",
"Asia/Istanbul": "Азия/Стамбул",
"Asia/Jakarta": "Азия/Джакарта",
"Asia/Jayapura": "Азия/Джаяпура",
"Asia/Jerusalem": "Азия/Иерусалим",
"Asia/Kabul": "Азия/Кабул",
"Asia/Kamchatka": "Азия/Камчатка",
"Asia/Karachi": "Азия/Карачи",
"Asia/Kashgar": "Азия/Кашгар",
"Asia/Kathmandu": "Азия/Катманду",
"Asia/Katmandu": "Азия/Катманду",
"Asia/Khandyga": "Азия/Хандыга",
"Asia/Kolkata": "Азия/Калькутта",
"Asia/Krasnoyarsk": "Азия/Красноярск",
"Asia/Kuala_Lumpur": "Азия/Куала-Лумпур",
"Asia/Kuching": "Азия/Кучинг",
"Asia/Kuwait": "Азия/Кувейт",
"Asia/Macao": "Азия/Макао",
"Asia/Macau": "Азия/Макао",
"Asia/Magadan": "Азия/Магадан",
"Asia/Makassar": "Азия/Макассар",
"Asia/Manila": "Азия/Манила",
"Asia/Muscat": "Азия/Маскат",
"Asia/Nicosia": "Азия/Никосия",
"Asia/Novokuznetsk": "Азия/Новокузнецк",
"Asia/Novosibirsk": "Азия/Новосибирск",
"Asia/Omsk": "Азия/Омск",
"Asia/Oral": "Азия/Орал",
"Asia/Phnom_Penh": "Азия/Пномпень",
"Asia/Pontianak": "Азия/Понтианак",
"Asia/Pyongyang": "Азия/Пхеньян",
"Asia/Qatar": "Азия/Катар",
"Asia/Qostanay": "Азия/Кустанай",
"Asia/Qyzylorda": "Азия/Кызылорда",
"Asia/Rangoon": "Азия/Рангун",
"Asia/Riyadh": "Азия/Эр-Рияд",
"Asia/Saigon": "Азия/Сайгон",
"Asia/Sakhalin": "Азия/Сахалин",
"Asia/Samarkand": "Азия/Самарканд",
"Asia/Seoul": "Азия/Сеул",
"Asia/Shanghai": "Азия/Шанхай",
"Asia/Singapore": "Азия/Сингапур",
"Asia/Srednekolymsk": "Азия/Среднеколымск",
"Asia/Taipei": "Азия/Тайбэй",
"Asia/Tashkent": "Азия/Ташкент",
"Asia/Tbilisi": "Азия/Тбилиси",
"Asia/Tehran": "Азия/Тегеран",
"Asia/Tel_Aviv": "Азия/Тель-Авив",
"Asia/Thimbu": "Азия/Тхимбу",
"Asia/Thimphu": "Азия/Тхимпху",
"Asia/Tokyo": "Азия/Токио",
"Asia/Tomsk": "Азия/Томск",
"Asia/Ujung_Pandang": "Азия/Юнг-Панданг",
"Asia/Ulaanbaatar": "Азия/Улан-Батор",
"Asia/Ulan_Bator": "Азия/Улан-Батор",
"Asia/Urumqi": "Азия/Урумчи",
"Asia/Ust-Nera": "Азия/Усть-Нера",
"Asia/Vientiane": "Азия/Вьентьян",
"Asia/Vladivostok": "Азия/Владивосток",
"Asia/Yakutsk": "Азия/Якутск",
"Asia/Yangon": "Азия/Янгон",
"Asia/Yekaterinburg": "Азия/Екатеринбург",
"Asia/Yerevan": "Азия/Ереван",
"Atlantic/Azores": "Атлантика/Азорские острова",
"Atlantic/Bermuda": "Атлантика/Бермудские острова",
"Atlantic/Canary": "Атлантика/Канары",
"Atlantic/Cape_Verde": "Атлантика/Кабо-Верде",
"Atlantic/Faeroe": "Атлантика/Фарерские острова",
"Atlantic/Faroe": "Атлантика/Фарерские острова",
"Atlantic/Jan_Mayen": "Атлантика/Ян-Майен",
"Atlantic/Madeira": "Атлантика/Мадейра",
"Atlantic/Reykjavik": "Атлантика/Рейкьявик",
"Atlantic/South_Georgia": "Атлантика/Южная Джорджия",
"Atlantic/St_Helena": "Атлантика/Остров Святой Елены",
"Atlantic/Stanley": "Атлантика/Стэнли",
"Australia/ACT": "Австралия/ACT",
"Australia/Adelaide": "Австралия/Аделаида",
"Australia/Brisbane": "Австралия/Брисбен",
"Australia/Broken_Hill": "Австралия/Брокен_Хилл",
"Australia/Canberra": "Австралия/Канберра",
"Australia/Currie": "Австралия/Карри",
"Australia/Darwin": "Австралия/Дарвин",
"Australia/Eucla": "Австралия/Юкла",
"Australia/Hobart": "Австралия/Хобарт",
"Australia/LHI": "Австралия/LHI",
"Australia/Lindeman": "Австралия/Линдеман",
"Australia/Lord_Howe": "Австралия/Лорд_Хау",
"Australia/Melbourne": "Австралия/Мельбурн",
"Australia/NSW": "Австралия/Новый Южный Уэльс",
"Australia/North": "Австралия/Север",
"Australia/Perth": "Австралия/Перт",
"Australia/Queensland": "Австралия/Квинсленд",
"Australia/South": "Австралия/Юг",
"Australia/Sydney": "Австралия/Сидней",
"Australia/Tasmania": "Австралия/Тасмания",
"Australia/Victoria": "Австралия/Виктория",
"Australia/West": "Австралия/Запад",
"Australia/Yancowinna": "Австралия/Янковинна",
"Brazil/Acre": "Бразилия/Акко",
"Brazil/DeNoronha": "Бразилия/Деноронья",
"Brazil/East": "Бразилия/Восток",
"Brazil/West": "Бразилия/Запад",
"CET": "ЦЕНТ",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Канада/Атлантика",
"Canada/Central": "Канада/Центральная",
"Canada/Eastern": "Канада/Восток",
"Canada/Mountain": "Канада/Горы",
"Canada/Newfoundland": "Канада/Ньюфаундленд",
"Canada/Pacific": "Канада/Тихий океан",
"Canada/Saskatchewan": "Канада/Саскачеван",
"Canada/Yukon": "Канада/Юкон",
"Chile/Continental": "Чили/Континенталь",
"Chile/EasterIsland": "Чили/Остров Пасхи",
"Cuba": "Куба",
"EET": "НОГИ",
"EST": "ЛУЧШИЙ",
"EST5EDT": "EST5EDT",
"Egypt": "Египет",
"Eire": "Проволока",
"Etc/GMT": "ETC/GMT",
"Etc/GMT+0": "ETC/GMT+0",
"Etc/GMT+1": "ETC/GMT+1",
"Etc/GMT+10": "ETC/GMT+10",
"Etc/GMT+11": "ETC/GMT+11",
"Etc/GMT+12": "ETC/GMT+12",
"Etc/GMT+2": "ETC/GMT+2",
"Etc/GMT+3": "ETC/GMT+3",
"Etc/GMT+4": "ETC/GMT+4",
"Etc/GMT+5": "ETC/GMT+5",
"Etc/GMT+6": "ETC/GMT+6",
"Etc/GMT+7": "ETC/GMT+7",
"Etc/GMT+8": "ETC/GMT+8",
"Etc/GMT+9": "ETC/GMT+9",
"Etc/GMT-0": "ETC/GMT-0",
"Etc/GMT-1": "ETC/GMT-1",
"Etc/GMT-10": "ЕТК/ГМТ-10",
"Etc/GMT-11": "ЕТК/ГМТ-11",
"Etc/GMT-12": "ЕТК/ГМТ-12",
"Etc/GMT-13": "ЕТК/ГМТ-13",
"Etc/GMT-14": "ЕТК/ГМТ-14",
"Etc/GMT-2": "ЕТК/ГМТ-2",
"Etc/GMT-3": "ЕТК/ГМТ-3",
"Etc/GMT-4": "ЕТК/ГМТ-4",
"Etc/GMT-5": "ЕТК/ГМТ-5",
"Etc/GMT-6": "ЕТК/ГМТ-6",
"Etc/GMT-7": "ЕТК/ГМТ-7",
"Etc/GMT-8": "ЕТК/ГМТ-8",
"Etc/GMT-9": "ЕТК/ГМТ-9",
"Etc/GMT0": "ETC/GMT0",
"Etc/Greenwich": "Etc/Гринвич",
"Etc/UCT": "ETC/UCT",
"Etc/UTC": "ETC/UTC",
"Etc/Universal": "Etc/Универсальный",
"Etc/Zulu": "Etc/Зулу",
"Europe/Amsterdam": "Европа/Амстердам",
"Europe/Andorra": "Европа/Андорра",
"Europe/Astrakhan": "Европа/Астрахань",
"Europe/Athens": "Европа/Афины",
"Europe/Belfast": "Европа/Белфаст",
"Europe/Belgrade": "Европа/Белград",
"Europe/Berlin": "Европа/Берлин",
"Europe/Bratislava": "Европа/Братислава",
"Europe/Brussels": "Европа/Брюссель",
"Europe/Bucharest": "Европа/Бухарест",
"Europe/Budapest": "Европа/Будапешт",
"Europe/Busingen": "Европа/Бюсинген",
"Europe/Chisinau": "Европа/Кишинев",
"Europe/Copenhagen": "Европа/Копенгаген",
"Europe/Dublin": "Европа/Дублин",
"Europe/Gibraltar": "Европа/Гибралтар",
"Europe/Guernsey": "Европа/Гернси",
"Europe/Helsinki": "Европа/Хельсинки",
"Europe/Isle_of_Man": "Европа/остров Мэн",
"Europe/Istanbul": "Европа/Стамбул",
"Europe/Jersey": "Европа/Джерси",
"Europe/Kaliningrad": "Европа/Калининград",
"Europe/Kiev": "Европа/Киев",
"Europe/Kirov": "Европа/Киров",
"Europe/Kyiv": "Европа/Киев",
"Europe/Lisbon": "Европа/Лиссабон",
"Europe/Ljubljana": "Европа/Любляна",
"Europe/London": "Европа/Лондон",
"Europe/Luxembourg": "Европа/Люксембург",
"Europe/Madrid": "Европа/Мадрид",
"Europe/Malta": "Европа/Мальта",
"Europe/Mariehamn": "Европа/Мариехамн",
"Europe/Minsk": "Европа/Минск",
"Europe/Monaco": "Европа/Монако",
"Europe/Moscow": "Европа/Москва",
"Europe/Nicosia": "Европа/Никосия",
"Europe/Oslo": "Европа/Осло",
"Europe/Paris": "Европа/Париж",
"Europe/Podgorica": "Европа/Подгорица",
"Europe/Prague": "Европа/Прага",
"Europe/Riga": "Европа/Рига",
"Europe/Rome": "Европа/Рим",
"Europe/Samara": "Европа/Самара",
"Europe/San_Marino": "Европа/Сан-Марино",
"Europe/Sarajevo": "Европа/Сараево",
"Europe/Saratov": "Европа/Саратов",
"Europe/Simferopol": "Европа/Симферополь",
"Europe/Skopje": "Европа/Скопье",
"Europe/Sofia": "Европа/София",
"Europe/Stockholm": "Европа/Стокгольм",
"Europe/Tallinn": "Европа/Таллин",
"Europe/Tirane": "Европа/Тирана",
"Europe/Tiraspol": "Европа/Тирасполь",
"Europe/Ulyanovsk": "Европа/Ульяновск",
"Europe/Uzhgorod": "Европа/Ужгород",
"Europe/Vaduz": "Европа/Вадуц",
"Europe/Vatican": "Европа/Ватикан",
"Europe/Vienna": "Европа/Вена",
"Europe/Vilnius": "Европа/Вильнюс",
"Europe/Volgograd": "Европа/Волгоград",
"Europe/Warsaw": "Европа/Варшава",
"Europe/Zagreb": "Европа/Загреб",
"Europe/Zaporozhye": "Европа/Запорожье",
"Europe/Zurich": "Европа/Цюрих",
"GB": "ГИГАБАЙТ",
"GB-Eire": "Британская империя",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Гринвич",
"HST": "ХОСТ",
"Hongkong": "Гонконг",
"Iceland": "Исландия",
"Indian/Antananarivo": "Индиан/Антананариву",
"Indian/Chagos": "Индей/Чагос",
"Indian/Christmas": "Индейский/Рождество",
"Indian/Cocos": "Индейцы/Кокос",
"Indian/Comoro": "Индейский/Коморский",
"Indian/Kerguelen": "Индейцы/Кергелен",
"Indian/Mahe": "Индейский/Маэ",
"Indian/Maldives": "Индийцы/Мальдивы",
"Indian/Mauritius": "Индийский/Маврикий",
"Indian/Mayotte": "Индей/Майотта",
"Indian/Reunion": "Индеец и Реюньон",
"Iran": "Иран",
"Israel": "Израиль",
"Jamaica": "Ямайка",
"Japan": "Япония",
"Kwajalein": "Кваджалейн",
"Libya": "Ливия",
"MET": "ВСТРЕТИЛ",
"MST": "ДОЛЖЕН",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Мексика/Северный Бахано",
"Mexico/BajaSur": "Мексика/Баджасур",
"Mexico/General": "Мексика/Генерал",
"NZ": "НОВАЯ ЗЕЛАНДИЯ",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Навахо",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Тихий океан/Апиа",
"Pacific/Auckland": "Тихий океан/Окленд",
"Pacific/Bougainville": "Тихий океан/Бугенвиль",
"Pacific/Chatham": "Тихий океан/Чатем",
"Pacific/Chuuk": "Тихий океан/Чуук",
"Pacific/Easter": "Тихий океан/Пасха",
"Pacific/Efate": "Тихий океан/Эфат",
"Pacific/Enderbury": "Тихий океан/Эндербери",
"Pacific/Fakaofo": "Тихий океан/Факаофо",
"Pacific/Fiji": "Тихий океан/Фиджи",
"Pacific/Funafuti": "Тихий океан/Фунафути",
"Pacific/Galapagos": "Тихий океан/Галапагосские острова",
"Pacific/Gambier": "Тихий океан/Гамбиер",
"Pacific/Guadalcanal": "Тихий океан/Гуадалканал",
"Pacific/Guam": "Тихий океан/Гуам",
"Pacific/Honolulu": "Тихий океан/Гонолулу",
"Pacific/Johnston": "Тихий океан/Джонстон",
"Pacific/Kanton": "Тихий океан/Кантон",
"Pacific/Kiritimati": "Тихий океан/Киритимати",
"Pacific/Kosrae": "Тихий океан/Косраэ",
"Pacific/Kwajalein": "Тихий океан/Кваджалейн",
"Pacific/Majuro": "Тихий океан/Маджуро",
"Pacific/Marquesas": "Тихий океан/Маркизские острова",
"Pacific/Midway": "Тихий океан/Мидуэй",
"Pacific/Nauru": "Тихий океан/Науру",
"Pacific/Niue": "Тихий океан/Ниуэ",
"Pacific/Norfolk": "Тихий океан/Норфолк",
"Pacific/Noumea": "Тихий океан/Нумеа",
"Pacific/Pago_Pago": "Тихий океан/Паго-Паго",
"Pacific/Palau": "Тихий океан/Палау",
"Pacific/Pitcairn": "Тихий океан/Питкэрн",
"Pacific/Pohnpei": "Тихий океан/Понпеи",
"Pacific/Ponape": "Тихий океан/Понапе",
"Pacific/Port_Moresby": "Тихий океан/Порт-Морсби",
"Pacific/Rarotonga": "Тихий океан/Раротонга",
"Pacific/Saipan": "Тихий океан/Сайпан",
"Pacific/Samoa": "Тихий океан/Самоа",
"Pacific/Tahiti": "Тихий океан/Таити",
"Pacific/Tarawa": "Тихий океан/Тарава",
"Pacific/Tongatapu": "Тихий океан/Тонгатапу",
"Pacific/Truk": "Тихий океан/Грузовик",
"Pacific/Wake": "Тихий океан/Уэйк",
"Pacific/Wallis": "Тихий океан/Уоллис",
"Pacific/Yap": "Тихий океан/Япония",
"Poland": "Польша",
"Portugal": "Португалия",
"ROC": "РОК",
"ROK": "РОК",
"Singapore": "Сингапур",
"Turkey": "Турция",
"UCT": "ВЫРЕЗАТЬ",
"US/Alaska": "США/Аляска",
"US/Aleutian": "США/Алеутские острова",
"US/Arizona": "США/Аризона",
"US/Central": "США/Центральная часть",
"US/East-Indiana": "США/Восточная Индиана",
"US/Eastern": "США/Восток",
"US/Hawaii": "США/Гавайи",
"US/Indiana-Starke": "США/Индиана-Старк",
"US/Michigan": "США/Мичиган",
"US/Mountain": "США/Горы",
"US/Pacific": "США/Тихоокеанский регион",
"US/Samoa": "США/Самоа",
"UTC": "UTC",
"Universal": "Универсальный",
"W-SU": "W-SU",
"WET": "МОКРЫЙ",
"Zulu": "Зулу",
}