import createActionType from '../utils/action'
import api from '../utils/api'
import storage from '../utils/storage/storage'
import showToast from '../utils/toast'
import openapi from '../utils/openapi'

export const PASSCODE_VALIDATE_REQUEST = createActionType('PASSCODE_VALIDATE_REQUEST')
export const PASSCODE_VALIDATE_FAILURE = createActionType('PASSCODE_VALIDATE_FAILURE')
export const PASSCODE_VALIDATE_SUCCESS = createActionType('PASSCODE_VALIDATE_SUCCESS')

const passcodeValidateRequest = () => ({
  type: PASSCODE_VALIDATE_REQUEST,
})

export const passcodeValidateFailure = (message) => ({
  type: PASSCODE_VALIDATE_FAILURE,
  message,
})

export const passcodeValidateSuccess = (data) => ({
  type: PASSCODE_VALIDATE_SUCCESS,
  data,
})

export const validatePasscode = (passcode, t) => async (dispatch) => {
  dispatch(passcodeValidateRequest())
  try {
    const res = await openapi.get(`/sites/passcode/${passcode}`)
    dispatch(passcodeValidateSuccess({ ...res.data, passcode }))    
    // api.defaults.headers.common['Realmname'] = res.data.keycloakRealm
    // api.defaults.headers.common['OrganizationCode'] = res.data.organizationCode
    return res.data
  } catch (error) {
    console.log(error)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 404 || 400:
          message = t('InvalidPasscode')
          break
        case 500:
          message = t('SomethingWentWrong')
          break
        default:
          message = t('NetworkError')
          break
      }
    } else {
      message = t('NetworkError')
    }
    dispatch(passcodeValidateFailure(message))
    throw error
  }
}
