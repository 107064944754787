import _ from 'lodash'
import { createSelector } from 'reselect'
import { getActiveStudyId } from './study'
import { getCurrentUserId, getCurrentUser } from './user'

const getSubjectData = (state) => state.subject.data
const getSubjectState = (state) => state.subject
const getActiveSubjectId = (state) => state.subject.selectedSubjectId
// const getCurrentUser = (state) => state.currentUser
// const getCurrentUser = (state) => 'EEF6EB55-00BB-4AEB-98DF-4103DABE0F55'
export const getSubjectInContext = createSelector([getCurrentUser], (user) => {
  return user && user.sessionInfo ? user.sessionInfo.subjectId : null
})
export const getSubjectsOfStudy = createSelector(
  [getSubjectData, getCurrentUser, getActiveStudyId],
  (data, currentUser, studyId) => {
    if (currentUser && currentUser.role === 'CAREGIVER') {
      return data[currentUser.id]
    }
    if (studyId) {
      return _.filter(
        data[currentUser.id],
        (sub) => sub.studySite && sub.studySite.study.id === studyId
      )
    }
    return []
  }
)

export const getSearchTerm = createSelector(
  [getCurrentUserId, getSubjectState],
  (userId, subject) => {
    return _.get(subject, `searchTermSubject[${userId}]`, '')
  }
)

export const getFilteredSubjects = createSelector(
  [getSubjectsOfStudy, getSearchTerm],
  (subjects, searchTermSubject) => {
    if (!subjects) {
      return []
    }
    if (searchTermSubject) {
      return subjects.filter((subject) => _.includes(_.lowerCase(subject?.subjectNo), _.lowerCase(searchTermSubject)))
    } else {
      return subjects
    }
  }
)
export const getActiveSubject = createSelector(
  [getActiveSubjectId, getSubjectsOfStudy],
  (subjectId, subjects) => {
    return _.find(subjects, (sub) => sub.id === subjectId)
  }
)
