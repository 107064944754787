import createActionType from '../utils/action'
export const STUDIES_RETRIEVE_SUCCESS = createActionType('STUDIES_RETRIEVE_SUCCESS')
export const STUDIES_RETRIEVE_REQUEST = createActionType('STUDIES_RETRIEVE_REQUEST')
export const STUDIES_RETRIEVE_FAILURE = createActionType('STUDIES_RETRIEVE_FAILURE')
export const UPDATE_SELECTED_STUDY = createActionType('UPDATE_SELECTED_STUDY')
export const UPDATE_STUDY_SEARCH_TERM = createActionType('UPDATE_STUDY_SEARCH_TERM')
import enLocale from '../utils/localization/locale-en'

import api from '../utils/api'
import storage from '../utils/storage/storage'
import { updateSession } from './users'

const updateStudies = (data, userId) => ({
  type: STUDIES_RETRIEVE_SUCCESS,
  studies: data,
  userId,
})

const studiesRetrieveRequest = () => ({
  type: STUDIES_RETRIEVE_REQUEST,
})
const studiesRetrieveFailure = () => ({
  type: STUDIES_RETRIEVE_FAILURE,
})
export const retrieveStudies = (siteId) => async (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  dispatch(studiesRetrieveRequest())
  try {
    const res = await api.get(`clients/${siteId}/studies`)
    dispatch(updateStudies(res.data, id))
  } catch (error) {
    console.log(error)
    dispatch(studiesRetrieveFailure(enLocale.FailedRetrieve))
    throw error
  }
}

export const updateStudySearchTerm = (searchTerm) => (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  dispatch({
    type: UPDATE_STUDY_SEARCH_TERM,
    searchTerm: searchTerm,
    userId: id,
  })
}

export const updateStudyInStore = (studyId) => ({
  type: UPDATE_SELECTED_STUDY,
  studyId: studyId,
})
export const updateSelectedStudy = (study) => async (dispatch) => {
  const sessionInfo = {
    studyId: study ? study.id : null,
  }
  dispatch(updateSession(sessionInfo))
  dispatch(updateStudyInStore(study ? study.id : null))
}
