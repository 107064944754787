import createActionType from '../utils/action'
import _ from 'lodash'
export const CAREGIVER_SUBJECTS_RETRIEVE_SUCCESS = createActionType(
  'CAREGIVER_SUBJECTS_RETRIEVE_SUCCESS'
)
export const CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS = createActionType(
  'CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS'
)
export const CAREGIVER_SUBJECTS_RETRIEVE_REQUEST = createActionType(
  'CAREGIVER_SUBJECTS_RETRIEVE_REQUEST'
)
export const CAREGIVER_SUBJECTS_RETRIEVE_FAILURE = createActionType(
  'CAREGIVER_SUBJECTS_RETRIEVE_FAILURE'
)
export const CAREGIVER_SUBJECT_RETRIEVE_BY_ID_REQUEST = createActionType(
  'CAREGIVER_SUBJECTS_RETRIEVE_REQUEST'
)
export const CAREGIVER_SUBJECT_RETRIEVE_BY_ID_FAILURE = createActionType(
  'CAREGIVER_SUBJECTS_RETRIEVE_FAILURE'
)

import api from '../utils/api'
import { subjectsRetrieveFailure, subjectsRetrieveRequest, updateSubjects } from './subject'
import { getStudyVisitMetaData } from './subjectStudyMetaData'
import { changeLanguageSuccess } from './changeLanguage'
import storage from '../utils/storage/storage'
import moment from 'moment-timezone'
import constants from '../constants/constants'

const updateSubjectById = (data) => {
  storage.setItem('privileges', data?.subjectPrivileges)
return {
  type: CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS,
  subject: data,
}
}

const subjectRetrieveByIdRequest = () => ({
  type: CAREGIVER_SUBJECT_RETRIEVE_BY_ID_REQUEST,
})
const subjectRetrieveByIdFailure = () => ({
  type: CAREGIVER_SUBJECT_RETRIEVE_BY_ID_FAILURE,
})
export const retrieveSubjectsOfCaregiver = (caregiverId) => async (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  try {
    dispatch(subjectsRetrieveRequest())
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/caregivers/${id}/subjects`)
    if (res && res.data) {
      if (!_.isEmpty(res.data) && _.size(res.data, 1)) {
        await dispatch(retrieveSubjectById(res.data[0].id))
        await dispatch(getStudyVisitMetaData())
      }
    } else {
      throw Error('Invalid response.')
    }
    dispatch(updateSubjects(res.data ? res.data : [], id))
  } catch (error) {
    dispatch(subjectsRetrieveFailure(id))
    throw error
  }
}
export const retrieveSubjectById = (subjectId) => async (dispatch) => {
  try {
    dispatch(subjectRetrieveByIdRequest())
    api.defaults.headers.common['subject-id'] = subjectId
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}`)
    dispatch(updateSubjectById(res.data ? res.data : []))
    dispatch(changeLanguageSuccess(res.data.subject.locale))
    moment.tz.setDefault(res.data?.subject?.timeZone)
  } catch (error) {
    dispatch(subjectRetrieveByIdFailure())
    throw error
  }
}
