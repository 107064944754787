import createActionType from '../utils/action'

export const STORE_APP_STATUS = createActionType('STORE_APP_STATUS')
export const STORE_OFFLINE_FORMS_TO_SYNC = createActionType('STORE_OFFLINE_FORMS_TO_SYNC')
export const STORE_CURRENT_SCREEN = createActionType('STORE_CURRENT_SCREEN')
export const STORE_SYNC_STATUS = createActionType('STORE_SYNC_STATUS')
export const UPDATE_SYNC_COMPLETE_STATUS = createActionType('UPDATE_SYNC_COMPLETE_STATUS')
export const UPDATE_DRAFT_DATA = createActionType('UPDATE_DRAFT_DATA')
export const UPDATE_SYNC_QUEUE_TIMER = createActionType('UPDATE_SYNC_QUEUE_TIMER')
export const UPDATE_APP_TYPE = createActionType('UPDATE_APP_TYPE')
export const UPDATE_IS_ACKNOWLEDGED = createActionType('UPDATE_IS_ACKNOWLEDGED')

export const UPDATE_SPACE_AVAILABLE = createActionType('UPDATE_SPACE_AVAILABLE')
export const UPDATE_DOWNLOADED_IMAGE_TIMER = createActionType('UPDATE_DOWNLOADED_IMAGE_TIMER')
export const UPDATE_SPACE_ACKNOWLEDGEMENT = createActionType('UPDATE_SPACE_ACKNOWLEDGEMENT')
export const UPDATE_ON_MOBILE_STATUS = createActionType('UPDATE_ON_MOBILE_STATUS')


const storeAppStatusSuccess = (status) => ({
  type: STORE_APP_STATUS,
  isDeviceOnline: status,
})

const storeOfflineFormsToSyncSuccess = (noOfForms) => ({
  type: STORE_OFFLINE_FORMS_TO_SYNC,
  noOfForms,
})

const setCurrentScreenSuccess = (screen) => ({
  type: STORE_CURRENT_SCREEN,
  screen,
})

const storeSyncStatusSuccess = (value) => ({
  type: STORE_SYNC_STATUS,
  value,
})

const updateSyncCompleteStatusSuccess = (value) => ({
  type: UPDATE_SYNC_COMPLETE_STATUS,
  value,
})

export const storeAppStatus = (status) => (dispatch) => {
  dispatch(storeAppStatusSuccess(status))
}

export const setCurrentScreen = (screen) => (dispatch) => {
  dispatch(setCurrentScreenSuccess(screen))
}

export const storeSyncStatus = (value) => (dispatch) => {
  dispatch(storeSyncStatusSuccess(value))
}

export const storeOfflineFormsToSync = (noOfForms) => (dispatch) => {
  dispatch(storeOfflineFormsToSyncSuccess(noOfForms))
}

export const updateSyncCompleteStatus = (value) => (dispatch) => {
  dispatch(updateSyncCompleteStatusSuccess(value))
}

export const updateDraftData = (data) => ({
  type: UPDATE_DRAFT_DATA,
  data,
})

export const updateSyncQueueTimer = (timerId) => ({
  type: UPDATE_SYNC_QUEUE_TIMER,
  timerId,
})

export const updateAppType = (appType) => ({
  type: UPDATE_APP_TYPE,
  appType,
})

export const updateIsAcknowledged = (isAcknowledged) => async (dispatch) => {
  dispatch({
    type: UPDATE_IS_ACKNOWLEDGED,
    isAcknowledged,
  })
}


export const updateSpaceAvailabilityInStore = (isSpaceAvailable) => async (dispatch) => {
  dispatch({
    type: UPDATE_SPACE_AVAILABLE,
    data: isSpaceAvailable,
  })
}

export const updateDownloadImageTimerId = (timerId) => async (dispatch) => {
  dispatch({
    type: UPDATE_DOWNLOADED_IMAGE_TIMER,
    timerId,
  })
}

export const updateSpaceAcknowledgement = (isAcknowledged) => async (dispatch) => {
  dispatch({
    type: UPDATE_SPACE_ACKNOWLEDGEMENT,
    isAcknowledged,
  })
}

export const updateOnMobileStatus = (onMobile) => async (dispatch) => {
  dispatch({
    type: UPDATE_ON_MOBILE_STATUS,
    onMobile,
  })
}