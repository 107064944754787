import React from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import { getHzTextAlign, getScaleProperties } from '../utils';
import PropTypes from 'prop-types';
import { alignments } from '../alignments';
import { getValue } from '../../fields/Visual Scale/VerticalLabelUtils';
import LabelTextHorizontal from '../../fields/Visual Scale/LabelTextHorizontal';
import { getHzLabelHeight, getWidthArray } from '../../fields/Visual Scale/HorizontalLabelUtils';
import { useSelector } from 'react-redux';

const HorizontalLabels = (props) => {
  const { start, step, end, labels, orientation, id, disabled, transformedFontSize, labelsData} = props
  const { onMobile } = useSelector(state => state?.appStatus);
  const scaleProps = getScaleProperties(start, end, step, orientation, onMobile)
  const {widthOfBox, scale, margin} = scaleProps

  let boxStyles = {...styles.box}
  if(!_.isEmpty(_.filter(labels, label => label.labelPlacement === 'BOTTOM'))){
    boxStyles = {...boxStyles, justifyContent: 'flex-start'}
  }

  const getTextStyles = (number) => {
    return {textAlign: getHzTextAlign(number, end)}
  }

  return (
    <View style={styles.container}>
      {
        _.map(_.range(start, end + 1, step), (number, index) => {
          let labelBoxStyle = {...styles.labelBoxStyle}
          if(number === end){
            labelBoxStyle = {
              ...labelBoxStyle,
              alignItems: 'flex-end'}
          }
          const loWidth =  _.get(labelsData[number], 'width', 0)
          const isTextFit =  _.get(labelsData[number], 'fit', false)
          return (
            <View key={"Horizontal label-"+index} 
            style={[boxStyles, { 
              width: widthOfBox, 
              transform: [{ scale}],
              marginHorizontal: margin,
              }]}
              testID={`numeric-rating-scale-sliderLabels-${id}-${number}-hz-label`}
              accessible={!disabled}
              >
                <View style={[labelBoxStyle, {width: widthOfBox}]}>
                <LabelTextHorizontal 
                    text={getValue(number, labels)}
                    minFontSize={transformedFontSize}
                    containerWidth={loWidth}
                    containerHeight={getHzLabelHeight(loWidth, (widthOfBox+margin))}
                    labelStyles={getTextStyles(number)}
                    testID={`numeric-rating-scale-sliderLabels-${id}-${number}-hz-label`}
                    accessible={!disabled} 
                    containerStyle={{width: loWidth}}
                    widthArray={onMobile ? [loWidth] : getWidthArray(loWidth, (widthOfBox+margin))}
                    isTextFit={isTextFit}
                    orientation={alignments.HORIZONTAL}
                  />
                </View>
                 
          </View>
          )
        })
      }
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  box: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  labelBoxStyle: {
    marginVertical: 5,
  }
});

HorizontalLabels.defaultProps = {
    labels: [],
    start: 0,
    end: 10,
    step: 1,
    orientation: alignments.HORIZONTAL,
    id: null,
    disabled: false,
    labelsData: {}
  }
  
HorizontalLabels.propTypes = {
    labels: PropTypes.arrayOf(Object),
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    orientation: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    transformedFontSize: PropTypes.number.isRequired,
    labelsData: PropTypes.instanceOf(Object)
}
export default HorizontalLabels;


