import { combineReducers } from 'redux'
import call from './call'
import changeLanguage from './changelanguage'
import chat from './chat'
import crfData from './crfData'
import deviceLocation from './deviceLocation'
import faq from './faq'
import field from './field'
import form from './form'
import healthKitData from './healthKitdata'
import loading from './loading'
import offlineData from './offlineData'
import site from './site'
import appStatus from './storeAppStatus'
import study from './study'
import subject from './subject'
import subjectPrivileges from './subjectPrivileges'
import subjectStudyMetaData from './subjectStudyMetaData'
import subjectVisitForm from './subjectVisitForm'
import timeZone from './timeZone'
import visitForm from './visitForm'
import users from './users'
import session from './session'
import syncQueue from './syncQueue'
import metaData from './metaData'
import formAnswers from './formAnswers'
import formPrint from './formPrint'

const AppReducer = combineReducers({
  subject,
  site,
  study,
  changeLanguage,
  subjectVisitForm,
  field,
  crfData,
  faq,
  loading,
  timeZone,
  subjectStudyMetaData,
  visitForm,
  appStatus,
  healthKitData,
  deviceLocation,
  chat,
  call,
  subjectPrivileges,
  study,
  users,
  offlineData,
  form,
  session,
  syncQueue,
  metaData,
  formAnswers,
  formPrint,
})

export default AppReducer
