import { encode as btoa } from 'base-64'
import 'text-encoding-polyfill'

function bytesToBase64(bytes) {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('')
  return btoa(binString)
}

export const generateBasicAuthToken = (userName, password) =>
  `Basic ${bytesToBase64(new TextEncoder().encode(`${userName}:${password}`))}`

export const generateBasicAuthHeader = (userName, password) =>
  `Authorization: ${generateBasicAuthToken(userName, password)}`

export const checkForOfflineFailureStatus = (response) => {
  if (response) {
    return (
      response.includes('Failed to connect') ||
      response.includes('Software caused connection abort')
    )
  }
}

export const generatePercentage = (value, total) => {
  const percentageValue = (value / total) * 100
  console.log(percentageValue)
  return percentageValue
}

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const imageExists = (url) => {
  return new Promise(resolve => {
    let img = new Image()
    img.addEventListener('load', () => resolve(true))
    img.addEventListener('error', () => resolve(false))
    img.src = url
  })
}

export const removePercentageFromValue = (value) => {
  return (value != null && value != "" && value != undefined) ? value?.toString().replace(/[%\s]/g, '') : null
}