import * as Linking from 'expo-linking'
import moment from 'moment-timezone'
import React, { Component } from 'react'
import { Platform, Text, TouchableOpacity, View } from 'react-native'
import appConstants from '../../constants/appConstants'
import constants from '../../constants/constants'
import SubjectTimezone from '../../utils/dateFormatUtils'
import { retrieveSubjectDeviceToken } from '../../utils/secureStorageUtils'
import { getActualCallDuration } from './callUtils'
import styles from './styles'
import storage from '../../utils/storage/storage'

export default class CallDetails extends Component {
  state = { deviceTokenData: {} }

  componentDidMount() {
    this.getSubjectDeviceData()
  }

  getSubjectDeviceData = async () => {
    const deviceTokenData = await retrieveSubjectDeviceToken()
    this.setState({
      deviceTokenData,
    })
  }
  showJoinButton = () => {
    const { call, timeZone } = this.props
    if (
      constants.CALL_STATUS.STARTED == call.status ||
      constants.CALL_STATUS.SCHEDULED == call.status
    ) {
      return true
    }
    const scheduleEndTime = moment
      .utc(call.scheduleDateTimeUtc)
      .tz(timeZone)
      .add(call.duration + 5, 'm')
      .format('DD-MMM-YYYY hh:mm A')
    const currentDate = moment().tz(timeZone).format('DD-MMM-YYYY hh:mm A')
    if (moment(currentDate).isAfter(moment(scheduleEndTime))) {
      return false
    }
    return true
  }

  render() {
    const {
      navigation,
      call,
      screenProps: { t },
      timeZone,
    } = this.props
    const meetingUrl = `${appConstants.jitsiMeetUrl}/${call.accessCode}?jwt=${encodeURIComponent(
      storage.getString('SubjectAuthorizationToken')
    )}`
    return (
      <View style={styles.callDetailsView} testID={'call-details-tab'} accessible = {true}>
        <Text style={styles.callDetailLabelText}>
          {t('Title')}: <Text style={styles.callDetailText}>{call.title}</Text>{' '}
        </Text>
        <Text style={styles.callDetailLabelText}>
          {t('Status')}: <Text style={styles.callDetailText}>{call.status}</Text>{' '}
        </Text>
        {constants.CALL_STATUS.SCHEDULED == call.status && (
          <Text style={styles.callDetailLabelText}>
            {t('ScheduledTime')}:{' '}
            <Text style={styles.callDetailText}>
              {moment(call.date).format('DD-MMM-YYYY')} {call.time}{' '}
              {moment(call.date).tz(timeZone).format('z')}
            </Text>{' '}
          </Text>
        )}
        {(constants.CALL_STATUS.COMPLETED == call.status ||
          constants.CALL_STATUS.STARTED == call.status) && (
          <Text style={styles.callDetailLabelText}>
            {t('StartedOn')}:{' '}
            <Text style={styles.callDetailText}>
              <SubjectTimezone dateTime={call.startTime} format="DD-MMM-YYYY hh:mm:ss A z" />
            </Text>
          </Text>
        )}
        {constants.CALL_STATUS.COMPLETED == call.status && (
          <Text style={styles.callDetailLabelText}>
            {t('CompletedOn')}:{' '}
            <Text style={styles.callDetailText}>
              <SubjectTimezone dateTime={call.endTime} format="DD-MMM-YYYY hh:mm:ss A z" />
            </Text>{' '}
          </Text>
        )}
        {constants.CALL_STATUS.SCHEDULED == call.status && (
          <Text style={styles.callDetailLabelText}>
            {t('ScheduledDuration')}:{' '}
            <Text style={styles.callDetailText}>
              {Math.floor(call.duration / 60) == 0
                ? ''
                : `${Math.floor(call.duration / 60)} ${call.duration > 119 ? 'hrs' : 'hr'} `}{' '}
              {call.duration % 60 == 0
                ? ''
                : `${call.duration % 60} ${call.duration % 60 == 1 ? 'min' : 'mins'}`}
            </Text>{' '}
          </Text>
        )}
        {constants.CALL_STATUS.COMPLETED == call.status && (
          <Text style={styles.callDetailLabelText}>
            {t('Duration')}:{' '}
            <Text style={styles.callDetailText}>{getActualCallDuration(call)}</Text>{' '}
          </Text>
        )}
        {this.showJoinButton() && (
          <TouchableOpacity
            style={styles.joinCallBtn}
            onPress={() => {
              if (Platform.OS === 'web') {
                window.open(meetingUrl, '_blank')
              } else {
                Linking.openURL(meetingUrl)
              }
            }}
            testID={`join-call-${call.title}-btn`}
            accessible={true}
          >
            <Text style={styles.joinCallText}>{t('JoinCall')}</Text>
          </TouchableOpacity>
        )}
      </View>
    )
  }
}
