import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { checkTextFit, getFontSizeCalculated, getValue, isLabelsDataPrepared } from "../../../components/fields/Visual Scale/VerticalLabelUtils"
import _ from 'lodash'
import constants from "../../../constants/constants"
import { getHzLabelHeight, getLabelWidth } from "../../../components/fields/Visual Scale/HorizontalLabelUtils"
import PropTypes from 'prop-types'
import { fontScale } from "../../styles/fontResizer"
import HorizontalScaleWrapper from "./HorizontalScaleContainer"
import { alignments } from "../alignments"

const {VERTICAL_LABEL_HEIGHT_LIMIT} = constants
const HorizontalScaleContainerWrapper = (props) => {
    const { start, end, step, options, scaleProps} = props
    const {widthOfBox, margin} = scaleProps
    const [labelsData, setLabelsData] = useState({})

    useEffect(() => {
        prepareLabelsData()
    }, [options])

    const setLabelsDetails = (data) => {
        if(_.isEmpty(_.filter(data, ele => !_.isNumber(ele?.fontSize) && !_.isBoolean(ele?.fit)))){
         setLabelsData(data)
        }
    }
    
    const textStyles = {textAlign : 'center'}
     const prepareLabelsData = () => {
        let loLabelsData = {...labelsData}
        _.map(_.range(start, end + 1, step), (number, index) => {   
            if(!_.isEmpty(getValue(number, options))){
                const avaWidth = getLabelWidth(options, number, (widthOfBox+margin), start, end, step)
                 loLabelsData = {
                    ...loLabelsData,
                    [number]: {
                        ...loLabelsData[number],
                        height: getHzLabelHeight(avaWidth, widthOfBox+margin),
                        width: avaWidth,
                        textStyles: textStyles,
                    }
                } 
            } 
        })
        setLabelsData(loLabelsData)
    }

    return (
    <View>
    {/* -------------checking fontsize is available or not */}
    {!isLabelsDataPrepared(labelsData) && checkTextFit({start: start, end: end, step, labelsData, defaultLabelWidth: 0, defaultlabelHeight: 0, labels: options, textStyles: textStyles}, setLabelsDetails)}
     <HorizontalScaleWrapper 
        {...props}
        minimumFontSize = {getFontSizeCalculated(labelsData, options, alignments.HORIZONTAL).minFont}
        labelsData={labelsData}
        isCalculated={getFontSizeCalculated(labelsData, options, alignments.HORIZONTAL).isCalculated}                    
    /> 
               
    </View>)
}


HorizontalScaleContainerWrapper.defaultProps = {
    step: 1,
    start: 0,
    end: 10,  
    options: [],
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: fontScale(10),
        scale: 1,
        margin: 0
      },
  }
  
  HorizontalScaleContainerWrapper.propTypes = {
    step: PropTypes.number,
    start: PropTypes.number,
    end: PropTypes.number,
    options: PropTypes.arrayOf(Object),
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
        margin: PropTypes.number,
      }),
}
export default HorizontalScaleContainerWrapper