import _ from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'

export const getMetadata = (state) => state.metaData.data
const getSelectedVisitId = (state) => state.metaData.selectedVisitId

export const getSubjectVisitsWithVisitData = createSelector([getMetadata], (metaData) => {
  var subVWithVData = []
  const visits = metaData.visits
  const subjectVisits = metaData.subjectVisits
  _.forEach(subjectVisits, (sv) => {
    const visit = _.filter(visits, (v) => _.isEqual(v.id, sv.visitId))[0]
    const subV = { ...sv, visit: visit }
    subVWithVData.push(subV)
  })
  var ediaryVisits = _.filter(subVWithVData, (sv) => sv.visit?.isEdiaryVisit)
  const visitsInOrder = _.orderBy(ediaryVisits, 'visit.visitOrder', 'asc')
  return updateVisitStatus(visitsInOrder)
})

export const getCurrentVisit = createSelector(
  [getSelectedVisitId, getSubjectVisitsWithVisitData],
  (selectedVisitId, subjectVisits) => {
    const selectedVisit = _.find(subjectVisits, (sv) => sv.visitId === selectedVisitId)
    return _.isEmpty(selectedVisit) ? {} : selectedVisit
  }
)

const updateVisitStatus = (visits) => {
  const currentDate = moment.utc().format('YYYY-MM-DD')
  return _.map(visits, (v) => {
    const visitStartdate = moment.utc(v.visitStartDate).format('YYYY-MM-DD')
    const visitEndDate = moment.utc(v.visitEndDate).format('YYYY-MM-DD')
    if (
      v.status === 'INPROGRESS') {
      return {
        ...v,
        status: 'NOT_STARTED',
      }
    }
    return v
  })
}
