import createActionType from '../utils/action'

export const CRFDATA_UPDATE_REQUEST = createActionType('CRFDATA_UPDATE_EQUEST')
export const CRFDATA_UPDATE_SUCCESS = createActionType('CRFDATA_UPDATE_SUCCESS')
export const CRFDATA_UPDATE_FAILURE = createActionType('CRFDATA_UPDATE_FAILURE')

const updateCrfDataRequest = () => ({
  type: CRFDATA_UPDATE_REQUEST,
})

const updateCrfDataFailure = (message) => ({
  type: CRFDATA_UPDATE_FAILURE,
  message,
})

const updateCrfDataSuccess = (t) => ({
  type: CRFDATA_UPDATE_SUCCESS,
  locale: t,
})

