import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import constants from '../../constants/constants'
import VisitsHeader from './clinroHeader/components/visitsHeader'
import ParticipantHeader from './participantHeader'

const VisitsScreenHeader = (props)  => {
    const {navigation, screenProps } = props
    const { appStatus } = useSelector((state) => state)

        if(_.isEqual(appStatus.appType, constants.AppType.SITESTAFF)){
            return <VisitsHeader showBanner={true} navigation={navigation} screenProps={screenProps} />
        }
    
        return <ParticipantHeader navigation={navigation} screenProps={screenProps} canShowBackIcon={false} title="Visits"/> 

    

}

export default VisitsScreenHeader