import React, { useEffect, useState } from "react";
import { checkTextFit, getFontSizeCalculated, getLabel, getLabelWidthBasedOnPlacement, getValue, isLabelsDataPrepared, prepareLabelsMetaData } from "../../../components/fields/Visual Scale/VerticalLabelUtils";
import VerticalScaleContainer from "./VerticalScaleContainer";
import { getLabelsFlex } from "../../scales/utils";
import { View } from "react-native";
import _ from 'lodash';
import PropTypes from 'prop-types';
import constants from "../../../constants/constants";
import { fontScale } from "../../styles/fontResizer";

const {SCALE_POSITION, labelPlacement, EDGE_LABEL_HEIGHT} = constants
const VerticalScaleContainerWrapper = (props) => {
    const {start, end, step, options, scaleProps, scaleVerticalAlignment } = props
    const {widthOfBox, margin} = scaleProps
    const [labelsData, setLabelsData] = useState({})
    const [boxLabelsData, setBoxLabelsData] = useState({})
    const containerHeight = widthOfBox+margin

    const labelsFlex = getLabelsFlex(options, scaleVerticalAlignment);
    useEffect(() => {
        prepareLabelDataBasedOnItsOwnBox()
   }, [])

    const prepareLabelDataBasedOnItsOwnBox = () => {
        let data = {}
        _.map(_.orderBy(_.range(start, end + 1, step), [], 'desc'), (number, index) => {   
            if(!_.isEmpty(getValue(number, options))){
                const label = getLabel(number, options)
                data = {
                    ...data,
                    [number]: {
                        ...data[number],
                        height: getBoxHeight(label, widthOfBox+margin),
                        width: getLabelWidthBasedOnPlacement(labelsFlex, label),
                    }
                } 
            } 
        })
        setBoxLabelsData(data)
    }

   const setLabelsDetails = (data) => {
    if(_.isEmpty(_.filter(data, ele => !_.isNumber(ele?.fontSize) && !_.isBoolean(ele?.fit)))){
        const keys = Object.keys(data)
        _.forEach(keys, key => {
            data = {
                ...data,
                [key]: {
                    ...data[key],
                    fitInOwnBox: boxLabelsData[key]?.fit,
                    fontSize: data[key]?.fontSize,
                    fit: data[key]?.fit,
                }
            }
        })
        setLabelsData(data) 
    }
   }

   const textStyles = {textAlign: 'center'}

   const setBoxLabelsDetails = (data) => {
    if(_.isEmpty(_.filter(data, ele => !_.isNumber(ele?.fontSize) && !_.isBoolean(ele?.fit)))){
        setBoxLabelsData(data)
        prepareLabelsMetaData({start, end, step, labelsData, options, widthOfBox: containerHeight, labelsFlex, isVAS: false}, setLabelsBasicDetails) 
    }
   }

  const setLabelsBasicDetails = (data) => {
    setLabelsData(data)
  }


   const getBoxHeight = (label, widthOfBox) => {
    switch (label.labelPlacement) {
        case labelPlacement.TOP: return EDGE_LABEL_HEIGHT
        case labelPlacement.BOTTOM: return EDGE_LABEL_HEIGHT
        default: return widthOfBox
    }
   }


    return (
        <View>
            {/* -------------checking with it's own box */}
           {!isLabelsDataPrepared(boxLabelsData) &&  checkTextFit({start,end, step, labelsData: boxLabelsData, defaultLabelWidth: 0, defaultlabelHeight: 0, labels: options, textStyles}, setBoxLabelsDetails)}
           {/* -------------checking fontsize is available or not */}
           {!_.isEmpty(boxLabelsData) && !isLabelsDataPrepared(labelsData) && checkTextFit({start,end, step, labelsData, defaultLabelWidth: 0, defaultlabelHeight: 0, labels: options, textStyles}, setLabelsDetails)}

           {/* --------------slider-------------- */}
            <VerticalScaleContainer 
                {...props}
                minimumFontSize = {getFontSizeCalculated(labelsData, options).minFont}
                labelsData={labelsData}
                labelsFlex={labelsFlex}
                isCalculated={getFontSizeCalculated(labelsData, options).isCalculated}
            /> 
        </View>

    )

}


VerticalScaleContainerWrapper.defaultProps = {
    step: 1,
    start: 0,
    end: 10,
    options: [],
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: fontScale(10),
        scale: 1,
        margin: 0
      },
      scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE, 
  }
  
  VerticalScaleContainerWrapper.propTypes = {
    step: PropTypes.number,
    start: PropTypes.number,
    end: PropTypes.number,
    options: PropTypes.arrayOf(Object),
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
        margin: PropTypes.number,
      }),
    scaleVerticalAlignment: PropTypes.string,  
   
}

export default VerticalScaleContainerWrapper