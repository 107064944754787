import initialState from '../store/initialState'
import { OFFLINE_DATA_RETRIEVE_SUCCESS } from '../actions/login'
import { CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS } from '../actions/caregiver'
import _ from 'lodash'
import { PURGE } from 'redux-persist'

export default (state = initialState.subjectPrivileges, action) => {
  switch (action.type) {
    case OFFLINE_DATA_RETRIEVE_SUCCESS:
      return !_.isEmpty(action.offlineData.subjectPrivileges)
        ? [
            ...action.offlineData.subjectPrivileges,
            // ...['VIEW_DIARY',
            // // 'SUBMIT_FORM',
            // 'VIEW_CALL',
            // 'VIEW_COMPLIANCE',
            // 'VIEW_CHAT',
            // // 'CREATE_CHAT',
            // 'VIEW_HEALTH_DATA',
            // // 'VIEW_NOTIFICATIONS',
            // 'VIEW_TIMEZONE',
            // 'VIEW_CONTACT',
            // // 'SETUP_APPLOCK',
            // 'VIEW_FAQ']
          ]
        : []
    case CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS:
      return [...action.subject.subjectPrivileges]
    case PURGE:
      return []
    default:
      return state
  }
}
