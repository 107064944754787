import React from 'react'
import { StyleSheet, View, Text, Platform } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isPinExists } from '../utils/secureStorageUtils'
import { storeDeviceLocationDetails } from '../actions/deviceLocation'
import Constants from 'expo-constants'
import coreConstants from '../constants/constants'
import appConstants from '../constants/appConstants'
import { getCurrentUser } from '../selectors/user'
import { NavigationActions } from 'react-navigation'
import PropTypes from 'prop-types';


class InitialScreen extends React.Component {
  state = {}

  async componentDidMount() {
    const { navigation, currentUser } = this.props
  
    setTimeout(async () => {
      const fromLoginWithPin = navigation.state?.params?.fromLoginWithPin
      const sessionTimeout = navigation.state?.params?.sessionTimeout
      let isClinRo = coreConstants.AppType.SITESTAFF === Constants.expoConfig.extra.appType
      if (Platform.OS !== 'web') {
        isClinRo = false // temporarily disable clinro support for native
      }
      const screen = isClinRo ? 'ClinRoLogin' : 'Login'
      const appType = isClinRo ? 'clinician' : 'participant'
  
      try {
        if (!isClinRo) {
          if(fromLoginWithPin){
            navigation.navigate('Login', {fromLoginWithPin: true})
          } else if(sessionTimeout){
            navigation.navigate('SessionTimeout')
          } else if (isPinExists(currentUser?.phoneNo)) {
            const navigateAction = NavigationActions.navigate({
              routeName: `${appConstants.urlPrefix}ParticipantAppSetup`,
              params: { login: true },
              action: NavigationActions.navigate({ routeName: 'PinValidate' }),
            })
            navigation.dispatch(navigateAction)
          } else {
            navigation.replace(screen, { appType })
          }
        } else if (Platform.OS === 'web') {
          let params = { appType: 'participant' }
          const urlParams = new URLSearchParams(window.location.search)
          const accessCode = urlParams.get('accessCode')
          if (accessCode && appConstants.AppType.SITESTAFF !== appType) {
            params.accessCode = accessCode
          }
          navigation.replace('Login', params)
        }
      } catch (error) {
        console.log(error)
        navigation.replace(screen, { appType })
      }
    }, 1500)
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={{ fontSize: 25 }}></Text>
      </View>
    )
  }
}

InitialScreen.defaultProps = {
  currentUser: {
    phoneNo: '',
  },
}
InitialScreen.propTypes = {
  currentUser: PropTypes.object,
  navigation: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeDeviceLocationDetails,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(InitialScreen)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
})
