import React, { useMemo } from "react"
import { Dimensions, PixelRatio, StyleSheet, View } from "react-native"
import { alignments } from "../../scales/alignments"
import { Slider } from '@miblanchard/react-native-slider'
import constants from "./constants"
import PropTypes from 'prop-types'
import _ from 'lodash'
import VerticalTrackMarkContainer from "./VerticalTrackMarkContainer"
const {SHOW_MARKS} = constants
const BasicVerticalScale = (props) => useMemo(() => {
    const {step, min, max, onValueChange, disabled, id, value, 
        isPercentage, indicationConfig, showMarks, orientation, 
        fontConfig} = props
    const trackMarks = _.range(min, max+1, 0.1)
    const displayMarks = (obj) => {
        return (
            <VerticalTrackMarkContainer
                value={value}
                index={(trackMarks[obj].toFixed(1)*10)/10}
                min={min}
                max={max}
                showMarks={showMarks}
                step={step}
                indicationConfig={indicationConfig}
                isPercentage={isPercentage}
                requiredFontForMarks={fontConfig.requiredFontForMarks}
                numberOfTerms={fontConfig.numberOfTerms}
                onValueSelect = {onValueSelect}
                {...props}
                orientation={alignments.MOBILE_VERTICAL}

            />
        )
    }
    let thumbStyle = {...styles.hzThumbStyles}
    if(orientation === alignments.VERTICAL){
        thumbStyle = {...styles.vtThumbStyles}
    }
    const displayThumb = () => {
        return (<View style={{...thumbStyle}}
            accessible = {!disabled}
            testID= {`${id}-slider-thumb`}></View>)
    }
    const onValueSelect = (v) => {
      onValueChange(v)
    }
    return (
        <View style={{
            flex: 0.9, 
            height: 400,
            width: Dimensions.get('window').width,
        }}>
          <Slider
            step={0.1}
            minimumValue={min}
            maximumValue={max}
            containerStyle={{
              width: 400,
              height: 60,
              justifyContent: "center",
              alignSelf: "stretch",
            marginLeft: -150,
            marginTop: 170,
            }}
            onSlidingComplete={onValueSelect}
            onValueChange={onValueSelect}
            vertical={true}
            trackMarks={trackMarks}
            renderTrackMarkComponent={displayMarks}
            disabled={false}
            minimumTrackTintColor="#c5c5c5"
            maximumTrackTintColor="#c5c5c5"
            accessible = {true}
            testID= {`${id}-slider`}
            value={value}
            renderThumbComponent={displayThumb}
          />
        </View>
    )
}, [props.id, props.value, props.disabled])
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        height: "100%",
    },
    hzThumbStyles: {
        borderRightColor: 'transparent',
        borderRightWidth: 0,
        height: 0,
        width: 0,
    },
    vtThumbStyles: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
    },
})
BasicVerticalScale.defaultProps = {
    step: 1,
    min: 0,
    max: 10,
    onValueChange: ()=> null,
    id: null,
    disabled: false,
    value: null,
    isPercentage: false,
    indicationConfig: {
        indicationMultiple: null,
        indicationNumbers: null
    },
    showMarks: SHOW_MARKS.NO_MARKS,
    orientation: alignments.HORIZONTAL,
    fontConfig: {
        requiredFontForMarks: 10/PixelRatio.getFontScale(),
        numberOfTerms: 0,
      }
  }
  BasicVerticalScale.propTypes = {
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    isPercentage: PropTypes.bool,
    indicationConfig: PropTypes.shape({
        indicationMultiple: PropTypes.number,
        indicationNumbers: PropTypes.string,
    }),
    showMarks: PropTypes.string,
    orientation: PropTypes.string,
    id: PropTypes.string,
    fontConfig: PropTypes.shape({
        requiredFontForMarks: PropTypes.number,
        numberOfTerms: PropTypes.number,
      })
  }
export default BasicVerticalScale