import timezoneLocaleJapanese from "./timezoneLocale-japanese";
export default {
  ...timezoneLocaleJapanese,
  LoginTitle: 'アルファ臨床 システム',
  LoginACSUserID: '電話番号',
  LoginACSPwd: 'パスワード',
  LoginACSLogin: 'ログイン',
  LoginACSFrgtPwd: '忘れた パスワード ?',
  LoginACSVersn: 'バージョン',
  HomeTitle: 'ホーム',
  HomePull: '引くにリフレッシュする引',
  HomeGM: 'おはようございます',
  HomeTxt: 'あなたの答えはあなたが参加している研究の研究にとって非常に重要です。',
  HomeCompli: '各期間のコンプライアンス',
  HomeTotal: '合計',
  HomeToday: '今日',
  HomeCrntWeek: '今週',
  HomeCrntMon: '今月',
  Homemsgs: 'メッセージ',
  HomeNewmsgs: '新しいメッセージがあります',
  'Homemsgs sub txt': '新しいメッセージはありません',
  HomePatnt_Diary: '日記',
  HomePatnt_Diary_sub_txt: 'フォームに記入してください。',
  Calls: '電話',
  Chat: 'チャット',
  'HomeQlty of Life': '健康トラッカー',
  'HomeQlty of Life sub txt': '最近の活動はありません',
  'HomeVst Remnders': '訪問のリマインダ',
  'HomeVst Remnders sub txt': '新しいリマインダーはありません',
  HomeTabHome: 'ホーム',
  HomeTabmsgs: 'メッセージ',
  Form: '形',
  HomeTabCalndr: 'カレンダー',
  MessagesTitle: 'メッセージ',
  'Patient DiaryTitle': '患者日記',
  'Visit CalendarTitle': 'カレンダーへ',
  'Qlty of LfeChoose optn': 'オプションを選択してください',
  'Qlty of LfeWithings': 'ウィッシング',
  'Qlty of LfeFitbit': 'フィットビット',
  WithingsTitle: 'アクティビティ',
  'WithingsActivity Tab': 'アクティビティ',
  'WithingsHeart Rate Tab': '心拍数',
  'WithingsHeart Rte TabHeart Rate': '心拍数',
  'WithingsHeart Rte TabThis week': '今週',
  'WithingsHeart Rte TabSubmit': '提出する',
  FitbitTitle: 'アクティビティ',
  'FitbitActivity Tab': 'アクティビティ',
  'FitbitHeart Rate Tab': '心拍数',
  'FitbitHeart Rte TabHeart  Rate': '心拍数',
  'FitbitHeart Rte TabThis week': '今週',
  'FitbitHeart Rte TabSubmit': '提出する',
  Actn_sheetMore: 'もっと',
  Actn_sheetStudy: '調査',
  Actn_sheetContact: '接触',
  Actn_sheetFAQ: 'よくある質問',
  Actn_sheetChange_Language: '言語を選択する',
  ChangeLanguage: '言語を選択する',
  StudyTab1Title: '研究情報',
  StudyTab1Txt: '私たちの研究へようこそ スワイプして表示する',
  'StudyTab2Brf Dctn': '簡単な説明',
  'StudyTab3Detail Dctn': '詳細な説明',
  'StudyTab4Anticipated prblms': '予期される問題',
  'StudyTab5Withdrn rule': '引退規則',
  StudyTab6Video: 'ビデオ',
  'StudyTab6View Video': '研究に関するビデオを見る',
  StudyTab6Joined: 'すでに参加しています',
  ContactTitle: '連絡先',
  ContactSiteAddress: 'サイトアドレス',
  ContactPI: '主任研究員',
  FAQTitle: 'よくある質問',
  FieldPrevious: '前',
  FieldNext: '次',
  PainscaleText1: 'あなたの健康が今日どのように良いか悪いかを知りたいです。',
  PainscaleText2: '現在の健康状態を示すためにスケールをタップしてください。',
  PainscaleSubmit: '提出する',
  PainscaleHealthToday: '',
  PainscaleHealthToday1: 'あなた',
  PainscaleHealthToday2: 'の健康',
  PainscaleHealthToday3: '今日',
  ClickableImageMessage: '画像をタップしてレスポンスを選択してください.',
  tmlnallday: '一日中',
  painmax: '最悪の可能な痛み',
  painmin: '痛み無し',
  completedat: '完了時 ',
  EventsforDay: '今日のイベント',
  NoEventsforDay: '選択した日付のイベントはありません。',
  TODAY: '今日',
  MONTH: '月',
  WEEK: '週間',
  DAY: '日',
  ChangeTheme: 'テーマを変更',
  MessageSent: '送信済み',
  MessageRead: '読む',
  EventCalendar: 'イベントカレンダー',
  Logout: 'ログアウト',
  USRNAMEWARNING: '電話番号を入力してください。',
  PWDWARNING: 'パスワードを入力してください。',
  NoFormToFill: '選択した日付に使用可能なフォームがありません。',
  PIN: '端子',
  EnterOTP: 'ワンタイムパスワードを入力',
  OtpEmpty: 'OTPはならない。',
  WrongOTP: '有効なOTPを入力してください。',
  VerifyOTP: 'OTPを確認',
  ResendOTP: 'ワンタイムパスワード再送信',
  NumberValidate1: '入力値と',
  NumberValidate2: 'や',
  ResetPassword: 'パスワードのリセット',
  ChangePassword: 'パスワードを変更する',
  NewPassword: '新しいパスワード',
  ConfirmPassword: 'パスワードを確認する',
  PSWD_NOT_MATCH: 'パスワードが間違っています。',
  PSWD_VALIDATION: 'パスワードが条件と一致しませんでした。',
  PSWD_MIN_LENGTH: '- パスワードは少なくとも8文字です。',
  PSWD_MAX_LENGTH: '- パスワードは 64 文字を超えてはなりません。',
  PSWD_ONE_NUMBER: '- パスワードには少なくとも 1 つの数字を含める必要があります。',
  PSWD_ONE_SC: '- パスワードには少なくとも 1 つの特殊文字を含める必要があります。',
  PSWD_ONE_UPPER: '- パスワードには少なくとも 1 つの大文字を含める必要があります。',
  PSWD_NO_SPACES: '- パスワードにはスペースを含めないでください。',
  PSWD_RESTRICT: '- パスワードには連続文字や繰り返し文字を使用しないでください。',
  PSWD_NO_USERNAME: '- パスワードにはユーザー名を含めないでください。',
  PSWD_NO_EMAILNAME: '- パスワードには電子メール アカウント名を含めないでください。',
  PSWD_NO_SITENAME: '- パスワードにはサイト名を含めないでください。',
  PSWD_NO_ORGNAME: '- パスワードには組織名を含めないでください',
  PSWD_NOTE: 'パスワード基準:',
  Save: '保存',
  ForgotPSWD: 'パスワードを忘れたら？',
  OTPMSG: 'まSMS携帯電話番号とワンタイムパスワードをリセットパスワードを入力して下さい。',
  FP_PNO: 'の提出をご登録の電話番号国コードです。',
  PNO_Vald: '電話番号はならない。',
  Submit: '提出',
  Cancel: '消',
  Prev: '前',
  Next: '次へ',
  NoPain: '疼痛',
  Mild: '穏やかな',
  Moderate: '緩やかな',
  Severe: '厳しい',
  Worst: '悪',
  SetAppLock: 'パスコードを設定',
  AppLock: 'アプリロック',
  EnterPIN: '入力端子',
  MinPin: 'PIN は 4 ～ 6 桁である必要があります',
  WrongPIN: '間違ったピン!',
  PINS_NOT_MATCH: 'PINが一致しませんでした。',
  ConfirmPIN: '確認端子',
  OK: 'わかりました',
  Reset: 'リセット',
  Confirm: '確認',
  SelTimezone: 'タイムゾーンの選択',
  CompletedOn: '完了',
  BScaleValMsg: '価痛。',
  BarcodeValMsg: '続行するにはバーコードをスキャンしてください',
  DateValMsg: 'を選択してください。',
  SingSelValMsg: 'を選択してください。',
  MultSelValMsg: 'を選択してください少なくとも一つのオプションです。',
  TextValMsg: 'ご記入ください。',
  PswdEmpty: 'パスワードを入力してください。',
  CnfPswdEmpty: 'パスワードを確認してください。',
  SelectTimezone: 'タイムゾーンを選択',
  Timezone: 'タイムゾーン',
  LoginPhPW: '電話番号とパスワードでログインします',
  PhoneNotReg: 'あなたの電話番号は登録されていません。 番号を確認してもう一度やり直してください。',
  SomethingWrong: '何か問題がありました。 あなたの研究者に連絡してください。',
  PswdResetSuccess: 'パスワードが正常にリセットされました。',
  PswdResetFailure: 'パスワードのリセットに失敗しました',
  SavedMessage: '正常に保存されました。',
  FailedMessage: '保存できませんでした。しばらくしてからもう一度お試しください。',
  FailedRetrieve: '検索に失敗しました。',
  FailedResetPWD: 'パスワードのリセットに失敗しました',
  FailedRetrieveMsgs: 'メッセージを取得できません。',
  FailedRetrieveFaqs: 'FAQの取得に失敗しました。',
  FailedRetrieveFields: 'フィールドを取得できません。',
  FailedRetrieveForms: 'フォームを取得できません。',
  FailedRetrieveTimezones: 'タイムゾーンの取得に失敗しました',
  FailedRetrieveSubjects: 'トピックを取得できません。',
  FailedChangeLanguage: '言語の変更に失敗しました。',
  FailedUpdateTimeZone: 'タイムゾーンの更新に失敗しました',
  NetworkError: 'ネットワークエラー。',
  LogoutMessage: 'あなたはezproからログアウトしています。続行しますか？',
  YES: 'はい',
  NO: 'いいえ',
  InvPhonePSWD: '電話番号またはパスワードが無効です。',
  NotValidPhone: 'あなたは有効な電話番号を入力しませんでした。',
  ReqNumericField: 'この数値フィールドに入力してください。',
  OTPResend: 'OTP再送信',
  SelectDate: '日付を選択',
  EnterHere: 'ここに入力',
  NOMSG: '表示するメッセージはありません。',
  NoData: 'データなし',
  UserLocked: 'ユーザーがロックされています。管理者に連絡してください。',
  LanguageValidate: '言語を選択してください',
  OfflineMessage: 'ネットワーク接続が失われました。オフラインモードに切り替えます。',
  Offline: 'オフライン',
  Online: 'オンライン',
  savedLocally: 'ローカルに保存されました。',
  formsToSync: '同期するフォーム',
  formToSync: '同期するフォーム',
  showingOfflineForms: 'オフラインデータの表示。 インターネット接続を確認してください。',
  NoForm: '記入するフォームはありません',
  NoFaqs: 'よくある質問はありません。',
  HealthMenu: '健康データ',
  myProfile: '私のプロフィール',
  subjectInformation: '主題に関する情報',
  studyInformation: '学習情報',
  versionInformation: 'バージョン情報',
  LoggedUser: 'ユーザーがログインしました',
  StudyDetails: '勉強',
  VersionDetails: 'バージョン情報',
  LoggedInUser: 'ユーザーがログインしました',
  Study: '勉強',
  VersionDetails: 'リリース詳細',
  Syncing: '同期しています。 。 。',
  SyncSuccess: '同期しました。',
  MaxAttemptsDone: '最大試行回数が完了しました。',
  LastSynced: '最終同期',
  bmi: 'ボディ・マス・インデックス',
  Height: '高さ',
  Weight: '重量',
  LeanBodyMass: 'リーンボディマス',
  BodyFat: '体脂肪率',
  Activity: 'アクティビティ',
  Vitals: 'バイタル',
  Biometrics: 'バイオメトリクス',
  HeartRate: '心拍数',
  BloodPressure: '血圧',
  RespirationRate: '呼吸数',
  BodyTemperature: '体温',
  BloodGlucose: '血糖',
  Sex: '性別',
  SleepAnalysis: '睡眠分析',
  ActiveEnergy: 'アクティブエネルギー',
  FlightsClimbed: '上昇したフライト',
  Distance: '距離',
  Steps: '手順',
  StepUnits: '歩数',
  DistanceUnits: 'マイル',
  FloorUnits: '床',
  ActiveEnergyUnits: 'カロリー',
  SleepAnalysisUnits: '時間',
  MeetingSchedule: '会議スケジュール',
  UpcomingCalls: '今後の通話',
  PastCalls: '過去の電話',
  NoCallScheduled: '現在、予定されている通話はありません',
  NoPastCalls: '過去の電話はありません',
  NoInternet: 'インターネットに接続されていません。',
  NoChats: '表示するチャットはありません。',
  SelectChat: 'チャットを選択するか、新しいチャットを開始してください。',
  NoUsersFound: 'ユーザが見つかりませんでした',
  Done: 'できた',
  AddParticipants: '参加者を追加',
  SearchParticipants: '参加者を検索',
  LeaveGroup: 'グループを脱退',
  Participants: '参加者',
  participants: '参加者',
  Remove: '削除する',
  Others: 'その他',
  Add: '追加',
  Notifications: '通知',
  CallDetails: '通話の詳細',
  Participants: '参加者',
  CallHistory: '通話履歴',
  JoinCall: '通話に参加',
  MissedCall: 'あなたはこの電話に出られませんでした。',
  CallInProgress: '通話中です。',
  CallNotStarted: '通話はまだ開始されていません。',
  JoinedOn: '参加しました',
  LeftOn: 'そのままにしておきます',
  Duration: 'デュレーション',
  ScheduledDuration: '予定期間',
  Title: '題名',
  StartedOn: '開始',
  Status: '状態',
  ScheduledTime: '予定時間',
  Alerts: 'アラート',
  Reminders: 'リマインダー',
  Notifications: '通知',
  VersionInfo: 'バージョン情報',
  FormPreview: 'フォームのプレビュー',
  NRSNumber: '番号を選択してください。',
  SubmitTraining: 'トレーニングフォームを送信する',
  TrainingNotCompleted: '日記を始める前にトレーニングを完了してください。',
  StartTraining: 'トレーニングを開始する',
  ViewCompletedTraining: '完了したトレーニングを表示する',
  CompleteTraining: '日記フォームにアクセスするには、トレーニングを完了してください。',
  Training: '研修',
  SITESTAFFUSRNAMEWARNING: 'ユーザー名を入力してください',
  SITESTAFFPINWARNING: 'PINを入力してください',
  LoginSiteStaffUsrName: 'ユーザー名',
  LoginSiteStaffPin: 'ピン',
  InvUsrNmPIN: '無効なユーザー名またはPIN',
  InvPasscode: '有効なサイト識別子を入力してください。',
  EnterPasscode: 'TRYALサイト識別子の入力',
  EnterUsername: 'ユーザーネームを入力してください',
  EnterPassword: 'パスワードを入力する',
  EnterValidUname: '有効なユーザー名を入力してください',
  EnterValidPassword: '有効なパスワードを入力してください',
  FirstName: 'ファーストネーム',
  LastName: '姓',
  UserID: 'ユーザーID',
  Site: 'サイト',
  MyAccount: '私のアカウント',
  StudyList: '研究リスト',
  SubjectList: '件名一覧',
  SelectFormCategory: 'フォームのカテゴリを選択してください',
  LoginWithPIN: 'ユーザー名とPINを使用してログインします',
  VersionInfo: 'バージョン情報',
  Email: 'Eメール',
  SessionTimeoutMessage: 'セッションがタイムアウトしました',
  FillRequiredFields: 'すべての必須フィールドに入力してください',
  InvalidPasscode: '無効なサイト識別子です。',
  SomethingWentWrong: '何か問題が発生しました。',
  InvalidUsernamePassword: 'ユーザー名かパスワードが無効。',
  InvalidUsernamePasswordWeb:
    '<p style="font-weight:normal"><b>ユーザー名かパスワードが無効。</b><br/>あなたが参加者または介護者の場合は、下のリンクをクリックして参加者のログイン ページにアクセスしてください。</p>',
  ScheduleStartDate: 'スケジュール開始日',
  ScheduleEndDate: 'スケジュール終了日',
  VisitNotStarted: '訪問ウィンドウはまだ開始されていません。',
  VisitWindowClosed: '訪問ウィンドウが過ぎました、あなたはデータを入力できません。',
  VisitCompleted: '訪問が完了しました。アンケートを更新することはできません。',
  ShowAlertMsg: '変更を保存しますか？',
  firstName: 'ファーストネーム',
  lastName: '苗字',
  email: 'Eメール',
  phone: '電話',
  language: '言語',
  study: '勉強',
  sponsor: 'スポンサー',
  versionName: 'バージョン名',
  Visit: '訪問',
  StartTime: '始まる時間',
  EndTime: '終了時間',
  VersionUpgradeInfo: 'eDiaryバージョンがバージョンにアップグレードされました',
  New: '新しい',
  NoDiary: '日記/アンケートはありません。サイトスタッフにお問い合わせください',
  UserInactive: 'アクセスが無効になります。スタディコーディネーターに連絡してください。',
  SubjectInactive:
    'サブジェクトへのアクセスは無効化されています。スタディコーディネーターに連絡してください.',
  ProcessMessage: 'アンケートの処理。',
  LoadingCallData: '通話データを読み込んでいます',
  DownloadMetadata: 'メタデータのダウンロード',
  DownloadStudydata: '研究データをダウンロードしています。',
  DownloadQuestionnaries: 'アンケートのダウンロード',
  FailedRetrieveStudy: 'スタディの詳細をダウンロードできませんでした。',

  SubjectCaregiverInactive:
    '件名はアクティブではありません。詳細については、研究コーディネーターにお問い合わせください',
  OnceFrequencyScheduleStartDate: 'スケジュール開始日',
  OnceFrequencyScheduleEndDate: '予定終了日',
  Click: 'クリック',
  LoginText: '再度ログインするには',
  TermsAndConditions: 'プライバシーポリシーと利用規約',
  TermsNConditionsCancel:
    'このアプリケーションの使用を続けるには、プライバシー ポリシーと利用規約に同意する必要があります',
  UnsyncedData:
    'このデバイスには同期されていないデータがあります。 データを同期するまではログアウトできません。 ご質問がある場合は、サイトにお問い合わせください。',
  ChangeToClinician: '臨床医ログインへの変更',
  ParticipantLogin: '参加者ログイン',
  ClinicianLogin: '臨床医ログイン',
  SwitchToParticipant: '参加者に切り替える',
  OrgText1: '別の組織にログインしたい場合は、',
  OrgText2: '管理ポータルのリンクにアクセスしてください',
  BackToLogin: 'ログインに戻る',
  GoToPortal:
    'アカウントに対して必要なアクションがあります。 アカウントの操作を完了するには、管理者ポータルからログインしてください。',
  Organization: '組織',
  NoClinroAccessPrivilege:
    'ClinRo データを入力するアクセス権がありません。 ご質問がある場合は、CRA または管理者にお問い合わせください。',
  DeviceTimezoneMismatchTitle:
    'お使いのデバイスのタイムゾーンがこのアプリで設定されているタイムゾーンと一致していないようです。',
  DeviceTimezoneMismatchSubtitle: 'タイムゾーンを変更しますか？',
  DeviceTimezoneMismatchTzConfigured: '設定されているタイムゾーン:',
  DeviceTimezoneMismatchTzInferred: 'お使いのデバイスのタイムゾーン:',
  SiteIdentifierInfo: '<p>トライアルを見つけてください  <b>サイト識別子 </b> で 主題リストページのポータル</p>',
  InvPhonePSWDWeb:'<p style="font-weight:normal"><b>電話番号またはパスワードが無効です。</b><br/>臨床医の場合は、下のリンクをクリックして臨床医のログイン ページにアクセスしてください。</p>',
  LoginUnsyncData: 'デバイス上で同期する必要があるデータがあります。 データを転送できるようにパスワードを入力してください。 ご質問がある場合はサポートまでお問い合わせください。',
  DeviceTimezoneNoClicked: 'タイムゾーンは、この画面の下部にある[その他]に移動していつでも変更できます。',
  AgreeingToPrivacypolicy: 'プライバシー ポリシーと利用規約への同意は必須です。',
  ReadAndAgree: '利用規約を読んで同意します',
  IAgree: '同意します',
  SubjectNumber: '被験者番号',
  Version: 'バージョン',
  Back: '戻る',
  Visits: '訪問',
  DownloadImageFailedDeviceOffline: 'この日記には、デバイスに不完全にダウンロードされた画像が含まれています。インターネットに接続して、この日記を再度選択してください。',
  DownloadImageFailedNoEnoughSpace: 'デバイスに 1 つ以上の日記をダウンロードするのに十分なスペースがありません。 スペースを解放して、もう一度お試しください。',
  ImageLoading: '画像を読み込んでいます...',
  Barcode:'バーコード',
  BarcodeMessage:'バーコード スキャンは現在 Web ではサポートされていません。バーコードをスキャンするにはモバイルデバイスを使用してください',
  ScanAgain:'もう一度スキャン',
  SelectedValue:'選択された値'

}
