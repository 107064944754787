import createActionType from '../utils/action'
import api from '../utils/api'

export const RETRIEVE_VISITS_REQUEST = createActionType('RETRIEVE_VISITS_REQUEST')
export const RETRIEVE_VISITS_SUCCESS = createActionType('RETRIEVE_VISITS_SUCCESS')
export const RETRIEVE_VISITS_FAILURE = createActionType('RETRIEVE_VISITS_FAILURE')

export const UPDATE_SELECTED_VISIT = createActionType('UPDATE_SELECTED_VISIT')

const retrieveVisitsRequest = () => ({
  type: RETRIEVE_VISITS_REQUEST,
})

const retrieveVisitsFailure = (error) => ({
  type: RETRIEVE_VISITS_FAILURE,
  message: error.message,
})

const retrieveVisitsSuccess = (res) => ({
  type: RETRIEVE_VISITS_SUCCESS,
  visits: res.data,
})

export const updateSelectedVisit = (selectedVisit) => ({
  type: UPDATE_SELECTED_VISIT,
  selectedVisit,
})

