export default {
    "Africa/Abidjan": "アフリカ/アビジャン",
"Africa/Accra": "アフリカ/アクラ",
"Africa/Addis_Ababa": "アフリカ/アディスアベバ",
"Africa/Algiers": "アフリカ/アルジェ",
"Africa/Asmara": "アフリカ/アスマラ",
"Africa/Asmera": "アフリカ/アスメラ",
"Africa/Bamako": "アフリカ/バマコ",
"Africa/Bangui": "アフリカ/バンギ",
"Africa/Banjul": "アフリカ/バンジュール",
"Africa/Bissau": "アフリカ/ビサウ",
"Africa/Blantyre": "アフリカ/ブランタイア",
"Africa/Brazzaville": "アフリカ/ブラザビル",
"Africa/Bujumbura": "アフリカ/ブジュンブラ",
"Africa/Cairo": "アフリカ/カイロ",
"Africa/Casablanca": "アフリカ/カサブランカ",
"Africa/Ceuta": "アフリカ/セウタ",
"Africa/Conakry": "アフリカ/コナクリ",
"Africa/Dakar": "アフリカ/ダカール",
"Africa/Dar_es_Salaam": "アフリカ/ダルエスサラーム",
"Africa/Djibouti": "アフリカ/ジブチ",
"Africa/Douala": "アフリカ/ドゥアラ",
"Africa/El_Aaiun": "アフリカ/エル・アイウン",
"Africa/Freetown": "アフリカ/フリータウン",
"Africa/Gaborone": "アフリカ/ハボローネ",
"Africa/Harare": "アフリカ/ハラレ",
"Africa/Johannesburg": "アフリカ/ヨハネスブルグ",
"Africa/Juba": "アフリカ/ジュバ",
"Africa/Kampala": "アフリカ/カンパラ",
"Africa/Khartoum": "アフリカ/ハルツーム",
"Africa/Kigali": "アフリカ/キガリ",
"Africa/Kinshasa": "アフリカ/キンシャサ",
"Africa/Lagos": "アフリカ/ラゴス",
"Africa/Libreville": "アフリカ/リーブルビル",
"Africa/Lome": "アフリカ/ロメ",
"Africa/Luanda": "アフリカ/ルアンダ",
"Africa/Lubumbashi": "アフリカ/ルブンバシ",
"Africa/Lusaka": "アフリカ/ルサカ",
"Africa/Malabo": "アフリカ/マラボ",
"Africa/Maputo": "アフリカ/マプト",
"Africa/Maseru": "アフリカ/マセル",
"Africa/Mbabane": "アフリカ/ムババネ",
"Africa/Mogadishu": "アフリカ/モガディシュ",
"Africa/Monrovia": "アフリカ/モンロビア",
"Africa/Nairobi": "アフリカ/ナイロビ",
"Africa/Ndjamena": "アフリカ/ンジャメナ",
"Africa/Niamey": "アフリカ/ニアメ",
"Africa/Nouakchott": "アフリカ/ヌアクショット",
"Africa/Ouagadougou": "アフリカ/ワガドゥグー",
"Africa/Porto-Novo": "アフリカ/ポルトノボ",
"Africa/Sao_Tome": "アフリカ/サントメ",
"Africa/Timbuktu": "アフリカ/ティンブクトゥ",
"Africa/Tripoli": "アフリカ/トリポリ",
"Africa/Tunis": "アフリカ/チュニス",
"Africa/Windhoek": "アフリカ/ウィントフック",
"America/Adak": "アメリカ/アダック",
"America/Anchorage": "アメリカ/アンカレッジ",
"America/Anguilla": "アメリカ/アングィラ",
"America/Antigua": "アメリカ/アンティグア",
"America/Araguaina": "アメリカ/アラグァイーナ",
"America/Argentina/Buenos_Aires": "アメリカ/アルゼンチン/ブエノスアイレス",
"America/Argentina/Catamarca": "アメリカ/アルゼンチン/カタマルカ",
"America/Argentina/ComodRivadavia": "アメリカ/アルゼンチン/コモドリバダビア",
"America/Argentina/Cordoba": "アメリカ/アルゼンチン/コルドバ",
"America/Argentina/Jujuy": "アメリカ/アルゼンチン/7月",
"America/Argentina/La_Rioja": "アメリカ/アルゼンチン/ラ_リオハ",
"America/Argentina/Mendoza": "アメリカ/アルゼンチン/メンドーサ",
"America/Argentina/Rio_Gallegos": "アメリカ/アルゼンチン/リオ・ガレゴス",
"America/Argentina/Salta": "アメリカ/アルゼンチン/サルタ",
"America/Argentina/San_Juan": "アメリカ/アルゼンチン/サンフアン",
"America/Argentina/San_Luis": "アメリカ/アルゼンチン/サンルイス",
"America/Argentina/Tucuman": "アメリカ/アルゼンチン/トゥクマン",
"America/Argentina/Ushuaia": "アメリカ/アルゼンチン/ウシュアイア",
"America/Aruba": "アメリカ/アルバ",
"America/Asuncion": "アメリカ/アスンシオン",
"America/Atikokan": "アメリカ/アティコーカン",
"America/Atka": "アメリカ/アトカ",
"America/Bahia": "アメリカ/バイア",
"America/Bahia_Banderas": "アメリカ/バイア_バンデラス",
"America/Barbados": "アメリカ/バルバドス",
"America/Belem": "アメリカ/ベレン",
"America/Belize": "アメリカ/ベリーズ",
"America/Blanc-Sablon": "アメリカ/ブランサブロン",
"America/Boa_Vista": "アメリカ/ボアビスタ",
"America/Bogota": "アメリカ/ボゴタ",
"America/Boise": "アメリカ/ボイジー",
"America/Buenos_Aires": "アメリカ/ブエノスアイレス",
"America/Cambridge_Bay": "アメリカ/ケンブリッジベイ",
"America/Campo_Grande": "アメリカ/カンポグランデ",
"America/Cancun": "アメリカ/カンクン",
"America/Caracas": "アメリカ/カラカス",
"America/Catamarca": "アメリカ/カタマルカ",
"America/Cayenne": "アメリカ/カイエン",
"America/Cayman": "アメリカ/ケイマン",
"America/Chicago": "アメリカ/シカゴ",
"America/Chihuahua": "アメリカ/チワワ",
"America/Ciudad_Juarez": "アメリカ/シウダー・フアレス",
"America/Coral_Harbour": "アメリカ/コーラルハーバー",
"America/Cordoba": "アメリカ/コルドバ",
"America/Costa_Rica": "アメリカ/コスタリカ",
"America/Creston": "アメリカ/クレストン",
"America/Cuiaba": "アメリカ/クイアバ",
"America/Curacao": "アメリカ/キュラソー",
"America/Danmarkshavn": "アメリカ/デンマークシャウン",
"America/Dawson": "アメリカ/ドーソン",
"America/Dawson_Creek": "アメリカ/ドーソンクリーク",
"America/Denver": "アメリカ/デンバー",
"America/Detroit": "アメリカ/デトロイト",
"America/Dominica": "アメリカ/ドミニカ",
"America/Edmonton": "アメリカ/エドモントン",
"America/Eirunepe": "アメリカ/エイルネペ",
"America/El_Salvador": "アメリカ/エルサルバドル",
"America/Ensenada": "アメリカ/エンセナーダ",
"America/Fort_Nelson": "アメリカ/フォートネルソン",
"America/Fort_Wayne": "アメリカ/フォートウェイン",
"America/Fortaleza": "アメリカ/フォルタレザ",
"America/Glace_Bay": "アメリカ/グレースベイ",
"America/Godthab": "アメリカ/ゴッドタブ",
"America/Goose_Bay": "アメリカ/グースベイ",
"America/Grand_Turk": "アメリカ/グランドターク",
"America/Grenada": "アメリカ/グレナダ",
"America/Guadeloupe": "アメリカ/グアドループ",
"America/Guatemala": "アメリカ/グアテマラ",
"America/Guayaquil": "アメリカ/グアヤキル",
"America/Guyana": "アメリカ/ガイアナ",
"America/Halifax": "アメリカ/ハリファックス",
"America/Havana": "アメリカ/ハバナ",
"America/Hermosillo": "アメリカ/エルモシヨ",
"America/Indiana/Indianapolis": "アメリカ/インディアナ/インディアナポリス",
"America/Indiana/Knox": "アメリカ/インディアナ/ノックス",
"America/Indiana/Marengo": "アメリカ/インディアナ/マレンゴ",
"America/Indiana/Petersburg": "アメリカ/インディアナ/ピーターズバーグ",
"America/Indiana/Tell_City": "アメリカ/インディアナ/テルシティ",
"America/Indiana/Vevay": "アメリカ/インディアナ/ビベイ",
"America/Indiana/Vincennes": "アメリカ/インディアナ/ヴァンセンヌ",
"America/Indiana/Winamac": "アメリカ/インディアナ/ウィナマック",
"America/Indianapolis": "アメリカ/インディアナポリス",
"America/Inuvik": "アメリカ/イヌビク",
"America/Iqaluit": "アメリカ/イカルイト",
"America/Jamaica": "アメリカ/ジャマイカ",
"America/Jujuy": "アメリカ/7月",
"America/Juneau": "アメリカ/ジュノー",
"America/Kentucky/Louisville": "アメリカ/ケンタッキー/ルイビル",
"America/Kentucky/Monticello": "アメリカ/ケンタッキー/モンティセロ",
"America/Knox_IN": "アメリカ/ノックスイン",
"America/Kralendijk": "アメリカ/クラレンダイク",
"America/La_Paz": "アメリカ/ラパス",
"America/Lima": "アメリカ/リマ",
"America/Los_Angeles": "アメリカ/ロサンゼルス",
"America/Louisville": "アメリカ/ルイビル",
"America/Lower_Princes": "アメリカ/ローワープリンセス",
"America/Maceio": "アメリカ/マセイオ",
"America/Managua": "アメリカ/マナグア",
"America/Manaus": "アメリカ/マナウス",
"America/Marigot": "アメリカ/マリゴ",
"America/Martinique": "アメリカ/マルティニーク",
"America/Matamoros": "アメリカ/マタモロス",
"America/Mazatlan": "アメリカ/マサトラン",
"America/Mendoza": "アメリカ/メンドーサ",
"America/Menominee": "アメリカ/メノミニー",
"America/Merida": "アメリカ/メリダ",
"America/Metlakatla": "アメリカ/メトラカトラ",
"America/Mexico_City": "アメリカ/メキシコシティ",
"America/Miquelon": "アメリカ/ミクロン",
"America/Moncton": "アメリカ/モンクトン",
"America/Monterrey": "アメリカ/モンテレイ",
"America/Montevideo": "アメリカ/モンテビデオ",
"America/Montreal": "アメリカ/モントリオール",
"America/Montserrat": "アメリカ/モントセラト",
"America/Nassau": "アメリカ/ナッソー",
"America/New_York": "アメリカ/ニューヨーク",
"America/Nipigon": "アメリカ/ニピゴン",
"America/Nome": "アメリカ/ノーム",
"America/Noronha": "アメリカ/ノローニャ",
"America/North_Dakota/Beulah": "アメリカ/ノースダコタ/ビューラ",
"America/North_Dakota/Center": "アメリカ/ノースダコタ/センター",
"America/North_Dakota/New_Salem": "アメリカ/ノースダコタ/ニューセーラム",
"America/Nuuk": "アメリカ/ヌーク",
"America/Ojinaga": "アメリカ/オヒナガ",
"America/Panama": "アメリカ/パナマ",
"America/Pangnirtung": "アメリカ/パングナータング",
"America/Paramaribo": "アメリカ/パラマリボ",
"America/Phoenix": "アメリカ/フェニックス",
"America/Port-au-Prince": "アメリカ/ポルトープランス",
"America/Port_of_Spain": "アメリカ/ポート・オブ・スペイン",
"America/Porto_Acre": "アメリカ/ポルトアクレ",
"America/Porto_Velho": "アメリカ/ポルトベリョ",
"America/Puerto_Rico": "アメリカ/プエルトリコ",
"America/Punta_Arenas": "アメリカ/プンタアレナス",
"America/Rainy_River": "アメリカ/レイニーリバー",
"America/Rankin_Inlet": "アメリカ/ランキンインレット",
"America/Recife": "アメリカ/レシフェ",
"America/Regina": "アメリカ/レジーナ",
"America/Resolute": "アメリカ/レゾリュート",
"America/Rio_Branco": "アメリカ/リオブランコ",
"America/Rosario": "アメリカ/ロザリオ",
"America/Santa_Isabel": "アメリカ/サンタイザベル",
"America/Santarem": "アメリカ/サンタレン",
"America/Santiago": "アメリカ/サンティアゴ",
"America/Santo_Domingo": "アメリカ/サントドミンゴ",
"America/Sao_Paulo": "アメリカ/サンパウロ",
"America/Scoresbysund": "アメリカ/スコアズビサンド",
"America/Shiprock": "アメリカ/シップロック",
"America/Sitka": "アメリカ/シトカ",
"America/St_Barthelemy": "アメリカ/セントバーセレミー",
"America/St_Johns": "アメリカ/セントジョンズ",
"America/St_Kitts": "アメリカ/セントキッツ",
"America/St_Lucia": "アメリカ/セントルシア",
"America/St_Thomas": "アメリカ/セントトーマス",
"America/St_Vincent": "アメリカ/セントビンセント",
"America/Swift_Current": "アメリカ/スイフト・カレント",
"America/Tegucigalpa": "アメリカ/テグシガルパ",
"America/Thule": "アメリカ/チューレ",
"America/Thunder_Bay": "アメリカ/サンダーベイ",
"America/Tijuana": "アメリカ/ティファナ",
"America/Toronto": "アメリカ/トロント",
"America/Tortola": "アメリカ/トルトーラ",
"America/Vancouver": "アメリカ/バンクーバー",
"America/Virgin": "アメリカ/ヴァージン",
"America/Whitehorse": "アメリカ/ホワイトホース",
"America/Winnipeg": "アメリカ/ウィニペグ",
"America/Yakutat": "アメリカ/ヤクタット",
"America/Yellowknife": "アメリカ/イエローナイフ",
"Antarctica/Casey": "南極大陸/ケーシー",
"Antarctica/Davis": "南極大陸/デイビス",
"Antarctica/DumontDUrville": "南極大陸/デュモンデュルビル",
"Antarctica/Macquarie": "南極大陸/マッコーリー",
"Antarctica/Mawson": "南極大陸/モーソン",
"Antarctica/McMurdo": "南極大陸/マクマード",
"Antarctica/Palmer": "南極大陸/パーマー",
"Antarctica/Rothera": "南極大陸/ロセラ",
"Antarctica/South_Pole": "南極大陸/南極",
"Antarctica/Syowa": "南極大陸/昭和",
"Antarctica/Troll": "南極大陸/トロール",
"Antarctica/Vostok": "南極大陸/ボストーク",
"Arctic/Longyearbyen": "北極/ロングイェールビーン",
"Asia/Aden": "アジア/アデン",
"Asia/Almaty": "アジア/アルマトイ",
"Asia/Amman": "アジア/アンマン",
"Asia/Anadyr": "アジア/アナディル",
"Asia/Aqtau": "アジア/アクタウ",
"Asia/Aqtobe": "アジア/アクトーベ",
"Asia/Ashgabat": "アジア/アシガバート",
"Asia/Ashkhabad": "アジア/アシュハバード",
"Asia/Atyrau": "アジア/アティラウ",
"Asia/Baghdad": "アジア/バグダッド",
"Asia/Bahrain": "アジア/バーレーン",
"Asia/Baku": "アジア/バクー",
"Asia/Bangkok": "アジア/バンコク",
"Asia/Barnaul": "アジア/バルナウル",
"Asia/Beirut": "アジア/ベイルート",
"Asia/Bishkek": "アジア/ビシュケク",
"Asia/Brunei": "アジア/ブルネイ",
"Asia/Calcutta": "アジア/カルカッタ",
"Asia/Chita": "アジア/知多",
"Asia/Choibalsan": "アジア/チョイバルサン",
"Asia/Chongqing": "アジア/重慶",
"Asia/Chungking": "アジア/重慶市",
"Asia/Colombo": "アジア/コロンボ",
"Asia/Dacca": "アジア/ダッカ",
"Asia/Damascus": "アジア/ダマスカス",
"Asia/Dhaka": "アジア/ダッカ",
"Asia/Dili": "アジア/ディリ",
"Asia/Dubai": "アジア/ドバイ",
"Asia/Dushanbe": "アジア/ドゥシャンベ",
"Asia/Famagusta": "アジア/ファマグスタ",
"Asia/Gaza": "アジア/ガザ",
"Asia/Harbin": "アジア/ハルビン",
"Asia/Hebron": "アジア/ヘブロン",
"Asia/Ho_Chi_Minh": "アジア/ホーチミン",
"Asia/Hong_Kong": "アジア/香港",
"Asia/Hovd": "アジア/ホブド",
"Asia/Irkutsk": "アジア/イルクーツク",
"Asia/Istanbul": "アジア/イスタンブール",
"Asia/Jakarta": "アジア/ジャカルタ",
"Asia/Jayapura": "アジア/ジャヤプラ",
"Asia/Jerusalem": "アジア/エルサレム",
"Asia/Kabul": "アジア/カブール",
"Asia/Kamchatka": "アジア/カムチャッカ",
"Asia/Karachi": "アジア/カラチ",
"Asia/Kashgar": "アジア/カシュガル",
"Asia/Kathmandu": "アジア/カトマンズ",
"Asia/Katmandu": "アジア/カトマンズ",
"Asia/Khandyga": "アジア/ハンディガ",
"Asia/Kolkata": "アジア/コルカタ",
"Asia/Krasnoyarsk": "アジア/クラスノヤルスク",
"Asia/Kuala_Lumpur": "アジア/クアラルンプール",
"Asia/Kuching": "アジア/クチン",
"Asia/Kuwait": "アジア/クウェート",
"Asia/Macao": "アジア/マカオ",
"Asia/Macau": "アジア/マカオ",
"Asia/Magadan": "アジア/マガダン",
"Asia/Makassar": "アジア/マカッサル",
"Asia/Manila": "アジア/マニラ",
"Asia/Muscat": "アジア/マスカット",
"Asia/Nicosia": "アジア/ニコシア",
"Asia/Novokuznetsk": "アジア/ノヴォクズネツク",
"Asia/Novosibirsk": "アジア/ノボシビルスク",
"Asia/Omsk": "アジア/オムスク",
"Asia/Oral": "アジア/オーラル",
"Asia/Phnom_Penh": "アジア/プノンペン",
"Asia/Pontianak": "アジア/ポンティアナック",
"Asia/Pyongyang": "アジア/平壌",
"Asia/Qatar": "アジア/カタール",
"Asia/Qostanay": "アジア/コスタネイ",
"Asia/Qyzylorda": "アジア/クズロルダ",
"Asia/Rangoon": "アジア/ラングーン",
"Asia/Riyadh": "アジア/リヤド",
"Asia/Saigon": "アジア/サイゴン",
"Asia/Sakhalin": "アジア/サハリン",
"Asia/Samarkand": "アジア/サマルカンド",
"Asia/Seoul": "アジア/ソウル",
"Asia/Shanghai": "アジア/上海",
"Asia/Singapore": "アジア/シンガポール",
"Asia/Srednekolymsk": "アジア/スレドネコリムスク",
"Asia/Taipei": "アジア/台北",
"Asia/Tashkent": "アジア/タシケント",
"Asia/Tbilisi": "アジア/トビリシ",
"Asia/Tehran": "アジア/テヘラン",
"Asia/Tel_Aviv": "アジア/テルアビブ",
"Asia/Thimbu": "アジア/ティンブ",
"Asia/Thimphu": "アジア/ティンプー",
"Asia/Tokyo": "アジア/東京",
"Asia/Tomsk": "アジア/トムスク",
"Asia/Ujung_Pandang": "アジア/ウジュンパンダン",
"Asia/Ulaanbaatar": "アジア/ウランバートル",
"Asia/Ulan_Bator": "アジア/ウランバートル",
"Asia/Urumqi": "アジア/ウルムチ",
"Asia/Ust-Nera": "アジア/ウストネラ",
"Asia/Vientiane": "アジア/ビエンチャン",
"Asia/Vladivostok": "アジア/ウラジオストク",
"Asia/Yakutsk": "アジア/ヤクーツク",
"Asia/Yangon": "アジア/ヤンゴン",
"Asia/Yekaterinburg": "アジア/エカテリンブルク",
"Asia/Yerevan": "アジア/エレバン",
"Atlantic/Azores": "アトランティック/アゾレス",
"Atlantic/Bermuda": "アトランティック/バミューダ",
"Atlantic/Canary": "アトランティック/カナリア",
"Atlantic/Cape_Verde": "アトランティック/カーボベルデ",
"Atlantic/Faeroe": "アトランティック/フェロー",
"Atlantic/Faroe": "大西洋/フェロー",
"Atlantic/Jan_Mayen": "アトランティック/ヤンマイエン",
"Atlantic/Madeira": "アトランティック/マデイラ",
"Atlantic/Reykjavik": "アトランティック/レイキャビク",
"Atlantic/South_Georgia": "アトランティック/サウスジョージア",
"Atlantic/St_Helena": "アトランティック/セントヘレナ",
"Atlantic/Stanley": "アトランティック/スタンレー",
"Australia/ACT": "オーストラリア/ACT",
"Australia/Adelaide": "オーストラリア/アデレード",
"Australia/Brisbane": "オーストラリア/ブリスベン",
"Australia/Broken_Hill": "オーストラリア/ブロークンヒル",
"Australia/Canberra": "オーストラリア/キャンベラ",
"Australia/Currie": "オーストラリア/カリー",
"Australia/Darwin": "オーストラリア/ダーウィン",
"Australia/Eucla": "オーストラリア/ユークラ",
"Australia/Hobart": "オーストラリア/ホバート",
"Australia/LHI": "オーストラリア/LHI",
"Australia/Lindeman": "オーストラリア/リンデマン",
"Australia/Lord_Howe": "オーストラリア/ロード・ハウ",
"Australia/Melbourne": "オーストラリア/メルボルン",
"Australia/NSW": "オーストラリア/NSW",
"Australia/North": "オーストラリア/北部",
"Australia/Perth": "オーストラリア/パース",
"Australia/Queensland": "オーストラリア/クイーンズランド",
"Australia/South": "オーストラリア/南部",
"Australia/Sydney": "オーストラリア/シドニー",
"Australia/Tasmania": "オーストラリア/タスマニア",
"Australia/Victoria": "オーストラリア/ビクトリア",
"Australia/West": "オーストラリア/西部",
"Australia/Yancowinna": "オーストラリア/ヤンコウィナ",
"Brazil/Acre": "ブラジル/エーカー",
"Brazil/DeNoronha": "ブラジル/デノローニャ",
"Brazil/East": "ブラジル/東部",
"Brazil/West": "ブラジル/西部",
"CET": "セント",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "カナダ/大西洋",
"Canada/Central": "カナダ/中部",
"Canada/Eastern": "カナダ/東部",
"Canada/Mountain": "カナダ/マウンテン",
"Canada/Newfoundland": "カナダ/ニューファンドランド",
"Canada/Pacific": "カナダ/太平洋",
"Canada/Saskatchewan": "カナダ/サスカチュワン",
"Canada/Yukon": "カナダ/ユーコン",
"Chile/Continental": "チリ/コンチネンタル",
"Chile/EasterIsland": "チリ/イースター島",
"Cuba": "キューバ",
"EET": "食べる",
"EST": "ベスト",
"EST5EDT": "エスト5エディット",
"Egypt": "エジプト",
"Eire": "エール",
"Etc/GMT": "その他/GMT",
"Etc/GMT+0": "その他/GMT+0",
"Etc/GMT+1": "その他/GMT+1",
"Etc/GMT+10": "その他/GMT+10",
"Etc/GMT+11": "その他/GMT+11",
"Etc/GMT+12": "その他/GMT+12",
"Etc/GMT+2": "その他/GMT+2",
"Etc/GMT+3": "その他/GMT+3",
"Etc/GMT+4": "その他/GMT+4",
"Etc/GMT+5": "その他/GMT+5",
"Etc/GMT+6": "その他/GMT+6",
"Etc/GMT+7": "その他/GMT+7",
"Etc/GMT+8": "その他/GMT+8",
"Etc/GMT+9": "その他/GMT+9",
"Etc/GMT-0": "その他/GMT-0",
"Etc/GMT-1": "その他/GMT-1",
"Etc/GMT-10": "その他/GMT-10",
"Etc/GMT-11": "その他/GMT-11",
"Etc/GMT-12": "その他/GMT-12",
"Etc/GMT-13": "その他/GMT-13",
"Etc/GMT-14": "その他/GMT-14",
"Etc/GMT-2": "その他/GMT-2",
"Etc/GMT-3": "その他/GMT-3",
"Etc/GMT-4": "その他/GMT-4",
"Etc/GMT-5": "その他/GMT-5",
"Etc/GMT-6": "その他/GMT-6",
"Etc/GMT-7": "その他/GMT-7",
"Etc/GMT-8": "その他/GMT-8",
"Etc/GMT-9": "その他/GMT-9",
"Etc/GMT0": "その他/GMT0",
"Etc/Greenwich": "その他/グリニッジ",
"Etc/UCT": "その他/カット",
"Etc/UTC": "その他/UTC",
"Etc/Universal": "その他/ユニバーサル",
"Etc/Zulu": "その他/ズールー",
"Europe/Amsterdam": "ヨーロッパ/アムステルダム",
"Europe/Andorra": "ヨーロッパ/アンドラ",
"Europe/Astrakhan": "ヨーロッパ/アストラハン",
"Europe/Athens": "ヨーロッパ/アテネ",
"Europe/Belfast": "ヨーロッパ/ベルファスト",
"Europe/Belgrade": "ヨーロッパ/ベオグラード",
"Europe/Berlin": "ヨーロッパ/ベルリン",
"Europe/Bratislava": "ヨーロッパ/ブラチスラバ",
"Europe/Brussels": "ヨーロッパ/ブリュッセル",
"Europe/Bucharest": "ヨーロッパ/ブカレスト",
"Europe/Budapest": "ヨーロッパ/ブダペスト",
"Europe/Busingen": "ヨーロッパ/ビュージンゲン",
"Europe/Chisinau": "ヨーロッパ/キシナウ",
"Europe/Copenhagen": "ヨーロッパ/コペンハーゲン",
"Europe/Dublin": "ヨーロッパ/ダブリン",
"Europe/Gibraltar": "ヨーロッパ/ジブラルタル",
"Europe/Guernsey": "ヨーロッパ/ガーンジー",
"Europe/Helsinki": "ヨーロッパ/ヘルシンキ",
"Europe/Isle_of_Man": "ヨーロッパ/マン島",
"Europe/Istanbul": "ヨーロッパ/イスタンブール",
"Europe/Jersey": "ヨーロッパ/ジャージー",
"Europe/Kaliningrad": "ヨーロッパ/カリーニングラード",
"Europe/Kiev": "ヨーロッパ/キエフ",
"Europe/Kirov": "ヨーロッパ/キーロフ",
"Europe/Kyiv": "ヨーロッパ/キエフ",
"Europe/Lisbon": "ヨーロッパ/リスボン",
"Europe/Ljubljana": "ヨーロッパ/リュブリャナ",
"Europe/London": "ヨーロッパ/ロンドン",
"Europe/Luxembourg": "ヨーロッパ/ルクセンブルグ",
"Europe/Madrid": "ヨーロッパ/マドリード",
"Europe/Malta": "ヨーロッパ/マルタ",
"Europe/Mariehamn": "ヨーロッパ/マリハムン",
"Europe/Minsk": "ヨーロッパ/ミンスク",
"Europe/Monaco": "ヨーロッパ/モナコ",
"Europe/Moscow": "ヨーロッパ/モスクワ",
"Europe/Nicosia": "ヨーロッパ/ニコシア",
"Europe/Oslo": "ヨーロッパ/オスロ",
"Europe/Paris": "ヨーロッパ/パリ",
"Europe/Podgorica": "ヨーロッパ/ポドゴリツァ",
"Europe/Prague": "ヨーロッパ/プラハ",
"Europe/Riga": "ヨーロッパ/リガ",
"Europe/Rome": "ヨーロッパ/ローマ",
"Europe/Samara": "ヨーロッパ/サマラ",
"Europe/San_Marino": "ヨーロッパ/サンマリノ",
"Europe/Sarajevo": "ヨーロッパ/サラエボ",
"Europe/Saratov": "ヨーロッパ/サラトフ",
"Europe/Simferopol": "ヨーロッパ/シンフェロポリ",
"Europe/Skopje": "ヨーロッパ/スコピエ",
"Europe/Sofia": "ヨーロッパ/ソフィア",
"Europe/Stockholm": "ヨーロッパ/ストックホルム",
"Europe/Tallinn": "ヨーロッパ/タリン",
"Europe/Tirane": "ヨーロッパ/ティラネ",
"Europe/Tiraspol": "ヨーロッパ/ティラスポリ",
"Europe/Ulyanovsk": "ヨーロッパ/ウリヤノフスク",
"Europe/Uzhgorod": "ヨーロッパ/ウジゴロド",
"Europe/Vaduz": "ヨーロッパ/ファドゥーツ",
"Europe/Vatican": "ヨーロッパ/バチカン",
"Europe/Vienna": "ヨーロッパ/ウィーン",
"Europe/Vilnius": "ヨーロッパ/ビリニュス",
"Europe/Volgograd": "ヨーロッパ/ボルゴグラード",
"Europe/Warsaw": "ヨーロッパ/ワルシャワ",
"Europe/Zagreb": "ヨーロッパ/ザグレブ",
"Europe/Zaporozhye": "ヨーロッパ/ザポリージャ",
"Europe/Zurich": "ヨーロッパ/チューリッヒ",
"GB": "ギガバイト",
"GB-Eire": "GB-ワイア",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "グリニッジ",
"HST": "ホスト",
"Hongkong": "香港",
"Iceland": "アイスランド",
"Indian/Antananarivo": "インド/アンタナナリボ",
"Indian/Chagos": "インディアン/チャゴス",
"Indian/Christmas": "インディアン/クリスマス",
"Indian/Cocos": "インディアン/ココス",
"Indian/Comoro": "インド/コモロ",
"Indian/Kerguelen": "インディアン/ケルゲレン",
"Indian/Mahe": "インディアン/マヘ",
"Indian/Maldives": "インド/モルディブ",
"Indian/Mauritius": "インド/モーリシャス",
"Indian/Mayotte": "インディアン/マヨット",
"Indian/Reunion": "インディアン/レユニオン",
"Iran": "イラン",
"Israel": "イスラエル",
"Jamaica": "ジャマイカ",
"Japan": "日本",
"Kwajalein": "クェゼリン",
"Libya": "リビア",
"MET": "会った",
"MST": "最も",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "メキシコ/バハノルテ",
"Mexico/BajaSur": "メキシコ/バハスール",
"Mexico/General": "メキシコ/ジェネラル",
"NZ": "NZ",
"NZ-CHAT": "ニュージーランドチャット",
"Navajo": "ナバホ",
"PRC": "中華人民共和国",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "パシフィック/アピア",
"Pacific/Auckland": "太平洋/オークランド",
"Pacific/Bougainville": "パシフィック/ブーゲンビル",
"Pacific/Chatham": "太平洋/チャタム",
"Pacific/Chuuk": "太平洋/チューク",
"Pacific/Easter": "パシフィック/イースター",
"Pacific/Efate": "太平洋/エファテ",
"Pacific/Enderbury": "パシフィック/エンダーベリー",
"Pacific/Fakaofo": "太平洋/ファカオフォ",
"Pacific/Fiji": "太平洋/フィジー",
"Pacific/Funafuti": "太平洋/フナフティ",
"Pacific/Galapagos": "太平洋/ガラパゴス",
"Pacific/Gambier": "パシフィック/ガンビア",
"Pacific/Guadalcanal": "太平洋/ガダルカナル",
"Pacific/Guam": "太平洋/グアム",
"Pacific/Honolulu": "パシフィック/ホノルル",
"Pacific/Johnston": "パシフィック/ジョンストン",
"Pacific/Kanton": "太平洋/カントン",
"Pacific/Kiritimati": "太平洋/キリティマティ",
"Pacific/Kosrae": "太平洋/コスラエ",
"Pacific/Kwajalein": "太平洋/クェゼリン",
"Pacific/Majuro": "太平洋/マジュロ",
"Pacific/Marquesas": "太平洋/マルケサス",
"Pacific/Midway": "パシフィック/ミッドウェイ",
"Pacific/Nauru": "太平洋/ナウル",
"Pacific/Niue": "太平洋/ニウエ",
"Pacific/Norfolk": "パシフィック/ノーフォーク",
"Pacific/Noumea": "太平洋/ヌメア",
"Pacific/Pago_Pago": "パシフィック/パゴパゴ",
"Pacific/Palau": "太平洋/パラオ",
"Pacific/Pitcairn": "太平洋/ピトケアン",
"Pacific/Pohnpei": "太平洋/ポンペイ",
"Pacific/Ponape": "太平洋/ポナペ",
"Pacific/Port_Moresby": "パシフィック/ポートモレスビー",
"Pacific/Rarotonga": "太平洋/ラロトンガ",
"Pacific/Saipan": "太平洋/サイパン",
"Pacific/Samoa": "太平洋/サモア",
"Pacific/Tahiti": "太平洋/タヒチ",
"Pacific/Tarawa": "パシフィック/タラワ",
"Pacific/Tongatapu": "太平洋/トンガタプ",
"Pacific/Truk": "パシフィック/トラック",
"Pacific/Wake": "パシフィック/ウェイク",
"Pacific/Wallis": "太平洋/ウォリス",
"Pacific/Yap": "パシフィック/ヤップ",
"Poland": "ポーランド",
"Portugal": "ポルトガル",
"ROC": "ロック",
"ROK": "ロック",
"Singapore": "シンガポール",
"Turkey": "トルコ",
"UCT": "カット",
"US/Alaska": "米国/アラスカ",
"US/Aleutian": "米国/アリューシャン",
"US/Arizona": "米国/アリゾナ",
"US/Central": "米国/中部",
"US/East-Indiana": "米国/東インディアナ州",
"US/Eastern": "米国/東部",
"US/Hawaii": "米国/ハワイ",
"US/Indiana-Starke": "米国/インディアナ-スターク",
"US/Michigan": "米国/ミシガン州",
"US/Mountain": "アメリカ/マウンテン",
"US/Pacific": "米国/太平洋",
"US/Samoa": "米国/サモア",
"UTC": "UTC",
"Universal": "ユニバーサル",
"W-SU": "ウー・スー",
"WET": "ウェット",
"Zulu": "ズールー",
}