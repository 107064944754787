import React from 'react'
import { View, Image, Platform, Text, TouchableOpacity, Dimensions } from 'react-native'
import AutoHeightImage from '../AutoHeightImage'
import tryalLogo from '../../../assets/base64/tryalLogo'
import { MaterialIcons } from '@expo/vector-icons'
import { fontResizer } from '../styles/fontResizer'
import constants from '../../constants/constants'
import PropTypes from 'prop-types'

const Header = (props) => {
  const { title, navigation, canShowBackIcon, showHeaderLogo, onPress = () => navigation.goBack() } = props

  return (
    <View style={{ flexDirection: 'row' }}>
      {canShowBackIcon && (
        <TouchableOpacity
          onPress={onPress}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <MaterialIcons name="arrow-back" color="#212121" size={24} />
        </TouchableOpacity>
      )}

      {showHeaderLogo && (
        <View style={{ width: 65, height: 60, marginRight: 8, marginLeft: 5 }}>
          {Platform.OS !== 'web' ? (
            <AutoHeightImage
              source={{
                uri: `data:image/png;base64,${tryalLogo}`,
              }}
              width={45}
              style={{ marginRight: 8 }}
            />
          ) : (
            <Image
              source={{
                uri: `data:image/png;base64,${tryalLogo}`,
              }}
              resizeMode="contain"
              style={{ width: 55, height: 60, marginRight: 8 }}
            />
          )}
        </View>
      )}
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: title !== 'Passcode' ? 0 : 10,
        }}
      >
        <Text
          style={{
            fontWeight: '400',
            fontFamily: 'Inter',
            fontSize: fontResizer(24),
            color: '#212121',
          }}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {title}
        </Text>
      </View>
    </View>
  )
}

Header.defaultProps = {
  showHeaderLogo: true,
}
Header.propTypes = {
  showHeaderLogo: PropTypes.bool,
}

export default Header
