import _ from 'lodash'
import appConstants from '../constants/appConstants'
import createActionType from '../utils/action'
import api from '../utils/api'
import { storage } from '../utils/storage'
import { OFFLINE_DATA_RETRIEVE_SUCCESS, storeData } from './login'
import { updateTrainingForms } from './subjectVisitForm'
import constants from '../constants/constants'

export const OFFLINE_DATA_RETRIEVE_REQUEST = createActionType('OFFLINE_DATA_RETRIEVE_REQUEST')
export const OFFLINE_DATA_RETRIEVE_FAILURE = createActionType('OFFLINE_DATA_RETRIEVE_AILURE')
export const UPDATE_FIELDS = createActionType('UPDATE_FIELDS')
export const UPDATE_TRAINING_STATUS = createActionType('UPDATE_TRAINING_STATUS')
export const UPDATE_SITE = createActionType('UPDATE_SITE')
export const UPDATE_STUDY_META_DATA = createActionType('UPDATE_STUDY_META_DATA')
export const STUDY_DETAILS_RETRIEVE_REQUEST = createActionType('STUDY_DETAILS_RETRIEVE_REQUEST')
export const STUDY_DETAILS_RETRIEVE_SUCCESS = createActionType('STUDY_DETAILS_RETRIEVE_SUCCESS')
export const STUDY_DETAILS_RETRIEVE_FAILURE = createActionType('STUDY_DETAILS_RETRIEVE_FAILURE')
export const UPDATE_PRIVACY_POLICY = createActionType('UPDATE_PRIVACY_POLICY')
export const UPDATE_PASSWORD_EXPIRY = createActionType('UPDATE_PASSWORD_EXPIRY')
export const UPDATE_RESET_PASSWORD = createActionType('UPDATE_RESET_PASSWORD')
export const UPDATE_IS_SUBJECT_REGISTERED = createActionType('UPDATE_IS_SUBJECT_REGISTERED')

export const getOfflineDataRequest = () => ({
  type: OFFLINE_DATA_RETRIEVE_REQUEST,
})

export const getOfflineDataFailed = () => ({
  type: OFFLINE_DATA_RETRIEVE_FAILURE,
})

export const updateTrainingStatus = (status) => ({
  type: UPDATE_TRAINING_STATUS,
  status,
})

const updateSite = (data) => ({
  type: UPDATE_SITE,
  client: data,
})

const updateFieldsInMetaData = (data) => ({
  type: UPDATE_FIELDS,
  data,
})

export const updatePrivacyPolicy= (agreedToTerms) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRIVACY_POLICY,
    agreedToTerms,
  })
}

export const updatePasswordExpiry= (passwordExpired) => async (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_EXPIRY,
    passwordExpired,
  })
}

export const updateResetPassword= (resetPassword) => async (dispatch) => {
  dispatch({
    type: UPDATE_RESET_PASSWORD,
    resetPassword,
  })
}

export const updateIsSubjectRegistered= (isSubjectRegistered) => async (dispatch) => {
  dispatch({
    type: UPDATE_IS_SUBJECT_REGISTERED,
    isSubjectRegistered,
  })
}

export const getOfflineDataSuccess = (data) => {
  storage.setItem('privileges', data?.subjectPrivileges)
  return {
      type: OFFLINE_DATA_RETRIEVE_SUCCESS,
      offlineData: data,
    }
}

export const getOfflineData = (navigation) => async (dispatch) => {
  navigation.navigate(`${appConstants.urlPrefix}RootTabs`)
  // dispatch(getOfflineDataRequest())
  // try {
  //   const data = storage.getString('subjectStudyMetaData')
  //   // const data = initialState.subjectStudyMetaData;
  //   if (data != null && data != '' && data != undefined) {
  //     dispatch(getOfflineDataSuccess(JSON.parse(data)))
  //     navigation.navigate(`${appConstants.urlPrefix}RootTabs`)
  //   } else {
  //     dispatch(getOfflineDataFailed())
  //   }
  // } catch (e) {
  //   console.log(e)
  //   dispatch(getOfflineDataFailed())
  // }
}

export const getContactDetails = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}`)
    dispatch(updateSite(res.data))
  } catch (error) {
    console.log(error)
  }
}

export const updateFields = (fields, formId) => async (dispatch, getState) => {
  const { subjectStudyMetaData } = getState()
  const visits = _.map(subjectStudyMetaData.study.visits, (v) => {
    const visitForms = _.map(v.visitForms, (vf) => {
      if (vf.form.id === formId) {
        const fieldGroupWithFields = vf.form.fieldGroups
        fieldGroupWithFields[0].fields = fields
        return {
          ...vf,
          form: {
            ...vf.form,
            fieldGroups: [...fieldGroupWithFields],
          },
        }
      }
      return vf
    })
    return {
      ...v,
      visitForms,
    }
  })
  subjectStudyMetaData.study.visits = visits
  dispatch(updateFieldsInMetaData(subjectStudyMetaData))
}

export const updateTrainingStatusBasedOnFormSubmission =
  (completedSvfId) => async (dispatch, getState) => {
    const { trainingForms } = getState().appStatus
    _.remove(trainingForms, (svfId) => _.isEqual(_.toUpper(completedSvfId), _.toUpper(svfId)))
    dispatch(updateTrainingForms(trainingForms))
    dispatch(updateTrainingStatus(_.isEmpty(trainingForms)))
  }

export const getStudyVisitMetaData = () => async (dispatch, getState) => {
  const subjectStudyMetaData = getState().subjectStudyMetaData
  try {
    dispatch(getOfflineDataRequest())
    api.defaults.headers.common['Accept-Language'] = subjectStudyMetaData.subject.locale
    const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}/studyMetaData`, { phoneNo: subjectStudyMetaData.userId })
    const data = {
      ...subjectStudyMetaData,
      study: res.data,
    }
    await storeData(data)
    dispatch({
      type: UPDATE_STUDY_META_DATA,
      data: res.data,
    })
  } catch (error) {
    console.log(error)
    dispatch(getOfflineDataFailed())
  }
}

export const retrieveStudyDetailsRequest = () => ({
  type: STUDY_DETAILS_RETRIEVE_REQUEST,
})

export const retrieveStudyDetailsSuccess = (data) => ({
  type: STUDY_DETAILS_RETRIEVE_SUCCESS,
  data,
})

export const retrieveStudyDetailsFailure = (status) => ({
  type: STUDY_DETAILS_RETRIEVE_FAILURE,
})

export const getStudyDetails = (subjectId) => async (dispatch) => {
  try {
    dispatch(retrieveStudyDetailsRequest())
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/allowedStudies`)
    dispatch(retrieveStudyDetailsSuccess(res.data))
  } catch (error) {
    console.log(error)
    dispatch(retrieveStudyDetailsFailure())
  }
}
