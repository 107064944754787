import React, { Component } from 'react'
import VersionDetails from '../components/VersionDetails'
import { getUserData } from '../selectors/user'
import { connect } from 'react-redux'

class VersionDetailssPage extends Component {
  state = {}

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    title: t('VersionDetails'),
  })

  render() {
    const { subject, studyInfo, versionInfo, screenProps } = this.props
    return (
      <VersionDetails
        screenProps={screenProps}
        subject={subject}
        studyInfo={studyInfo}
        versionInfo={versionInfo}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  subject: state.subjectStudyMetaData.subject,
  studyInfo: state.subjectStudyMetaData.study,
  versionInfo: state.subjectStudyMetaData.study.crfVersion,
})

export default connect(mapStateToProps)(VersionDetailssPage)
