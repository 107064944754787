import _ from 'lodash'
import {
  RETRIEVE_ALL_CRF_DATA_FAILURE,
  RETRIEVE_ALL_CRF_DATA_REQUEST,
  RETRIEVE_ALL_CRF_DATA_SUCCESS,
  UPDATE_FIELD_ANSWER,
} from '../actions/formAnswers'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

export default (state = initialState.formAnswers, action) => {
  switch (action.type) {
    case RETRIEVE_ALL_CRF_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case RETRIEVE_ALL_CRF_DATA_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
      }
    case RETRIEVE_ALL_CRF_DATA_SUCCESS: {
      return {
        ...state,
        data: action.formAnswers,
        loading: false,
      }
    }
    case UPDATE_FIELD_ANSWER: {
      const fieldId = Object.keys(action.selectedField)[0]
      const selectedFieldValue = action.selectedField[fieldId]
      var currentForm = { ...state.data[action.svfId] }
      currentForm = {
        ...currentForm,
        [fieldId]: {
          ...currentForm[fieldId],
          fieldValue: selectedFieldValue,
          optionOid: selectedFieldValue,
        },
      }
      var data = {
        ...state.data,
        [action.svfId]: currentForm,
      }
      return {
        ...state,
        data: data,
      }
    }
    case PURGE:
      return {
        data: [],
        loading: false,
      }
    default:
      return state
  }
}
