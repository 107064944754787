import NetInfo from '@react-native-community/netinfo'
import { isEqual, cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { Platform, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  storeAppStatus,
  storeOfflineFormsToSync,
  storeSyncStatus,
  updateSyncCompleteStatus,
} from '../actions/storeAppStatus'
import { syncDataOfAllUsers } from '../actions/offlineData'
import { updateSubjectTimezone } from '../actions/timeZone'
import { updateIsAcknowledged } from '../actions/storeAppStatus'
import OfflineNotice from '../components/OfflineNotice'
import { checkForDataSync, getOfflineForms } from '../utils/offline/dataSync'
import NavigationScreens from './NavigationScreens'
import coreConstants from '../constants/constants'
import NavigationService from './navigationService'
import moment from 'moment-timezone'
import { getTzDetailsByOfffsetFromName } from '../utils/timezoneUtils'
import AppAlerts from './AppAlerts'

class AppNavigation extends Component {
  constructor(props) {
    super(props)

    const configuredValue = props?.subjectStudyMetaData?.subject?.timeZone

    this.state = {
      disappear: false,
      isInternetReachable: true,
      errorMessage: '',
      isTimeZoneNoClicked:false,
    }

    if (configuredValue) {
      moment.tz.setDefault(configuredValue)
    }
  }

  componentDidMount() {
      NetInfo.addEventListener((state) => {
        this.handleConnectivityChange(state)
      })
  }

  componentDidUpdate(prevProps) {
    const {
      updateSyncCompleteStatus,
      storeOfflineFormsToSync,
      appStatus,
      storeSyncStatus,
      syncDataOfAllUsers,
    } = this.props

    const syncUpdates = {
      isSyncing: null,
      syncCompleted: null,
    }

    const prevAppStatus = {
      ...prevProps.appStatus,
      ...syncUpdates,
    }

    const currentAppStatus = {
      ...appStatus,
      ...syncUpdates,
    }

    const hasAppStatusChanged = !isEqual(prevAppStatus, currentAppStatus)

    if (hasAppStatusChanged) {
      if (coreConstants.AppType.SITESTAFF === appStatus.appType) {
        syncDataOfAllUsers()
      } else {
        checkForDataSync(
          appStatus,
          storeSyncStatus,
          updateSyncCompleteStatus,
          storeOfflineFormsToSync
        )
      }
    }
  }

  handleConnectivityChange = (state) => {
    const { storeAppStatus, screenProps: { t } } = this.props
    const { type, isInternetReachable, isConnected } = state

    const isNotOnline = ['none', 'unknown'].includes(type) || !isConnected
    const isOnline = (Platform.OS === 'web' && isConnected) || (isConnected && isInternetReachable)

    if (isOnline) {
      storeAppStatus(true)
      this.autoCloseToaster()
      this.setState({
        errorMessage: null,
      })
      console.log({
        caller: 'AppNavigator.handleConnectivityChange',
        isOnline,
      })
    } else if (isNotOnline) {
      storeAppStatus(false)
      this.setState({
        disappear: false,
        errorMessage: t('OfflineMessage'),
      })
      console.log({
        caller: 'AppNavigator.handleConnectivityChange',
        isOnline: false,
      })
    }
  }

  isTimeZoneNoClicked = (val) => {
    this.setState({isTimeZoneNoClicked : val})
  }

  setOrAckTimezoneChange = async (shouldChange) => {
    const { timeZones, subjectStudyMetaData, updateSubjectTimezone, updateIsAcknowledged, screenProps:{t} } = this.props
    const inferredValue = moment.tz.guess(true)
    await updateIsAcknowledged(true)
    if (shouldChange) {
      let updatedTimeZone = getTzDetailsByOfffsetFromName(inferredValue, timeZones) || '';
      const subject = {
        id: subjectStudyMetaData?.subject?.id,
        timeZone: updatedTimeZone && updatedTimeZone.timeZoneName ? updatedTimeZone.timeZoneName : inferredValue ,
        lastUpdatedDateOn: subjectStudyMetaData?.subject?.lastUpdatedDateOn
      }

      const mutatedSubjectStudyMetaData = cloneDeep(subjectStudyMetaData)
      mutatedSubjectStudyMetaData.subject = {
        ...subjectStudyMetaData.subject,
        timeZone: updatedTimeZone?.timeZoneName ? updatedTimeZone.timeZoneName : inferredValue,
      }
      await updateSubjectTimezone(subject, mutatedSubjectStudyMetaData,t)
    }
  }

  autoCloseToaster = () => {
    setTimeout(() => {
      this.setState({ disappear: true })
    }, 5000)
  }

  render() {
    const { screenProps, appStatus,appStatus: { isDeviceOnline: isConnected }, screenProps: { t },navigation, subjectStudyMetaData, storage } = this.props
    const { disappear, isInternetReachable ,isTimeZoneNoClicked} = this.state
    const finalScreenProps = {
      ...screenProps,
    }
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff', width: '99%', marginLeft: 2 }}>
        {disappear === false && Platform.OS !== 'web' && (
          <OfflineNotice
            t={screenProps.t}
            isInternetReachable={isInternetReachable}
            isConnected={isConnected}
          />
        )}
        <AppAlerts
        t={t}
        navigation={navigation}
        appStatus ={appStatus}
        subjectStudyMetaData={subjectStudyMetaData}
        setOrAckTimezoneChange ={this.setOrAckTimezoneChange}
        isTimeZoneNoClicked = {this.isTimeZoneNoClicked}
        configuredValue ={subjectStudyMetaData?.subject?.timeZone}
        isTimeZoneNoClickedProp={isTimeZoneNoClicked}
        isStorageAvailable={storage?.isStorageAvailable}
        isSpaceAlertAcknowledged={storage?.isSpaceAlertAcknowledged}
        />
        <NavigationScreens
          ref={(navigatorRef) => {
            NavigationService.setTopLevelNavigator(navigatorRef)
          }}
          screenProps={finalScreenProps}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  appStatus: state.appStatus,
  unreadChats: state.chat?.unreadChats?.count,
  subjectStudyMetaData: state.subjectStudyMetaData,
  timeZones: state.timeZone?.list,
  storage: state.appStatus.storage
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeAppStatus,
      storeOfflineFormsToSync,
      getOfflineForms,
      checkForDataSync,
      storeSyncStatus,
      updateSyncCompleteStatus,
      syncDataOfAllUsers,
      updateSubjectTimezone,
      updateIsAcknowledged,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation)
