export default {
    "Africa/Abidjan":"آفریقا/آبیجان",
"Africa/Accra":"آفریقا/آکرا",
"Africa/Addis_Ababa":"آفریقا/آدیس_آبابا",
"Africa/Algiers":"آفریقا/الجزیره",
"Africa/Asmara":"آفریقا/اسمره",
"Africa/Asmera":"آفریقا/آسمرا",
"Africa/Bamako":"آفریقا/باماکو",
"Africa/Bangui":"آفریقا/بانگوی",
"Africa/Banjul":"آفریقا/بانجول",
"Africa/Bissau":"آفریقا/بیسائو",
"Africa/Blantyre":"آفریقا/بلانتایر",
"Africa/Brazzaville":"آفریقا/برازاویل",
"Africa/Bujumbura":"آفریقا/بوجومبورا",
"Africa/Cairo":"آفریقا/قاهره",
"Africa/Casablanca":"آفریقا/کازابلانکا",
"Africa/Ceuta":"آفریقا/سوتا",
"Africa/Conakry":"آفریقا/کوناکری",
"Africa/Dakar":"آفریقا/داکار",
"Africa/Dar_es_Salaam":"آفریقا/دار_اس_سلام",
"Africa/Djibouti":"آفریقا/جیبوتی",
"Africa/Douala":"آفریقا/دوالا",
"Africa/El_Aaiun":"آفریقا/ال_آئون",
"Africa/Freetown":"آفریقا/فریتاون",
"Africa/Gaborone":"آفریقا/گابورون",
"Africa/Harare":"آفریقا/هراره",
"Africa/Johannesburg":"آفریقا/ژوهانسبورگ",
"Africa/Juba":"آفریقا/جوبا",
"Africa/Kampala":"آفریقا/کامپالا",
"Africa/Khartoum":"آفریقا/خارطوم",
"Africa/Kigali":"آفریقا/کیگالی",
"Africa/Kinshasa":"آفریقا/کینشاسا",
"Africa/Lagos":"آفریقا/لاگوس",
"Africa/Libreville":"آفریقا/لیبرویل",
"Africa/Lome":"آفریقا/لومه",
"Africa/Luanda":"آفریقا/لوآندا",
"Africa/Lubumbashi":"آفریقا/لوبومباشی",
"Africa/Lusaka":"آفریقا/لوساکا",
"Africa/Malabo":"آفریقا/مالابو",
"Africa/Maputo":"آفریقا/ماپوتو",
"Africa/Maseru":"آفریقا/ماسرو",
"Africa/Mbabane":"آفریقا/مبابان",
"Africa/Mogadishu":"آفریقا/موگادیشو",
"Africa/Monrovia":"آفریقا/مونروویا",
"Africa/Nairobi":"آفریقا/نایروبی",
"Africa/Ndjamena":"آفریقا/نجیمنا",
"Africa/Niamey":"آفریقا/نیامی",
"Africa/Nouakchott":"آفریقا/نواکشوت",
"Africa/Ouagadougou":"آفریقا/اوآگادوگو",
"Africa/Porto-Novo":"آفریقا/پورتو نوو",
"Africa/Sao_Tome":"آفریقا/سائو_تومه",
"Africa/Timbuktu":"آفریقا/تیمبوکتو",
"Africa/Tripoli":"آفریقا/طرابلس",
"Africa/Tunis":"آفریقا/تونس",
"Africa/Windhoek":"آفریقا/ویندهوک",
"America/Adak":"آمریکا/آداک",
"America/Anchorage":"آمریکا/انکوریج",
"America/Anguilla":"آمریکا/آنگویلا",
"America/Antigua":"آمریکا/آنتیگوا",
"America/Araguaina":"آمریکا/آراگواینا",
"America/Argentina/Buenos_Aires":"آمریکا/آرژانتین/بوئنوس_آیرس",
"America/Argentina/Catamarca":"آمریکا/آرژانتین/کاتامارکا",
"America/Argentina/ComodRivadavia":"آمریکا/آرژانتین/کومودریواداویا",
"America/Argentina/Cordoba":"آمریکا/آرژانتین/کوردوبا",
"America/Argentina/Jujuy":"آمریکا/آرژانتین/جوخوی",
"America/Argentina/La_Rioja":"آمریکا/آرژانتین/لا_ریوجا",
"America/Argentina/Mendoza":"آمریکا/آرژانتین/مندوزا",
"America/Argentina/Rio_Gallegos":"آمریکا/آرژانتین/ریو_گالگوس",
"America/Argentina/Salta":"آمریکا/آرژانتین/سالتا",
"America/Argentina/San_Juan":"آمریکا/آرژانتین/سان_خوان",
"America/Argentina/San_Luis":"آمریکا/آرژانتین/سان_لوئیس",
"America/Argentina/Tucuman":"آمریکا/آرژانتین/توکومن",
"America/Argentina/Ushuaia":"آمریکا/آرژانتین/اوشوایا",
"America/Aruba":"آمریکا/آروبا",
"America/Asuncion":"آمریکا/آسونسیون",
"America/Atikokan":"آمریکا/آتیکوکان",
"America/Atka":"آمریکا/آتکا",
"America/Bahia":"آمریکا/باهیا",
"America/Bahia_Banderas":"آمریکا/باهیا_باندراس",
"America/Barbados":"آمریکا/باربادوس",
"America/Belem":"آمریکا/بلم",
"America/Belize":"آمریکا/بلیز",
"America/Blanc-Sablon":"آمریکا/بلانک-سابلون",
"America/Boa_Vista":"آمریکا/بوآ_ویستا",
"America/Bogota":"آمریکا/بوگوتا",
"America/Boise":"آمریکا/بویز",
"America/Buenos_Aires":"آمریکا/بوئنوس_آیرس",
"America/Cambridge_Bay":"آمریکا/کمبریج_بی",
"America/Campo_Grande":"آمریکا/کامپو_گراند",
"America/Cancun":"آمریکا/کانکون",
"America/Caracas":"آمریکا/کاراکاس",
"America/Catamarca":"آمریکا/کاتامارکا",
"America/Cayenne":"آمریکا/کاین",
"America/Cayman":"آمریکا/کیمن",
"America/Chicago":"آمریکا/شیکاگو",
"America/Chihuahua":"آمریکا/چیواوا",
"America/Ciudad_Juarez":"آمریکا/سیوداد_خوارز",
"America/Coral_Harbour":"آمریکا/کورال_هاربور",
"America/Cordoba":"آمریکا/کوردوبا",
"America/Costa_Rica":"آمریکا/کوستا_ریکا",
"America/Creston":"آمریکا/کرستون",
"America/Cuiaba":"آمریکا/کویابا",
"America/Curacao":"آمریکا/کوراسائو",
"America/Danmarkshavn":"آمریکا/دانمارکسهاون",
"America/Dawson":"آمریکا/داوسون",
"America/Dawson_Creek":"آمریکا/داوسون_کریک",
"America/Denver":"آمریکا/دنور",
"America/Detroit":"آمریکا/دیترویت",
"America/Dominica":"آمریکا/دومینیکا",
"America/Edmonton":"آمریکا/ادمونتون",
"America/Eirunepe":"آمریکا/ایرونپه",
"America/El_Salvador":"آمریکا/ال_سالوادور",
"America/Ensenada":"آمریکا/انسنادا",
"America/Fort_Nelson":"آمریکا/فورت_نلسون",
"America/Fort_Wayne":"آمریکا/فورت_وین",
"America/Fortaleza":"آمریکا/فورتالزا",
"America/Glace_Bay":"آمریکا/گلیس_بی",
"America/Godthab":"آمریکا/گودتاب",
"America/Goose_Bay":"آمریکا/Goose_Bay",
"America/Grand_Turk":"آمریکا/گرند_ترک",
"America/Grenada":"آمریکا/گرنادا",
"America/Guadeloupe":"آمریکا/گوادلوپ",
"America/Guatemala":"آمریکا/گواتمالا",
"America/Guayaquil":"آمریکا/گوایاکیل",
"America/Guyana":"آمریکا/گویان",
"America/Halifax":"آمریکا/هالیفکس",
"America/Havana":"آمریکا/هاوانا",
"America/Hermosillo":"آمریکا/هرموسیلو",
"America/Indiana/Indianapolis":"آمریکا/ایندیانا/ایندیاناپولیس",
"America/Indiana/Knox":"آمریکا/ایندیانا/ناکس",
"America/Indiana/Marengo":"آمریکا/ایندیانا/مارنگو",
"America/Indiana/Petersburg":"آمریکا/ایندیانا/پترزبورگ",
"America/Indiana/Tell_City":"آمریکا/ایندیانا/تل_سیتی",
"America/Indiana/Vevay":"آمریکا/ایندیانا/ووی",
"America/Indiana/Vincennes":"آمریکا/ایندیانا/وینسنس",
"America/Indiana/Winamac":"آمریکا/ایندیانا/وینامک",
"America/Indianapolis":"آمریکا/ایندیاناپولیس",
"America/Inuvik":"آمریکا/اینوویک",
"America/Iqaluit":"آمریکا/ایکالویت",
"America/Jamaica":"آمریکا/جامائیکا",
"America/Jujuy":"آمریکا/جوخوی",
"America/Juneau":"آمریکا/جونو",
"America/Kentucky/Louisville":"آمریکا/کنتاکی/لوئیزویل",
"America/Kentucky/Monticello":"آمریکا/کنتاکی/مونتیسلو",
"America/Knox_IN":"آمریکا/ناکس_ین",
"America/Kralendijk":"آمریکا/کرالندیک",
"America/La_Paz":"آمریکا/لا_پاز",
"America/Lima":"آمریکا/لیما",
"America/Los_Angeles":"آمریکا/لوس_آنجلس",
"America/Louisville":"آمریکا/لوئیزویل",
"America/Lower_Princes":"آمریکا/لاور_پرنس",
"America/Maceio":"آمریکا/ماسیو",
"America/Managua":"آمریکا/ماناگوا",
"America/Manaus":"آمریکا/مانائوس",
"America/Marigot":"آمریکا/مریگو",
"America/Martinique":"آمریکا/مارتینیک",
"America/Matamoros":"آمریکا/ماتاموروس",
"America/Mazatlan":"آمریکا/مازاتلان",
"America/Mendoza":"آمریکا/مندوزا",
"America/Menominee":"آمریکا/منومینی",
"America/Merida":"آمریکا/مریدا",
"America/Metlakatla":"آمریکا/متلاکاتلا",
"America/Mexico_City":"آمریکا/مکزیکو_سیتی",
"America/Miquelon":"آمریکا/میکلون",
"America/Moncton":"آمریکا/مونکتون",
"America/Monterrey":"آمریکا/مونتری",
"America/Montevideo":"آمریکا/مونته ویدئو",
"America/Montreal":"آمریکا/مونترال",
"America/Montserrat":"آمریکا/مونتسرات",
"America/Nassau":"آمریکا/ناسائو",
"America/New_York":"آمریکا/نیویورک",
"America/Nipigon":"آمریکا/نیپیگون",
"America/Nome":"آمریکا/نوم",
"America/Noronha":"آمریکا/نورونیا",
"America/North_Dakota/Beulah":"آمریکا/نورث_داکوتا/بیوله",
"America/North_Dakota/Center":"آمریکا/نورث_داکوتا/مرکز",
"America/North_Dakota/New_Salem":"آمریکا/نورث_داکوتا/نیو_سالم",
"America/Nuuk":"آمریکا/نووک",
"America/Ojinaga":"آمریکا/اوجیناگا",
"America/Panama":"آمریکا/پاناما",
"America/Pangnirtung":"آمریکا/پانگنیرتونگ",
"America/Paramaribo":"آمریکا/پاراماریبو",
"America/Phoenix":"آمریکا/ققنوس",
"America/Port-au-Prince":"آمریکا/پورتو پرنس",
"America/Port_of_Spain":"آمریکا/بندر اسپانیا",
"America/Porto_Acre":"آمریکا/پورتو_آکر",
"America/Porto_Velho":"آمریکا/پورتو_ولهو",
"America/Puerto_Rico":"آمریکا/پوئرتو_ریکو",
"America/Punta_Arenas":"آمریکا/پونتا_آرناس",
"America/Rainy_River":"آمریکا/رینی_ریور",
"America/Rankin_Inlet":"آمریکا/رانکین_اینلت",
"America/Recife":"آمریکا/رسیف",
"America/Regina":"آمریکا/رجینا",
"America/Resolute":"آمریکا/قاطع",
"America/Rio_Branco":"آمریکا/ریو_برانکو",
"America/Rosario":"آمریکا/روزاریو",
"America/Santa_Isabel":"آمریکا/سنتا_ایزابل",
"America/Santarem":"آمریکا/سانتارم",
"America/Santiago":"آمریکا/سانتیاگو",
"America/Santo_Domingo":"آمریکا/سانتو_دومینگو",
"America/Sao_Paulo":"آمریکا/سائو_پائولو",
"America/Scoresbysund":"آمریکا/اسکورسبیسوند",
"America/Shiprock":"آمریکا/شیپراک",
"America/Sitka":"آمریکا/سیتکا",
"America/St_Barthelemy":"آمریکا/سنت_بارتلمی",
"America/St_Johns":"آمریکا/است_جانز",
"America/St_Kitts":"آمریکا/است_کیتس",
"America/St_Lucia":"آمریکا/سنت_لوسیا",
"America/St_Thomas":"آمریکا/سنت_توماس",
"America/St_Vincent":"آمریکا/سنت_وینسنت",
"America/Swift_Current":"آمریکا/سوئیفت_کورنت",
"America/Tegucigalpa":"آمریکا/تگوسیگالپا",
"America/Thule":"آمریکا/تول",
"America/Thunder_Bay":"آمریکا/تاندر_بی",
"America/Tijuana":"آمریکا/تیخوانا",
"America/Toronto":"آمریکا/تورنتو",
"America/Tortola":"آمریکا/تورتولا",
"America/Vancouver":"آمریکا/ونکوور",
"America/Virgin":"آمریکا/ویرجین",
"America/Whitehorse":"آمریکا/وایت‌هورس",
"America/Winnipeg":"آمریکا/وینیپگ",
"America/Yakutat":"آمریکا/یاکوتات",
"America/Yellowknife":"آمریکا/یلونایف",
"Antarctica/Casey":"قطب جنوب /کیسی",
"Antarctica/Davis":"قطب جنوب /دیویس",
"Antarctica/DumontDUrville":"قطب جنوب ها/دومونتدورویل",
"Antarctica/Macquarie":"قطب جنوب /مک‌کواری",
"Antarctica/Mawson":"قطب جنوب/موسون",
"Antarctica/McMurdo":"قطب جنوب ها/مک موردو",
"Antarctica/Palmer":"قطب جنوب/پالمر",
"Antarctica/Rothera":"قطب جنوب/روترا",
"Antarctica/South_Pole":"قطب جنوبی/قطب جنوب",
"Antarctica/Syowa":"قطب جنوب /سیووا",
"Antarctica/Troll":"قطب جنوب /ترول",
"Antarctica/Vostok":"قطب جنوب /وستوک",
"Arctic/Longyearbyen":"آرکتیک/لانجیربین",
"Asia/Aden":"آسیا/عدن",
"Asia/Almaty":"آسیا/آلماتی",
"Asia/Amman":"آسیا/امان",
"Asia/Anadyr":"آسیا/آنادیر",
"Asia/Aqtau":"آسیا/آقتاو",
"Asia/Aqtobe":"آسیا/آکتوبه",
"Asia/Ashgabat":"آسیا/عشق آباد",
"Asia/Ashkhabad":"آسیا/اشخ‌آباد",
"Asia/Atyrau":"آسیا/آتیراو",
"Asia/Baghdad":"آسیا/بغداد",
"Asia/Bahrain":"آسیا/بحرین",
"Asia/Baku":"آسیا/باکو",
"Asia/Bangkok":"آسیا/بانکوک",
"Asia/Barnaul":"آسیا/بارنائول",
"Asia/Beirut":"آسیا/بیروت",
"Asia/Bishkek":"آسیا/بیشکک",
"Asia/Brunei":"آسیا/برونئی",
"Asia/Calcutta":"آسیا/کلکته",
"Asia/Chita":"آسیا/چیتا",
"Asia/Choibalsan":"آسیا/چویبالسان",
"Asia/Chongqing":"آسیا/چونگکینگ",
"Asia/Chungking":"آسیا/چونگکینگ",
"Asia/Colombo":"آسیا/کلمبو",
"Asia/Dacca":"آسیا/داکا",
"Asia/Damascus":"آسیا/دمشق",
"Asia/Dhaka":"آسیا/داکا",
"Asia/Dili":"آسیا/دیلی",
"Asia/Dubai":"آسیا/دبی",
"Asia/Dushanbe":"آسیا/دوشنبه",
"Asia/Famagusta":"آسیا/فاماگوستا",
"Asia/Gaza":"آسیا/غزه",
"Asia/Harbin":"آسیا/هاربین",
"Asia/Hebron":"آسیا/حبرون",
"Asia/Ho_Chi_Minh":"آسیا/هو_چی_مین",
"Asia/Hong_Kong":"آسیا/هنگ کنگ",
"Asia/Hovd":"آسیا/هود",
"Asia/Irkutsk":"آسیا/ایرکوتسک",
"Asia/Istanbul":"آسیا/استانبول",
"Asia/Jakarta":"آسیا/جاکارتا",
"Asia/Jayapura":"آسیا/جیاپورا",
"Asia/Jerusalem":"آسیا/اورشلیم",
"Asia/Kabul":"آسیا/کابل",
"Asia/Kamchatka":"آسیا/کامچاتکا",
"Asia/Karachi":"آسیا/کراچی",
"Asia/Kashgar":"آسیا/کاشغر",
"Asia/Kathmandu":"آسیا/کاتماندو",
"Asia/Katmandu":"آسیا/کاتماندو",
"Asia/Khandyga":"آسیا/خاندیگا",
"Asia/Kolkata":"آسیا/کلکته",
"Asia/Krasnoyarsk":"آسیا/کراسنویارسک",
"Asia/Kuala_Lumpur":"آسیا/کوالا_لامپور",
"Asia/Kuching":"آسیا/کوچینگ",
"Asia/Kuwait":"آسیا/کویت",
"Asia/Macao":"آسیا/ماکائو",
"Asia/Macau":"آسیا/ماکائو",
"Asia/Magadan":"آسیا/ماگادان",
"Asia/Makassar":"آسیا/ماکاسار",
"Asia/Manila":"آسیا/مانیل",
"Asia/Muscat":"آسیا/مسقط",
"Asia/Nicosia":"آسیا/نیکوزیا",
"Asia/Novokuznetsk":"آسیا/نووکوزنتسک",
"Asia/Novosibirsk":"آسیا/نووسیبیرسک",
"Asia/Omsk":"آسیا/اومسک",
"Asia/Oral":"آسیا/دهانی",
"Asia/Phnom_Penh":"آسیا/پنوم_پن",
"Asia/Pontianak":"آسیا/پونتیاناک",
"Asia/Pyongyang":"آسیا/پیونگ یانگ",
"Asia/Qatar":"آسیا/قطر",
"Asia/Qostanay":"آسیا/قستانای",
"Asia/Qyzylorda":"آسیا/قیزیلوردا",
"Asia/Rangoon":"آسیا/رانگون",
"Asia/Riyadh":"آسیا/ریاض",
"Asia/Saigon":"آسیا/سایگون",
"Asia/Sakhalin":"آسیا/ساخالین",
"Asia/Samarkand":"آسیا/سمرقند",
"Asia/Seoul":"آسیا/سئول",
"Asia/Shanghai":"آسیا/شانگهای",
"Asia/Singapore":"آسیا/سنگاپور",
"Asia/Srednekolymsk":"آسیا/سردنکولیمسک",
"Asia/Taipei":"آسیا/تایپه",
"Asia/Tashkent":"آسیا/تاشکند",
"Asia/Tbilisi":"آسیا/تفلیس",
"Asia/Tehran":"آسیا/تهران",
"Asia/Tel_Aviv":"آسیا/تل_آویو",
"Asia/Thimbu":"آسیا/تیمبو",
"Asia/Thimphu":"آسیا/تیمفو",
"Asia/Tokyo":"آسیا/توکیو",
"Asia/Tomsk":"آسیا/تومسک",
"Asia/Ujung_Pandang":"آسیا/اوجونگ_پانگ",
"Asia/Ulaanbaatar":"آسیا/اولانباتور",
"Asia/Ulan_Bator":"آسیا/اولان_باتور",
"Asia/Urumqi":"آسیا/ارومچی",
"Asia/Ust-Nera":"آسیا/اوست-نرا",
"Asia/Vientiane":"آسیا/وینتیان",
"Asia/Vladivostok":"آسیا/ولادی وستوک",
"Asia/Yakutsk":"آسیا/یاکوتسک",
"Asia/Yangon":"آسیا/یانگون",
"Asia/Yekaterinburg":"آسیا/یکاترینبورگ",
"Asia/Yerevan":"آسیا/ایروان",
"Atlantic/Azores":"اقیانوس اطلس",
"Atlantic/Bermuda":"اقیانوس اطلس/برمودا",
"Atlantic/Canary":"اقیانوس اطلس/قناری",
"Atlantic/Cape_Verde":"اقیانوس اطلس/کیپ_ورد",
"Atlantic/Faeroe":"اقیانتیس/فارو",
"Atlantic/Faroe":"اقیانوس اطلس/فارو",
"Atlantic/Jan_Mayen":"اقیانتیس/جان_ماین",
"Atlantic/Madeira":"اقیانوس اطلس و مادیرا",
"Atlantic/Reykjavik":"اقیانوس اطلس/ریکیاویک",
"Atlantic/South_Georgia":"اقیانتیس/جنوبی_جورجیا",
"Atlantic/St_Helena":"اقیانتیس/سنت_هلنا",
"Atlantic/Stanley":"اقیانتیک/استنلی",
"Australia/ACT":"استرالیا/ACT",
"Australia/Adelaide":"استرالیا/آدلاید",
"Australia/Brisbane":"استرالیا/بریزبن",
"Australia/Broken_Hill":"استرالیا/بروکن_هیل",
"Australia/Canberra":"استرالیا/کانبرا",
"Australia/Currie":"استرالیا/کوری",
"Australia/Darwin":"استرالیا/داروین",
"Australia/Eucla":"استرالیا/اوکلا",
"Australia/Hobart":"استرالیا/هوبارت",
"Australia/LHI":"استرالیا/LHI",
"Australia/Lindeman":"استرالیا/لیندمن",
"Australia/Lord_Howe":"استرالیا/لرد_هاو",
"Australia/Melbourne":"استرالیا/ملبورن",
"Australia/NSW":"استرالیا/NSW",
"Australia/North":"استرالیا/شمال",
"Australia/Perth":"استرالیا/پرت",
"Australia/Queensland":"استرالیا/کوئینزلند",
"Australia/South":"استرالیا/جنوب",
"Australia/Sydney":"استرالیا/سیدنی",
"Australia/Tasmania":"استرالیا/تاسمانی",
"Australia/Victoria":"استرالیا/ویکتوریا",
"Australia/West":"استرالیا/غرب",
"Australia/Yancowinna":"استرالیا/یانکووینا",
"Brazil/Acre":"برزیل/آکر",
"Brazil/DeNoronha":"برزیل/دنورونیا",
"Brazil/East":"برزیل/شرق",
"Brazil/West":"برزیل/غرب",
"CET":"این",
"CST6CDT":"سی‌اس‌تی۶سی‌دیت",
"Canada/Atlantic":"کانادا/آتلانتیک",
"Canada/Central":"کانادا/مرکزی",
"Canada/Eastern":"کانادا/شرق",
"Canada/Mountain":"کانادا/کوه",
"Canada/Newfoundland":"کانادا/نیوفاندلند",
"Canada/Pacific":"کانادا/اقیانوس آرام",
"Canada/Saskatchewan":"کانادا/ساسکاچوان",
"Canada/Yukon":"کانادا/یوکان",
"Chile/Continental":"شیلی/قاره ای",
"Chile/EasterIsland":"شیلی/جزیره عید پاک",
"Cuba":"کوبا",
"EET":"خوردن",
"EST":"است",
"EST5EDT":"است5EDT",
"Egypt":"مصر",
"Eire":"ایرن",
"Etc/GMT":"و غیره/GMT",
"Etc/GMT+0":"و غیره/جی‌ام‌تی+0",
"Etc/GMT+1":"و غیره/جی‌ام‌تی+۱",
"Etc/GMT+10":"و غیره/جی‌ام‌تی+10",
"Etc/GMT+11":"و غیره/جی‌ام‌تی+۱۱",
"Etc/GMT+12":"و غیره/جی‌ام‌تی+۱۲",
"Etc/GMT+2":"و غیره/جی‌ام‌تی+۲",
"Etc/GMT+3":"و غیره/جی‌ام‌تی+۳",
"Etc/GMT+4":"و غیره/جی‌ام‌تی+۴",
"Etc/GMT+5":"و غیره جی‌ام‌تی+۵",
"Etc/GMT+6":"و غیره/جی‌ام‌تی+6",
"Etc/GMT+7":"و غیره/جی‌ام‌ت+7",
"Etc/GMT+8":"و غیره/جی‌ام‌تی+8",
"Etc/GMT+9":"و غیره/جی‌ام‌تی+9",
"Etc/GMT-0":"و غیره/جی‌ام‌تی-۰",
"Etc/GMT-1":"و غیره/جی‌ام‌تی-۱",
"Etc/GMT-10":"و غیره/جی‌ام‌تی-۱۰",
"Etc/GMT-11":"و غیره/جی‌ام‌تی-۱۱",
"Etc/GMT-12":"و غیره/جی‌ام‌تی-۱۲",
"Etc/GMT-13":"و غیره/جی‌ام‌تی-۱۳",
"Etc/GMT-14":"و غیره/جی‌ام‌تی-۱۴",
"Etc/GMT-2":"و غیره/جی‌ام‌تی-۲",
"Etc/GMT-3":"و غیره/جی‌ام‌تی-۳",
"Etc/GMT-4":"و غیره/جی‌ام‌تی-۴",
"Etc/GMT-5":"و غیره/جی‌ام‌تی-۵",
"Etc/GMT-6":"و غیره/جی‌ام‌تی-۶",
"Etc/GMT-7":"و غیره/جی‌ام‌تی-۷",
"Etc/GMT-8":"و غیره/جی‌ام‌تی-۸",
"Etc/GMT-9":"و غیره/جی‌ام‌تی-۹",
"Etc/GMT0":"و غیره/جی‌ام‌تی۰",
"Etc/Greenwich":"و غیره گرینویچ",
"Etc/UCT":"و غیره/UCT",
"Etc/UTC":"و غیره/UTC",
"Etc/Universal":"و غیره یونیورسال",
"Etc/Zulu":"و غیره/زولو",
"Europe/Amsterdam":"اروپا/آمستردام",
"Europe/Andorra":"اروپا/آندورا",
"Europe/Astrakhan":"اروپا/آستراخان",
"Europe/Athens":"اروپا/آتن",
"Europe/Belfast":"اروپا/بلفاست",
"Europe/Belgrade":"اروپا/بلگراد",
"Europe/Berlin":"اروپا/برلین",
"Europe/Bratislava":"اروپا/براتیسلاوا",
"Europe/Brussels":"اروپا/بروکسل",
"Europe/Bucharest":"اروپا/بخارست",
"Europe/Budapest":"اروپا/بوداپست",
"Europe/Busingen":"اروپا/بوسینگن",
"Europe/Chisinau":"اروپا/کیشیناو",
"Europe/Copenhagen":"اروپا/کپنهاگ",
"Europe/Dublin":"اروپا/دوبلین",
"Europe/Gibraltar":"اروپا/جبل الطارق",
"Europe/Guernsey":"اروپا/گرنزی",
"Europe/Helsinki":"اروپا/هلسینکی",
"Europe/Isle_of_Man":"اروپا/جزیره انسان",
"Europe/Istanbul":"اروپا/استانبول",
"Europe/Jersey":"اروپا/جرسی",
"Europe/Kaliningrad":"اروپا/کالینینگراد",
"Europe/Kiev":"اروپا/کیف",
"Europe/Kirov":"اروپا/کیروف",
"Europe/Kyiv":"اروپا/کییف",
"Europe/Lisbon":"اروپا/لیسبون",
"Europe/Ljubljana":"اروپا/لیوبلیانا",
"Europe/London":"اروپا/لندن",
"Europe/Luxembourg":"اروپا/لوکزامبورگ",
"Europe/Madrid":"اروپا/مادرید",
"Europe/Malta":"اروپا/مالت",
"Europe/Mariehamn":"اروپا/ماریهامن",
"Europe/Minsk":"اروپا/مینسک",
"Europe/Monaco":"اروپا/موناکو",
"Europe/Moscow":"اروپا/مسکو",
"Europe/Nicosia":"اروپا/نیکوزیا",
"Europe/Oslo":"اروپا/اسلو",
"Europe/Paris":"اروپا/پاریس",
"Europe/Podgorica":"اروپا/پودگوریتسا",
"Europe/Prague":"اروپا/پراگ",
"Europe/Riga":"اروپا/ریگا",
"Europe/Rome":"اروپا/رم",
"Europe/Samara":"اروپا/سامارا",
"Europe/San_Marino":"اروپا/سان_مارینو",
"Europe/Sarajevo":"اروپا/سارایوو",
"Europe/Saratov":"اروپا/ساراتوف",
"Europe/Simferopol":"اروپا/سیمفروپول",
"Europe/Skopje":"اروپا/اسکوپیه",
"Europe/Sofia":"اروپا/صوفیه",
"Europe/Stockholm":"اروپا/استکهلم",
"Europe/Tallinn":"اروپا/تالین",
"Europe/Tirane":"اروپا/تیران",
"Europe/Tiraspol":"اروپا/تیراسپول",
"Europe/Ulyanovsk":"اروپا/اولیانوفسک",
"Europe/Uzhgorod":"اروپا/اوژگورود",
"Europe/Vaduz":"اروپا/فادوتس",
"Europe/Vatican":"اروپا/واتیکان",
"Europe/Vienna":"اروپا/وین",
"Europe/Vilnius":"اروپا/ویلنیوس",
"Europe/Volgograd":"اروپا/ولگوگراد",
"Europe/Warsaw":"اروپا/ورشو",
"Europe/Zagreb":"اروپا/زاگرب",
"Europe/Zaporozhye":"اروپا/زاپروژیه",
"Europe/Zurich":"اروپا/زوریخ",
"GB":"گیگابایت",
"GB-Eire":"جی‌بی-ایر",
"GMT":"جی‌ام‌تی",
"GMT+0":"جی‌امت+۰",
"GMT-0":"جی‌ام‌تی-۰",
"GMT0":"جی‌ام‌تی۰",
"Greenwich":"گرینویچ",
"HST":"هست",
"Hongkong":"هنگ کنگ",
"Iceland":"ایسلند",
"Indian/Antananarivo":"هندی/آنتاناناریوو",
"Indian/Chagos":"هندی/چاگوس",
"Indian/Christmas":"هندی/کریسمس",
"Indian/Cocos":"هندی/کوکوس",
"Indian/Comoro":"هندی/کومورو",
"Indian/Kerguelen":"هندی/کرگولن",
"Indian/Mahe":"هندی/ماهه",
"Indian/Maldives":"هندی/مالدیو",
"Indian/Mauritius":"هندی/موریس",
"Indian/Mayotte":"هندی/مایوت",
"Indian/Reunion":"هندی/ریونیون",
"Iran":"ایران",
"Israel":"اسرائیل",
"Jamaica":"جامائیکا",
"Japan":"ژاپن",
"Kwajalein":"کواجالین",
"Libya":"لیبی",
"MET":"با",
"MST":"ام‌اس‌تی",
"MST7MDT":"ام‌اس‌تی۷ ام‌دیت",
"Mexico/BajaNorte":"مکزیک/باجانورته",
"Mexico/BajaSur":"مکزیک/باجاسور",
"Mexico/General":"مکزیک/عمومی",
"NZ":"نیوزیلند",
"NZ-CHAT":"NZ-چت",
"Navajo":"ناواهو",
"PRC":"PRC",
"PST8PDT":"پی‌اس‌تی۸پدت",
"Pacific/Apia":"اقیانوس آرام/آپیا",
"Pacific/Auckland":"اقیانوس آرام/اوکلند",
"Pacific/Bougainville":"اقیانوس آرام/بوگنویل",
"Pacific/Chatham":"اقیانوس آرام/چاتم",
"Pacific/Chuuk":"اقیانوس آرام/چووک",
"Pacific/Easter":"اقیانوس آرام/عید پاک",
"Pacific/Efate":"اقیانوس آرام/افات",
"Pacific/Enderbury":"اقیانوس آرام/اندربری",
"Pacific/Fakaofo":"اقیانوس آرام/فاکائوفو",
"Pacific/Fiji":"اقیانوس آرام/فیجی",
"Pacific/Funafuti":"اقیانوس آرام/فونافوتی",
"Pacific/Galapagos":"اقیانوس آرام/گالاپاگوس",
"Pacific/Gambier":"اقیانوس آرام/گمبیر",
"Pacific/Guadalcanal":"اقیانوس آرام/گوادالکانال",
"Pacific/Guam":"اقیانوس آرام/گوام",
"Pacific/Honolulu":"اقیانوس آرام/هونولولو",
"Pacific/Johnston":"اقیانوس آرام/جانستون",
"Pacific/Kanton":"اقیانوس آرام/کانتون",
"Pacific/Kiritimati":"اقیانوس آرام/کیریتیماتی",
"Pacific/Kosrae":"اقیانوس آرام/کوسرائه",
"Pacific/Kwajalein":"اقیانوس آرام/کواجلین",
"Pacific/Majuro":"اقیانوس آرام/ماجورو",
"Pacific/Marquesas":"اقیانوس آرام/مارکزاس",
"Pacific/Midway":"اقیانوس آرام/میدوی",
"Pacific/Nauru":"اقیانوس آرام/نائورو",
"Pacific/Niue":"اقیانوس آرام/نیو",
"Pacific/Norfolk":"اقیانوس آرام/نورفولک",
"Pacific/Noumea":"اقیانوس آرام/نومیا",
"Pacific/Pago_Pago":"اقیانوس آرام/پاگو_پاگو",
"Pacific/Palau":"اقیانوس آرام/پالائو",
"Pacific/Pitcairn":"اقیانوس آرام/پیتکرن",
"Pacific/Pohnpei":"اقیانوس آرام/پهنپی",
"Pacific/Ponape":"اقیانوس آرام/پوناپه",
"Pacific/Port_Moresby":"اقیانوس آرام/پورت_مورسبی",
"Pacific/Rarotonga":"اقیانوس آرام/راروتونگا",
"Pacific/Saipan":"اقیانوس آرام/سایپان",
"Pacific/Samoa":"اقیانوس آرام/ساموآ",
"Pacific/Tahiti":"اقیانوس آرام/تاهیتی",
"Pacific/Tarawa":"اقیانوس آرام/تاراوا",
"Pacific/Tongatapu":"اقیانوس آرام/تونگاتاپو",
"Pacific/Truk":"اقیانوس آرام/تروک",
"Pacific/Wake":"اقیانوس آرام/بیدار شدن",
"Pacific/Wallis":"اقیانوس آرام/والیس",
"Pacific/Yap":"اقیانوس آرام/یاپ",
"Poland":"لهستان",
"Portugal":"پرتغال",
"ROC":"روک",
"ROK":"سال",
"Singapore":"سنگاپور",
"Turkey":"ترکیه",
"UCT":"UCT",
"US/Alaska":"ایالات متحده آمریکا/آلاسکا",
"US/Aleutian":"آمریکا/آلئوتی",
"US/Arizona":"آمریکا/آریزونا",
"US/Central":"آمریکا/مرکزی",
"US/East-Indiana":"آمریکا/ایندیانای شرقی",
"US/Eastern":"آمریکا/شرق",
"US/Hawaii":"آمریکا/هاوایی",
"US/Indiana-Starke":"ایالات متحده آمریکا/ایندیانا-استارک",
"US/Michigan":"آمریکا/میشیگان",
"US/Mountain":"آمریکا/کوه",
"US/Pacific":"آمریکا/اقیانوس آرام",
"US/Samoa":"آمریکا/ساموآ",
"UTC":"یوتی‌سی",
"Universal":"جهانی",
"W-SU":"وا-سو",
"WET":"مرطوب",
"Zulu":"زولو",
}