import showToast from '../utils/toast'
import initialState from '../store/initialState'

export default (state = initialState.loading, action) => {
  if (action.type.match(/.*SUCCESS/)) {
    return false
  }
  if (action.type.match(/.*FAILURE/)) {
    if (action.message) {
      showToast(action.message, 'danger', 3000)
    }
    return false
  }
  if (action.type.match(/.*AILURE/)) {
    return false
  }
  if (action.type.match(/.*CONNECTION_FAILED/)) {
    if (action.message) {
      showToast(action.message, 'warning', 5000)
    }
    return false
  }
  if (action.type.match(/.*REQUEST/)) {
    return true
  }

  return state
}
