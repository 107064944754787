export default {
    "Africa/Abidjan": "Afryka/Abidżan",
"Africa/Accra": "Afryka/Akra",
"Africa/Addis_Ababa": "Afryka/Addis_Abeba",
"Africa/Algiers": "Afryka/Algier",
"Africa/Asmara": "Afryka/Asmara",
"Africa/Asmera": "Afryka/Asmera",
"Africa/Bamako": "Afryka/Bamako",
"Africa/Bangui": "Afryka/Bangui",
"Africa/Banjul": "Afryka/Banjul",
"Africa/Bissau": "Afryka/Bissau",
"Africa/Blantyre": "Afryka/Blantyre",
"Africa/Brazzaville": "Afryka/Brazzaville",
"Africa/Bujumbura": "Afryka/Bużumbura",
"Africa/Cairo": "Afryka/Kair",
"Africa/Casablanca": "Afryka/Casablanca",
"Africa/Ceuta": "Afryka/Ceuta",
"Africa/Conakry": "Afryka/Conakry",
"Africa/Dakar": "Afryka/Dakar",
"Africa/Dar_es_Salaam": "Afryka/Dar_es_Salaam",
"Africa/Djibouti": "Afryka/Dżibuti",
"Africa/Douala": "Afryka/Douala",
"Africa/El_Aaiun": "Afryka/El_Aaiun",
"Africa/Freetown": "Afryka/Freetown",
"Africa/Gaborone": "Afryka/Gaborone",
"Africa/Harare": "Afryka/Harare",
"Africa/Johannesburg": "Afryka/Johannesburg",
"Africa/Juba": "Afryka/Juba",
"Africa/Kampala": "Afryka/Kampala",
"Africa/Khartoum": "Afryka/Chartum",
"Africa/Kigali": "Afryka/Kigali",
"Africa/Kinshasa": "Afryka/Kinszasa",
"Africa/Lagos": "Afryka/Lagos",
"Africa/Libreville": "Afryka/Libreville",
"Africa/Lome": "Afryka/Lome",
"Africa/Luanda": "Afryka/Luanda",
"Africa/Lubumbashi": "Afryka/Lubumbashi",
"Africa/Lusaka": "Afryka/Lusaka",
"Africa/Malabo": "Afryka/Malabo",
"Africa/Maputo": "Afryka/Maputo",
"Africa/Maseru": "Afryka/Maseru",
"Africa/Mbabane": "Afryka/Mbabane",
"Africa/Mogadishu": "Afryka/Mogadiszu",
"Africa/Monrovia": "Afryka/Monrowia",
"Africa/Nairobi": "Afryka/Nairobi",
"Africa/Ndjamena": "Afryka/Ndjamena",
"Africa/Niamey": "Afryka/Niamey",
"Africa/Nouakchott": "Afryka/Nouakchott",
"Africa/Ouagadougou": "Afryka/Wagadugu",
"Africa/Porto-Novo": "Afryka/Porto-Nowo",
"Africa/Sao_Tome": "Afryka/Sao_Tome",
"Africa/Timbuktu": "Afryka/Timbuktu",
"Africa/Tripoli": "Afryka/Trypolis",
"Africa/Tunis": "Afryka/Tunis",
"Africa/Windhoek": "Afryka/Windhoek",
"America/Adak": "Ameryka/Adak",
"America/Anchorage": "Ameryka/Anchorage",
"America/Anguilla": "Ameryka/Anguilla",
"America/Antigua": "Ameryka/Antigua",
"America/Araguaina": "Ameryka/Araguaina",
"America/Argentina/Buenos_Aires": "Ameryka/Argentyna/Buenos_Aires",
"America/Argentina/Catamarca": "Ameryka/Argentyna/Katamarka",
"America/Argentina/ComodRivadavia": "Ameryka/Argentyna/Comodrivadavia",
"America/Argentina/Cordoba": "Ameryka/Argentyna/Cordoba",
"America/Argentina/Jujuy": "Ameryka/Argentyna/Jujuy",
"America/Argentina/La_Rioja": "Ameryka/Argentyna/La_Rioja",
"America/Argentina/Mendoza": "Ameryka/Argentyna/Mendoza",
"America/Argentina/Rio_Gallegos": "Ameryka/Argentyna/Rio_Gallegos",
"America/Argentina/Salta": "Ameryka/Argentyna/Salta",
"America/Argentina/San_Juan": "Ameryka/Argentyna/San_Juan",
"America/Argentina/San_Luis": "Ameryka/Argentyna/San_Luis",
"America/Argentina/Tucuman": "Ameryka/Argentyna/Tucuman",
"America/Argentina/Ushuaia": "Ameryka/Argentyna/Ushuaia",
"America/Aruba": "Ameryka/Aruba",
"America/Asuncion": "Ameryka/Asuncion",
"America/Atikokan": "Ameryka/Atikokan",
"America/Atka": "Ameryka/Atka",
"America/Bahia": "Ameryka/Bahia",
"America/Bahia_Banderas": "Ameryka/Bahia_Banderas",
"America/Barbados": "Ameryka/Barbados",
"America/Belem": "Ameryka/Belem",
"America/Belize": "Ameryka/Belize",
"America/Blanc-Sablon": "Ameryka/Blanc-Sablon",
"America/Boa_Vista": "Ameryka/Boa_Vista",
"America/Bogota": "Ameryka/Bogota",
"America/Boise": "Ameryka/Boise",
"America/Buenos_Aires": "Ameryka/Buenos_Aires",
"America/Cambridge_Bay": "Ameryka/Cambridge_Bay",
"America/Campo_Grande": "Ameryka/Campo_Grande",
"America/Cancun": "Ameryka/Cancun",
"America/Caracas": "Ameryka/Caracas",
"America/Catamarca": "Ameryka/Katamarka",
"America/Cayenne": "Ameryka/Cayenne",
"America/Cayman": "Ameryka/Kajman",
"America/Chicago": "Ameryka/Chicago",
"America/Chihuahua": "Ameryka/Chihuahua",
"America/Ciudad_Juarez": "Ameryka/Ciudad_Juarez",
"America/Coral_Harbour": "Ameryka/Coral_Harbour",
"America/Cordoba": "Ameryka/Kordoba",
"America/Costa_Rica": "Ameryka/Costa_Rica",
"America/Creston": "Ameryka/Creston",
"America/Cuiaba": "Ameryka/Cuiaba",
"America/Curacao": "Ameryka/Curacao",
"America/Danmarkshavn": "Ameryka/Danmarkshavn",
"America/Dawson": "Ameryka/Dawson",
"America/Dawson_Creek": "Ameryka/Dawson_Creek",
"America/Denver": "Ameryka/Denver",
"America/Detroit": "Ameryka/Detroit",
"America/Dominica": "Ameryka/Dominika",
"America/Edmonton": "Ameryka/Edmonton",
"America/Eirunepe": "Ameryka/Eirunepe",
"America/El_Salvador": "Ameryka/El_Salvador",
"America/Ensenada": "Ameryka/Ensenada",
"America/Fort_Nelson": "Ameryka/Fort_Nelson",
"America/Fort_Wayne": "Ameryka/Fort_Wayne",
"America/Fortaleza": "Ameryka/Fortaleza",
"America/Glace_Bay": "Ameryka/Glace_Bay",
"America/Godthab": "Ameryka/Godthab",
"America/Goose_Bay": "Ameryka/Goose_Bay",
"America/Grand_Turk": "Ameryka/Grand_Turk",
"America/Grenada": "Ameryka/Grenada",
"America/Guadeloupe": "Ameryka/Gwadelupa",
"America/Guatemala": "Ameryka/Gwatemala",
"America/Guayaquil": "Ameryka/Guayaquil",
"America/Guyana": "Ameryka/Gujana",
"America/Halifax": "Ameryka/Halifax",
"America/Havana": "Ameryka/Hawana",
"America/Hermosillo": "Ameryka/Hermosillo",
"America/Indiana/Indianapolis": "Ameryka/Indiana/Indianapolis",
"America/Indiana/Knox": "Ameryka/Indiana/Knox",
"America/Indiana/Marengo": "Ameryka/Indiana/Marengo",
"America/Indiana/Petersburg": "Ameryka/Indiana/Petersburg",
"America/Indiana/Tell_City": "Ameryka/Indiana/Tell_City",
"America/Indiana/Vevay": "Ameryka/Indiana/Vevay",
"America/Indiana/Vincennes": "Ameryka/Indiana/Vincennes",
"America/Indiana/Winamac": "Ameryka/Indiana/Winamac",
"America/Indianapolis": "Ameryka/Indianapolis",
"America/Inuvik": "Ameryka/Inuvik",
"America/Iqaluit": "Ameryka/Iqaluit",
"America/Jamaica": "Ameryka/Jamajka",
"America/Jujuy": "Ameryka/Jujuy",
"America/Juneau": "Ameryka/Juneau",
"America/Kentucky/Louisville": "Ameryka/Kentucky/Louisville",
"America/Kentucky/Monticello": "Ameryka/Kentucky/Monticello",
"America/Knox_IN": "Ameryka/Knox_in",
"America/Kralendijk": "Ameryka/Kralendijk",
"America/La_Paz": "Ameryka/La_Paz",
"America/Lima": "Ameryka/Lima",
"America/Los_Angeles": "Ameryka/Los_Angeles",
"America/Louisville": "Ameryka/Louisville",
"America/Lower_Princes": "Ameryka/Lower_Princes",
"America/Maceio": "Ameryka/Maceio",
"America/Managua": "Ameryka/Managua",
"America/Manaus": "Ameryka/Manaus",
"America/Marigot": "Ameryka/Marigot",
"America/Martinique": "Ameryka/Martynika",
"America/Matamoros": "Ameryka/Matamoros",
"America/Mazatlan": "Ameryka/Mazatlan",
"America/Mendoza": "Ameryka/Mendoza",
"America/Menominee": "Ameryka/Menominee",
"America/Merida": "Ameryka/Merida",
"America/Metlakatla": "Ameryka/Metlakatla",
"America/Mexico_City": "Ameryka/Meksykańskie miasto",
"America/Miquelon": "Ameryka/Miquelon",
"America/Moncton": "Ameryka/Moncton",
"America/Monterrey": "Ameryka/Monterrey",
"America/Montevideo": "Ameryka/Montevideo",
"America/Montreal": "Ameryka/Montreal",
"America/Montserrat": "Ameryka/Montserrat",
"America/Nassau": "Ameryka/Nassau",
"America/New_York": "Ameryka/Nowy Jork",
"America/Nipigon": "Ameryka/Nipigon",
"America/Nome": "Ameryka/Nazwa",
"America/Noronha": "Ameryka/Noronha",
"America/North_Dakota/Beulah": "Ameryka/Północna Dakota/Beulah",
"America/North_Dakota/Center": "Ameryka/Północna Dakota/Centrum",
"America/North_Dakota/New_Salem": "Ameryka/Północna Dakota/New_Salem",
"America/Nuuk": "Ameryka/Nowy Jork",
"America/Ojinaga": "Ameryka/Ojinaga",
"America/Panama": "Ameryka/Panama",
"America/Pangnirtung": "Ameryka/Pangnirtung",
"America/Paramaribo": "Ameryka/Paramaribo",
"America/Phoenix": "Ameryka/Phoenix",
"America/Port-au-Prince": "Ameryka/Port-au-Prince",
"America/Port_of_Spain": "Ameryka/Port_Hiszpanii",
"America/Porto_Acre": "Ameryka/Porto_Acre",
"America/Porto_Velho": "Ameryka/Porto_Velho",
"America/Puerto_Rico": "Ameryka/Puerto_Rico",
"America/Punta_Arenas": "Ameryka/Punta_Arenas",
"America/Rainy_River": "Ameryka/Rainy_River",
"America/Rankin_Inlet": "Ameryka/Rankin_Inlet",
"America/Recife": "Ameryka/Recife",
"America/Regina": "Ameryka/Regina",
"America/Resolute": "Ameryka/Zdecydowany",
"America/Rio_Branco": "Ameryka/Rio_Branco",
"America/Rosario": "Ameryka/Rosario",
"America/Santa_Isabel": "Ameryka/Santa_Isabel",
"America/Santarem": "Ameryka/Santarem",
"America/Santiago": "Ameryka/Santiago",
"America/Santo_Domingo": "Ameryka/Santo_Domingo",
"America/Sao_Paulo": "Ameryka/Sao_Paulo",
"America/Scoresbysund": "Ameryka/Scoresbysund",
"America/Shiprock": "Ameryka/Shiprock",
"America/Sitka": "Ameryka/Sitka",
"America/St_Barthelemy": "Ameryka/St_Barthelemy",
"America/St_Johns": "Ameryka/St_Johns",
"America/St_Kitts": "Ameryka/St_Kitts",
"America/St_Lucia": "Ameryka/St_Lucia",
"America/St_Thomas": "Ameryka/St_Thomas",
"America/St_Vincent": "Ameryka/St_Vincent",
"America/Swift_Current": "Ameryka/Swift_Current",
"America/Tegucigalpa": "Ameryka/Tegucigalpa",
"America/Thule": "Ameryka/Thule",
"America/Thunder_Bay": "Ameryka/Thunder_Bay",
"America/Tijuana": "Ameryka/Tijuana",
"America/Toronto": "Ameryka/Toronto",
"America/Tortola": "Ameryka/Tortola",
"America/Vancouver": "Ameryka/Vancouver",
"America/Virgin": "Ameryka/Dziewica",
"America/Whitehorse": "Ameryka/Whitehorse",
"America/Winnipeg": "Ameryka/Winnipeg",
"America/Yakutat": "Ameryka/Yakutat",
"America/Yellowknife": "Ameryka/Yellowknife",
"Antarctica/Casey": "Antarktyka/Casey",
"Antarctica/Davis": "Antarktyka/Davis",
"Antarctica/DumontDUrville": "Antarktyka/Dumontdurville",
"Antarctica/Macquarie": "Antarktyka/Macquarie",
"Antarctica/Mawson": "Antarktyka/Mawson",
"Antarctica/McMurdo": "Antarktyka/McMurdo",
"Antarctica/Palmer": "Antarktyka/Palmer",
"Antarctica/Rothera": "Antarktyka/Rothera",
"Antarctica/South_Pole": "Antarktyka/biegun południowy",
"Antarctica/Syowa": "Antarktyka/Syowa",
"Antarctica/Troll": "Antarktyka/Troll",
"Antarctica/Vostok": "Antarktyka/Wostok",
"Arctic/Longyearbyen": "Arktyka/Longyearbyen",
"Asia/Aden": "Azja/Aden",
"Asia/Almaty": "Azja/Ałmaty",
"Asia/Amman": "Azja/Amman",
"Asia/Anadyr": "Azja/Anadyr",
"Asia/Aqtau": "Azja/Aqtau",
"Asia/Aqtobe": "Azja/Aqtobe",
"Asia/Ashgabat": "Azja/Aszchabad",
"Asia/Ashkhabad": "Azja/Aszchabad",
"Asia/Atyrau": "Azja/Atyrau",
"Asia/Baghdad": "Azja/Bagdad",
"Asia/Bahrain": "Azja/Bahrajn",
"Asia/Baku": "Azja/Baku",
"Asia/Bangkok": "Azja/Bangkok",
"Asia/Barnaul": "Azja/Barnauł",
"Asia/Beirut": "Azja/Bejrut",
"Asia/Bishkek": "Azja/Biszkek",
"Asia/Brunei": "Azja/Brunei",
"Asia/Calcutta": "Azja/Kalkuta",
"Asia/Chita": "Azja/Chita",
"Asia/Choibalsan": "Azja/Choibalsan",
"Asia/Chongqing": "Azja/Chongqing",
"Asia/Chungking": "Azja/Chungking",
"Asia/Colombo": "Azja/Kolombo",
"Asia/Dacca": "Azja/Dakka",
"Asia/Damascus": "Azja/Damaszek",
"Asia/Dhaka": "Azja/Dhaka",
"Asia/Dili": "Azja/Dili",
"Asia/Dubai": "Azja/Dubaj",
"Asia/Dushanbe": "Azja/Duszanbe",
"Asia/Famagusta": "Azja/Famagusta",
"Asia/Gaza": "Azja/Gaza",
"Asia/Harbin": "Azja/Harbin",
"Asia/Hebron": "Azja/Hebron",
"Asia/Ho_Chi_Minh": "Azja/Ho_Chi_Minh",
"Asia/Hong_Kong": "Azja/Hongkong",
"Asia/Hovd": "Azja/Hovd",
"Asia/Irkutsk": "Azja/Irkuck",
"Asia/Istanbul": "Azja/Stambuł",
"Asia/Jakarta": "Azja/Dżakarta",
"Asia/Jayapura": "Azja/Dżajapura",
"Asia/Jerusalem": "Azja/Jerozolima",
"Asia/Kabul": "Azja/Kabul",
"Asia/Kamchatka": "Azja/Kamczatka",
"Asia/Karachi": "Azja/Karaczi",
"Asia/Kashgar": "Azja/Kaszgar",
"Asia/Kathmandu": "Azja/Katmandu",
"Asia/Katmandu": "Azja/Katmandu",
"Asia/Khandyga": "Azja/Khandyga",
"Asia/Kolkata": "Azja/Kalkuta",
"Asia/Krasnoyarsk": "Azja/Krasnojarsk",
"Asia/Kuala_Lumpur": "Azja/Kuala_Lumpur",
"Asia/Kuching": "Azja/Kuching",
"Asia/Kuwait": "Azja/Kuwejt",
"Asia/Macao": "Azja/Makau",
"Asia/Macau": "Azja/Makau",
"Asia/Magadan": "Azja/Magadan",
"Asia/Makassar": "Azja/Makasar",
"Asia/Manila": "Azja/Manila",
"Asia/Muscat": "Asia/Muscat",
"Asia/Nicosia": "Azja/Nikozja",
"Asia/Novokuznetsk": "Azja/Nowokuźnieck",
"Asia/Novosibirsk": "Azja/Nowosybirsk",
"Asia/Omsk": "Azja/Omsk",
"Asia/Oral": "Azja/Ustny",
"Asia/Phnom_Penh": "Azja/Phnom_Penh",
"Asia/Pontianak": "Azja/Pontianak",
"Asia/Pyongyang": "Azja/Pjongjang",
"Asia/Qatar": "Azja/Katar",
"Asia/Qostanay": "Azja/Qostanay",
"Asia/Qyzylorda": "Azja/Qyzylorda",
"Asia/Rangoon": "Azja/Rangoon",
"Asia/Riyadh": "Azja/Rijad",
"Asia/Saigon": "Azja/Sajgon",
"Asia/Sakhalin": "Azja/Sachalin",
"Asia/Samarkand": "Azja/Samarkanda",
"Asia/Seoul": "Azja/Seul",
"Asia/Shanghai": "Azja/Szanghaj",
"Asia/Singapore": "Azja/Singapur",
"Asia/Srednekolymsk": "Azja/Srednekolymsk",
"Asia/Taipei": "Azja/Tajpej",
"Asia/Tashkent": "Azja/Taszkent",
"Asia/Tbilisi": "Azja/Tbilisi",
"Asia/Tehran": "Azja/Teheran",
"Asia/Tel_Aviv": "Azja/Tel_Awiw",
"Asia/Thimbu": "Azja/Thimbu",
"Asia/Thimphu": "Azja/Thimphu",
"Asia/Tokyo": "Azja/Tokio",
"Asia/Tomsk": "Azja/Tomsk",
"Asia/Ujung_Pandang": "Azja/Ujung_Pandang",
"Asia/Ulaanbaatar": "Azja/Ułan Bator",
"Asia/Ulan_Bator": "Azja/Ulan_Bator",
"Asia/Urumqi": "Azja/Urumczi",
"Asia/Ust-Nera": "Azja/Ust-Nera",
"Asia/Vientiane": "Azja/Wientiane",
"Asia/Vladivostok": "Azja/Władywostok",
"Asia/Yakutsk": "Azja/Jakuck",
"Asia/Yangon": "Azja/Rangun",
"Asia/Yekaterinburg": "Azja/Jekaterynburg",
"Asia/Yerevan": "Azja/Erywań",
"Atlantic/Azores": "Atlantyk/Azory",
"Atlantic/Bermuda": "Atlantyk/Bermudy",
"Atlantic/Canary": "Atlantyk/Kanary",
"Atlantic/Cape_Verde": "Atlantyk/Zielony Zielony Zielony",
"Atlantic/Faeroe": "Atlantyk/Owary",
"Atlantic/Faroe": "Atlantyk/Owczy",
"Atlantic/Jan_Mayen": "Atlantyk/Jan_Mayen",
"Atlantic/Madeira": "Atlantyk/Madera",
"Atlantic/Reykjavik": "Atlantyk/Reykjavik",
"Atlantic/South_Georgia": "Atlantyk/Południowa Georgia",
"Atlantic/St_Helena": "Atlantyk/Święta Helena",
"Atlantic/Stanley": "Atlantyk/Stanley",
"Australia/ACT": "Australia/ACT",
"Australia/Adelaide": "Australia/Adelajda",
"Australia/Brisbane": "Australia/Brisbane",
"Australia/Broken_Hill": "Australia/Broken_Hill",
"Australia/Canberra": "Australia/Canberra",
"Australia/Currie": "Australia/Currie",
"Australia/Darwin": "Australia/Darwin",
"Australia/Eucla": "Australia/Eucla",
"Australia/Hobart": "Australia/Hobart",
"Australia/LHI": "Australia/LHI",
"Australia/Lindeman": "Australia/Lindeman",
"Australia/Lord_Howe": "Australia/Lord_Howe",
"Australia/Melbourne": "Australia/Melbourne",
"Australia/NSW": "Australia/NSW",
"Australia/North": "Australia/Północ",
"Australia/Perth": "Australia/Perth",
"Australia/Queensland": "Australia/Queensland",
"Australia/South": "Australia/Południe",
"Australia/Sydney": "Australia/Sydney",
"Australia/Tasmania": "Australia/Tasmania",
"Australia/Victoria": "Australia/Wiktoria",
"Australia/West": "Australia/Zachód",
"Australia/Yancowinna": "Australia/Yancowinna",
"Brazil/Acre": "Brazylia/Acre",
"Brazil/DeNoronha": "Brazylia/Denoronha",
"Brazil/East": "Brazylia/Wschód",
"Brazil/West": "Brazylia/Zachód",
"CET": "TEN",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Kanada/Atlantyk",
"Canada/Central": "Kanada/Centralna",
"Canada/Eastern": "Kanada/Wschodnia",
"Canada/Mountain": "Kanada/Góra",
"Canada/Newfoundland": "Kanada/Nowa Fundlandia",
"Canada/Pacific": "Kanada/Pacyfik",
"Canada/Saskatchewan": "Kanada/Saskatchewan",
"Canada/Yukon": "Kanada/Yukon",
"Chile/Continental": "Chile/Kontynentalny",
"Chile/EasterIsland": "Chili/Wyspa Wschodnia",
"Cuba": "Kuba",
"EET": "JEŚĆ",
"EST": "EST",
"EST5EDT": "JEST 5 DNI",
"Egypt": "Egipt",
"Eire": "Wierze",
"Etc/GMT": "Etc/GMT",
"Etc/GMT+0": "Etc/GMT+0",
"Etc/GMT+1": "Etc/GMT+1",
"Etc/GMT+10": "Etc/GMT+10",
"Etc/GMT+11": "ITC/GMT+11",
"Etc/GMT+12": "ITC/GMT+12",
"Etc/GMT+2": "ITC/GMT+2",
"Etc/GMT+3": "ITC/GMT+3",
"Etc/GMT+4": "ITC/GMT+4",
"Etc/GMT+5": "ITC/GMT+5",
"Etc/GMT+6": "ITC/GMT+6",
"Etc/GMT+7": "Etc/GMT+7",
"Etc/GMT+8": "ITC/GMT+8",
"Etc/GMT+9": "Etc/GMT+9",
"Etc/GMT-0": "ITC/GMT-0",
"Etc/GMT-1": "ITC/GMT-1",
"Etc/GMT-10": "ITC/GMT-10",
"Etc/GMT-11": "ITC/GMT-11",
"Etc/GMT-12": "ITC/GMT-12",
"Etc/GMT-13": "ITC/GMT-13",
"Etc/GMT-14": "ITC/GMT-14",
"Etc/GMT-2": "ITC/GMT-2",
"Etc/GMT-3": "ITC/GMT-3",
"Etc/GMT-4": "ITC/GMT-4",
"Etc/GMT-5": "ITC/GMT-5",
"Etc/GMT-6": "ITC/GMT-6",
"Etc/GMT-7": "ITC/GMT-7",
"Etc/GMT-8": "ITC/GMT-8",
"Etc/GMT-9": "ITC/GMT-9",
"Etc/GMT0": "Etc/GMT0",
"Etc/Greenwich": "Etc/Greenwich",
"Etc/UCT": "Etc/UCT",
"Etc/UTC": "Etc/UTC",
"Etc/Universal": "Etc/Uniwersalny",
"Etc/Zulu": "Etc/Zulu",
"Europe/Amsterdam": "Europa/Amsterdam",
"Europe/Andorra": "Europa/Andora",
"Europe/Astrakhan": "Europa/Astrachań",
"Europe/Athens": "Europa/Ateny",
"Europe/Belfast": "Europa/Belfast",
"Europe/Belgrade": "Europa/Belgrad",
"Europe/Berlin": "Europa/Berlin",
"Europe/Bratislava": "Europa/Bratysława",
"Europe/Brussels": "Europa/Bruksela",
"Europe/Bucharest": "Europa/Bukareszt",
"Europe/Budapest": "Europa/Budapeszt",
"Europe/Busingen": "Europa/Busingen",
"Europe/Chisinau": "Europa/Kiszyniów",
"Europe/Copenhagen": "Europa/Kopenhaga",
"Europe/Dublin": "Europa/Dublin",
"Europe/Gibraltar": "Europa/Gibraltar",
"Europe/Guernsey": "Europa/Guernsey",
"Europe/Helsinki": "Europa/Helsinki",
"Europe/Isle_of_Man": "Europa/Isle_of_Man",
"Europe/Istanbul": "Europa/Stambuł",
"Europe/Jersey": "Europa/Jersey",
"Europe/Kaliningrad": "Europa/Kaliningrad",
"Europe/Kiev": "Europa/Kijów",
"Europe/Kirov": "Europa/Kirow",
"Europe/Kyiv": "Europa/Kijów",
"Europe/Lisbon": "Europa/Lizbona",
"Europe/Ljubljana": "Europa/Lublana",
"Europe/London": "Europa/Londyn",
"Europe/Luxembourg": "Europa/Luksemburg",
"Europe/Madrid": "Europa/Madryt",
"Europe/Malta": "Europa/Malta",
"Europe/Mariehamn": "Europa/Mariehamn",
"Europe/Minsk": "Europa/Mińsk",
"Europe/Monaco": "Europa/Monako",
"Europe/Moscow": "Europa/Moskwa",
"Europe/Nicosia": "Europa/Nikozja",
"Europe/Oslo": "Europa/Oslo",
"Europe/Paris": "Europa/Paryż",
"Europe/Podgorica": "Europa/Podgorica",
"Europe/Prague": "Europa/Praga",
"Europe/Riga": "Europa/Ryga",
"Europe/Rome": "Europa/Rzym",
"Europe/Samara": "Europa/Samara",
"Europe/San_Marino": "Europa/San_Marino",
"Europe/Sarajevo": "Europa/Sarajewo",
"Europe/Saratov": "Europa/Saratów",
"Europe/Simferopol": "Europa/Symferopol",
"Europe/Skopje": "Europa/Skopje",
"Europe/Sofia": "Europa/Sofia",
"Europe/Stockholm": "Europa/Sztokholm",
"Europe/Tallinn": "Europa/Tallin",
"Europe/Tirane": "Europa/Tirane",
"Europe/Tiraspol": "Europa/Tyraspol",
"Europe/Ulyanovsk": "Europa/Uljanowsk",
"Europe/Uzhgorod": "Europa/Użgorod",
"Europe/Vaduz": "Europa/Vaduz",
"Europe/Vatican": "Europa/Watykan",
"Europe/Vienna": "Europa/Wiedeń",
"Europe/Vilnius": "Europa/Wilno",
"Europe/Volgograd": "Europa/Wołgograd",
"Europe/Warsaw": "Europa/Warszawa",
"Europe/Zagreb": "Europa/Zagrzeb",
"Europe/Zaporozhye": "Europa/Zaporoże",
"Europe/Zurich": "Europa/Zurych",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "HST",
"Hongkong": "Hongkong",
"Iceland": "Islandia",
"Indian/Antananarivo": "Indyjski/Antananarywo",
"Indian/Chagos": "Indyjski/Chagos",
"Indian/Christmas": "Indyjski/Boże Narodzenie",
"Indian/Cocos": "Indyjski/Kokosowy",
"Indian/Comoro": "Indyjski/Komoro",
"Indian/Kerguelen": "Indyjski/Kerguelen",
"Indian/Mahe": "Indyjski/Mahe",
"Indian/Maldives": "Indyjski/Malediwy",
"Indian/Mauritius": "Indyjski/Mauritius",
"Indian/Mayotte": "Indyjski/Majotta",
"Indian/Reunion": "Indyjski/Reunion",
"Iran": "Iran",
"Israel": "Izrael",
"Jamaica": "Jamajka",
"Japan": "Japonia",
"Kwajalein": "Kwajalein",
"Libya": "Libia",
"MET": "SPOTKAŁEM SIĘ",
"MST": "MST",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Meksyk/Bajanorte",
"Mexico/BajaSur": "Meksyk/Bajasur",
"Mexico/General": "Meksyk/Ogólne",
"NZ": "NZ",
"NZ-CHAT": "CZAT NZ",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Pacyfik/Apia",
"Pacific/Auckland": "Pacyfik/Auckland",
"Pacific/Bougainville": "Pacyfik/Bougainville",
"Pacific/Chatham": "Pacyfik/Chatham",
"Pacific/Chuuk": "Pacyfik/Chuuk",
"Pacific/Easter": "Pacyfik/Wielkanoc",
"Pacific/Efate": "Pacyfik/Efate",
"Pacific/Enderbury": "Pacyfik/Enderbury",
"Pacific/Fakaofo": "Pacyfik/Fakaofo",
"Pacific/Fiji": "Pacyfik/Fidżi",
"Pacific/Funafuti": "Pacyfik/Funafuti",
"Pacific/Galapagos": "Pacyfik/Galapagos",
"Pacific/Gambier": "Pacyfik/Gambier",
"Pacific/Guadalcanal": "Pacyfik/Guadalcanal",
"Pacific/Guam": "Pacyfik/Guam",
"Pacific/Honolulu": "Pacyfik/Honolulu",
"Pacific/Johnston": "Pacyfik/Johnston",
"Pacific/Kanton": "Pacyfik/Kanton",
"Pacific/Kiritimati": "Pacyfik/Kiritimati",
"Pacific/Kosrae": "Pacyfik/Kosrae",
"Pacific/Kwajalein": "Pacyfik/Kwajalein",
"Pacific/Majuro": "Pacyfik/Majuro",
"Pacific/Marquesas": "Pacyfik/Marquesas",
"Pacific/Midway": "Pacyfik/Midway",
"Pacific/Nauru": "Pacyfik/Nauru",
"Pacific/Niue": "Pacyfik/Niue",
"Pacific/Norfolk": "Pacyfik/Norfolk",
"Pacific/Noumea": "Pacyfik/Noumea",
"Pacific/Pago_Pago": "Pacyfik/Pago_Pago",
"Pacific/Palau": "Pacyfik/Palau",
"Pacific/Pitcairn": "Pacyfik/Pitcairn",
"Pacific/Pohnpei": "Pacyfik/Pohnpei",
"Pacific/Ponape": "Pacyfik/Ponape",
"Pacific/Port_Moresby": "Pacyfik/Port_Moresby",
"Pacific/Rarotonga": "Pacyfik/Rarotonga",
"Pacific/Saipan": "Pacyfik/Saipan",
"Pacific/Samoa": "Pacyfik/Samoa",
"Pacific/Tahiti": "Pacyfik/Tahiti",
"Pacific/Tarawa": "Pacyfik/Tarawa",
"Pacific/Tongatapu": "Pacyfik/Tongatapu",
"Pacific/Truk": "Pacyfik/Truk",
"Pacific/Wake": "Pacyfik/Przebudzenie",
"Pacific/Wallis": "Pacyfik/Wallis",
"Pacific/Yap": "Pacyfik/Yap",
"Poland": "Polska",
"Portugal": "Portugalia",
"ROC": "ROC",
"ROK": "ROK",
"Singapore": "Singapur",
"Turkey": "Turcja",
"UCT": "UCT",
"US/Alaska": "USA/Alaska",
"US/Aleutian": "USA/Aleucki",
"US/Arizona": "USA/Arizona",
"US/Central": "USA/Central",
"US/East-Indiana": "USA/Wschodnia Indiana",
"US/Eastern": "USA/Wschodnia",
"US/Hawaii": "USA/Hawaje",
"US/Indiana-Starke": "USA/Indiana-Starke",
"US/Michigan": "USA/Michigan",
"US/Mountain": "US/Góra",
"US/Pacific": "USA/Pacyfik",
"US/Samoa": "USA/Samoa",
"UTC": "UTC",
"Universal": "Uniwersalny",
"W-SU": "W SU",
"WET": "MOKRY",
"Zulu": "Zulu",
}