import React, { Component } from 'react'
import { View, Platform } from 'react-native'
import WebDateInput from '../webDatePicker/WebDateInput'

import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import DatePickerNative from '../appDatePicker/DatePickerNative'

export class DateTime24 extends Component {
  state = {
    date: null,
    isInitialValue: true,
  }

  componentDidMount() {
    this.setInitialDateValue()
  }

  setInitialDateValue = () => {
    const {
      field: { crfData },
    } = this.props
    this.setState({
      date: crfData && crfData.fieldValue ? crfData.fieldValue : null,
      isInitialValue: true,
    })
  }

  changeDate = (date) => {
    const {
      form,
      field: { id },
      subjectTimezone,
    } = this.props
    const { setFieldsValue, getFieldsValue } = form
    this.setState({ date })
    setFieldsValue({
      [id]: Platform.OS !== 'web' ? date : date + ' ' + subjectTimezone,
    })
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema },
      t,
      subjectTimezone,
      disabled,
      fieldAnswer,
    } = this.props
    const { isInitialValue, date } = this.state
    const crfData = { ...fieldAnswer }
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {this.props.form.getFieldDecorator(`${id}`, {
          initialValue: crfData && crfData.fieldValue ? crfData.fieldValue : null,
          valuePropName: Platform.OS === 'web' ? 'date' : 'value',
          rules: [
            {
              required: isRequired,
              message: t('DateValMsg'),
              whitespace: true,
            },
          ],
        })(
          Platform.OS !== 'web' ? (
            <DatePickerNative
              mode="datetime24"
              onChange={(date) => this.changeDate(date)}
              shortTimezone={subjectTimezone}
              valueFormat="DD-MMM-YYYY HH:mm z"
              setFormat="DD-MMM-YYYY hh:mm a"
              disabled={disabled}
              testID={`DateTime24-Field-${id}-view`}
            />
          ) : (
            <WebDateInput
              onDateChange={this.changeDate}
              format="dd-MMM-yyyy HH:mm"
              showTimeInput={true}
              timeFormat="HH:mm"
              placeHolder={t('SelectDate')}
              valueFormat="DD-MMM-YYYY HH:mm"
              isInitialValue={isInitialValue}
              subjectTimezone={subjectTimezone}
              disabled={disabled}
              testID={`DateTime24-Field-${id}-view`}
            />
          )
        )}
      </View>
    )
  }
}
