import { connect } from 'react-redux'
import { View, Card } from 'native-base'
import React, { Component } from 'react'
import { StyleSheet, Text } from 'react-native'
import { fontResizer } from './styles/fontResizer'

class SubjectDetails extends Component {
  state = {}

  render() {
    const {
      navigation,
      subject,
      screenProps: { t },
    } = this.props
    return (
      <View>
        <Card
          style={{
            borderRadius: 2,
            flexDirection: 'row',
            borderColor: 'grey',
          }}
        >
          <View style={{ flex: 1, backgroundColor: '#fff', padding: 20 }}>
            {subject.firstName ? (
              <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
                {t('firstName')}: {subject.firstName}
              </Text>
            ) : (
              ''
            )}
            {subject.lastName ? (
              <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
                {t('lastName')}: {subject.lastName}
              </Text>
            ) : (
              ''
            )}
            <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
              {t('email')}: {subject.email}
            </Text>
            <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
              {t('phone')}: {subject.phoneNo}
            </Text>
            <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
              {t('language')}: {subject.language}
            </Text>
          </View>
        </Card>
      </View>
    )
  }
}

export const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  iconContainer: {
    flex: 1,
  },
  textContainer: {
    flex: 5,
    alignSelf: 'flex-end',
  },
  text: {
    fontSize: 16,
    fontFamily: 'Inter',
    padding: 7,
  },
})

const mapStateToProps = (state) => ({
  subject: state.users.currentUser,
})

export default connect(mapStateToProps)(SubjectDetails)
