import { Formik } from 'formik'
import React, { Component, Fragment } from 'react'
import { Input, Item, List, ListItem } from 'native-base'
import _ from 'lodash'
import * as Yup from 'yup'
import {
  Text,
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
  FlatList,
} from 'react-native'
import { styles as loginStyles } from './styles/loginStyles'
import { getTzObject } from '../utils/timezoneUtils'
import appConstants from '../constants/appConstants'
import moment from 'moment-timezone'
import { SafeAreaView } from 'react-native'
import CustomTextInput from './common/CustomTextInput'

const { width: SCREEN_WIDTH} = Dimensions.get('window')
const { height } = Dimensions.get('window');
const SCREEN_HEIGHT = Platform.OS === 'web' ? height*0.47 : height*0.70;

const ViewWrapper = ({ t, children }) => (
  <View style={styles.viewContainer}>
    <Text style={styles.headerText}>{t('SelectTimezone')}</Text>
    {children}
  </View>
)

const SelectedTimezoneScreen = ({ t, timeZoneFullName, showChangeScreen, handleSubmit }) => (
  <Fragment>
    <TouchableOpacity
      onPress={showChangeScreen}
      style={styles.changeButton}
      testID={`selecttimezone-changetimezone-view`}
      accessible={true}
    >
      <View pointerEvents="none">
        <Item
          style={[loginStyles.inputStyle]}
          testID={`selecttimezone-timezoneitem-${timeZoneFullName}`}
          accessible={true}
        >
          <Input
            disabled
            style={loginStyles.inputText}
            value={timeZoneFullName}
            placeholder={t('SelTimezone')}
            placeholderTextColor="#bdbdbd"
          />
        </Item>
      </View>
    </TouchableOpacity>
    <TouchableOpacity
      onPress={handleSubmit}
      style={styles.submitButton}
      testID={`selecttimezone-savetimezone-btn`}
      accessible={true}
    >
      <Text style={styles.submitText}>{t('Save')}</Text>
    </TouchableOpacity>
  </Fragment>
)

const ChangeTimezoneScreen = ({ selectedTimezone, item, handleChangeTimezone,t }) => (
      <List>
            <ListItem
              key={item}
              title={item}
              selected={selectedTimezone.timeZoneName === item}
              style={selectedTimezone.timeZoneName === item ? [styles.listItem, { backgroundColor: '#f2f2f2' }] : styles.listItem}
              onPress={() => handleChangeTimezone(item)}
            >
              <Text style={styles.text}>{item}</Text>
            </ListItem>
      </List>
)

class ChangeSubjectTimezone extends Component {
  constructor(props) {
    super(props)

    const { timezone, timezoneList } = props
    const selectedTimezone = getTzObject(timezone, timezoneList)
    const state = {
      changeScreenVisible: false,
      selectedTimezone,
      isDraft: false,
      searchTerm: '',
    }
    this.state = state
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term })
  }

  onChangeTimeZone(timeZone) {
    const {
      changeScreenVisible,
      selectedTimezone,
    } = this.state
    const {timezoneList} = this.props
    const updatedSelectedTimezone = getTzObject(timeZone, timezoneList)
    this.setState(prevState => ({
      ...prevState,
      changeScreenVisible: false ,
      selectedTimezone: {...updatedSelectedTimezone},
      isDraft: true,
      searchTerm:'',
      }))
  }

  render() {
    const {
      changeScreenVisible,
      selectedTimezone,
      isDraft,
      searchTerm
    } = this.state
    const timeZoneName = selectedTimezone?.timeZoneName
    const {
      navigation,
      screenProps: { t },
      timezoneList,
      changeTimezone,
      submitTimezone,
      subjectStudyMetaData,
      updateIsSubjectRegistered,
    } = this.props
    var timeZoneObj={}
    timezoneList.forEach((item,index) => {
      var offset = moment.tz(item).format('Z')
      timeZoneObj[item] = '(GMT'+offset+') '+t(timezoneList[index])
    })
    const keys = Object.keys(timeZoneObj);
    const timezonesListWithGMTValues = Object.values(timeZoneObj);
    const timezone = Object.keys(timeZoneObj).filter(key => timeZoneObj[key] === timeZoneName);
    const filteredTimeZones = searchTerm ? _.filter(timezonesListWithGMTValues, (f) =>  _.toLower(f).includes(_.toLower(searchTerm))) : timezonesListWithGMTValues
    return (
      <View style={{ flex: 1 }}>
          <Formik
            initialValues={{
              timezone: timeZoneName,
            }}
            validationSchema={Yup.object({
              timezone: Yup.string().required(t('SelTimezone')),
            })}
            onSubmit={async () => {
              if(isDraft) {
                await submitTimezone(timezone[0])
              } else {
                await submitTimezone(subjectStudyMetaData?.subject?.timeZone)
              }
              this.setState({isDraft:false})
              const fromLogin = navigation.getParam("fromLogin")
              if(fromLogin){
                updateIsSubjectRegistered(true)
                if(Platform.OS === 'web') {
                  navigation.navigate(`${appConstants.urlPrefix}RootTabs`)
                } else {
                  navigation.navigate('PinSetup')
                }
              }
            }}
          >
            {(props) => {
              return (
                <ViewWrapper t={t} style={{flex:1}}>
                  {!changeScreenVisible ? (
                    <SelectedTimezoneScreen
                      t={t}
                      timeZoneFullName={isDraft ? timeZoneName : '(GMT'+ moment.tz(subjectStudyMetaData?.subject?.timeZone).format('Z')+') '+ t(subjectStudyMetaData?.subject?.timeZone) }
                      showChangeScreen={() => this.setState({ changeScreenVisible: true })}
                      handleSubmit={props.handleSubmit}
                    />
                  ) : (
                    <View style={{height:SCREEN_HEIGHT}}>
                      <CustomTextInput
                      numberOfLines={1}
                      autoFocus
                      fuzzy
                      sortResult
                      inputFocus
                      onChangeText={(term) => {
                        this.searchUpdated(term)
                      }}
                      value= {searchTerm}
                      style={styles.searchInput}
                      placeholder={t('SelTimezone')}
                      />
                       {Array.isArray(timezoneList) && timezoneList.length ? ( 
                        <SafeAreaView>
                          <FlatList
                            data={filteredTimeZones}
                            renderItem={({ item }) => (
                              <ChangeTimezoneScreen
                                    selectedTimezone={selectedTimezone}
                                    item={item}
                                    handleChangeTimezone={async (tz) => {
                                      props.setFieldValue("timezone", tz)
                                      this.onChangeTimeZone(tz);
                                    }}
                                    t={t}
                                  />
                            )}
                            keyExtractor={(item) => item} 
                            showsVerticalScrollIndicator={true}
                            scrollEnabled={true}
                            initialNumToRender={10} // Render only 10 items initially
                            maxToRenderPerBatch={10} // Render only 10 items per batch
                            windowSize={20} // Keep 20 items ready for display
                            updateCellsBatchingPeriod={100} // Update cells every 100ms
                            getItemLayout={(data, index) => ({
                              length: 50, // Assuming each item has a fixed height of 50
                              offset: 50 * index,
                              index,
                            })}
                            removeClippedSubviews={true}
                          style={{height: Platform.OS === 'web' ? SCREEN_HEIGHT : SCREEN_HEIGHT*0.70 , minHeight: SCREEN_HEIGHT/2,}}
            />
           
            </SafeAreaView> ):null}
                    </View>
                  )}
                </ViewWrapper>
              )
            }}
          </Formik>
      </View>
    )
  }
}

export default ChangeSubjectTimezone

const styles = StyleSheet.create({
  viewContainer: {
    padding: 10,
    marginTop: 10,
  },
  headerText: {
    fontSize: 18,
    fontFamily: 'Inter',
  },
  loadingContainer: {
    height: SCREEN_HEIGHT,
    width: SCREEN_WIDTH,
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  changeButton: {
    position: 'absolute',
    marginTop: 30,
    zIndex: 3,
    height: 40,
    width: '95%',
  },
  submitButton: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#9155FD',
    marginTop: 80,
    width: 100,
    borderRadius: 5,
  },
  submitText: {
    color: '#fff',
    alignItems: 'center',
    fontFamily: 'Inter',
    textTransform: 'uppercase',
  },
  listItem: {
    height: 60,
    paddingLeft: 10,
    paddingRight: 10,
  },
  searchInput: {
    padding: 20,
    marginTop: 30,
    borderColor: '#CCC',
    borderWidth: 1,
  },
})
