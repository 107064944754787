export default {
    "Africa/Abidjan": "非洲/阿比让",
"Africa/Accra": "非洲/阿克拉",
"Africa/Addis_Ababa": "非洲/亚的斯亚贝巴",
"Africa/Algiers": "非洲/阿尔及尔",
"Africa/Asmara": "非洲/阿斯马拉",
"Africa/Asmera": "非洲/阿斯马拉",
"Africa/Bamako": "非洲/巴马科",
"Africa/Bangui": "非洲/班吉",
"Africa/Banjul": "非洲/班珠尔",
"Africa/Bissau": "非洲/比绍",
"Africa/Blantyre": "非洲/布兰太尔",
"Africa/Brazzaville": "非洲/布拉柴维尔",
"Africa/Bujumbura": "非洲/布琼布拉",
"Africa/Cairo": "非洲/开罗",
"Africa/Casablanca": "非洲/卡萨布兰卡",
"Africa/Ceuta": "非洲/休达",
"Africa/Conakry": "非洲/科纳克里",
"Africa/Dakar": "非洲/达喀尔",
"Africa/Dar_es_Salaam": "非洲/达累斯萨拉姆",
"Africa/Djibouti": "非洲/吉布提",
"Africa/Douala": "非洲/杜阿拉",
"Africa/El_Aaiun": "非洲/艾尔阿尤恩",
"Africa/Freetown": "非洲/弗里敦",
"Africa/Gaborone": "非洲/哈博罗内",
"Africa/Harare": "非洲/哈拉雷",
"Africa/Johannesburg": "非洲/约翰内斯堡",
"Africa/Juba": "非洲/朱巴",
"Africa/Kampala": "非洲/坎帕拉",
"Africa/Khartoum": "非洲/喀土穆",
"Africa/Kigali": "非洲/基加利",
"Africa/Kinshasa": "非洲/金沙萨",
"Africa/Lagos": "非洲/拉各斯",
"Africa/Libreville": "非洲/利伯维尔",
"Africa/Lome": "非洲/洛美",
"Africa/Luanda": "非洲/罗安达",
"Africa/Lubumbashi": "非洲/卢本巴希",
"Africa/Lusaka": "非洲/卢萨卡",
"Africa/Malabo": "非洲/马拉博",
"Africa/Maputo": "非洲/马普托",
"Africa/Maseru": "非洲/马塞卢",
"Africa/Mbabane": "非洲/姆巴巴内",
"Africa/Mogadishu": "非洲/摩加迪沙",
"Africa/Monrovia": "非洲/蒙罗维亚",
"Africa/Nairobi": "非洲/内罗毕",
"Africa/Ndjamena": "非洲/恩贾梅纳",
"Africa/Niamey": "非洲/尼亚美",
"Africa/Nouakchott": "非洲/努瓦克肖特",
"Africa/Ouagadougou": "非洲／瓦加杜古",
"Africa/Porto-Novo": "非洲/波多诺伏",
"Africa/Sao_Tome": "非洲/圣多美",
"Africa/Timbuktu": "非洲/廷巴克图",
"Africa/Tripoli": "非洲/的黎波里",
"Africa/Tunis": "非洲/突尼斯",
"Africa/Windhoek": "非洲/温得和克",
"America/Adak": "美国/阿达克",
"America/Anchorage": "美国/安克雷奇",
"America/Anguilla": "美国/安圭拉",
"America/Antigua": "美国/安提瓜",
"America/Araguaina": "美洲／阿拉瓜伊纳",
"America/Argentina/Buenos_Aires": "美国/阿根廷/布宜诺斯艾利斯",
"America/Argentina/Catamarca": "美国/阿根廷/卡塔马卡",
"America/Argentina/ComodRivadavia": "美国/阿根廷/科摩德里瓦达维亚",
"America/Argentina/Cordoba": "美国/阿根廷/科尔多瓦",
"America/Argentina/Jujuy": "美国/阿根廷/胡胡伊",
"America/Argentina/La_Rioja": "美国/阿根廷/拉里奥哈",
"America/Argentina/Mendoza": "美国/阿根廷/门多萨",
"America/Argentina/Rio_Gallegos": "美国/阿根廷/里奥加列戈斯",
"America/Argentina/Salta": "美国/阿根廷/萨尔塔",
"America/Argentina/San_Juan": "美国/阿根廷/圣胡安",
"America/Argentina/San_Luis": "美国/阿根廷/圣路易斯",
"America/Argentina/Tucuman": "美国/阿根廷/图库曼",
"America/Argentina/Ushuaia": "美国/阿根廷/乌斯怀亚",
"America/Aruba": "美国/阿鲁巴",
"America/Asuncion": "美洲／亚松森",
"America/Atikokan": "美国/阿蒂科坎",
"America/Atka": "美国/阿特卡",
"America/Bahia": "美国/巴伊亚州",
"America/Bahia_Banderas": "美国/巴伊亚州班德拉斯",
"America/Barbados": "美国/巴巴多斯",
"America/Belem": "美国/贝伦",
"America/Belize": "美国/伯利兹",
"America/Blanc-Sablon": "美国/Blanc-Sablon",
"America/Boa_Vista": "美洲/Boa_Vista",
"America/Bogota": "美国/波哥大",
"America/Boise": "美国/博伊西",
"America/Buenos_Aires": "美国/布宜诺斯艾利斯",
"America/Cambridge_Bay": "美洲/剑桥湾",
"America/Campo_Grande": "美洲/坎波格兰德",
"America/Cancun": "美国/坎昆",
"America/Caracas": "美国/加拉加斯",
"America/Catamarca": "美国/卡塔马卡",
"America/Cayenne": "美国/卡宴",
"America/Cayman": "美国/开曼",
"America/Chicago": "美国/芝加哥",
"America/Chihuahua": "美国/吉娃娃",
"America/Ciudad_Juarez": "美国/华雷斯城",
"America/Coral_Harbour": "美国/珊瑚港",
"America/Cordoba": "美国/科尔多瓦",
"America/Costa_Rica": "美洲/哥斯达黎加",
"America/Creston": "美国/克雷斯顿",
"America/Cuiaba": "美国/库亚巴",
"America/Curacao": "美国/库拉索岛",
"America/Danmarkshavn": "美国／丹马港",
"America/Dawson": "美国/道森",
"America/Dawson_Creek": "美国/道森克里克",
"America/Denver": "美国/丹佛",
"America/Detroit": "美国/底特律",
"America/Dominica": "美国/多米尼加",
"America/Edmonton": "美国/埃德蒙顿",
"America/Eirunepe": "美国/埃鲁内佩",
"America/El_Salvador": "美洲／萨尔瓦多",
"America/Ensenada": "美洲/恩塞纳达",
"America/Fort_Nelson": "美国/纳尔逊堡",
"America/Fort_Wayne": "美国/韦恩堡",
"America/Fortaleza": "美国/福塔雷萨",
"America/Glace_Bay": "美洲/格莱斯湾",
"America/Godthab": "美国/戈特霍布",
"America/Goose_Bay": "美国/Goose_Bay",
"America/Grand_Turk": "美洲/大特克岛",
"America/Grenada": "美国/格林纳达",
"America/Guadeloupe": "美国/瓜德罗普岛",
"America/Guatemala": "美国/危地马拉",
"America/Guayaquil": "美国／瓜亚基尔",
"America/Guyana": "美国/圭亚那",
"America/Halifax": "美国/哈利法克斯",
"America/Havana": "美国/哈瓦那",
"America/Hermosillo": "美国/埃莫西约",
"America/Indiana/Indianapolis": "美国/印第安纳州/印第安纳波利",
"America/Indiana/Knox": "美国/印第安纳州/诺克斯",
"America/Indiana/Marengo": "美国/印第安纳州/马伦戈",
"America/Indiana/Petersburg": "美国/印第安纳州/彼得斯堡",
"America/Indiana/Tell_City": "美国/印第安纳州/Tell_City",
"America/Indiana/Vevay": "美国/印第安纳州/韦韦",
"America/Indiana/Vincennes": "美国/印第安纳州/文森斯",
"America/Indiana/Winamac": "美国/印第安纳州/Winamac",
"America/Indianapolis": "美国/印第安纳波利斯",
"America/Inuvik": "美国/伊努维克",
"America/Iqaluit": "美国/伊卡卢伊特",
"America/Jamaica": "美国/牙买加",
"America/Jujuy": "美国/胡胡伊",
"America/Juneau": "美国/朱诺",
"America/Kentucky/Louisville": "美国/肯塔基州/路易斯维尔",
"America/Kentucky/Monticello": "美国/肯塔基州/蒙蒂塞洛",
"America/Knox_IN": "美国/Knox_IN",
"America/Kralendijk": "美国/克拉伦代克",
"America/La_Paz": "美洲/拉巴斯",
"America/Lima": "美国/利马",
"America/Los_Angeles": "美国/洛杉矶",
"America/Louisville": "美国/路易斯维尔",
"America/Lower_Princes": "美国/Lower_Princes",
"America/Maceio": "美国/马塞约",
"America/Managua": "美国/马那瓜",
"America/Manaus": "美国/马瑙斯",
"America/Marigot": "美国/马里戈特",
"America/Martinique": "美洲/马提尼克岛",
"America/Matamoros": "美国/马塔莫罗斯",
"America/Mazatlan": "美国/马萨特兰",
"America/Mendoza": "美国/门多萨",
"America/Menominee": "美国/梅诺米尼",
"America/Merida": "美国/梅里达",
"America/Metlakatla": "美国/梅特拉卡特拉",
"America/Mexico_City": "美国/墨西哥城",
"America/Miquelon": "美国/密克隆群岛",
"America/Moncton": "美国/蒙克顿",
"America/Monterrey": "美国/蒙特雷",
"America/Montevideo": "美国/蒙得维的亚",
"America/Montreal": "美国/蒙特利尔",
"America/Montserrat": "美国/蒙特塞拉特",
"America/Nassau": "美国/拿骚",
"America/New_York": "美国/纽约",
"America/Nipigon": "美国/尼皮贡",
"America/Nome": "美国/诺姆",
"America/Noronha": "美国/诺罗尼亚",
"America/North_Dakota/Beulah": "美国/北达科他州/比乌拉",
"America/North_Dakota/Center": "美洲/北达科他州/中部",
"America/North_Dakota/New_Salem": "美国/北达科他州/新塞勒姆",
"America/Nuuk": "美国/努克",
"America/Ojinaga": "美国/奥希纳加",
"America/Panama": "美国/巴拿马",
"America/Pangnirtung": "美国/庞纳通",
"America/Paramaribo": "美国/帕拉马里博",
"America/Phoenix": "美国/菲尼克斯",
"America/Port-au-Prince": "美国／太子港",
"America/Port_of_Spain": "美洲/西班牙港",
"America/Porto_Acre": "美洲/波尔图_阿克里",
"America/Porto_Velho": "美洲/波尔图韦略",
"America/Puerto_Rico": "美国/波多黎各",
"America/Punta_Arenas": "美洲/蓬塔阿雷纳斯",
"America/Rainy_River": "美国/Rainy_River",
"America/Rankin_Inlet": "美国/Rankin_Inlet",
"America/Recife": "美国/累西腓",
"America/Regina": "美国/里贾纳",
"America/Resolute": "美国/坚决",
"America/Rio_Branco": "美洲/里约布兰科",
"America/Rosario": "美国/罗萨里奥",
"America/Santa_Isabel": "美国/圣伊莎贝尔",
"America/Santarem": "美国/圣塔伦",
"America/Santiago": "美国/圣地亚哥",
"America/Santo_Domingo": "美国/圣多明哥",
"America/Sao_Paulo": "美洲/圣保罗",
"America/Scoresbysund": "美国/斯科尔斯比松",
"America/Shiprock": "美国/Shiprock",
"America/Sitka": "美国/锡特卡",
"America/St_Barthelemy": "美洲/圣巴泰勒米岛",
"America/St_Johns": "美国/圣约翰斯",
"America/St_Kitts": "美洲/圣基茨",
"America/St_Lucia": "美洲/圣露西亚",
"America/St_Thomas": "美国/圣托马斯",
"America/St_Vincent": "美洲/圣文森特",
"America/Swift_Current": "美洲/Swift_Current",
"America/Tegucigalpa": "美国/特古西加尔巴",
"America/Thule": "美国/图勒",
"America/Thunder_Bay": "美国/桑德湾",
"America/Tijuana": "美国／蒂华纳",
"America/Toronto": "美国/多伦多",
"America/Tortola": "美国/托尔托拉",
"America/Vancouver": "美国/温哥华",
"America/Virgin": "美国/维珍航空",
"America/Whitehorse": "美国/怀特霍斯",
"America/Winnipeg": "美国/温尼伯",
"America/Yakutat": "美国/亚库塔特",
"America/Yellowknife": "美国/耶洛奈夫",
"Antarctica/Casey": "南极洲/凯西",
"Antarctica/Davis": "南极洲/戴维斯",
"Antarctica/DumontDUrville": "南极洲/杜蒙特德维尔",
"Antarctica/Macquarie": "南极洲/麦格理",
"Antarctica/Mawson": "南极洲/莫森",
"Antarctica/McMurdo": "南极洲/麦克默多",
"Antarctica/Palmer": "南极洲/帕尔默",
"Antarctica/Rothera": "南极洲/罗瑟拉",
"Antarctica/South_Pole": "南极洲/南极",
"Antarctica/Syowa": "南极洲/昭和",
"Antarctica/Troll": "南极洲/巨魔",
"Antarctica/Vostok": "南极洲/沃斯托克",
"Arctic/Longyearbyen": "北极/朗伊尔城",
"Asia/Aden": "亚洲/亚丁",
"Asia/Almaty": "亚洲／阿拉木图",
"Asia/Amman": "亚洲/安曼",
"Asia/Anadyr": "亚洲/阿纳德尔",
"Asia/Aqtau": "亚洲/阿克套",
"Asia/Aqtobe": "亚洲/阿克托比",
"Asia/Ashgabat": "亚洲/阿什哈巴德",
"Asia/Ashkhabad": "亚洲/阿什哈巴德",
"Asia/Atyrau": "亚洲/阿特劳",
"Asia/Baghdad": "亚洲／巴格达",
"Asia/Bahrain": "亚洲／巴林",
"Asia/Baku": "亚洲/巴库",
"Asia/Bangkok": "亚洲/曼谷",
"Asia/Barnaul": "亚洲/巴尔瑙尔",
"Asia/Beirut": "亚洲/贝鲁特",
"Asia/Bishkek": "亚洲／比什凯克",
"Asia/Brunei": "亚洲/文莱",
"Asia/Calcutta": "亚洲/加尔各答",
"Asia/Chita": "亚洲/赤塔",
"Asia/Choibalsan": "亚洲/乔巴山",
"Asia/Chongqing": "亚洲/重庆",
"Asia/Chungking": "亚洲/重庆",
"Asia/Colombo": "亚洲/科伦坡",
"Asia/Dacca": "亚洲/达卡",
"Asia/Damascus": "亚洲/大马士革",
"Asia/Dhaka": "亚洲/达卡",
"Asia/Dili": "亚洲/帝力",
"Asia/Dubai": "亚洲/迪拜",
"Asia/Dushanbe": "亚洲/杜尚别",
"Asia/Famagusta": "亚洲/法马古斯塔",
"Asia/Gaza": "亚洲／加沙",
"Asia/Harbin": "亚洲/哈尔滨",
"Asia/Hebron": "亚洲/希伯伦",
"Asia/Ho_Chi_Minh": "亚洲/胡志明市",
"Asia/Hong_Kong": "亚洲/香港",
"Asia/Hovd": "亚洲/霍夫德",
"Asia/Irkutsk": "亚洲/伊尔库茨克",
"Asia/Istanbul": "亚洲/伊斯坦布尔",
"Asia/Jakarta": "亚洲/雅加达",
"Asia/Jayapura": "亚洲/查亚普拉",
"Asia/Jerusalem": "亚洲／耶路撒冷",
"Asia/Kabul": "亚洲/喀布尔",
"Asia/Kamchatka": "亚洲/堪察加半岛",
"Asia/Karachi": "亚洲/卡拉奇",
"Asia/Kashgar": "亚洲/喀什",
"Asia/Kathmandu": "亚洲/加德满都",
"Asia/Katmandu": "亚洲/加德满都",
"Asia/Khandyga": "亚洲/汉德加",
"Asia/Kolkata": "亚洲/加尔各答",
"Asia/Krasnoyarsk": "亚洲/克拉斯诺亚尔斯克",
"Asia/Kuala_Lumpur": "亚洲/吉隆坡",
"Asia/Kuching": "亚洲/古晋",
"Asia/Kuwait": "亚洲/科威特",
"Asia/Macao": "亚洲/澳门",
"Asia/Macau": "亚洲/澳门",
"Asia/Magadan": "亚洲/马加丹",
"Asia/Makassar": "亚洲/望加锡",
"Asia/Manila": "亚洲/马尼拉",
"Asia/Muscat": "亚洲/马斯喀特",
"Asia/Nicosia": "亚洲/尼科西亚",
"Asia/Novokuznetsk": "亚洲／新库兹涅茨克",
"Asia/Novosibirsk": "亚洲／新西伯利亚",
"Asia/Omsk": "亚洲/鄂木斯克",
"Asia/Oral": "亚洲/口头",
"Asia/Phnom_Penh": "亚洲/金边",
"Asia/Pontianak": "亚洲/坤甸",
"Asia/Pyongyang": "亚洲/平壤",
"Asia/Qatar": "亚洲/卡塔尔",
"Asia/Qostanay": "亚洲/科斯塔奈",
"Asia/Qyzylorda": "亚洲/克孜勒奥尔达",
"Asia/Rangoon": "亚洲／仰光",
"Asia/Riyadh": "亚洲／利雅得",
"Asia/Saigon": "亚洲/西贡",
"Asia/Sakhalin": "亚洲/萨哈林",
"Asia/Samarkand": "亚洲／撒马尔罕",
"Asia/Seoul": "亚洲/首尔",
"Asia/Shanghai": "亚洲/上海",
"Asia/Singapore": "亚洲/新加坡",
"Asia/Srednekolymsk": "亚洲/中科雷姆斯克",
"Asia/Taipei": "亚洲/台北",
"Asia/Tashkent": "亚洲/塔什干",
"Asia/Tbilisi": "亚洲/第比利斯",
"Asia/Tehran": "亚洲/德黑兰",
"Asia/Tel_Aviv": "亚洲/特拉维夫",
"Asia/Thimbu": "亚洲/廷布",
"Asia/Thimphu": "亚洲/廷布",
"Asia/Tokyo": "亚洲/东京",
"Asia/Tomsk": "亚洲／托木斯克",
"Asia/Ujung_Pandang": "亚洲/Ujung_Pandang",
"Asia/Ulaanbaatar": "亚洲／乌兰巴托",
"Asia/Ulan_Bator": "亚洲/乌兰巴托",
"Asia/Urumqi": "亚洲/乌鲁木齐",
"Asia/Ust-Nera": "亚洲/乌斯季内拉",
"Asia/Vientiane": "亚洲/万象",
"Asia/Vladivostok": "亚洲/符拉迪沃斯托克",
"Asia/Yakutsk": "亚洲/雅库茨克",
"Asia/Yangon": "亚洲/仰光",
"Asia/Yekaterinburg": "亚洲／叶卡捷琳堡",
"Asia/Yerevan": "亚洲/埃里温",
"Atlantic/Azores": "大西洋/亚速尔群岛",
"Atlantic/Bermuda": "大西洋/百慕大",
"Atlantic/Canary": "大西洋/加那利",
"Atlantic/Cape_Verde": "大西洋/佛得角",
"Atlantic/Faeroe": "大西洋/法罗",
"Atlantic/Faroe": "大西洋/法罗",
"Atlantic/Jan_Mayen": "大西洋/扬·马延岛",
"Atlantic/Madeira": "大西洋/马德拉",
"Atlantic/Reykjavik": "大西洋/雷克雅未克",
"Atlantic/South_Georgia": "大西洋/南乔治亚",
"Atlantic/St_Helena": "大西洋/圣海伦娜",
"Atlantic/Stanley": "大西洋/斯坦利",
"Australia/ACT": "澳大利亚/澳大利亚首都直辖区",
"Australia/Adelaide": "澳大利亚/阿德莱德",
"Australia/Brisbane": "澳大利亚/布里斯班",
"Australia/Broken_Hill": "澳大利亚/Broken_Hill",
"Australia/Canberra": "澳大利亚/堪培拉",
"Australia/Currie": "澳大利亚/柯里",
"Australia/Darwin": "澳大利亚/达尔文",
"Australia/Eucla": "澳大利亚/尤克拉",
"Australia/Hobart": "澳大利亚/霍巴特",
"Australia/LHI": "澳大利亚/LHI",
"Australia/Lindeman": "澳大利亚/林德曼",
"Australia/Lord_Howe": "澳大利亚/Lord_Howe",
"Australia/Melbourne": "澳大利亚/墨尔本",
"Australia/NSW": "澳大利亚/新南威尔士州",
"Australia/North": "澳大利亚/北部",
"Australia/Perth": "澳大利亚/珀斯",
"Australia/Queensland": "澳大利亚/昆士兰州",
"Australia/South": "澳大利亚/南部",
"Australia/Sydney": "澳大利亚/悉尼",
"Australia/Tasmania": "澳大利亚/塔斯马尼亚",
"Australia/Victoria": "澳大利亚/维多利亚",
"Australia/West": "澳大利亚/西部",
"Australia/Yancowinna": "澳大利亚/扬科温纳",
"Brazil/Acre": "巴西/英亩",
"Brazil/DeNoronha": "巴西/德诺罗尼亚",
"Brazil/East": "巴西/东部",
"Brazil/West": "巴西/西方",
"CET": "CET",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "加拿大/大西洋",
"Canada/Central": "加拿大/中部",
"Canada/Eastern": "加拿大/东部",
"Canada/Mountain": "加拿大/山区",
"Canada/Newfoundland": "加拿大/纽芬兰",
"Canada/Pacific": "加拿大/太平洋",
"Canada/Saskatchewan": "加拿大/萨斯喀彻温省",
"Canada/Yukon": "加拿大/育空地区",
"Chile/Continental": "智利/大陆",
"Chile/EasterIsland": "智利/复活节岛",
"Cuba": "古巴",
"EET": "EET",
"EST": "EST",
"EST5EDT": "EST5EDT",
"Egypt": "埃及",
"Eire": "伊利",
"Etc/GMT": "等/格林威治标准时间",
"Etc/GMT+0": "etc/GMT+0",
"Etc/GMT+1": "etc/GMT+1",
"Etc/GMT+10": "等/格林威治标准时间+10",
"Etc/GMT+11": "等/格林威治标准时间+11",
"Etc/GMT+12": "等/格林威治标准时间+12",
"Etc/GMT+2": "etc/GMT+2",
"Etc/GMT+3": "等/格林威治标准时间+3",
"Etc/GMT+4": "etc/GMT+4",
"Etc/GMT+5": "etc/GMT+5",
"Etc/GMT+6": "etc/GMT+6",
"Etc/GMT+7": "etc/GMT+7",
"Etc/GMT+8": "etc/GMT+8",
"Etc/GMT+9": "等/格林威治标准时间+9",
"Etc/GMT-0": "etc/GMT-0",
"Etc/GMT-1": "etc/GMT-1",
"Etc/GMT-10": "etc/GMT-10",
"Etc/GMT-11": "etc/GMT-11",
"Etc/GMT-12": "etc/GMT-12",
"Etc/GMT-13": "etc/GMT-13",
"Etc/GMT-14": "etc/GMT-14",
"Etc/GMT-2": "etc/GMT-2",
"Etc/GMT-3": "etc/GMT-3",
"Etc/GMT-4": "etc/GMT-4",
"Etc/GMT-5": "etc/GMT-5",
"Etc/GMT-6": "etc/GMT-6",
"Etc/GMT-7": "etc/GMT-7",
"Etc/GMT-8": "etc/GMT-8",
"Etc/GMT-9": "etc/GMT-9",
"Etc/GMT0": "etc/GMT0",
"Etc/Greenwich": "等/格林威治",
"Etc/UCT": "等/UCT",
"Etc/UTC": "等/世界标准时间",
"Etc/Universal": "等/通用",
"Etc/Zulu": "等/祖鲁语",
"Europe/Amsterdam": "欧洲/阿姆斯特丹",
"Europe/Andorra": "欧洲/安道尔",
"Europe/Astrakhan": "欧洲/阿斯特拉罕",
"Europe/Athens": "欧洲/雅典",
"Europe/Belfast": "欧洲/贝尔法斯特",
"Europe/Belgrade": "欧洲/贝尔格莱德",
"Europe/Berlin": "欧洲/柏林",
"Europe/Bratislava": "欧洲/布拉迪斯拉发",
"Europe/Brussels": "欧洲/布鲁塞尔",
"Europe/Bucharest": "欧洲/布加勒斯特",
"Europe/Budapest": "欧洲/布达佩斯",
"Europe/Busingen": "欧洲/布辛根",
"Europe/Chisinau": "欧洲/基希讷乌",
"Europe/Copenhagen": "欧洲/哥本哈根",
"Europe/Dublin": "欧洲/都柏林",
"Europe/Gibraltar": "欧洲/直布罗陀",
"Europe/Guernsey": "欧洲/根西岛",
"Europe/Helsinki": "欧洲/赫尔辛基",
"Europe/Isle_of_Man": "欧洲/马恩岛",
"Europe/Istanbul": "欧洲/伊斯坦布尔",
"Europe/Jersey": "欧洲/泽西岛",
"Europe/Kaliningrad": "欧洲/加里宁格勒",
"Europe/Kiev": "欧洲/基辅",
"Europe/Kirov": "欧洲/基洛夫",
"Europe/Kyiv": "欧洲/基辅",
"Europe/Lisbon": "欧洲/里斯本",
"Europe/Ljubljana": "欧洲/卢布尔雅那",
"Europe/London": "欧洲/伦敦",
"Europe/Luxembourg": "欧洲/卢森堡",
"Europe/Madrid": "欧洲/马德里",
"Europe/Malta": "欧洲/马耳他",
"Europe/Mariehamn": "欧洲/玛丽港",
"Europe/Minsk": "欧洲/明斯克",
"Europe/Monaco": "欧洲/摩纳哥",
"Europe/Moscow": "欧洲/莫斯科",
"Europe/Nicosia": "欧洲/尼科西亚",
"Europe/Oslo": "欧洲/奥斯陆",
"Europe/Paris": "欧洲/巴黎",
"Europe/Podgorica": "欧洲/波德戈里察",
"Europe/Prague": "欧洲/布拉格",
"Europe/Riga": "欧洲/里加",
"Europe/Rome": "欧洲/罗马",
"Europe/Samara": "欧洲/萨马拉",
"Europe/San_Marino": "欧洲/圣马力诺",
"Europe/Sarajevo": "欧洲/萨拉热窝",
"Europe/Saratov": "欧洲/萨拉托夫",
"Europe/Simferopol": "欧洲/辛菲罗波尔",
"Europe/Skopje": "欧洲/斯科普里",
"Europe/Sofia": "欧洲/索非亚",
"Europe/Stockholm": "欧洲/斯德哥尔摩",
"Europe/Tallinn": "欧洲/塔林",
"Europe/Tirane": "欧洲/地拉那",
"Europe/Tiraspol": "欧洲/蒂拉斯波尔",
"Europe/Ulyanovsk": "欧洲/乌里扬诺夫斯克",
"Europe/Uzhgorod": "欧洲/乌日哥罗德",
"Europe/Vaduz": "欧洲/瓦杜兹",
"Europe/Vatican": "欧洲/梵蒂冈",
"Europe/Vienna": "欧洲/维也纳",
"Europe/Vilnius": "欧洲/维尔纽斯",
"Europe/Volgograd": "欧洲/伏尔加格勒",
"Europe/Warsaw": "欧洲/华沙",
"Europe/Zagreb": "欧洲/萨格勒布",
"Europe/Zaporozhye": "欧洲/扎波罗热",
"Europe/Zurich": "欧洲/苏黎世",
"GB": "GB",
"GB-Eire": "GB-eire",
"GMT": "格林威治标准时间",
"GMT+0": "格林威治标准时间+0",
"GMT-0": "格林威治标准时间 0",
"GMT0": "格林威治标准时间 0",
"Greenwich": "格林威治",
"HST": "主机",
"Hongkong": "香港",
"Iceland": "冰岛",
"Indian/Antananarivo": "印度/塔那那利佛",
"Indian/Chagos": "印度/查戈斯",
"Indian/Christmas": "印度/圣诞节",
"Indian/Cocos": "印度/科科斯",
"Indian/Comoro": "印度/科摩罗",
"Indian/Kerguelen": "印度/凯尔盖伦",
"Indian/Mahe": "印度/马埃岛",
"Indian/Maldives": "印度/马尔代夫",
"Indian/Mauritius": "印度/毛里求斯",
"Indian/Mayotte": "印度/马约特岛",
"Indian/Reunion": "印度/留尼汪岛",
"Iran": "伊朗",
"Israel": "以色列",
"Jamaica": "牙买加",
"Japan": "日本",
"Kwajalein": "夸贾林",
"Libya": "利比亚",
"MET": "遇到",
"MST": "MST",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "墨西哥/巴哈诺特",
"Mexico/BajaSur": "墨西哥/巴哈苏尔",
"Mexico/General": "墨西哥/一般",
"NZ": "新西兰",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "纳瓦霍人",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "太平洋/阿皮亚",
"Pacific/Auckland": "太平洋/奥克兰",
"Pacific/Bougainville": "太平洋/布干维尔",
"Pacific/Chatham": "太平洋/查塔姆",
"Pacific/Chuuk": "太平洋/丘克",
"Pacific/Easter": "太平洋/复活节",
"Pacific/Efate": "太平洋/埃法特",
"Pacific/Enderbury": "太平洋/恩德伯里",
"Pacific/Fakaofo": "太平洋/法考福",
"Pacific/Fiji": "太平洋/斐济",
"Pacific/Funafuti": "太平洋/富纳富提",
"Pacific/Galapagos": "太平洋/加拉帕戈斯群岛",
"Pacific/Gambier": "太平洋/甘比尔",
"Pacific/Guadalcanal": "太平洋/瓜达尔卡纳尔岛",
"Pacific/Guam": "太平洋/关岛",
"Pacific/Honolulu": "太平洋/檀香山",
"Pacific/Johnston": "太平洋/约翰斯顿",
"Pacific/Kanton": "太平洋/广州",
"Pacific/Kiritimati": "太平洋/基里蒂马蒂岛",
"Pacific/Kosrae": "太平洋/科斯雷",
"Pacific/Kwajalein": "太平洋/夸贾林岛",
"Pacific/Majuro": "太平洋/马朱罗",
"Pacific/Marquesas": "太平洋/马克萨斯",
"Pacific/Midway": "太平洋/中途岛",
"Pacific/Nauru": "太平洋/瑙鲁",
"Pacific/Niue": "太平洋/纽埃岛",
"Pacific/Norfolk": "太平洋/诺福克",
"Pacific/Noumea": "太平洋/努美阿",
"Pacific/Pago_Pago": "太平洋/Pago_Pago",
"Pacific/Palau": "太平洋/帕劳",
"Pacific/Pitcairn": "太平洋/皮特凯恩",
"Pacific/Pohnpei": "太平洋/波纳佩",
"Pacific/Ponape": "太平洋/波纳佩",
"Pacific/Port_Moresby": "太平洋/莫尔兹比港",
"Pacific/Rarotonga": "太平洋/拉罗汤加",
"Pacific/Saipan": "太平洋/塞班岛",
"Pacific/Samoa": "太平洋/萨摩亚",
"Pacific/Tahiti": "太平洋/塔希提岛",
"Pacific/Tarawa": "太平洋/塔拉瓦",
"Pacific/Tongatapu": "太平洋/汤加塔普岛",
"Pacific/Truk": "太平洋/特鲁克",
"Pacific/Wake": "太平洋/Wake",
"Pacific/Wallis": "太平洋/瓦利斯岛",
"Pacific/Yap": "太平洋/雅浦岛",
"Poland": "波兰",
"Portugal": "葡萄牙",
"ROC": "摇滚",
"ROK": "ROK",
"Singapore": "新加坡",
"Turkey": "土耳其",
"UCT": "切割",
"US/Alaska": "美国/阿拉斯加",
"US/Aleutian": "美国/阿留申语",
"US/Arizona": "美国/亚利桑那",
"US/Central": "美国/中部",
"US/East-Indiana": "美国/东印第安纳州",
"US/Eastern": "美国/东部",
"US/Hawaii": "美国/夏威夷",
"US/Indiana-Starke": "美国/印第安纳州-斯塔克",
"US/Michigan": "美国/密歇根州",
"US/Mountain": "美国/山区",
"US/Pacific": "美国/太平洋",
"US/Samoa": "美国/萨摩亚",
"UTC": "世界标准时间",
"Universal": "环球",
"W-SU": "W-SU",
"WET": "湿",
"Zulu": "祖鲁人",
}