export default {
"Africa/Abidjan": "아프리카/아비장",
"Africa/Accra": "아프리카/아크라",
"Africa/Addis_Ababa": "아프리카/아디스_아바바",
"Africa/Algiers": "아프리카/알제",
"Africa/Asmara": "아프리카/아스마라",
"Africa/Asmera": "아프리카/아스마라",
"Africa/Bamako": "아프리카/바마코",
"Africa/Bangui": "아프리카/방기",
"Africa/Banjul": "아프리카/반줄",
"Africa/Bissau": "아프리카/비사우",
"Africa/Blantyre": "아프리카/블랜타이어",
"Africa/Brazzaville": "아프리카/브라자빌",
"Africa/Bujumbura": "아프리카/부줌부라",
"Africa/Cairo": "아프리카/카이로",
"Africa/Casablanca": "아프리카/카사블랑카",
"Africa/Ceuta": "아프리카/세우타",
"Africa/Conakry": "아프리카/코나크리",
"Africa/Dakar": "아프리카/다카르",
"Africa/Dar_es_Salaam": "아프리카/다르_에스_살람",
"Africa/Djibouti": "아프리카/지부티",
"Africa/Douala": "아프리카/두알라",
"Africa/El_Aaiun": "아프리카/엘_아이운",
"Africa/Freetown": "아프리카/프리타운",
"Africa/Gaborone": "아프리카/가보로네",
"Africa/Harare": "아프리카/하라레",
"Africa/Johannesburg": "아프리카/요하네스버그",
"Africa/Juba": "아프리카/주바",
"Africa/Kampala": "아프리카/캄팔라",
"Africa/Khartoum": "아프리카/하르툼",
"Africa/Kigali": "아프리카/키갈리",
"Africa/Kinshasa": "아프리카/킨샤사",
"Africa/Lagos": "아프리카/라고스",
"Africa/Libreville": "아프리카/리브르빌",
"Africa/Lome": "아프리카/로메",
"Africa/Luanda": "아프리카/루안다",
"Africa/Lubumbashi": "아프리카/루붐바시",
"Africa/Lusaka": "아프리카/루사카",
"Africa/Malabo": "아프리카/말라보",
"Africa/Maputo": "아프리카/마푸토",
"Africa/Maseru": "아프리카/마세루",
"Africa/Mbabane": "아프리카/음바바네",
"Africa/Mogadishu": "아프리카/모가디슈",
"Africa/Monrovia": "아프리카/몬로비아",
"Africa/Nairobi": "아프리카/나이로비",
"Africa/Ndjamena": "아프리카/엔자메나",
"Africa/Niamey": "아프리카/니아메",
"Africa/Nouakchott": "아프리카/누악쇼트",
"Africa/Ouagadougou": "아프리카/와가두구",
"Africa/Porto-Novo": "아프리카/포르토노보",
"Africa/Sao_Tome": "아프리카/상투메",
"Africa/Timbuktu": "아프리카/팀북투",
"Africa/Tripoli": "아프리카/트리폴리",
"Africa/Tunis": "아프리카/튀니스",
"Africa/Windhoek": "아프리카/빈트후크",
"America/Adak": "아메리카/아닥",
"America/Anchorage": "미국/앵커리지",
"America/Anguilla": "아메리카/앵귈라",
"America/Antigua": "아메리카/안티구아",
"America/Araguaina": "아메리카/아라구아이나",
"America/Argentina/Buenos_Aires": "미국/아르헨티나/부에노스아이레스",
"America/Argentina/Catamarca": "미국/아르헨티나/카타마르카",
"America/Argentina/ComodRivadavia": "미국/아르헨티나/코모드리바다비아",
"America/Argentina/Cordoba": "미국/아르헨티나/코르도바",
"America/Argentina/Jujuy": "미국/아르헨티나/후후이",
"America/Argentina/La_Rioja": "미국/아르헨티나/라_리오하",
"America/Argentina/Mendoza": "미국/아르헨티나/멘도사",
"America/Argentina/Rio_Gallegos": "미국/아르헨티나/리오 갈레고스",
"America/Argentina/Salta": "미국/아르헨티나/살타",
"America/Argentina/San_Juan": "미국/아르헨티나/산후안",
"America/Argentina/San_Luis": "미국/아르헨티나/산_루이스",
"America/Argentina/Tucuman": "미국/아르헨티나/투쿠만",
"America/Argentina/Ushuaia": "미국/아르헨티나/우슈아이아",
"America/Aruba": "아메리카/아루바",
"America/Asuncion": "아메리카/아순시온",
"America/Atikokan": "아메리카/아티코칸",
"America/Atka": "아메리카/아트카",
"America/Bahia": "아메리카/바이아",
"America/Bahia_Banderas": "아메리카/바이아_반데라스",
"America/Barbados": "아메리카/바베이도스",
"America/Belem": "아메리카/벨렘",
"America/Belize": "미국/벨리즈",
"America/Blanc-Sablon": "아메리카/블랑-사블론",
"America/Boa_Vista": "아메리카/보아_비스타",
"America/Bogota": "아메리카/보고타",
"America/Boise": "아메리카/보이시",
"America/Buenos_Aires": "아메리카/부에노스_아이레스",
"America/Cambridge_Bay": "아메리카/캠브리지_베이",
"America/Campo_Grande": "아메리카/캄포_그란데",
"America/Cancun": "아메리카/칸쿤",
"America/Caracas": "아메리카/카라카스",
"America/Catamarca": "아메리카/카타마르카",
"America/Cayenne": "아메리카/카이엔",
"America/Cayman": "아메리카/케이맨",
"America/Chicago": "미국/시카고",
"America/Chihuahua": "미국/치와와",
"America/Ciudad_Juarez": "아메리카/시우다드_후아레스",
"America/Coral_Harbour": "아메리카/코럴_하버",
"America/Cordoba": "아메리카/코르도바",
"America/Costa_Rica": "아메리카/코스타리카",
"America/Creston": "아메리카/크레스톤",
"America/Cuiaba": "아메리카/쿠이아바",
"America/Curacao": "아메리카/큐라소",
"America/Danmarkshavn": "아메리카/덴마크스하운",
"America/Dawson": "아메리카/도슨",
"America/Dawson_Creek": "아메리카/도슨_ 크릭",
"America/Denver": "미국/덴버",
"America/Detroit": "미국/디트로이트",
"America/Dominica": "미국/도미니카",
"America/Edmonton": "아메리카/에드먼턴",
"America/Eirunepe": "아메리카/아이루네페",
"America/El_Salvador": "아메리카/엘_살바도르",
"America/Ensenada": "아메리카/엔세나다",
"America/Fort_Nelson": "아메리카/포트_ 넬슨",
"America/Fort_Wayne": "아메리카/포트_웨인",
"America/Fortaleza": "아메리카/포르탈레자",
"America/Glace_Bay": "아메리카/글라스_베이",
"America/Godthab": "아메리카/고트호브",
"America/Goose_Bay": "아메리카/구스_베이",
"America/Grand_Turk": "아메리카/그랜드_터크",
"America/Grenada": "미국/그레나다",
"America/Guadeloupe": "미국/과들루프",
"America/Guatemala": "미국/과테말라",
"America/Guayaquil": "아메리카/과야킬",
"America/Guyana": "아메리카/가이아나",
"America/Halifax": "아메리카/핼리팩스",
"America/Havana": "아메리카/하바나",
"America/Hermosillo": "아메리카/에르모시요",
"America/Indiana/Indianapolis": "미국/인디애나/인디애나폴리스",
"America/Indiana/Knox": "미국/인디애나/녹스",
"America/Indiana/Marengo": "미국/인디애나/마렝고",
"America/Indiana/Petersburg": "미국/인디애나/피터스버그",
"America/Indiana/Tell_City": "미국/인디애나/텔_시티",
"America/Indiana/Vevay": "미국/인디애나/브베이",
"America/Indiana/Vincennes": "미국/인디애나/뱅센",
"America/Indiana/Winamac": "미국/인디애나/위나맥",
"America/Indianapolis": "아메리카/인디애나폴리스",
"America/Inuvik": "아메리카/이누빅",
"America/Iqaluit": "아메리카/이칼루이트",
"America/Jamaica": "미국/자메이카",
"America/Jujuy": "아메리카/후후이",
"America/Juneau": "아메리카/주노",
"America/Kentucky/Louisville": "미국/켄터키/루이빌",
"America/Kentucky/Monticello": "미국/켄터키/몬티첼로",
"America/Knox_IN": "아메리카/녹스_인",
"America/Kralendijk": "아메리카/크랄렌데이크",
"America/La_Paz": "아메리카/라파스",
"America/Lima": "아메리카/리마",
"America/Los_Angeles": "미국/로스앤젤레스",
"America/Louisville": "아메리카/루이빌",
"America/Lower_Princes": "아메리카/로워_프린스",
"America/Maceio": "아메리카/마세이오",
"America/Managua": "아메리카/마나과",
"America/Manaus": "아메리카/마나우스",
"America/Marigot": "아메리카/마리고트",
"America/Martinique": "아메리카/마르티니크",
"America/Matamoros": "아메리카/마타모로스",
"America/Mazatlan": "아메리카/마사틀란",
"America/Mendoza": "아메리카/멘도사",
"America/Menominee": "아메리카/메노미니",
"America/Merida": "아메리카/메리다",
"America/Metlakatla": "아메리카/메트라카틀라",
"America/Mexico_City": "아메리카/멕시코_시티",
"America/Miquelon": "아메리카/미켈론",
"America/Moncton": "아메리카/멍크턴",
"America/Monterrey": "아메리카/몬테레이",
"America/Montevideo": "아메리카/몬테비데오",
"America/Montreal": "미국/몬트리올",
"America/Montserrat": "아메리카/몬세라트",
"America/Nassau": "아메리카/나소",
"America/New_York": "미국/뉴욕",
"America/Nipigon": "아메리카/니피곤",
"America/Nome": "미국/이름",
"America/Noronha": "아메리카/노로냐",
"America/North_Dakota/Beulah": "미국/노스다코타/불라",
"America/North_Dakota/Center": "아메리카/노스다코타/센터",
"America/North_Dakota/New_Salem": "아메리카/노스_다코타/뉴_살렘",
"America/Nuuk": "아메리카/누크",
"America/Ojinaga": "아메리카/오지나가",
"America/Panama": "미국/파나마",
"America/Pangnirtung": "아메리카/팡니르퉁",
"America/Paramaribo": "아메리카/파라마리보",
"America/Phoenix": "아메리카/피닉스",
"America/Port-au-Prince": "아메리카/포르토프랭스",
"America/Port_of_Spain": "아메리카/포트 오브 스페인",
"America/Porto_Acre": "아메리카/포르토_아크레",
"America/Porto_Velho": "아메리카/포르토_벨류",
"America/Puerto_Rico": "아메리카/푸에르토리코",
"America/Punta_Arenas": "아메리카/푼타_아레나스",
"America/Rainy_River": "아메리카/레이니_리버",
"America/Rankin_Inlet": "아메리카/랭킨_인렛",
"America/Recife": "아메리카/레시페",
"America/Regina": "아메리카/레지나",
"America/Resolute": "아메리카/레졸루트",
"America/Rio_Branco": "아메리카/리오_브랑코",
"America/Rosario": "아메리카/로사리오",
"America/Santa_Isabel": "아메리카/산타_이사벨",
"America/Santarem": "아메리카/산타렘",
"America/Santiago": "아메리카/산티아고",
"America/Santo_Domingo": "아메리카/산토_ 도밍고",
"America/Sao_Paulo": "아메리카/상파울루",
"America/Scoresbysund": "아메리카/스코레스비순드",
"America/Shiprock": "아메리카/쉽락",
"America/Sitka": "아메리카/싯카",
"America/St_Barthelemy": "아메리카/생바르텔레미",
"America/St_Johns": "아메리카/세인트 존스",
"America/St_Kitts": "아메리카/세인트 키츠",
"America/St_Lucia": "아메리카/세인트루시아",
"America/St_Thomas": "아메리카/세인트 토마스",
"America/St_Vincent": "아메리카/세인트 빈센트",
"America/Swift_Current": "아메리카/스위프트_커런트",
"America/Tegucigalpa": "아메리카/테구시갈파",
"America/Thule": "아메리카/툴레",
"America/Thunder_Bay": "아메리카/썬더_베이",
"America/Tijuana": "아메리카/티후아나",
"America/Toronto": "아메리카/토론토",
"America/Tortola": "아메리카/토르톨라",
"America/Vancouver": "아메리카/밴쿠버",
"America/Virgin": "아메리카/버진",
"America/Whitehorse": "아메리카/화이트호스",
"America/Winnipeg": "미국/위니펙",
"America/Yakutat": "아메리카/야쿠타트",
"America/Yellowknife": "아메리카/옐로나이프",
"Antarctica/Casey": "남극/케이시",
"Antarctica/Davis": "남극/데이비스",
"Antarctica/DumontDUrville": "남극/뒤몽 두르빌",
"Antarctica/Macquarie": "남극/맥쿼리",
"Antarctica/Mawson": "남극/모슨",
"Antarctica/McMurdo": "남극/맥머도",
"Antarctica/Palmer": "남극/파머",
"Antarctica/Rothera": "남극/로테라",
"Antarctica/South_Pole": "남극/남극",
"Antarctica/Syowa": "남극/쇼와",
"Antarctica/Troll": "남극/트롤",
"Antarctica/Vostok": "남극/보스토크",
"Arctic/Longyearbyen": "북극/롱이어비엔",
"Asia/Aden": "아시아/아덴",
"Asia/Almaty": "아시아/알마티",
"Asia/Amman": "아시아/암만",
"Asia/Anadyr": "아시아/아나디르",
"Asia/Aqtau": "아시아/아크타우",
"Asia/Aqtobe": "아시아/아크토베",
"Asia/Ashgabat": "아시아/아시가바트",
"Asia/Ashkhabad": "아시아/아시하바드",
"Asia/Atyrau": "아시아/아티라우",
"Asia/Baghdad": "아시아/바그다드",
"Asia/Bahrain": "아시아/바레인",
"Asia/Baku": "아시아/바쿠",
"Asia/Bangkok": "아시아/방콕",
"Asia/Barnaul": "아시아/바르나울",
"Asia/Beirut": "아시아/베이루트",
"Asia/Bishkek": "아시아/비슈케크",
"Asia/Brunei": "아시아/브루나이",
"Asia/Calcutta": "아시아/캘커타",
"Asia/Chita": "아시아/치타",
"Asia/Choibalsan": "아시아/초이발산",
"Asia/Chongqing": "아시아/충칭",
"Asia/Chungking": "아시아/청킹",
"Asia/Colombo": "아시아/콜롬보",
"Asia/Dacca": "아시아/다카",
"Asia/Damascus": "아시아/다마스쿠스",
"Asia/Dhaka": "아시아/다카",
"Asia/Dili": "아시아/딜리",
"Asia/Dubai": "아시아/두바이",
"Asia/Dushanbe": "아시아/두샨베",
"Asia/Famagusta": "아시아/파마구스타",
"Asia/Gaza": "아시아/가자",
"Asia/Harbin": "아시아/하얼빈",
"Asia/Hebron": "아시아/헤브론",
"Asia/Ho_Chi_Minh": "아시아/호치민",
"Asia/Hong_Kong": "아시아/홍콩",
"Asia/Hovd": "아시아/호브드",
"Asia/Irkutsk": "아시아/이르쿠츠크",
"Asia/Istanbul": "아시아/이스탄불",
"Asia/Jakarta": "아시아/자카르타",
"Asia/Jayapura": "아시아/자야푸라",
"Asia/Jerusalem": "아시아/예루살렘",
"Asia/Kabul": "아시아/카불",
"Asia/Kamchatka": "아시아/캄차카",
"Asia/Karachi": "아시아/카라치",
"Asia/Kashgar": "아시아/카슈가르",
"Asia/Kathmandu": "아시아/카트만두",
"Asia/Katmandu": "아시아/카트만두",
"Asia/Khandyga": "아시아/한디가",
"Asia/Kolkata": "아시아/콜카타",
"Asia/Krasnoyarsk": "아시아/크라스노야르스크",
"Asia/Kuala_Lumpur": "아시아/쿠알라룸푸르",
"Asia/Kuching": "아시아/쿠칭",
"Asia/Kuwait": "아시아/쿠웨이트",
"Asia/Macao": "아시아/마카오",
"Asia/Macau": "아시아/마카오",
"Asia/Magadan": "아시아/마가단",
"Asia/Makassar": "아시아/마카사르",
"Asia/Manila": "아시아/마닐라",
"Asia/Muscat": "아시아/무스카트",
"Asia/Nicosia": "아시아/니코시아",
"Asia/Novokuznetsk": "아시아/노보쿠즈네츠크",
"Asia/Novosibirsk": "아시아/노보시비르스크",
"Asia/Omsk": "아시아/옴스크",
"Asia/Oral": "아시아/오랄",
"Asia/Phnom_Penh": "아시아/프놈펜",
"Asia/Pontianak": "아시아/폰티아낙",
"Asia/Pyongyang": "아시아/평양",
"Asia/Qatar": "아시아/카타르",
"Asia/Qostanay": "아시아/코스타나이",
"Asia/Qyzylorda": "아시아/키질로르다",
"Asia/Rangoon": "아시아/랑군",
"Asia/Riyadh": "아시아/리야드",
"Asia/Saigon": "아시아/사이공",
"Asia/Sakhalin": "아시아/사할린",
"Asia/Samarkand": "아시아/사마르칸트",
"Asia/Seoul": "아시아/서울",
"Asia/Shanghai": "아시아/상하이",
"Asia/Singapore": "아시아/싱가포르",
"Asia/Srednekolymsk": "아시아/스레드네콜림스크",
"Asia/Taipei": "아시아/타이페이",
"Asia/Tashkent": "아시아/타슈켄트",
"Asia/Tbilisi": "아시아/트빌리시",
"Asia/Tehran": "아시아/테헤란",
"Asia/Tel_Aviv": "아시아/텔아비브",
"Asia/Thimbu": "아시아/팀부",
"Asia/Thimphu": "아시아/팀푸",
"Asia/Tokyo": "아시아/도쿄",
"Asia/Tomsk": "아시아/톰스크",
"Asia/Ujung_Pandang": "아시아/우정_판당",
"Asia/Ulaanbaatar": "아시아/울란바토르",
"Asia/Ulan_Bator": "아시아/울란_바토르",
"Asia/Urumqi": "아시아/우루무치",
"Asia/Ust-Nera": "아시아/동부 네라",
"Asia/Vientiane": "아시아/비엔티안",
"Asia/Vladivostok": "아시아/블라디보스톡",
"Asia/Yakutsk": "아시아/야쿠츠크",
"Asia/Yangon": "아시아/양곤",
"Asia/Yekaterinburg": "아시아/예카테린부르크",
"Asia/Yerevan": "아시아/예레반",
"Atlantic/Azores": "대서양/아조레스",
"Atlantic/Bermuda": "대서양/버뮤다",
"Atlantic/Canary": "대서양/카나리아",
"Atlantic/Cape_Verde": "대서양/케이프 베르데",
"Atlantic/Faeroe": "대서양/페로",
"Atlantic/Faroe": "대서양/페로",
"Atlantic/Jan_Mayen": "대서양/얀_마옌",
"Atlantic/Madeira": "아틀란틱/마데이라",
"Atlantic/Reykjavik": "대서양/레이캬비크",
"Atlantic/South_Georgia": "대서양/남부 조지아",
"Atlantic/St_Helena": "대서양/세인트헬레나",
"Atlantic/Stanley": "아틀란틱/스탠리",
"Australia/ACT": "호주/ACT",
"Australia/Adelaide": "오스트레일리아/애들레이드",
"Australia/Brisbane": "호주/브리즈번",
"Australia/Broken_Hill": "오스트레일리아/브로큰_힐",
"Australia/Canberra": "호주/캔버라",
"Australia/Currie": "호주/커리",
"Australia/Darwin": "호주/다윈",
"Australia/Eucla": "호주/유클라",
"Australia/Hobart": "호주/호바트",
"Australia/LHI": "오스트레일리아/LHI",
"Australia/Lindeman": "오스트레일리아/린드먼",
"Australia/Lord_Howe": "오스트레일리아/로드_하우",
"Australia/Melbourne": "호주/멜버른",
"Australia/NSW": "오스트레일리아/뉴사우스웨일즈",
"Australia/North": "오스트레일리아/북부",
"Australia/Perth": "호주/퍼스",
"Australia/Queensland": "오스트레일리아/퀸즈랜드",
"Australia/South": "호주/남부",
"Australia/Sydney": "호주/시드니",
"Australia/Tasmania": "오스트레일리아/태즈매니아",
"Australia/Victoria": "호주/빅토리아",
"Australia/West": "오스트레일리아/서부",
"Australia/Yancowinna": "오스트레일리아/얀코위나",
"Brazil/Acre": "브라질/에이커",
"Brazil/DeNoronha": "브라질/데노로냐",
"Brazil/East": "브라질/동부",
"Brazil/West": "브라질/서부",
"CET": "CET",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "캐나다/대서양",
"Canada/Central": "캐나다/중부",
"Canada/Eastern": "캐나다/동부",
"Canada/Mountain": "캐나다/마운틴",
"Canada/Newfoundland": "캐나다/뉴펀들랜드",
"Canada/Pacific": "캐나다/태평양",
"Canada/Saskatchewan": "캐나다/서스캐처원",
"Canada/Yukon": "캐나다/유콘",
"Chile/Continental": "칠레/콘티넨탈",
"Chile/EasterIsland": "칠레/이스터 섬",
"Cuba": "쿠바",
"EET": "세트",
"EST": "테스트",
"EST5EDT": "EST5EDT",
"Egypt": "이집트",
"Eire": "와이어",
"Etc/GMT": "ETC/GMT",
"Etc/GMT+0": "ETC/GMT+0",
"Etc/GMT+1": "ETC/GMT+1",
"Etc/GMT+10": "ETC/GMT+10",
"Etc/GMT+11": "ETC/GMT+11",
"Etc/GMT+12": "ETC/GMT+12",
"Etc/GMT+2": "ETC/GMT+2",
"Etc/GMT+3": "ETC/GMT+3",
"Etc/GMT+4": "ETC/GMT+4",
"Etc/GMT+5": "ETC/GMT+5",
"Etc/GMT+6": "ETC/GMT+6",
"Etc/GMT+7": "ETC/GMT+7",
"Etc/GMT+8": "ETC/GMT+8",
"Etc/GMT+9": "ETC/GMT+9",
"Etc/GMT-0": "ETC/GMT-0",
"Etc/GMT-1": "ETC/GMT-1",
"Etc/GMT-10": "ETC/GMT-10",
"Etc/GMT-11": "ETC/GMT-11",
"Etc/GMT-12": "ETC/GMT-12",
"Etc/GMT-13": "ETC/GMT-13",
"Etc/GMT-14": "ETC/GMT-14",
"Etc/GMT-2": "ETC/GMT-2",
"Etc/GMT-3": "ETC/GMT-3",
"Etc/GMT-4": "ETC/GMT-4",
"Etc/GMT-5": "ETC/GMT-5",
"Etc/GMT-6": "ETC/GMT-6",
"Etc/GMT-7": "ETC/GMT-7",
"Etc/GMT-8": "ETC/GMT-8",
"Etc/GMT-9": "ETC/GMT-9",
"Etc/GMT0": "ETC/GMT0",
"Etc/Greenwich": "기타/그리니치",
"Etc/UCT": "ETC/UCT",
"Etc/UTC": "ETC/UTC",
"Etc/Universal": "기타/유니버설",
"Etc/Zulu": "기타/줄루",
"Europe/Amsterdam": "유럽/암스테르담",
"Europe/Andorra": "유럽/안도라",
"Europe/Astrakhan": "유럽/아스트라한",
"Europe/Athens": "유럽/아테네",
"Europe/Belfast": "유럽/벨파스트",
"Europe/Belgrade": "유럽/베오그라드",
"Europe/Berlin": "유럽/베를린",
"Europe/Bratislava": "유럽/브라티슬라바",
"Europe/Brussels": "유럽/브뤼셀",
"Europe/Bucharest": "유럽/부카레스트",
"Europe/Budapest": "유럽/부다페스트",
"Europe/Busingen": "유럽/뷔징겐",
"Europe/Chisinau": "유럽/키시나우",
"Europe/Copenhagen": "유럽/코펜하겐",
"Europe/Dublin": "유럽/더블린",
"Europe/Gibraltar": "유럽/지브롤터",
"Europe/Guernsey": "유럽/건지",
"Europe/Helsinki": "유럽/헬싱키",
"Europe/Isle_of_Man": "유럽/맨 섬",
"Europe/Istanbul": "유럽/이스탄불",
"Europe/Jersey": "유럽/저지",
"Europe/Kaliningrad": "유럽/칼리닌그라드",
"Europe/Kiev": "유럽/키예프",
"Europe/Kirov": "유럽/키로프",
"Europe/Kyiv": "유럽/키예프",
"Europe/Lisbon": "유럽/리스본",
"Europe/Ljubljana": "유럽/류블랴나",
"Europe/London": "유럽/런던",
"Europe/Luxembourg": "유럽/룩셈부르크",
"Europe/Madrid": "유럽/마드리드",
"Europe/Malta": "유럽/몰타",
"Europe/Mariehamn": "유럽/마리에함",
"Europe/Minsk": "유럽/민스크",
"Europe/Monaco": "유럽/모나코",
"Europe/Moscow": "유럽/모스크바",
"Europe/Nicosia": "유럽/니코시아",
"Europe/Oslo": "유럽/오슬로",
"Europe/Paris": "유럽/파리",
"Europe/Podgorica": "유럽/포드고리차",
"Europe/Prague": "유럽/프라하",
"Europe/Riga": "유럽/리가",
"Europe/Rome": "유럽/로마",
"Europe/Samara": "유럽/사마라",
"Europe/San_Marino": "유럽/산마리노",
"Europe/Sarajevo": "유럽/사라예보",
"Europe/Saratov": "유럽/사라토프",
"Europe/Simferopol": "유럽/심페로폴",
"Europe/Skopje": "유럽/스코페",
"Europe/Sofia": "유럽/소피아",
"Europe/Stockholm": "유럽/스톡홀름",
"Europe/Tallinn": "유럽/탈린",
"Europe/Tirane": "유럽/티라나",
"Europe/Tiraspol": "유럽/티라스폴",
"Europe/Ulyanovsk": "유럽/울리야노프스크",
"Europe/Uzhgorod": "유럽/우즈고로드",
"Europe/Vaduz": "유럽/바두즈",
"Europe/Vatican": "유럽/바티칸",
"Europe/Vienna": "유럽/비엔나",
"Europe/Vilnius": "유럽/빌니우스",
"Europe/Volgograd": "유럽/볼고그라드",
"Europe/Warsaw": "유럽/바르샤바",
"Europe/Zagreb": "유럽/자그레브",
"Europe/Zaporozhye": "유럽/자포로제",
"Europe/Zurich": "유럽/취리히",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "그리니치",
"HST": "호스트",
"Hongkong": "홍콩",
"Iceland": "아이슬란드",
"Indian/Antananarivo": "인디언/안타나나리보",
"Indian/Chagos": "인디언/차고스",
"Indian/Christmas": "인도/크리스마스",
"Indian/Cocos": "인디언/코코스",
"Indian/Comoro": "인도/코모로",
"Indian/Kerguelen": "인디언/케르겔렌",
"Indian/Mahe": "인디언/마헤",
"Indian/Maldives": "인도/몰디브",
"Indian/Mauritius": "인도/모리셔스",
"Indian/Mayotte": "인디언/마요트",
"Indian/Reunion": "인디언/레위니옹",
"Iran": "이란",
"Israel": "이스라엘",
"Jamaica": "자메이카",
"Japan": "일본",
"Kwajalein": "콰잘레인",
"Libya": "리비아",
"MET": "만났다",
"MST": "마스트",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "멕시코/바하노르테",
"Mexico/BajaSur": "멕시코/바하수르",
"Mexico/General": "멕시코/일반",
"NZ": "뉴질랜드",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "나바호어",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "태평양/아피아",
"Pacific/Auckland": "태평양/오클랜드",
"Pacific/Bougainville": "퍼시픽/부겐빌",
"Pacific/Chatham": "퍼시픽/채텀",
"Pacific/Chuuk": "태평양/추크",
"Pacific/Easter": "태평양/부활절",
"Pacific/Efate": "태평양/이파테",
"Pacific/Enderbury": "태평양/엔더베리",
"Pacific/Fakaofo": "태평양/파카오포",
"Pacific/Fiji": "태평양/피지",
"Pacific/Funafuti": "태평양/푸나푸티",
"Pacific/Galapagos": "태평양/갈라파고스",
"Pacific/Gambier": "퍼시픽/갬비어",
"Pacific/Guadalcanal": "태평양/과달카날",
"Pacific/Guam": "태평양/괌",
"Pacific/Honolulu": "태평양/호놀룰루",
"Pacific/Johnston": "태평양/존스턴",
"Pacific/Kanton": "태평양/캔턴",
"Pacific/Kiritimati": "태평양/키리티마티",
"Pacific/Kosrae": "태평양/코스라에",
"Pacific/Kwajalein": "태평양/콰잘레인",
"Pacific/Majuro": "태평양/마주로",
"Pacific/Marquesas": "태평양/마르케사스",
"Pacific/Midway": "태평양/미드웨이",
"Pacific/Nauru": "태평양/나우루",
"Pacific/Niue": "태평양/니우에",
"Pacific/Norfolk": "태평양/노퍽",
"Pacific/Noumea": "태평양/누메아",
"Pacific/Pago_Pago": "태평양/파고_파고",
"Pacific/Palau": "태평양/팔라우",
"Pacific/Pitcairn": "태평양/핏케언",
"Pacific/Pohnpei": "태평양/폰페이",
"Pacific/Ponape": "태평양/포나페",
"Pacific/Port_Moresby": "태평양/포트_모스비",
"Pacific/Rarotonga": "태평양/라로통가",
"Pacific/Saipan": "태평양/사이판",
"Pacific/Samoa": "태평양/사모아",
"Pacific/Tahiti": "태평양/타히티",
"Pacific/Tarawa": "태평양/타라와",
"Pacific/Tongatapu": "태평양/통가타푸",
"Pacific/Truk": "태평양/트럭",
"Pacific/Wake": "퍼시픽/웨이크",
"Pacific/Wallis": "태평양/월리스",
"Pacific/Yap": "태평양/야프",
"Poland": "폴란드",
"Portugal": "포르투갈",
"ROC": "록",
"ROK": "록",
"Singapore": "싱가포르",
"Turkey": "터키",
"UCT": "컷",
"US/Alaska": "미국/알래스카",
"US/Aleutian": "미국/알류샨",
"US/Arizona": "미국/애리조나",
"US/Central": "미국/중부",
"US/East-Indiana": "미국/동부 인디애나",
"US/Eastern": "미국/동부",
"US/Hawaii": "미국/하와이",
"US/Indiana-Starke": "미국/인디애나-스타크",
"US/Michigan": "미국/미시건",
"US/Mountain": "미국/마운틴",
"US/Pacific": "미국/태평양",
"US/Samoa": "미국/사모아",
"UTC": "UTC",
"Universal": "유니버설",
"W-SU": "W-SU",
"WET": "젖은",
"Zulu": "줄루족",
}