import React from "react"
import {  View, StyleSheet, PixelRatio, Platform} from "react-native"
import _ from 'lodash'
import { isInSequence } from "../../scales/utils"
import PropTypes from 'prop-types'
import { getLabelWidth, getValue } from "./VerticalLabelUtils"
import { fontScale } from "../../styles/fontResizer"
import LabelTextDisplay from "./LabelTextDisplay"
import { alignments } from "../../scales/alignments"

const VerticalLabels = (props) => {
    const {labels, step, start, end, labelFlex , disabled, id, scaleProps, transformedFont, labelsData} = props
    const {widthOfBox, scaleWidth} = scaleProps

    let textAlign = 'right'
    let boxAlign = 'flex-end'
    if(_.find(labels, label => label.labelPlacement === 'RIGHT')){
        textAlign = 'left'
        boxAlign = 'flex-start'
    }

    const getTextStyles = () => {
       return {
            textAlign: textAlign, 
            marginTop:  Platform.OS !== 'web' ? -8 : -4,
            marginRight: Platform.OS !== 'web' ? -20 : 0
        }
    }

    const getBoxStyles = (number) => {
        return {
            ...styles.box,
            alignItems: boxAlign,
            height: getBoxHeight(number),
            marginTop: getMarginTop(number)
        }
    }

    const getMarginTop = (number) => {
        const arr = _.map(_.range(start, end + 1, step), number => number)
        if(!isInSequence(end, start, end, step) && number === _.last(arr)){
          //lastButOneNumber where end is not in sequence
          return scaleWidth - (arr.length-1)*widthOfBox
        }
        return 0
    }

    const getBoxHeight =(number) => {
        if(number === start && _.isEmpty(getValue(start, labels))){
            return 0
        }else if(number === start && !_.isEmpty(getValue(start, labels))){
            return widthOfBox>30 ? widthOfBox : 30
        }else{
            return widthOfBox
        }
    }

   return (
            <View style={styles.container}>
            {_.map(_.orderBy(_.range(start, end + 1, step), [], 'desc'), (number, index) => {
                        const loWidth =  _.get(labelsData[number], 'width', getLabelWidth(labelFlex))
                        const loHeight =  _.get(labelsData[number], 'height', 0)
                        const isTextFit =  _.get(labelsData[number], 'fit', false)
                        return (
                            <View key={index} style={[getBoxStyles(number), {paddingLeft: 10, paddingRight: 10}]}>
                                <LabelTextDisplay 
                                    isFit={isTextFit || _.isEmpty(getValue(number, labels))}
                                    testID={`visual-analog-scale-sliderLabels-${id}-${number}-vtLabel`}
                                    accessible={!disabled} 
                                    text={getValue(number, labels)}
                                    fontsize={transformedFont}
                                    containerWidth={loWidth}
                                    containerHeight={loHeight}
                                    labelStyles={getTextStyles()}
                                    orientation={alignments.VERTICAL}
                                    containerStyle={{ height: loHeight}}
                                />
                            </View>
                        )
                    })
               }
        </View>
    );
}



const styles = StyleSheet.create({
    container: { flex: 1},
    box: {},
  });

VerticalLabels.defaultProps = {
    start: 0,
    end: 10,
    step: 1,
    labelFlex: null,
    labels: [],
    disabled: false,
    id: null,
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: 10/(PixelRatio.getFontScale()),
        scale: 1,
      },
    labelsData: {}, 
    transformedFont: fontScale(10)
  }
  
VerticalLabels.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    labelFlex: PropTypes.number,
    labels: PropTypes.arrayOf(Object),
    disabled: PropTypes.bool,
    id: PropTypes.string,
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
      }),
    labelsData: PropTypes.instanceOf(Object), 
    transformedFont: PropTypes.number,
}
export default VerticalLabels