import React, { useState } from 'react'
// import DateTimePicker from '@react-native-community/datetimepicker'
import DatePicker from 'react-native-date-picker'

import { TouchableOpacity, Text, StyleSheet } from 'react-native'
import moment from 'moment'

const styles = StyleSheet.create({
  dateConatiner: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 2,
    marginRight: 2,
    display: 'flex',
    flexDirection: 'row',
  },
})

const MobileDiaryDatePicker = (props) => {
  const { selectedDate, onDateChange, t, subjectTimezone, testID } = props
  const [openDatepicker, setOpenDatepicker] = useState(false)

  const onChange = (value) => {
    const date = new Date(value)
    setOpenDatepicker(false)
    onDateChange(date)
  }

  return (
    <React.Fragment>
      <TouchableOpacity
        onPress={() => setOpenDatepicker(!openDatepicker)}
        style={styles.dateConatiner}
        testID={testID}
        accessible={true}
      >
        <Text>{moment(selectedDate).format('DD-MMM-YYYY')}</Text>
      </TouchableOpacity>
      {openDatepicker && (
        <DatePicker
          modal
          open={() => setOpenDatepicker(true)}
          date={new Date(selectedDate) || new Date()}
          onConfirm={(date) => {
            onChange(date)
          }}
          onCancel={() => {
            setOpenDatepicker(false)
          }}
          mode="date"
          androidVariant="iosClone"
          testID={`${testID}-openDatepicker-view`}
          accessible={true}
        />
      )}
    </React.Fragment>
  )
}

export default MobileDiaryDatePicker
