import _ from 'lodash'
import { createSelector } from 'reselect'
import constants from '../constants/constants'

export const getSyncQueue = (state) => state.syncQueue.data
export const getSubjectId = (state) => state.subjectStudyMetaData.subject?.id
export const getCurrentUserId = (state) => state.users.currentUser?.id
export const getSessionUserId = (state) => state.syncQueue.data.sessionUser?.id


export const getOfflineFormsToSync = createSelector([getSyncQueue, getCurrentUserId], (syncQueue, currentUserId) => {
  return _.filter(syncQueue?.[currentUserId], (ele) => ele.isActive).length
})

export const getSubmittedSvfIds = createSelector([getSyncQueue, getCurrentUserId], (syncQueue, currentUserId) => {
  return _.uniqBy(_.map(syncQueue?.[currentUserId], (ele) => ele.url.split('/')[4]))
})

export const isDataExistsToSync = createSelector(
  [getSyncQueue, getSessionUserId],
  (syncQueueData, currentUserId) => {
    return !_.isEmpty(
      _.filter(
        syncQueueData?.[currentUserId],
        (item) =>
          item.status === constants.OfflineDataStatus.ACTIVE ||
          item.status === constants.OfflineDataStatus.IN_PROGRESS
      )
    )
  }
)
