import { Platform } from 'react-native'
import { getUniqueId } from 'react-native-device-info'
import uuid from 'react-uuid'
import constants from '../constants/constants'
import createActionType from '../utils/action'
import api from '../utils/api'
import { deviceConfig } from '../utils/deviceConfig'
import { setupInitialHealthKit } from '../utils/healthKit/Healthkit'
import {
  putItem,
  setSubjectCredentials,
  setSubjectDeviceToken,
  setSubjectAuthToken,
} from '../utils/secureStorageUtils'
import { storage } from '../utils/storage'
import showToast from '../utils/toast'
import { store } from '../store/configStore'
import enLocale from '../utils/localization/locale-en'
import jwt_decode from 'jwt-decode'
import { logout, updatedLoggedinUser } from './users'
import { generateBasicAuthToken } from '../utils/util'
import _ from 'lodash'
import NavigationService from '../containers/navigationService'
import appConstants from '../constants/appConstants'
import ConstraintStatus from '../utils/constraintKeys'
export const LOGIN_RETRIEVE_REQUEST = createActionType('LOGIN_RETRIEVE_REQUEST')
export const LOGIN_RETRIEVE_SUCCESS = createActionType('LOGIN_RETRIEVE_SUCCESS')
export const LOGIN_RETRIEVE_FAILURE = createActionType('LOGIN_RETRIEVE_AILURE')
export const OFFLINE_DATA_RETRIEVE_SUCCESS = createActionType('OFFLINE_DATA_RETRIEVE_SUCCESS')
export const SESSION_TIMEOUT = createActionType('SESSION_TIMEOUT')
import { getOfflineDataSuccess } from './subjectStudyMetaData'
import moment from 'moment-timezone'
import { clearStorageAndStoreSyncQueue } from './syncQueue'
import { isDataExistsToSync } from '../selectors/syncQueue'
import { updateAppType, updateOnMobileStatus } from './storeAppStatus'
import Constants from 'expo-constants'
import RenderHTML from 'react-native-render-html'
import openapi from '../utils/openapi'
const retrieveLoginRequest = () => ({
  type: LOGIN_RETRIEVE_REQUEST,
})

const retrieveLoginFailure = () => ({
  type: LOGIN_RETRIEVE_FAILURE,
})

export const retrieveLoginOfflineSuccess = (data) => ({
  type: OFFLINE_DATA_RETRIEVE_SUCCESS,
  offlineData: data,
})

export const retrieveLoginSuccess = (res, password) => ({
  type: LOGIN_RETRIEVE_SUCCESS,
  subject: {
    ...res,
    password,
  },
})

export const sessionTimeout = (timeout) => ({
  type: SESSION_TIMEOUT,
  timeout,
})

export const storeData = async (data) => {
  try {
    storage.set('subjectStudyMetaData', JSON.stringify(data))
  } catch (e) {
    // saving error
  }
}

export const retrieveLogin =
  (
    subject,
    navigation,
    deviceToken,
    hasPinSetup,
    t,
    deviceName,
    systemVersion,
    os,
    setLocale,
    accessCode
  ) =>
  async (dispatch,getState) => {
    const obj = deviceConfig()
    const data = {
      subject,
      mobileInfo: {
        systemVersion: obj.systemVersion,
        os: obj.os,
        deviceId: Platform.OS !== 'web' ? getUniqueId() : uuid(),
        web: Platform.OS === 'web' ? true : false,
      },
    }
    dispatch(retrieveLoginRequest())
    try {
      const config = {
        headers: {
          Authorization: generateBasicAuthToken(subject.phoneNo, subject.password),
        },
      }

      api.defaults.headers.common['Client-Type'] = 'mobile'
      let res = await openapi.post(`/subject/login`, data, config)
      if (res.data?.id) {
        checkUserAndClearStorage(res.data?.id)
        dispatch(updateAppType(constants.AppType.SUBJECT))
      }
      dispatch(updatedLoggedinUser(res.data))
      setSubjectAuthToken(
        res.headers['subjectauthorization-token'],
        res.headers['subjectrefresh-token']
      )
      const appType = getState().appStatus?.appType
      res.data.role === 'SUBJECT' ? setLocale(res.data.subject.locale) : setLocale(res.data.locale)
      if (Platform.OS === 'web' && accessCode && res.data?.privacyPolicy?.agreedToTerms
      && !res.data.resetPassword && !res.data.passwordExpired && constants.AppType.SITESTAFF !== appType) {
        return openMeeting(accessCode)
      }
      delete subject.password
      let offlineData = {
        ...res.data,
        subject: {
          ...res.data.subject,
        },
      }
      dispatch(getOfflineDataSuccess(offlineData))
      if (
        Platform.OS === 'ios' &&
        offlineData.subjectPrivileges?.includes(constants.APP_PRIVILEGES.VIEW_HEALTH_DATA)
      ) {
        setupInitialHealthKit()
      }
      if (!hasPinSetup) setSubjectCredentials(subject)
      updateDeviceToken({
        deviceToken: deviceToken,
        subjectId: res.data.id,
      })
      if (res.data.passwordExpired) {
        NavigationService.navigate('ResetPassword', {
          phoneNumber: res.data.phoneNo,
          fromLogin: true,
          passwordExpired: true,
        })
      } else if (
        (res.data.role === 'SUBJECT' || res.data.role === 'CAREGIVER') &&
        res.data?.privacyPolicy &&
        !res.data?.privacyPolicy?.agreedToTerms
      ) {
        let params ={}
        if(accessCode && constants.AppType.SITESTAFF !== appType) {
          params = {accessCode:accessCode}
        }
        NavigationService.navigate('TermsAndConditions', params)
      }else if (
        res.data.resetPassword &&
        (res.data.role === 'SUBJECT' || res.data.role === 'CAREGIVER')
      ) {
        let params = {
          phoneNumber: res.data.phoneNo,
          fromLogin: true
        }
        if(accessCode && constants.AppType.SITESTAFF !== appType) {
          params.accessCode = accessCode
          }
        NavigationService.navigate('ResetPassword', params)
      } else if (res.data.role === 'SUBJECT') {
        moment.tz.setDefault(res.data?.subject?.timeZone)
        NavigationService.navigate('OfflineDataLoader')
      } else if (res.data.role === 'CAREGIVER') {
        NavigationService.navigate('SubjectDataLoaderPage')
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        switch (error.response.status) {
          case 404:
            showToast(t('NetworkError'), 'danger', 3000)
            break
          case 500: {
            showMessageForInternalServerError(error.response.data, t)
            break
          }
          case 401 || 403:
            Constants.expoConfig.extra.nativeApp
              ? showToast(t('InvPhonePSWD'), 'danger', 3000)
              : showToast(<RenderHTML source={{ html: t('InvPhonePSWDWeb')}} />, 'danger', 3000)
            break
          case 423:
            showToast(t('UserLocked'), 'danger', 3000)
            break
          default:
            showToast(t('NetworkError'), 'danger', 3000)
        }
      } else {
        showToast(t('NetworkError'), 'danger', 3000)
      }
      dispatch(retrieveLoginFailure())
    }
  }
export const validateSubjectToken = async () => {
  try {
    const subjectDeviceToken = storage.getString('SubjectAuthorizationToken')
    const subjectRefreshToken = storage.getString('SubjectRefreshToken')
    console.log(subjectDeviceToken)
    if (_.isEmpty(subjectDeviceToken)) {
      return {}
    }
    if (_.isEmpty(subjectRefreshToken)) {
      return getAuthToken()
    }
    var decodedSubjectToken = jwt_decode(subjectDeviceToken)
    var decodedSubjectRefreshToken = jwt_decode(subjectRefreshToken)
    if (Date.now() >= decodedSubjectToken.exp * 1000) {
      if (Date.now() >= decodedSubjectRefreshToken.exp * 1000) {
       return navigateToSessionTimeout()
      } else {
        const tokenDetails = await getTokenFromRefreshToken(subjectRefreshToken)
        if (!_.isEmpty(tokenDetails)) {
          setSubjectAuthToken(tokenDetails.token, tokenDetails.refreshToken)
          return {
            subjectDeviceToken: tokenDetails.token,
          }
        } else {
          return navigateToSessionTimeout()
        }
      }
    }
    return getAuthToken()
  } catch (error) {
    console.log(error)
  }
}

const navigateToSessionTimeout = () => {
  const onMobile = store.getState()?.appStatus?.onMobile
  if (Platform.OS === 'web') {
    storage.clear()
  }
  store.dispatch(updateOnMobileStatus(onMobile))
  store.dispatch(sessionTimeout(true))
  NavigationService.navigate('InitialScreen', {sessionTimeout: true})
}
const getTokenFromRefreshToken = async (refreshToken) => {
  try {
    const res = await api.post(
      `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}/generateToken`,
      { refreshToken }
    )
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}
export const getAuthToken = () => {
  const subjectDeviceToken = storage.getString('SubjectAuthorizationToken')
  console.log(subjectDeviceToken)
  if (!subjectDeviceToken) {
    return {}
  }
  return {
    subjectDeviceToken: subjectDeviceToken,
  }
}

export const updateDeviceToken = async (subject) => {
  try {
    await api.put(
      `${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${subject?.subjectId}/deviceTokens`,
      subject
    )
  } catch (error) {
    console.log(error)
  }
}

export const openMeeting = (accessCode) => {
  const token = storage.getString('SubjectAuthorizationToken')
  const meetingUrl = `${appConstants.jitsiMeetUrl}/${accessCode}?jwt=${encodeURIComponent(token)}`
  window.open(meetingUrl, '_self')
}
const showMessageForInternalServerError = (errorCode, t) => {
  if (errorCode && Number(errorCode) === ConstraintStatus.USER_CAREGIVER_INACTIVE) {
    showToast(t('SubjectCaregiverInactive'), 'danger', 3000)
  }
  if (errorCode && (Number(errorCode) === ConstraintStatus.USER_INACTIVE || Number(errorCode) === ConstraintStatus.USER_WITHDRAWN || Number(errorCode) === ConstraintStatus.USER_SCREENING_FAILED)) {
    showToast(t('UserInactive'), 'danger', 3000)
  } else {
    showToast(t('SomethingWrong'), 'danger', 3000)
  }
}

const checkUserAndClearStorage = (currentUser) => {
  const existingUser = store.getState().users?.currentUser?.id
  const isOfflineDataExists = isDataExistsToSync(store.getState())
  if (existingUser && existingUser !== currentUser && !isOfflineDataExists) {
    store.dispatch(clearStorageAndStoreSyncQueue())
  }
}
