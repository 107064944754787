import _ from 'lodash'
import React, { Component } from 'react'
import { ActivityIndicator, View, Text } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { emptyFieldsInStore } from '../actions/field'
import {
  setCurrentScreen,
  storeOfflineFormsToSync,
  storeSyncStatus,
  updateDraftData,
  updateSyncCompleteStatus,
} from '../actions/storeAppStatus'
import { updateTrainingStatusBasedOnFormSubmission } from '../actions/subjectStudyMetaData'
import FormConsumer from '../components/form/FormConsumer'
import { backgroundColor, buttonBackgroundColor } from '../containers/NavigationScreens'
import { getFieldsFromFieldGroupList, getFieldGroupList } from '../selectors/field'
import { getCurrentVisit } from '../selectors/visits'
import { getSelectedSvf } from '../selectors/subjectVisitForm'
import constants from '../constants/constants'
import FormScreenHeader from '../components/common/FormScreenHeader'
import { store } from '../store/configStore'

class FormScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alreadyLoadedOfflineData: false,
      offlineSaveLoading: false,
      alreadyLoadedOnlineData: false,
      showFormInstruction: true,
    }
    this.fields = []
  }

  static navigationOptions = ({ navigation, screenProps }) => {
   return (store.getState()?.appStatus?.appType === constants.AppType.SUBJECT) ? {
    // headerLeft: () =>
    //   screenProps.appType === constants.AppType.SUBJECT && (
    //     <Header navigation={navigation} title={navigation.getParam('formName')} canShowBackIcon />
    //   ),
    // title: <Text style ={{ left: 10}}>{navigation.getParam('formName')}</Text>,
    headerLeft: () => <FormScreenHeader navigation={navigation} screenProps={screenProps} />
      
  }: {
    header: () => <FormScreenHeader navigation={navigation} screenProps={screenProps} />
  }}

  componentDidMount() {
    const {
      navigation,
      appStatus,
      selectedLanguage,
      screenProps: { t },
      selectedSvf,
      isDeviceOnline,
      setCurrentScreen,
    } = this.props
    const { svfId, formId, crfVersionId } = selectedSvf

    setCurrentScreen('FORM')
    const isDeviceOnlineAtDiary = navigation.getParam('isDeviceOnlineAtDiary')
    if (isDeviceOnline && isDeviceOnlineAtDiary) {
      this.setState({
        alreadyLoadedOnlineData: true,
      })
    } else {
      this.setState({
        alreadyLoadedOfflineData: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline !== prevProps.isDeviceOnline && !isDeviceOnline) {
      this.setState({
        alreadyLoadedOfflineData: true,
      })
    } else if (isDeviceOnline !== prevProps.isDeviceOnline && isDeviceOnline) {
      this.setState({
        alreadyLoadedOnlineData: true,
      })
    }
  }

  updateOfflineSaveLoading = (offlineSaveLoading) => {
    this.setState({ offlineSaveLoading })
  }

  componentWillUnmount = () => {
    const { emptyFieldsInStore } = this.props
    this.setState({
      alreadyLoadedOfflineData: false,
      alreadyLoadedOnlineData: false,
    })
    this.fields = []
    emptyFieldsInStore()
  }

  onNext = () => {
    this.setState({
      showFormInstruction: false,
    })
  }

  render() {
    const {
      navigation,
      loading,
      storeOfflineFormsToSync,
      subject,
      subjectTimezone,
      screenProps,
      isDeviceOnline,
      OfflineFormsToSync,
      selectedSvf,
      subjectVisitId,
      subjectId,
      rsaPublicKey,
      storeSyncStatus,
      updateSyncCompleteStatus,
      connectionFailed,
      updateTrainingStatusBasedOnFormSubmission: updateTrainingForms,
      draftData,
      updateDraftData: saveDraftData,
      fields,
      formAnswers, scheduleDate, isSvfUpdated, fieldGroupList
    } = this.props
    const { offlineSaveLoading, alreadyLoadedOnlineData } = this.state
    const loadOfflineData = connectionFailed === true ? connectionFailed : !alreadyLoadedOnlineData
    const loadOnlineData = connectionFailed === true ? !connectionFailed : alreadyLoadedOnlineData

    const isDeviceOnlineAtDiary = navigation.getParam('isDeviceOnlineAtDiary')
    const isTraining = navigation.getParam('isTraining')
    this.fields = fields
    if (_.isEmpty(this.fields)) {
      return (
        <ActivityIndicator
          size="large"
          color={backgroundColor}
          animating={true}
          key={loading ? 'loading' : 'not-loading'}
        />
      )
    }
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {(loading || offlineSaveLoading) && (
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              zIndex: 2,
            }}
          >
            <ActivityIndicator
              size="large"
              color={buttonBackgroundColor}
              animating={true}
              key={loading ? 'loading' : 'not-loading'}
            />
          </View>
        )}

        {
          // (!!selectedSvf.form.instruction && this.state.showFormInstruction) ?
          //   (<FormInstruction
          //     instruction={selectedSvf.form.instruction}
          //     onNext={this.onNext}
          //     screenProps={screenProps}
          //   />) :

          //  <Form
          //   storeSyncStatus={storeSyncStatus}
          //     subjectId={subjectId}
          //     rsaPublicKey={rsaPublicKey}
          //     subjectVisitId={subjectVisitId}
          //     storeOfflineFormsToSync={storeOfflineFormsToSync}
          //     OfflineFormsToSync={OfflineFormsToSync}
          //     isDeviceOnline={isDeviceOnline}
          //     subjectTimezone={subjectTimezone}
          //     navigation={navigation}
          //     fieldList={this.fields}
          //     subject={subject}
          //     loading={loading}
          //     screenProps={screenProps}
          //     selectedSvf={selectedSvf}
          //     updateSyncCompleteStatus={updateSyncCompleteStatus}
          //     updateOfflineSaveLoading={this.updateOfflineSaveLoading}
          //     offlineSaveLoading={offlineSaveLoading}
          //     isTraining = { isTraining }
          //     updateTrainingStatusBasedOnFormSubmission = { updateTrainingForms }
          //     draftData = { draftData }
          //     saveDraftData = { saveDraftData }
          //   />
          <FormConsumer
            {...this.props}
            screenProps={screenProps}
            selectedSvf={selectedSvf}
            subjectTimezone={subjectTimezone}
            draftData={draftData}
            updateOfflineSaveLoading={this.updateOfflineSaveLoading}
            formAnswers={formAnswers}
            scheduleDate={scheduleDate}
            isSvfUpdated={isSvfUpdated}
            fieldList={fields}
            fieldGroupList={fieldGroupList}
            
          />
        }
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  rsaPublicKey: state.subjectStudyMetaData.subject.publicKey,
  subject: state.subjectStudyMetaData.subject,
  subjectId: state.subjectStudyMetaData.subject.id,
  selectedLanguage: state.changeLanguage.selectedLanguage,
  fields: getFieldsFromFieldGroupList(state),
  loading: state.field.loading,
  crfDataLoading: state.crfData.loading,
  subjectTimezone: state.subjectStudyMetaData.subject.timeZone,
  OfflineFormsToSync: state.appStatus.OfflineFormsToSync,
  isSyncing: state.appStatus.isSyncing,
  isDeviceOnline: state.appStatus.isDeviceOnline,
  currentScreen: 'FORM',
  selectedSvf: getSelectedSvf(state),
  subjectVisitId: _.get(getCurrentVisit(state), 'id', null),
  appStatus: state.appStatus,
  connectionFailed: state.field.connectionFailed,
  draftData: state.appStatus.draftData,
  appType: state.appStatus.appType,
  formAnswers: state.formAnswers.data,
  fieldGroupList: getFieldGroupList(state),
  isSvfUpdated: state.subjectVisitForm.selectedSvf?.isSvfUpdated,
  scheduleDate: state.metaData.selectedDate,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeOfflineFormsToSync,
      setCurrentScreen,
      storeSyncStatus,
      updateSyncCompleteStatus,
      emptyFieldsInStore,
      updateTrainingStatusBasedOnFormSubmission,
      updateDraftData,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FormScreen)
