import React from 'react'
import { Text } from 'react-native'
import fieldTypes from '../../constants/fieldTypes'
// import SliderExample from '../../components/fields/SSlider'
import DefaultScale from '../../components/fields/BasicScale'
// import SliderExample from '../../components/fields/AntSlider'
import { TextInput } from '../../components/fields/TextInput'
import { TextArea } from '../../components/fields/TextArea'
import { NumericInput } from '../../components/fields/NumericInput'
import { SingleSelect } from '../../components/fields/SingleSelect'
import { MultiSelect } from '../../components/fields/MultiSelect'
import { DateInput } from '../../components/fields/DateInput'
// import { DateTime } from '../../components/fields/DateTime';
import { DateTime12 } from '../../components/fields/DateTime12'
import { DateTime24 } from '../../components/fields/DateTime24'
import Barcode from '../../components/fields/Barcode'
import FormClickableImagePage from '../../containers/FormClickableImagePage'
import NRScale from '../../components/fields/NRScale'
// import { YesNo } from '../../components/fields/YesNo';
// import { YesNoNA } from '../../components/fields/YesNoNA';
// import { Instruction } from '../../components/fields/Instruction';
// import { PainScale } from '../../components/fields/PainScale';
// import { NumericRatingScale } from '../../components/fields/NumericRatingScale';
// import { VisualAnalogScale } from '../../components/fields/visualAnalogScale'
// import { ClickableImage } from '../../components/fields/ClickableImage';
import VASScale from '../../components/fields/Visual Scale/VASContainer'
import CaptureImage from '../../components/fields/CaptureImage'
import UploadAudio from '../../components/fields/UploadAudio'
import RecordVideo from '../../components/fields/RecordVideo'

const {
  TEXT,
  TEXTAREA,
  NUMBER,
  RADIO,
  CHECKBOX,
  DATE,
  // DATETIME,
  DATETIME12,
  DATETIME24,
  YESNO,
  YESNONA,
  // INSTRUCTION,
  PS,
  NRS,
  VAS,
  CLICKIMAGE,
  BARCODE,
  SCORE,
  LABEL,
  RESULT,
  IMAGE,
  AUDIO,
  VIDEO,
} = fieldTypes

export const getFieldComponent = (field, form, t, subjectTimezone, disabled, mode) => {
  switch (field.fieldType) {
    case TEXT:
      return {
        component: TextInput,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case TEXTAREA:
      return {
        component: TextArea,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case NUMBER:
      return {
        component: NumericInput,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case RADIO:
      return {
        component: SingleSelect,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case CHECKBOX:
      return {
        component: MultiSelect,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case DATE:
      return {
        component: DateInput,
        props: {
          field,
          form,
          t,
          subjectTimezone,
          disabled,
        },
      }
    // case DATETIME:
    //     return <DateTime field={field}/>;
    case DATETIME12:
      return {
        component: DateTime12,
        props: {
          field,
          form,
          t,
          subjectTimezone,
          disabled,
        },
      }
    case DATETIME24:
      return {
        component: DateTime24,
        props: {
          field,
          form,
          t,
          subjectTimezone,
          disabled,
        },
      }
    case YESNO:
      return {
        component: SingleSelect,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case YESNONA:
      return {
        component: SingleSelect,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    // case INSTRUCTION:
    //     return <Instruction field={field}/>;
    case PS:
      return {
        component: DefaultScale,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case NRS:
      return {
        component: NRScale,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case VAS:
      return {
        component: VASScale,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case CLICKIMAGE:
      return {
        component: FormClickableImagePage,
        props: {
          field,
          form,
          t,
          disabled,
          mode
        },
      }
    case BARCODE:
      return {
        component: Barcode,
        props: {
          field,
          form,
          t,
          type: 'barcode',
          disabled,
        },
      }
    case SCORE:
      return {
        component: SingleSelect,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
    case RESULT:
      return {
        component: TextInput,
        props: {
          field,
          form,
          t,
          disabled: true,
        },
      }
    case LABEL:
      return {
        component: Text,
        props: {
          children: '',
        },
      }
    case IMAGE:
      return {
        component: TextInput,
        props: {
          field,
          form,
          t,
          type: 'image',
          disabled,
        },
      }
    case AUDIO:
      return {
        component: TextInput,
        props: {
          field,
          form,
          t,
          type: 'audio',
          disabled,
        },
      }
    case VIDEO:
      return {
        component: TextInput,
        props: {
          field,
          form,
          t,
          type: 'video',
          disabled,
        },
      }
    default:
      return {
        component: TextInput,
        props: {
          field,
          form,
          t,
          disabled,
        },
      }
  }
}

export const wrapFieldType = (field, form, t, subjectTimezone) => {
  switch (field.fieldType) {
    case TEXT:
      return <TextInput field={field} form={form} t={t} />
    case TEXTAREA:
      return <TextArea field={field} form={form} t={t} />
    case NUMBER:
      return <NumericInput field={field} form={form} t={t} />
    case RADIO:
      return <SingleSelect field={field} form={form} t={t} />
    case CHECKBOX:
      return <MultiSelect field={field} form={form} t={t} />
    case DATE:
      return <DateInput field={field} form={form} t={t} subjectTimezone={subjectTimezone} />
    // case DATETIME:
    //     return <DateTime field={field}/>;
    case DATETIME12:
      return <DateTime12 field={field} form={form} t={t} subjectTimezone={subjectTimezone} />
    case DATETIME24:
      return <DateTime24 field={field} form={form} t={t} subjectTimezone={subjectTimezone} />
    case YESNO:
      return <SingleSelect field={field} form={form} t={t} />
    case YESNONA:
      return <SingleSelect field={field} form={form} t={t} />
    // case INSTRUCTION:
    //     return <Instruction field={field}/>;
    case PS:
      return <DefaultScale field={field} form={form} t={t} />
    case NRS:
      return <NRScale field={field} form={form} t={t} />
    case VAS:
      return <VASScale field={field} form={form} t={t} />
    case CLICKIMAGE:
      //     return <ClickableImage field={field}/>;
      return <FormClickableImagePage field={field} form={form} t={t} />
    case BARCODE:
      return <Barcode field={field} form={form} t={t} type="barcode" />
    case SCORE:
      return <SingleSelect field={field} form={form} t={t} />
    default:
      return <TextInput field={field} form={form} t={t} />
  }
}

export const getFieldUISchemaData = (fieldUISchema, type, position, defaultValue) => {
  var fdUISchema = fieldUISchema != null ? JSON.parse(fieldUISchema) : null
  if (fdUISchema != null) {
    switch (type) {
      case 'fieldName':
        if (position === 'top' && fdUISchema.fNameTopSpace != null) {
          return fdUISchema.fNameTopSpace
        } else if (position === 'bottom' && fdUISchema.fNameBottomSpace != null) {
          return fdUISchema.fNameBottomSpace
        } else {
          return defaultValue
        }

      case 'fieldInstruction':
        if (position === 'top' && fdUISchema.fInstructionTopSpace != null) {
          return fdUISchema.fInstructionTopSpace
        } else if (position === 'bottom' && fdUISchema.fInstructionBottomSpace != null) {
          return fdUISchema.fInstructionBottomSpace
        } else {
          return defaultValue
        }
      case 'options':
        if (position === 'top' && fdUISchema.optionTopSpace != null) {
          return fdUISchema.optionTopSpace
        } else if (position === 'bottom' && fdUISchema.optionBottomSpace != null) {
          return fdUISchema.optionBottomSpace
        } else {
          return defaultValue
        }
      default:
        return defaultValue
    }
  }
  return defaultValue
}
