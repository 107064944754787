import React, { Component } from 'react'
import { connect } from 'react-redux'
import ParticipantList from '../../components/scheduledCalls/participantList'

class ParticipantListPage extends Component {
  state = {}

  render() {
    const { navigation, selectedCall } = this.props
    return <ParticipantList {...this.props} call={selectedCall} />
  }
}

const mapStateToProps = (state) => ({
  selectedCall: state.call.selectedCall,
})

export default connect(mapStateToProps)(ParticipantListPage)
