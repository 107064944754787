import _ from 'lodash'
import { createSelector } from 'reselect'
import Constants from 'expo-constants'
import appConstants from '../constants/constants'
import { store } from '../store/configStore'
import fieldTypes from '../constants/fieldTypes'

const getAppType = () => {
  const state = store.getState()
  const applicationType = state.appStatus.appType
  return applicationType
}

const getOfflineData = (state) => state.subjectStudyMetaData

const getselectedVisitFormOid = (state) => state.metaData.selectedVisitFormOid

const getFieldListOfMultipleForms = (state) => state.field.fieldListOfMultipleForms

const getFieldListOfMultipleFormsFormPrint = (state) => state.formPrint.field.fieldListOfMultipleForms

const getselectedVisitFormOidFormPrint = (state) => state.formPrint.selectedVisitFormOid

const getSubjectId = (state) => state.subjectStudyMetaData.subject.id

const getImagesMetadata = (state) => state.metaData.data.images


export const buildOfflineFields = createSelector(
  [getOfflineData, getselectedVisitFormOid],
  (data, visitFormOid) => {
    if (appConstants.AppType.SITESTAFF === getAppType()) {
      return []
    }
    const selectedVisitForm = _.head(
      _.filter(_.head(data.study.visits).visitForms, (visitForm) => {
        return visitForm.visitFormOid === visitFormOid
      })
    )
    if (selectedVisitForm && !_.isEmpty(selectedVisitForm)) {
      const fields = _.map(_.head(selectedVisitForm.form.fieldGroups).fields, (field) => ({
        ...field,
        dictionary: field.dictionary ? JSON.parse(field.dictionary) : null,
        enDictionary: field.enDictionary ? JSON.parse(field.enDictionary) : null,
      }))
      return _.sortBy(fields, ['ordinal'])
    } else {
      return []
    }
  }
)


export const getFieldGroupList = createSelector(
  [getFieldListOfMultipleForms, getselectedVisitFormOid],
  (fieldsListOfForms, formOid) => {
    return fieldsListOfForms[formOid] || []
  }
)

export const getFieldGroupListFormPrint = createSelector(
  [getFieldListOfMultipleFormsFormPrint, getselectedVisitFormOidFormPrint],
  (fieldsListOfForms, formOid) => {
    return fieldsListOfForms[formOid] || []
  }
)

export const getFieldsFromFieldGroupList = createSelector([getFieldGroupList], (fgList) => {
  return _.flatten(_.map(fgList, (fg) => fg.fields)) || []
})

export const getFieldsFromFieldGroupListFormPrint = createSelector([getFieldGroupListFormPrint], (fgList) => {
  return _.flatten(_.map(fgList, (fg) => fg.fields)) || []
})

export const isSvfClickableImagesDownloaded = createSelector([getFieldGroupList, getImagesMetadata, getSubjectId], (fgList, imagesMetadata, subjectId) => {
    const fields = _.flatten(_.map(fgList, (fg) => fg.fields)) || []
    const clkImageFields = _.filter(fields, field => field.fieldType == fieldTypes.CLICKIMAGE)
    if(_.isEmpty(clkImageFields)){
      return true
    }else{
      const subjectImageMetadata = !_.isEmpty(imagesMetadata) ?imagesMetadata[subjectId] : {}
      if(_.isEmpty(subjectImageMetadata)){
        return false;
      }
      let notDownloadedImages = _.filter(clkImageFields, image => _.isEmpty(subjectImageMetadata[image.dictionary?.imageCode]?.downloadedUrl)) 
      return _.isEmpty(notDownloadedImages) ? true : false
    }
})


export const getClickableImagesOfLoggedInSubject = createSelector([getImagesMetadata, getSubjectId], (imagesMetadata, subjectId) => {
  return !_.isEmpty(imagesMetadata?.[subjectId]) ? imagesMetadata[subjectId] : {}
})