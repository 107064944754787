import { TouchableOpacity, View , StyleSheet, Text} from "react-native"
import { deviceConfig } from '../../utils/deviceConfig'
import { putItem, removeCredentials, removeSubjectDeviceToken } from '../../utils/secureStorageUtils'
import storage from '../../utils/storage/storage'
import { changeLanguageSuccess } from '../../actions/changeLanguage'
import {handleLogout} from '../../actions/users'
import { useDispatch, useSelector } from 'react-redux'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { TermsAndConditionButtonStyles } from "../TermsNConditions/TermsNConditionButtons"
import { updateOnMobileStatus } from "../../actions/storeAppStatus"

const LogoutStyles = StyleSheet.create({
    buttonContainer: {flexDirection: 'row', padding: 4 },
    textStyle: {color: '#fff' }
})

const ParticipantLogout = (props) => {
    const { navigation, screenProps: { t, setLocale },} = props
    const dispatch = useDispatch()
    const { subjectStudyMetaData, users, appStatus } = useSelector((state) => state)
    const {onMobile} = appStatus

  const logoutAction = async () => {
        try {
          const obj = deviceConfig()
          const data = {
            subject: {
              studySite: { client: { id: subjectStudyMetaData?.studySite?.client?.id } },
              phoneNo: users?.currentUser.phoneNo,
              id: users?.currentUser.id,
            },
            mobileInfo: {
              systemVersion: obj.systemVersion,
              os: obj.os,
            },
          }
          await dispatch(handleLogout(data))
          await removeCredentials()
          await removeSubjectDeviceToken()
          storage.clear()
          await dispatch(updateOnMobileStatus(onMobile))
          changeLanguageSuccess('en_US')
          setLocale('en_US')
          putItem('en_US', 'locale')
          navigation.navigate('Login')
        } catch (error) {
          console.log(error)
        }
 }

    return(
    <View >
        <View>
            <TouchableOpacity style={[TermsAndConditionButtonStyles.buttonStyle]} onPress={() => logoutAction()}>
                  <View style={[TermsAndConditionButtonStyles.buttonTxtStyle, LogoutStyles.buttonContainer]}>
                    <MaterialCommunityIcons name="logout" size={18} color={'#fff'} />
                    <Text style={LogoutStyles.textStyle}>{t('Logout')}</Text>
                </View>
            </TouchableOpacity>
        </View>
    </View>
    )
}

export default ParticipantLogout