import createActionType from '../utils/action'
import _ from 'lodash'
import { getAllCrfDataOfSubject } from './formAnswers'
import api from '../utils/api'
import { Platform } from 'react-native'
import ConstraintStatus from '../utils/constraintKeys'
import constants from '../constants/constants'
import fieldTypes from '../constants/fieldTypes'
import fileapi from '../utils/fileapi'
import { imageExists } from '../utils/util'


export const GET_META_DATA_REQUEST = createActionType('GET_META_DATA_REQUEST')
export const GET_META_DATA_FAILURE = createActionType('GET_META_DATA_FAILURE')
export const GET_META_DATA_SUCCESS = createActionType('GET_META_DATA_SUCCESS')
export const UPDATE_SELECTED_VISIT_ID = createActionType('UPDATE_SELECTED_VISIT_ID')
export const NO_META_DATA_UPDATED = createActionType('NO_META_DATA_UPDATED')
export const UPDATE_SELECTED_VISIT_FROM_ID = createActionType('UPDATE_SELECTED_VISIT_FROM_ID')
export const UPDATE_IMAGE_CODES = createActionType('UPDATE_IMAGE_CODES')
export const UPDATE_DOWNLOADED_IMAGE_URL = createActionType('UPDATE_DOWNLOADED_IMAGE_URL')

export const RETRIEVE_ARM_VISIT_SCHEDULE_REQUEST = createActionType(
  'RETRIEVE_ARM_VISIT_SCHEDULE_REQUEST'
)
export const RETRIEVE_ARM_VISIT_SCHEDULE_SUCCESS = createActionType(
  'RETRIEVE_ARM_VISIT_SCHEDULE_SUCCESS'
)
export const RETRIEVE_ARM_VISIT_SCHEDULE_FAILURE = createActionType(
  'RETRIEVE_ARM_VISIT_SCHEDULE_FAILURE'
)
export const UPDATE_SVF_STATUS = createActionType('UPDATE_SVF_STATUS')
export const UPDATE_SELECTED_DATE = createActionType('UPDATE_SELECTED_DATE')

export const getMetaData =
  (subjectId, lastUpdatedDateOn, fromLogin = false) =>
  async (dispatch, getState) => {
    const studyId = _.get(getState().subjectStudyMetaData.study, 'id', null)
    const subjectMetadataRequest = {
      subjectId: subjectId,
      lastUpdatedDateOn: lastUpdatedDateOn,
    }
    dispatch({ type: GET_META_DATA_REQUEST })
    try {
      const res = await api.post(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${studyId}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/subjectMetaData`,
        subjectMetadataRequest
      )
      dispatch({ type: GET_META_DATA_SUCCESS, metaData: res.data })
      if (fromLogin) dispatch(getAllCrfDataOfSubject(subjectId))
    } catch (error) {
      console.error(error)
      if (error.response) {
        switch (error.response.status) {
          case 500: {
            console.log(error.response.data)
            if (
              error.response.data &&
              Number(error.response.data) == ConstraintStatus.NO_META_DATA_UPDATED
            ) {
              dispatch({ type: NO_META_DATA_UPDATED })
              break
            }
          }
          default:
            dispatch({ type: GET_META_DATA_FAILURE })
        }
      } else {
        dispatch({ type: GET_META_DATA_FAILURE })
      }
    }
  }

export const getFormIds = (metadata, svId, appType) => {
  var subjectVisits = []
  if (Platform.OS === 'web' && svId) {
    subjectVisits = _.filter(metadata.data.subjectVisits, (sv) => sv.id === svId)
  } else {
    subjectVisits = metadata.data.subjectVisits
  }
  let allSubjectVisitForms = []
  _.forEach(
    _.map(subjectVisits, (sv) => sv.subjectVisitForms),
    (svf) => {
      allSubjectVisitForms = allSubjectVisitForms.concat(svf)
    }
  )

  let filteredSvfs = []

  if (_.isEqual(constants.AppType.SITESTAFF, appType)) {
    filteredSvfs = _.filter(allSubjectVisitForms, (svf) =>
      _.isEqual(svf?.form?.formType, constants.FormTypes.RATER_ASSESSMENT)
    )
  } else {
    filteredSvfs = _.filter(allSubjectVisitForms, (svf) =>
      _.isEqual(svf?.form?.formType, constants.FormTypes.SUBJECT_DIARY)
    )
  }
  return _.map(filteredSvfs, (svf) => svf?.form?.id)
}

export const updateSelectedVisitId = (visitId) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_VISIT_ID,
    selectedVisitId: visitId,
  })
}

export const updateSelectedVisitFormOidAndSelectedSvfId = (formId, svfId) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_VISIT_FROM_ID,
    visitFormOid: formId,
    svfId: svfId,
  })
}

export const updateSvfStatus =
  (svf, subjectVisitId, status, completedDate = null, svfStatus) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_SVF_STATUS,
      svfId: svf,
      subjectVisitId,
      status,
      completedDate,
      svfStatus,
    })
  }


export const updateSelectedVisitData = (armVisitSchedule, svId) => async (dispatch) => {
  dispatch({
    type: RETRIEVE_ARM_VISIT_SCHEDULE_SUCCESS,
    armVisitSchedule: armVisitSchedule,
    visitId: svId,
  })
}

export const updateSelectedDate = (selectedDate) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_DATE,
    date: selectedDate,
  })
}



export const getClickableImagesConfigured  = () => async (dispatch, getState) => {
  const subjectVisitFormList = getSubjectVisitForms(getState())
  const formList = getState()?.form?.formList
  const fieldList = getState()?.field?.fieldListOfMultipleForms
  const subjectId = getState()?.subjectStudyMetaData?.subject?.id
  const imagesMetadata = getState()?.metaData?.data?.images || {}
  var imagesOfSubject  = !_.isEmpty(imagesMetadata[subjectId]) ? imagesMetadata[subjectId] : {}
  let imagesAvailables = false
  _.forEach(subjectVisitFormList, svf => {
    const fields = getFieldsOfSubjectVisitForm(svf, formList, fieldList)
    const clickableImageFields = _.filter(fields, field => _.isEqual(field?.fieldType, fieldTypes.CLICKIMAGE))
    const imagesTobeDownloaded =_.filter(clickableImageFields, imageField => _.isEmpty(imagesOfSubject[imageField?.dictionary?.imageCode]) || _.isEmpty(imagesOfSubject[imageField?.dictionary?.imageCode]?.downloadedUrl))
    if(!_.isEmpty(imagesTobeDownloaded)){
      imagesAvailables = true
      _.forEach(imagesTobeDownloaded, fld => {
        imagesOfSubject[fld?.dictionary?.imageCode] = {
          fieldUrl: fld?.dictionary?.img?.src
        }
    })
   }
  });
  if(imagesAvailables){
    dispatch({type: UPDATE_IMAGE_CODES, data: imagesOfSubject, subjectId: subjectId})
  }else if(!imagesAvailables && !_.isEmpty(imagesOfSubject) && Platform.OS == 'web'){
    await dispatch(checkImagesValidAndRetryDownload())
  }
}

//checking existing images are valid 
export const checkImagesValidAndRetryDownload = () => async(dispatch, getState) => {
  const imagesMetadata = getState()?.metaData?.data?.images || {}
  const subjectId = getState()?.subjectStudyMetaData?.subject?.id
  const imagesOfSubject  = !_.isEmpty(imagesMetadata?.[subjectId]) ? imagesMetadata[subjectId] : {}
  let imagesValid = true
  if(!_.isEmpty(imagesOfSubject)){
    const keys = Object.keys(imagesOfSubject)
    for(const key of keys){
      const isExist = await imageExists(imagesOfSubject[key].downloadedUrl)
      if(!isExist){
        imagesOfSubject[key].downloadedUrl = null;
        imagesValid = false
      }
    }
  }
  if(!imagesValid){
    dispatch({type: UPDATE_IMAGE_CODES, data: imagesOfSubject, subjectId: subjectId})
  }
}


export const updateDownloadedUrl = (downloadedUrl, key, subjectId) => async (dispatch, getState) => {
  if(!subjectId){
    subjectId = getState()?.subjectStudyMetaData?.subject?.id
  }
  dispatch({
    type: UPDATE_DOWNLOADED_IMAGE_URL,
    key: key,
    downloadedUrl: downloadedUrl,
    subjectId
  })
}

const getSubjectVisitForms = (state) => {
  var subjectVisits = state?.metaData?.data?.subjectVisits
  let allSubjectVisitForms = []
  _.forEach(
    _.map(subjectVisits, (sv) => sv?.subjectVisitForms),
    (svf) => {
      allSubjectVisitForms = allSubjectVisitForms.concat(svf)
    }
  )
 return _.orderBy(allSubjectVisitForms, 'scheduleStartTime')
}

const getFieldsOfSubjectVisitForm = (svf, allFormList, allFields) => {
  var form = _.filter(allFormList, form => _.isEqual(form?.id, svf?.form?.id))
  return !_.isEmpty(form) ? _.flatten(_.map(allFields[form[0].id], (fg) => fg.fields)) || [] : []
}


export const getImagesAvailableForDownload =(forPreview) => async (dispatch, getState) => {
  const images= getState()?.metaData?.data?.images;
  const subjectId = forPreview ? constants.AppType.PREVIEW : getState()?.subjectStudyMetaData?.subject?.id;
  if(!_.isEmpty(images?.[subjectId])){
    const tobeDownloadedImages = _.pickBy(images[subjectId], img => _.isEmpty(img?.downloadedUrl))
    return tobeDownloadedImages
  }
  return []
}
//To download clickable images to store locally
export const downloadClickableImage =  async(fileName) => {
  try {
    return await fileapi.get(`/clickableImage/download?fileName=${fileName}`, { responseType: 'blob'})  
  } catch (error) {
    console.log(error)
  }
}