import React, { Component } from 'react'
import { ActivityIndicator, Platform, View, Image } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeLanguageSuccess } from '../../actions/changeLanguage'
import { setCurrentScreen, storeOfflineFormsToSync } from '../../actions/storeAppStatus'
import { getOfflineData } from '../../actions/subjectStudyMetaData'
import { backgroundColor } from '../../containers/NavigationScreens'
import { getOfflineForms } from '../../utils/offline/dataSync'
import { getPin } from '../../utils/secureStorageUtils'
import PIN from './PIN'
import {clearStorageAndStoreSyncQueue} from '../../actions/syncQueue'
import { updateIsAcknowledged } from '../../actions/storeAppStatus'
// import { setupInitialHealthKit } from '../../utils/healthKit/Healthkit';

class PinValidation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appPin: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { setCurrentScreen } = this.props
    setCurrentScreen('PIN')
    if (Platform.OS === 'ios') {
      // setupInitialHealthKit();
    }
  }

  retrieveAppPin = async () => {
    const { loggedInUserId } = this.props;
    const appPin = getPin(loggedInUserId)
    this.setState({
      appPin,
      loading: false,
    })
  }

  login = async () => {
    const {
      storeOfflineFormsToSync: formsToSync,
      selectedLanguage,
      navigation,
      screenProps: { t },
      retrieveOfflineData,
      updateIsAcknowledged
    } = this.props
    updateIsAcknowledged(false)
    retrieveOfflineData(navigation)
  }

  render() {
    const {
      navigation,
      loading: appLoading,
      screenProps,
      subject,
      clientID,
      changeLanguageSuccess: changeSubjectLanguageSuccess,
      loggedInUserId,
      syncQueueData,
      clearStorageAndStoreSyncQueue
    } = this.props
    const { loading, appPin } = this.state
    return (
      <View style={{ flex: 1 }}>
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator
              size="large"
              color={backgroundColor}
              overlayColor="rgba(0, 0, 0, 0.07)"
            />
          </View>
        )}
        <NavigationEvents
          onWillFocus={() => {
            this.retrieveAppPin()
          }}
        />
        {!loading && (
          <PIN
            login={this.login}
            navigation={navigation}
            mode="validate"
            appPin={appPin}
            screenProps={screenProps}
            changeLanguageSuccess={changeSubjectLanguageSuccess}
            subject={subject}
            clientID={clientID}
            loggedInUserId = {loggedInUserId}
            syncQueueData={syncQueueData}
            clearStorageAndStoreSyncQueue={clearStorageAndStoreSyncQueue}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.changeLanguage.selectedLanguage,
  loading: state.loading,
  currentScreen: state.appStatus.currentScreen,
  subject: state.subjectStudyMetaData.subject,
  clientID: state.subjectStudyMetaData?.studySite?.client?.id,
  loggedInUserId: state.users.currentUser?.phoneNo,
  syncQueueData: state.syncQueue.data,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveOfflineData: getOfflineData,
      storeOfflineFormsToSync,
      setCurrentScreen,
      changeLanguageSuccess,
      clearStorageAndStoreSyncQueue,
      updateIsAcknowledged,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PinValidation)
