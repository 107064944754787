export default {
"Africa/Abidjan":"Afrique/Abidjan",
"Africa/Accra":"Afrique/Accra",
"Africa/Addis_Ababa":"Afrique/Addis_Ababa",
"Africa/Algiers":"Afrique/Alger",
"Africa/Asmara":"Afrique/Asmara",
"Africa/Asmera":"Afrique/Asmera",
"Africa/Bamako":"Afrique/Bamako",
"Africa/Bangui":"Afrique/Bangui",
"Africa/Banjul":"Afrique/Banjul",
"Africa/Bissau":"Afrique/Bissau",
"Africa/Blantyre":"Afrique/Blantyre",
"Africa/Brazzaville":"Afrique/Brazzaville",
"Africa/Bujumbura":"Afrique/Bujumbura",
"Africa/Cairo":"Afrique/Le Caire",
"Africa/Casablanca":"Afrique/Casablanca",
"Africa/Ceuta":"Afrique/Ceuta",
"Africa/Conakry":"Afrique/Conakry",
"Africa/Dakar":"Afrique/Dakar",
"Africa/Dar_es_Salaam":"AFRIQUE/Dar_es_Salaam",
"Africa/Djibouti":"Afrique/Djibouti",
"Africa/Douala":"Afrique/Douala",
"Africa/El_Aaiun":"Afrique/El_Aaiun",
"Africa/Freetown":"Afrique/Freetown",
"Africa/Gaborone":"Afrique/Gaborone",
"Africa/Harare":"Afrique/Harare",
"Africa/Johannesburg":"Afrique/Johannesburg",
"Africa/Juba":"Afrique/Juba",
"Africa/Kampala":"Afrique/Kampala",
"Africa/Khartoum":"Afrique/Khartoum",
"Africa/Kigali":"Afrique/Kigali",
"Africa/Kinshasa":"Afrique/Kinshasa",
"Africa/Lagos":"Afrique/Lagos",
"Africa/Libreville":"Afrique/Libreville",
"Africa/Lome":"Afrique/Lomé",
"Africa/Luanda":"Afrique/Luanda",
"Africa/Lubumbashi":"Afrique/Lubumbashi",
"Africa/Lusaka":"Afrique/Lusaka",
"Africa/Malabo":"Afrique/Malabo",
"Africa/Maputo":"Afrique/Maputo",
"Africa/Maseru":"Afrique/Maseru",
"Africa/Mbabane":"Afrique/Mhabane",
"Africa/Mogadishu":"Afrique/Mogadiscio",
"Africa/Monrovia":"Afrique/Monrovia",
"Africa/Nairobi":"Afrique/Nairobi",
"Africa/Ndjamena":"Afrique/Ndjamena",
"Africa/Niamey":"Afrique/Niamey",
"Africa/Nouakchott":"Afrique/Nouakchott",
"Africa/Ouagadougou":"Afrique/Ouagadougou",
"Africa/Porto-Novo":"Afrique/Porto-Novo",
"Africa/Sao_Tome":"Afrique/Sao_Tome",
"Africa/Timbuktu":"Afrique/Tombouctou",
"Africa/Tripoli":"Afrique/Tripoli",
"Africa/Tunis":"Afrique/Tunis",
"Africa/Windhoek":"Afrique/Windhoek",
"America/Adak":"Amérique/Adak",
"America/Anchorage":"Amérique/Anchorage",
"America/Anguilla":"Amérique/Anguilla",
"America/Antigua":"Amérique/Antigua",
"America/Araguaina":"Amérique/Araguaina",
"America/Argentina/Buenos_Aires":"Amérique/Argentine/Buenos_Aires",
"America/Argentina/Catamarca":"Amérique/Argentine/Catamarca",
"America/Argentina/ComodRivadavia":"Amérique/Argentine/Comodrivadavia",
"America/Argentina/Cordoba":"Amérique/Argentine/Cordoba",
"America/Argentina/Jujuy":"Amérique/Argentine/Jujuy",
"America/Argentina/La_Rioja":"Amérique/Argentine/La_Rioja",
"America/Argentina/Mendoza":"Amérique/Argentine/Mendoza",
"America/Argentina/Rio_Gallegos":"Amérique/Argentine/Rio_Gallegos",
"America/Argentina/Salta":"Amérique/Argentine/Salta",
"America/Argentina/San_Juan":"Amérique/Argentine/San_Juan",
"America/Argentina/San_Luis":"Amérique/Argentine/San_Luis",
"America/Argentina/Tucuman":"Amérique/Argentine/Tucuman",
"America/Argentina/Ushuaia":"Amérique/Argentine/Ushuaia",
"America/Aruba":"Amérique/Aruba",
"America/Asuncion":"Amérique/Asuncion",
"America/Atikokan":"Amérique/Atikokan",
"America/Atka":"Amérique/Atka",
"America/Bahia":"Amérique/Bahia",
"America/Bahia_Banderas":"Amérique/Bahia_Banderas",
"America/Barbados":"Amérique/Barbade",
"America/Belem":"Amérique/Belém",
"America/Belize":"Amérique/Belize",
"America/Blanc-Sablon":"Amérique/Blanc-Sablon",
"America/Boa_Vista":"Amérique/Boa_Vista",
"America/Bogota":"Amérique/Bogota",
"America/Boise":"Amérique/Boise",
"America/Buenos_Aires":"Amérique/Buenos_Aires",
"America/Cambridge_Bay":"Amérique/Cambridge_Bay",
"America/Campo_Grande":"Amérique/Campo_Grande",
"America/Cancun":"Amérique/Cancun",
"America/Caracas":"Amérique/Caracas",
"America/Catamarca":"Amérique/Catamarca",
"America/Cayenne":"Amérique/Cayenne",
"America/Cayman":"Amérique/Caïmans",
"America/Chicago":"Amérique/Chicago",
"America/Chihuahua":"Amérique/Chihuahua",
"America/Ciudad_Juarez":"Amérique/Ciudad_Juarez",
"America/Coral_Harbour":"Amérique/Coral_Harbour",
"America/Cordoba":"Amérique/Cordoba",
"America/Costa_Rica":"Amérique/Costa Rica",
"America/Creston":"Amérique/Creston",
"America/Cuiaba":"Amérique/Cuiaba",
"America/Curacao":"Amérique/Curaçao",
"America/Danmarkshavn":"Amérique/Danemark",
"America/Dawson":"Amérique/Dawson",
"America/Dawson_Creek":"Amérique/Dawson_Creek",
"America/Denver":"Amérique/Denver",
"America/Detroit":"Amérique/Detroit",
"America/Dominica":"Amérique/Dominique",
"America/Edmonton":"Amérique/Edmonton",
"America/Eirunepe":"Amérique/Eirunepe",
"America/El_Salvador":"Amérique/El Salvador",
"America/Ensenada":"Amérique/Endenada",
"America/Fort_Nelson":"Amérique/Fort_Nelson",
"America/Fort_Wayne":"Amérique/Fort_Wayne",
"America/Fortaleza":"Amérique/Fortaleza",
"America/Glace_Bay":"Amérique/Glace_Bay",
"America/Godthab":"Amérique/Godthab",
"America/Goose_Bay":"Amérique/Goose_Bay",
"America/Grand_Turk":"Amérique/Grand_Turk",
"America/Grenada":"Amérique/Grenade",
"America/Guadeloupe":"Amérique/Guadeloupe",
"America/Guatemala":"Amérique/Guatemala",
"America/Guayaquil":"Amérique/Guayaquil",
"America/Guyana":"Amérique/Guyana",
"America/Halifax":"Amérique/Halifax",
"America/Havana":"Amérique/La Havane",
"America/Hermosillo":"Amérique/Hermosillo",
"America/Indiana/Indianapolis":"Amérique/Indiana/Indianapolis",
"America/Indiana/Knox":"Amérique/Indiana/Knox",
"America/Indiana/Marengo":"Amérique/Indiana/Marengo",
"America/Indiana/Petersburg":"Amérique/Indiana/Petersburg",
"America/Indiana/Tell_City":"Amérique/Indiana/Tell_City",
"America/Indiana/Vevay":"Amérique/Indiana/Vevay",
"America/Indiana/Vincennes":"Amérique/Indiana/Vincennes",
"America/Indiana/Winamac":"Amérique/Indiana/Winamac",
"America/Indianapolis":"Amérique/Indianapolis",
"America/Inuvik":"Amérique/Inuvik",
"America/Iqaluit":"Amérique/Iqaluit",
"America/Jamaica":"Amérique/Jamaïque",
"America/Jujuy":"Amérique/Jujuy",
"America/Juneau":"Amérique/Juneau",
"America/Kentucky/Louisville":"Amérique/Kentucky/Louisville",
"America/Kentucky/Monticello":"Amérique/Kentucky/Monticello",
"America/Knox_IN":"America/Knox_in",
"America/Kralendijk":"Amérique/Kralendijk",
"America/La_Paz":"Amérique/La_Paz",
"America/Lima":"Amérique/Lima",
"America/Los_Angeles":"Amérique/Los Angeles",
"America/Louisville":"Amérique/Louisville",
"America/Lower_Princes":"Amérique/Lower_Princes",
"America/Maceio":"Amérique/Maceio",
"America/Managua":"Amérique/Managua",
"America/Manaus":"Amérique/Manaus",
"America/Marigot":"Amérique/Marigot",
"America/Martinique":"Amérique/Martinique",
"America/Matamoros":"Amérique/Matamoros",
"America/Mazatlan":"Amérique/Mazatlan",
"America/Mendoza":"Amérique/Mendoza",
"America/Menominee":"Amérique/Menominee",
"America/Merida":"Amérique/Mérida",
"America/Metlakatla":"Amérique/Metlakatla",
"America/Mexico_City":"America/Mexico_City",
"America/Miquelon":"Amérique/Miquelon",
"America/Moncton":"Amérique/Moncton",
"America/Monterrey":"Amérique/Monterrey",
"America/Montevideo":"Amérique/Montevideo",
"America/Montreal":"Amérique/Montréal",
"America/Montserrat":"Amérique/Montserrat",
"America/Nassau":"Amérique/Nassau",
"America/New_York":"Amérique/New York",
"America/Nipigon":"Amérique/Nipigon",
"America/Nome":"Amérique/Nom",
"America/Noronha":"Amérique/Noronha",
"America/North_Dakota/Beulah":"Amérique/Dakota du Nord/Beulah",
"America/North_Dakota/Center":"Amérique/Dakota du Nord/Centre",
"America/North_Dakota/New_Salem":"Amérique/Dakota du Nord/New_Salem",
"America/Nuuk":"Amérique/Nuuk",
"America/Ojinaga":"Amérique/Ojinaga",
"America/Panama":"Amérique/Panama",
"America/Pangnirtung":"Amérique/Pangnirtung",
"America/Paramaribo":"Amérique/Paramaribo",
"America/Phoenix":"Amérique/Phénix",
"America/Port-au-Prince":"Amérique/Port-au-Prince",
"America/Port_of_Spain":"Amérique/Port_d'Espagne",
"America/Porto_Acre":"Amérique/Porto_Acre",
"America/Porto_Velho":"Amérique/Porto_Velho",
"America/Puerto_Rico":"Amérique/Porto Rico",
"America/Punta_Arenas":"Amérique/Punta_Arenas",
"America/Rainy_River":"Amérique/Rainy_River",
"America/Rankin_Inlet":"America/Rankin_Inlet",
"America/Recife":"Amérique/Recife",
"America/Regina":"Amérique/Regina",
"America/Resolute":"Amérique/Resolute",
"America/Rio_Branco":"Amérique/Rio_Branco",
"America/Rosario":"Amérique/Rosario",
"America/Santa_Isabel":"Amérique/Santa Isabel",
"America/Santarem":"Amérique/Santarem",
"America/Santiago":"Amérique/Santiago",
"America/Santo_Domingo":"Amérique/Santo_Domingo",
"America/Sao_Paulo":"Amérique/Sao Paulo",
"America/Scoresbysund":"Amérique/Scoresbysund",
"America/Shiprock":"Amérique/Shiprock",
"America/Sitka":"Amérique/Sitka",
"America/St_Barthelemy":"Amérique/St_Barthélemy",
"America/St_Johns":"Amérique/St_Johns",
"America/St_Kitts":"Amérique/Saint-Kitts",
"America/St_Lucia":"Amérique/Sainte-Lucie",
"America/St_Thomas":"Amérique/St_Thomas",
"America/St_Vincent":"Amérique/Saint-Vincent",
"America/Swift_Current":"Amérique/Swift_Current",
"America/Tegucigalpa":"Amérique/Tegucigalpa",
"America/Thule":"Amérique/Thulé",
"America/Thunder_Bay":"Amérique/Thunder_Bay",
"America/Tijuana":"Amérique/Tijuana",
"America/Toronto":"Amérique/Toronto",
"America/Tortola":"Amérique/Tortola",
"America/Vancouver":"Amérique/Vancouver",
"America/Virgin":"Amérique/Vierge",
"America/Whitehorse":"Amérique/Whitehorse",
"America/Winnipeg":"Amérique/Winnipeg",
"America/Yakutat":"Amérique/Yakutat",
"America/Yellowknife":"Amérique/Yellowknife",
"Antarctica/Casey":"Antarctique/Casey",
"Antarctica/Davis":"Antarctique/Davis",
"Antarctica/DumontDUrville":"Antarctique/Dumontdurville",
"Antarctica/Macquarie":"Antarctique/Macquarie",
"Antarctica/Mawson":"Antarctique/Mawson",
"Antarctica/McMurdo":"Antarctique/McMurdo",
"Antarctica/Palmer":"Antarctique/Palmer",
"Antarctica/Rothera":"Antarctique/Rothera",
"Antarctica/South_Pole":"Antarctique/Pôle Sud",
"Antarctica/Syowa":"Antarctique/Syowa",
"Antarctica/Troll":"Antarctique/Troll",
"Antarctica/Vostok":"Antarctique/Vostok",
"Arctic/Longyearbyen":"Arctique/Longyearbyen",
"Asia/Aden":"Asie/Aden",
"Asia/Almaty":"Asie/Almaty",
"Asia/Amman":"Asie/Amman",
"Asia/Anadyr":"Asie/Anadyr",
"Asia/Aqtau":"Asie/Aqtau",
"Asia/Aqtobe":"Asie/Aqtobe",
"Asia/Ashgabat":"Asie/Achgabat",
"Asia/Ashkhabad":"Asie/Ashkhabad",
"Asia/Atyrau":"Asie/Atyrau",
"Asia/Baghdad":"Asie/Bagdad",
"Asia/Bahrain":"Asie/Bahreïn",
"Asia/Baku":"Asie/Bakou",
"Asia/Bangkok":"Asie/Bangkok",
"Asia/Barnaul":"Asie/Barnaul",
"Asia/Beirut":"Asie/Beyrouth",
"Asia/Bishkek":"Asie/Bichkek",
"Asia/Brunei":"Asie/Brunei",
"Asia/Calcutta":"Asie/Calcutta",
"Asia/Chita":"Asie/Tchita",
"Asia/Choibalsan":"Asie/Choibalsan",
"Asia/Chongqing":"Asie/Chongqing",
"Asia/Chungking":"Asie/Chungking",
"Asia/Colombo":"Asie/Colombo",
"Asia/Dacca":"Asie/Dacca",
"Asia/Damascus":"Asie/Damas",
"Asia/Dhaka":"Asie/Dhaka",
"Asia/Dili":"Asie/Dili",
"Asia/Dubai":"Asie/Dubaï",
"Asia/Dushanbe":"Asie/Douchanbé",
"Asia/Famagusta":"Asie/Famagusta",
"Asia/Gaza":"Asie/Gaza",
"Asia/Harbin":"Asie/Harbin",
"Asia/Hebron":"Asie/Hebron",
"Asia/Ho_Chi_Minh":"Asie/Ho_Chi_Minh",
"Asia/Hong_Kong":"Asie/Hong_Kong",
"Asia/Hovd":"Asie/Hovd",
"Asia/Irkutsk":"Asie/Irkoutsk",
"Asia/Istanbul":"Asie/Istanbul",
"Asia/Jakarta":"Asie/Jakarta",
"Asia/Jayapura":"Asie/Jayapura",
"Asia/Jerusalem":"Asie/Jérusalem",
"Asia/Kabul":"Asie/Kaboul",
"Asia/Kamchatka":"Asie/Kamtchatka",
"Asia/Karachi":"Asie/Karachi",
"Asia/Kashgar":"Asie/Kashgar",
"Asia/Kathmandu":"Asie/Katmandou",
"Asia/Katmandu":"Asie/Katmandu",
"Asia/Khandyga":"Asie/Khandyga",
"Asia/Kolkata":"Asie/Kolkata",
"Asia/Krasnoyarsk":"Asie/Krasnoïarsk",
"Asia/Kuala_Lumpur":"Asie/Kuala_Lumpur",
"Asia/Kuching":"Asie/Kuching",
"Asia/Kuwait":"Asie/Koweït",
"Asia/Macao":"Asie/Macao",
"Asia/Macau":"Asie/Macau",
"Asia/Magadan":"Asie/Magadan",
"Asia/Makassar":"Asie/Makassar",
"Asia/Manila":"Asie/Manille",
"Asia/Muscat":"Asie/Mascate",
"Asia/Nicosia":"Asie/Nicosie",
"Asia/Novokuznetsk":"Asie/Novokuznetsk",
"Asia/Novosibirsk":"Asie/Novossibirsk",
"Asia/Omsk":"Asie/Omsk",
"Asia/Oral":"Asie/Oral",
"Asia/Phnom_Penh":"Asie/Phnom_Penh",
"Asia/Pontianak":"Asie/Pontianak",
"Asia/Pyongyang":"Asie/Pyongyang",
"Asia/Qatar":"Asie/Qatar",
"Asia/Qostanay":"Asie/Qostanay",
"Asia/Qyzylorda":"Asie/Qyzylorda",
"Asia/Rangoon":"Asie/Rangoon",
"Asia/Riyadh":"Asie/Riyad",
"Asia/Saigon":"Asie/Saigon",
"Asia/Sakhalin":"Asie/Sakhaline",
"Asia/Samarkand":"Asie/Samarkand",
"Asia/Seoul":"Asie/Séoul",
"Asia/Shanghai":"Asie/Shanghai",
"Asia/Singapore":"Asie/Singapour",
"Asia/Srednekolymsk":"Asie/Srednekolymsk",
"Asia/Taipei":"Asie/Taipei",
"Asia/Tashkent":"Asie/Tachkent",
"Asia/Tbilisi":"Asie/Tbilissi",
"Asia/Tehran":"Asie/Téhéran",
"Asia/Tel_Aviv":"Asie/Tel Aviv",
"Asia/Thimbu":"Asie/Thimbu",
"Asia/Thimphu":"Asie/Thimphu",
"Asia/Tokyo":"Asie/Tokyo",
"Asia/Tomsk":"Asie/Tomsk",
"Asia/Ujung_Pandang":"Asie/Ujung_Pandang",
"Asia/Ulaanbaatar":"Asie/Oulan-Bator",
"Asia/Ulan_Bator":"Asie/Oulan_Bator",
"Asia/Urumqi":"Asie/Urumqi",
"Asia/Ust-Nera":"Asie/Oust-Nera",
"Asia/Vientiane":"Asie/Vientiane",
"Asia/Vladivostok":"Asie/Vladivostok",
"Asia/Yakutsk":"Asie/Iakoutsk",
"Asia/Yangon":"Asie/Yangon",
"Asia/Yekaterinburg":"Asie/Iekaterinbourg",
"Asia/Yerevan":"Asie/Erevan",
"Atlantic/Azores":"Atlantique/Açores",
"Atlantic/Bermuda":"Atlantique/Bermudes",
"Atlantic/Canary":"Atlantique/Canaries",
"Atlantic/Cape_Verde":"Atlantique/Cap-Vert",
"Atlantic/Faeroe":"Atlantique/Iles Féroé",
"Atlantic/Faroe":"Atlantique/Féroé",
"Atlantic/Jan_Mayen":"Atlantique/Jan_Mayen",
"Atlantic/Madeira":"Atlantique/Madère",
"Atlantic/Reykjavik":"Atlantique/Reykjavik",
"Atlantic/South_Georgia":"Atlantique/Géorgie du Sud",
"Atlantic/St_Helena":"Atlantique/Sainte-Hélène",
"Atlantic/Stanley":"Atlantique/Stanley",
"Australia/ACT":"Australie/ACT",
"Australia/Adelaide":"Australie/Adélaïde",
"Australia/Brisbane":"Australie/Brisbane",
"Australia/Broken_Hill":"Australie/Broken_Hill",
"Australia/Canberra":"Australie/Canberra",
"Australia/Currie":"Australie/Currie",
"Australia/Darwin":"Australie/Darwin",
"Australia/Eucla":"Australie/Eucla",
"Australia/Hobart":"Australie/Hobart",
"Australia/LHI":"Australie/LHI",
"Australia/Lindeman":"Australie/Lindeman",
"Australia/Lord_Howe":"Australie/Lord_Howe",
"Australia/Melbourne":"Australie/Melbourne",
"Australia/NSW":"Australie/Nouvelle-Galles du Sud",
"Australia/North":"Australie/Nord",
"Australia/Perth":"Australie/Perth",
"Australia/Queensland":"Australie/Queensland",
"Australia/South":"Australie/Sud",
"Australia/Sydney":"Australie/Sydney",
"Australia/Tasmania":"Australie/Tasmanie",
"Australia/Victoria":"Australie/Victoria",
"Australia/West":"Australie/Ouest",
"Australia/Yancowinna":"Australie/Yancowinna",
"Brazil/Acre":"Brésil/Acre",
"Brazil/DeNoronha":"Brésil/Denoronha",
"Brazil/East":"Brésil/Est",
"Brazil/West":"Brésil/Ouest",
"CET":"CET",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Canada/Atlantique",
"Canada/Central":"Canada/Centre",
"Canada/Eastern":"Canada/Est",
"Canada/Mountain":"Canada/Montagne",
"Canada/Newfoundland":"Canada/Terre-Neuve",
"Canada/Pacific":"Canada/Pacifique",
"Canada/Saskatchewan":"Canada/Saskatchewan",
"Canada/Yukon":"Canada/Yukon",
"Chile/Continental":"Chili/Continental",
"Chile/EasterIsland":"Chili/Île de Pâque",
"Cuba":"Cuba",
"EET":"JE TE RENCONTRE",
"EST":"MEILLEUR",
"EST5EDT":"EST5EDT",
"Egypt":"Égypte",
"Eire":"Fire",
"Etc/GMT":"ETC/GMT",
"Etc/GMT+0":"ETC/GMT+0",
"Etc/GMT+1":"ETC/GMT+1",
"Etc/GMT+10":"ETC/GMT+10",
"Etc/GMT+11":"ETC/GMT+11",
"Etc/GMT+12":"ETC/GMT+12",
"Etc/GMT+2":"ETC/GMT+2",
"Etc/GMT+3":"ETC/GMT+3",
"Etc/GMT+4":"ETC/GMT+4",
"Etc/GMT+5":"ETC/GMT+5",
"Etc/GMT+6":"ETC/GMT+6",
"Etc/GMT+7":"ETC/GMT+7",
"Etc/GMT+8":"ETC/GMT+8",
"Etc/GMT+9":"ETC/GMT+9",
"Etc/GMT-0":"ETC/GMT-0",
"Etc/GMT-1":"ETC/GMT-1",
"Etc/GMT-10":"ETC/GMT-10",
"Etc/GMT-11":"ETC/GMT-11",
"Etc/GMT-12":"ETC/GMT-12",
"Etc/GMT-13":"ETC/GMT-13",
"Etc/GMT-14":"ETC/GMT-14",
"Etc/GMT-2":"ETC/GMT-2",
"Etc/GMT-3":"ETC/GMT-3",
"Etc/GMT-4":"ETC/GMT-4",
"Etc/GMT-5":"ETC/GMT-5",
"Etc/GMT-6":"ETC/GMT-6",
"Etc/GMT-7":"ETC/GMT-7",
"Etc/GMT-8":"ETC/GMT-8",
"Etc/GMT-9":"ETC/GMT-9",
"Etc/GMT0":"ETC/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"ETC/UCT",
"Etc/UTC":"ETC/UTC",
"Etc/Universal":"Etc/Universel",
"Etc/Zulu":"Etc/Zoulou",
"Europe/Amsterdam":"Europe/Amsterdam",
"Europe/Andorra":"Europe/Andorre",
"Europe/Astrakhan":"Europe/Astrakhan",
"Europe/Athens":"Europe/Athènes",
"Europe/Belfast":"Europe/Belfast",
"Europe/Belgrade":"Europe/Belgrade",
"Europe/Berlin":"Europe/Berlin",
"Europe/Bratislava":"Europe/Bratislava",
"Europe/Brussels":"Europe/Bruxelles",
"Europe/Bucharest":"Europe/Bucarest",
"Europe/Budapest":"Europe/Budapest",
"Europe/Busingen":"Europe/Busingen",
"Europe/Chisinau":"Europe/Chisinau",
"Europe/Copenhagen":"Europe/Copenhague",
"Europe/Dublin":"Europe/Dublin",
"Europe/Gibraltar":"Europe/Gibraltar",
"Europe/Guernsey":"Europe/Guernesey",
"Europe/Helsinki":"Europe/Helsinki",
"Europe/Isle_of_Man":"Europe/Ile de Man",
"Europe/Istanbul":"Europe/Istanbul",
"Europe/Jersey":"Europe/Jersey",
"Europe/Kaliningrad":"Europe/Kaliningrad",
"Europe/Kiev":"Europe/Kiev",
"Europe/Kirov":"Europe/Kirov",
"Europe/Kyiv":"Europe/Kiev",
"Europe/Lisbon":"Europe/Lisbonne",
"Europe/Ljubljana":"Europe/Ljubljana",
"Europe/London":"Europe/Londres",
"Europe/Luxembourg":"Europe/Luxembourg",
"Europe/Madrid":"Europe/Madrid",
"Europe/Malta":"Europe/Malte",
"Europe/Mariehamn":"Europe/Mariehamn",
"Europe/Minsk":"Europe/Minsk",
"Europe/Monaco":"Europe/Monaco",
"Europe/Moscow":"Europe/Moscou",
"Europe/Nicosia":"Europe/Nicosie",
"Europe/Oslo":"Europe/Oslo",
"Europe/Paris":"Europe/Paris",
"Europe/Podgorica":"Europe/Podgorica",
"Europe/Prague":"Europe/Prague",
"Europe/Riga":"Europe/Riga",
"Europe/Rome":"Europe/Rome",
"Europe/Samara":"Europe/Samara",
"Europe/San_Marino":"Europe/Saint-Marin",
"Europe/Sarajevo":"Europe/Sarajevo",
"Europe/Saratov":"Europe/Saratov",
"Europe/Simferopol":"Europe/Simferopol",
"Europe/Skopje":"Europe/Skopje",
"Europe/Sofia":"Europe/Sofia",
"Europe/Stockholm":"Europe/Stockholm",
"Europe/Tallinn":"Europe/Tallinn",
"Europe/Tirane":"Europe/Tirane",
"Europe/Tiraspol":"Europe/Tiraspol",
"Europe/Ulyanovsk":"Europe/Oulianovsk",
"Europe/Uzhgorod":"Europe/Uzhgorod",
"Europe/Vaduz":"Europe/Vaduz",
"Europe/Vatican":"Europe/Vatican",
"Europe/Vienna":"Europe/Vienne",
"Europe/Vilnius":"Europe/Vilnius",
"Europe/Volgograd":"Europe/Volgograd",
"Europe/Warsaw":"Europe/Varsovie",
"Europe/Zagreb":"Europe/Zagreb",
"Europe/Zaporozhye":"Europe/Zaporozhye",
"Europe/Zurich":"Europe/Zurich",
"GB":"GO",
"GB-Eire":"GB-Wire",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HÔTE",
"Hongkong":"Hong Kong",
"Iceland":"Islande",
"Indian/Antananarivo":"Indien/Antananarivo",
"Indian/Chagos":"Indien/Chagos",
"Indian/Christmas":"Indien/Noël",
"Indian/Cocos":"Indien/Cocos",
"Indian/Comoro":"Indien/Comores",
"Indian/Kerguelen":"Indien/Kerguelen",
"Indian/Mahe":"Indien/Mahé",
"Indian/Maldives":"Indien/Maldives",
"Indian/Mauritius":"Indien/Maurice",
"Indian/Mayotte":"Indien/Mayotte",
"Indian/Reunion":"Indien/Réunion",
"Iran":"Iran",
"Israel":"Israël",
"Jamaica":"Jamaïque",
"Japan":"Japon",
"Kwajalein":"Kwajalein",
"Libya":"Libye",
"MET":"RENCONTRÉ",
"MST":"LE PLUS",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"Mexique/Bajanorte",
"Mexico/BajaSur":"Mexique/Bajasur",
"Mexico/General":"Mexique/Général",
"NZ":"NZ",
"NZ-CHAT":"PAS DE CHAT",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Pacifique/Apia",
"Pacific/Auckland":"Pacifique/Auckland",
"Pacific/Bougainville":"Pacifique/Bougainville",
"Pacific/Chatham":"Pacifique/Chatham",
"Pacific/Chuuk":"Pacifique/Chuuk",
"Pacific/Easter":"Pacifique/Pâques",
"Pacific/Efate":"Pacifique/Efate",
"Pacific/Enderbury":"Pacifique/Enderbury",
"Pacific/Fakaofo":"Pacifique/Fakaofo",
"Pacific/Fiji":"Pacifique/Fidji",
"Pacific/Funafuti":"Pacifique/Funafuti",
"Pacific/Galapagos":"Pacifique/Galapagos",
"Pacific/Gambier":"Pacifique/Gambier",
"Pacific/Guadalcanal":"Pacifique/Guadalcanal",
"Pacific/Guam":"Pacifique/Guam",
"Pacific/Honolulu":"Pacifique/Honolulu",
"Pacific/Johnston":"Pacifique/Johnston",
"Pacific/Kanton":"Pacifique/Canton",
"Pacific/Kiritimati":"Pacifique/Kiritimati",
"Pacific/Kosrae":"Pacifique/Kosrae",
"Pacific/Kwajalein":"Pacifique/Kwajalein",
"Pacific/Majuro":"Pacifique/Majuro",
"Pacific/Marquesas":"Pacifique/Marquise",
"Pacific/Midway":"Pacifique/Midway",
"Pacific/Nauru":"Pacifique/Nauru",
"Pacific/Niue":"Pacifique/Niue",
"Pacific/Norfolk":"Pacifique/Norfolk",
"Pacific/Noumea":"Pacifique/Nouméa",
"Pacific/Pago_Pago":"Pacifique/Pago_Pago",
"Pacific/Palau":"Pacifique/Palaos",
"Pacific/Pitcairn":"Pacifique/Pitcairn",
"Pacific/Pohnpei":"Pacifique/Pohnpei",
"Pacific/Ponape":"Pacifique/Ponape",
"Pacific/Port_Moresby":"Pacifique/Port_Moresby",
"Pacific/Rarotonga":"Pacifique/Rarotonga",
"Pacific/Saipan":"Pacifique/Saipan",
"Pacific/Samoa":"Pacifique/Samoa",
"Pacific/Tahiti":"Pacifique/Tahiti",
"Pacific/Tarawa":"Pacifique/Tarawa",
"Pacific/Tongatapu":"Pacifique/Tongatapu",
"Pacific/Truk":"Pacifique/Truck",
"Pacific/Wake":"Pacifique/Wake",
"Pacific/Wallis":"Pacifique/Wallis",
"Pacific/Yap":"Pacifique/Yap",
"Poland":"Pologne",
"Portugal":"Portugal",
"ROC":"ROC",
"ROK":"ROK",
"Singapore":"Singapour",
"Turkey":"Turquie",
"UCT":"DÉDUCTION",
"US/Alaska":"États-Unis/Alaska",
"US/Aleutian":"États-Unis/Aléoutiennes",
"US/Arizona":"États-Unis/Arizona",
"US/Central":"États-Unis/Centre",
"US/East-Indiana":"États-Unis/Est-Indiana",
"US/Eastern":"États-Unis/Est",
"US/Hawaii":"États-Unis/Hawaï",
"US/Indiana-Starke":"États-Unis/Indiana-Starke",
"US/Michigan":"États-Unis/Michigan",
"US/Mountain":"États-Unis/Montagne",
"US/Pacific":"États-Unis/Pacifique",
"US/Samoa":"États-Unis/Samoa",
"UTC":"UTC",
"Universal":"Universel",
"W-SU":"NOUS",
"WET":"HUMIDE",
"Zulu":"Zoulou",
}