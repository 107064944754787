import _ from 'lodash'
import moment from 'moment'
import { frFieldTypes, validateFieldRule } from '../../constants/fieldRule'
import FieldTypes from '../../constants/fieldTypes'
import { removePercentageFromValue } from '../../utils/util'

const checkFieldRule = (currentField) => {
  const fr = {
    satisfiedFieldRuleIndex: null,
  }
  currentField.fieldRules.every((fieldRule) => {
    const fieldType = frFieldTypes[`${currentField.fieldType}`]
    if (fieldType === frFieldTypes.date && currentField?.crfData?.fieldValue) {
      const dateValue = getFormattedValue(currentField)
      const field = {
        ...currentField,
        crfData: {
          ...currentField.crfData,
          fieldValue: dateValue,
        },
      }
      if (validateFieldRule[`${fieldType}`][`${fieldRule.operator}`](field, fieldRule)) {
        fr.satisfiedFieldRuleIndex = currentField.fieldRules.indexOf(fieldRule)
        return false
      }
    }
    if (validateFieldRule[`${fieldType}`][`${fieldRule.operator}`](currentField, fieldRule)) {
      fr.satisfiedFieldRuleIndex = currentField.fieldRules.indexOf(fieldRule)
      return false
    }
    return true
  })
  return fr.satisfiedFieldRuleIndex
}

const getNextField = (fieldList, currentField, satisfiedFrIndex) => {
  const nextField = _.find(
    fieldList,
    (field) =>
      field.fieldOid === currentField.fieldRules[satisfiedFrIndex].destFieldOid &&
      field.ordinal > currentField.ordinal
  )
  return nextField ? nextField.ordinal : null
}

export const fetchNextFieldByFieldRule = (selectedField, fieldList) => {
  const fieldValue = (selectedField?.fieldType === FieldTypes.NRS || selectedField?.fieldType === FieldTypes.VAS) ? removePercentageFromValue(selectedField?.crfData?.fieldValue) : selectedField?.crfData?.fieldValue
  selectedField.crfData.fieldValue = fieldValue
  const satisfiedFieldRuleIndex = checkFieldRule(selectedField)
  if (satisfiedFieldRuleIndex !== null) {
    const nextOrdinal = getNextField(fieldList, selectedField, satisfiedFieldRuleIndex)
    return nextOrdinal
  }

  return null
}

export const getFormattedValue = (currentField) => {
  const value = currentField.crfData.fieldValue
  switch (currentField.fieldType) {
    case FieldTypes.DATE:
      return moment(value, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
    case FieldTypes.DATETIME12:
      return moment(value, 'DD-MMM-YYYY h:mm A').format('DD-MMM-YYYY h:mm A')
    case FieldTypes.DATETIME24:
      return moment(value, 'DD-MMM-YYYY HH:mm').format('DD-MMM-YYYY HH:mm')
    default:
      return moment(value).format('DD-MMM-YYYY')
  }
}
