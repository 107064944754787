import React from 'react'
import { View, Text } from 'react-native'
import { backgroundColor } from '../../../../containers/NavigationScreens'
import _ from 'lodash'

const StudySiteInfo = (props) => {
  const { study, subject, site, versionInfo } = props

  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        marginBottom: 10,
      }}
    >
      <View style={{ flexDirection: 'row', marginLeft: 5 }}>
        <Text
          style={{
            fontSize: 16,
            color: 'rgba(58,53,65,.68)',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        >
          Study: {' ' + study.studyNumber}
        </Text>
      </View>
      {subject && (
        <View>
          <View style={{ flexDirection: 'row', marginTop: 4, marginLeft: 5 }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                color: 'rgba(58,53,65,.68)',
                fontWeight: '500',
              }}
            >
              Site: {' ' + site.organizationName}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 4, marginLeft: 5 }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                color: 'rgba(58,53,65,.68)',
                fontWeight: '500',
              }}
            >
              Subject: {' ' + subject.subjectNo}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 4, marginLeft: 5 }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                color: 'rgba(58,53,65,.68)',
                fontWeight: '500',
              }}
            >
              Version: {!_.isEmpty(versionInfo) ? ' ' + versionInfo.versionNumber : ''}
            </Text>
          </View>
        </View>
      )}
    </View>
  )
}

export default StudySiteInfo
