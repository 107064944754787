import WebDatePicker from './webDatePicker/WebDatePicker'
import React from 'react'
import { View } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { TouchableOpacity, Platform } from 'react-native'
import MobileDiaryDatePicker from './webDatePicker/MobileDiaryDatePicker'
import moment from 'moment'

const DatePickerLayout = (props) => {
  const { onDateChange, initialDiaryDate, t, subjectTimezone, testID } = props
  const getPreviousDate = () => {
    const previousDate = moment.utc(initialDiaryDate).subtract(1, 'days')
    onDateChange(previousDate)
  }

  const getNextDate = () => {
    const nextDate = moment.utc(initialDiaryDate).add(1, 'days')
    onDateChange(nextDate)
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'center',
        backgroundColor: '#CFD8DC',
        borderColor: '#CFD8DC',
        borderRadius: 5,
        paddingBottom: Platform.OS === 'web' ? 5 : 4,
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: 'Inter',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View>
        <TouchableOpacity
          onPress={() => getPreviousDate()}
          testID={`datePicker-previous-click-btn`}
          accessible={true}
        >
          <AntDesign name="doubleleft" size={14} color="#424242" />
        </TouchableOpacity>
      </View>
      <View>
        {Platform.OS === 'web' ? (
          <WebDatePicker
            onDateChange={(date) => onDateChange(date)}
            date={initialDiaryDate}
            testID={testID}
          />
        ) : (
          <MobileDiaryDatePicker
            onDateChange={(date) => onDateChange(date)}
            selectedDate={initialDiaryDate}
            t={t}
            subjectTimezone={subjectTimezone}
            testID={testID}
          />
        )}
      </View>
      <View>
        <TouchableOpacity
          onPress={() => getNextDate()}
          testID={`datepicker-next-click`}
          accessible={true}
        >
          <AntDesign name="doubleright" size={14} color="#424242" />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default DatePickerLayout
