import React, { Component } from 'react'
import StudyList from '../components/study/list'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { retrieveStudies, updateSelectedStudy, updateStudyInStore } from '../actions/study'
import { getStudiesOfUser } from '../selectors/study'
import { NavigationEvents } from 'react-navigation'
import { updateSubjectInStore } from '../actions/subject'

class StudyListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.getStudies()
  }

  getStudies = async () => {
    const { retrieveStudies: retrieveStudiesOfSite, siteId } = this.props
    try {
      await retrieveStudiesOfSite(siteId)
      // await retrieveStudiesOfSite('C2A6F80A-D6FB-4C7F-A3FD-3AFE454CF1A6')
    } catch (error) {
      console.log(error)
    }
  }

  clearStudy = async () => {
    const { updateSelectedStudy: updateSelectedStudyInStore } = this.props
    updateSelectedStudyInStore(null)
    updateSubjectInStore(null)
  }

  onStudySelect = (study) => {
    const { updateSelectedStudy: updateSelectedStudyInStore, navigation } = this.props
    updateSelectedStudyInStore(study)
    navigation.navigate('SubjectListPage')
  }

  render() {
    const { list, loading } = this.props
    return (
      <View style={{ flex: 1 }}>
        <NavigationEvents
          onDidFocus={() => {
            this.clearStudy()
          }}
        />
        <StudyList list={list} onStudySelect={this.onStudySelect} loading={loading} />
      </View>
    )
  }
}

StudyListPage.defaultProps = {
  list: [],
  siteId: '',
}

StudyListPage.propTypes = {
  list: PropTypes.arrayOf(Object),
  siteId: PropTypes.string,
}

const mapStateToProps = (state) => ({
  siteId: state.site.id,
  list: getStudiesOfUser(state),
  loading: state.study.loading,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveStudies,
      updateSelectedStudy,
      updateStudyInStore,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(StudyListPage)
