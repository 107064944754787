import { PUSH_FORM_DATA_TO_QUEUE, processQueue } from '../actions/syncQueue'
import { STORE_APP_STATUS, updateDownloadImageTimerId, updateSyncQueueTimer } from '../actions/storeAppStatus'
import { getDeviceStatus } from '../actions/users'
import _ from 'lodash'
import { startBackGroundJob } from '../utils/backgroundTask/downloadImageUtil'
import { SAVE_FIELD_LIST_OF_MULTIPLE_FORM } from '../actions/field'
import { Platform } from 'react-native'
import { GET_META_DATA_SUCCESS, UPDATE_IMAGE_CODES, getClickableImagesConfigured } from '../actions/metaData'
import Constants from 'expo-constants'
import constants from '../constants/constants'

export const loggerMiddleware = (store) => (next) => (action) => {
  console.info('dispatching', action.type)
  let result = next(action)
  if (
    action.type === PUSH_FORM_DATA_TO_QUEUE && getDeviceStatus()
  ) {
     store.dispatch(processQueue())
  }
  if (
    action.type === STORE_APP_STATUS && 
    !_.isEmpty(store.getState().syncQueue.data) && getDeviceStatus()){
    var queueId = setInterval(() => {
      store.dispatch(processQueue())
    }, 30000);
    store.dispatch(updateSyncQueueTimer(queueId))
  }
  
  if(action.type === SAVE_FIELD_LIST_OF_MULTIPLE_FORM || (_.isEqual(store.getState()?.appStatus?.appType, constants.AppType.SITESTAFF) && action.type === GET_META_DATA_SUCCESS)){
    store.dispatch(getClickableImagesConfigured())
  }
  
  
  if(action.type === UPDATE_IMAGE_CODES){
    store.dispatch(startBackGroundJob())
    if(Platform.OS == 'web'){
      var queueId = setInterval(() => {
        store.dispatch(startBackGroundJob())
      }, Constants.expoConfig.extra.downloadImageTimer); //5min
      store.dispatch(updateDownloadImageTimerId(queueId))
    }
  }

  return result
}
