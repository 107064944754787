import { SELECT_VISIT_FORM } from '../actions/visitForm'

import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

const visitForm = (state = initialState.visitForm, action) => {
  switch (action.type) {
    case SELECT_VISIT_FORM:
      return {
        ...state,
        selectedVisitFormOid: action.visitFormOid,
      }
    case PURGE:
      return {
        selectedVisitFormOid: '',
      }
    default:
      return state
  }
}

export default visitForm
