import { View, Text } from 'react-native'
import React from 'react'

export default function Result() {
  return (
    <View>
      <Text>Result</Text>
    </View>
  )
}
