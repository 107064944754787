import { MaterialIcons } from '@expo/vector-icons'
import _ from 'lodash'
import WebModal from 'modal-react-native-web'
import { Body, Button, Container, Content, Header, Left, Right, Title } from 'native-base'
import React, { Component } from 'react'
import {
  Alert,
  Dimensions,
  FlatList,
  Modal as AppModal,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import TextAvatar from 'react-native-text-avatar'
// import { Carousel } from '@ant-design/react-native';
import { backgroundColor } from '../containers/NavigationScreens'
import WebAlert from '../utils/WebAlert'

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const Modal = Platform.OS === 'web' ? WebModal : AppModal

class HeaderDialogScreen extends Component {
  state = {
    selectedLang: '',
    selectedId: '',
    setSelectedId: '',
    message: '',
    isSelected: true,
    participantData: [],
    isRefresh: true,
    searchValue: '',
    isRemoveAlertModalVisible: false,
    isLeaveAlertModalVisible: false,
    selectedChatId: '',
    value: '',
    item: '',
  }

  componentDidUpdate(prevProps) {
    const { chatData } = this.props
    if (prevProps.chatData !== chatData) {
      this.setState({
        participantData: chatData,
      })
    }
  }

  refresh = () => {
    const { chatData, getData } = this.props
    getData()
    this.setState({
      participantData: chatData,
    })
  }

  getSubjectDetailsAndRemove = () => {
    const { participantData } = this.state
    const { subjectId } = this.props
    var selectedValue = participantData
    var subject = _.filter(selectedValue, function (n) {
      return n.id == subjectId
    })
    this.showSubjectWarningMessage(subject[0])
    // alert(JSON.stringify(subject));
  }

  showSubjectWarningMessage = (value) => {
    const { selectedChatId, removeSubject } = this.props
    if (Platform.OS != 'web') {
      Alert.alert(
        '',
        `Do you want to Leave this group ?`,
        [
          {
            text: 'Cancel',
            onPress: () => true,
            style: 'cancel',
          },
          {
            text: 'OK',
            onPress: () => {
              //  alert('hello');
              removeSubject(selectedChatId, value, this.removeItem)
            },
          },
        ],
        { cancelable: false }
      )
    } else {
      this.setState(
        {
          message: 'Do you want to Leave this group ?',
          selectedChatId: selectedChatId,
          vaue: value,
        },
        () => {
          this.setState({
            isLeaveAlertModalVisible: true,
          })
        }
      )
    }
  }

  showWarningMessage = (item, selectedChatId) => {
    const { removeParticipant } = this.props
    if (Platform.OS != 'web') {
      Alert.alert(
        '',
        `Do you want to remove ${item.fullName} ?`,
        [
          {
            text: 'Cancel',
            onPress: () => true,
            style: 'cancel',
          },
          {
            text: 'OK',
            onPress: () => {
              removeParticipant(item, selectedChatId, this.removeItem)
              // this.removeItem(item);
            },
          },
        ],
        { cancelable: false }
      )
    } else {
      this.setState(
        {
          message: `Do you want to remove ${item.fullName} ?`,
          selectedChatId: selectedChatId,
          item: item,
        },
        () => {
          this.setState({
            isRemoveAlertModalVisible: true,
          })
        }
      )
    }
  }

  removeItem = (value) => {
    const { participantData } = this.state
    var selectedValue = participantData
    const selectedData = _.remove(selectedValue, function (n) {
      return n.id != value.id
    })
    this.setState({
      participantData: selectedData,
    })
  }

  // removeParticipantNow = (value) => {
  //   this.showWarningMessage(value);
  // }

  renderItem = ({ item }) => {
    const { selectedChatId, removeParticipant, subjectId, screenProps } = this.props

    return (
      <View style={{ padding: 10, flexDirection: 'row' }}>
        <TextAvatar
          backgroundColor={'#4e89ae'}
          textColor={'white'}
          size={50}
          type={'circle'} // optional
        >
          {JSON.stringify(item.fullName)}
        </TextAvatar>
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignContent: 'center',
            alignSelf: 'center',
          }}
        >
          <Text style={{ paddingLeft: 10, fontSize: 16 }}>{item.fullName}</Text>
        </View>
        <View>
          {item.id != subjectId ? (
            <TouchableOpacity
              onPress={() => {
                this.showWarningMessage(item, selectedChatId, this.removeParticipantNow)
              }}
              style={{
                borderWidth: 1,
                marginTop: 10,
                borderRadius: 5,
                borderColor: '#4e89ae',
                height: 30,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              testID={`headerDialog-remove-participant-${item.id}-btn`}
              accessible={true}
            >
              <Text style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                {screenProps.t('Remove')}
              </Text>
            </TouchableOpacity>
          ) : (
            <View />
          )}
        </View>
      </View>
    )
  }

  renderListEmptyComponent = () => {
    const { noChatsMessage, screenProps } = this.props
    return (
      <View style={{ flex: 1, height: 400, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: '#546e7a', fontFamily: 'Inter', fontSize: 14 }}>
          {screenProps.t('NoUsersFound')}
        </Text>
      </View>
    )
  }

  hideRemoveAlert = () => {
    this.setState({
      isRemoveAlertModalVisible: false,
    })
  }

  hideLeaveAlert = () => {
    this.setState({
      isLeaveAlertModalVisible: false,
    })
  }

  render() {
    const {
      data,
      removeSubject,
      screenProps,
      selectedChatId,
      hideModal,
      isModalVisible,
      navigation,
      refresh,
      chatData,
      removeParticipant,
    } = this.props
    const {
      participantData,
      isRemoveAlertModalVisible,
      isLeaveAlertModalVisible,
      message,
      item,
      value,
    } = this.state
    const removeAlertbuttons = [ 
      { 'title': 'NO', 'action': () => this.hideRemoveAlert(), key:1 }, 
      { 'title': 'YES', 'action': () => {
        removeParticipant(this.state.item, this.props.selectedChatId, this.removeItem(this.state.item))
        this.hideRemoveAlert()
      } } 
  ]
  const leaveAlertbuttons = [ 
    { 'title': 'NO', 'action': () => this.hideLeaveAlert(), key:2 }, 
    { 'title': 'YES', 'action': () => {
      removeSubject(this.props.selectedChatId, this.state.value, this.removeItem(this.state.value))
      this.hideLeaveAlert()
    } } 
]
    return (
      // <View>

      <Modal
        style={{ width: '100%', height: SCREEN_HEIGHT }}
        onShow={() => this.refresh()}
        transparent={true}
        animationType="slide"
        visible={isModalVisible}
      >
        {Platform.OS == 'web' && isRemoveAlertModalVisible && (
          <WebAlert
            t={screenProps.t}
            headerText={''}
            buttons ={removeAlertbuttons}
            message={message}
            action={() => {
              removeParticipant(item, selectedChatId, this.removeItem)
              this.hideRemoveAlert()
            }}
            hideModal={this.hideRemoveAlert}
          />
        )}
        {Platform.OS == 'web' && isLeaveAlertModalVisible && (
          <WebAlert
            t={screenProps.t}
            headerText={''}
            buttons ={leaveAlertbuttons}
            message={message}
            action={() => {
              removeSubject(selectedChatId, value, this.removeItem)
              this.hideLeaveAlert()
            }}
            hideModal={this.hideLeaveAlert}
          />
        )}

        <View
          style={
            Platform.OS == 'web' && SCREEN_WIDTH >= 1030
              ? { flex: 1, alignItems: 'center', backgroundColor: '#b0bec5', overflow: 'hidden' }
              : { flex: 1 }
          }
        >
          <Container
            style={
              Platform.OS == 'web'
                ? { width: SCREEN_WIDTH >= 1030 ? 800 : '100%', height: '99%' }
                : { width: '100%', backgroundColor: '#fff', alignSelf: 'center' }
            }
          >
            <Header style={{ backgroundColor: backgroundColor }}>
              <Left style={{ alignContent: 'flex-start', width: 10, flex: 0.1 }}>
                <TouchableOpacity
                  onPress={() => {
                    hideModal()
                  }}
                >
                  <Text>
                    <MaterialIcons name="arrow-back" color="#212121" size={24} />
                  </Text>
                </TouchableOpacity>
              </Left>
              <Body style={{ alignContent: 'flex-start' }}>
                <Title style={{ color: '#212121', fontFamily: 'Inter' }}>
                  {screenProps.t('Participants')}
                </Title>
              </Body>
              <Right></Right>
            </Header>
            <Content>
              <FlatList
                data={participantData}
                renderItem={this.renderItem}
                ListEmptyComponent={this.renderListEmptyComponent}
              />
              <Button
                onPress={() => {
                  this.getSubjectDetailsAndRemove()
                  //  removeSubject(selectedChatId,this.getSubjectDetailsAndRemove);
                }}
                transparent
                style={{
                  borderColor: 'red',
                  borderWidth: 1,
                  justifyContent: 'center',
                  margin: 15,
                  marginTop: 30,
                  padding: 5,
                }}
                testID={`headerDialog-leave-group-btn`}
                accessible={true}
              >
                <Text
                  style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    color: 'red',
                    textTransform: 'uppercase',
                  }}
                >
                  {screenProps.t('LeaveGroup')}
                </Text>
              </Button>
            </Content>
          </Container>
        </View>
      </Modal>
    )
  }
}

export default HeaderDialogScreen
