import React from 'react'
import { View } from 'react-native'
import Header from '../../components/common/header'
import SubjectHeaderRight from '../SubjectHeaderRight'

const ParticipantHeader = (props) => {
  const { navigation, screenProps, canShowBackIcon } = props

  return (
    <View
      style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#fff' }}
    >
      <Header navigation={navigation} canShowBackIcon={canShowBackIcon} title={screenProps.t('Visits')} />
      <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
    </View>
  )
}

export default ParticipantHeader
