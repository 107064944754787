export default {
    "Africa/Abidjan": "Αφρική/Αμπιτζάν",
"Africa/Accra": "Αφρική/Άκρα",
"Africa/Addis_Ababa": "Αφρική/Αντίς Αμπέμπα",
"Africa/Algiers": "Αφρική/Αλγέρι",
"Africa/Asmara": "Αφρική/Ασμάρα",
"Africa/Asmera": "Αφρική/Ασμέρα",
"Africa/Bamako": "Αφρική/Μπαμάκο",
"Africa/Bangui": "Αφρική/Μπανγκούι",
"Africa/Banjul": "Αφρική/Μπαντζούλ",
"Africa/Bissau": "Αφρική/Μπισάου",
"Africa/Blantyre": "Αφρική/Μπλαντίρ",
"Africa/Brazzaville": "Αφρική/Μπραζαβίλ",
"Africa/Bujumbura": "Αφρική/Μπουζουμπούρα",
"Africa/Cairo": "Αφρική/Κάιρο",
"Africa/Casablanca": "Αφρική/Καζαμπλάνκα",
"Africa/Ceuta": "Αφρική/Θέουτα",
"Africa/Conakry": "Αφρική/Κόνακρι",
"Africa/Dakar": "Αφρική/Ντακάρ",
"Africa/Dar_es_Salaam": "Αφρική/Ντάρ_ες_Σαλάμ",
"Africa/Djibouti": "Αφρική/Τζιμπουτί",
"Africa/Douala": "Αφρική/Ντουάλα",
"Africa/El_Aaiun": "Αφρική/El_Aaiun",
"Africa/Freetown": "Αφρική/Φρίταουν",
"Africa/Gaborone": "Αφρική/Γκαμπορόνε",
"Africa/Harare": "Αφρική/Χαράρε",
"Africa/Johannesburg": "Αφρική/Γιοχάνεσμπουργκ",
"Africa/Juba": "Αφρική/Τζούμπα",
"Africa/Kampala": "Αφρική/Καμπάλα",
"Africa/Khartoum": "Αφρική/Χαρτούμ",
"Africa/Kigali": "Αφρική/Κιγκάλι",
"Africa/Kinshasa": "Αφρική/Κινσάσα",
"Africa/Lagos": "Αφρική/Λάγος",
"Africa/Libreville": "Αφρική/Λιμπρεβίλ",
"Africa/Lome": "Αφρική/Λομέ",
"Africa/Luanda": "Αφρική/Λουάντα",
"Africa/Lubumbashi": "Αφρική/Λουμπουμπάσι",
"Africa/Lusaka": "Αφρική/Λουσάκα",
"Africa/Malabo": "Αφρική/Μαλάμπο",
"Africa/Maputo": "Αφρική/Μαπούτο",
"Africa/Maseru": "Αφρική/Μασερού",
"Africa/Mbabane": "Αφρική/Μπαμπάνε",
"Africa/Mogadishu": "Αφρική/Μογκαντίσου",
"Africa/Monrovia": "Αφρική/Μονρόβια",
"Africa/Nairobi": "Αφρική/Ναϊρόμπι",
"Africa/Ndjamena": "Αφρική/Ντζαμένα",
"Africa/Niamey": "Αφρική/Νιαμέι",
"Africa/Nouakchott": "Αφρική/Νουακσότ",
"Africa/Ouagadougou": "Αφρική/Ουαγκαντούγκου",
"Africa/Porto-Novo": "Αφρική/Πόρτο Νόβο",
"Africa/Sao_Tome": "Αφρική/Σαο_Τομ",
"Africa/Timbuktu": "Αφρική/Τιμπουκτού",
"Africa/Tripoli": "Αφρική/Τρίπολη",
"Africa/Tunis": "Αφρική/Τύνιδα",
"Africa/Windhoek": "Αφρική/Βίντχουκ",
"America/Adak": "Αμερική/Αντακ",
"America/Anchorage": "Αμερική/Άνκορατζ",
"America/Anguilla": "Αμερική/Ανγκουίλα",
"America/Antigua": "Αμερική/Αντίγκουα",
"America/Araguaina": "Αμερική/Αραγκουέινα",
"America/Argentina/Buenos_Aires": "Αμερική/Αργεντινή/Buenos_Aires",
"America/Argentina/Catamarca": "Αμερική/Αργεντινή/Καταμάρκα",
"America/Argentina/ComodRivadavia": "Αμερική/Αργεντινή/Κομοντριβαδάβια",
"America/Argentina/Cordoba": "Αμερική/Αργεντινή/Κόρδοβα",
"America/Argentina/Jujuy": "Αμερική/Αργεντινή/Jujuy",
"America/Argentina/La_Rioja": "Αμερική/Αργεντινή/La_Rioja",
"America/Argentina/Mendoza": "Αμερική/Αργεντινή/Μεντόζα",
"America/Argentina/Rio_Gallegos": "Αμερική/Αργεντινή/Rio_Gallegos",
"America/Argentina/Salta": "Αμερική/Αργεντινή/Σάλτα",
"America/Argentina/San_Juan": "Αμερική/Αργεντινή/San_Juan",
"America/Argentina/San_Luis": "Αμερική/Αργεντινή/San_Luis",
"America/Argentina/Tucuman": "Αμερική/Αργεντινή/Τουκουμάν",
"America/Argentina/Ushuaia": "Αμερική/Αργεντινή/Ουσουάια",
"America/Aruba": "Αμερική/Αρούμπα",
"America/Asuncion": "Αμερική/Ασουνσιόν",
"America/Atikokan": "Αμερική/Ατικοκάν",
"America/Atka": "Αμερική/Άτκα",
"America/Bahia": "Αμερική/Μπαΐα",
"America/Bahia_Banderas": "Αμερική/Μπάχια_Μπαντέρας",
"America/Barbados": "Αμερική/Μπαρμπάντος",
"America/Belem": "Αμερική/Μπελέμ",
"America/Belize": "Αμερική/Μπελίζ",
"America/Blanc-Sablon": "Αμερική/Μπλάνκ-Σάμπλον",
"America/Boa_Vista": "Αμερική/Μπόα_Βίστα",
"America/Bogota": "Αμερική/Μπογκοτά",
"America/Boise": "Αμερική/Μπόιζι",
"America/Buenos_Aires": "Αμερική/Μπουένος Άιρες",
"America/Cambridge_Bay": "Αμερική/Κάμπρίτζ_Μπέι",
"America/Campo_Grande": "Αμερική/Κάμπο_Γκράντε",
"America/Cancun": "Αμερική/Κανκούν",
"America/Caracas": "Αμερική/Καράκας",
"America/Catamarca": "Αμερική/Καταμάρκα",
"America/Cayenne": "Αμερική/Καγιέν",
"America/Cayman": "Αμερική/Καϊμάν",
"America/Chicago": "Αμερική/Σικάγο",
"America/Chihuahua": "Αμερική/Τσιουάουα",
"America/Ciudad_Juarez": "Αμερική/Σούνταντ_Χουάρες",
"America/Coral_Harbour": "Αμερική/Κοράλ_Χάρμπορ",
"America/Cordoba": "Αμερική/Κόρδοβα",
"America/Costa_Rica": "Αμερική/Κόστα Ρίκα",
"America/Creston": "Αμερική/Κρέστον",
"America/Cuiaba": "Αμερική/Κουιάμπα",
"America/Curacao": "Αμερική/Κουρασάο",
"America/Danmarkshavn": "Αμερική/Δανασκάλαβν",
"America/Dawson": "Αμερική/Ντώσον",
"America/Dawson_Creek": "Αμερική/Ντώσον_Κρικ",
"America/Denver": "Αμερική/Ντένβερ",
"America/Detroit": "Αμερική/Ντιτρόιτ",
"America/Dominica": "Αμερική/Ντομίνικα",
"America/Edmonton": "Αμερική/Έντμοντον",
"America/Eirunepe": "Αμερική/Ειρυνέπε",
"America/El_Salvador": "Αμερική/Ελ Σαλβαδόρ",
"America/Ensenada": "Αμερική/Ενσενάδα",
"America/Fort_Nelson": "Αμερική/Φορτ_Νέλσον",
"America/Fort_Wayne": "Αμερική/Φορτ_Γουέιν",
"America/Fortaleza": "Αμερική/Φορταλέζα",
"America/Glace_Bay": "Αμερική/Glace_Bay",
"America/Godthab": "Αμερική/Γκόντχαμπ",
"America/Goose_Bay": "Αμερική/Goose_Bay",
"America/Grand_Turk": "Αμερική/Γκραντ_Τερκ",
"America/Grenada": "Αμερική/Γρενάδα",
"America/Guadeloupe": "Αμερική/Γουαδελούπη",
"America/Guatemala": "Αμερική/Γουατεμάλα",
"America/Guayaquil": "Αμερική/Γκουαγιακίλ",
"America/Guyana": "Αμερική/Γουιάνα",
"America/Halifax": "Αμερική/Χάλιφαξ",
"America/Havana": "Αμερική/Αβάνα",
"America/Hermosillo": "Αμερική/Ερμοσίγιο",
"America/Indiana/Indianapolis": "Αμερική/Ιντιάνα/Ινδιανάπολη",
"America/Indiana/Knox": "Αμερική/Ιντιάνα/Νοξ",
"America/Indiana/Marengo": "Αμερική/Ιντιάνα/Μαρένγκο",
"America/Indiana/Petersburg": "Αμερική/Ιντιάνα/Πετρούπολη",
"America/Indiana/Tell_City": "Αμερική/Ιντιάνα/Tell_City",
"America/Indiana/Vevay": "Αμερική/Ιντιάνα/Vevay",
"America/Indiana/Vincennes": "Αμερική/Ιντιάνα/Βινσέν",
"America/Indiana/Winamac": "Αμερική/Ιντιάνα/Winamac",
"America/Indianapolis": "Αμερική/Ινδιανάπολη",
"America/Inuvik": "Αμερική/Ινούβικ",
"America/Iqaluit": "Αμερική/Ικαλουίτ",
"America/Jamaica": "Αμερική/Τζαμάικα",
"America/Jujuy": "Αμερική/Χουζούι",
"America/Juneau": "Αμερική/Τζούνο",
"America/Kentucky/Louisville": "Αμερική/Κεντάκι/Λούισβιλ",
"America/Kentucky/Monticello": "Αμερική/Κεντάκι/Μοντιτσέλο",
"America/Knox_IN": "Αμερική/Νόξ_ιν",
"America/Kralendijk": "Αμερική/Κράλεντικ",
"America/La_Paz": "Αμερική/La_Paz",
"America/Lima": "Αμερική/Λίμα",
"America/Los_Angeles": "Αμερική/Λος Άντζελες",
"America/Louisville": "Αμερική/Λούισβιλ",
"America/Lower_Princes": "Αμερική/Lower_Princes",
"America/Maceio": "Αμερική/Μασέιο",
"America/Managua": "Αμερική/Μανάγκουα",
"America/Manaus": "Αμερική/Μανάους",
"America/Marigot": "Αμερική/Μαριγκότ",
"America/Martinique": "Αμερική/Μαρτινίκα",
"America/Matamoros": "Αμερική/Ματαμόρος",
"America/Mazatlan": "Αμερική/Μαζατλάν",
"America/Mendoza": "Αμερική/Μεντόζα",
"America/Menominee": "Αμερική/Μενόμινι",
"America/Merida": "Αμερική/Μέριδα",
"America/Metlakatla": "Αμερική/Μετλακατλά",
"America/Mexico_City": "Αμερική/Μεξικό_Πόλη",
"America/Miquelon": "Αμερική/Μικελόν",
"America/Moncton": "Αμερική/Μόνκτον",
"America/Monterrey": "Αμερική/Μοντερέι",
"America/Montevideo": "Αμερική/Μοντεβιδέο",
"America/Montreal": "Αμερική/Μόντρεαλ",
"America/Montserrat": "Αμερική/Μοντσερράτ",
"America/Nassau": "Αμερική/Νασάου",
"America/New_York": "Αμερική/Νέα Υόρκη",
"America/Nipigon": "Αμερική/Νιπιγκόν",
"America/Nome": "Αμερική/Νομ",
"America/Noronha": "Αμερική/Νόρονα",
"America/North_Dakota/Beulah": "Αμερική/Βόρεια Ντακότα/Beulah",
"America/North_Dakota/Center": "Αμερική/Βόρεια_Ντακότα/Κέντρο",
"America/North_Dakota/New_Salem": "Αμερική/Βόρεια_Ντακότα/Νέου_Σάλεμ",
"America/Nuuk": "Αμερική/Νιούκ",
"America/Ojinaga": "Αμερική/Οτζινάγκα",
"America/Panama": "Αμερική/Παναμάς",
"America/Pangnirtung": "Αμερική/Πανγκνιρτούνγκ",
"America/Paramaribo": "Αμερική/Παραμαρίμπο",
"America/Phoenix": "Αμερική/Φοίνιξ",
"America/Port-au-Prince": "Αμερική/Πορτ-ο-Πρενς",
"America/Port_of_Spain": "Αμερική/λιμάνι της Ισπανίας",
"America/Porto_Acre": "Αμερική/Πόρτο_Άκρα",
"America/Porto_Velho": "Αμερική/Πορτο_Βέλχο",
"America/Puerto_Rico": "Αμερική/Πουέρτο_Ρίκο",
"America/Punta_Arenas": "Αμερική/Πούντα_Αρένας",
"America/Rainy_River": "Αμερική/Ρέινι_Ρίβερ",
"America/Rankin_Inlet": "Αμερική/Ράνκιν_Ινλέτ",
"America/Recife": "Αμερική/Ρεσίφε",
"America/Regina": "Αμερική/Ρετζίνα",
"America/Resolute": "Αμερική/Αποφασιστική",
"America/Rio_Branco": "Αμερική/Ρίο_Μπράνκο",
"America/Rosario": "Αμερική/Ροζάριο",
"America/Santa_Isabel": "Αμερική/Σάντα_Ιζαμπέλ",
"America/Santarem": "Αμερική/Σανταρέμ",
"America/Santiago": "Αμερική/Σαντιάγο",
"America/Santo_Domingo": "Αμερική/Σάντο_Ντομίνγκο",
"America/Sao_Paulo": "Αμερική/Σαό_Πάολο",
"America/Scoresbysund": "Αμερική/Σκόρεσμπισουντ",
"America/Shiprock": "Αμερική/Σίπροκ",
"America/Sitka": "Αμερική/Σίτκα",
"America/St_Barthelemy": "Αμερική/Άγιος Βαρθολομαίος",
"America/St_Johns": "Αμερική/Στ Τζονς",
"America/St_Kitts": "Αμερική/Στ Κιτς",
"America/St_Lucia": "Αμερική/Στ Λουκία",
"America/St_Thomas": "Αμερική/Στ Τόμας",
"America/St_Vincent": "Αμερική/Στ Βίνσεντ",
"America/Swift_Current": "Αμερική/Swift_Current",
"America/Tegucigalpa": "Αμερική/Τεγκουσιγκάλπα",
"America/Thule": "Αμερική/Thule",
"America/Thunder_Bay": "Αμερική/Θάντερ_Μπέι",
"America/Tijuana": "Αμερική/Τιχουάνα",
"America/Toronto": "Αμερική/Τορόντο",
"America/Tortola": "Αμερική/Τορτολά",
"America/Vancouver": "Αμερική/Βανκούβερ",
"America/Virgin": "Αμερική/Παρθένος",
"America/Whitehorse": "Αμερική/Γουάιτχορς",
"America/Winnipeg": "Αμερική/Γουίνιπεγκ",
"America/Yakutat": "Αμερική/Γιακούτατ",
"America/Yellowknife": "Αμερική/Yellowknife",
"Antarctica/Casey": "Ανταρκτική/Κέισι",
"Antarctica/Davis": "Ανταρκτική/Ντέιβις",
"Antarctica/DumontDUrville": "Ανταρκτική/ΝτουμοντΝτάρβιλ",
"Antarctica/Macquarie": "Ανταρκτική/Μακουάρι",
"Antarctica/Mawson": "Ανταρκτική/Mawson",
"Antarctica/McMurdo": "Ανταρκτική/ΜακΜέρντο",
"Antarctica/Palmer": "Ανταρκτική/Πάλμερ",
"Antarctica/Rothera": "Ανταρκτική/Ρόθηρα",
"Antarctica/South_Pole": "Ανταρκτική/Νότιο Πόλο",
"Antarctica/Syowa": "Ανταρκτική/Syowa",
"Antarctica/Troll": "Ανταρκτική/Τρολ",
"Antarctica/Vostok": "Ανταρκτική/Βοστόκ",
"Arctic/Longyearbyen": "Αρκτική/Λονγκιέρμπιεν",
"Asia/Aden": "Ασία/Άντεν",
"Asia/Almaty": "Ασία/Αλμάτι",
"Asia/Amman": "Ασία/Αμμάν",
"Asia/Anadyr": "Ασία/Αναντίρ",
"Asia/Aqtau": "Ασία/Άκταου",
"Asia/Aqtobe": "Ασία/Άκτομπε",
"Asia/Ashgabat": "Ασία/Ασγκαμπάτ",
"Asia/Ashkhabad": "Ασία/Ασκαμπάντ",
"Asia/Atyrau": "Ασία/Ατιράου",
"Asia/Baghdad": "Ασία/Βαγδάτη",
"Asia/Bahrain": "Ασία/Μπαχρέιν",
"Asia/Baku": "Ασία/Μπακού",
"Asia/Bangkok": "Ασία/Μπανγκόκ",
"Asia/Barnaul": "Ασία/Μπαρναούλ",
"Asia/Beirut": "Ασία/Βηρυτός",
"Asia/Bishkek": "Ασία/Μπισκέκ",
"Asia/Brunei": "Ασία/Μπρουνέι",
"Asia/Calcutta": "Ασία/Καλκούτα",
"Asia/Chita": "Ασία/Τσίτα",
"Asia/Choibalsan": "Ασία/Τσόιμπαλσαν",
"Asia/Chongqing": "Ασία/Τσονγκκίνγκ",
"Asia/Chungking": "Ασία/Τσουνγκκίνγκ",
"Asia/Colombo": "Ασία/Κολόμπο",
"Asia/Dacca": "Ασία/Ντάκα",
"Asia/Damascus": "Ασία/Δαμασκός",
"Asia/Dhaka": "Ασία/Ντάκα",
"Asia/Dili": "Ασία/Ντίλι",
"Asia/Dubai": "Ασία/Ντουμπάι",
"Asia/Dushanbe": "Ασία/Ντουσάνμπε",
"Asia/Famagusta": "Ασία/Αμμόχωστος",
"Asia/Gaza": "Ασία/Γάζα",
"Asia/Harbin": "Ασία/Χαρμπίν",
"Asia/Hebron": "Ασία/Χεβρώνα",
"Asia/Ho_Chi_Minh": "Ασία/Χο_Τσι_Μινχ",
"Asia/Hong_Kong": "Ασία/Χονγκ Κονγκ",
"Asia/Hovd": "Ασία/Hovd",
"Asia/Irkutsk": "Ασία/Ιρκούτσκ",
"Asia/Istanbul": "Ασία/Κωνσταντινούπολη",
"Asia/Jakarta": "Ασία/Τζακάρτα",
"Asia/Jayapura": "Ασία/Τζαγιαπούρα",
"Asia/Jerusalem": "Ασία/Ιερουσαλήμ",
"Asia/Kabul": "Ασία/Καμπούλ",
"Asia/Kamchatka": "Ασία/Καμτσάτκα",
"Asia/Karachi": "Ασία/Καράτσι",
"Asia/Kashgar": "Ασία/Κασγκάρ",
"Asia/Kathmandu": "Ασία/Κατμαντού",
"Asia/Katmandu": "Ασία/Κατμαντού",
"Asia/Khandyga": "Ασία/Χάντυγα",
"Asia/Kolkata": "Ασία/Καλκούτα",
"Asia/Krasnoyarsk": "Ασία/Κρασνογιάρσκ",
"Asia/Kuala_Lumpur": "Ασία/Κουάλα Λουμπούρ",
"Asia/Kuching": "Ασία/Κουτσίνγκ",
"Asia/Kuwait": "Ασία/Κουβέιτ",
"Asia/Macao": "Ασία/Μακάο",
"Asia/Macau": "Ασία/Μακάο",
"Asia/Magadan": "Ασία/Μαγκαντάν",
"Asia/Makassar": "Ασία/Μακάσαρ",
"Asia/Manila": "Ασία/Μανίλα",
"Asia/Muscat": "Ασία/Μουσκάτ",
"Asia/Nicosia": "Ασία/Λευκωσία",
"Asia/Novokuznetsk": "Ασία/Νοβοκουζνέτσκ",
"Asia/Novosibirsk": "Ασία/Νοβοσιμπίρσκ",
"Asia/Omsk": "Ασία/Ομσκ",
"Asia/Oral": "Ασία/Προφορική",
"Asia/Phnom_Penh": "Ασία/Φνομ Πενχ",
"Asia/Pontianak": "Ασία/Ποντιάνακ",
"Asia/Pyongyang": "Ασία/Πιονγκγιάνγκ",
"Asia/Qatar": "Ασία/Κατάρ",
"Asia/Qostanay": "Ασία/Κοστανάι",
"Asia/Qyzylorda": "Ασία/Κιζιλόρδα",
"Asia/Rangoon": "Ασία/Ρανγκούν",
"Asia/Riyadh": "Ασία/Ριάντ",
"Asia/Saigon": "Ασία/Σαϊγκόν",
"Asia/Sakhalin": "Ασία/Σαχαλίν",
"Asia/Samarkand": "Ασία/Σαμαρκάνδη",
"Asia/Seoul": "Ασία/Σεούλ",
"Asia/Shanghai": "Ασία/Σαγκάη",
"Asia/Singapore": "Ασία/Σιγκαπούρη",
"Asia/Srednekolymsk": "Ασία/Σρεντνεκολυμσκ",
"Asia/Taipei": "Ασία/Ταϊπέι",
"Asia/Tashkent": "Ασία/Τασκένδη",
"Asia/Tbilisi": "Ασία/Τιφλίδα",
"Asia/Tehran": "Ασία/Τεχεράνη",
"Asia/Tel_Aviv": "Ασία/Τελ Αβίβ",
"Asia/Thimbu": "Ασία/Θίμπου",
"Asia/Thimphu": "Ασία/Θίμπου",
"Asia/Tokyo": "Ασία/Τόκιο",
"Asia/Tomsk": "Ασία/Τομσκ",
"Asia/Ujung_Pandang": "Ασία/Ουζουνγκ_Παντάνγκ",
"Asia/Ulaanbaatar": "Ασία/Ουλάν Μπατόρ",
"Asia/Ulan_Bator": "Ασία/Ουλάν_Μπατόρ",
"Asia/Urumqi": "Ασία/Ουρούμκι",
"Asia/Ust-Nera": "Ασία/Ούστ-Νερά",
"Asia/Vientiane": "Ασία/Βιεντιάν",
"Asia/Vladivostok": "Ασία/Βλαδιβοστόκ",
"Asia/Yakutsk": "Ασία/Γιακούτσκ",
"Asia/Yangon": "Ασία/Γιανγκόν",
"Asia/Yekaterinburg": "Ασία/Εκατερίνμπουργκ",
"Asia/Yerevan": "Ασία/Ερεβάν",
"Atlantic/Azores": "Ατλαντικός/Αζόρες",
"Atlantic/Bermuda": "Ατλαντικός/Βερμούδες",
"Atlantic/Canary": "Ατλαντικό/Καναρίνι",
"Atlantic/Cape_Verde": "Ατλαντικός/Πράσινο Ακρωτήριο",
"Atlantic/Faeroe": "Ατλαντικός/Φερόε",
"Atlantic/Faroe": "Ατλαντικός/Φερόες",
"Atlantic/Jan_Mayen": "Ατλαντικός/Jan_Mayen",
"Atlantic/Madeira": "Ατλαντικός/Μαδέρα",
"Atlantic/Reykjavik": "Ατλαντικό/Ρέικιαβικ",
"Atlantic/South_Georgia": "Ατλαντικός/Νότια_Γεωργία",
"Atlantic/St_Helena": "Ατλαντικός/Αγία Ελένη",
"Atlantic/Stanley": "Ατλαντικός/Στάνλεϊ",
"Australia/ACT": "Αυστραλία/ACT",
"Australia/Adelaide": "Αυστραλία/Αδελαΐδα",
"Australia/Brisbane": "Αυστραλία/Μπρίσμπεϊν",
"Australia/Broken_Hill": "Αυστραλία/Μπρόκεν_Χιλ",
"Australia/Canberra": "Αυστραλία/Καμπέρα",
"Australia/Currie": "Αυστραλία/Currie",
"Australia/Darwin": "Αυστραλία/Δαρβίνος",
"Australia/Eucla": "Αυστραλία/Εύκλα",
"Australia/Hobart": "Αυστραλία/Χόμπαρτ",
"Australia/LHI": "Αυστραλία/LHI",
"Australia/Lindeman": "Αυστραλία/Λίντεμαν",
"Australia/Lord_Howe": "Αυστραλία/Λόρντ_Χάου",
"Australia/Melbourne": "Αυστραλία/Μελβούρνη",
"Australia/NSW": "Αυστραλία/NSW",
"Australia/North": "Αυστραλία/Βόρεια",
"Australia/Perth": "Αυστραλία/Περθ",
"Australia/Queensland": "Αυστραλία/Κουίνσλαντ",
"Australia/South": "Αυστραλία/Νότος",
"Australia/Sydney": "Αυστραλία/Σίδνεϊ",
"Australia/Tasmania": "Αυστραλία/Τασμανία",
"Australia/Victoria": "Αυστραλία/Βικτώρια",
"Australia/West": "Αυστραλία/Δύση",
"Australia/Yancowinna": "Αυστραλία/Γιάνκοβιννα",
"Brazil/Acre": "Βραζιλία/Άκρα",
"Brazil/DeNoronha": "Βραζιλία/Ντενορόνχα",
"Brazil/East": "Βραζιλία/Ανατολή",
"Brazil/West": "Βραζιλία/Δύση",
"CET": "ΑΥΤΌ",
"CST6CDT": "ΚΣΤ6CDT",
"Canada/Atlantic": "Καναδάς/Ατλαντικός",
"Canada/Central": "Καναδάς/Κεντρικός",
"Canada/Eastern": "Καναδάς/Ανατολικός",
"Canada/Mountain": "Καναδάς/Βουνό",
"Canada/Newfoundland": "Καναδάς/Νιούφαουντλαντ",
"Canada/Pacific": "Καναδάς/Ειρηνικός",
"Canada/Saskatchewan": "Καναδάς/Σασκάτσουαν",
"Canada/Yukon": "Καναδάς/Γιούκον",
"Chile/Continental": "Χιλή/Ηπειρωτική χώρα",
"Chile/EasterIsland": "Χιλή/Νησί του Πάσχα",
"Cuba": "Κούβα",
"EET": "ΦΆΤΕ",
"EST": "ΕΊΝΑΙ",
"EST5EDT": "ΕΣΤ5EDT",
"Egypt": "Αίγυπτος",
"Eire": "Αίγυπτος",
"Etc/GMT": "κ.λπ.χ/GMT",
"Etc/GMT+0": "Κ.λ.λ./GMT+0",
"Etc/GMT+1": "Κ.λ.λ.λ.λ.λ.λ.λ.",
"Etc/GMT+10": "Κ.λ.λ.λ.λ.λ.λ.",
"Etc/GMT+11": "Κ.λ.λ./GMT+11",
"Etc/GMT+12": "Κ.λ.λ./GMT+12",
"Etc/GMT+2": "Κ.λ.λ./ΓΜΤ+2",
"Etc/GMT+3": "Κ.λ.λ.λ./GMT+3",
"Etc/GMT+4": "Κ.λ.λ.λ.λ.λ.λ.",
"Etc/GMT+5": "Κ.λ.λ./GMT+5",
"Etc/GMT+6": "Κ.λ.λ./GMT+6",
"Etc/GMT+7": "Κ.λ.λ./GMT+7",
"Etc/GMT+8": "Κ.λ.λ./GMT+8",
"Etc/GMT+9": "Κ.λ.λ./GMT+9",
"Etc/GMT-0": "κ.λπ./GMT-0",
"Etc/GMT-1": "Κ.λτ/GMT-1",
"Etc/GMT-10": "κ.λπ./GMT-10",
"Etc/GMT-11": "κ.λπ./GMT-11",
"Etc/GMT-12": "κ.λπ./GMT-12",
"Etc/GMT-13": "κ.λπ./GMT-13",
"Etc/GMT-14": "κ.λπ./GMT-14",
"Etc/GMT-2": "Κ.λτ/GMT-2",
"Etc/GMT-3": "Κ.λτ/GMT-3",
"Etc/GMT-4": "Κ.λτ/GMT-4",
"Etc/GMT-5": "Κ.λτ/GMT-5",
"Etc/GMT-6": "Κ.λτ/GMT-6",
"Etc/GMT-7": "Κ.λτ/GMT-7",
"Etc/GMT-8": "Κ.λτ/GMT-8",
"Etc/GMT-9": "κ.λπ./GMT-9",
"Etc/GMT0": "Κ.λ.λ./GMT0",
"Etc/Greenwich": "Etc/Γκρίνουιτς",
"Etc/UCT": "Κ.λ.λ./UCT",
"Etc/UTC": "κ.λπ./UTC",
"Etc/Universal": "Etc/Καθολική",
"Etc/Zulu": "Etc/Ζουλού",
"Europe/Amsterdam": "Ευρώπη/Άμστερνταμ",
"Europe/Andorra": "Ευρώπη/Ανδόρα",
"Europe/Astrakhan": "Ευρώπη/Αστραχάν",
"Europe/Athens": "Ευρώπη/Αθήνα",
"Europe/Belfast": "Ευρώπη/Μπέλφαστ",
"Europe/Belgrade": "Ευρώπη/Βελιγράδι",
"Europe/Berlin": "Ευρώπη/Βερολίνο",
"Europe/Bratislava": "Ευρώπη/Μπρατισλάβα",
"Europe/Brussels": "Ευρώπη/Βρυξέλλες",
"Europe/Bucharest": "Ευρώπη/Βουκουρέστι",
"Europe/Budapest": "Ευρώπη/Βουδαπέστη",
"Europe/Busingen": "Ευρώπη/Μπούσινγκεν",
"Europe/Chisinau": "Ευρώπη/Κισινάου",
"Europe/Copenhagen": "Ευρώπη/Κοπεγχάγη",
"Europe/Dublin": "Ευρώπη/Δουβλίνο",
"Europe/Gibraltar": "Ευρώπη/Γιβραλτάρ",
"Europe/Guernsey": "Ευρώπη/Γκέρνσεϊ",
"Europe/Helsinki": "Ευρώπη/Ελσίνκι",
"Europe/Isle_of_Man": "Ευρώπη/Νησί του Ανθρώπου",
"Europe/Istanbul": "Ευρώπη/Κωνσταντινούπολη",
"Europe/Jersey": "Ευρώπη/Τζέρσεϋ",
"Europe/Kaliningrad": "Ευρώπη/Καλίνινγκραντ",
"Europe/Kiev": "Ευρώπη/Κίεβο",
"Europe/Kirov": "Ευρώπη/Κίροφ",
"Europe/Kyiv": "Ευρώπη/Κίεβο",
"Europe/Lisbon": "Ευρώπη/Λισαβόνα",
"Europe/Ljubljana": "Ευρώπη/Λιουμπλιάνα",
"Europe/London": "Ευρώπη/Λονδίνο",
"Europe/Luxembourg": "Ευρώπη/Λουξεμβούργο",
"Europe/Madrid": "Ευρώπη/Μαδρίτη",
"Europe/Malta": "Ευρώπη/Μάλτα",
"Europe/Mariehamn": "Ευρώπη/Μαριέχαμν",
"Europe/Minsk": "Ευρώπη/Μινσκ",
"Europe/Monaco": "Ευρώπη/Μονακό",
"Europe/Moscow": "Ευρώπη/Μόσχα",
"Europe/Nicosia": "Ευρώπη/Λευκωσία",
"Europe/Oslo": "Ευρώπη/Όσλο",
"Europe/Paris": "Ευρώπη/Παρίσι",
"Europe/Podgorica": "Ευρώπη/Ποντγκόριτσα",
"Europe/Prague": "Ευρώπη/Πράγα",
"Europe/Riga": "Ευρώπη/Ρίγα",
"Europe/Rome": "Ευρώπη/Ρώμη",
"Europe/Samara": "Ευρώπη/Σαμάρα",
"Europe/San_Marino": "Ευρώπη/Σαν Μαρίνο",
"Europe/Sarajevo": "Ευρώπη/Σαράγεβο",
"Europe/Saratov": "Ευρώπη/Σαράτοφ",
"Europe/Simferopol": "Ευρώπη/Συμφερούπολη",
"Europe/Skopje": "Ευρώπη/Σκόπια",
"Europe/Sofia": "Ευρώπη/Σόφια",
"Europe/Stockholm": "Ευρώπη/Στοκχόλμη",
"Europe/Tallinn": "Ευρώπη/Ταλίν",
"Europe/Tirane": "Ευρώπη/Τιράνη",
"Europe/Tiraspol": "Ευρώπη/Τιρασπόλ",
"Europe/Ulyanovsk": "Ευρώπη/Ουλιάνοφσκ",
"Europe/Uzhgorod": "Ευρώπη/Ουζχορόντ",
"Europe/Vaduz": "Ευρώπη/Βαντούζ",
"Europe/Vatican": "Ευρώπη/Βατικανό",
"Europe/Vienna": "Ευρώπη/Βιέννη",
"Europe/Vilnius": "Ευρώπη/Βίλνιους",
"Europe/Volgograd": "Ευρώπη/Βόλγκογκραντ",
"Europe/Warsaw": "Ευρώπη/Βαρσοβία",
"Europe/Zagreb": "Ευρώπη/Ζάγκρεμπ",
"Europe/Zaporozhye": "Ευρώπη/Ζαπορίζια",
"Europe/Zurich": "Ευρώπη/Ζυρίχη",
"GB": "GB",
"GB-Eire": "GB Άιρο",
"GMT": "ΓΡΜ",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Γκρίνουιτς",
"HST": "HST",
"Hongkong": "Χονγκ Κονγκ",
"Iceland": "Ισλανδία",
"Indian/Antananarivo": "Ινδία/Ανταναναρίβο",
"Indian/Chagos": "Ινδία/Τσάγκος",
"Indian/Christmas": "Ινδία/Χριστούγεννα",
"Indian/Cocos": "Ινδικός/Κόκος",
"Indian/Comoro": "Ινδία/Κομόρο",
"Indian/Kerguelen": "Ινδία/Κεργκέλεν",
"Indian/Mahe": "Ινδία/Μάε",
"Indian/Maldives": "Ινδία/Μαλδίβες",
"Indian/Mauritius": "Ινδικός/Μαυρίκιος",
"Indian/Mayotte": "Ινδία/Μαγιότ",
"Indian/Reunion": "Ινδία/Ρεϊνιόν",
"Iran": "Ιράν",
"Israel": "Ισραήλ",
"Jamaica": "Τζαμάικα",
"Japan": "Ιαπωνία",
"Kwajalein": "Κουατζαλέιν",
"Libya": "Λιβύη",
"MET": "ΜΕ",
"MST": "MST",
"MST7MDT": "ΜΣΤ7ΜDT",
"Mexico/BajaNorte": "Μεξικό/Μπαγανόρτε",
"Mexico/BajaSur": "Μεξικό/Μπατζασούρ",
"Mexico/General": "Μεξικό/Γενικά",
"NZ": "ΝΈΑ ΖΗΛΑΝΔΊΑ",
"NZ-CHAT": "NZ-ΣΥΝΟΜΙΛΊΑ",
"Navajo": "Ναβάχο",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Ειρηνικός/Άπια",
"Pacific/Auckland": "Ειρηνικός/Ώκλαντ",
"Pacific/Bougainville": "Ειρηνικός/Μπουγκενβίλ",
"Pacific/Chatham": "Ειρηνικός/Τσάταμ",
"Pacific/Chuuk": "Ειρηνικός/Τσουκ",
"Pacific/Easter": "Ειρηνικός/Πάσχα",
"Pacific/Efate": "Ειρηνικός/Εφάτε",
"Pacific/Enderbury": "Ειρηνικός/Έντερμπερι",
"Pacific/Fakaofo": "Ειρηνικός/Φακαόφο",
"Pacific/Fiji": "Ειρηνικός/Φίτζι",
"Pacific/Funafuti": "Ειρηνικός/Φουναφούτι",
"Pacific/Galapagos": "Ειρηνικός/Γκαλαπάγκος",
"Pacific/Gambier": "Ειρηνικός/Γκάμπιερ",
"Pacific/Guadalcanal": "Ειρηνικός/Γκουανταλκανάλ",
"Pacific/Guam": "Ειρηνικός/Γκουάμ",
"Pacific/Honolulu": "Ειρηνικός/Χονολουλού",
"Pacific/Johnston": "Ειρηνικός/Τζόνστον",
"Pacific/Kanton": "Ειρηνικός/Καντόν",
"Pacific/Kiritimati": "Ειρηνικός/Κιριτιμάτι",
"Pacific/Kosrae": "Ειρηνικός/Κοσράε",
"Pacific/Kwajalein": "Ειρηνικός/Κουαγιαλέιν",
"Pacific/Majuro": "Ειρηνικός/Ματζούρο",
"Pacific/Marquesas": "Ειρηνικός/Μαρκέζας",
"Pacific/Midway": "Ειρηνικός/Μίντγουεϊ",
"Pacific/Nauru": "Ειρηνικός/Ναουρού",
"Pacific/Niue": "Ειρηνικός/Νιούε",
"Pacific/Norfolk": "Ειρηνικός/Νόρφολκ",
"Pacific/Noumea": "Ειρηνικός/Νουμέα",
"Pacific/Pago_Pago": "Ειρηνικός/Πάγκο_Πάγκο",
"Pacific/Palau": "Ειρηνικός/Παλάου",
"Pacific/Pitcairn": "Ειρηνικός/Πίτκερν",
"Pacific/Pohnpei": "Ειρηνικός/Πονπέι",
"Pacific/Ponape": "Ειρηνικός/Πονάπε",
"Pacific/Port_Moresby": "Ειρηνικός/Πόρτ_Μόρεσμπι",
"Pacific/Rarotonga": "Ειρηνικός/Ραροτόνγκα",
"Pacific/Saipan": "Ειρηνικός/Σαϊπάν",
"Pacific/Samoa": "Ειρηνικός/Σαμόα",
"Pacific/Tahiti": "Ειρηνικός/Ταϊτή",
"Pacific/Tarawa": "Ειρηνικός/Ταράβα",
"Pacific/Tongatapu": "Ειρηνικός/Τονγκαταπού",
"Pacific/Truk": "Ειρηνικός/Τρουκ",
"Pacific/Wake": "Ειρηνικός/Ξύπνημα",
"Pacific/Wallis": "Ειρηνικός/Γουόλις",
"Pacific/Yap": "Ειρηνικός/Γιαπ",
"Poland": "Πολωνία",
"Portugal": "Πορτογαλία",
"ROC": "ΡΟΚ",
"ROK": "ΡΟΚ",
"Singapore": "Σιγκαπούρη",
"Turkey": "Τουρκία",
"UCT": "UCT",
"US/Alaska": "ΗΠΑ/Αλάσκα",
"US/Aleutian": "ΗΠΑ/Αλεούτια",
"US/Arizona": "ΗΠΑ/Αριζόνα",
"US/Central": "ΗΠΑ/Κεντρική",
"US/East-Indiana": "ΗΠΑ/Ανατολική Ιντιάνα",
"US/Eastern": "ΗΠΑ/Ανατολικά",
"US/Hawaii": "ΗΠΑ/Χαβάη",
"US/Indiana-Starke": "ΗΠΑ/Ιντιάνα Σταρκ",
"US/Michigan": "ΗΠΑ/Μίτσιγκαν",
"US/Mountain": "ΗΠΑ/Βουνό",
"US/Pacific": "ΗΠΑ/Ειρηνικός",
"US/Samoa": "ΗΠΑ/Σαμόα",
"UTC": "UTC",
"Universal": "Καθολική",
"W-SU": "W-SU",
"WET": "ΥΓΡΌ",
"Zulu": "Ζουλού",
}