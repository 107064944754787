import { View, ScrollView, Dimensions, StyleSheet, Platform , Text} from 'react-native'
import React, {useState} from 'react'
import RenderHTML from 'react-native-render-html'
import TermsAndConditionButtons from './TermsNConditionButtons'
import Logo from './Logo'
import styles from '../../../src/styles/listStyles'
import { useSelector } from 'react-redux';
import Loader from '../common/Loader'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const {height: SCREEN_HEIGHT} = Dimensions.get('window')
let { height } = Dimensions.get('window');
height = height - hp('30%');

export const TermsAndConditionStyles = StyleSheet.create({
  containerStyle: { flex: 1, backgroundColor: '#fff'},
  innerContainerStyle: { margin: 20, marginTop: 40, flex: 0.8},
  headingStyle:  {fontWeight: 'bold', marginTop: 20, marginBottom: 20},
  buttonsView: {marginTop: 10, flex: 0.2}
})

const TermsAndConditions = (props) => {
  const { navigation,  screenProps: {t} } = props
  const { privacyPolicy } = useSelector(state => state.users);
  const firstTimeLogin = navigation.getParam('firstTimeLogin')
  const phoneNumber = navigation.getParam('phoneNumber')

  const[enableAgreeBtn, setEnableAgreeBtn] = useState(false)
  const [loading, setLoading] = useState(false)

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  const handleAgreeButtonWhenNoScroll = (contentWidth, contentHeight) => {
    if(contentHeight && (contentHeight <  height)) {
      setEnableAgreeBtn(true)
    } else if(contentHeight && (contentHeight >  height)) {
      setEnableAgreeBtn(false)
    }
  }

  return (
    <View style={TermsAndConditionStyles.containerStyle}>
      <Loader loading={loading}/>
      <View style={TermsAndConditionStyles.innerContainerStyle}>
       <Logo />
       <Text style={[styles.textStyle, TermsAndConditionStyles.headingStyle]}>{t('TermsAndConditions')}</Text>
      <ScrollView onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
                setEnableAgreeBtn(true);
              }
          }}
          style={{height: Platform.OS === 'web' ? SCREEN_HEIGHT*0.47 : SCREEN_HEIGHT*0.70 }}
          onContentSizeChange={handleAgreeButtonWhenNoScroll}
          >
        <RenderHTML source={{ html: privacyPolicy?.privacyPolicy }} />
        <RenderHTML source={{ html: privacyPolicy?.termsAndConditions }} />  
      </ScrollView> 
      </View>
      <View style={TermsAndConditionStyles.buttonsView}>
            <TermsAndConditionButtons navigation={navigation} firstTimeLogin = {firstTimeLogin} enableAgreeBtn={enableAgreeBtn} t={t} setLoading={() => {setLoading(!loading)}}/>
      </View> 
    </View>
  )
}

export default TermsAndConditions
