import React from 'react'
import PhoneNoText from './PhoneNoText'
import PhNumberInput from './PhNumberInput'
import { useSelector } from 'react-redux';
import { isDataExistsToSync } from '../selectors/syncQueue';

const LoginPhoneNoComponent = (props) => {
  const {deviceLocation, callingCode,  t,  getPhoneValue } = props
  const isOfflineDataExists = useSelector(state => isDataExistsToSync(state));

  if(isOfflineDataExists){
    return <PhoneNoText t={t}/>
  }
    
  return(
      <PhNumberInput
      deviceLocation={deviceLocation}
      callingCode={callingCode}
      t={t}
      getPhoneValue={getPhoneValue}
      />
    )
  
}

export default LoginPhoneNoComponent
