import React from 'react'
import { Text, Platform, TouchableOpacity, StyleSheet } from 'react-native'
import Dialog, {
  DialogContent,
  DialogFooter,
  DialogButton,
  ScaleAnimation,
} from 'react-native-popup-dialog'
import moment from 'moment-timezone'

export default class CallJoinRequest extends React.Component {
  render() {
    const { visible, closePopup, call, openCallLink, timeZone } = this.props
    return (
      <Dialog
        onDismiss={() => {
          closePopup()
        }}
        width={Platform.OS === 'web' ? 0.5 : 0.9}
        visible={visible}
        rounded
        dialogAnimation={new ScaleAnimation()}
        footer={
          <DialogFooter>
            <TouchableOpacity
              style={{ padding: 20, flex: 1, alignItems: 'center' }}
              onPress={() => {
                closePopup()
              }}
            >
              <Text style={{ color: '#e53935', fontSize: 20 }}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ padding: 20, backgroundColor: '#4caf50', flex: 1, alignItems: 'center' }}
              onPress={() => {
                openCallLink()
              }}
            >
              <Text
                style={{
                  color: '#ffff',
                  fontSize: 20,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                Join
              </Text>
            </TouchableOpacity>
          </DialogFooter>
        }
      >
        <DialogContent
          style={{
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            height: 'auto',
          }}
        >
          <Text style={styles.callTitleText}>
            {call.callTitle} is in progress. Please join the call.
          </Text>
          <Text style={styles.callDetailsText}>
            {moment(call.scheduleDateTimeUtc)?.tz(timeZone).format('DD-MMM-YYYY hh:mm A z')}
          </Text>
          <Text style={styles.callDetailsText}>
            Duration:{' '}
            {Math.floor(call.duration / 60) == 0
              ? ''
              : `${Math.floor(call.duration / 60)} ${call.duration > 119 ? 'hrs' : 'hr'} `}{' '}
            {call.duration % 60 == 0
              ? ''
              : `${call.duration % 60} ${call.duration % 60 == 1 ? 'min' : 'mins'}`}
          </Text>
        </DialogContent>
      </Dialog>
    )
  }
}

const openCallLink = (URL) => {
  const { deviceTokenData } = this.state
  const callUrl = `${URL}?subjectDeviceToken=${encodeURIComponent(
    deviceTokenData.subjectDeviceToken
  )}&subjectDeviceTokenId=${deviceTokenData.subjectDeviceTokenId}`
  if (Platform.OS === 'web') {
    window.open(callUrl, '_blank')
  } else {
    Linking.openURL(callUrl)
  }
}

const styles = StyleSheet.create({
  callDetailsText: {
    color: '#000000',
    fontSize: 14,
  },
  callTitleText: {
    color: '#000000',
    fontSize: 18,
    marginVertical: 20,
  },
})
