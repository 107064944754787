import React, { Component } from 'react'
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  ScrollView,
  ActivityIndicator,
  FlatList,
  Platform,
} from 'react-native'
import { Content } from 'native-base'
// import PushNotifications from '../constants/PushNotifications';
import { buildMessage } from '../utils/pushNotificationUtils'
import { NavigationEvents } from 'react-navigation'
import { screensEnabled } from 'react-native-screens'
import * as Linking from 'expo-linking'
import { backgroundColor } from '../containers/NavigationScreens'
import { retrieveSubjectDeviceToken } from '../utils/secureStorageUtils'

const { height } = Dimensions.get('window')
class Messages extends Component {
  state = {
    deviceTokenData: null,
  }

  componentDidMount() {
    this.fetchSubjectDeviceToken()
  }

  fetchSubjectDeviceToken = async () => {
    try {
      const deviceTokenData = await retrieveSubjectDeviceToken()
      this.setState({
        deviceTokenData,
      })
    } catch (error) {
      console.log(error)
    }
  }

  openCallLink = (URL) => {
    const { deviceTokenData } = this.state
    const callUrl = `${URL}?subjectDeviceToken=${encodeURIComponent(
      deviceTokenData.subjectDeviceToken
    )}&subjectDeviceTokenId=${deviceTokenData.subjectDeviceTokenId}`
    if (Platform.OS === 'web') {
      window.open(callUrl, '_blank')
    } else {
      Linking.openURL(callUrl)
    }
  }

  renderListEmptyComponent = () => {
    const {
      screenProps: { t },
      loading,
    } = this.props

    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text style={[styles.noDataText, { padding: 10 }]}>{t('NOMSG')}</Text>
      </View>
    )
  }

  render() {
    const {
      selectedLanguage,
      pushNotifications,
      screenProps: { t },
      loading,
      refresh,
    } = this.props
    return (
      <View style={{ flex: 1, justifyContent: 'center', backgroundColor: '#ffffff' }}>
        {loading && (
          <View
            style={{
              flex: 1,
              position: 'absolute',
              alignItems: 'center',
              alignSelf: 'center',
              zIndex: 10,
            }}
          >
            <ActivityIndicator size="large" color="#d7dadb" overlayColor="#d7dadb" />
          </View>
        )}
        <Content
          padder
          contentContainerStyle={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}
        >
          {!loading && (
            <FlatList
              data={pushNotifications || []}
              renderItem={({ item: pn }) => buildMessage(pn, this.openCallLink)}
              keyExtractor={(item) => item.id}
              onRefresh={refresh}
              refreshing={loading}
              ListEmptyComponent={this.renderListEmptyComponent}
            />
          )}
        </Content>
      </View>
    )
  }
}

export default Messages

export const styles = StyleSheet.create({
  noDataText: {
    color: '#546e7a',
    fontFamily: 'Inter',
    fontSize: 14,
    alignSelf: 'center',
    justifyContent: 'center',
    // alignItems:'center',
  },
})
