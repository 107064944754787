import { MaterialIcons } from '@expo/vector-icons'
import { Header } from 'native-base'
import React, { Component } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setCurrentScreen } from '../actions/storeAppStatus'
import ChangeTimeZone from '../components/ChangeTimeZone'
import api from '../utils/api'

class ChangeTimeZonePage extends Component {
  state = {
    timeZonesList: [],
    loading: true,
  }

  // static navigationOptions = ({ navigation, screenProps: { t } }) => ({
  //   title: t('SelectTimezone'),
  // })

  retrieveTimeZoneList = async () => {
    const { navigation } = this.props
    try {
      const res = await api.get(`/timeZone`)
      this.getTimeZoneList(res)
      this.setState({
        loading: false,
      })
    } catch (error) {
      this.setState({
        loading: false,
      })
      console.log(error)
    }
  }

  getTimeZoneList = (res) => {
    this.setState({
      timeZonesList: res.data,
    })
  }

  componentDidMount = () => {
    const { navigation } = this.props
    const timeZones = navigation.getParam('timeZones')
    const loading = navigation.getParam('loading')
    this.setState({
      loading: loading,
    })
    this.retrieveTimeZoneList()
  }

  render() {
    const { params } = this.props.navigation.state
    const { timeZonesList, loading } = this.state
    const { selectLanguage, selectedLanguage, navigation, screenProps } = this.props
    const setTimeZone = navigation.getParam('setTimeZone')
    const fromLogin = navigation.getParam('fromLogin')

    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        <ChangeTimeZone
          loading={loading}
          setTimeZone={setTimeZone}
          timeZones={timeZonesList}
          screenProps={screenProps}
          navigation={navigation}
          selectedLang={selectedLanguage}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.changeLanguage.selectedLanguage,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTimeZonePage)
