export default {
    "Africa/Abidjan": "Africa/Abidjan",
"Africa/Accra": "Africa/Accra",
"Africa/Addis_Ababa": "Africa/Addis Abeba",
"Africa/Algiers": "Africa/Algeri",
"Africa/Asmara": "Africa/Asmara",
"Africa/Asmera": "Africa/Asmera",
"Africa/Bamako": "Africa/Bamako",
"Africa/Bangui": "Africa/Bangui",
"Africa/Banjul": "Africa/Banjul",
"Africa/Bissau": "Africa/Bissau",
"Africa/Blantyre": "Africa/Blantyre",
"Africa/Brazzaville": "Africa/Brazzaville",
"Africa/Bujumbura": "Africa/Bujumbura",
"Africa/Cairo": "Africa/Il Cairo",
"Africa/Casablanca": "Africa/Casablanca",
"Africa/Ceuta": "Africa/Ceuta",
"Africa/Conakry": "Africa/Conakry",
"Africa/Dakar": "Africa/Dakar",
"Africa/Dar_es_Salaam": "Africa/Dar_es_Salaam",
"Africa/Djibouti": "Africa/Gibuti",
"Africa/Douala": "Africa/Douala",
"Africa/El_Aaiun": "Africa/El Aaiun",
"Africa/Freetown": "Africa/Freetown",
"Africa/Gaborone": "Africa/Gaborone",
"Africa/Harare": "Africa/Harare",
"Africa/Johannesburg": "Africa/Johannesburg",
"Africa/Juba": "Africa/Juba",
"Africa/Kampala": "Africa/Kampala",
"Africa/Khartoum": "Africa/Khartum",
"Africa/Kigali": "Africa/Kigali",
"Africa/Kinshasa": "Africa/Kinshasa",
"Africa/Lagos": "Africa/Lagos",
"Africa/Libreville": "Africa/Libreville",
"Africa/Lome": "Africa/Lomé",
"Africa/Luanda": "Africa/Luanda",
"Africa/Lubumbashi": "Africa/Lubumbashi",
"Africa/Lusaka": "Africa/Lusaka",
"Africa/Malabo": "Africa/Malabo",
"Africa/Maputo": "Africa/Maputo",
"Africa/Maseru": "Africa/Maseru",
"Africa/Mbabane": "Africa/Mbabane",
"Africa/Mogadishu": "Africa/Mogadiscio",
"Africa/Monrovia": "Africa/Monrovia",
"Africa/Nairobi": "Africa/Nairobi",
"Africa/Ndjamena": "Africa/Ndjamena",
"Africa/Niamey": "Africa/Niamey",
"Africa/Nouakchott": "Africa/Nouakchott",
"Africa/Ouagadougou": "Africa/Ouagadougou",
"Africa/Porto-Novo": "Africa/Porto-Novo",
"Africa/Sao_Tome": "Africa/Sao_Tomé",
"Africa/Timbuktu": "Africa/Timbuktu",
"Africa/Tripoli": "Africa/Tripoli",
"Africa/Tunis": "Africa/Tunisi",
"Africa/Windhoek": "Africa/Windhoek",
"America/Adak": "America/Adak",
"America/Anchorage": "America/Anchorage",
"America/Anguilla": "America/Anguilla",
"America/Antigua": "America/Antigua",
"America/Araguaina": "America/Araguaina",
"America/Argentina/Buenos_Aires": "America/Argentina/Buenos Aires",
"America/Argentina/Catamarca": "America/Argentina/Catamarca",
"America/Argentina/ComodRivadavia": "America/Argentina/Comodrivadavia",
"America/Argentina/Cordoba": "America/Argentina/Córdoba",
"America/Argentina/Jujuy": "America/Argentina/Jujuy",
"America/Argentina/La_Rioja": "America/Argentina/La_Rioja",
"America/Argentina/Mendoza": "America/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos": "America/Argentina/Rio_Gallegos",
"America/Argentina/Salta": "America/Argentina/Salta",
"America/Argentina/San_Juan": "America/Argentina/San Juan",
"America/Argentina/San_Luis": "America/Argentina/San Luis",
"America/Argentina/Tucuman": "America/Argentina/Tucuman",
"America/Argentina/Ushuaia": "America/Argentina/Ushuaia",
"America/Aruba": "America/Aruba",
"America/Asuncion": "America/Asunción",
"America/Atikokan": "America/Atikokan",
"America/Atka": "America/Atka",
"America/Bahia": "America/Bahia",
"America/Bahia_Banderas": "America/Bahia Banderas",
"America/Barbados": "America/Barbados",
"America/Belem": "America/Belém",
"America/Belize": "America/Belize",
"America/Blanc-Sablon": "America/Blanc-Sablon",
"America/Boa_Vista": "America/Boa_Vista",
"America/Bogota": "America/Bogotà",
"America/Boise": "America/Boise",
"America/Buenos_Aires": "America/Buenos Aires",
"America/Cambridge_Bay": "America/Cambridge_Bay",
"America/Campo_Grande": "America/Campo Grande",
"America/Cancun": "America/Cancun",
"America/Caracas": "America/Caracas",
"America/Catamarca": "America/Catamarca",
"America/Cayenne": "America/Cayenne",
"America/Cayman": "America/Cayman",
"America/Chicago": "America/Chicago",
"America/Chihuahua": "America/Chihuahua",
"America/Ciudad_Juarez": "America/Ciudad_Juárez",
"America/Coral_Harbour": "America/Coral_Harbour",
"America/Cordoba": "America/Córdoba",
"America/Costa_Rica": "America/Costa_Rica",
"America/Creston": "America/Creston",
"America/Cuiaba": "America/Cuiabá",
"America/Curacao": "America/Curacao",
"America/Danmarkshavn": "America/Danmarkshavn",
"America/Dawson": "America/Dawson",
"America/Dawson_Creek": "America/Dawson Creek",
"America/Denver": "America/Denver",
"America/Detroit": "America/Detroit",
"America/Dominica": "America/Dominica",
"America/Edmonton": "America/Edmonton",
"America/Eirunepe": "America/Eirunepé",
"America/El_Salvador": "America/El Salvador",
"America/Ensenada": "America/Ensenada",
"America/Fort_Nelson": "America/Fort Nelson",
"America/Fort_Wayne": "America/Fort Wayne",
"America/Fortaleza": "America/Fortaleza",
"America/Glace_Bay": "America/Glace_Bay",
"America/Godthab": "America/Godthab",
"America/Goose_Bay": "America/Goose Bay",
"America/Grand_Turk": "America/Grand_Turk",
"America/Grenada": "America/Grenada",
"America/Guadeloupe": "America/Guadalupa",
"America/Guatemala": "America/Guatemala",
"America/Guayaquil": "America/Guayaquil",
"America/Guyana": "America/Guyana",
"America/Halifax": "America/Halifax",
"America/Havana": "America/L' Avana",
"America/Hermosillo": "America/Hermosillo",
"America/Indiana/Indianapolis": "America/Indiana/Indianapolis",
"America/Indiana/Knox": "America/Indiana/Knox",
"America/Indiana/Marengo": "America/Indiana/Marengo",
"America/Indiana/Petersburg": "America/Indiana/Petersburg",
"America/Indiana/Tell_City": "America/Indiana/Tell_City",
"America/Indiana/Vevay": "America/Indiana/Vevay",
"America/Indiana/Vincennes": "America/Indiana/Vincennes",
"America/Indiana/Winamac": "America/Indiana/Winamac",
"America/Indianapolis": "America/Indianapolis",
"America/Inuvik": "America/Inuvik",
"America/Iqaluit": "America/Iqaluit",
"America/Jamaica": "America/Giamaica",
"America/Jujuy": "America/Jujuy",
"America/Juneau": "America/Juneau",
"America/Kentucky/Louisville": "America/Kentucky/Louisville",
"America/Kentucky/Monticello": "America/Kentucky/Monticello",
"America/Knox_IN": "America/Knox_in",
"America/Kralendijk": "America/Kralendijk",
"America/La_Paz": "America/La_Paz",
"America/Lima": "America/Lima",
"America/Los_Angeles": "America/Los Angeles",
"America/Louisville": "America/Louisville",
"America/Lower_Princes": "America/Principi_Inferiori",
"America/Maceio": "America/Maceió",
"America/Managua": "America/Managua",
"America/Manaus": "America/Manaus",
"America/Marigot": "America/Marigot",
"America/Martinique": "America/Martinica",
"America/Matamoros": "America/Matamoros",
"America/Mazatlan": "America/Mazatlán",
"America/Mendoza": "America/Mendoza",
"America/Menominee": "America/Menominee",
"America/Merida": "America/Mérida",
"America/Metlakatla": "America/Metlakatla",
"America/Mexico_City": "America/Città del Messico",
"America/Miquelon": "America/Miquelon",
"America/Moncton": "America/Moncton",
"America/Monterrey": "America/Monterrey",
"America/Montevideo": "America/Montevideo",
"America/Montreal": "America/Montréal",
"America/Montserrat": "America/Montserrat",
"America/Nassau": "America/Nassau",
"America/New_York": "America/New York",
"America/Nipigon": "America/Nipigon",
"America/Nome": "America/Nome",
"America/Noronha": "America/Noronha",
"America/North_Dakota/Beulah": "America/Nord_Dakota/Beulah",
"America/North_Dakota/Center": "America/Nord_Dakota/Centro",
"America/North_Dakota/New_Salem": "America/Nord_Dakota/New_Salem",
"America/Nuuk": "America/Nuuk",
"America/Ojinaga": "America/Ojinaga",
"America/Panama": "America/Panama",
"America/Pangnirtung": "America/Pangnirtung",
"America/Paramaribo": "America/Paramaribo",
"America/Phoenix": "America/Phoenix",
"America/Port-au-Prince": "America/Port-au-Prince",
"America/Port_of_Spain": "America/Por_di_Spain",
"America/Porto_Acre": "America/Porto_Acre",
"America/Porto_Velho": "America/Porto_Velho",
"America/Puerto_Rico": "America/Puerto Rico",
"America/Punta_Arenas": "America/Punta Arenas",
"America/Rainy_River": "America/Rainy_River",
"America/Rankin_Inlet": "America/Rankin_Inlet",
"America/Recife": "America/Recife",
"America/Regina": "America/Regina",
"America/Resolute": "America/Resolute",
"America/Rio_Branco": "America/Rio Branco",
"America/Rosario": "America/Rosario",
"America/Santa_Isabel": "America/Santa Isabel",
"America/Santarem": "America/Santarem",
"America/Santiago": "America/Santiago",
"America/Santo_Domingo": "America/Santo Domingo",
"America/Sao_Paulo": "America/San Paolo",
"America/Scoresbysund": "America/Scoresbysund",
"America/Shiprock": "America/Shiprock",
"America/Sitka": "America/Sitka",
"America/St_Barthelemy": "America/Saint-Barthelemy",
"America/St_Johns": "America/Saint Johns",
"America/St_Kitts": "America/Saint Kitts",
"America/St_Lucia": "America/Santa Lucia",
"America/St_Thomas": "America/Saint Thomas",
"America/St_Vincent": "America/Saint Vincent",
"America/Swift_Current": "America/Swift_Current",
"America/Tegucigalpa": "America/Tegucigalpa",
"America/Thule": "America/Thule",
"America/Thunder_Bay": "America/Thunder_Bay",
"America/Tijuana": "America/Tijuana",
"America/Toronto": "America/Toronto",
"America/Tortola": "America/Tortola",
"America/Vancouver": "America/Vancouver",
"America/Virgin": "America/Vergine",
"America/Whitehorse": "America/Whitehorse",
"America/Winnipeg": "America/Winnipeg",
"America/Yakutat": "America/Yakutat",
"America/Yellowknife": "America/Yellowknife",
"Antarctica/Casey": "Antartide/Casey",
"Antarctica/Davis": "Antartide/Davis",
"Antarctica/DumontDUrville": "Antartide/DumontDurville",
"Antarctica/Macquarie": "Antartide/Macquarie",
"Antarctica/Mawson": "Antartide/Mawson",
"Antarctica/McMurdo": "Antartide/McMurdo",
"Antarctica/Palmer": "Antartide/Palmer",
"Antarctica/Rothera": "Antartide/Rothera",
"Antarctica/South_Pole": "Antartica/Polo Sud",
"Antarctica/Syowa": "Antartide/Syowa",
"Antarctica/Troll": "Antartide/Troll",
"Antarctica/Vostok": "Antartide/Vostok",
"Arctic/Longyearbyen": "Artico/Longyearbyen",
"Asia/Aden": "Asia/Aden",
"Asia/Almaty": "Asia/Almaty",
"Asia/Amman": "Asia/Amman",
"Asia/Anadyr": "Asia/Anadyr",
"Asia/Aqtau": "Asia/Aqtau",
"Asia/Aqtobe": "Asia/Aqtobe",
"Asia/Ashgabat": "Asia/Ashgabat",
"Asia/Ashkhabad": "Asia/Ashkhabad",
"Asia/Atyrau": "Asia/Atyrau",
"Asia/Baghdad": "Asia/Baghdad",
"Asia/Bahrain": "Asia/Bahrein",
"Asia/Baku": "Asia/Baku",
"Asia/Bangkok": "Asia/Bangkok",
"Asia/Barnaul": "Asia/Barnaul",
"Asia/Beirut": "Asia/Beirut",
"Asia/Bishkek": "Asia/Bishkek",
"Asia/Brunei": "Asia/Brunei",
"Asia/Calcutta": "Asia/Calcutta",
"Asia/Chita": "Asia/Chita",
"Asia/Choibalsan": "Asia/Choibalsan",
"Asia/Chongqing": "Asia/Chongqing",
"Asia/Chungking": "Asia/Chungking",
"Asia/Colombo": "Asia/Colombo",
"Asia/Dacca": "Asia/Dacca",
"Asia/Damascus": "Asia/Damasco",
"Asia/Dhaka": "Asia/Dacca",
"Asia/Dili": "Asia/Dili",
"Asia/Dubai": "Asia/Dubai",
"Asia/Dushanbe": "Asia/Dušanbe",
"Asia/Famagusta": "Asia/Famagosta",
"Asia/Gaza": "Asia/Gaza",
"Asia/Harbin": "Asia/Harbin",
"Asia/Hebron": "Asia/Hebron",
"Asia/Ho_Chi_Minh": "Asia/Ho Chi Minh",
"Asia/Hong_Kong": "Asia/Hong_Kong",
"Asia/Hovd": "Asia/Hovd",
"Asia/Irkutsk": "Asia/Irkutsk",
"Asia/Istanbul": "Asia/Istanbul",
"Asia/Jakarta": "Asia/Giacarta",
"Asia/Jayapura": "Asia/Jayapura",
"Asia/Jerusalem": "Asia/Gerusalemme",
"Asia/Kabul": "Asia/Kabul",
"Asia/Kamchatka": "Asia/Kamchatka",
"Asia/Karachi": "Asia/Karachi",
"Asia/Kashgar": "Asia/Kashgar",
"Asia/Kathmandu": "Asia/Katmandu",
"Asia/Katmandu": "Asia/Katmandu",
"Asia/Khandyga": "Asia/Khandyga",
"Asia/Kolkata": "Asia/Calcutta",
"Asia/Krasnoyarsk": "Asia/Krasnojarsk",
"Asia/Kuala_Lumpur": "Asia/Kuala Lumpur",
"Asia/Kuching": "Asia/Kuching",
"Asia/Kuwait": "Asia/Kuwait",
"Asia/Macao": "Asia/Macao",
"Asia/Macau": "Asia/Macao",
"Asia/Magadan": "Asia/Magadan",
"Asia/Makassar": "Asia/Makassar",
"Asia/Manila": "Asia/Manila",
"Asia/Muscat": "Asia/Muscat",
"Asia/Nicosia": "Asia/Nicosia",
"Asia/Novokuznetsk": "Asia/Novokuznetsk",
"Asia/Novosibirsk": "Asia/Novosibirsk",
"Asia/Omsk": "Asia/Omsk",
"Asia/Oral": "Asia/Orale",
"Asia/Phnom_Penh": "Asia/Phnom Penh",
"Asia/Pontianak": "Asia/Pontianak",
"Asia/Pyongyang": "Asia/Pyongyang",
"Asia/Qatar": "Asia/Qatar",
"Asia/Qostanay": "Asia/Qostanai",
"Asia/Qyzylorda": "Asia/Qyzylorda",
"Asia/Rangoon": "Asia/Rangoon",
"Asia/Riyadh": "Asia/Riyad",
"Asia/Saigon": "Asia/Saigon",
"Asia/Sakhalin": "Asia/Sakhalin",
"Asia/Samarkand": "Asia/Samarcanda",
"Asia/Seoul": "Asia/Seoul",
"Asia/Shanghai": "Asia/Shanghai",
"Asia/Singapore": "Asia/Singapore",
"Asia/Srednekolymsk": "Asia/Srednekolymsk",
"Asia/Taipei": "Asia/Taipei",
"Asia/Tashkent": "Asia/Taskent",
"Asia/Tbilisi": "Asia/Tbilisi",
"Asia/Tehran": "Asia/Teheran",
"Asia/Tel_Aviv": "Asia/Tel Aviv",
"Asia/Thimbu": "Asia/Thimbu",
"Asia/Thimphu": "Asia/Thimphu",
"Asia/Tokyo": "Asia/Tokyo",
"Asia/Tomsk": "Asia/Tomsk",
"Asia/Ujung_Pandang": "Asia/Ujung_Pandang",
"Asia/Ulaanbaatar": "Asia/Ulan Bator",
"Asia/Ulan_Bator": "Asia/Ulan_Bator",
"Asia/Urumqi": "Asia/Ürümqi",
"Asia/Ust-Nera": "Asia/Ust-Nera",
"Asia/Vientiane": "Asia/Vientiane",
"Asia/Vladivostok": "Asia/Vladivostok",
"Asia/Yakutsk": "Asia/Yakutsk",
"Asia/Yangon": "Asia/Yangon",
"Asia/Yekaterinburg": "Asia/Ekaterinburg",
"Asia/Yerevan": "Asia/Yerevan",
"Atlantic/Azores": "Atlantico/Azzorre",
"Atlantic/Bermuda": "Atlantico/Bermuda",
"Atlantic/Canary": "Atlantico/Canarie",
"Atlantic/Cape_Verde": "Atlantico/Capo Verde",
"Atlantic/Faeroe": "Atlantico/Færøer",
"Atlantic/Faroe": "Atlantico/Faroe",
"Atlantic/Jan_Mayen": "Atlantico/Jan_Mayen",
"Atlantic/Madeira": "Atlantico/Madeira",
"Atlantic/Reykjavik": "Atlantico/Reykjavik",
"Atlantic/South_Georgia": "Atlantico/Georgia del Sud",
"Atlantic/St_Helena": "Atlantic/Sant' Elena",
"Atlantic/Stanley": "Atlantico/Stanley",
"Australia/ACT": "Australia/ACT",
"Australia/Adelaide": "Australia/Adelaide",
"Australia/Brisbane": "Australia/Brisbane",
"Australia/Broken_Hill": "Australia/Broken Hill",
"Australia/Canberra": "Australia/Canberra",
"Australia/Currie": "Australia/Currie",
"Australia/Darwin": "Australia/Darwin",
"Australia/Eucla": "Australia/Eucla",
"Australia/Hobart": "Australia/Hobart",
"Australia/LHI": "Australia/LHI",
"Australia/Lindeman": "Australia/Lindeman",
"Australia/Lord_Howe": "Australia/Lord Howe",
"Australia/Melbourne": "Australia/Melbourne",
"Australia/NSW": "Australia/NSW",
"Australia/North": "Australia/Nord",
"Australia/Perth": "Australia/Perth",
"Australia/Queensland": "Australia/Queensland",
"Australia/South": "Australia/Sud",
"Australia/Sydney": "Australia/Sydney",
"Australia/Tasmania": "Australia/Tasmania",
"Australia/Victoria": "Australia/Victoria",
"Australia/West": "Australia/Ovest",
"Australia/Yancowinna": "Australia/Yancowinna",
"Brazil/Acre": "Brasile/Acre",
"Brazil/DeNoronha": "Brasile/Denoronha",
"Brazil/East": "Brasile/Est",
"Brazil/West": "Brasile/Ovest",
"CET": "CETINA",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Canada/Atlantico",
"Canada/Central": "Canada/Central",
"Canada/Eastern": "Canada/Est",
"Canada/Mountain": "Canada/Montagna",
"Canada/Newfoundland": "Canada/Terranova",
"Canada/Pacific": "Canada/Pacifico",
"Canada/Saskatchewan": "Canada/Saskatchewan",
"Canada/Yukon": "Canada/Yukon",
"Chile/Continental": "Cile/Continental",
"Chile/EasterIsland": "Cile/Isola di Pasqua",
"Cuba": "Cuba",
"EET": "PIEDI",
"EST": "EST",
"EST5EDT": "EST 5EDT",
"Egypt": "Egitto",
"Eire": "Irlanda",
"Etc/GMT": "ETC/GMT",
"Etc/GMT+0": "ETC/GMT+0",
"Etc/GMT+1": "ETC/GMT+1",
"Etc/GMT+10": "ETC/GMT+10",
"Etc/GMT+11": "ETC/GMT+11",
"Etc/GMT+12": "ETC/GMT+12",
"Etc/GMT+2": "ETC/GMT+2",
"Etc/GMT+3": "ETC/GMT+3",
"Etc/GMT+4": "ETC/GMT+4",
"Etc/GMT+5": "ETC/GMT+5",
"Etc/GMT+6": "ECC/GMT+6",
"Etc/GMT+7": "ETC/GMT+7",
"Etc/GMT+8": "ETC/GMT+8",
"Etc/GMT+9": "ETC/GMT+9",
"Etc/GMT-0": "ECC/GMT-0",
"Etc/GMT-1": "ECC/GMT-1",
"Etc/GMT-10": "ETC/GMT-10",
"Etc/GMT-11": "ECC/GMT-11",
"Etc/GMT-12": "ETC/GMT-12",
"Etc/GMT-13": "ETC/GMT-13",
"Etc/GMT-14": "ETC/GMT-14",
"Etc/GMT-2": "ETC/GMT-2",
"Etc/GMT-3": "ECC/GMT-3",
"Etc/GMT-4": "ECC/GMT-4",
"Etc/GMT-5": "ETC/GMT-5",
"Etc/GMT-6": "ECC/GMT-6",
"Etc/GMT-7": "ETC/GMT-7",
"Etc/GMT-8": "ECC/GMT-8",
"Etc/GMT-9": "ECC/GMT-9",
"Etc/GMT0": "ETC/GMT0",
"Etc/Greenwich": "Ec/Greenwich",
"Etc/UCT": "ETC/UCT",
"Etc/UTC": "Etc/UTC",
"Etc/Universal": "Ec/Universal",
"Etc/Zulu": "Ec/Zulu",
"Europe/Amsterdam": "Europa/Amsterdam",
"Europe/Andorra": "Europa/Andorra",
"Europe/Astrakhan": "Europa/Astrakhan",
"Europe/Athens": "Europa/Atene",
"Europe/Belfast": "Europa/Belfast",
"Europe/Belgrade": "Europa/Belgrado",
"Europe/Berlin": "Europa/Berlino",
"Europe/Bratislava": "Europa/Bratislava",
"Europe/Brussels": "Europa/Bruxelles",
"Europe/Bucharest": "Europa/Bucarest",
"Europe/Budapest": "Europa/Budapest",
"Europe/Busingen": "Europa/Busingen",
"Europe/Chisinau": "Europa/Chisinau",
"Europe/Copenhagen": "Europa/Copenhagen",
"Europe/Dublin": "Europa/Dublino",
"Europe/Gibraltar": "Europa/Gibilterra",
"Europe/Guernsey": "Europa/Guernsey",
"Europe/Helsinki": "Europa/Helsinki",
"Europe/Isle_of_Man": "Europa/Isle_of_Man",
"Europe/Istanbul": "Europa/Istanbul",
"Europe/Jersey": "Europa/Jersey",
"Europe/Kaliningrad": "Europa/Kaliningrad",
"Europe/Kiev": "Europa/Kiev",
"Europe/Kirov": "Europa/Kirov",
"Europe/Kyiv": "Europa/Kiev",
"Europe/Lisbon": "Europa/Lisbona",
"Europe/Ljubljana": "Europa/Lubiana",
"Europe/London": "Europa/Londra",
"Europe/Luxembourg": "Europa/Lussemburgo",
"Europe/Madrid": "Europa/Madrid",
"Europe/Malta": "Europa/Malta",
"Europe/Mariehamn": "Europa/Mariehamn",
"Europe/Minsk": "Europa/Minsk",
"Europe/Monaco": "Europa/Monaco",
"Europe/Moscow": "Europa/Mosca",
"Europe/Nicosia": "Europa/Nicosia",
"Europe/Oslo": "Europa/Oslo",
"Europe/Paris": "Europa/Parigi",
"Europe/Podgorica": "Europa/Podgorica",
"Europe/Prague": "Europa/Praga",
"Europe/Riga": "Europa/Riga",
"Europe/Rome": "Europa/Roma",
"Europe/Samara": "Europa/Samara",
"Europe/San_Marino": "Europa/San_Marino",
"Europe/Sarajevo": "Europa/Sarajevo",
"Europe/Saratov": "Europa/Saratov",
"Europe/Simferopol": "Europa/Simferopol",
"Europe/Skopje": "Europa/Skopje",
"Europe/Sofia": "Europa/Sofia",
"Europe/Stockholm": "Europa/Stoccolma",
"Europe/Tallinn": "Europa/Tallinn",
"Europe/Tirane": "Europa/Tirana",
"Europe/Tiraspol": "Europa/Tiraspol",
"Europe/Ulyanovsk": "Europa/Ulyanovsk",
"Europe/Uzhgorod": "Europa/Uzhgorod",
"Europe/Vaduz": "Europa/Vaduz",
"Europe/Vatican": "Europa/Vaticano",
"Europe/Vienna": "Europa/Vienna",
"Europe/Vilnius": "Europa/Vilnius",
"Europe/Volgograd": "Europa/Volgograd",
"Europe/Warsaw": "Europa/Varsavia",
"Europe/Zagreb": "Europa/Zagabria",
"Europe/Zaporozhye": "Europa/Zaporozhye",
"Europe/Zurich": "Europa/Zurigo",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT 0",
"Greenwich": "Greenwich",
"HST": "OSPITE",
"Hongkong": "Hong Kong",
"Iceland": "Islanda",
"Indian/Antananarivo": "Indiano/Antananarivo",
"Indian/Chagos": "Indiano/Chagos",
"Indian/Christmas": "Indiano/Natale",
"Indian/Cocos": "Indiano/Cocos",
"Indian/Comoro": "Indiano/Comore",
"Indian/Kerguelen": "Indiano/Kerguelen",
"Indian/Mahe": "Indiano/Mahé",
"Indian/Maldives": "Indiano/Maldive",
"Indian/Mauritius": "Indiano/Mauritius",
"Indian/Mayotte": "Indiano/Mayotte",
"Indian/Reunion": "Indiano/Reunion",
"Iran": "Iran",
"Israel": "Israele",
"Jamaica": "Giamaica",
"Japan": "Giappone",
"Kwajalein": "Kwajalein",
"Libya": "Libia",
"MET": "INCONTRATO",
"MST": "MOSTO",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Messico/Bajanorte",
"Mexico/BajaSur": "Messico/Bajasur",
"Mexico/General": "Messico/Generale",
"NZ": "NEOZELANDESE",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Pacifico/Apia",
"Pacific/Auckland": "Pacifico/Auckland",
"Pacific/Bougainville": "Pacifico/Bougainville",
"Pacific/Chatham": "Pacifico/Chatham",
"Pacific/Chuuk": "Pacifico/Chuuk",
"Pacific/Easter": "Pacifico/Pasqua",
"Pacific/Efate": "Pacifico/Efate",
"Pacific/Enderbury": "Pacifico/Enderbury",
"Pacific/Fakaofo": "Pacifico/Fakaofo",
"Pacific/Fiji": "Pacifico/Figi",
"Pacific/Funafuti": "Pacifico/Funafuti",
"Pacific/Galapagos": "Pacifico/Galapagos",
"Pacific/Gambier": "Pacifico/Gambier",
"Pacific/Guadalcanal": "Pacifico/Guadalcanal",
"Pacific/Guam": "Pacifico/Guam",
"Pacific/Honolulu": "Pacifico/Honolulu",
"Pacific/Johnston": "Pacifico/Johnston",
"Pacific/Kanton": "Pacific/Kanton",
"Pacific/Kiritimati": "Pacifico/Kiritimati",
"Pacific/Kosrae": "Pacifico/Kosrae",
"Pacific/Kwajalein": "Pacifico/Kwajalein",
"Pacific/Majuro": "Pacifico/Majuro",
"Pacific/Marquesas": "Pacifico/Marchesi",
"Pacific/Midway": "Pacifico/Midway",
"Pacific/Nauru": "Pacifico/Nauru",
"Pacific/Niue": "Pacifico/Niue",
"Pacific/Norfolk": "Pacifico/Norfolk",
"Pacific/Noumea": "Pacifico/Noumea",
"Pacific/Pago_Pago": "Pacifico/Pago_Pago",
"Pacific/Palau": "Pacifico/Palau",
"Pacific/Pitcairn": "Pacifico/Pitcairn",
"Pacific/Pohnpei": "Pacifico/Pohnpei",
"Pacific/Ponape": "Pacifico/Ponape",
"Pacific/Port_Moresby": "Pacifico/Port_Moresby",
"Pacific/Rarotonga": "Pacifico/Rarotonga",
"Pacific/Saipan": "Pacifico/Saipan",
"Pacific/Samoa": "Pacifico/Samoa",
"Pacific/Tahiti": "Pacifico/Tahiti",
"Pacific/Tarawa": "Pacifico/Tarawa",
"Pacific/Tongatapu": "Pacifico/Tongatapu",
"Pacific/Truk": "Pacifico/Truk",
"Pacific/Wake": "Pacifico/Wake",
"Pacific/Wallis": "Pacifico/Wallis",
"Pacific/Yap": "Pacifico/Yap",
"Poland": "Polonia",
"Portugal": "Portogallo",
"ROC": "ROCCIA",
"ROK": "ROCCIA",
"Singapore": "Singapore",
"Turkey": "Turchia",
"UCT": "TAGLIA",
"US/Alaska": "Stati Uniti/Alaska",
"US/Aleutian": "Stati Uniti/Aleutine",
"US/Arizona": "Stati Uniti/Arizona",
"US/Central": "Stati Uniti/Central",
"US/East-Indiana": "Stati Uniti/Indiana orientale",
"US/Eastern": "Stati Uniti/Est",
"US/Hawaii": "Stati Uniti/Hawaii",
"US/Indiana-Starke": "U.S.A. /Indiana Starke",
"US/Michigan": "Stati Uniti/Michigan",
"US/Mountain": "Stati Uniti/Montagna",
"US/Pacific": "Stati Uniti/Pacifico",
"US/Samoa": "Stati Uniti/Samoa",
"UTC": "UTC",
"Universal": "Universale",
"W-SU": "W-SU",
"WET": "BAGNATO",
"Zulu": "Zulù",
}