import { getActiveStudy } from '../selectors/study'
import createActionType from '../utils/action'
import _ from 'lodash'
export const SUBJECTS_RETRIEVE_SUCCESS = createActionType('SUBJECTS_RETRIEVE_SUCCESS')
export const UPDATE_SELECTED_SUBJECT = createActionType('UPDATE_SELECTED_SUBJECT')
export const SUBJECTS_RETRIEVE_REQUEST = createActionType('SUBJECTS_RETRIEVE_REQUEST')
export const SUBJECTS_RETRIEVE_FAILURE = createActionType('SUBJECTS_RETRIEVE_FAILURE')
export const UPDATE_SUBJECT_SEARCH_TERM = createActionType('UPDATE_SUBJECT_SEARCH_TERM')

import api from '../utils/api'
import { getOfflineDataSuccess } from './subjectStudyMetaData'
import { updateSession } from './users'

export const updateSubjects = (data, userId) => ({
  type: SUBJECTS_RETRIEVE_SUCCESS,
  subjects: data,
  userId,
})

export const subjectsRetrieveRequest = () => ({
  type: SUBJECTS_RETRIEVE_REQUEST,
})
export const subjectsRetrieveFailure = (userId) => ({
  type: SUBJECTS_RETRIEVE_FAILURE,
  userId,
})

export const retrieveSubjects = (siteId) => async (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
    study,
    site,
  } = getState()
  dispatch(subjectsRetrieveRequest())
  try {
    const res = await api.get(
      `/${site.primaryOrganizationCode}/studies/${study.selectedStudyId}/sites/${siteId}/subjects`
    )
    dispatch(updateSubjects(res.data, id))
  } catch (error) {
    console.log(error)
    dispatch(subjectsRetrieveFailure(id))
    throw error
  }
}

export const updateSubjectSearchTerm = (searchTermSubject) => (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  dispatch({
    type: UPDATE_SUBJECT_SEARCH_TERM,
    searchTermSubject: searchTermSubject,
    userId: id,
  })
}

export const updateSubjectInStore = (subjectId) => ({
  type: UPDATE_SELECTED_SUBJECT,
  subjectId: subjectId,
})

export const updateSelectedSubject = (subject) => async (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
    users,
  } = getState()

  const user = users[id]
  const sessionInfo = {
    ...user.sessionInfo,
    subjectId: subject ? subject.id : null,
  }

  dispatch(updateSession(sessionInfo))
  const activeStudy = getActiveStudy(getState())
  const subjectMetaData = {
    subject,
    subjectVisit: subject ? _.head(subject.subjectVisits) : null,
    study: activeStudy,
    subjectPrivileges: [],
  }
  dispatch(getOfflineDataSuccess(subjectMetaData))
  dispatch(updateSubjectInStore(subject ? subject.id : null))
}
