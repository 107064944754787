import _ from 'lodash'
import { ADD_ITEM, UPDATE_ITEMS } from '../actions/offlineData'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

export default (state = initialState.offlineData, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        data: [...state.data, action.item],
      }
    case UPDATE_ITEMS:
      return {
        ...state,
        data: [...action.data],
      }
    case PURGE:
      return {
        data: [],
      }
    default:
      return state
  }
}
