import { ScrollView, Text, View } from 'react-native'
import React, { Component } from 'react'
import {
  Container,
  Header,
  Button,
  Title,
  Content,
  List,
  ListItem,
  Icon,
  Left,
  Body,
  Right,
  Switch,
  Card,
} from 'native-base'
import moment from 'moment'
import Constants from 'expo-constants'

export default class VersionInfo extends Component {
  // static navigationOptions = ({ navigation, screenProps: { t } }) => ({
  //   title: navigation.state.params ? navigation.state.params.title : t('VersionInfo'),
  // })

  render() {
    return (
      <Container
        style={{ flex: 1, justifyContent: 'space-evenly', fontFamily: 'Inter' }}
        testID={'version-info-Container'}
        accessible={true}
      >
        <ScrollView>
          <Card>
            <ListItem
              style={{
                backgroundColor: '#efefef',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text>App Version No </Text>
              </Left>
              <Text>{Constants.expoConfig.version}</Text>
            </ListItem>
            <ListItem
              style={{
                backgroundColor: '#ffffff',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text style={{ fontFamily: 'Inter' }}>SDK Version</Text>
              </Left>
              <Text>{Constants?.manifest?.sdkVersion}</Text>
            </ListItem>
            <ListItem
              style={{
                backgroundColor: '#efefef',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text style={{ fontFamily: 'Inter' }}>Slug</Text>
              </Left>
              <Text>{Constants.manifest.slug}</Text>
            </ListItem>
            <ListItem
              style={{
                backgroundColor: '#ffffff',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text style={{ fontFamily: 'Inter' }}>Revision Id</Text>
              </Left>
              <Text style={{ fontFamily: 'Inter' }}>{Constants.manifest.revisionId}</Text>
            </ListItem>
            <ListItem
              style={{
                backgroundColor: '#efefef',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text style={{ fontFamily: 'Inter' }}>Publish Time</Text>
              </Left>
              <Text style={{ fontFamily: 'Inter' }}>
                {moment(Constants.manifest.publishedTime).format('DD/MM/YYYY hh:mm:ss a')}
              </Text>
            </ListItem>
            <ListItem
              style={{
                backgroundColor: '#ffffff',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text style={{ fontFamily: 'Inter' }}>Release Channel</Text>
              </Left>
              <Text style={{ fontFamily: 'Inter' }}>{Constants.manifest.releaseChannel}</Text>
            </ListItem>
            <ListItem
              style={{
                backgroundColor: '#ffffff',
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomWidth: 0,
                marginLeft: 0,
                fontFamily: 'Inter',
              }}
            >
              <Left>
                <Text style={{ fontFamily: 'Inter' }}>Release Channel</Text>
              </Left>
              <Text style={{ fontFamily: 'Inter' }}>{Constants.manifest.currentFullName}</Text>
            </ListItem>
          </Card>
        </ScrollView>
      </Container>
    )
  }
}
