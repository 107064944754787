import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api'
import constants from '../constants/constants'
import { getMetaData, updateSvfStatus } from './metaData'
import { retrieveFormsIfNotExist } from './form'
import moment from 'moment'
import { storage } from '../utils/storage'
import { getDeviceStatus } from './users'
import { updateOnMobileStatus } from './storeAppStatus'

export const PUSH_FORM_DATA_TO_QUEUE = createActionType('PUSH_FORM_DATA_TO_QUEUE')
export const STORE_SYNC_QUEUE_DATA = createActionType('STORE_SYNC_QUEUE_DATA')
export const UPDATE_FORM_STATUS_IN_QUEUE = createActionType('UPDATE_FORM_STATUS_IN_QUEUE')
export const REMOVE_FROM_QUEUE = createActionType('REMOVE_FROM_QUEUE')

const { ContextProperties: {PRIMARY_ORG_CODE, STUDY_ID, SITE_ID} } = constants;
export const pushFormDataToQueue =
  (svfList, subjectId, completedOn, crfVersionId, subjectVisitId) => async (dispatch, getState) => {
    const currentUserId = getState().users.currentUser?.id
    const url = `/${PRIMARY_ORG_CODE}/studies/${STUDY_ID}/crfVersions/${crfVersionId}/sites/${SITE_ID}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms`
    const currentItem = {
      url: url,
      data: svfList,
      method: 'POST',
      headers: null,
      isActive: true,
      status: constants.OfflineDataStatus.ACTIVE,
      submittedOn: completedOn,
      attempts: 0,
    }
    dispatch({
      type: PUSH_FORM_DATA_TO_QUEUE,
      queueItem: currentItem,
      currentUserId: currentUserId,
    })
  }

const updateStatus = (key, status, currentUserId) => ({
  type: UPDATE_FORM_STATUS_IN_QUEUE,
  key,
  status,
  currentUserId
  // attempts
})

const removeFromQueue = (key, currentUserId) => ({
  type: REMOVE_FROM_QUEUE,
  key,
  currentUserId
})
export const processQueue = () => async (dispatch, getState) => {
  const { syncQueue, subjectStudyMetaData: { subject }, users } = getState()
  
  const keys  = !_.isEmpty(syncQueue.data[users.currentUser?.id]) ? Object.keys(syncQueue.data[users.currentUser?.id]): [];
  for (const key of keys ) {
    const item = syncQueue.data[users.currentUser?.id][key];
    if (constants.OfflineDataStatus.ACTIVE === item.status && getDeviceStatus()) {
      // const attempts = item.attempts + 1
      console.log('svf processing' + item.data[0].id + ' ' + item.status)
      await processSubmission(key,item,subject,dispatch, users.currentUser.id)
    }
  }
 
}

const processSubmission = async (key,item,subject,dispatch, currentUserId) => {
  try {
    dispatch(updateStatus(key, constants.OfflineDataStatus.IN_PROGRESS, currentUserId))
    const res = await invokeApiEndPoint(item)
    if (res.status === 200 || res.status === 201) {
      console.log('svf success' + item.data[0].id + ' ' + item.status)
      dispatch(updateStatus(key, constants.OfflineDataStatus.SUCCESS, currentUserId))
      updateSvfBasedOnSyncStatus(dispatch, item)
      dispatch(removeFromQueue(key, currentUserId))
      refreshMetaData(dispatch, subject);
    }else{
      dispatch(updateStatus(key, constants.OfflineDataStatus.ACTIVE, currentUserId))
    }
    console.log('svf outside' + item.data[0].id + ' ' + item.status)
  } catch (error) {
    console.log(error)
    console.log('svf failed' + item.data[0].id + ' ' + item.status)
    if(item.data?.[0]?.id){
      dispatch(updateStatus(key, constants.OfflineDataStatus.ACTIVE, currentUserId))
    }
  }
}
const invokeApiEndPoint = (item) => {
  return api({
    method: item.method,
    url: item.url,
    data: item.data,
    headers: item.headers,
  })
}

const refreshMetaData = async (dispatch,subject) => {
 try {
  await dispatch(getMetaData(subject.id, moment(subject.lastUpdatedDateOn).utc(), false))
  dispatch(retrieveFormsIfNotExist())
 } catch (error) {
    console.log("Failed to refresh meta data", error)
 }
}

export const storeSyncQueueData = (data) => ({
  type: STORE_SYNC_QUEUE_DATA,
  data: data
})

export const clearStorageAndStoreSyncQueue = () => async (dispatch, getState) => {
  const loSyncQueueData = getState().syncQueue.data
  const onMobile = getState().appStatus?.onMobile
  storage.clear()
  dispatch(updateOnMobileStatus(onMobile))
  dispatch(storeSyncQueueData(loSyncQueueData))
}

export const updateSvfBasedOnSyncStatus = (dispatch,item) => {
  try {
    _.forEach(item.data, (d) => {
      dispatch(
        updateSvfStatus(
          d.id,
          d.subjectVisit.id,
          d.status === 'COMPLETED' ? constants.OfflineDataStatus.SUCCESS : d.status,
          item.submittedOn,
          d.status
        )
      )
    })
  } catch (e){
    console.log("Failed to update svf status", e)
  }
}