import React from "react";
import { getDeviceStatus } from "../../actions/users";
import { StyleSheet, View } from "react-native";
import { useSelector } from 'react-redux'
import { isAllPreviewImagesLoaded, isSvfClickableImagesDownloaded } from "../../selectors/field";
import { Text } from "react-native";

export const styles = StyleSheet.create({
    errorMsgContainer: {
        flex: 1,
        height: 400,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    errorText: {
        color: '#546e7a', fontFamily: 'Inter', fontSize: 16, textAlign: 'center'
    }
})

const DiaryDisplay = (props) => {
    const isImagesDownloaded = useSelector((state) => isSvfClickableImagesDownloaded(state))
    const {preview, t} = props
 
    //Showing error message when the selected Form's clickable images not downloaded and device is in offline
    //Else showing FormConsumer
    if(preview){
        return props.children
    }else if(!isImagesDownloaded && !getDeviceStatus()){
        return(
            <View style={styles.errorMsgContainer}>
                <Text style={styles.errorText}>{t('DownloadImageFailedDeviceOffline')}</Text>
            </View>
        )
    }else{
        return props.children
    }
}

export default DiaryDisplay