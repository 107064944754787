import * as Notifications from 'expo-notifications'
import { handleNotifications, handleFailedNotifications } from '../notifications/syncNotifications'
import * as TaskManager from 'expo-task-manager'

const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK'

export const setNotificationChannel = () => {
  Notifications.setNotificationChannelAsync('diary-froms', {
    name: 'Dairy notifications',
    importance: Notifications.AndroidImportance.HIGH,
    sound: 'polite.wav', // <- for Android 8.0+, see channelId property below
  })

  Notifications.setNotificationHandler({
    handleNotification: async (notification) => {
      handleNotifications(notification, 'SCHEDULED')
      return {
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
        priority: 'MAX',
      }
    },
    handleSuccess: async (notificationId) => {
      console.log('notification recieved successfully')
      console.log(notificationId)
    },
    handleError: async (notificationId) => {
      console.log('notification recieve failed')
      console.log(notificationId)
      handleFailedNotifications(notificationId)
    },
  })
}

export const scheduleNotification = (notificationInput) => {
  notificationInput = notificationInput.content.sound = 'polite.wav'
  Notifications.scheduleNotificationAsync(notificationInput)
}

export const cancelNotifications = () => {
  Notifications.cancelAllScheduledNotificationsAsync()
}

TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data, error, executionInfo }) => {
  console.log('Received a notification in the background!')
  console.log(data)
  handleNotifications(data, 'SCHEDULED')
})

// Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK)

// First, set the handler that will cause the notification
// to show the alert

// export default () => {
//     console.log("local notify")

//     Notifications.setNotificationChannelAsync('diary-froms', {
//         name: 'Dairy notifications',
//         importance: Notifications.AndroidImportance.HIGH,
//         // sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
//       });

//     Notifications.setNotificationHandler({
//         handleNotification: async () => ({
//           shouldShowAlert: true,
//           shouldPlaySound: true,
//           shouldSetBadge: true,
//           priority: 'MAX'
//         }),
//       });

//       // Second, call the method

//       Notifications.scheduleNotificationAsync({
//           content: {
//             title: "Time's up!",
//             body: 'Change sides!',
//           },
//           trigger: {
//             seconds: 30,
//             repeats: true,
//             // channelId: 'diary-froms',
//           },
//         });

//         // Notifications.scheduleNotificationAsync({
//         //     content: {
//         //       title: "Please fill the form",
//         //       body: 'Change sides!',
//         //     },
//         //     trigger: {
//         //       seconds: 90,
//         //       repeats: true
//         //     },
//         //   });
// }
