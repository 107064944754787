import React from "react"
import { PixelRatio, StyleSheet, View } from "react-native"
import HorizontalScale from "./BasicVASScale"
import HorizontalLabels from "./HorizontalLabels"
import { alignments } from "../../scales/alignments"
import _ from 'lodash'
import PropTypes from 'prop-types'
import constants from "./constants"
import { fontScale } from "../../styles/fontResizer"

const {SHOW_MARKS} = constants

const VASHorizontalScaleContainer = (props) => {
    const {step, min, max,  disabled, id, value, isPercentage, indicationConfig, showMarks, orientation, options, onValueChange, scaleProps, fontConfig, minimumFontSize, labelsData, isCalculated} = props
    return (
        <View style={styles.container}>
          {isCalculated && (  <HorizontalLabels 
                    labels = {_.filter(options, op => op.labelPlacement === 'TOP')}
                    start={min}
                    end={max}
                    step={step}
                    orientation={orientation}
                    disabled={disabled}
                    id={id}
                    scaleProps={scaleProps}
                    minimumFontSize = {minimumFontSize}
                    labelsData={labelsData}
            />)}
            <View style={styles.scaleContainer}>
                <HorizontalScale 
                 min={min}
                 max={max}
                 step={step}
                 value={value}
                 disabled={disabled} 
                 id={id}
                 isPercentage={isPercentage}
                 onValueChange={onValueChange}
                 showMarks={showMarks}
                 indicationConfig={indicationConfig}
                 orientation={orientation}
                 scaleProps={scaleProps}
                 fontConfig={fontConfig}
                />
            </View>
            {isCalculated && ( <HorizontalLabels 
                    labels = {_.filter(options, op => op.labelPlacement === 'BOTTOM')}
                    start={min}
                    end={max}
                    step={step}
                    orientation={orientation}
                    disabled={disabled}
                    id={id}
                    scaleProps={scaleProps}
                    minimumFontSize = {minimumFontSize}
                    labelsData={labelsData}
            />)}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    scaleContainer: {
        marginTop: 5,
        marginBottom: 15,
    }

});


VASHorizontalScaleContainer.defaultProps = {
    step: 1,
    min: 0,
    max: 10,
    onValueChange: ()=> null,
    id: null,
    disabled: false,
    value: null,
    isPercentage: false,
    indicationConfig: {
        indicationMultiple: null,
        indicationNumbers: null
    },
    showMarks: SHOW_MARKS.NO_MARKS,
    orientation: alignments.HORIZONTAL,  
    options: [],
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: 10/(PixelRatio.getFontScale()),
        scale: 1,
      },
      fontConfig: {
        requiredFontForMarks: 10/PixelRatio.getFontScale(),
        numberOfTerms: 0,
      },
    labelsData: {}, 
    minimumFontSize: fontScale(10),
    isCalculated: false,
  }
  
  VASHorizontalScaleContainer.propTypes = {
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    isPercentage: PropTypes.bool,
    indicationConfig: PropTypes.shape({
        indicationMultiple: PropTypes.number,
        indicationNumbers: PropTypes.string,
    }),
    showMarks: PropTypes.string,
    orientation: PropTypes.string,    
    id: PropTypes.string,
    options: PropTypes.arrayOf(Object),
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
      }),
    fontConfig: PropTypes.shape({
        requiredFontForMarks: PropTypes.number,
        numberOfTerms: PropTypes.number,
      }),
    labelsData: PropTypes.instanceOf(Object), 
    minimumFontSize: PropTypes.number,
    isCalculated: PropTypes.bool,
}

export default VASHorizontalScaleContainer