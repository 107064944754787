import {
  FORM_FIELD_LIST_BY_FORM_ID_FAILURE,
  FORM_FIELD_LIST_BY_FORM_ID_REQUEST,
  FORM_FIELD_LIST_BY_FORM_ID_SUCCESS,
  FORM_SVF_DATA_BY_FORM_ID_FAILURE,
  FORM_SVF_DATA_BY_FORM_ID_REQUEST,
  FORM_SVF_DATA_BY_FORM_ID_SUCCESS,
  RETRIEVE_FORMS_WITH_FIELDS_FAILURE,
  RETRIEVE_FORMS_WITH_FIELDS_SUCCESS,
  RETRIEVE_FORMS_WITH_FIELDS_REQUEST,
} from '../actions/form'
import { PURGE } from 'redux-persist'

export default (
  state = {
    form: {},
    loading: false,
    formList: [],
    formListLoading: false,
  },
  action
) => {
  switch (action.type) {
    case FORM_FIELD_LIST_BY_FORM_ID_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FORM_FIELD_LIST_BY_FORM_ID_SUCCESS:
      return {
        ...state,
        form: action.form,
        loading: false,
      }
    case FORM_FIELD_LIST_BY_FORM_ID_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case FORM_SVF_DATA_BY_FORM_ID_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FORM_SVF_DATA_BY_FORM_ID_SUCCESS:
      return {
        ...state,
        form: action.form,
        loading: false,
      }
    case FORM_SVF_DATA_BY_FORM_ID_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case RETRIEVE_FORMS_WITH_FIELDS_REQUEST:
      return {
        ...state,
        formListLoading: true,
      }
    case RETRIEVE_FORMS_WITH_FIELDS_SUCCESS:
      return {
        ...state,
        formList: action.formList,
        formListLoading: false,
      }
    case RETRIEVE_FORMS_WITH_FIELDS_FAILURE:
      return {
        ...state,
        formListLoading: false,
      }
    case PURGE:
      return {
        form: {},
        loading: false,
        formList: [],
        formListLoading: false,
      }
    default:
      return state
  }
}
