import initialState from '../store/initialState'
import {
  CLINRO_LOGIN_REQUEST,
  CLINRO_LOGIN_SUCCESS,
  CLINRO_LOGIN_FAILURE,
  UPDATE_SELECTED_FORMTYPE,
  CLINRO_LOGOUT_REQUEST,
  CLINRO_LOGOUT_FAILURE,
  CLINRO_LOGOUT_SUCCESS,
  UPDATE_USERS,
  UPDATE_LOGGEDIN_USER,
} from '../actions/users'
import { PURGE } from 'redux-persist'

export default (state = initialState.users, action) => {
  switch (action.type) {
    case CLINRO_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CLINRO_LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: {
          id: action.userId,
        },
        loading: false,
      }
    case CLINRO_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case CLINRO_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CLINRO_LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: {},
        loading: false,
      }
    case CLINRO_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_SELECTED_FORMTYPE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          selectedFormType: action.selectedFormType,
        },
      }
    case UPDATE_USERS:
      return {
        ...state,
        ...action.users,
        currentUser: state.currentUser,
      }
    case UPDATE_LOGGEDIN_USER:
      return {
        ...state,
        [action.user.id]: action.user,
        currentUser: action.user,
        privacyPolicy: action?.privacyPolicy,
      }
    case PURGE:
      return {
        currentUser: {
          id: null,
          selectedFormType: '',
        },
        loading: false,
      }
    default:
      return state
  }
}
