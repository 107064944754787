import createActionType from '../utils/action'
import appConstants from '../constants/constants'
import uuid from 'react-uuid'
import { Platform } from 'react-native'
import _ from 'lodash'
import api from '../utils/api'
import storage from '../utils/storage/storage'

export const ADD_ITEM = createActionType('ADD_ITEM')
export const UPDATE_ITEMS = createActionType('UPDATE_ITEMS')

export const addItem = (data) => (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  const item = {
    userId: id,
    data,
    status: appConstants.OfflineDataStatus.ACTIVE,
    id: Platform.OS !== 'web' ? getUniqueId() : uuid(),
    attempts: 0,
  }
  dispatch({
    type: ADD_ITEM,
    item,
  })
}

export const updateStatus = (id, status, attempts) => (dispatch, getState) => {
  const {
    offlineData: { data },
  } = getState()
  const index = _.findIndex(data, (item) => item.id === action.id)
  if (index >= 0) {
    data[index] = {
      ...data[index],
      status,
      attempts,
    }
  }
  dispatch({
    type: UPDATE_ITEMS,
    data,
  })
}

export const deleteItem = (id) => (dispatch, getState) => {
  const {
    offlineData: { data },
  } = getState()
  _.remove(data, (item) => item.id === action.id)
  dispatch({
    type: UPDATE_ITEMS,
    data,
  })
}

export const syncDataOfAllUsers = () => (dispatch, getState) => {
  const {
    offlineData: { data },
  } = getState()
  const dataToSync = _.filter(data, (d) => d.status === appConstants.OfflineDataStatus.ACTIVE)
  dispatch(syncData(dataToSync))
}

export const syncTokenExpiredDataOfCurrentUser = () => (dispatch, getState) => {
  const {
    offlineData: { data },
    users: {
      currentUser: { id },
    },
  } = getState()
  const currentUserData = _.filter(
    data,
    (item) => item.userId === id && item.status === appConstants.OfflineDataStatus.INVALID_TOKEN
  )
  dispatch(syncData(currentUserData))
}
const syncData = (data) => (dispatch, getState) => {
  _.forEach(data, async (item) => {
    try {
      dispatch(updateStatus(item.id, appConstants.OfflineDataStatus.IN_PROGRESS, item.attempts))
      const user = getState().users[item.id]
      await api.post(`crfData/data/sync`, [item], { headers: { accessToken: user.accessToken } })
      dispatch(deleteItem(item.id))
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(
            updateStatus(item.id, appConstants.OfflineDataStatus.INVALID_TOKEN, item.attempts + 1)
          )
        } else {
          dispatch(
            updateStatus(
              item.id,
              item.attempts >= 2
                ? appConstants.OfflineDataStatus.SYNC_FAILED
                : appConstants.OfflineDataStatus.ACTIVE,
              item.attempts + 1
            )
          )
        }
      } else {
        dispatch(
          updateStatus(
            item.id,
            item.attempts >= 2
              ? appConstants.OfflineDataStatus.SYNC_FAILED
              : appConstants.OfflineDataStatus.ACTIVE,
            item.attempts + 1
          )
        )
      }
    }
  })
}
