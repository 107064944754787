import React from "react"
import { View, StyleSheet } from "react-native"
import _ from 'lodash'
import { getScaleProperties } from "../utils"
import PropTypes from 'prop-types'
import { alignments } from "../alignments"
import { getValue } from "../../fields/Visual Scale/VerticalLabelUtils"
import LabelTextDisplay from "../../fields/Visual Scale/LabelTextDisplay"
import { useSelector } from 'react-redux';

const VerticalLabels = (props) => {
    const {labels, step, start, end, orientation, disabled, id, transformedFontSize, labelsData} = props
    const { onMobile } = useSelector(state => state?.appStatus);
    const scaleProps = getScaleProperties(start, end, step, orientation, onMobile)
    const {widthOfBox, margin} = scaleProps
    const boxHeight = widthOfBox


    let textAlign = 'right'
    if(_.find(labels, label => label.labelPlacement === 'RIGHT')){
        textAlign = 'left'
    }

    const getMarginTop = () => {
        return (boxHeight+5)/2
    }

    const getTextStyles = () => {
       return {
            textAlign: textAlign,
        }
    }

    return (
        <View style={styles.container}>
          {
            _.map(_.orderBy(_.range(start, end + 1, step), [], 'desc'), (number, index) => {
                const loWidth =  _.get(labelsData[number], 'width', 0)
                const loHeight =  _.get(labelsData[number], 'height', 0)
                const isFitInOwnBox = _.get(labelsData[number], 'fitInOwnBox', false)
                const isTextFit =  _.get(labelsData[number], 'fit', false)
                return (
                    <View key={index} 
                    style={[styles.box, { 
                      height: widthOfBox,
                      marginBottom: margin,
                     }]}
                     testID={`numeric-rating-scale-sliderLabels-${id}-${number}-vt-label`}
                     accessible={!disabled}
                    >
                     <View style={{height: widthOfBox, marginHorizontal: 5, marginTop: isFitInOwnBox ? getMarginTop() : 0}}>
                            <LabelTextDisplay 
                                    isFit={isTextFit || _.isEmpty(getValue(number, labels))}
                                    testID={`numeric-rating-scale-sliderLabels-${id}-${number}-vtLabel`}
                                    accessible={!disabled} 
                                    text={getValue(number, labels)}
                                    fontsize={transformedFontSize}
                                    containerWidth={loWidth}
                                    containerHeight={loHeight}
                                    labelStyles={getTextStyles()}
                                    orientation={alignments.VERTICAL}
                                    containerStyle={{height: loHeight}}
                                />
                     </View>
                  </View>
                  )
            }
        )
          }
        </View>
      ); 
}



const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        flex: 1,
    },
    box: {
        justifyContent: 'center',
    }
  });

VerticalLabels.defaultProps = {
    start: 0,
    end: 10,
    step: 1,
    labels: [],
    orientation: alignments.VERTICAL,
    disabled: false,
    id: null,
    transformedFontSize: 10,
    labelsData: {}
  }
  
VerticalLabels.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    labels: PropTypes.arrayOf(Object),
    orientation: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    transformedFontSize: PropTypes.number,
    labelsData: PropTypes.instanceOf(Object)
}
export default VerticalLabels