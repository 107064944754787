import React from 'react'
import { View, Text } from 'react-native'
import styles from './styles/trainingStyles'

function TrainingLabel() {
  return (
    <View style={styles.trainingLabelContainer}>
      <Text style={styles.trainingText}> You are viewing training forms.</Text>
    </View>
  )
}

export default TrainingLabel
