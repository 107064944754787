import {
  GET_META_DATA_REQUEST,
  GET_META_DATA_FAILURE,
  GET_META_DATA_SUCCESS,
  UPDATE_SELECTED_VISIT_ID,
  UPDATE_SELECTED_VISIT_FROM_ID,
  UPDATE_SVF_STATUS,
  RETRIEVE_ARM_VISIT_SCHEDULE_SUCCESS,
  RETRIEVE_ARM_VISIT_SCHEDULE_REQUEST,
  RETRIEVE_ARM_VISIT_SCHEDULE_FAILURE,
  NO_META_DATA_UPDATED,
  UPDATE_SELECTED_DATE,
  UPDATE_IMAGE_CODES,
  UPDATE_DOWNLOADED_IMAGE_URL,
} from '../actions/metaData'
import initialState from '../store/initialState'
import _ from 'lodash'
import { PURGE } from 'redux-persist'
import moment from 'moment'

export default (state = initialState.metaData, action) => {
  switch (action.type) {
    case GET_META_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_META_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
      }
    case GET_META_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.metaData
        },
        loading: false,
      }
    case UPDATE_SELECTED_VISIT_ID:
      return {
        ...state,
        selectedVisitId: action.selectedVisitId,
      }
    case UPDATE_SELECTED_VISIT_FROM_ID:
      return {
        ...state,
        selectedVisitFormOid: action.visitFormOid,
        selectedSvfId: action.svfId,
      }
    case UPDATE_SVF_STATUS: {
      console.log('svf status update ' + action.svfId + ' ' + action.status)
      const updatedSvs = _.map(state.data.subjectVisits, (sv) => {
        if (sv.id === action.subjectVisitId) {
          return {
            ...sv,
            subjectVisitForms: _.map(sv.subjectVisitForms, (svf) => {
              if (svf.id === action.svfId) {
                return {
                  ...svf,
                  submissionStatus: action.status,
                  completedDateTime: action.completedDate,
                  status: action.svfStatus,
                }
              }
              return svf
            }),
          }
        }
        return sv
      })
      var data = { ...state.data }
      data = { ...data, subjectVisits: updatedSvs }
      return {
        ...state,
        data: data,
      }
    }
    case RETRIEVE_ARM_VISIT_SCHEDULE_REQUEST:
      return {
        ...state,
        webappVisitDtLoading: true,
      }
    case RETRIEVE_ARM_VISIT_SCHEDULE_FAILURE:
      return {
        ...state,
        webappVisitDtLoading: false,
      }
    case RETRIEVE_ARM_VISIT_SCHEDULE_SUCCESS:
      const updatedVisits = _.map(state.data.visits, (v) => {
        if (v.id === action.visitId) {
          return {
            ...v,
            armVisitSchedule: action.armVisitSchedule,
          }
        }
        return v
      })
      var data = { ...state.data }
      data = { ...data, visits: updatedVisits }
      return {
        ...state,
        data: data,
        webappVisitDtLoading: false,
      }
    case NO_META_DATA_UPDATED:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.date,
      }
    case UPDATE_IMAGE_CODES:
        return {
          ...state,
          data: {
            ...state.data,
            images: {
              ...state.data?.images,
            [action.subjectId]: action.data,
            }
          }
      }
    case UPDATE_DOWNLOADED_IMAGE_URL:
      const subjectImageMetaData = !_.isEmpty(state?.data?.images[action?.subjectId])? state?.data?.images[action?.subjectId] : {}
      const keyMetaData = !_.isEmpty(state.data.images[action?.subjectId][action.key])? state.data.images[action?.subjectId][action.key] : {}

        return {
          ...state,
          data: {
            ...state.data,
            images: {
              ...state.data.images,
              [action.subjectId]: {
                ...subjectImageMetaData,
                [action.key]: {
                  ...keyMetaData,
                  downloadedUrl : action.downloadedUrl
                }

              }
            }
          }
      }
    case PURGE:
      return {
        data: {},
        loading: false,
        failed: false,
        selectedVisitId: '',
        selectedVisitFormOid: '',
        selectedSvfId: '',
        webappVisitDtLoading: false,
        selectedDate: moment.utc().format('YYYY-MM-DD'),
      }
    default:
      return state
  }
}
