import { Dimensions, PixelRatio, Platform } from 'react-native'
import { alignments } from './alignments'
import _ from 'lodash'
import constants from '../../constants/constants'
import { fontScale } from '../styles/fontResizer'


const {SCALE_POSITION} = constants

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

export const getWithOfScale = (orientation) => {
  if (SCREEN_WIDTH >= 1030) {
    if (_.isEqual(_.toUpper(orientation), _.toUpper(alignments.HORIZONTAL))) {
      return 600
    }
    return 300
  }
  return 300
}

export const getScaleWidth = () => {
  if (SCREEN_WIDTH < 576) {
    return 320
  }
  if (SCREEN_WIDTH >= 576 && SCREEN_WIDTH < 768) {
    return 500
  }
  if (SCREEN_WIDTH >= 768 && SCREEN_WIDTH < 992) {
    return 700
  }
  return 750
}

export const getScaleHeight = () => {
  // if(SCREEN_HEIGHT < 576){
  //     return 320;
  // } if(SCREEN_HEIGHT >= 576 && SCREEN_HEIGHT < 768){
  //     return 500;
  // } if(SCREEN_HEIGHT >= 768 && SCREEN_HEIGHT < 992){
  //     return 700;
  // }
  return 300
}




//To define Vertical scale position w.r.t screen
export const getScalePosition = (scalePosition) => {
  switch (scalePosition) {
    case SCALE_POSITION.CENTRE_OF_THE_PAGE: return 'center'
    case SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE: return 'flex-start'
    case SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE: return 'flex-end'
    default: return 'center'
  }
}


//For Vertical scale and it's labels
export const getLabelsFlex = (options, scaleposiiton) => {
  const isLeftSideLabelsPresent = !_.isEmpty(_.filter(options, op => op.labelPlacement === 'LEFT'))
  const isRightSideLabelsPresent = !_.isEmpty(_.filter(options, op => op.labelPlacement === 'RIGHT'))
  const marginFactor = Platform.OS === 'web' ? 0.24 : 0.23
  if(!isLeftSideLabelsPresent && !isRightSideLabelsPresent){
      return {left: 0, right: 0}
  }else{
      switch (scaleposiiton) {
          case SCALE_POSITION.CENTRE_OF_THE_PAGE: return {left: 0.5, right: 0.5}
          case SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE: {
              if(isLeftSideLabelsPresent){
                  return {left:marginFactor, right: 0.75}
              }else{
                  return {left: 0, right: 1}
              }
          }
          case SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE: {
              if(isRightSideLabelsPresent){
                  return {right: marginFactor, left: 0.75}
              }else{
                  return {right: 0, left: 1}
              }
          }
      }
  }
}

//To define label aligment for Hz Scale 
export const getHzTextAlign = (number, end) => {
  if(number === end){
    return 'right'
  }else {
    return 'left'
  }
}

//To calculate the no of character to fit in given width per single line
export const calculateCharactersPerWidth = (fontSize, containerWidth) => {
  const avgCharWidth = fontSize * 0.5; // approximate average character width
  return Math.floor(containerWidth / avgCharWidth);
}

export const getEdgeLabelStyles = (scalePosition, labelsFlex) => {
  if((labelsFlex.left === 1 || labelsFlex.right === 1) || (labelsFlex.left === 0 && labelsFlex.right === 0)){
    switch (scalePosition) {
      case SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE: return {textAlign: 'right'}  
      case SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE: return {textAlign: 'left'} 
      default: return {} 
    }
  }
  return {}
}

export const isInSequence = (x, start, end, step) => {
  // Check if x is within the range of the sequence
  if (x < start || x > end) {
      return false;
  }
  // Calculate n
  const n = (x - start) / step + 1;
  // Check if n is an integer
  return Number.isInteger(n);
}

export const getScaleProperties = (start, end, step, orientation, onMobile) => {
  const length = ((Number(end)-Number(start))/Number(step))+1;
  const webScreenWidth = orientation === alignments.VERTICAL ? 400 : 750
  const screenWidth = onMobile ? SCREEN_WIDTH-20 : webScreenWidth
  const widthOfBox = (screenWidth/length) > 32 ? 32 : (screenWidth/length);
  const scale =  (screenWidth/length) > 32 ? 1 : (screenWidth/widthOfBox)/length;
  const marign = ((screenWidth - (widthOfBox*scale*length))/length)/2
  const loMargin = ((3*marign) > widthOfBox) ? 1 : marign
  const platformMargin = Platform.OS === 'web'? 12 : 8
  const textScale = (screenWidth/length) > 32 && Platform.OS === 'web' ? 0.5 : ((widthOfBox-2*loMargin)/screenWidth)*(platformMargin)
  return {
    scale: scale,
    boxMargin: loMargin,
    textScale: textScale,
    widthOfBox: widthOfBox,
    margin: marign,
    length: length,
    scaleWidth: screenWidth,
  }
}

export const calculateCharacterPerLine = (lines, containerWidth) => {
  // Assuming an average character width for estimation
  let averageCharacterWidth = 4; // Adjust this based on actual font and rendering
  if(Platform.OS !== 'web'){
    averageCharacterWidth = 2;
  }
  // Calculate characters per line based on available width
  const charactersPerLine = Math.floor(containerWidth / averageCharacterWidth);
  // Calculate total characters that can fit
  const totalCharacters = lines * charactersPerLine;

  return totalCharacters;
}

export const getRightAlignedTextEllipsis = (height, width, fontSize, label, textAlign, spaceChars=4) => {
  if(!_.isEmpty(label) && textAlign==='right'){
    const totalCharacterPerWidth = calculateCharactersPerWidth(fontSize, width)-spaceChars
    const charsPerHeight = totalCharacterPerWidth*Math.floor(height/fontSize)
    if(_.size(label)> charsPerHeight && Platform.OS === 'web'){
      return (((label).substring(0, charsPerHeight-((spaceChars*3)+3))) + '...') 
    }
  }
  return label
}

export const getVASScaleProperties = (start, end, step, orientation, onMobile) => {
  const length = ((Number(end)-Number(start))/Number(step));
  const hZScreenWidth = onMobile ? SCREEN_WIDTH-50 : 700
  const vtScreenWidth = 400
  const screenWidth = orientation===alignments.HORIZONTAL ? hZScreenWidth : vtScreenWidth
  const widthOfBox = screenWidth/length
  const scale =  (screenWidth/length) > 32 ? 1 : (screenWidth/widthOfBox)/length;
  return {
    widthOfBox: widthOfBox,
    length: length,
    scaleWidth: screenWidth,
    fontSize: 10/(PixelRatio.getFontScale()),
    scale: scale,
  }
}

export const calculateFontSize = (baseFontSize, containerWidth, text, charWidthRatio = 0.5) => {
  const fontScale = PixelRatio.getFontScale();
 
  const loBaseFontSize = baseFontSize / fontScale;
  // Approximate character width
  const avgCharWidth = loBaseFontSize * charWidthRatio;
  // Calculate the number of characters that can fit in the container with the base font size
  const maxCharsFit = Math.floor(containerWidth / avgCharWidth);
  // If the text length exceeds the maximum characters that fit, adjust the font size
  if (text.length > maxCharsFit) {
    // Calculate the reduced font size
    const newFontSize = loBaseFontSize * (containerWidth / (text.length * avgCharWidth));
    return Math.max(newFontSize, 1); // Ensure font size doesn't go below 1
  }
  // Return the base font size if the text fits within the container width
  return loBaseFontSize;
}

export const scaledFont = (max, step, isPercentage, min, indicationConfig, scaleProps) => {
    const {widthOfBox} = scaleProps
    const {indicationMultiple, indicationNumbers} = indicationConfig
    const largestNumber = max
    const sym = isPercentage?'%':''   
    let requiredFontForMarks = calculateFontSize(10, widthOfBox, _.toString(largestNumber)+sym, 0.6)
    let numberOfTerms = 0 
    let multiple = null
    if(!_.isNumber(indicationMultiple) && _.isEmpty(indicationNumbers)){
      multiple = 5
    }else if(_.isNumber(indicationMultiple)){
      multiple = indicationMultiple
    }else if(!_.isEmpty(indicationNumbers)){
      numberOfTerms= indicationNumbers.split(',').length
    }
    if(multiple!=null){
      const noOfIndications = Math.floor((step-min)/multiple)+1
      const largestDigitLength = ((_.toString(largestNumber)+sym).length)*noOfIndications
      const count = (largestDigitLength/(_.toString(largestNumber)+sym).length) < 1 ? 1 : largestDigitLength/(_.toString(largestNumber)+sym).length
      requiredFontForMarks = calculateFontSize(10, widthOfBox, (_.toString(largestNumber)+sym).repeat(count))
      numberOfTerms = ((max-min)/multiple)+1
    }

    requiredFontForMarks = getIndicationsFontSize(min, max, step, isPercentage, indicationConfig, scaleProps, requiredFontForMarks)
    return {requiredFontForMarks: requiredFontForMarks, numberOfTerms: numberOfTerms}
    
}

const getIndicationsFontSize = (min, max, step, isPercentage, indicationConfig, scaleProps, requiredFontForMarks) => {
  if(requiredFontForMarks<8){
        return getFontBasedOnSpaceBetweenNumbers(min, max, step, isPercentage, indicationConfig, scaleProps)
  }else{
      return requiredFontForMarks
  } 
}

const getFontBasedOnSpaceBetweenNumbers = (min, max, step, isPercentage, indicationConfig, scaleProps) => {
  const {indicationNumbers, indicationMultiple} = indicationConfig
  const {widthOfBox} = scaleProps
  const avgCharWidth = 0.7
  let elementArray = []
  let fontsizes = []
  if(!_.isEmpty(indicationNumbers)){
    elementArray =  _.sortBy(_.map(indicationNumbers.split(','), Number));
  }else{
    const multiple = _.isNumber(indicationMultiple) ? indicationMultiple : 5
    elementArray = _.filter(_.range(min, max + 1, step), n => n % multiple === 0);
  }
  _.forEach(elementArray, number => {
    fontsizes.push(processElementsAndReturnFont(elementArray, number, avgCharWidth, step, isPercentage, widthOfBox)) 
  })
  return Math.min(...fontsizes);
}

const processElementsAndReturnFont = (elementArray, number, avgCharWidth, step, isPercentage, widthOfBox) => {    
  const nextNumber = getNextNumber(elementArray, number)
  const font = getStepMultiples(nextNumber, number, avgCharWidth, step, isPercentage, widthOfBox)
  const previousNumber = getPreviousNumber(elementArray, number)
  if(previousNumber){
      const prevFont = getStepMultiples(number, previousNumber, avgCharWidth, step, isPercentage, widthOfBox)
      return Math.min(font, prevFont)       
  }else{
      return font
  }
}

const getNextNumber = (numbers, target) => numbers[numbers.indexOf(target) + 1] || null;
const getPreviousNumber = (numbers, target) => numbers[numbers.indexOf(target) - 1] || null;

const getStepMultiples = (nextNumber, number, avgCharWidth, step, isPercentage, widthOfBox) => {
  if(number!=null){
      const difference = Math.abs(nextNumber - number)
      const stepMultiple = difference / step
      const percentSymbol = isPercentage?'%':''
      const stringifiedNumber = _.toString(number)
      const loNumber = stringifiedNumber+percentSymbol
      return calculateFontSize(10, stepMultiple*widthOfBox, loNumber, avgCharWidth)   
  }else{
      return fontScale(8)
  } 
}