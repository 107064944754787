import { createSelector } from 'reselect'
import { generateOfflineSvfs, getSvfsWithRequiredProps } from '../utils/offline/generateSvfs'
import moment from 'moment'
import appConstants from '../constants/appConstants'

import { getCurrentVisit } from '../selectors/visits'
import { getformList } from '../selectors/form'
import { getMetadata } from '../selectors/visits'

import _ from 'lodash'
import { getCurrentDateTime } from '../utils/fieldutils/field'
import constants from '../constants/constants'
let bgColors = ['#ffecb3', '#b2ebf2', '#ffccbc', '#fce4ec', '#f0f4c3', '#bbdefb']

const getScheduledDateSvfList = (state) => {
  return Object.values(state.subjectVisitForm.list)
}

const getSubjectStudyMetaData = (state) => {
  return state.subjectStudyMetaData
}

export const getSubjectSvfs = (state) => Object.values(state.subjectVisitForm.allSvfs)
export const getSelectedSvfId = (state) => state.metaData.selectedSvfId
export const getSelectedDate = (state) => state.metaData.selectedDate
export const getSubjectTimezone = (state) => state.subjectStudyMetaData.subject.timeZone
export const getAppType = (state) => state.appStatus.appType

export const getSVfsForAScheduledDate = createSelector(
  [getScheduledDateSvfList, getSubjectStudyMetaData],
  (svfList, subjectStudyMetaData) => {
    return getSvfsWithRequiredProps(svfList, subjectStudyMetaData)
  }
)

export const getOfflineSVfsForToday = createSelector(
  [getSubjectStudyMetaData],
  (subjectStudyMetaData) => {
    return generateOfflineSvfs(subjectStudyMetaData)
  }
)

const getBgColor = (index) => {
  if (bgColors[index]) {
    return bgColors[index]
  }
  bgColors = [...bgColors, ...bgColors]
  return bgColors[index]
}

export const getFilteredSvfs = createSelector(
  [getScheduledDateSvfList, getSubjectStudyMetaData],
  (svfList, subjectStudyMetaData) => {
    const currentVisit = _.get(subjectStudyMetaData.subject.subjectVisits, 'currentVisit', {})
    const selectedVisit = _.get(subjectStudyMetaData.subject.subjectVisits, 'selectedVisit', null)
    const selectedVisitDetails = _.find(
      subjectStudyMetaData.subject.subjectVisits?.visits,
      (sv) => sv.id === selectedVisit
    )
    const selectedVisitType = selectedVisitDetails ? selectedVisitDetails.visitType : null
    const filteredSvfs = []
    const currentDateTime = getCurrentDateTime()
    _.map(svfList, (svf) => {
      if (
        svf.training ||
        (!_.isEmpty(currentVisit) && selectedVisit !== currentVisit.id) ||
        !svf.scheduleEndTime ||
        _.isEmpty(subjectStudyMetaData.study.schedule)
      ) {
        filteredSvfs.push(svf)
      } else {
        const schedule = subjectStudyMetaData.study.schedule
        if (
          selectedVisitType === 'UNSCHEDULED' ||
          !schedule ||
          selectedVisitDetails.currentScheduleId != schedule.id
        ) {
          filteredSvfs.push(svf)
        } else {
          const scheduleForm = _.find(_.get(schedule, 'formScheduleEvents', []), (formSchedule) => {
            const formIds = _.map(formSchedule.forms, (f) => f.formOid)
            if (
              _.includes(formIds, svf.form.formOid) ||
              svf.crfVersion.id !== subjectStudyMetaData.study.crfVersion.id
            ) {
              return formSchedule
            }
          })
          if (!_.isEmpty(scheduleForm)) {
            let totalTimeForSubmission = null
            totalTimeForSubmission = _.isNull(scheduleForm.eventTimeslot[0].allowDiaryCompletion)
              ? svf.scheduleEndTime
                ? moment(svf.scheduleEndTime)
                : null
              : moment(svf.scheduleEndTime).add(
                  scheduleForm.eventTimeslot[0].allowDiaryCompletion,
                  'minutes'
                )
            svf.allowDiaryCompletion = scheduleForm
              ? scheduleForm.eventTimeslot[0].allowDiaryCompletion
              : null
            if (
              !totalTimeForSubmission ||
              moment(currentDateTime).isAfter(totalTimeForSubmission)
            ) {
              if (
                selectedVisitType === 'UNSCHEDULED' ||
                (scheduleForm && scheduleForm.eventTimeslot[0].allowDiaryDisplay)
              ) {
                filteredSvfs.push(svf)
              }
            } else {
              filteredSvfs.push(svf)
            }
          }
        }
      }
    })
    return getSvfsWithRequiredProps(filteredSvfs, subjectStudyMetaData)
  }
)

export const getSvfsOfCurrentVisit = createSelector(
  [getCurrentVisit, getformList, getSelectedDate, getSubjectTimezone, getMetadata, getAppType],
  (currentVisit, formList, scheduleDate, subjectTimezone, metadata, appType) => {
    if (!_.isEmpty(formList)) {
      var svfList = _.map(currentVisit.subjectVisitForms, (svf) => {
        const svfEventObj = getEventTimeSlot(currentVisit, svf, metadata)
        return {
          ...svf,
          form: _.filter(formList, (form) => form.id === svf.form.id)[0],
          eventTS: svfEventObj?.eventTimeSlot,
          frequency: svfEventObj?.frequency,
          isMultiTimeSlotSvf: svfEventObj?.isMultiTimeSlotSvf,
          eventOccurence: svfEventObj?.eventOccurence,
        }
      })
      svfList = getSvfsWithRequiredProps(svfList)
      if (_.isEqual(appType, constants.AppType.SITESTAFF)) {
        svfList = _.filter(svfList, (svf) => svf.formType == constants.FormTypes.RATER_ASSESSMENT)
      } else {
        svfList = _.filter(svfList, (svf) => svf.formType == constants.FormTypes.SUBJECT_DIARY)
      }
      return getSvfsBasedOnStudyType(svfList, scheduleDate, currentVisit.visitType, subjectTimezone)
    }
    return []
  }
)

export const getSvfsOfCurrentVisitWithMinEntries = createSelector(
  [getSvfsOfCurrentVisit],
  (svfListOfSelectedDate) => {
    let loSvfList = []
    let svfsOfEachEvent = _.groupBy(svfListOfSelectedDate, 'originatingEventId')
    _.forEach(svfsOfEachEvent, (event) => {
      if (event[0]?.isMultiTimeSlotSvf) {
        _.forEach(event, (f) => loSvfList.push(f))
      } else {
        const min = event[0]?.eventOccurence?.minumumOccurence || 1
        const max = Number(event[0]?.eventOccurence?.maximumOccurence) || 1
        const listOfEachForm = _.groupBy(event, 'form.formOid')
        _.forEach(listOfEachForm, (formList) => {
          const noOfSets = _.size(formList) / max;
          const submittedFormsLength = _.filter(formList, (f) => f.status === 'COMPLETED').length
          const unsubmittedForms = _.filter(formList, (f) => f.status !== 'COMPLETED') //to push unsubmittedForms
          _.orderBy(unsubmittedForms, 'setNumber')
          if (noOfSets > 1) {
            const submittedMaxForms = submittedFormsLength - noOfSets * min
            for (var i = 0; i < noOfSets; i++) {
              if (submittedMaxForms < 0) {
                if (submittedFormsLength < (i + 1) * min && !_.isEmpty(unsubmittedForms)) {
                  if ((i + 1) * min - submittedFormsLength <= min) {
                    _.forEach(unsubmittedForms, (f, index) => {
                      if (index < (i + 1) * min - submittedFormsLength) {
                        loSvfList.push(f)
                      }
                    })
                  } else {
                    _.forEach(unsubmittedForms, (f, index) => {
                      if (index < min) {
                        loSvfList.push(f)
                      }
                    })
                  }
                }
              } else {
                if (submittedFormsLength <= (i + 1) * max && !_.isEmpty(unsubmittedForms)) {
                  loSvfList.push(unsubmittedForms[0])
                }
              }
            }
          } else if (
            submittedFormsLength >= min &&
            submittedFormsLength < max &&
            !_.isEmpty(unsubmittedForms)
          ) {
            loSvfList.push(unsubmittedForms[0])
          } else if (submittedFormsLength < min && !_.isEmpty(unsubmittedForms)) {
            _.forEach(unsubmittedForms, (f, index) => {
              if (index < min - submittedFormsLength) {
                loSvfList.push(f)
              }
            })
          }
        })
      }
    })
    return loSvfList
  }
)

const getSvfsBasedOnStudyType = (svfList, scheduleDate, visitType, subjectTimezone) => {
  return getSvfsBasedOnSelectedDate(svfList, scheduleDate, visitType, subjectTimezone)
}

const getSvfsBasedOnSelectedDate = (svfList, scheduleDate, visitType, subjectTimezone) => {
  const svfsOfSelectedDate = _.filter(svfList, (svf) => {
    if (
      moment(scheduleDate).isSameOrAfter(moment(svf.scheduleStartTime), 'day') &&
      moment(scheduleDate).isSameOrBefore(moment(svf.scheduleEndTime), 'day')
    ) {
      return svf
    } else {
      if (visitType !== 'UNSCHEDULED') {
        const shouldShowSvf = filterSvfByAllowDiaryDisplay(svf, scheduleDate)
        if (shouldShowSvf) {
          return svf
        }
      }
    }
  })
  return svfsOfSelectedDate
}

export const filterSvfByAllowDiaryDisplay = (svf, scheduleDate) => {
  if (svf.allowDiaryDisplay) {
    const endDate = moment(svf.scheduleEndTime).format('YYYY-MM-DD')
    const totalTimeForDisplay = _.isNull(svf.diaryDisplayDuration)
      ? moment(svf.scheduleEndTime).format('YYYY-MM-DD HH:mm')
      : moment(svf.scheduleEndTime)
          .add(svf.diaryDisplayDuration, 'minutes')
          .format('YYYY-MM-DD HH:mm')
    const currentTime = moment().format('HH:mm')
    const selectedDateTime = scheduleDate + ' ' + currentTime
    if (
      endDate &&
      moment(scheduleDate).isAfter(moment(endDate)) &&
      moment(selectedDateTime).isSameOrAfter(moment(svf.scheduleStartTime)) &&
      moment(selectedDateTime).isSameOrBefore(moment(totalTimeForDisplay))
    ) {
      return true
    } else if (
      endDate == null &&
      moment(scheduleDate).isSameOrAfter(moment(svf.scheduleStartTime), 'day')
    ) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const getSvfEventTimeSlot = (currentVisit, svf, metadata) => {
  const svfCrfVersion = _.find(metadata.versions, (cv) => _.isEqual(cv.id, svf.crfVersion?.id))
  const visitCrfVersion = _.find(metadata.versions, (cv) =>
   _.isEqual(cv.id, currentVisit.crfVersion?.id)
  )
  let currentSchedule = {}
  if (_.isEqual(svf.scheduleId, currentVisit.currentScheduleId)) {
    currentSchedule = _.find(svfCrfVersion?.armVisitSchedules, (sc) =>
      _.isEqual(sc.id, currentVisit.currentScheduleId)
    )
  } else {
    const svfSchedule = _.find(svfCrfVersion?.armVisitSchedules, (sc) =>
      _.isEqual(sc.id, svf.scheduleId)
    )
    const visitSchedule = _.find(visitCrfVersion?.armVisitSchedules, (sc) =>
      _.isEqual(sc.id, currentVisit.currentScheduleId)
    )
    currentSchedule = _.isEqual(svfSchedule?.scheduleCode, visitSchedule?.scheduleCode)
      ? visitSchedule
      : svfSchedule
  }
  const currentEvent = _.find(currentSchedule?.formScheduleEvents, (event) =>
    _.isEqual(event.eventCode, svf.originatingEventId)
  )
  return !_.isEmpty(currentEvent) && !_.isEmpty(currentEvent?.eventTimeslot) ? currentEvent : {}
}

export const getSelectedSvf = createSelector(
  [getSvfsOfCurrentVisit, getSelectedSvfId],
  (svfList, selectedSvfId) => {
    return _.filter(svfList, (svf) => _.isEqual(svf.svfId, selectedSvfId))?.[0]
  }
)

const getEventTimeSlot = (currentVisit, svf, metadata) => {
  let requiredEventSlot = {}
  if (currentVisit.visitType !== 'UNSCHEDULED') {
    const svfEvent = getSvfEventTimeSlot(currentVisit, svf, metadata)
    requiredEventSlot = {
      eventTimeSlot: { ...svfEvent?.eventTimeslot?.[0] },
      frequency: svfEvent?.eventFrequency?.frequency,
      isMultiTimeSlotSvf: svfEvent?.eventTimeslot?.length > 1 ? true : false,
      eventOccurence: svfEvent?.eventTimeslot?.length > 1 ? {} : svfEvent?.eventOccurrences,
    }
  } else {
    requiredEventSlot = {
      eventTimeSlot: {
        isAllDayEvent: true,
        allowDiaryDisplay: true,
        allowDiaryCompletion: 0,
        diaryDisplayDuration: 0,
      },
      frequency: 0,
      isMultiTimeSlotSvf: true,
      eventOccurence: {},
    }
  }
  return requiredEventSlot
}
