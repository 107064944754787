import _ from 'lodash'
import momentTz from 'moment-timezone'
import { Spinner } from 'native-base'
import { createForm } from 'rc-form'
import React, { Component } from 'react'
import {
  Dimensions,
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { bindActionCreators } from 'redux'
import { updateSelectedSvf } from '../../actions/subjectVisitForm'
import {
  prepareFormItems,
  prepareFormItemsForMobile,
  sendPrintData,
  storeDraftFormData,
  submitForm,
  validateRules,
} from '../../actions/form'
import { addItem } from '../../actions/offlineData'
import { styles } from '../../components/fields/styles'
import { Authorize } from '../../utils/appPrivileges'
import { disableForm, getSvf } from '../../utils/fieldutils/field'
import { Default, Mobile } from '../../utils/MatchMedia'
import showToast from '../../utils/toast'
import FormInstruction from '../FormInstructionScreen'
import Popup from '../Popup'
import Error from './Error'
import Field from './Field'
import Header from './Header'
import Info from './Info'
import PrintContent from './print/PrintContent'
import Result from './Result'
import StepLayout from './StepLayout'
import { pushFormDataToQueue } from '../../actions/syncQueue'
import { updateFieldAnswer } from '../../actions/formAnswers'
import placeFieldValues from '../../utils/fieldutils/fieldDecorator'
import {
  updateSvfStatus,
} from '../../actions/metaData'
import constants from '../../constants/constants'
import { getDeviceStatus } from '../../actions/users'
import DiaryDisplay from './DiaryDisplay'
import PropTypes from 'prop-types'

export class FormConsumer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formData: [],
      isInstructionsRead: false,
      previewFieldId: null,
      errorMessage: null,
      showError: false,
      dialogColor: null,
      currentPage: 0,
    }
    this.fieldRefs = []
  }

  printPdf = () => {
    setTimeout(() => {
      this.printElement.click()
    }, 1000)
  }

  async componentDidMount() {
    const {
      selectedSvf,
      screenProps: { t },
      preview,
      previewFieldId,
      mode,
    } = this.props
    if (preview) {
      this.setState({ previewFieldId: previewFieldId })
      setTimeout(() => {
        console.log(this.fieldRefs[previewFieldId])
        if (this.fieldRefs[previewFieldId] !== undefined) {
          this.fieldRefs[previewFieldId].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 1000)
    }
    this.getFormData(selectedSvf.isFilled, false)
    if (Platform.OS === 'web') {
      window.addEventListener('beforeunload', this.handletabClose)
    }
    if (mode === 'printView') {
      this.printPdf()
    }
  }

  componentWillUnmount() {
    this.saveDraftDataToServer()
    if (Platform.OS === 'web') {
      window.removeEventListener('beforeunload', this.handletabClose)
    }
  }

  setCurrentPage = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: page,
    }))
  }
  handletabClose = (ev) => {
    const { selectedSvf, subjectTimezone } = this.props
    if (!disableForm(selectedSvf, subjectTimezone)) {
      ev.preventDefault()
      ev.returnValue = true
    } else {
      return
    }
  }

  setDraftData = (fieldList, selectedVisitForm) => {
    const { draftData } = this.props
    fieldList = _.map(fieldList, (loField) => {
      const draftField = _.find(
        draftData,
        (d) =>
          d.scheduleDate === selectedVisitForm.scheduleDate &&
          d.scheduleTime === selectedVisitForm.scheduleTime &&
          d.field.id === loField.id &&
          selectedVisitForm.training === d.training
      )
      return {
        ...loField,
        crfData: loField
          ? draftField
            ? draftField.field.crfData
            : loField.crfData
          : draftField
            ? draftField.field.crfData
            : null,
      }
    })
    return fieldList
  }



  componentDidUpdate(prevProps, prevState) {
    const { fieldGroupList, formAnswers, selectedSvf, mode } = this.props
    const { isInstructionsRead, currentPage } = this.state
    if (
      !_.isEqual(prevProps.fieldGroupList, fieldGroupList) ||
      !_.isEqual(prevProps.formAnswers[selectedSvf.svfId], formAnswers[selectedSvf.svfId]) ||
      !_.isEqual(currentPage, prevState.currentPage)
    ) {
      this.getFormData(isInstructionsRead, prevState.currentPage < currentPage)
    }
  }



  getFormData = (isInstructionsRead, isNewPage) => {
    const { selectedSvf, fieldGroupList, mode, subjectTimezone, formAnswers, onMobile } = this.props
    const { currentPage } = this.state
    this.setState({
      loading: true,
    })
    let data = []
    let isFormDisabled = disableForm(selectedSvf, subjectTimezone)
    // Initial page i.e., page: 0, system should display all the fields based on page size
    // If page is changed, isNewPage is true, so system will display fields based on the skip logic of previous page fields
    // If field is answered, isNewPage is false, system will disable the fields on the same page but not hide them
    const isPageChanged = currentPage !== 0 ? true : isNewPage
    data = !onMobile
        ? prepareFormItems(selectedSvf, fieldGroupList, mode, isFormDisabled, formAnswers)
        : prepareFormItemsForMobile(
          selectedSvf,
          fieldGroupList,
          mode,
          isFormDisabled,
          formAnswers,
          currentPage,
          isPageChanged
        )
    this.setState({
      formData: data,
      loading: false,
      isInstructionsRead,
    })
  }

  handleSubmit = (isFilled) => {
    const {
      subject,
      screenProps: { t },
      updateOfflineSaveLoading,
      fieldList,
      actions
    } = this.props
    this.props.form.validateFields(async (error, values) => {
      if (!error) {
        updateOfflineSaveLoading(true)
        const svfList = this.prepareSvfData(isFilled)
        if (getDeviceStatus()) {
          await this.handleFormSubmissionOnline(svfList, subject.id, isFilled)
        } else {
          await this.handleFormSubmissionOffline(svfList, subject.id, isFilled)
        }
        updateOfflineSaveLoading(false)
      } else {
        const filteredFields = _.filter(fieldList, (fl) => fl.isRequired === true)
        if (!_.isEmpty(filteredFields) && !isFilled) {
          this.showError('FillRequiredFields')
        }
      }
    })
  }

  prepareSvfData = (isFilled) => {
    const { selectedSvf, subjectVisitId, subjectTimezone, formAnswers, screenProps: { t }, fieldList, subject } = this.props
    let crfData = []
    const filteredFields = _.filter(
      fieldList,
      (fl) => fl.fieldType !== 'result' && fl.fieldType !== 'label'
    )
    _.forEach(filteredFields, (field) => {
      if (formAnswers?.[selectedSvf.svfId]?.[field.fieldOid]) {
        let loCrfdata = placeFieldValues(
          formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.fieldValue,
          formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.optionOid,
          field,
          selectedSvf.svfId,
          selectedSvf.crfVersionId
        )
        loCrfdata = { ...loCrfdata, locale: subject.locale }
        crfData.push(
          loCrfdata
        )
      }
    })
    var svfList = []
    const svf = getSvf(selectedSvf, subjectVisitId, subjectTimezone, crfData, isFilled)
    svf.locale = subject.locale
    svfList.push(svf)
    return svfList
  }

  handleFormSubmissionOffline = async (svfList, subjectId, isFilled) => {
    const { actions, navigation, screenProps: { t } } = this.props
    actions.pushFormDataToQueue(svfList, subjectId, svfList[0].completedDateTime, svfList[0]?.crfVersion?.id, svfList[0]?.subjectVisit?.id)
    actions.updateSvfStatus(
      svfList[0].id,
      svfList[0].subjectVisit?.id,
      constants.OfflineDataStatus.IN_PROGRESS,
      svfList[0].completedDateTime,
      svfList[0].status
    )
    if (isFilled) {
      setTimeout(() => {
        showToast(t('SavedMessage'), 'success', 3000)
        navigation.goBack()
      }, 3000)
    }
  }

  handleFormSubmissionOnline = async (svfList, subjectId, isFilled) => {
    const { actions, updateOfflineSaveLoading, navigation, screenProps: { t } } = this.props
    try {
      const res = await actions.submitForm(svfList[0].crfVersion?.id, svfList[0].subjectVisit?.id, subjectId, svfList)
      if (res.status == 200 || res.status == 201 && isFilled) {
        actions.updateSvfStatus(svfList[0].id, svfList[0].subjectVisit?.id, constants.OfflineDataStatus.SUCCESS, svfList[0].completedDateTime, 'COMPLETED')
        setTimeout(() => {
          showToast(t('SavedMessage'), 'success', 3000)
          navigation.goBack()
        }, 1000)
      }
    } catch (error) {
      console.log(error)
      updateOfflineSaveLoading(false)
      if (isFilled) {
        showToast(t('FailedMessage'), 'error', 3000)
      }
      actions.pushFormDataToQueue(svfList, subjectId, svfList[0].completedDateTime, svfList[0].crfVersion?.id, svfList[0].subjectVisit?.id)
    }
  }

  renderFormItem = (item, footerPageY) => {
    const { screenProps, selectedSvf, form, formAnswers, preview, mode } = this.props
    const { t } = screenProps
    let { subjectTimezone } = this.props
    const { isInstructionsRead } = this.state
    subjectTimezone = momentTz().tz(subjectTimezone).format('z')
    switch (item.type) {
      case 'label':
        return <Info title={item.data} isFooter={false} />
      case 'instruction':
        return (
          <FormInstruction
            screenProps={screenProps}
            onNext={() => this.getFormData(true)}
            showOkButton={!isInstructionsRead}
            instruction={item.data}
          />
        )
        break
      case 'field': {
        const fieldAnswer = formAnswers[selectedSvf.svfId]?.[item?.data?.fieldOid]
        return (
          <Field
            {...item}
            subjectTimezone={subjectTimezone}
            t={t}
            form={form}
            selectedSvfId={selectedSvf.svfId}
            fieldAnswer={fieldAnswer}
            mode={mode === 'printView' ? mode : (preview ? 'preview' : '')}
            footerPageY={footerPageY}
          />
        )
        break
      }
      case 'divider': {
        return (
          <View
            style={{
              marginHorizontal: 10,
              marginVertical: 3,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: 'white',
            }}
          ></View>
        )
      }
      case 'result':
        return <Result {...item} />
      case 'header':
        return (
          <Header
            options={item.data}
            uiSchema={item.uiSchema}
            selfScored={item.selfScored}
          />
        )
      case 'footer':
        return <Info title={item.data} isFooter={true} />
      default:
        return <Error {...item} />
        break
    }
  }

  showError = (errorMessage) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        errorMessage: errorMessage,
        dialogColor: '#0d47a1',
        showError: true,
      }
    })
  }

  closePopup = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        errorMessage: '',
        showError: false,
      }
    })
  }

  renderItem = ({ item }) => {
    let { subjectTimezone } = this.props
    subjectTimezone = momentTz().tz(subjectTimezone).format('z')
    if (Platform.OS === 'web') {
      return (
        <div ref={(elem) => (this.fieldRefs[item.key] = elem)}>{this.renderFormItem(item)}</div>
      )
    } else {
      return <View>{this.renderFormItem(item)}</View>
    }
  }

  renderSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          width: StyleSheet.hairlineWidth,
          backgroundColor: 'grey',
        }}
      />
    )
  }
  handlePrint = async (target) => {
    const {
      printOptions,
      formId,
      svfId,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      navigation,
      selectedVisitId,
    } = this.props
    let data = target.contentWindow.document.documentElement.outerHTML
    const printRequest = {
      formId: formId,
      content: '<!DOCTYPE html>' + data,
      header: printOptions.printHeader,
      footer: printOptions.printFooter,
      layout: printOptions.printLayout,
      svfId: svfId,
      subjectId: subjectId,
      visitFormOid: visitFormOid,
      training: isTraining,
      siteName: siteName,
      siteId:
        siteId !== 'null' && siteId !== null && siteId !== '' && siteId !== undefined
          ? siteId
          : null,
      recordNo: recordNo,
      selectedSubjectVisitId:
        selectedVisitId !== 'null' &&
          selectedVisitId !== null &&
          selectedVisitId !== '' &&
          selectedVisitId !== undefined
          ? selectedVisitId
          : null,
      primaryOrgCode: printOptions.primaryOrgCode,
      studyId: printOptions.studyId
    }
    const URL = await sendPrintData(printRequest)
    if (URL) {
      window.location.href = URL
    } else {
      navigation.navigate('PrintFail')
    }
  }

  disableSubmitButton = () => {
    const { selectedSvf, subjectTimezone } = this.props
    return disableForm(selectedSvf, subjectTimezone)
  }

  saveDraftDataToServer = (e) => {
    const { isSvfUpdated, selectedSvf, subjectTimezone } = this.props
    if (isSvfUpdated && !disableForm(selectedSvf, subjectTimezone)) {
      this.handleSubmit(false)
    }
  }

  render() {
    const { loading, formData, showError, errorMessage, dialogColor, currentPage } = this.state
    const {
      selectedSvf,
      mode,
      screenProps: { t },
      printOptions,
      form,
      fieldList,
      preview,
      onMobile
    } = this.props
    const { training } = selectedSvf
    if (loading) {
      return <Spinner color="#4e89ae" />
    }
    return (
      <View style={{ flex: 1, paddingHorizontal: 5 }}>
        <Popup
          color={dialogColor}
          visible={showError}
          message={t(errorMessage)}
          closePopup={this.closePopup}
          t={t}
        />
        {mode === 'printView' ? (
          <>
            <View
              style={{
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: Dimensions.get('window').height,
              }}
            >
              <Spinner color="#4e89ae" />
              <Text>Generating pdf...</Text>
            </View>
            <View style={{ visibility: 'hidden' }}>
              <ReactToPrint
                print={this.handlePrint}
                trigger={() => {
                  return (
                    <a
                      ref={(link) => (this.printElement = link)}
                      href="#"
                      style={{ display: 'none', fontSize: 20, color: 'black' }}
                    >
                      Print this out!
                    </a>
                  )
                }}
                pageStyle={
                  this.props.svfId
                    ? '@page { size: auto; margin: 150px 0px 100px 0px; } tr { page-break-inside: avoid;  } .gridHeader { page-break-inside: avoid;  }'
                    : '@page { size: auto; margin: 105px 0px 100px 0px; } tr { page-break-inside: avoid;  } .gridHeader { page-break-inside: avoid;  }'
                }
                content={() => this.componentRef}
              />
              <PrintContent
                ref={(el) => (this.componentRef = el)}
                data={formData}
                renderItem={this.renderItem}
                printOptions={printOptions}
              ></PrintContent>
            </View>
          </>
        ) : (
          <>
            <DiaryDisplay preview={preview} t={t}>
              { onMobile ? (
                <StepLayout
                form={form}
                selectedSvf={selectedSvf}
                formData={formData}
                renderFormItem={this.renderFormItem}
                handleSubmit={this.handleSubmit}
                disableSubmitButton={this.disableSubmitButton}
                training={training}
                t={t}
                fieldList={fieldList}
                setCurrentPage={this.setCurrentPage}
                currentPage={currentPage}
              /> ) : (
              <React.Fragment>
                <FlatList
                  initialNumToRender={20}
                  data={formData}
                  renderItem={this.renderItem}
                  keyExtractor={(item) => item.key}
                />
              
              <Authorize privilege="SUBMIT_FORM">
                <Default>
                  <View style={[styles.submitButtonContainer]}>
                    {this.disableSubmitButton() ? (
                      <Text />
                    ) : (
                      <TouchableOpacity
                        style={styles.submitButton}
                        onPress={() => this.handleSubmit(true)}
                      >
                        <Text
                          style={{
                            fontFamily: 'Inter',
                            alignSelf: 'center',
                            color: '#fff',
                            textTransform: 'uppercase',
                          }}
                        >
                          {training ? t('SubmitTraining') : t('Submit')}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </Default>
              </Authorize>
              </React.Fragment>
              )}
            </DiaryDisplay>
          </>
        )}
      </View>
    )
  }
}

FormConsumer.defaultProps = {
  footerPageY: 0,
}

FormConsumer.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  selectedSvf: PropTypes.instanceOf(Object).isRequired,
  fieldGroupList: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired, 
  subjectTimezone: PropTypes.string.isRequired, 
  formAnswers: PropTypes.array.isRequired,
  footerPageY: PropTypes.number,
} 

const mapStateToProps = (state) => ({
  onMobile: state?.appStatus?.onMobile
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      validateRules,
      storeDraftFormData,
      addItem,
      updateSelectedSvf,
      pushFormDataToQueue,
      updateFieldAnswer,
      updateSvfStatus,
      submitForm,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  createForm({
    onValuesChange: (props, changed, all) => {
      // props.actions.validateRules(changed, props)
      const fieldId = Object.keys(changed)[0]
      const selectedFieldValue = changed[fieldId]
      const currentChangedField = _.find(props.fieldList, field => _.isEqual(field.id, fieldId))
      const loChanged = {
        [currentChangedField.fieldOid]: selectedFieldValue
      }
      props.actions.updateFieldAnswer(loChanged, props.selectedSvf.svfId)
      // storeDraftFormData(changed, props.selectedSvf.form.id)
    },
    onFieldsChange: (props, changed, all) => {
      props.actions.updateSelectedSvf(true)
    },
  })(FormConsumer)
)