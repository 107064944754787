import { UPDATE_SELECTED_CALL } from '../actions/call'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

const call = (state = initialState.call, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_CALL:
      return {
        ...state,
        selectedCall: action.selectedCall,
      }
    case PURGE:
      return {
        selectedCall: {},
      }
    default:
      return state
  }
}

export default call
