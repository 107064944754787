export default {
"Africa/Abidjan":"افریقہ/ابیجان",
"Africa/Accra":"افریقہ/اکرا",
"Africa/Addis_Ababa":"افریقہ/اڈیس_اببا",
"Africa/Algiers":"افریقہ/الجیئرز",
"Africa/Asmara":"افریقہ/اسمارا",
"Africa/Asmera":"افریقہ/اسمیرا",
"Africa/Bamako":"افریقہ/باماکو",
"Africa/Bangui":"افریقہ/بنگوی",
"Africa/Banjul":"افریقہ/بنجول",
"Africa/Bissau":"افریقہ/بساؤ",
"Africa/Blantyre":"افریقہ/بلانٹائر",
"Africa/Brazzaville":"افریقہ/برازاویل",
"Africa/Bujumbura":"افریقہ/بوجمبورا",
"Africa/Cairo":"افریقہ/قاہرہ",
"Africa/Casablanca":"افریقہ/کاسابلانکا",
"Africa/Ceuta":"افریقہ/سیوٹا",
"Africa/Conakry":"افریقہ/کوناکری",
"Africa/Dakar":"افریقہ/ڈکار",
"Africa/Dar_es_Salaam":"افریقہ/دار_ایس_سلام",
"Africa/Djibouti":"افریقہ/جبوتی",
"Africa/Douala":"افریقہ/ڈوالا",
"Africa/El_Aaiun":"افریقہ/ایل_ایون",
"Africa/Freetown":"افریقہ/فری ٹاؤن",
"Africa/Gaborone":"افریقہ/گابرون",
"Africa/Harare":"افریقہ/ہرارے",
"Africa/Johannesburg":"افریقہ/جوہانسبرگ",
"Africa/Juba":"افریقہ/جوبا",
"Africa/Kampala":"افریقہ/کمپالا",
"Africa/Khartoum":"افریقہ/کھارتوم",
"Africa/Kigali":"افریقہ/کیگالی",
"Africa/Kinshasa":"افریقہ/کنشاسا",
"Africa/Lagos":"افریقہ/لاگوس",
"Africa/Libreville":"افریقہ/لیبرویل",
"Africa/Lome":"افریقہ/لوم",
"Africa/Luanda":"افریقہ/لوانڈا",
"Africa/Lubumbashi":"افریقہ/لوبومباشی",
"Africa/Lusaka":"افریقہ/لوساکا",
"Africa/Malabo":"افریقہ/مالابو",
"Africa/Maputo":"افریقہ/مپوٹو",
"Africa/Maseru":"افریقہ/ماسرو",
"Africa/Mbabane":"افریقہ/مبابین",
"Africa/Mogadishu":"افریقہ/موگادیشو",
"Africa/Monrovia":"افریقہ/مونروویا",
"Africa/Nairobi":"افریقہ/نیروبی",
"Africa/Ndjamena":"افریقہ/نڈجمینا",
"Africa/Niamey":"افریقہ/نیامی",
"Africa/Nouakchott":"افریقہ/نواچوٹ",
"Africa/Ouagadougou":"افریقہ/اواگڈوگو",
"Africa/Porto-Novo":"افریقہ/پورٹو نوو",
"Africa/Sao_Tome":"افریقہ/ساو_ٹوم",
"Africa/Timbuktu":"افریقہ/ٹمبکٹو",
"Africa/Tripoli":"افریقہ/طراپولی",
"Africa/Tunis":"افریقہ/تیونس",
"Africa/Windhoek":"افریقہ/ونڈہوک",
"America/Adak":"امریکا/ایڈک",
"America/Anchorage":"امریکا/اینکرج",
"America/Anguilla":"امریکا/انجویلا",
"America/Antigua":"امریکا/اینٹیگوا",
"America/Araguaina":"امریکا/اراگوئینا",
"America/Argentina/Buenos_Aires":"امریکہ/ارجنٹائن/بوئنوس_آئرس",
"America/Argentina/Catamarca":"امریکہ/ارجنٹینا/کاٹامارکا",
"America/Argentina/ComodRivadavia":"امریکا/ارجنٹینا/کوموڈریواڈویا",
"America/Argentina/Cordoba":"امریکہ/ارجنٹائنا/کارڈوبا",
"America/Argentina/Jujuy":"امریکہ/ارجنٹینا/جوجوئی",
"America/Argentina/La_Rioja":"امریکا/ارجنٹینا/لا ریوجا",
"America/Argentina/Mendoza":"امریکہ/ارجنٹینا/مینڈوزا",
"America/Argentina/Rio_Gallegos":"امریکہ/ارجنٹینا/ریو_گیلیگوس",
"America/Argentina/Salta":"امریکہ/ارجنٹینا/سالٹا",
"America/Argentina/San_Juan":"امریکہ/ارجنٹینا/سان_جوان",
"America/Argentina/San_Luis":"امریکہ/ارجنٹینا/سان_لوئس",
"America/Argentina/Tucuman":"امریکہ/ارجنٹائن/ٹوکومن",
"America/Argentina/Ushuaia":"امریکہ/ارجنٹائنا/اوشوایا",
"America/Aruba":"امریکہ/اروبا",
"America/Asuncion":"امریکا/اسونسین",
"America/Atikokan":"امریکا/اتیکوکان",
"America/Atka":"امریکا/اٹکا",
"America/Bahia":"امریکا/باہیا",
"America/Bahia_Banderas":"امریکا/باہیا_بینڈراس",
"America/Barbados":"امریکہ/بارباڈوس",
"America/Belem":"امریکا/بیلیم",
"America/Belize":"امریکہ/بیلیز",
"America/Blanc-Sablon":"امریکا/بلانک سبلن",
"America/Boa_Vista":"امریکا/بوا_وسٹا",
"America/Bogota":"امریکا/بوگوٹا",
"America/Boise":"امریکا/بوئس",
"America/Buenos_Aires":"امریکا/بوئنوس_آئرس",
"America/Cambridge_Bay":"امریکا/کیمبرج_بے",
"America/Campo_Grande":"امریکا/کیمپو_گرانڈے",
"America/Cancun":"امریکا/کینکون",
"America/Caracas":"امریکہ/کاراکاس",
"America/Catamarca":"امریکا/کاٹامارکا",
"America/Cayenne":"امریکا/کیین",
"America/Cayman":"امریکہ/کیمین",
"America/Chicago":"امریکا/شکاگو",
"America/Chihuahua":"امریکا/چیہواوا",
"America/Ciudad_Juarez":"امریکا/سیوڈڈ_جواریز",
"America/Coral_Harbour":"امریکا/کورل_ہاربر",
"America/Cordoba":"امریکا/کارڈوبا",
"America/Costa_Rica":"امریکا/کوسٹا_ریکا",
"America/Creston":"امریکا/کریسٹن",
"America/Cuiaba":"امریکا/کیابا",
"America/Curacao":"امریکا/کوراکاؤ",
"America/Danmarkshavn":"امریکا/ڈنمارک شاون",
"America/Dawson":"امریکا/ڈاوسن",
"America/Dawson_Creek":"امریکا/ڈاوسن کریک",
"America/Denver":"امریکہ/ڈینور",
"America/Detroit":"امریکا/ڈیٹرائٹ",
"America/Dominica":"امریکہ/ڈومینیکا",
"America/Edmonton":"امریکا/ایڈمنٹن",
"America/Eirunepe":"امریکا/ایرونیپ",
"America/El_Salvador":"امریکا/ایل_سلواڈور",
"America/Ensenada":"امریکا/اینسیناڈا",
"America/Fort_Nelson":"امریکا/فورٹ_نیلسن",
"America/Fort_Wayne":"امریکا/فورٹ_وین",
"America/Fortaleza":"امریکا/فورٹیلیزا",
"America/Glace_Bay":"امریکا/گلیس_بے",
"America/Godthab":"امریکا/گودتھاب",
"America/Goose_Bay":"امریکا/گوز_بے",
"America/Grand_Turk":"امریکا/گرانڈ_ٹرک",
"America/Grenada":"امریکہ/گریناڈا",
"America/Guadeloupe":"امریکا/گواڈیلوپ",
"America/Guatemala":"امریکہ/گوئٹے مالا",
"America/Guayaquil":"امریکا/گیاکیل",
"America/Guyana":"امریکہ/گیانا",
"America/Halifax":"امریکا/ہیلی فیکس",
"America/Havana":"امریکہ/ہوانا",
"America/Hermosillo":"امریکا/ہرموسیلو",
"America/Indiana/Indianapolis":"امریکہ/انڈیانا/انڈیاناپولس",
"America/Indiana/Knox":"امریکہ/انڈیانا/ناکس",
"America/Indiana/Marengo":"امریکہ/انڈیانا/مارینگو",
"America/Indiana/Petersburg":"امریکہ/انڈیانا/پیٹرزبرگ",
"America/Indiana/Tell_City":"امریکہ/انڈیانا/ٹیل_سٹی",
"America/Indiana/Vevay":"امریکہ/انڈیانا/ویوے",
"America/Indiana/Vincennes":"امریکہ/انڈیانا/ونسینس",
"America/Indiana/Winamac":"امریکہ/انڈیانا/وناماک",
"America/Indianapolis":"امریکہ/انڈیاناپولس",
"America/Inuvik":"امریکا/انوویک",
"America/Iqaluit":"امریکا/اقالوت",
"America/Jamaica":"امریکہ/جمیکا",
"America/Jujuy":"امریکہ/جوجوئی",
"America/Juneau":"امریکا/جونیو",
"America/Kentucky/Louisville":"امریکہ/کینٹکی/لوئس ویل",
"America/Kentucky/Monticello":"امریکا/کینٹکی/مونٹیسیلو",
"America/Knox_IN":"امریکا/ناکس_ان",
"America/Kralendijk":"امریکا/کرالینڈیجک",
"America/La_Paz":"امریکا/لا_پاز",
"America/Lima":"امریکا/لیما",
"America/Los_Angeles":"امریکا/لوس_اینجلس",
"America/Louisville":"امریکہ/لوئس ویل",
"America/Lower_Princes":"امریکا/لوئر_پرنزس",
"America/Maceio":"امریکا/مسیو",
"America/Managua":"امریکہ/ماناگوا",
"America/Manaus":"امریکا/مانوس",
"America/Marigot":"امریکا/ماریگوٹ",
"America/Martinique":"امریکا/مارٹنیک",
"America/Matamoros":"امریکا/ماتاماروس",
"America/Mazatlan":"امریکا/مزاتلان",
"America/Mendoza":"امریکا/مینڈوزا",
"America/Menominee":"امریکا/مینومینی",
"America/Merida":"امریکا/میریڈا",
"America/Metlakatla":"امریکہ/میٹلاکاٹلا",
"America/Mexico_City":"امریکا/میکسیکو_سٹی",
"America/Miquelon":"امریکا/میکویلن",
"America/Moncton":"امریکہ/مونکٹن",
"America/Monterrey":"امریکا/مونٹیری",
"America/Montevideo":"امریکا/مونٹیویڈیو",
"America/Montreal":"امریکہ/مونٹریال",
"America/Montserrat":"امریکہ/مونٹسیریٹ",
"America/Nassau":"امریکہ/ناساؤ",
"America/New_York":"امریکا/نیو_یارک",
"America/Nipigon":"امریکا/نیپیگون",
"America/Nome":"امریکا/نام",
"America/Noronha":"امریکا/نورونہا",
"America/North_Dakota/Beulah":"امریکا/شمالی ڈاکوتا/بیولہ",
"America/North_Dakota/Center":"امریکا/شمالی ڈاکوٹا/سینٹر",
"America/North_Dakota/New_Salem":"امریکا/شمالی ڈاکوٹا/نیو_سیلم",
"America/Nuuk":"امریکا/نیوک",
"America/Ojinaga":"امریکا/اوجیناگا",
"America/Panama":"امریکا/پاناما",
"America/Pangnirtung":"امریکا/پینگرٹنگ",
"America/Paramaribo":"امریکا/پیرامریبو",
"America/Phoenix":"امریکہ/فینکس",
"America/Port-au-Prince":"امریکا/پورٹ او پرنس",
"America/Port_of_Spain":"امریکا/پورٹ_آف_اسپین",
"America/Porto_Acre":"امریکا/پورٹو_ایکر",
"America/Porto_Velho":"امریکا/پورٹو_ویلہو",
"America/Puerto_Rico":"امریکا/پیرٹو_ریکو",
"America/Punta_Arenas":"امریکا/پنٹا_اریناس",
"America/Rainy_River":"امریکا/رینی_ریور",
"America/Rankin_Inlet":"امریکا/رینکن_انلیٹ",
"America/Recife":"امریکا/ریسیف",
"America/Regina":"امریکا/ریجینا",
"America/Resolute":"امریکہ/ریسولیٹ",
"America/Rio_Branco":"امریکا/ریو_برانکو",
"America/Rosario":"امریکا/روساریو",
"America/Santa_Isabel":"امریکا/سانٹا_اسابیل",
"America/Santarem":"امریکا/سینٹارم",
"America/Santiago":"امریکا/سینٹیاگو",
"America/Santo_Domingo":"امریکا/سینٹو_ڈومنگو",
"America/Sao_Paulo":"امریکا/ساو_پالو",
"America/Scoresbysund":"امریکا/سکورسبی سونڈ",
"America/Shiprock":"امریکا/شپروک",
"America/Sitka":"امریکا/سیٹکا",
"America/St_Barthelemy":"امریکا/سینٹ_بارتھلیمی",
"America/St_Johns":"امریکا/سینٹ_جانز",
"America/St_Kitts":"امریکا/سینٹ_کٹس",
"America/St_Lucia":"امریکا/سینٹ لوسیا",
"America/St_Thomas":"امریکا/سینٹ_تھامس",
"America/St_Vincent":"امریکا/سینٹ_ونسنٹ",
"America/Swift_Current":"امریکا/سوئفٹ_کرنٹ",
"America/Tegucigalpa":"امریکا/ٹیگوسیگالپا",
"America/Thule":"امریکا/تھولے",
"America/Thunder_Bay":"امریکا/تھنڈر_بے",
"America/Tijuana":"امریکہ/تیجوانا",
"America/Toronto":"امریکہ/ٹورنٹو",
"America/Tortola":"امریکا/ٹورٹولا",
"America/Vancouver":"امریکہ/وینکوور",
"America/Virgin":"امریکہ/ورجن",
"America/Whitehorse":"امریکہ/وائٹ ہارس",
"America/Winnipeg":"امریکا/ونیپیگ",
"America/Yakutat":"امریکا/یاکوتاٹ",
"America/Yellowknife":"امریکا/یلو نائف",
"Antarctica/Casey":"انٹارکٹیکا/کیسی",
"Antarctica/Davis":"انٹارکٹیکا/ڈیوس",
"Antarctica/DumontDUrville":"انٹارکٹیکا/ڈومنٹ ڈورویل",
"Antarctica/Macquarie":"انٹارکٹیکا/میکویری",
"Antarctica/Mawson":"انٹارکٹیکا/موسن",
"Antarctica/McMurdo":"انٹارکٹیکا/میک مرڈو",
"Antarctica/Palmer":"انٹارکٹیکا/پامر",
"Antarctica/Rothera":"انٹارکٹیکا/روتھرا",
"Antarctica/South_Pole":"انٹارکٹیکا/ساؤتھ پول",
"Antarctica/Syowa":"انٹارکٹیکا/سیووا",
"Antarctica/Troll":"انٹارکٹیکا/ٹرول",
"Antarctica/Vostok":"انٹارکٹیکا/ووسٹوک",
"Arctic/Longyearbyen":"آرکٹک/لانگیئر بیئن",
"Asia/Aden":"ایشیا/ایڈین",
"Asia/Almaty":"ایشیا/الماتی",
"Asia/Amman":"ایشیا/عمان",
"Asia/Anadyr":"ایشیا/اناڈیر",
"Asia/Aqtau":"ایشیا/اقتاو",
"Asia/Aqtobe":"ایشیا/اکتوب",
"Asia/Ashgabat":"ایشیا/اشگابت",
"Asia/Ashkhabad":"ایشیا/اشخآباد",
"Asia/Atyrau":"ایشیا/اتریاؤ",
"Asia/Baghdad":"ایشیا/بغداد",
"Asia/Bahrain":"ایشیا/بحرین",
"Asia/Baku":"ایشیا/باکو",
"Asia/Bangkok":"ایشیا/بینکاک",
"Asia/Barnaul":"ایشیا/بارنول",
"Asia/Beirut":"ایشیا/بیروت",
"Asia/Bishkek":"ایشیا/بشکیک",
"Asia/Brunei":"ایشیا/برونائی",
"Asia/Calcutta":"ایشیا/کلکتہ",
"Asia/Chita":"ایشیا/چیٹا",
"Asia/Choibalsan":"ایشیا/چوبیلسن",
"Asia/Chongqing":"ایشیا/چونگ کنگ",
"Asia/Chungking":"ایشیا/چنگکنگ",
"Asia/Colombo":"ایشیا/کولمبو",
"Asia/Dacca":"ایشیا/ڈاکا",
"Asia/Damascus":"ایشیا/دمشق",
"Asia/Dhaka":"ایشیا/ڈھاکا",
"Asia/Dili":"ایشیا/دیلی",
"Asia/Dubai":"ایشیا/دبئی",
"Asia/Dushanbe":"ایشیا/دوشنبے",
"Asia/Famagusta":"ایشیا/فاماگوستا",
"Asia/Gaza":"ایشیا/غزا",
"Asia/Harbin":"ایشیا/ہاربن",
"Asia/Hebron":"ایشیا/ہیبرون",
"Asia/Ho_Chi_Minh":"ایشیا/ہو_چی_من",
"Asia/Hong_Kong":"ایشیا/ہانگ کانگ",
"Asia/Hovd":"ایشیا/ہووڈ",
"Asia/Irkutsk":"ایشیا/ارکٹسک",
"Asia/Istanbul":"ایشیا/استنبول",
"Asia/Jakarta":"ایشیا/جکارتہ",
"Asia/Jayapura":"ایشیا/جایاپورا",
"Asia/Jerusalem":"ایشیا/یروشلم",
"Asia/Kabul":"ایشیا/کابل",
"Asia/Kamchatka":"ایشیا/کامچٹکا",
"Asia/Karachi":"ایشیا/کراچی",
"Asia/Kashgar":"ایشیا/کشگر",
"Asia/Kathmandu":"ایشیا/کاتھمنڈو",
"Asia/Katmandu":"ایشیا/کاٹمانڈو",
"Asia/Khandyga":"ایشیا/کھنڈیگا",
"Asia/Kolkata":"ایشیا/کولکتہ",
"Asia/Krasnoyarsk":"ایشیا/کراسنوئارسک",
"Asia/Kuala_Lumpur":"ایشیا/کوالا_لمپور",
"Asia/Kuching":"ایشیا/کوچنگ",
"Asia/Kuwait":"ایشیا/کویت",
"Asia/Macao":"ایشیا/مکاؤ",
"Asia/Macau":"ایشیا/مکاؤ",
"Asia/Magadan":"ایشیا/مگادن",
"Asia/Makassar":"ایشیا/مکاسر",
"Asia/Manila":"ایشیا/منیلا",
"Asia/Muscat":"ایشیا/مسقط",
"Asia/Nicosia":"ایشیا/نیکوسیا",
"Asia/Novokuznetsk":"ایشیا/نووکوزنیٹسک",
"Asia/Novosibirsk":"ایشیا/نووسیبیرسک",
"Asia/Omsk":"ایشیا/اومسک",
"Asia/Oral":"ایشیا/زبانی",
"Asia/Phnom_Penh":"ایشیا/فنوم_پین",
"Asia/Pontianak":"ایشیا/پونٹیاناک",
"Asia/Pyongyang":"ایشیا/پیونگیانگ",
"Asia/Qatar":"ایشیا/قطر",
"Asia/Qostanay":"ایشیا/کوسٹانی",
"Asia/Qyzylorda":"ایشیا/کیزلورڈا",
"Asia/Rangoon":"ایشیا/رنگون",
"Asia/Riyadh":"ایشیا/ریاض",
"Asia/Saigon":"ایشیا/سائگن",
"Asia/Sakhalin":"ایشیا/سخالین",
"Asia/Samarkand":"ایشیا/سمرقند",
"Asia/Seoul":"ایشیا/سیئول",
"Asia/Shanghai":"ایشیا/شنگھائی",
"Asia/Singapore":"ایشیا/سنگاپور",
"Asia/Srednekolymsk":"ایشیا/سریڈنیکولیمسک",
"Asia/Taipei":"ایشیا/تائپی",
"Asia/Tashkent":"ایشیا/تاشکنڈ",
"Asia/Tbilisi":"ایشیا/تبلیسی",
"Asia/Tehran":"ایشیا/تہران",
"Asia/Tel_Aviv":"ایشیا/ٹیل_ابیو",
"Asia/Thimbu":"ایشیا/تھیمبو",
"Asia/Thimphu":"ایشیا/تھیمفو",
"Asia/Tokyo":"ایشیا/ٹوکیو",
"Asia/Tomsk":"ایشیا/ٹامسک",
"Asia/Ujung_Pandang":"ایشیا/اوجنگ_پانڈنگ",
"Asia/Ulaanbaatar":"ایشیا/اولانباتور",
"Asia/Ulan_Bator":"ایشیا/الان_باٹر",
"Asia/Urumqi":"ایشیا/ارومقی",
"Asia/Ust-Nera":"ایشیا/اوسٹ نیرا",
"Asia/Vientiane":"ایشیا/وینٹین",
"Asia/Vladivostok":"ایشیا/ولادیووسٹوک",
"Asia/Yakutsk":"ایشیا/یاکوٹسک",
"Asia/Yangon":"ایشیا/یانگون",
"Asia/Yekaterinburg":"ایشیا/یکاٹیرنبرگ",
"Asia/Yerevan":"ایشیا/یریوان",
"Atlantic/Azores":"اٹلانٹک/ایزوریس",
"Atlantic/Bermuda":"اٹلانٹک/برمودا",
"Atlantic/Canary":"اٹلانٹک/کینری",
"Atlantic/Cape_Verde":"اٹلانٹک/کیپ_ورڈی",
"Atlantic/Faeroe":"اٹلانٹک/فیرو",
"Atlantic/Faroe":"اٹلانٹک/فارو",
"Atlantic/Jan_Mayen":"اٹلانٹک/جن_میئن",
"Atlantic/Madeira":"اٹلانٹک/میڈیرا",
"Atlantic/Reykjavik":"اٹلانٹک/ریکیوک",
"Atlantic/South_Georgia":"اٹلانٹک/جنوبی جارجیا",
"Atlantic/St_Helena":"اٹلانٹک/سینٹ_ہیلینا",
"Atlantic/Stanley":"اٹلانٹک/اسٹینلے",
"Australia/ACT":"آسٹریلیا/ایکٹ",
"Australia/Adelaide":"آسٹریلیا/ایڈیلیڈ",
"Australia/Brisbane":"آسٹریلیا/برسبین",
"Australia/Broken_Hill":"آسٹریلیا/بروکن_ہل",
"Australia/Canberra":"آسٹریلیا/کینبیرا",
"Australia/Currie":"آسٹریلیا/کیری",
"Australia/Darwin":"آسٹریلیا/ڈارون",
"Australia/Eucla":"آسٹریلیا/یوکلا",
"Australia/Hobart":"آسٹریلیا/ہوبارٹ",
"Australia/LHI":"آسٹریلیا/ایل ایچ آئی",
"Australia/Lindeman":"آسٹریلیا/لنڈیمن",
"Australia/Lord_Howe":"آسٹریلیا/لارڈ_ہوو",
"Australia/Melbourne":"آسٹریلیا/میلبورن",
"Australia/NSW":"آسٹریلیا/این ایس ڈبلیو",
"Australia/North":"آسٹریلیا/شمالی",
"Australia/Perth":"آسٹریلیا/پرتھ",
"Australia/Queensland":"آسٹریلیا/کوئینزلینڈ",
"Australia/South":"آسٹریلیا/جنوبی",
"Australia/Sydney":"آسٹریلیا/سڈنی",
"Australia/Tasmania":"آسٹریلیا/تسمانیہ",
"Australia/Victoria":"آسٹریلیا/وکٹوریہ",
"Australia/West":"آسٹریلیا/مغربی",
"Australia/Yancowinna":"آسٹریلیا/یانکووینا",
"Brazil/Acre":"برازیل/ایکڑ",
"Brazil/DeNoronha":"برازیل/ڈینورونہا",
"Brazil/East":"برازیل/مشرق",
"Brazil/West":"برازیل/مغربی",
"CET":"یہ",
"CST6CDT":"سی ایس ٹی 6 سی ڈی ٹی",
"Canada/Atlantic":"کینیڈا/اٹلانٹک",
"Canada/Central":"کینیڈا/سنٹرل",
"Canada/Eastern":"کینیڈا/مشرقی",
"Canada/Mountain":"کینیڈا/ماؤنٹین",
"Canada/Newfoundland":"کینیڈا/نیو فاؤنڈ لینڈ",
"Canada/Pacific":"کینیڈا/پیسیفک",
"Canada/Saskatchewan":"کینیڈا/ساسکاچیوان",
"Canada/Yukon":"کینیڈا/یوکون",
"Chile/Continental":"چلی/کانٹینٹینل",
"Chile/EasterIsland":"چلی/ایسٹر آئلینڈ",
"Cuba":"کیوبا",
"EET":"کھانا",
"EST":"ایسٹ",
"EST5EDT":"ایسٹ 5 ڈی ٹی",
"Egypt":"مصر",
"Eire":"ایئر",
"Etc/GMT":"وغیرہ/جی ایم ٹی",
"Etc/GMT+0":"وغیرہ/جی ایم ٹی+0",
"Etc/GMT+1":"ایٹسی/جی ایم ٹی +1",
"Etc/GMT+10":"ایٹسی/جی ایم ٹی +10",
"Etc/GMT+11":"ایٹسی/جی ایم ٹی +11",
"Etc/GMT+12":"ایٹسی/جی ایم ٹی +12",
"Etc/GMT+2":"ایٹسی/جی ایم ٹی +2",
"Etc/GMT+3":"وغیرہ/جی ایم ٹی +3",
"Etc/GMT+4":"وغیرہ/جی ایم ٹی +4",
"Etc/GMT+5":"ایٹسی/جی ایم ٹی +5",
"Etc/GMT+6":"وغیرہ/جی ایم ٹی +6",
"Etc/GMT+7":"ایٹسی/جی ایم ٹی +7",
"Etc/GMT+8":"وغیرہ/جی ایم ٹی+8",
"Etc/GMT+9":"ایٹسی/جی ایم ٹی +9",
"Etc/GMT-0":"وغیرہ/جی ایم ٹی -0",
"Etc/GMT-1":"ایٹسی/جی ایم ٹی 1",
"Etc/GMT-10":"ایٹسی/جی ایم ٹی -10",
"Etc/GMT-11":"ایٹسی/جی ایم ٹی -11",
"Etc/GMT-12":"ایٹسی/جی ایم ٹی -12",
"Etc/GMT-13":"ایٹسی/جی ایم ٹی -13",
"Etc/GMT-14":"ایٹسی/جی ایم ٹی -14",
"Etc/GMT-2":"ایٹسی/جی ایم ٹی 2",
"Etc/GMT-3":"وغیرہ/جی ایم ٹی 3",
"Etc/GMT-4":"وغیرہ/جی ایم ٹی 4",
"Etc/GMT-5":"ایٹسی/جی ایم ٹی 5",
"Etc/GMT-6":"وغیرہ/جی ایم ٹی -6",
"Etc/GMT-7":"ایٹسی/جی ایم ٹی 7",
"Etc/GMT-8":"وغیرہ/جی ایم ٹی -8",
"Etc/GMT-9":"وغیرہ/جی ایم ٹی 9",
"Etc/GMT0":"وغیرہ/جی ایم ٹی 0",
"Etc/Greenwich":"وغیرہ/گرین وچ",
"Etc/UCT":"ای ٹی سی/یو سی ٹی",
"Etc/UTC":"وغیرہ/یو ٹی سی",
"Etc/Universal":"وغیرہ/یونیورسل",
"Etc/Zulu":"وغیرہ/زولو",
"Europe/Amsterdam":"یورپ/ایمسٹرڈیم",
"Europe/Andorra":"یورپ/انڈورا",
"Europe/Astrakhan":"یورپ/آسٹراخان",
"Europe/Athens":"یورپ/ایتھنز",
"Europe/Belfast":"یورپ/بیلفاسٹ",
"Europe/Belgrade":"یورپ/بلغراد",
"Europe/Berlin":"یورپ/برلن",
"Europe/Bratislava":"یورپ/براٹیسلاوا",
"Europe/Brussels":"یورپ/برسلز",
"Europe/Bucharest":"یورپ/بخارسٹ",
"Europe/Budapest":"یورپ/بوڈاپسٹ",
"Europe/Busingen":"یورپ/بوسینگن",
"Europe/Chisinau":"یورپ/چیسینو",
"Europe/Copenhagen":"یورپ/کوپن ہیگن",
"Europe/Dublin":"یورپ/ڈبلن",
"Europe/Gibraltar":"یورپ/جبرالٹر",
"Europe/Guernsey":"یورپ/گورنسی",
"Europe/Helsinki":"یورپ/ہیلسنکی",
"Europe/Isle_of_Man":"یورپ/جزیرہ آف مین",
"Europe/Istanbul":"یورپ/استنبول",
"Europe/Jersey":"یورپ/جرسی",
"Europe/Kaliningrad":"یورپ/کالینینگراڈ",
"Europe/Kiev":"یورپ/کیف",
"Europe/Kirov":"یورپ/کیروف",
"Europe/Kyiv":"یورپ/کیف",
"Europe/Lisbon":"یورپ/لزبن",
"Europe/Ljubljana":"یورپ/لوبلیانا",
"Europe/London":"یورپ/لندن",
"Europe/Luxembourg":"یورپ/لکسمبرگ",
"Europe/Madrid":"یورپ/میڈرڈ",
"Europe/Malta":"یورپ/مالٹا",
"Europe/Mariehamn":"یورپ/مریہامن",
"Europe/Minsk":"یورپ/منسک",
"Europe/Monaco":"یورپ/موناکو",
"Europe/Moscow":"یورپ/ماسکو",
"Europe/Nicosia":"یورپ/نیکوسیا",
"Europe/Oslo":"یورپ/اوسلو",
"Europe/Paris":"یورپ/پیرس",
"Europe/Podgorica":"یورپ/پوڈگوریکا",
"Europe/Prague":"یورپ/پراگ",
"Europe/Riga":"یورپ/ریگا",
"Europe/Rome":"یورپ/روم",
"Europe/Samara":"یورپ/سمارا",
"Europe/San_Marino":"یورپ/سان_مارینو",
"Europe/Sarajevo":"یورپ/سارایوو",
"Europe/Saratov":"یورپ/ساراٹوف",
"Europe/Simferopol":"یورپ/سمفیروپول",
"Europe/Skopje":"یورپ/اسکوپجے",
"Europe/Sofia":"یورپ/صوفیہ",
"Europe/Stockholm":"یورپ/اسٹاک ہوم",
"Europe/Tallinn":"یورپ/ٹلن",
"Europe/Tirane":"یورپ/ٹیرین",
"Europe/Tiraspol":"یورپ/ٹیراسپول",
"Europe/Ulyanovsk":"یورپ/الیانوفسک",
"Europe/Uzhgorod":"یورپ/ازگوروڈ",
"Europe/Vaduz":"یورپ/وڈوز",
"Europe/Vatican":"یورپ/ویٹیکن",
"Europe/Vienna":"یورپ/ویانا",
"Europe/Vilnius":"یورپ/ویلنیس",
"Europe/Volgograd":"یورپ/وولگوگراڈ",
"Europe/Warsaw":"یورپ/وارسا",
"Europe/Zagreb":"یورپ/زگریب",
"Europe/Zaporozhye":"یورپ/زاپوروزیے",
"Europe/Zurich":"یورپ/زیورخ",
"GB":"جی بی",
"GB-Eire":"جی بی آئیر",
"GMT":"جی ایم ٹی",
"GMT+0":"جی ایم ٹی+0",
"GMT-0":"جی ایم ٹی -0",
"GMT0":"جی ایم ٹی 0",
"Greenwich":"گرین وچ",
"HST":"ایچ ایس ٹی",
"Hongkong":"ہانگ کانگ",
"Iceland":"آئس لینڈ",
"Indian/Antananarivo":"ہندوستانی/انتاناناریوو",
"Indian/Chagos":"ہندوستانی/چاگوس",
"Indian/Christmas":"ہندوستانی/کرسمس",
"Indian/Cocos":"ہندوستانی/کوکوس",
"Indian/Comoro":"ہندوستانی/کومورو",
"Indian/Kerguelen":"ہندوستانی/کرگیلن",
"Indian/Mahe":"ہندوستانی/ماہی",
"Indian/Maldives":"ہندوستانی/مالدیپ",
"Indian/Mauritius":"ہندوستانی/ماریشس",
"Indian/Mayotte":"ہندوستانی/میوٹ",
"Indian/Reunion":"ہندوستانی/رییونین",
"Iran":"ایران",
"Israel":"اسرائیل",
"Jamaica":"جمیکا",
"Japan":"جاپان",
"Kwajalein":"کواجلین",
"Libya":"لیبیا",
"MET":"ملاقات",
"MST":"ایم ایس ٹی",
"MST7MDT":"ایم ایس ٹی 7 ایم ڈی ٹی",
"Mexico/BajaNorte":"میکسیکو/باجانورٹ",
"Mexico/BajaSur":"میکسیکو/باجاسور",
"Mexico/General":"میکسیکو/جنرل",
"NZ":"این زیڈ",
"NZ-CHAT":"این زیڈ چیٹ",
"Navajo":"نواجو",
"PRC":"پی آر سی",
"PST8PDT":"پی ایس ٹی 8 پی ڈی ٹی",
"Pacific/Apia":"پیسیفک/اپیا",
"Pacific/Auckland":"پیسیفک/آکلینڈ",
"Pacific/Bougainville":"پیسیفک/بوگین ویل",
"Pacific/Chatham":"پیسیفک/چیتھم",
"Pacific/Chuuk":"پیسیفک/چوک",
"Pacific/Easter":"پیسیفک/ایسٹر",
"Pacific/Efate":"پیسیفک/ایفیٹ",
"Pacific/Enderbury":"پیسیفک/اینڈربری",
"Pacific/Fakaofo":"پیسیفک/فکااوفو",
"Pacific/Fiji":"پیسیفک/فجی",
"Pacific/Funafuti":"پیسیفک/فونافوٹی",
"Pacific/Galapagos":"پیسیفک/گالاپاگوس",
"Pacific/Gambier":"پیسیفک/گیمبیئر",
"Pacific/Guadalcanal":"پیسیفک/گوڈالکینال",
"Pacific/Guam":"پیسیفک/گوام",
"Pacific/Honolulu":"پیسیفک/ہونولولو",
"Pacific/Johnston":"پیسیفک/جانسٹن",
"Pacific/Kanton":"پیسیفک/کینٹن",
"Pacific/Kiritimati":"پیسیفک/کیریتیمتی",
"Pacific/Kosrae":"پیسیفک/کوسرائے",
"Pacific/Kwajalein":"پیسیفک/کواجلین",
"Pacific/Majuro":"پیسیفک/میجورو",
"Pacific/Marquesas":"پیسیفک/مارکیساس",
"Pacific/Midway":"پیسیفک/مڈ وے",
"Pacific/Nauru":"پیسیفک/ناروو",
"Pacific/Niue":"پیسیفک/نیو",
"Pacific/Norfolk":"پیسیفک/نورفولک",
"Pacific/Noumea":"پیسیفک/نومیا",
"Pacific/Pago_Pago":"پیسیفک/پیگو_پیگو",
"Pacific/Palau":"پیسیفک/پلاؤ",
"Pacific/Pitcairn":"پیسیفک/پٹکیرن",
"Pacific/Pohnpei":"پیسیفک/پوہنپی",
"Pacific/Ponape":"پیسیفک/پونیپ",
"Pacific/Port_Moresby":"پیسیفک/پورٹ_مورسبی",
"Pacific/Rarotonga":"پیسیفک/ریروٹونگا",
"Pacific/Saipan":"پیسیفک/سیپن",
"Pacific/Samoa":"پیسیفک/ساموا",
"Pacific/Tahiti":"پیسیفک/تاہیٹی",
"Pacific/Tarawa":"پیسیفک/تاراوا",
"Pacific/Tongatapu":"پیسیفک/ٹونگٹاپو",
"Pacific/Truk":"پیسیفک/ٹرک",
"Pacific/Wake":"پیسیفک/ویک",
"Pacific/Wallis":"پیسیفک/والیس",
"Pacific/Yap":"پیسیفک/یپ",
"Poland":"پولینڈ",
"Portugal":"پرتگال",
"ROC":"روک",
"ROK":"سال",
"Singapore":"سنگاپور",
"Turkey":"ترکی",
"UCT":"یو سی ٹی",
"US/Alaska":"امریکا/الاسکا",
"US/Aleutian":"امریکا/الیوٹین",
"US/Arizona":"امریکا/ایریزونا",
"US/Central":"امریکا/وسطی",
"US/East-Indiana":"امریکا/مشرقی انڈیانا",
"US/Eastern":"امریکا/مشرقی",
"US/Hawaii":"امریکا/ہوائی",
"US/Indiana-Starke":"امریکا/انڈیانا اسٹارک",
"US/Michigan":"امریکا/مشی گن",
"US/Mountain":"امریکا/پہاڑ",
"US/Pacific":"امریکا/پیسیفک",
"US/Samoa":"امریکا/ساموا",
"UTC":"یو ٹی سی",
"Universal":"یونیورسل",
"W-SU":"ڈبلیو سو",
"WET":"گیلا",
"Zulu":"زولو",
}