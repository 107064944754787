import { STORE_DEVICE_LOCATION_DETAILS } from '../actions/deviceLocation'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

export default (state = initialState.deviceLocation, action) => {
  switch (action.type) {
    case STORE_DEVICE_LOCATION_DETAILS:
      return {
        ...state,
        ...action.data,
      }
    case PURGE:
      return {
        ip: null,
        type: null,
        continent_code: null,
        continent_name: null,
        country_code: null,
        country_name: null,
        region_code: null,
        region_name: null,
        city: null,
        zip: null,
        latitude: null,
        longitude: null,
        location: {
          geoname_id: null,
          capital: null,
          languages: [],
          country_flag: null,
          country_flag_emoji: null,
          country_flag_emoji_unicode: null,
          calling_code: null,
          is_eu: false,
        },
      }
    default:
      return state
  }
}
