export default {
    "Africa/Abidjan":"Afrika/Abidjan",
"Africa/Accra":"Afrika/Accra",
"Africa/Addis_Ababa":"Afrika/Addis_Abeba",
"Africa/Algiers":"Afrika/Alger",
"Africa/Asmara":"Afrika/Asmara",
"Africa/Asmera":"Afrika/Asmera",
"Africa/Bamako":"Afrika/Bamako",
"Africa/Bangui":"Afrika/Bangui",
"Africa/Banjul":"Afrika/Banjul",
"Africa/Bissau":"Afrika/Bissau",
"Africa/Blantyre":"Afrika/Blantyre",
"Africa/Brazzaville":"Afrika/Brazzaville",
"Africa/Bujumbura":"Afrika/Bujumbura",
"Africa/Cairo":"Afrika/Kairo",
"Africa/Casablanca":"Afrika/Casablanca",
"Africa/Ceuta":"Afrika/Ceuta",
"Africa/Conakry":"Afrika/Conakry",
"Africa/Dakar":"Afrika/Dakar",
"Africa/Dar_es_Salaam":"Afrika/Dar_es_Salaam",
"Africa/Djibouti":"Afrika/Djibouti",
"Africa/Douala":"Afrika/Douala",
"Africa/El_Aaiun":"Afrika/El_Aaiun",
"Africa/Freetown":"Afrika/Freetown",
"Africa/Gaborone":"Afrika/Gaborone",
"Africa/Harare":"Afrika/Harare",
"Africa/Johannesburg":"Afrika/Johannesburg",
"Africa/Juba":"Afrika/Juba",
"Africa/Kampala":"Afrika/Kampala",
"Africa/Khartoum":"Afrika/Khartoum",
"Africa/Kigali":"Afrika/Kigali",
"Africa/Kinshasa":"Afrika/Kinshasa",
"Africa/Lagos":"Afrika/Lagos",
"Africa/Libreville":"Afrika/Libreville",
"Africa/Lome":"Afrika/Lome",
"Africa/Luanda":"Afrika/Luanda",
"Africa/Lubumbashi":"Afrika/Lubumbashi",
"Africa/Lusaka":"Afrika/Lusaka",
"Africa/Malabo":"Afrika/Malabo",
"Africa/Maputo":"Afrika/Maputo",
"Africa/Maseru":"Afrika/Maseru",
"Africa/Mbabane":"Afrika/Mbabane",
"Africa/Mogadishu":"Afrika/Mogadishu",
"Africa/Monrovia":"Afrika/Monrovia",
"Africa/Nairobi":"Afrika/Nairobi",
"Africa/Ndjamena":"Afrika/Ndjamena",
"Africa/Niamey":"Afrika/Niamey",
"Africa/Nouakchott":"Afrika/Nouakchott",
"Africa/Ouagadougou":"Afrika/Ouagadougou",
"Africa/Porto-Novo":"Afrika/Porto-Novo",
"Africa/Sao_Tome":"Afrika/Sao_Tome",
"Africa/Timbuktu":"Afrika/Timbuktu",
"Africa/Tripoli":"Afrika/Tripoli",
"Africa/Tunis":"Afrika/Tunis",
"Africa/Windhoek":"Afrika/Windhoek",
"America/Adak":"Amerika/Adak",
"America/Anchorage":"Amerika/Anchorage",
"America/Anguilla":"Amerika/Anguilla",
"America/Antigua":"Amerika/Antigua",
"America/Araguaina":"Amerika/Araguaina",
"America/Argentina/Buenos_Aires":"Amerika/Argentina/Buenos_Aires",
"America/Argentina/Catamarca":"Amerika/Argentina/Catamarca",
"America/Argentina/ComodRivadavia":"Amerika/Argentina/Comodrivadavia",
"America/Argentina/Cordoba":"Amerika/Argentina/Cordoba",
"America/Argentina/Jujuy":"Amerika/Argentina/Jujuy",
"America/Argentina/La_Rioja":"Amerika/Argentina/La_Rioja",
"America/Argentina/Mendoza":"Amerika/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos":"Amerika/Argentina/Rio_Gallegos",
"America/Argentina/Salta":"Amerika/Argentina/Salta",
"America/Argentina/San_Juan":"Amerika/Argentina/San_Juan",
"America/Argentina/San_Luis":"Amerika/Argentina/San_Luis",
"America/Argentina/Tucuman":"Amerika/Argentina/Tucuman",
"America/Argentina/Ushuaia":"Amerika/Argentina/Ushuaia",
"America/Aruba":"Amerika/Aruba",
"America/Asuncion":"Amerika/Asuncion",
"America/Atikokan":"Amerika/Atikokan",
"America/Atka":"Amerika/Atka",
"America/Bahia":"Amerika/Bahia",
"America/Bahia_Banderas":"Amerika/Bahia_Banderas",
"America/Barbados":"Amerika/Barbados",
"America/Belem":"Amerika/Belem",
"America/Belize":"Amerika/Belize",
"America/Blanc-Sablon":"Amerika/Blanc-Sablon",
"America/Boa_Vista":"Amerika/Boa_Vista",
"America/Bogota":"Amerika/Bogota",
"America/Boise":"Amerika/Boise",
"America/Buenos_Aires":"Amerika/Buenos_Aires",
"America/Cambridge_Bay":"Amerika/Cambridge_Bay",
"America/Campo_Grande":"Amerika/Campo_Grande",
"America/Cancun":"Amerika/Cancun",
"America/Caracas":"Amerika/Caracas",
"America/Catamarca":"Amerika/Katamarca",
"America/Cayenne":"Amerika/Cayenne",
"America/Cayman":"Amerika/Cayman",
"America/Chicago":"Amerika/Chicago",
"America/Chihuahua":"Amerika/Chihuahua",
"America/Ciudad_Juarez":"America/Ciudad_Juarez",
"America/Coral_Harbour":"America/Coral_Harbour",
"America/Cordoba":"Amerika/Cordoba",
"America/Costa_Rica":"Amerika/Costa Rica",
"America/Creston":"Amerika/Creston",
"America/Cuiaba":"Amerika/Cuiaba",
"America/Curacao":"Amerika/Curacao",
"America/Danmarkshavn":"America/Danmarkshavn",
"America/Dawson":"Amerika/Dawson",
"America/Dawson_Creek":"Amerika/Dawson_Creek",
"America/Denver":"Amerika/Denver",
"America/Detroit":"Amerika/Detroit",
"America/Dominica":"Amerika/Dominica",
"America/Edmonton":"Amerika/Edmonton",
"America/Eirunepe":"Amerika/Eirunepe",
"America/El_Salvador":"Amerika/El_Salvador",
"America/Ensenada":"America/Ensenada",
"America/Fort_Nelson":"Amerika/Fort_Nelson",
"America/Fort_Wayne":"Amerika/Fort_Wayne",
"America/Fortaleza":"Amerika/Fortaleza",
"America/Glace_Bay":"Amerika/Glace_Bay",
"America/Godthab":"Amerika/Godthab",
"America/Goose_Bay":"Amerika/Goose_Bay",
"America/Grand_Turk":"Amerika/Grand_Turk",
"America/Grenada":"Amerika/Grenada",
"America/Guadeloupe":"Amerika/Guadeloupe",
"America/Guatemala":"Amerika/Guatemala",
"America/Guayaquil":"Amerika/Guayaquil",
"America/Guyana":"Amerika/Guyana",
"America/Halifax":"Amerika/Halifax",
"America/Havana":"Amerika/Havanna",
"America/Hermosillo":"Amerika/Hermosillo",
"America/Indiana/Indianapolis":"Amerika/Indiana/Indianapolis",
"America/Indiana/Knox":"Amerika/Indiana/Knox",
"America/Indiana/Marengo":"Amerika/Indiana/Marengo",
"America/Indiana/Petersburg":"Amerika/Indiana/Petersburg",
"America/Indiana/Tell_City":"Amerika/Indiana/Tell_City",
"America/Indiana/Vevay":"Amerika/Indiana/Vevay",
"America/Indiana/Vincennes":"Amerika/Indiana/Vincennes",
"America/Indiana/Winamac":"Amerika/Indiana/Winamac",
"America/Indianapolis":"Amerika/Indianapolis",
"America/Inuvik":"Amerika/Inuvik",
"America/Iqaluit":"Amerika/Iqaluit",
"America/Jamaica":"Amerika/Jamaica",
"America/Jujuy":"Amerika/Jujuy",
"America/Juneau":"Amerika/Juneau",
"America/Kentucky/Louisville":"Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello":"Amerika/Kentucky/Monticello",
"America/Knox_IN":"Amerika/Knox_in",
"America/Kralendijk":"Amerika/Kralendijk",
"America/La_Paz":"Amerika/La_Paz",
"America/Lima":"Amerika/Lima",
"America/Los_Angeles":"Amerika/Los_Angeles",
"America/Louisville":"Amerika/Louisville",
"America/Lower_Princes":"Amerika/Lower_Princes",
"America/Maceio":"Amerika/Maceio",
"America/Managua":"Amerika/Managua",
"America/Manaus":"Amerika/Manaus",
"America/Marigot":"Amerika/Marigot",
"America/Martinique":"Amerika/Martinique",
"America/Matamoros":"Amerika/Matamoros",
"America/Mazatlan":"Amerika/Mazatlan",
"America/Mendoza":"Amerika/Mendoza",
"America/Menominee":"Amerika/Menominee",
"America/Merida":"Amerika/Merida",
"America/Metlakatla":"Amerika/Metlakatla",
"America/Mexico_City":"America/Mexico_City",
"America/Miquelon":"Amerika/Miquelon",
"America/Moncton":"Amerika/Moncton",
"America/Monterrey":"Amerika/Monterrey",
"America/Montevideo":"Amerika/Montevideo",
"America/Montreal":"Amerika/Montreal",
"America/Montserrat":"Amerika/Montserrat",
"America/Nassau":"Amerika/Nassau",
"America/New_York":"Amerika/New_York",
"America/Nipigon":"Amerika/Nipigon",
"America/Nome":"Amerika/namn",
"America/Noronha":"Amerika/Noronha",
"America/North_Dakota/Beulah":"Amerika/Norra Dakota/Beulah",
"America/North_Dakota/Center":"Amerika/Nord_Dakota/Centrum",
"America/North_Dakota/New_Salem":"Amerika/Nord_Dakota/New_Salem",
"America/Nuuk":"Amerika/Nuuk",
"America/Ojinaga":"Amerika/Ojinaga",
"America/Panama":"Amerika/Panama",
"America/Pangnirtung":"Amerika/Pangnirtung",
"America/Paramaribo":"Amerika/Paramaribo",
"America/Phoenix":"Amerika/Phoenix",
"America/Port-au-Prince":"Amerika/Port-au-Prince",
"America/Port_of_Spain":"Amerika/hamn_of_Spanien",
"America/Porto_Acre":"America/Porto_Acre",
"America/Porto_Velho":"America/Porto_Velho",
"America/Puerto_Rico":"Amerika/Puerto_Rico",
"America/Punta_Arenas":"Amerika/Punta_Arenas",
"America/Rainy_River":"Amerika/Rainy_River",
"America/Rankin_Inlet":"Amerika/Rankin_Inlet",
"America/Recife":"Amerika/Recife",
"America/Regina":"Amerika/Regina",
"America/Resolute":"Amerika/Resolute",
"America/Rio_Branco":"America/Rio_Branco",
"America/Rosario":"Amerika/Rosario",
"America/Santa_Isabel":"Amerika/Santa_Isabel",
"America/Santarem":"Amerika/Santarem",
"America/Santiago":"Amerika/Santiago",
"America/Santo_Domingo":"Amerika/Santo_Domingo",
"America/Sao_Paulo":"Amerika/Sao_Paulo",
"America/Scoresbysund":"Amerika/Scoresbysund",
"America/Shiprock":"Amerika/Shiprock",
"America/Sitka":"Amerika/Sitka",
"America/St_Barthelemy":"Amerika/St_Barthelemy",
"America/St_Johns":"Amerika/St_Johns",
"America/St_Kitts":"Amerika/St_Kitts",
"America/St_Lucia":"Amerika/St_Lucia",
"America/St_Thomas":"Amerika/St_Thomas",
"America/St_Vincent":"Amerika/St_Vincent",
"America/Swift_Current":"Amerika/Swift_Current",
"America/Tegucigalpa":"Amerika/Tegucigalpa",
"America/Thule":"Amerika/Thule",
"America/Thunder_Bay":"Amerika/Thunder_Bay",
"America/Tijuana":"Amerika/Tijuana",
"America/Toronto":"Amerika/Toronto",
"America/Tortola":"Amerika/Tortola",
"America/Vancouver":"Amerika/Vancouver",
"America/Virgin":"Amerika/Jungfru",
"America/Whitehorse":"Amerika/Whitehorse",
"America/Winnipeg":"Amerika/Winnipeg",
"America/Yakutat":"Amerika/Yakutat",
"America/Yellowknife":"Amerika/Yellowknife",
"Antarctica/Casey":"Antarktis/Casey",
"Antarctica/Davis":"Antarktis/Davis",
"Antarctica/DumontDUrville":"Antarktis/DumontDurville",
"Antarctica/Macquarie":"Antarktis/Macquarie",
"Antarctica/Mawson":"Antarktis/Mawson",
"Antarctica/McMurdo":"Antarktis/McMurdo",
"Antarctica/Palmer":"Antarktis/Palmer",
"Antarctica/Rothera":"Antarktis/Rothera",
"Antarctica/South_Pole":"Antarktis/Sydpolen",
"Antarctica/Syowa":"Antarktis/Syowa",
"Antarctica/Troll":"Antarktis/Troll",
"Antarctica/Vostok":"Antarktis/Vostok",
"Arctic/Longyearbyen":"Arktisk/Longyearbyen",
"Asia/Aden":"Asien/Aden",
"Asia/Almaty":"Asien/Almaty",
"Asia/Amman":"Asien/Amman",
"Asia/Anadyr":"Asien/Anadyr",
"Asia/Aqtau":"Asien/Aqtau",
"Asia/Aqtobe":"Asien/Aqtobe",
"Asia/Ashgabat":"Asien/Ashgabat",
"Asia/Ashkhabad":"Asien/Ashkhabad",
"Asia/Atyrau":"Asien/Atyrau",
"Asia/Baghdad":"Asien/Bagdad",
"Asia/Bahrain":"Asien/Bahrain",
"Asia/Baku":"Asien/Baku",
"Asia/Bangkok":"Asien/Bangkok",
"Asia/Barnaul":"Asien/Barnaul",
"Asia/Beirut":"Asien/Beirut",
"Asia/Bishkek":"Asien/Bisjkek",
"Asia/Brunei":"Asien/Brunei",
"Asia/Calcutta":"Asien/Calcutta",
"Asia/Chita":"Asien/Chita",
"Asia/Choibalsan":"Asien/Choibalsan",
"Asia/Chongqing":"Asien/Chongqing",
"Asia/Chungking":"Asien/Chungking",
"Asia/Colombo":"Asien/Colombo",
"Asia/Dacca":"Asien/Dacca",
"Asia/Damascus":"Asien/Damaskus",
"Asia/Dhaka":"Asien/Dhaka",
"Asia/Dili":"Asien/Dili",
"Asia/Dubai":"Asien/Dubai",
"Asia/Dushanbe":"Asien/Dushanbe",
"Asia/Famagusta":"Asien/Famagusta",
"Asia/Gaza":"Asien/Gaza",
"Asia/Harbin":"Asien/Harbin",
"Asia/Hebron":"Asien/Hebron",
"Asia/Ho_Chi_Minh":"Asien/Ho_Chi_Minh",
"Asia/Hong_Kong":"Asien/Hongkong",
"Asia/Hovd":"Asien/Hovd",
"Asia/Irkutsk":"Asien/Irkutsk",
"Asia/Istanbul":"Asien/Istanbul",
"Asia/Jakarta":"Asien/Jakarta",
"Asia/Jayapura":"Asien/Jayapura",
"Asia/Jerusalem":"Asien/Jerusalem",
"Asia/Kabul":"Asien/Kabul",
"Asia/Kamchatka":"Asien/Kamchatka",
"Asia/Karachi":"Asien/Karachi",
"Asia/Kashgar":"Asien/Kashgar",
"Asia/Kathmandu":"Asien/Katmandu",
"Asia/Katmandu":"Asien/Katmandu",
"Asia/Khandyga":"Asien/Khandyga",
"Asia/Kolkata":"Asien/Kolkata",
"Asia/Krasnoyarsk":"Asien/Krasnoyarsk",
"Asia/Kuala_Lumpur":"Asien/Kuala_Lumpur",
"Asia/Kuching":"Asien/Kuching",
"Asia/Kuwait":"Asien/Kuwait",
"Asia/Macao":"Asien/Macao",
"Asia/Macau":"Asien/Macao",
"Asia/Magadan":"Asien/Magadan",
"Asia/Makassar":"Asien/Makassar",
"Asia/Manila":"Asien/Manila",
"Asia/Muscat":"Asien/Muscat",
"Asia/Nicosia":"Asien/Nicosia",
"Asia/Novokuznetsk":"Asien/Novokuznetsk",
"Asia/Novosibirsk":"Asien/Novosibirsk",
"Asia/Omsk":"Asien/Omsk",
"Asia/Oral":"Asien/Oral",
"Asia/Phnom_Penh":"Asien/Phnom_Penh",
"Asia/Pontianak":"Asien/Pontianak",
"Asia/Pyongyang":"Asien/Pyongyang",
"Asia/Qatar":"Asien/Qatar",
"Asia/Qostanay":"Asien/Qostanay",
"Asia/Qyzylorda":"Asien/Qyzylorda",
"Asia/Rangoon":"Asien/Rangoon",
"Asia/Riyadh":"Asien/Riyadh",
"Asia/Saigon":"Asien/Saigon",
"Asia/Sakhalin":"Asien/Sakhalin",
"Asia/Samarkand":"Asien/Samarkand",
"Asia/Seoul":"Asien/Seoul",
"Asia/Shanghai":"Asien/Shanghai",
"Asia/Singapore":"Asien/Singapore",
"Asia/Srednekolymsk":"Asien/Srednekolymsk",
"Asia/Taipei":"Asien/Taipei",
"Asia/Tashkent":"Asien/Tasjkent",
"Asia/Tbilisi":"Asien/Tbilisi",
"Asia/Tehran":"Asien/Teheran",
"Asia/Tel_Aviv":"Asien/Tel_Aviv",
"Asia/Thimbu":"Asien/Thimbu",
"Asia/Thimphu":"Asien/Thimphu",
"Asia/Tokyo":"Asien/Tokyo",
"Asia/Tomsk":"Asien/Tomsk",
"Asia/Ujung_Pandang":"Asien/Ujung_Pandang",
"Asia/Ulaanbaatar":"Asien/Ulaanbaatar",
"Asia/Ulan_Bator":"Asien/Ulan_Bator",
"Asia/Urumqi":"Asien/Urumqi",
"Asia/Ust-Nera":"Asien/Ust-Nera",
"Asia/Vientiane":"Asien/Vientiane",
"Asia/Vladivostok":"Asien/Vladivostok",
"Asia/Yakutsk":"Asien/Yakutsk",
"Asia/Yangon":"Asien/Yangon",
"Asia/Yekaterinburg":"Asien/Jekaterinburg",
"Asia/Yerevan":"Asien/Jerevan",
"Atlantic/Azores":"Atlanten/Azorerna",
"Atlantic/Bermuda":"Atlanten/Bermuda",
"Atlantic/Canary":"Atlantisk/Kanarieöarna",
"Atlantic/Cape_Verde":"Atlanten/Kap Verde",
"Atlantic/Faeroe":"Atlantic/Färöarna",
"Atlantic/Faroe":"Atlanten/Färöarna",
"Atlantic/Jan_Mayen":"Atlantisk/Jan_Mayen",
"Atlantic/Madeira":"Atlanten/Madeira",
"Atlantic/Reykjavik":"Atlantic/Reykjavik",
"Atlantic/South_Georgia":"Atlanten/Syd_Georgien",
"Atlantic/St_Helena":"Atlanten/St_Helena",
"Atlantic/Stanley":"Atlantic/Stanley",
"Australia/ACT":"Australien/ACT",
"Australia/Adelaide":"Australien/Adelaide",
"Australia/Brisbane":"Australien/Brisbane",
"Australia/Broken_Hill":"Australien/Broken_Hill",
"Australia/Canberra":"Australien/Canberra",
"Australia/Currie":"Australien/Currie",
"Australia/Darwin":"Australien/Darwin",
"Australia/Eucla":"Australien/Eucla",
"Australia/Hobart":"Australien/Hobart",
"Australia/LHI":"Australien/LHI",
"Australia/Lindeman":"Australien/Lindeman",
"Australia/Lord_Howe":"Australien/Lord_Howe",
"Australia/Melbourne":"Australien/Melbourne",
"Australia/NSW":"Australien/NSW",
"Australia/North":"Australien/Nord",
"Australia/Perth":"Australien/Perth",
"Australia/Queensland":"Australien/Queensland",
"Australia/South":"Australien/Syd",
"Australia/Sydney":"Australien/Sydney",
"Australia/Tasmania":"Australien/Tasmanien",
"Australia/Victoria":"Australien/Victoria",
"Australia/West":"Australien/Väst",
"Australia/Yancowinna":"Australien/Yancowinna",
"Brazil/Acre":"Brasilien/Acre",
"Brazil/DeNoronha":"Brasilien/Denoronha",
"Brazil/East":"Brasilien/Öst",
"Brazil/West":"Brasilien/Väst",
"CET":"DENNA",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Kanada/Atlanten",
"Canada/Central":"Kanada/Central",
"Canada/Eastern":"Kanada/Östra",
"Canada/Mountain":"Kanada/Berg",
"Canada/Newfoundland":"Kanada/Newfoundland",
"Canada/Pacific":"Kanada/Stillahavsområdet",
"Canada/Saskatchewan":"Kanada/Saskatchewan",
"Canada/Yukon":"Kanada/Yukon",
"Chile/Continental":"Chile/Kontinentalt",
"Chile/EasterIsland":"Chile/påskön",
"Cuba":"Kuba",
"EET":"ÄTA",
"EST":"EST",
"EST5EDT":"EST5EDT",
"Egypt":"Egypten",
"Eire":"Eire",
"Etc/GMT":"Etc/GMT",
"Etc/GMT+0":"Etc/GMT+0",
"Etc/GMT+1":"Etc/GMT+1",
"Etc/GMT+10":"Etc/GMT+10",
"Etc/GMT+11":"Etc/GMT+11",
"Etc/GMT+12":"Etc/GMT+12",
"Etc/GMT+2":"Etc/GMT+2",
"Etc/GMT+3":"Etc/GMT+3",
"Etc/GMT+4":"Etc/GMT+4",
"Etc/GMT+5":"Etc/GMT+5",
"Etc/GMT+6":"Etc/GMT+6",
"Etc/GMT+7":"Etc/GMT+7",
"Etc/GMT+8":"Etc/GMT+8",
"Etc/GMT+9":"Etc/GMT+9",
"Etc/GMT-0":"Etc/GMT-0",
"Etc/GMT-1":"Etc/GMT-1",
"Etc/GMT-10":"Etc/GMT-10",
"Etc/GMT-11":"Etc/GMT-11",
"Etc/GMT-12":"Etc/GMT-12",
"Etc/GMT-13":"Etc/GMT-13",
"Etc/GMT-14":"Etc/GMT-14",
"Etc/GMT-2":"Etc/GMT-2",
"Etc/GMT-3":"Etc/GMT-3",
"Etc/GMT-4":"Etc/GMT-4",
"Etc/GMT-5":"Etc/GMT-5",
"Etc/GMT-6":"Etc/GMT-6",
"Etc/GMT-7":"Etc/GMT-7",
"Etc/GMT-8":"Etc/GMT-8",
"Etc/GMT-9":"Etc/GMT-9",
"Etc/GMT0":"Etc/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"Etc/UCT",
"Etc/UTC":"Etc/UTC",
"Etc/Universal":"Etc/Universal",
"Etc/Zulu":"Etc/Zulu",
"Europe/Amsterdam":"Europa/Amsterdam",
"Europe/Andorra":"Europa/Andorra",
"Europe/Astrakhan":"Europa/Astrakhan",
"Europe/Athens":"Europa/Aten",
"Europe/Belfast":"Europa/Belfast",
"Europe/Belgrade":"Europa/Belgrad",
"Europe/Berlin":"Europa/Berlin",
"Europe/Bratislava":"Europa/Bratislava",
"Europe/Brussels":"Europa/Bryssel",
"Europe/Bucharest":"Europa/Bukarest",
"Europe/Budapest":"Europa/Budapest",
"Europe/Busingen":"Europa/Busingen",
"Europe/Chisinau":"Europa/Chisinau",
"Europe/Copenhagen":"Europa/Köpenhamn",
"Europe/Dublin":"Europa/Dublin",
"Europe/Gibraltar":"Europa/Gibraltar",
"Europe/Guernsey":"Europa/Guernsey",
"Europe/Helsinki":"Europa/Helsingfors",
"Europe/Isle_of_Man":"Europa/Isle_of_man",
"Europe/Istanbul":"Europa/Istanbul",
"Europe/Jersey":"Europa/Jersey",
"Europe/Kaliningrad":"Europa/Kaliningrad",
"Europe/Kiev":"Europa/Kiev",
"Europe/Kirov":"Europa/Kirov",
"Europe/Kyiv":"Europa/Kiev",
"Europe/Lisbon":"Europa/Lissabon",
"Europe/Ljubljana":"Europa/Ljubljana",
"Europe/London":"Europa/London",
"Europe/Luxembourg":"Europa/Luxemburg",
"Europe/Madrid":"Europa/Madrid",
"Europe/Malta":"Europa/Malta",
"Europe/Mariehamn":"Europa/Mariehamn",
"Europe/Minsk":"Europa/Minsk",
"Europe/Monaco":"Europa/Monaco",
"Europe/Moscow":"Europa/Moskva",
"Europe/Nicosia":"Europa/Nicosia",
"Europe/Oslo":"Europa/Oslo",
"Europe/Paris":"Europa/Paris",
"Europe/Podgorica":"Europa/Podgorica",
"Europe/Prague":"Europa/Prag",
"Europe/Riga":"Europa/Riga",
"Europe/Rome":"Europa/Rom",
"Europe/Samara":"Europa/Samara",
"Europe/San_Marino":"Europa/San_Marino",
"Europe/Sarajevo":"Europa/Sarajevo",
"Europe/Saratov":"Europa/Saratov",
"Europe/Simferopol":"Europa/Simferopol",
"Europe/Skopje":"Europa/Skopje",
"Europe/Sofia":"Europa/Sofia",
"Europe/Stockholm":"Europa/Stockholm",
"Europe/Tallinn":"Europa/Tallinn",
"Europe/Tirane":"Europa/Tirana",
"Europe/Tiraspol":"Europa/Tiraspol",
"Europe/Ulyanovsk":"Europa/Ulyanovsk",
"Europe/Uzhgorod":"Europa/Uzhgorod",
"Europe/Vaduz":"Europa/Vaduz",
"Europe/Vatican":"Europa/Vatikanen",
"Europe/Vienna":"Europa/Wien",
"Europe/Vilnius":"Europa/Vilnius",
"Europe/Volgograd":"Europa/Volgograd",
"Europe/Warsaw":"Europa/Warszawa",
"Europe/Zagreb":"Europa/Zagreb",
"Europe/Zaporozhye":"Europa/Zaporozhye",
"Europe/Zurich":"Europa/Zürich",
"GB":"GB",
"GB-Eire":"GB Eire",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HST",
"Hongkong":"Hongkong",
"Iceland":"Island",
"Indian/Antananarivo":"Indiska/Antananarivo",
"Indian/Chagos":"Indiska/Chagos",
"Indian/Christmas":"Indisk/jul",
"Indian/Cocos":"Indisk/Cocos",
"Indian/Comoro":"Indiska/Komorerna",
"Indian/Kerguelen":"Indiska/Kerguelen",
"Indian/Mahe":"Indisk/Mahe",
"Indian/Maldives":"Indiska/Maldiverna",
"Indian/Mauritius":"Indiska/Mauritius",
"Indian/Mayotte":"Indiska/Mayotte",
"Indian/Reunion":"Indisk/återförening",
"Iran":"Iran",
"Israel":"Israel",
"Jamaica":"Jamaica",
"Japan":"Japan",
"Kwajalein":"Kwajalein",
"Libya":"Libyen",
"MET":"MED",
"MST":"MST",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"Mexiko/Bajanorte",
"Mexico/BajaSur":"Mexiko/Bajasur",
"Mexico/General":"Mexiko/Allmänt",
"NZ":"NEW",
"NZ-CHAT":"NZ-CHATT",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Stillahavet/Apia",
"Pacific/Auckland":"Stillahavet/Auckland",
"Pacific/Bougainville":"Stillahavet/Bougainville",
"Pacific/Chatham":"Stillahavet/Chatham",
"Pacific/Chuuk":"Stillahavet/Chuuk",
"Pacific/Easter":"Stillahavet/påsk",
"Pacific/Efate":"Stillahavet/Efate",
"Pacific/Enderbury":"Stillahavet/Enderbury",
"Pacific/Fakaofo":"Stillahavet/Fakaofo",
"Pacific/Fiji":"Stillahavet/Fiji",
"Pacific/Funafuti":"Stillahavet/Funafuti",
"Pacific/Galapagos":"Stillahavet/Galapagos",
"Pacific/Gambier":"Stillahavet/Gambier",
"Pacific/Guadalcanal":"Stillahavet/Guadalcanal",
"Pacific/Guam":"Stillahavet/Guam",
"Pacific/Honolulu":"Stillahavet/Honolulu",
"Pacific/Johnston":"Stillahavet/Johnston",
"Pacific/Kanton":"Stillahavet/Kanton",
"Pacific/Kiritimati":"Stillahavet/Kiritimati",
"Pacific/Kosrae":"Stillahavet/Kosrae",
"Pacific/Kwajalein":"Stillahavet/Kwajalein",
"Pacific/Majuro":"Stillahavet/Majuro",
"Pacific/Marquesas":"Stillahavet/Marquesas",
"Pacific/Midway":"Stillahavet/Midway",
"Pacific/Nauru":"Stillahavet/Nauru",
"Pacific/Niue":"Stillahavet/Niue",
"Pacific/Norfolk":"Stillahavet/Norfolk",
"Pacific/Noumea":"Stillahavet/Noumea",
"Pacific/Pago_Pago":"Stillahavet/Pago_Pago",
"Pacific/Palau":"Stillahavet/Palau",
"Pacific/Pitcairn":"Stillahavet/Pitcairn",
"Pacific/Pohnpei":"Stillahavet/Pohnpei",
"Pacific/Ponape":"Stillahavet/Ponape",
"Pacific/Port_Moresby":"Stillahavet/Port_Moresby",
"Pacific/Rarotonga":"Stillahavet/Rarotonga",
"Pacific/Saipan":"Stillahavet/Saipan",
"Pacific/Samoa":"Stillahavet/Samoa",
"Pacific/Tahiti":"Stillahavet/Tahiti",
"Pacific/Tarawa":"Stillahavet/Tarawa",
"Pacific/Tongatapu":"Stillahavet/Tongatapu",
"Pacific/Truk":"Stillahavet/Truk",
"Pacific/Wake":"Stillahavet/Vakna",
"Pacific/Wallis":"Stillahavet/Wallis",
"Pacific/Yap":"Stillahavet/Yap",
"Poland":"Polen",
"Portugal":"Portugal",
"ROC":"ROCK",
"ROK":"ÅR",
"Singapore":"Singapore",
"Turkey":"Turkiet",
"UCT":"UCT",
"US/Alaska":"USA/Alaska",
"US/Aleutian":"USA/Aleutian",
"US/Arizona":"USA/Arizona",
"US/Central":"US/Central",
"US/East-Indiana":"USA/Östra Indiana",
"US/Eastern":"USA/Öst",
"US/Hawaii":"USA/Hawaii",
"US/Indiana-Starke":"USA/Indiana-Starke",
"US/Michigan":"USA/Michigan",
"US/Mountain":"US/Berg",
"US/Pacific":"USA/Stillahavsområdet",
"US/Samoa":"USA/Samoa",
"UTC":"UTC",
"Universal":"Universell",
"W-SU":"W-SU",
"WET":"VÅT",
"Zulu":"Zulu",
}