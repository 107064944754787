export default {
    "Africa/Abidjan": "África/Abidjan",
"Africa/Accra": "África/Accra",
"Africa/Addis_Ababa": "África/Addis_Ababa",
"Africa/Algiers": "África/Argel",
"Africa/Asmara": "África/Asmara",
"Africa/Asmera": "África/Asmera",
"Africa/Bamako": "África/Bamako",
"Africa/Bangui": "África/Bangui",
"Africa/Banjul": "África/Banjul",
"Africa/Bissau": "África/Bissau",
"Africa/Blantyre": "África/Blantyre",
"Africa/Brazzaville": "África/Brazzaville",
"Africa/Bujumbura": "África/Bujumbura",
"Africa/Cairo": "África/Cairo",
"Africa/Casablanca": "África/Casablanca",
"Africa/Ceuta": "África/Ceuta",
"Africa/Conakry": "África/Conakry",
"Africa/Dakar": "África/Dakar",
"Africa/Dar_es_Salaam": "África/Dar_es_Salaam",
"Africa/Djibouti": "África/Djibouti",
"Africa/Douala": "África/Douala",
"Africa/El_Aaiun": "África/El_Aaiun",
"Africa/Freetown": "África/Freetown",
"Africa/Gaborone": "África/Gaborone",
"Africa/Harare": "África/Harare",
"Africa/Johannesburg": "África/Joanesburgo",
"Africa/Juba": "África/Juba",
"Africa/Kampala": "África/Kampala",
"Africa/Khartoum": "África/Khartoum",
"Africa/Kigali": "África/Kigali",
"Africa/Kinshasa": "África/Kinshasa",
"Africa/Lagos": "África/Lagos",
"Africa/Libreville": "África/Libreville",
"Africa/Lome": "África/Lome",
"Africa/Luanda": "África/Luanda",
"Africa/Lubumbashi": "África/Lubumbashi",
"Africa/Lusaka": "África/Lusaka",
"Africa/Malabo": "África/Malabo",
"Africa/Maputo": "África/Maputo",
"Africa/Maseru": "África/Maseru",
"Africa/Mbabane": "África/Mbabane",
"Africa/Mogadishu": "África/Mogadishu",
"Africa/Monrovia": "África/Monrovia",
"Africa/Nairobi": "África/Nairobi",
"Africa/Ndjamena": "África/Ndjamena",
"Africa/Niamey": "África/Niamey",
"Africa/Nouakchott": "África/Nouakchott",
"Africa/Ouagadougou": "África/Ouagadougou",
"Africa/Porto-Novo": "África/Porto-Novo",
"Africa/Sao_Tome": "África/Sao_Tome",
"Africa/Timbuktu": "África/Timbuktu",
"Africa/Tripoli": "África/Tripoli",
"Africa/Tunis": "África/Tunis",
"Africa/Windhoek": "África/Windhoek",
"America/Adak": "América/Adak",
"America/Anchorage": "América/Anchorage",
"America/Anguilla": "América/Anguilla",
"America/Antigua": "América/Antigua",
"America/Araguaina": "América/Araguaina",
"America/Argentina/Buenos_Aires": "América/Argentina/Buenos_Aires",
"America/Argentina/Catamarca": "América/Argentina/Catamarca",
"America/Argentina/ComodRivadavia": "América/Argentina/Comodrivadavia",
"America/Argentina/Cordoba": "América/Argentina/Córdoba",
"America/Argentina/Jujuy": "América/Argentina/Jujuy",
"America/Argentina/La_Rioja": "América/Argentina/La_Rioja",
"America/Argentina/Mendoza": "América/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos": "América/Argentina/Rio_Gallegos",
"America/Argentina/Salta": "América/Argentina/Salta",
"America/Argentina/San_Juan": "América/Argentina/San_Juan",
"America/Argentina/San_Luis": "América/Argentina/San_Luis",
"America/Argentina/Tucuman": "América/Argentina/Tucuman",
"America/Argentina/Ushuaia": "América/Argentina/Ushuaia",
"America/Aruba": "América/Aruba",
"America/Asuncion": "América/Assunção",
"America/Atikokan": "América/Atikokan",
"America/Atka": "América/Atka",
"America/Bahia": "América/Bahia",
"America/Bahia_Banderas": "América/Bahia_Banderas",
"America/Barbados": "América/Barbados",
"America/Belem": "América/Belém",
"America/Belize": "América/Belize",
"America/Blanc-Sablon": "América/Blanc-Sablon",
"America/Boa_Vista": "América/Boa_Vista",
"America/Bogota": "América/Bogotá",
"America/Boise": "América/Boise",
"America/Buenos_Aires": "América/Buenos_Aires",
"America/Cambridge_Bay": "América/Cambridge_Bay",
"America/Campo_Grande": "América/Campo_Grande",
"America/Cancun": "América/Cancún",
"America/Caracas": "América/Caracas",
"America/Catamarca": "América/Catamarca",
"America/Cayenne": "América/Cayenne",
"America/Cayman": "América/Cayman",
"America/Chicago": "América/Chicago",
"America/Chihuahua": "América/Chihuahua",
"America/Ciudad_Juarez": "América/Ciudad_Juarez",
"America/Coral_Harbour": "América/Coral_Harbour",
"America/Cordoba": "América/Córdoba",
"America/Costa_Rica": "América/Costa_Rica",
"America/Creston": "América/Creston",
"America/Cuiaba": "América/Cuiaba",
"America/Curacao": "América/Curaçao",
"America/Danmarkshavn": "América/Danmarkshavn",
"America/Dawson": "América/Dawson",
"America/Dawson_Creek": "América/Dawson_Creek",
"America/Denver": "América/Denver",
"America/Detroit": "América/Detroit",
"America/Dominica": "América/Dominica",
"America/Edmonton": "América/Edmonton",
"America/Eirunepe": "América/Eirunepe",
"America/El_Salvador": "América/El_Salvador",
"America/Ensenada": "América/Ensenada",
"America/Fort_Nelson": "América/Fort_Nelson",
"America/Fort_Wayne": "América/Fort_Wayne",
"America/Fortaleza": "América/Fortaleza",
"America/Glace_Bay": "América/Glace_Bay",
"America/Godthab": "América/Godthab",
"America/Goose_Bay": "América/Goose_Bay",
"America/Grand_Turk": "América/Grand_Turk",
"America/Grenada": "América/Granada",
"America/Guadeloupe": "América/Guadalupe",
"America/Guatemala": "América/Guatemala",
"America/Guayaquil": "América/Guayaquil",
"America/Guyana": "América/Guiana",
"America/Halifax": "América/Halifax",
"America/Havana": "América/Havana",
"America/Hermosillo": "América/Hermosillo",
"America/Indiana/Indianapolis": "América/Indiana/Indianápolis",
"America/Indiana/Knox": "América/Indiana/Knox",
"America/Indiana/Marengo": "América/Indiana/Marengo",
"America/Indiana/Petersburg": "América/Indiana/Petersburgo",
"America/Indiana/Tell_City": "América/Indiana/Tell_City",
"America/Indiana/Vevay": "América/Indiana/Vevay",
"America/Indiana/Vincennes": "América/Indiana/Vincennes",
"America/Indiana/Winamac": "América/Indiana/Winamac",
"America/Indianapolis": "América/Indianápolis",
"America/Inuvik": "América/Inuvik",
"America/Iqaluit": "América/Iqaluit",
"America/Jamaica": "América/Jamaica",
"America/Jujuy": "América/Jujuy",
"America/Juneau": "América/Juneau",
"America/Kentucky/Louisville": "América/Kentucky/Louisville",
"America/Kentucky/Monticello": "América/Kentucky/Monticello",
"America/Knox_IN": "América/Knox_IN",
"America/Kralendijk": "América/Kralendijk",
"America/La_Paz": "América/La_Paz",
"America/Lima": "América/Lima",
"America/Los_Angeles": "América/Los_Angeles",
"America/Louisville": "América/Louisville",
"America/Lower_Princes": "América/Lower_Princes",
"America/Maceio": "América/Maceió",
"America/Managua": "América/Managua",
"America/Manaus": "América/Manaus",
"America/Marigot": "América/Marigot",
"America/Martinique": "América/Martinica",
"America/Matamoros": "América/Matamoros",
"America/Mazatlan": "América/Mazatlan",
"America/Mendoza": "América/Mendoza",
"America/Menominee": "América/Menominee",
"America/Merida": "América/Merida",
"America/Metlakatla": "América/Metlakatla",
"America/Mexico_City": "América/Cidade do México",
"America/Miquelon": "América/Miquelon",
"America/Moncton": "América/Moncton",
"America/Monterrey": "América/Monterrey",
"America/Montevideo": "América/Montevidéu",
"America/Montreal": "América/Montreal",
"America/Montserrat": "América/Montserrat",
"America/Nassau": "América/Nassau",
"America/New_York": "América/New_York",
"America/Nipigon": "América/Nipigon",
"America/Nome": "América/Nome",
"America/Noronha": "América/Noronha",
"America/North_Dakota/Beulah": "América/Dakota_do Norte/Beulah",
"America/North_Dakota/Center": "América/Dakota_Norte/Centro",
"America/North_Dakota/New_Salem": "América/Dakota_do Norte/New_Salem",
"America/Nuuk": "América/Nuuk",
"America/Ojinaga": "América/Ojinaga",
"America/Panama": "América/Panamá",
"America/Pangnirtung": "América/Pangnirtung",
"America/Paramaribo": "América/Paramaribo",
"America/Phoenix": "América/Phoenix",
"America/Port-au-Prince": "América/Port-au-Prince",
"America/Port_of_Spain": "América/Porto_de_Espanha",
"America/Porto_Acre": "América/Porto_Acre",
"America/Porto_Velho": "América/Porto_Velho",
"America/Puerto_Rico": "América/Puerto_Rico",
"America/Punta_Arenas": "América/Punta_Arenas",
"America/Rainy_River": "América/Rainy_River",
"America/Rankin_Inlet": "América/Rankin_Inlet",
"America/Recife": "América/Recife",
"America/Regina": "América/Regina",
"America/Resolute": "América/Resolute",
"America/Rio_Branco": "América/Rio_Branco",
"America/Rosario": "América/Rosário",
"America/Santa_Isabel": "América/Santa_Isabel",
"America/Santarem": "América/Santarém",
"America/Santiago": "América/Santiago",
"America/Santo_Domingo": "América/Santo_Domingo",
"America/Sao_Paulo": "América/Sao_Paulo",
"America/Scoresbysund": "América/Scoresbysund",
"America/Shiprock": "América/Shiprock",
"America/Sitka": "América/Sitka",
"America/St_Barthelemy": "América/St_Barthelemy",
"America/St_Johns": "América/St_Johns",
"America/St_Kitts": "América/St_Kitts",
"America/St_Lucia": "América/St_Lucia",
"America/St_Thomas": "América/St_Thomas",
"America/St_Vincent": "América/St_Vincent",
"America/Swift_Current": "América/Swift_Current",
"America/Tegucigalpa": "América/Tegucigalpa",
"America/Thule": "América/Thule",
"America/Thunder_Bay": "América/Thunder_Bay",
"America/Tijuana": "América/Tijuana",
"America/Toronto": "América/Toronto",
"America/Tortola": "América/Tortola",
"America/Vancouver": "América/Vancouver",
"America/Virgin": "América/Virgem",
"America/Whitehorse": "América/Whitehorse",
"America/Winnipeg": "América/Winnipeg",
"America/Yakutat": "América/Yakutat",
"America/Yellowknife": "América/Yellowknife",
"Antarctica/Casey": "Antártica/Casey",
"Antarctica/Davis": "Antártica/Davis",
"Antarctica/DumontDUrville": "Antártica/Dumont Durville",
"Antarctica/Macquarie": "Antártica/Macquarie",
"Antarctica/Mawson": "Antártica/Mawson",
"Antarctica/McMurdo": "Antártica/McMurdo",
"Antarctica/Palmer": "Antártica/Palmer",
"Antarctica/Rothera": "Antártica/Rothera",
"Antarctica/South_Pole": "Antártica/Pólo Sul",
"Antarctica/Syowa": "Antártica/Syowa",
"Antarctica/Troll": "Antártica/Troll",
"Antarctica/Vostok": "Antártica/Vostok",
"Arctic/Longyearbyen": "Ártico/Longyearbyen",
"Asia/Aden": "Ásia/Aden",
"Asia/Almaty": "Ásia/Almaty",
"Asia/Amman": "Ásia/Amã",
"Asia/Anadyr": "Ásia/Anadyr",
"Asia/Aqtau": "Ásia/Aqtau",
"Asia/Aqtobe": "Ásia/Aqtobe",
"Asia/Ashgabat": "Ásia/Ashgabat",
"Asia/Ashkhabad": "Ásia/Ashkhabad",
"Asia/Atyrau": "Ásia/Atyrau",
"Asia/Baghdad": "Ásia/Bagdá",
"Asia/Bahrain": "Ásia/Bahrein",
"Asia/Baku": "Ásia/Baku",
"Asia/Bangkok": "Ásia/Bangkok",
"Asia/Barnaul": "Ásia/Barnaul",
"Asia/Beirut": "Ásia/Beirute",
"Asia/Bishkek": "Ásia/Bishkek",
"Asia/Brunei": "Ásia/Brunei",
"Asia/Calcutta": "Ásia/Calcutá",
"Asia/Chita": "Ásia/Chita",
"Asia/Choibalsan": "Ásia/Choibalsan",
"Asia/Chongqing": "Ásia/Chongqing",
"Asia/Chungking": "Ásia/Chungking",
"Asia/Colombo": "Ásia/Colombo",
"Asia/Dacca": "Ásia/Daca",
"Asia/Damascus": "Ásia/Damasco",
"Asia/Dhaka": "Ásia/Dhaka",
"Asia/Dili": "Ásia/Díli",
"Asia/Dubai": "Ásia/Dubai",
"Asia/Dushanbe": "Ásia/Dushanbe",
"Asia/Famagusta": "Ásia/Famagusta",
"Asia/Gaza": "Ásia/Gaza",
"Asia/Harbin": "Ásia/Harbin",
"Asia/Hebron": "Ásia/Hebron",
"Asia/Ho_Chi_Minh": "Ásia/Ho_Chi_Minh",
"Asia/Hong_Kong": "Ásia/Hong_Kong",
"Asia/Hovd": "Ásia/Hovd",
"Asia/Irkutsk": "Ásia/Irkutsk",
"Asia/Istanbul": "Ásia/Istambul",
"Asia/Jakarta": "Ásia/Jacarta",
"Asia/Jayapura": "Ásia/Jayapura",
"Asia/Jerusalem": "Ásia/Jerusalém",
"Asia/Kabul": "Ásia/Cabul",
"Asia/Kamchatka": "Ásia/Kamchatka",
"Asia/Karachi": "Ásia/Karachi",
"Asia/Kashgar": "Ásia/Kashgar",
"Asia/Kathmandu": "Ásia/Katmandu",
"Asia/Katmandu": "Ásia/Katmandu",
"Asia/Khandyga": "Ásia/Khandyga",
"Asia/Kolkata": "Ásia/Calcutá",
"Asia/Krasnoyarsk": "Ásia/Krasnoyarsk",
"Asia/Kuala_Lumpur": "Ásia/Kuala_Lumpur",
"Asia/Kuching": "Ásia/Kuching",
"Asia/Kuwait": "Ásia/Kuwait",
"Asia/Macao": "Ásia/Macau",
"Asia/Macau": "Ásia/Macau",
"Asia/Magadan": "Ásia/Magadan",
"Asia/Makassar": "Ásia/Makassar",
"Asia/Manila": "Ásia/Manila",
"Asia/Muscat": "Ásia/Muscat",
"Asia/Nicosia": "Ásia/Nicósia",
"Asia/Novokuznetsk": "Ásia/Novokuznetsk",
"Asia/Novosibirsk": "Ásia/Novosibirsk",
"Asia/Omsk": "Ásia/Omsk",
"Asia/Oral": "Ásia/Oral",
"Asia/Phnom_Penh": "Ásia/Phnom_Penh",
"Asia/Pontianak": "Ásia/Pontianak",
"Asia/Pyongyang": "Ásia/Pyongyang",
"Asia/Qatar": "Ásia/Catar",
"Asia/Qostanay": "Ásia/Qostanay",
"Asia/Qyzylorda": "Ásia/Qyzylorda",
"Asia/Rangoon": "Ásia/Rangoon",
"Asia/Riyadh": "Ásia/Riade",
"Asia/Saigon": "Ásia/Saigon",
"Asia/Sakhalin": "Ásia/Sakhalin",
"Asia/Samarkand": "Ásia/Samarkand",
"Asia/Seoul": "Ásia/Seul",
"Asia/Shanghai": "Ásia/Xangai",
"Asia/Singapore": "Ásia/Cingapura",
"Asia/Srednekolymsk": "Ásia/Srednekolymsk",
"Asia/Taipei": "Ásia/Taipei",
"Asia/Tashkent": "Ásia/Tashkent",
"Asia/Tbilisi": "Ásia/Tbilisi",
"Asia/Tehran": "Ásia/Teerã",
"Asia/Tel_Aviv": "Ásia/Tel_Aviv",
"Asia/Thimbu": "Ásia/Thimbu",
"Asia/Thimphu": "Ásia/Thimphu",
"Asia/Tokyo": "Ásia/Tóquio",
"Asia/Tomsk": "Ásia/Tomsk",
"Asia/Ujung_Pandang": "Ásia/Ujung_Pandang",
"Asia/Ulaanbaatar": "Ásia/Ulaanbaatar",
"Asia/Ulan_Bator": "Ásia/Ulan_Bator",
"Asia/Urumqi": "Ásia/Urumqi",
"Asia/Ust-Nera": "Ásia/Ust-Nera",
"Asia/Vientiane": "Ásia/Vientiane",
"Asia/Vladivostok": "Ásia/Vladivostok",
"Asia/Yakutsk": "Ásia/Yakutsk",
"Asia/Yangon": "Ásia/Yangon",
"Asia/Yekaterinburg": "Ásia/Ecaterimburgo",
"Asia/Yerevan": "Ásia/Yerevan",
"Atlantic/Azores": "Atlântico/Açores",
"Atlantic/Bermuda": "Atlântico/Bermudas",
"Atlantic/Canary": "Atlântico/Canário",
"Atlantic/Cape_Verde": "Atlântico/Cabo_Verde",
"Atlantic/Faeroe": "Atlântico/Faroé",
"Atlantic/Faroe": "Atlântico/Faroe",
"Atlantic/Jan_Mayen": "Atlântico/Jan_Mayen",
"Atlantic/Madeira": "Atlântico/Madeira",
"Atlantic/Reykjavik": "Atlântico/Reykjavik",
"Atlantic/South_Georgia": "Atlântico/Georgia do Sul",
"Atlantic/St_Helena": "Atlântico/Santa Helena",
"Atlantic/Stanley": "Atlântico/Stanley",
"Australia/ACT": "Austrália/ACT",
"Australia/Adelaide": "Austrália/Adelaide",
"Australia/Brisbane": "Austrália/Brisbane",
"Australia/Broken_Hill": "Austrália/Broken_Hill",
"Australia/Canberra": "Austrália/Canberra",
"Australia/Currie": "Austrália/Currie",
"Australia/Darwin": "Austrália/Darwin",
"Australia/Eucla": "Austrália/Eucla",
"Australia/Hobart": "Austrália/Hobart",
"Australia/LHI": "Austrália/LHI",
"Australia/Lindeman": "Austrália/Lindeman",
"Australia/Lord_Howe": "Austrália/Lord_Howe",
"Australia/Melbourne": "Austrália/Melbourne",
"Australia/NSW": "Austrália/NSW",
"Australia/North": "Austrália/Norte",
"Australia/Perth": "Austrália/Perth",
"Australia/Queensland": "Austrália/Queensland",
"Australia/South": "Austrália/Sul",
"Australia/Sydney": "Austrália/Sydney",
"Australia/Tasmania": "Austrália/Tasmânia",
"Australia/Victoria": "Austrália/Victoria",
"Australia/West": "Austrália/Oeste",
"Australia/Yancowinna": "Austrália/Yancowinna",
"Brazil/Acre": "Brasil/Acre",
"Brazil/DeNoronha": "Brasil/de Noronha",
"Brazil/East": "Brasil/Oriente",
"Brazil/West": "Brasil/Oeste",
"CET": "CETO",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Canadá/Atlântico",
"Canada/Central": "Canadá/Central",
"Canada/Eastern": "Canadá/Leste",
"Canada/Mountain": "Canadá/Montanha",
"Canada/Newfoundland": "Canadá/Terra Nova",
"Canada/Pacific": "Canadá/Pacífico",
"Canada/Saskatchewan": "Canadá/Saskatchewan",
"Canada/Yukon": "Canadá/Yukon",
"Chile/Continental": "Chile/Continental",
"Chile/EasterIsland": "Chile/Ilha de Páscoa",
"Cuba": "Cuba",
"EET": "CONHEÇA",
"EST": "OESTE",
"EST5EDT": "EST 5EDT",
"Egypt": "Egito",
"Eire": "Fio",
"Etc/GMT": "Etc/GMT",
"Etc/GMT+0": "Etc/GMT+0",
"Etc/GMT+1": "Etc/GMT+1",
"Etc/GMT+10": "Etc/GMT+10",
"Etc/GMT+11": "Etc/GMT+11",
"Etc/GMT+12": "Etc/GMT+12",
"Etc/GMT+2": "Etc/GMT+2",
"Etc/GMT+3": "Etc/GMT+3",
"Etc/GMT+4": "Etc/GMT+4",
"Etc/GMT+5": "Etc/GMT+5",
"Etc/GMT+6": "Etc/GMT+6",
"Etc/GMT+7": "Etc/GMT+7",
"Etc/GMT+8": "Etc/GMT+8",
"Etc/GMT+9": "Etc/GMT+9",
"Etc/GMT-0": "Etc/GMT-0",
"Etc/GMT-1": "Etc/GMT-1",
"Etc/GMT-10": "Etc/GMT-10",
"Etc/GMT-11": "Etc/GMT-11",
"Etc/GMT-12": "Etc/GMT-12",
"Etc/GMT-13": "Etc/GMT-13",
"Etc/GMT-14": "Etc/GMT-14",
"Etc/GMT-2": "Etc/GMT-2",
"Etc/GMT-3": "Etc/GMT-3",
"Etc/GMT-4": "Etc/GMT-4",
"Etc/GMT-5": "Etc/GMT-5",
"Etc/GMT-6": "Etc/GMT-6",
"Etc/GMT-7": "Etc/GMT-7",
"Etc/GMT-8": "Etc/GMT-8",
"Etc/GMT-9": "Etc/GMT-9",
"Etc/GMT0": "Etc/GMT0",
"Etc/Greenwich": "Etc/Greenwich",
"Etc/UCT": "Etc/UCT",
"Etc/UTC": "Etc/UTC",
"Etc/Universal": "Etc/Universal",
"Etc/Zulu": "Etc/Zulu",
"Europe/Amsterdam": "Europa/Amsterdã",
"Europe/Andorra": "Europa/Andorra",
"Europe/Astrakhan": "Europa/Astrakhan",
"Europe/Athens": "Europa/Atenas",
"Europe/Belfast": "Europa/Belfast",
"Europe/Belgrade": "Europa/Belgrado",
"Europe/Berlin": "Europa/Berlim",
"Europe/Bratislava": "Europa/Bratislava",
"Europe/Brussels": "Europa/Bruxelas",
"Europe/Bucharest": "Europa/Bucareste",
"Europe/Budapest": "Europa/Budapeste",
"Europe/Busingen": "Europa/Busingen",
"Europe/Chisinau": "Europa/Chisinau",
"Europe/Copenhagen": "Europa/Copenhague",
"Europe/Dublin": "Europa/Dublin",
"Europe/Gibraltar": "Europa/Gibraltar",
"Europe/Guernsey": "Europa/Guernsey",
"Europe/Helsinki": "Europa/Helsinque",
"Europe/Isle_of_Man": "Europa/Isle_of_Man",
"Europe/Istanbul": "Europa/Istambul",
"Europe/Jersey": "Europa/Jersey",
"Europe/Kaliningrad": "Europa/Kaliningrado",
"Europe/Kiev": "Europa/Kiev",
"Europe/Kirov": "Europa/Kirov",
"Europe/Kyiv": "Europa/Kiev",
"Europe/Lisbon": "Europa/Lisboa",
"Europe/Ljubljana": "Europa/Liubliana",
"Europe/London": "Europa/Londres",
"Europe/Luxembourg": "Europa/Luxemburgo",
"Europe/Madrid": "Europa/Madri",
"Europe/Malta": "Europa/Malta",
"Europe/Mariehamn": "Europa/Mariehamn",
"Europe/Minsk": "Europa/Minsk",
"Europe/Monaco": "Europa/Mônaco",
"Europe/Moscow": "Europa/Moscou",
"Europe/Nicosia": "Europa/Nicósia",
"Europe/Oslo": "Europa/Oslo",
"Europe/Paris": "Europa/Paris",
"Europe/Podgorica": "Europa/Podgorica",
"Europe/Prague": "Europa/Praga",
"Europe/Riga": "Europa/Riga",
"Europe/Rome": "Europa/Roma",
"Europe/Samara": "Europa/Samara",
"Europe/San_Marino": "Europa/San_Marino",
"Europe/Sarajevo": "Europa/Sarajevo",
"Europe/Saratov": "Europa/Saratov",
"Europe/Simferopol": "Europa/Simferopol",
"Europe/Skopje": "Europa/Skopje",
"Europe/Sofia": "Europa/Sofia",
"Europe/Stockholm": "Europa/Estocolmo",
"Europe/Tallinn": "Europa/Tallinn",
"Europe/Tirane": "Europa/Tirane",
"Europe/Tiraspol": "Europa/Tiraspol",
"Europe/Ulyanovsk": "Europa/Ulyanovsk",
"Europe/Uzhgorod": "Europa/Uzhgorod",
"Europe/Vaduz": "Europa/Vaduz",
"Europe/Vatican": "Europa/Vaticano",
"Europe/Vienna": "Europa/Viena",
"Europe/Vilnius": "Europa/Vilnius",
"Europe/Volgograd": "Europa/Volgogrado",
"Europe/Warsaw": "Europa/Varsóvia",
"Europe/Zagreb": "Europa/Zagreb",
"Europe/Zaporozhye": "Europa/Zaporozhye",
"Europe/Zurich": "Europa/Zurique",
"GB": "GB",
"GB-Eire": "Série GB",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "CHAPÉU",
"Hongkong": "Hong Kong",
"Iceland": "Islândia",
"Indian/Antananarivo": "Índio/Antananarivo",
"Indian/Chagos": "Índio/Chagos",
"Indian/Christmas": "Índio/Natal",
"Indian/Cocos": "Índio/Cocos",
"Indian/Comoro": "Índia/Comores",
"Indian/Kerguelen": "Índio/Kerguelen",
"Indian/Mahe": "Índia/Mahe",
"Indian/Maldives": "Índia/Maldivas",
"Indian/Mauritius": "Índia/Maurício",
"Indian/Mayotte": "Índia/Mayotte",
"Indian/Reunion": "Índia/Reunião",
"Iran": "Irã",
"Israel": "Israel",
"Jamaica": "Jamaica",
"Japan": "Japão",
"Kwajalein": "Kwajalein",
"Libya": "Líbia",
"MET": "CONHECI",
"MST": "MAIORIA",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "México/Baja Norte",
"Mexico/BajaSur": "México/Bajasur",
"Mexico/General": "México/Geral",
"NZ": "NZ",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDF",
"Pacific/Apia": "Pacífico/Apia",
"Pacific/Auckland": "Pacífico/Auckland",
"Pacific/Bougainville": "Pacífico/Bougainville",
"Pacific/Chatham": "Pacífico/Chatham",
"Pacific/Chuuk": "Pacífico/Chuuk",
"Pacific/Easter": "Pacífico/Páscoa",
"Pacific/Efate": "Pacífico/Éfate",
"Pacific/Enderbury": "Pacífico/Enderbury",
"Pacific/Fakaofo": "Pacífico/Fakaofo",
"Pacific/Fiji": "Pacífico/Fiji",
"Pacific/Funafuti": "Pacífico/Funafuti",
"Pacific/Galapagos": "Pacífico/Galápagos",
"Pacific/Gambier": "Pacífico/Gambier",
"Pacific/Guadalcanal": "Pacífico/Guadalcanal",
"Pacific/Guam": "Pacífico/Guam",
"Pacific/Honolulu": "Pacífico/Honolulu",
"Pacific/Johnston": "Pacífico/Johnston",
"Pacific/Kanton": "Pacífico/Canton",
"Pacific/Kiritimati": "Pacífico/Kiritimati",
"Pacific/Kosrae": "Pacífico/Kosrae",
"Pacific/Kwajalein": "Pacífico/Kwajalein",
"Pacific/Majuro": "Pacífico/Majuro",
"Pacific/Marquesas": "Pacífico/Marquesas",
"Pacific/Midway": "Pacífico/Midway",
"Pacific/Nauru": "Pacífico/Nauru",
"Pacific/Niue": "Pacífico/Niue",
"Pacific/Norfolk": "Pacífico/Norfolk",
"Pacific/Noumea": "Pacífico/Noumea",
"Pacific/Pago_Pago": "Pacífico/Pago_Pago",
"Pacific/Palau": "Pacífico/Palau",
"Pacific/Pitcairn": "Pacífico/Pitcairn",
"Pacific/Pohnpei": "Pacífico/Pohnpei",
"Pacific/Ponape": "Pacífico/Ponape",
"Pacific/Port_Moresby": "Pacífico/Port_Moresby",
"Pacific/Rarotonga": "Pacífico/Rarotonga",
"Pacific/Saipan": "Pacífico/Saipan",
"Pacific/Samoa": "Pacífico/Samoa",
"Pacific/Tahiti": "Pacífico/Taiti",
"Pacific/Tarawa": "Pacífico/Tarawa",
"Pacific/Tongatapu": "Pacífico/Tongatapu",
"Pacific/Truk": "Pacífico/Truk",
"Pacific/Wake": "Pacífico/Wake",
"Pacific/Wallis": "Pacífico/Wallis",
"Pacific/Yap": "Pacífico/Yap",
"Poland": "Polônia",
"Portugal": "Portugal",
"ROC": "ROCHA",
"ROK": "ROCHA",
"Singapore": "Cingapura",
"Turkey": "Turquia",
"UCT": "CORTAR",
"US/Alaska": "EUA/Alasca",
"US/Aleutian": "EUA/Aleutas",
"US/Arizona": "EUA/Arizona",
"US/Central": "EUA/Central",
"US/East-Indiana": "EUA/Leste de Indiana",
"US/Eastern": "EUA/Leste",
"US/Hawaii": "EUA/Havaí",
"US/Indiana-Starke": "Starke dos EUA/Indiana-Starke",
"US/Michigan": "EUA/Michigan",
"US/Mountain": "EUA/montanha",
"US/Pacific": "EUA/Pacífico",
"US/Samoa": "EUA/Samoa",
"UTC": "UTC",
"Universal": "Universal",
"W-SU": "W-SU",
"WET": "MOLHADO",
"Zulu": "Zulu",
}