import _ from 'lodash'
import createActionType from '../utils/action'
import api from '../utils/api'
import { checkForOfflineFailureStatus } from '../utils/util'
import { updateFields } from './subjectStudyMetaData'
import placeFieldValues from '../utils/fieldutils/fieldDecorator'

export const FIELD_RETRIEVE_REQUEST = createActionType('FIELD_RETRIEVE_REQUEST')
export const FIELD_RETRIEVE_SUCCESS = createActionType('FIELD_RETRIEVE_SUCCESS')
export const FIELD_RETRIEVE_FAILURE = createActionType('FIELD_RETRIEVE_FAILURE')
export const CONNECTION_FAILED = createActionType('CONNECTION_FAILED')
export const SAVE_FIELD_LIST_OF_MULTIPLE_FORM = createActionType('SAVE_FIELD_LIST_OF_MULTIPLE_FORM')

export const CLEAR_FIELDS = createActionType('CLEAR_FIELDS')
import Constants from 'expo-constants'
import appConstants from '../constants/constants'
const applicationType = Constants.expoConfig.extra.appType
const retrieveFieldsRequest = () => ({
  type: FIELD_RETRIEVE_REQUEST,
})

const retrieveFieldsFailure = (message) => ({
  type: FIELD_RETRIEVE_FAILURE,
  fields: [],
  message,
})

export const retrieveFieldsSuccess = (fields) => ({
  type: FIELD_RETRIEVE_SUCCESS,
  fields: fields || [],
})

const retrieveOfflineFieldsSuccess = (fields) => ({
  type: FIELD_RETRIEVE_SUCCESS,
  fields: fields || [],
})

const failedToConnect = (message) => ({
  type: CONNECTION_FAILED,
  fields: [],
  message,
})


export const emptyFieldsInStore = () => ({
  type: CLEAR_FIELDS,
})

export const updateFieldList =
  (fieldList, currentOrdinal, nextFieldOrdinal) => async (dispatch) => {
    const data = _.map(fieldList, (field) => ({
      ...field,
      dictionary: field.dictionary ? JSON.stringify(field.dictionary) : null,
      enDictionary: field.enDictionary ? JSON.stringify(field.enDictionary) : null,
      disabled:
        field.ordinal < currentOrdinal
          ? field.disabled
          : !!(field.ordinal > currentOrdinal && field.ordinal < nextFieldOrdinal),
    }))
    dispatch(retrieveFieldsSuccess(data))
  }

export const saveFieldListOfMultipleForms = (fieldListOfForms) => async (dispatch) => {
  dispatch({
    type: SAVE_FIELD_LIST_OF_MULTIPLE_FORM,
    fieldListOfForms: fieldListOfForms,
  })
}
