// import BasicScale from "../BasicScale"
import React from 'react'
import { Platform, View } from 'react-native'
import BasicVerticalScale from './BasicVerticalScale'
import BasicVASScale from './BasicVASScale'

const VerticalScale = (props) => {
    return(
        <View>
            {Platform.OS !== "web" ? <BasicVerticalScale {...props} /> : <BasicVASScale {...props} />}
        </View>
    )
}
export default VerticalScale