import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import constants from '../../constants/constants'
import applicationConstants from '../../constants/constants'
import Header from '../../components/common/header'
import ProfileHeader from '../../components/common/Profile/index'

const FormScreenHeader = (props)  => {
    const {navigation, screenProps} = props
    const { appStatus } = useSelector((state) => state)
        if(_.isEqual(appStatus.appType, constants.AppType.SITESTAFF)){
            return (
                <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: 5,
          backgroundColor:'white'
        }}
      >
        <Header
          navigation={navigation}
          canShowBackIcon={applicationConstants.AppType.SITESTAFF === appStatus.appType ? true : false}
          title={navigation.getParam('formName')}
          appType={appStatus.appType}
          onPress={ () => navigation.goBack()
          }
        />
        <ProfileHeader navigation={navigation} screenProps={screenProps} />
      </View>
            )
        }
    
        return <Header navigation={navigation} title={navigation.getParam('formName')} canShowBackIcon={true} />

    

}

export default FormScreenHeader