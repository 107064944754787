import React, { useMemo }  from 'react'
import { View, Platform, Alert} from 'react-native'
import WebAlert from '../utils/WebAlert'
import _ from 'lodash'
import moment from 'moment-timezone'
import coreConstants from '../constants/constants'

const ConfirmationPopUp =  (props) => useMemo(() => {
    const {t,subjectStudyMetaData, appStatus, alertText,
        ackTimezone, setTimezone, buttons, shouldHandleTimezoneChange, inferredValue, configuredValue, isSubjectRegistered}= props
    return (
        !appStatus?.isAcknowledged && shouldHandleTimezoneChange && 
    subjectStudyMetaData?.privacyPolicy?.agreedToTerms &&
     !subjectStudyMetaData?.resetPassword && !subjectStudyMetaData?.passwordExpired  && isSubjectRegistered &&
     inferredValue && configuredValue && appStatus?.appType !== coreConstants.AppType.SITESTAFF &&(
        <View>
    {Platform.OS === 'web' && (
        <WebAlert
        headerText={''}
        hideModal={ackTimezone}
        t={t}
        action={setTimezone}
        message={alertText}
        buttons = {buttons}
        />
    )}
    {Platform.OS !== 'web' && (
         Alert.alert(
         '',
         alertText,
         [
             {
             text: t('NO'),
             onPress: async () => ackTimezone(),
             style: 'cancel',
             },
             {
             text: t('YES'),
             onPress: async () =>  setTimezone(),
             },
         ],
         { cancelable: false }
         )
     )
    }
    </View>
    ));
  }, [props?.subjectStudyMetaData?.subject?.timeZone, props?.isMismatched,props?.appStatus?.isAcknowledged,props?.subjectStudyMetaData?.resetPassword,  props?.isSubjectRegistered]);

const TimeZoneMismatchAlert = (props) => {
const { t, setOrAckTimezoneChange, isTimeZoneNoClicked, subjectStudyMetaData, appStatus} = props
const inferredValue = moment.tz.guess(true);
const configuredValue = subjectStudyMetaData?.subject?.timeZone;
const inferredOffset = moment.tz(inferredValue).utcOffset()
const configuredOffset = moment.tz(configuredValue).utcOffset()
const isMismatched = inferredValue && configuredOffset !== inferredOffset
const shouldHandleTimezoneChange = !appStatus?.isAcknowledged && isMismatched
const isSubjectRegistered = subjectStudyMetaData.isSubjectRegistered
var configOffSet = moment.tz(configuredValue).format('Z')
var inferredOffSet = moment.tz(inferredValue).format('Z')
const buttons = [ 
    { 'title': 'NO', 'action': () => ackTimezone() }, 
    { 'title': 'YES', 'action': () => setTimezone() } 
]

const setTimezone = () =>  {
    setOrAckTimezoneChange(true)
}
const ackTimezone = () => {
    isTimeZoneNoClicked(true);
    setOrAckTimezoneChange(false)
}
const alertText = [
    t('DeviceTimezoneMismatchTitle'),
    '',
    t('DeviceTimezoneMismatchSubtitle'),
    '',
    t('DeviceTimezoneMismatchTzConfigured'),
    '(GMT'+configOffSet+') '+t(configuredValue),
    '',
    t('DeviceTimezoneMismatchTzInferred'),
    '(GMT'+inferredOffSet+') '+t(inferredValue),
].join('\n')

return (
<ConfirmationPopUp 
    t = {t}
    setOrAckTimezoneChange = {setOrAckTimezoneChange}
    isTimeZoneNoClicked ={isTimeZoneNoClicked}
    subjectStudyMetaData ={subjectStudyMetaData}
    appStatus ={appStatus}
    alertText={alertText}
    ackTimezone={ackTimezone}
    setTimezone ={setTimezone}
    buttons={buttons}
    shouldHandleTimezoneChange={shouldHandleTimezoneChange}
    inferredValue={inferredValue}
    configuredValue={configuredValue}
    isMismatched={isMismatched}
    isSubjectRegistered={isSubjectRegistered}
    />)
}
export default TimeZoneMismatchAlert