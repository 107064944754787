import { Platform } from 'react-native'
import appConstants from '../constants/appConstants'
import constants from '../constants/constants'
import NavigationService from '../containers/navigationService'
import createActionType from '../utils/action'
import api from '../utils/api'
import { scheduleNotifications } from '../utils/notifications/scheduler'
import { putItem } from '../utils/secureStorageUtils'
import { storeData } from './login'
import {
  getOfflineDataFailed,
  getOfflineDataRequest,
  getOfflineDataSuccess,
} from './subjectStudyMetaData'
import moment from 'moment'
import _ from 'lodash'
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS'
export const REMOVE_SELECTED_LANGUAGE = createActionType('REMOVE_SELECTED_LANGUAGE')

export const changeLanguageSuccess = (language) => ({
  type: CHANGE_LANGUAGE_SUCCESS,
  value: language,
})

export const clearSelectedLanguage = () => ({
  type: REMOVE_SELECTED_LANGUAGE,
  value: null,
})

const isEdiaryStarted = (crfVersion, diaryStartDate, currentDateTime) => {
  if (_.isEmpty(crfVersion)) {
    return false
  }
  if (!diaryStartDate) {
    return false
  }
  if (moment(diaryStartDate).isAfter(moment(currentDateTime), 'day')) {
    return false
  }
  return true
}
