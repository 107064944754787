export default {
"Africa/Abidjan": "Afrika/Abidjan",
"Africa/Accra": "Afrika/Accra",
"Africa/Addis_Ababa": "Afrika/Addis_Ababa",
"Africa/Algiers": "Afrika/Algiers",
"Africa/Asmara": "Afrika/Asmara",
"Africa/Asmera": "Afrika/Asmera",
"Africa/Bamako": "Afrika/Bamako",
"Africa/Bangui": "Afrika/Bangui",
"Africa/Banjul": "Afrika/Banjul",
"Africa/Bissau": "Afrika/Bissau",
"Africa/Blantyre": "Afrika/Blantyre",
"Africa/Brazzaville": "Afrika/Brazzaville",
"Africa/Bujumbura": "Afrika/Bujumbura",
"Africa/Cairo": "Afrika/Caïro",
"Africa/Casablanca": "Afrika/Casablanca",
"Africa/Ceuta": "Afrika/Ceuta",
"Africa/Conakry": "Afrika/Conakry",
"Africa/Dakar": "Afrika/Dakar",
"Africa/Dar_es_Salaam": "Afrika/Dar_es_Salaam",
"Africa/Djibouti": "Afrika/Djibouti",
"Africa/Douala": "Afrika/Douala",
"Africa/El_Aaiun": "Afrika/El_Aaiun",
"Africa/Freetown": "Afrika/Freetown",
"Africa/Gaborone": "Afrika/Gaborone",
"Africa/Harare": "Afrika/Harare",
"Africa/Johannesburg": "Afrika/Johannesburg",
"Africa/Juba": "Afrika/Juba",
"Africa/Kampala": "Afrika/Kampala",
"Africa/Khartoum": "Afrika/Khartoem",
"Africa/Kigali": "Afrika/Kigali",
"Africa/Kinshasa": "Afrika/Kinshasa",
"Africa/Lagos": "Afrika/Lagos",
"Africa/Libreville": "Afrika/Libreville",
"Africa/Lome": "Afrika/Lome",
"Africa/Luanda": "Afrika/Luanda",
"Africa/Lubumbashi": "Afrika/Lubumbashi",
"Africa/Lusaka": "Afrika/Lusaka",
"Africa/Malabo": "Afrika/Malabo",
"Africa/Maputo": "Afrika/Maputo",
"Africa/Maseru": "Afrika/Maseru",
"Africa/Mbabane": "Afrika/Mbabane",
"Africa/Mogadishu": "Afrika/Mogadishu",
"Africa/Monrovia": "Afrika/Monrovia",
"Africa/Nairobi": "Afrika/Nairobi",
"Africa/Ndjamena": "Afrika/Ndjamena",
"Africa/Niamey": "Afrika/Niamey",
"Africa/Nouakchott": "Afrika/Nouakchott",
"Africa/Ouagadougou": "Afrika/Ouagadougou",
"Africa/Porto-Novo": "Afrika/Porto-Novo",
"Africa/Sao_Tome": "Afrika/Sao_Tome",
"Africa/Timbuktu": "Afrika/Timboektoe",
"Africa/Tripoli": "Afrika/Tripoli",
"Africa/Tunis": "Afrika/Tunis",
"Africa/Windhoek": "Afrika/Windhoek",
"America/Adak": "Amerika/Adak",
"America/Anchorage": "Amerika/Anchorage",
"America/Anguilla": "Amerika/Anguilla",
"America/Antigua": "Amerika/Antigua",
"America/Araguaina": "Amerika/Araguaina",
"America/Argentina/Buenos_Aires": "Amerika/Argentinië/Buenos_Aires",
"America/Argentina/Catamarca": "Amerika/Argentinië/Catamarca",
"America/Argentina/ComodRivadavia": "Amerika/Argentinië/Como Drivadavia",
"America/Argentina/Cordoba": "Amerika/Argentinië/Cordoba",
"America/Argentina/Jujuy": "Amerika/Argentinië/Jujuy",
"America/Argentina/La_Rioja": "Amerika/Argentinië/La_Rioja",
"America/Argentina/Mendoza": "Amerika/Argentinië/Mendoza",
"America/Argentina/Rio_Gallegos": "Amerika/Argentinië/Rio_Gallegos",
"America/Argentina/Salta": "Amerika/Argentinië/Salta",
"America/Argentina/San_Juan": "Amerika/Argentinië/San_Juan",
"America/Argentina/San_Luis": "Amerika/Argentinië/San_Luis",
"America/Argentina/Tucuman": "Amerika/Argentinië/Tucuman",
"America/Argentina/Ushuaia": "Amerika/Argentinië/Ushuaia",
"America/Aruba": "Amerika/Aruba",
"America/Asuncion": "Amerika/Asuncion",
"America/Atikokan": "Amerika/Atikokan",
"America/Atka": "Amerika/Atka",
"America/Bahia": "Amerika/Bahia",
"America/Bahia_Banderas": "Amerika/Bahia_Banderas",
"America/Barbados": "Amerika/Barbados",
"America/Belem": "Amerika/Belem",
"America/Belize": "Amerika/Belize",
"America/Blanc-Sablon": "Amerika/Blanc-Sablon",
"America/Boa_Vista": "Amerika/Boa_Vista",
"America/Bogota": "Amerika/Bogota",
"America/Boise": "Amerika/Boise",
"America/Buenos_Aires": "Amerika/Buenos_Aires",
"America/Cambridge_Bay": "Amerika/Cambridge_Bay",
"America/Campo_Grande": "Amerika/Campo_Grande",
"America/Cancun": "Amerika/Cancun",
"America/Caracas": "Amerika/Caracas",
"America/Catamarca": "Amerika/Catamarca",
"America/Cayenne": "Amerika/Cayenne",
"America/Cayman": "Amerika/Cayman",
"America/Chicago": "Amerika/Chicago",
"America/Chihuahua": "Amerika/Chihuahua",
"America/Ciudad_Juarez": "Amerika/Ciudad_Juarez",
"America/Coral_Harbour": "Amerika/Coral_Harbour",
"America/Cordoba": "Amerika/Cordoba",
"America/Costa_Rica": "Amerika/Costa_Rica",
"America/Creston": "Amerika/Creston",
"America/Cuiaba": "Amerika/Cuiaba",
"America/Curacao": "Amerika/Curaçao",
"America/Danmarkshavn": "Amerika/Danmarkshavn",
"America/Dawson": "Amerika/Dawson",
"America/Dawson_Creek": "Amerika/Dawson_Creek",
"America/Denver": "Amerika/Denver",
"America/Detroit": "Amerika/Detroit",
"America/Dominica": "Amerika/Dominica",
"America/Edmonton": "Amerika/Edmonton",
"America/Eirunepe": "Amerika/Eirunepe",
"America/El_Salvador": "Amerika/El_Salvador",
"America/Ensenada": "Amerika/Ensenada",
"America/Fort_Nelson": "Amerika/Fort_Nelson",
"America/Fort_Wayne": "Amerika/Fort_Wayne",
"America/Fortaleza": "Amerika/Fortaleza",
"America/Glace_Bay": "Amerika/Glace_Bay",
"America/Godthab": "Amerika/Godthab",
"America/Goose_Bay": "Amerika/Goose_Bay",
"America/Grand_Turk": "Amerika/Grand_Turk",
"America/Grenada": "Amerika/Grenada",
"America/Guadeloupe": "Amerika/Guadeloupe",
"America/Guatemala": "Amerika/Guatemala",
"America/Guayaquil": "Amerika/Guayaquil",
"America/Guyana": "Amerika/Guyana",
"America/Halifax": "Amerika/Halifax",
"America/Havana": "Amerika/Havana",
"America/Hermosillo": "Amerika/Hermosillo",
"America/Indiana/Indianapolis": "Amerika/Indiana/Indianapolis",
"America/Indiana/Knox": "Amerika/Indiana/Knox",
"America/Indiana/Marengo": "Amerika/Indiana/Marengo",
"America/Indiana/Petersburg": "Amerika/Indiana/Petersburg",
"America/Indiana/Tell_City": "Amerika/Indiana/Tell_City",
"America/Indiana/Vevay": "Amerika/Indiana/Vevay",
"America/Indiana/Vincennes": "Amerika/Indiana/Vincennes",
"America/Indiana/Winamac": "Amerika/Indiana/Winamac",
"America/Indianapolis": "Amerika/Indianapolis",
"America/Inuvik": "Amerika/Inuvik",
"America/Iqaluit": "Amerika/Iqaluit",
"America/Jamaica": "Amerika/Jamaica",
"America/Jujuy": "Amerika/Jujuy",
"America/Juneau": "Amerika/Juneau",
"America/Kentucky/Louisville": "Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello": "Amerika/Kentucky/Monticello",
"America/Knox_IN": "Amerika/Knox_in",
"America/Kralendijk": "Amerika/Kralendijk",
"America/La_Paz": "Amerika/La_Paz",
"America/Lima": "Amerika/Lima",
"America/Los_Angeles": "Amerika/Los_Angeles",
"America/Louisville": "Amerika/Louisville",
"America/Lower_Princes": "Amerika/Lower_Princes",
"America/Maceio": "Amerika/Maceio",
"America/Managua": "Amerika/Managua",
"America/Manaus": "Amerika/Manaus",
"America/Marigot": "Amerika/Marigot",
"America/Martinique": "Amerika/Martinique",
"America/Matamoros": "Amerika/Matamoros",
"America/Mazatlan": "Amerika/Mazatlan",
"America/Mendoza": "Amerika/Mendoza",
"America/Menominee": "Amerika/Menominee",
"America/Merida": "Amerika/Merida",
"America/Metlakatla": "Amerika/Metlakatla",
"America/Mexico_City": "Amerika/Mexico_City",
"America/Miquelon": "Amerika/Miquelon",
"America/Moncton": "Amerika/Moncton",
"America/Monterrey": "Amerika/Monterrey",
"America/Montevideo": "Amerika/Montevideo",
"America/Montreal": "Amerika/Montreal",
"America/Montserrat": "Amerika/Montserrat",
"America/Nassau": "Amerika/Nassau",
"America/New_York": "Amerika/New_York",
"America/Nipigon": "Amerika/Nipigon",
"America/Nome": "Amerika/Nome",
"America/Noronha": "Amerika/Noronha",
"America/North_Dakota/Beulah": "Amerika/North_Dakota/Beulah",
"America/North_Dakota/Center": "Amerika/North_Dakota/Center",
"America/North_Dakota/New_Salem": "Amerika/North_Dakota/New_Salem",
"America/Nuuk": "Amerika/Nuuk",
"America/Ojinaga": "Amerika/Ojinaga",
"America/Panama": "Amerika/Panama",
"America/Pangnirtung": "Amerika/Pangnirtung",
"America/Paramaribo": "Amerika/Paramaribo",
"America/Phoenix": "Amerika/Phoenix",
"America/Port-au-Prince": "Amerika/Port-au-Prince",
"America/Port_of_Spain": "Amerika/Port_of_Spain",
"America/Porto_Acre": "Amerika/Porto_Acre",
"America/Porto_Velho": "Amerika/Porto_Velho",
"America/Puerto_Rico": "Amerika/Puerto_Rico",
"America/Punta_Arenas": "Amerika/Punta_Arenas",
"America/Rainy_River": "Amerika/Rainy_River",
"America/Rankin_Inlet": "Amerika/Rankin_Inlet",
"America/Recife": "Amerika/Recife",
"America/Regina": "Amerika/Regina",
"America/Resolute": "Amerika/Resolute",
"America/Rio_Branco": "Amerika/Rio_Branco",
"America/Rosario": "Amerika/Rosario",
"America/Santa_Isabel": "Amerika/Santa_Isabel",
"America/Santarem": "Amerika/Santarem",
"America/Santiago": "Amerika/Santiago",
"America/Santo_Domingo": "Amerika/Santo_Domingo",
"America/Sao_Paulo": "Amerika/Sao_Paulo",
"America/Scoresbysund": "Amerika/Scoresbysund",
"America/Shiprock": "Amerika/Shiprock",
"America/Sitka": "Amerika/Sitka",
"America/St_Barthelemy": "Amerika/St_Barthélemy",
"America/St_Johns": "Amerika/St_Johns",
"America/St_Kitts": "Amerika/St_Kitts",
"America/St_Lucia": "Amerika/St_Lucia",
"America/St_Thomas": "Amerika/St_Thomas",
"America/St_Vincent": "Amerika/St_Vincent",
"America/Swift_Current": "Amerika/Swift_Current",
"America/Tegucigalpa": "Amerika/Tegucigalpa",
"America/Thule": "Amerika/Thule",
"America/Thunder_Bay": "Amerika/Thunder_Bay",
"America/Tijuana": "Amerika/Tijuana",
"America/Toronto": "Amerika/Toronto",
"America/Tortola": "Amerika/Tortola",
"America/Vancouver": "Amerika/Vancouver",
"America/Virgin": "Amerika/Virgin",
"America/Whitehorse": "Amerika/Whitehorse",
"America/Winnipeg": "Amerika/Winnipeg",
"America/Yakutat": "Amerika/Yakutat",
"America/Yellowknife": "Amerika/Yellowknife",
"Antarctica/Casey": "Antarctica/Casey",
"Antarctica/Davis": "Antarctica/Davis",
"Antarctica/DumontDUrville": "Antarctica/Dumont-Durville",
"Antarctica/Macquarie": "Antarctica/Macquarie",
"Antarctica/Mawson": "Antarctica/Mawson",
"Antarctica/McMurdo": "Antarctica/McMurdo",
"Antarctica/Palmer": "Antarctica/Palmer",
"Antarctica/Rothera": "Antarctica/Rothera",
"Antarctica/South_Pole": "Antarctica/Zuidpool",
"Antarctica/Syowa": "Antarctica/Syowa",
"Antarctica/Troll": "Antarctica/Troll",
"Antarctica/Vostok": "Antarctica/Vostok",
"Arctic/Longyearbyen": "Arctic/Longyearbyen",
"Asia/Aden": "Azië/Aden",
"Asia/Almaty": "Azië/Almaty",
"Asia/Amman": "Azië/Amman",
"Asia/Anadyr": "Azië/Anadyr",
"Asia/Aqtau": "Azië/Aqtau",
"Asia/Aqtobe": "Azië/Aqtobe",
"Asia/Ashgabat": "Azië/Ashgabat",
"Asia/Ashkhabad": "Azië/Ashkhabad",
"Asia/Atyrau": "Azië/Atyrau",
"Asia/Baghdad": "Azië/Bagdad",
"Asia/Bahrain": "Azië/Bahrein",
"Asia/Baku": "Azië/Baku",
"Asia/Bangkok": "Azië/Bangkok",
"Asia/Barnaul": "Azië/Barnaul",
"Asia/Beirut": "Azië/Beiroet",
"Asia/Bishkek": "Azië/Bisjkek",
"Asia/Brunei": "Azië/Brunei",
"Asia/Calcutta": "Azië/Calcutta",
"Asia/Chita": "Azië/Chita",
"Asia/Choibalsan": "Azië/Choibalsan",
"Asia/Chongqing": "Azië/Chongqing",
"Asia/Chungking": "Azië/Chungking",
"Asia/Colombo": "Azië/Colombo",
"Asia/Dacca": "Azië/Dacca",
"Asia/Damascus": "Azië/Damascus",
"Asia/Dhaka": "Azië/Dhaka",
"Asia/Dili": "Azië/Dili",
"Asia/Dubai": "Azië/Dubai",
"Asia/Dushanbe": "Azië/Dushanbe",
"Asia/Famagusta": "Azië/Famagusta",
"Asia/Gaza": "Azië/Gaza",
"Asia/Harbin": "Azië/Harbin",
"Asia/Hebron": "Azië/Hebron",
"Asia/Ho_Chi_Minh": "Azië/Ho_Chi_Minh",
"Asia/Hong_Kong": "Azië/Hong_Kong",
"Asia/Hovd": "Azië/Hovd",
"Asia/Irkutsk": "Azië/Irkoetsk",
"Asia/Istanbul": "Azië/Istanbul",
"Asia/Jakarta": "Azië/Jakarta",
"Asia/Jayapura": "Azië/Jayapura",
"Asia/Jerusalem": "Azië/Jeruzalem",
"Asia/Kabul": "Azië/Kabul",
"Asia/Kamchatka": "Azië/Kamtsjatka",
"Asia/Karachi": "Azië/Karachi",
"Asia/Kashgar": "Azië/Kashgar",
"Asia/Kathmandu": "Azië/Kathmandu",
"Asia/Katmandu": "Azië/Kathmandu",
"Asia/Khandyga": "Azië/Khandyga",
"Asia/Kolkata": "Azië/Kolkata",
"Asia/Krasnoyarsk": "Azië/Krasnoyarsk",
"Asia/Kuala_Lumpur": "Azië/Kuala_Lumpur",
"Asia/Kuching": "Azië/Kuching",
"Asia/Kuwait": "Azië/Koeweit",
"Asia/Macao": "Azië/Macao",
"Asia/Macau": "Azië/Macau",
"Asia/Magadan": "Azië/Magadan",
"Asia/Makassar": "Azië/Makassar",
"Asia/Manila": "Azië/Manilla",
"Asia/Muscat": "Azië/Muscat",
"Asia/Nicosia": "Azië/Nicosia",
"Asia/Novokuznetsk": "Azië/Novokuznetsk",
"Asia/Novosibirsk": "Azië/Novosibirsk",
"Asia/Omsk": "Azië/Omsk",
"Asia/Oral": "Azië/Oral",
"Asia/Phnom_Penh": "Azië/Phnom_Penh",
"Asia/Pontianak": "Azië/Pontianak",
"Asia/Pyongyang": "Azië/Pyongyang",
"Asia/Qatar": "Azië/Qatar",
"Asia/Qostanay": "Azië/Qostanay",
"Asia/Qyzylorda": "Azië/Qyzylorda",
"Asia/Rangoon": "Azië/Rangoon",
"Asia/Riyadh": "Azië/Riyad",
"Asia/Saigon": "Azië/Saigon",
"Asia/Sakhalin": "Azië/Sachalin",
"Asia/Samarkand": "Azië/Samarkand",
"Asia/Seoul": "Azië/Seoul",
"Asia/Shanghai": "Azië/Shanghai",
"Asia/Singapore": "Azië/Singapore",
"Asia/Srednekolymsk": "Azië/Srednekolymsk",
"Asia/Taipei": "Azië/Taipei",
"Asia/Tashkent": "Azië/Tasjkent",
"Asia/Tbilisi": "Azië/Tbilisi",
"Asia/Tehran": "Azië/Teheran",
"Asia/Tel_Aviv": "Azië/Tel-Aviv",
"Asia/Thimbu": "Azië/Thimbu",
"Asia/Thimphu": "Azië/Thimphu",
"Asia/Tokyo": "Azië/Tokio",
"Asia/Tomsk": "Azië/Tomsk",
"Asia/Ujung_Pandang": "Azië/Ujung_Pandang",
"Asia/Ulaanbaatar": "Azië/Ulaanbaatar",
"Asia/Ulan_Bator": "Azië/Ulan_Bator",
"Asia/Urumqi": "Azië/Urumqi",
"Asia/Ust-Nera": "Azië/Ust-Nera",
"Asia/Vientiane": "Azië/Vientiane",
"Asia/Vladivostok": "Azië/Vladivostok",
"Asia/Yakutsk": "Azië/Yakutsk",
"Asia/Yangon": "Azië/Yangon",
"Asia/Yekaterinburg": "Azië/Yekaterinburg",
"Asia/Yerevan": "Azië/Yerevan",
"Atlantic/Azores": "Atlantisch/Azoren",
"Atlantic/Bermuda": "Atlantisch/Bermuda",
"Atlantic/Canary": "Atlantisch/Canarische Eilanden",
"Atlantic/Cape_Verde": "Atlantic/Kaapverdië",
"Atlantic/Faeroe": "Atlantisch/Faeröer",
"Atlantic/Faroe": "Atlantisch/Faeröer",
"Atlantic/Jan_Mayen": "Atlantic/Jan_Mayen",
"Atlantic/Madeira": "Atlantisch/Madeira",
"Atlantic/Reykjavik": "Atlantic/Reykjavik",
"Atlantic/South_Georgia": "Atlantisch/Zuid_Georgië",
"Atlantic/St_Helena": "Atlantic/Sint-Helena",
"Atlantic/Stanley": "Atlantic/Stanley",
"Australia/ACT": "Australië/ACT",
"Australia/Adelaide": "Australië/Adelaide",
"Australia/Brisbane": "Australië/Brisbane",
"Australia/Broken_Hill": "Australië/Broken_Hill",
"Australia/Canberra": "Australië/Canberra",
"Australia/Currie": "Australië/Currie",
"Australia/Darwin": "Australië/Darwin",
"Australia/Eucla": "Australië/Eucla",
"Australia/Hobart": "Australië/Hobart",
"Australia/LHI": "Australië/LHI",
"Australia/Lindeman": "Australië/Lindeman",
"Australia/Lord_Howe": "Australië/Lord_Howe",
"Australia/Melbourne": "Australië/Melbourne",
"Australia/NSW": "Australië/NSW",
"Australia/North": "Australië/Noord",
"Australia/Perth": "Australië/Perth",
"Australia/Queensland": "Australië/Queensland",
"Australia/South": "Australië/Zuid",
"Australia/Sydney": "Australië/Sydney",
"Australia/Tasmania": "Australië/Tasmanië",
"Australia/Victoria": "Australië/Victoria",
"Australia/West": "Australië/West",
"Australia/Yancowinna": "Australië/Yandowinna",
"Brazil/Acre": "Brazilië/Acre",
"Brazil/DeNoronha": "Brazilië/Denoronha",
"Brazil/East": "Brazilië/Oost",
"Brazil/West": "Brazilië/West",
"CET": "CET",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Canada/Atlantic",
"Canada/Central": "Canada/Central",
"Canada/Eastern": "Canada/Oost",
"Canada/Mountain": "Canada/Mountain",
"Canada/Newfoundland": "Canada/Newfoundland",
"Canada/Pacific": "Canada/Pacific",
"Canada/Saskatchewan": "Canada/Saskatchewan",
"Canada/Yukon": "Canada/Yukon",
"Chile/Continental": "Chili/Continentaal",
"Chile/EasterIsland": "Chili/Paaseiland",
"Cuba": "Cuba",
"EET": "VOETEN",
"EST": "BESTE",
"EST5EDT": "EST5EDT",
"Egypt": "Egypte",
"Eire": "Draad",
"Etc/GMT": "ETC/GMT",
"Etc/GMT+0": "ETC/GMT+0",
"Etc/GMT+1": "ETC/GMT+1",
"Etc/GMT+10": "ETC/GMT+10",
"Etc/GMT+11": "ETC/GMT+11",
"Etc/GMT+12": "ETC/GMT+12",
"Etc/GMT+2": "ETC/GMT+2",
"Etc/GMT+3": "ETC/GMT+3",
"Etc/GMT+4": "ETC/GMT+4",
"Etc/GMT+5": "ETC/GMT+5",
"Etc/GMT+6": "ETC/GMT+6",
"Etc/GMT+7": "ETC/GMT+7",
"Etc/GMT+8": "ETC/GMT+8",
"Etc/GMT+9": "ETC/GMT+9",
"Etc/GMT-0": "ETC/GMT-0",
"Etc/GMT-1": "ETC/GMT-1",
"Etc/GMT-10": "ETC/GMT-10",
"Etc/GMT-11": "ETC/GMT-11",
"Etc/GMT-12": "ETC/GMT-12",
"Etc/GMT-13": "ETC/GMT-13",
"Etc/GMT-14": "ETC/GMT-14",
"Etc/GMT-2": "ETC/GMT-2",
"Etc/GMT-3": "ETC/GMT-3",
"Etc/GMT-4": "ETC/GMT-4",
"Etc/GMT-5": "ETC/GMT-5",
"Etc/GMT-6": "ETC/GMT-6",
"Etc/GMT-7": "ETC/GMT-7",
"Etc/GMT-8": "ETC/GMT-8",
"Etc/GMT-9": "ETC/GMT-9",
"Etc/GMT0": "ETC/GMT0",
"Etc/Greenwich": "Enzc/Greenwich",
"Etc/UCT": "Enz/snijden",
"Etc/UTC": "Etc/UTC",
"Etc/Universal": "Etc/Universeel",
"Etc/Zulu": "Etc/Zoeloe",
"Europe/Amsterdam": "Europa/Amsterdam",
"Europe/Andorra": "Europa/Andorra",
"Europe/Astrakhan": "Europa/Astrakan",
"Europe/Athens": "Europa/Athene",
"Europe/Belfast": "Europa/Belfast",
"Europe/Belgrade": "Europa/Belgrado",
"Europe/Berlin": "Europa/Berlijn",
"Europe/Bratislava": "Europa/Bratislava",
"Europe/Brussels": "Europa/Brussel",
"Europe/Bucharest": "Europa/Boekarest",
"Europe/Budapest": "Europa/Boedapest",
"Europe/Busingen": "Europa/Busingen",
"Europe/Chisinau": "Europa/Chisinau",
"Europe/Copenhagen": "Europa/Kopenhagen",
"Europe/Dublin": "Europa/Dublin",
"Europe/Gibraltar": "Europa/Gibraltar",
"Europe/Guernsey": "Europa/Guernsey",
"Europe/Helsinki": "Europa/Helsinki",
"Europe/Isle_of_Man": "Europa/Isle_of_Man",
"Europe/Istanbul": "Europa/Istanbul",
"Europe/Jersey": "Europa/Jersey",
"Europe/Kaliningrad": "Europa/Kaliningrad",
"Europe/Kiev": "Europa/Kiev",
"Europe/Kirov": "Europa/Kirov",
"Europe/Kyiv": "Europa/Kiev",
"Europe/Lisbon": "Europa/Lissabon",
"Europe/Ljubljana": "Europa/Ljubljana",
"Europe/London": "Europa/Londen",
"Europe/Luxembourg": "Europa/Luxemburg",
"Europe/Madrid": "Europa/Madrid",
"Europe/Malta": "Europa/Malta",
"Europe/Mariehamn": "Europa/Mariehamn",
"Europe/Minsk": "Europa/Minsk",
"Europe/Monaco": "Europa/Monaco",
"Europe/Moscow": "Europa/Moskou",
"Europe/Nicosia": "Europa/Nicosia",
"Europe/Oslo": "Europa/Oslo",
"Europe/Paris": "Europa/Parijs",
"Europe/Podgorica": "Europa/Podgorica",
"Europe/Prague": "Europa/Praag",
"Europe/Riga": "Europa/Riga",
"Europe/Rome": "Europa/Rome",
"Europe/Samara": "Europa/Samara",
"Europe/San_Marino": "Europa/San_Marino",
"Europe/Sarajevo": "Europa/Sarajevo",
"Europe/Saratov": "Europa/Saratov",
"Europe/Simferopol": "Europa/Simferopol",
"Europe/Skopje": "Europa/Skopje",
"Europe/Sofia": "Europa/Sofia",
"Europe/Stockholm": "Europa/Stockholm",
"Europe/Tallinn": "Europa/Tallinn",
"Europe/Tirane": "Europa/Tirane",
"Europe/Tiraspol": "Europa/Tiraspol",
"Europe/Ulyanovsk": "Europa/Ulyanovsk",
"Europe/Uzhgorod": "Europa/Uzhgorod",
"Europe/Vaduz": "Europa/Vaduz",
"Europe/Vatican": "Europa/Vaticaan",
"Europe/Vienna": "Europa/Wenen",
"Europe/Vilnius": "Europa/Vilnius",
"Europe/Volgograd": "Europa/Volgograd",
"Europe/Warsaw": "Europa/Warschau",
"Europe/Zagreb": "Europa/Zagreb",
"Europe/Zaporozhye": "Europa/Zaporizhye",
"Europe/Zurich": "Europa/Zürich",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "HOED",
"Hongkong": "Hongkong",
"Iceland": "IJsland",
"Indian/Antananarivo": "Indische/Antananarivo",
"Indian/Chagos": "Indische/Chagos",
"Indian/Christmas": "Indiaas/Kerstmis",
"Indian/Cocos": "Indiaas/kokos",
"Indian/Comoro": "Indiaas/Comoren",
"Indian/Kerguelen": "Indiaas/Kerguelen",
"Indian/Mahe": "Indisch/Mahe",
"Indian/Maldives": "Indische/Malediven",
"Indian/Mauritius": "Indisch/Mauritius",
"Indian/Mayotte": "Indiaas/Mayotte",
"Indian/Reunion": "Indische/Réunion",
"Iran": "Iran",
"Israel": "Israël",
"Jamaica": "Jamaica",
"Japan": "Japan",
"Kwajalein": "Kwajalein",
"Libya": "Libië",
"MET": "ONTMOET",
"MST": "MOET",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Mexico/Bajanorte",
"Mexico/BajaSur": "Mexico/Bajasur",
"Mexico/General": "Mexico/Algemeen",
"NZ": "NZ",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Pacific/Apia",
"Pacific/Auckland": "Pacific/Auckland",
"Pacific/Bougainville": "Pacific/Bougainville",
"Pacific/Chatham": "Pacific/Chatham",
"Pacific/Chuuk": "Pacific/Chuuk",
"Pacific/Easter": "Pacific/Pasen",
"Pacific/Efate": "Pacific/Efate",
"Pacific/Enderbury": "Pacific/Enderbury",
"Pacific/Fakaofo": "Pacific/Fakaofo",
"Pacific/Fiji": "Pacific/Fiji",
"Pacific/Funafuti": "Pacific/Funafuti",
"Pacific/Galapagos": "Pacific/Galapagos",
"Pacific/Gambier": "Pacific/Gambier",
"Pacific/Guadalcanal": "Pacific/Guadalcanal",
"Pacific/Guam": "Pacific/Guam",
"Pacific/Honolulu": "Pacific/Honolulu",
"Pacific/Johnston": "Pacific/Johnston",
"Pacific/Kanton": "Pacific/Kanton",
"Pacific/Kiritimati": "Pacific/Kiritimati",
"Pacific/Kosrae": "Pacific/Kosrae",
"Pacific/Kwajalein": "Pacific/Kwajalein",
"Pacific/Majuro": "Pacific/Majuro",
"Pacific/Marquesas": "Pacific/Marquesas",
"Pacific/Midway": "Pacific/Midway",
"Pacific/Nauru": "Pacific/Nauru",
"Pacific/Niue": "Pacific/Niue",
"Pacific/Norfolk": "Pacific/Norfolk",
"Pacific/Noumea": "Pacific/Noumea",
"Pacific/Pago_Pago": "Pacific/Pago_Pago",
"Pacific/Palau": "Pacific/Palau",
"Pacific/Pitcairn": "Pacific/Pitcairn",
"Pacific/Pohnpei": "Pacific/Pohnpei",
"Pacific/Ponape": "Pacific/Ponape",
"Pacific/Port_Moresby": "Pacific/Port_Moresby",
"Pacific/Rarotonga": "Pacific/Rarotonga",
"Pacific/Saipan": "Pacific/Saipan",
"Pacific/Samoa": "Pacific/Samoa",
"Pacific/Tahiti": "Pacific/Tahiti",
"Pacific/Tarawa": "Pacific/Tarawa",
"Pacific/Tongatapu": "Pacific/Tongatapu",
"Pacific/Truk": "Pacific/vrachtwagen",
"Pacific/Wake": "Pacific/Wake",
"Pacific/Wallis": "Pacific/Wallis",
"Pacific/Yap": "Pacific/Yap",
"Poland": "Polen",
"Portugal": "Portugal",
"ROC": "ROTS",
"ROK": "ROCK",
"Singapore": "Singapore",
"Turkey": "Turkije",
"UCT": "SNIJDEN",
"US/Alaska": "US/Alaska",
"US/Aleutian": "US/Aleoeten",
"US/Arizona": "VS/Arizona",
"US/Central": "US/Central",
"US/East-Indiana": "VS/Oost-Indiana",
"US/Eastern": "VS/Oost",
"US/Hawaii": "VS/Hawaï",
"US/Indiana-Starke": "VS/Indiana-Starke",
"US/Michigan": "VS/Michigan",
"US/Mountain": "US/Mountain",
"US/Pacific": "VS/Pacific",
"US/Samoa": "VS/Samoa",
"UTC": "UTC",
"Universal": "Universeel",
"W-SU": "W-SU",
"WET": "NAT",
"Zulu": "Zoeloe",
}