export default {
    "Africa/Abidjan":"África/Abiyán",
"Africa/Accra":"África/Accra",
"Africa/Addis_Ababa":"África/Addis_Ababa",
"Africa/Algiers":"África/Argel",
"Africa/Asmara":"África/Asmara",
"Africa/Asmera":"África/Asmera",
"Africa/Bamako":"África/Bamako",
"Africa/Bangui":"África/Bangui",
"Africa/Banjul":"África/Banjul",
"Africa/Bissau":"África/Bisáu",
"Africa/Blantyre":"África/Blantyre",
"Africa/Brazzaville":"África/Brazzaville",
"Africa/Bujumbura":"África/Buyumbura",
"Africa/Cairo":"África/El Cairo",
"Africa/Casablanca":"África/Casablanca",
"Africa/Ceuta":"África/Ceuta",
"Africa/Conakry":"África/Conakry",
"Africa/Dakar":"África/Dakar",
"Africa/Dar_es_Salaam":"África/Dar_es_Salaam",
"Africa/Djibouti":"África/Yibuti",
"Africa/Douala":"África/Duala",
"Africa/El_Aaiun":"África/El_Aaiun",
"Africa/Freetown":"África/Freetown",
"Africa/Gaborone":"África/Gaborone",
"Africa/Harare":"África/Harare",
"Africa/Johannesburg":"África/Johannesburgo",
"Africa/Juba":"África/Juba",
"Africa/Kampala":"África/Kampala",
"Africa/Khartoum":"África/Jartum",
"Africa/Kigali":"África/Kigali",
"Africa/Kinshasa":"África/Kinshasa",
"Africa/Lagos":"África/Lagos",
"Africa/Libreville":"África/Libreville",
"Africa/Lome":"África/Lomé",
"Africa/Luanda":"África/Luanda",
"Africa/Lubumbashi":"África/Lubumbashi",
"Africa/Lusaka":"África/Lusaka",
"Africa/Malabo":"África/Malabo",
"Africa/Maputo":"África/Maputo",
"Africa/Maseru":"África/Maseru",
"Africa/Mbabane":"África/Mbabane",
"Africa/Mogadishu":"África/Mogadiscio",
"Africa/Monrovia":"África/Monrovia",
"Africa/Nairobi":"África/Nairobi",
"Africa/Ndjamena":"África/Ndjamena",
"Africa/Niamey":"África/Niamey",
"Africa/Nouakchott":"África/Nuakchot",
"Africa/Ouagadougou":"África/Uagadugú",
"Africa/Porto-Novo":"África/Porto-Novo",
"Africa/Sao_Tome":"África/Sao_Tomé",
"Africa/Timbuktu":"África/Tombuctú",
"Africa/Tripoli":"África/Trípoli",
"Africa/Tunis":"África/Túnez",
"Africa/Windhoek":"África/Windhoek",
"America/Adak":"América/Adak",
"America/Anchorage":"América/Anchorage",
"America/Anguilla":"América/Anguila",
"America/Antigua":"América/Antigua",
"America/Araguaina":"América/Araguaina",
"America/Argentina/Buenos_Aires":"América/Argentina/Buenos_Aires",
"America/Argentina/Catamarca":"América/Argentina/Catamarca",
"America/Argentina/ComodRivadavia":"América/Argentina/Comodrivadavia",
"America/Argentina/Cordoba":"América/Argentina/Córdoba",
"America/Argentina/Jujuy":"América/Argentina/Jujuy",
"America/Argentina/La_Rioja":"América/Argentina/La_Rioja",
"America/Argentina/Mendoza":"América/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos":"América/Argentina/Rio_Gallegos",
"America/Argentina/Salta":"América/Argentina/Salta",
"America/Argentina/San_Juan":"América/Argentina/San_Juan",
"America/Argentina/San_Luis":"América/Argentina/San_Luis",
"America/Argentina/Tucuman":"América/Argentina/Tucumán",
"America/Argentina/Ushuaia":"América/Argentina/Ushuaia",
"America/Aruba":"América/Aruba",
"America/Asuncion":"América/Asunción",
"America/Atikokan":"América/Atikokan",
"America/Atka":"América/Atka",
"America/Bahia":"América/Bahia",
"America/Bahia_Banderas":"América/Bahia_Banderas",
"America/Barbados":"América/Barbados",
"America/Belem":"América/Belem",
"America/Belize":"América/Belice",
"America/Blanc-Sablon":"América/Blanc-Sablon",
"America/Boa_Vista":"América/Boa_Vista",
"America/Bogota":"América/Bogotá",
"America/Boise":"América/Boise",
"America/Buenos_Aires":"América/Buenos Aires",
"America/Cambridge_Bay":"América/Cambridge_Bay",
"America/Campo_Grande":"América/Campo_Grande",
"America/Cancun":"América/Cancún",
"America/Caracas":"América/Caracas",
"America/Catamarca":"América/Catamarca",
"America/Cayenne":"América/Cayenne",
"America/Cayman":"América/Caimán",
"America/Chicago":"América/Chicago",
"America/Chihuahua":"América/Chihuahua",
"America/Ciudad_Juarez":"América/Ciudad_Juarez",
"America/Coral_Harbour":"América/Coral_Harbour",
"America/Cordoba":"América/Córdoba",
"America/Costa_Rica":"América/Costa Rica",
"America/Creston":"América/Creston",
"America/Cuiaba":"América/Cuiaba",
"America/Curacao":"América/Curazao",
"America/Danmarkshavn":"América/Danmarkshavn",
"America/Dawson":"América/Dawson",
"America/Dawson_Creek":"América/Dawson_Creek",
"America/Denver":"América/Denver",
"America/Detroit":"América/Detroit",
"America/Dominica":"América/Dominica",
"America/Edmonton":"América/Edmonton",
"America/Eirunepe":"América/Eirunepe",
"America/El_Salvador":"América/El Salvador",
"America/Ensenada":"América/Ensenada",
"America/Fort_Nelson":"América/Fort_Nelson",
"America/Fort_Wayne":"América/Fort_Wayne",
"America/Fortaleza":"América/Fortaleza",
"America/Glace_Bay":"América/Glace_Bay",
"America/Godthab":"América/Godthab",
"America/Goose_Bay":"América/Goose_Bay",
"America/Grand_Turk":"América/Grand_Turk",
"America/Grenada":"América/Granada",
"America/Guadeloupe":"América/Guadalupe",
"America/Guatemala":"América/Guatemala",
"America/Guayaquil":"América/Guayaquil",
"America/Guyana":"América/Guyana",
"America/Halifax":"América/Halifax",
"America/Havana":"América/La Habana",
"America/Hermosillo":"América/Hermosillo",
"America/Indiana/Indianapolis":"América/Indiana/Indianápolis",
"America/Indiana/Knox":"América/Indiana/Knox",
"America/Indiana/Marengo":"América/Indiana/Marengo",
"America/Indiana/Petersburg":"América/Indiana/Petersburg",
"America/Indiana/Tell_City":"América/Indiana/Tell_City",
"America/Indiana/Vevay":"América/Indiana/Vevay",
"America/Indiana/Vincennes":"América/Indiana/Vincennes",
"America/Indiana/Winamac":"América/Indiana/Winamac",
"America/Indianapolis":"América/Indianápolis",
"America/Inuvik":"América/Inuvik",
"America/Iqaluit":"América/Iqaluit",
"America/Jamaica":"América/Jamaica",
"America/Jujuy":"América/Jujuy",
"America/Juneau":"América/Juneau",
"America/Kentucky/Louisville":"América/Kentucky/Louisville",
"America/Kentucky/Monticello":"América/Kentucky/Monticello",
"America/Knox_IN":"América/Knox_in",
"America/Kralendijk":"América/Kralendijk",
"America/La_Paz":"América/La_Paz",
"America/Lima":"América/Lima",
"America/Los_Angeles":"América/Los Ángeles",
"America/Louisville":"América/Louisville",
"America/Lower_Princes":"America/Lower Princes",
"America/Maceio":"América/Maceio",
"America/Managua":"América/Managua",
"America/Manaus":"América/Manaus",
"America/Marigot":"América/Marigot",
"America/Martinique":"América/Martinica",
"America/Matamoros":"América/Matamoros",
"America/Mazatlan":"América/Mazatlán",
"America/Mendoza":"América/Mendoza",
"America/Menominee":"América/Menominee",
"America/Merida":"América/Mérida",
"America/Metlakatla":"América/Metlakatla",
"America/Mexico_City":"América/Ciudad de México",
"America/Miquelon":"América/Miquelón",
"America/Moncton":"América/Moncton",
"America/Monterrey":"América/Monterrey",
"America/Montevideo":"América/Montevideo",
"America/Montreal":"América/Montreal",
"America/Montserrat":"América/Montserrat",
"America/Nassau":"América/Nassau",
"America/New_York":"America/New_York",
"America/Nipigon":"América/Nipigon",
"America/Nome":"América/Nombre",
"America/Noronha":"América/Noronha",
"America/North_Dakota/Beulah":"América/Dakota del Norte/Beulah",
"America/North_Dakota/Center":"América/Dakota del Norte/Centro",
"America/North_Dakota/New_Salem":"América/Dakota del Norte/New_Salem",
"America/Nuuk":"América/Nuevo",
"America/Ojinaga":"América/Ojinaga",
"America/Panama":"América/Panamá",
"America/Pangnirtung":"America/Pangnirtung",
"America/Paramaribo":"América/Paramaribo",
"America/Phoenix":"América/Fénix",
"America/Port-au-Prince":"América/Puerto Príncipe",
"America/Port_of_Spain":"América/Puerto España",
"America/Porto_Acre":"América/Porto_Acre",
"America/Porto_Velho":"América/Porto_Velho",
"America/Puerto_Rico":"América/Puerto Rico",
"America/Punta_Arenas":"América/Punta_Arenas",
"America/Rainy_River":"América/Rainy_River",
"America/Rankin_Inlet":"América/Rankin_Inlet",
"America/Recife":"América/Recife",
"America/Regina":"América/Regina",
"America/Resolute":"América/Resuelto",
"America/Rio_Branco":"América/Rio_Branco",
"America/Rosario":"América/Rosario",
"America/Santa_Isabel":"América/Santa_Isabel",
"America/Santarem":"América/Santarem",
"America/Santiago":"América/Santiago",
"America/Santo_Domingo":"América/Santo_Domingo",
"America/Sao_Paulo":"América/Sao_Paulo",
"America/Scoresbysund":"America/Scoresbysund",
"America/Shiprock":"América/Shiprock",
"America/Sitka":"América/Sitka",
"America/St_Barthelemy":"América/San Bartolomé",
"America/St_Johns":"América/St_Johns",
"America/St_Kitts":"América/St_Kitts",
"America/St_Lucia":"América/St_Lucia",
"America/St_Thomas":"América/St_Thomas",
"America/St_Vincent":"América/San Vicente",
"America/Swift_Current":"América/Swift_Current",
"America/Tegucigalpa":"América/Tegucigalpa",
"America/Thule":"América/Thule",
"America/Thunder_Bay":"América/Thunder_Bay",
"America/Tijuana":"América/Tijuana",
"America/Toronto":"América/Toronto",
"America/Tortola":"América/Tórtola",
"America/Vancouver":"América/Vancouver",
"America/Virgin":"América/Virgen",
"America/Whitehorse":"América/Whitehorse",
"America/Winnipeg":"América/Winnipeg",
"America/Yakutat":"América/Yakutat",
"America/Yellowknife":"América/Yellowknife",
"Antarctica/Casey":"Antarctica/Casey",
"Antarctica/Davis":"Antarctica/Davis",
"Antarctica/DumontDUrville":"Antarctica/Dumontdurville",
"Antarctica/Macquarie":"Antarctica/Macquarie",
"Antarctica/Mawson":"Antarctica/Mawson",
"Antarctica/McMurdo":"Antarctica/McMurdo",
"Antarctica/Palmer":"Antarctica/Palmer",
"Antarctica/Rothera":"Antarctica/Rothera",
"Antarctica/South_Pole":"Antarctica/Polo Sur",
"Antarctica/Syowa":"Antarctica/Syowa",
"Antarctica/Troll":"Antarctica/Troll",
"Antarctica/Vostok":"Antarctica/Vostok",
"Arctic/Longyearbyen":"Ártico/Longyearbyen",
"Asia/Aden":"Asia/Adén",
"Asia/Almaty":"Asia/Almaty",
"Asia/Amman":"Asia/Ammán",
"Asia/Anadyr":"Asia/Anadyr",
"Asia/Aqtau":"Asia/Aqtau",
"Asia/Aqtobe":"Asia/Aqtobe",
"Asia/Ashgabat":"Asia/Asjabad",
"Asia/Ashkhabad":"Asia/Ashkhabad",
"Asia/Atyrau":"Asia/Atyrau",
"Asia/Baghdad":"Asia/Bagdad",
"Asia/Bahrain":"Asia/Bahrein",
"Asia/Baku":"Asia/Bakú",
"Asia/Bangkok":"Asia/Bangkok",
"Asia/Barnaul":"Asia/Barnaul",
"Asia/Beirut":"Asia/Beirut",
"Asia/Bishkek":"Asia/Biskek",
"Asia/Brunei":"Asia/Brunéi",
"Asia/Calcutta":"Asia/Calcuta",
"Asia/Chita":"Asia/Chita",
"Asia/Choibalsan":"Asia/Choibalsan",
"Asia/Chongqing":"Asia/Chongqing",
"Asia/Chungking":"Asia/Chungking",
"Asia/Colombo":"Asia/Colombo",
"Asia/Dacca":"Asia/Dacca",
"Asia/Damascus":"Asia/Damasco",
"Asia/Dhaka":"Asia/Dacca",
"Asia/Dili":"Asia/Dili",
"Asia/Dubai":"Asia/Dubai",
"Asia/Dushanbe":"Asia/Dushanbe",
"Asia/Famagusta":"Asia/Famagusta",
"Asia/Gaza":"Asia/Gaza",
"Asia/Harbin":"Asia/Harbin",
"Asia/Hebron":"Asia/Hebrón",
"Asia/Ho_Chi_Minh":"Asia/Ho_Chi_Minh",
"Asia/Hong_Kong":"Asia/Hong Kong",
"Asia/Hovd":"Asia/Hovd",
"Asia/Irkutsk":"Asia/Irkutsk",
"Asia/Istanbul":"Asia/Estambul",
"Asia/Jakarta":"Asia/Yakarta",
"Asia/Jayapura":"Asia/Jayapura",
"Asia/Jerusalem":"Asia/Jerusalén",
"Asia/Kabul":"Asia/Kabul",
"Asia/Kamchatka":"Asia/Kamchatka",
"Asia/Karachi":"Asia/Karachi",
"Asia/Kashgar":"Asia/Kashgar",
"Asia/Kathmandu":"Asia/Katmandú",
"Asia/Katmandu":"Asia/Katmandú",
"Asia/Khandyga":"Asia/Khandyga",
"Asia/Kolkata":"Asia/Calcuta",
"Asia/Krasnoyarsk":"Asia/Krasnoyarsk",
"Asia/Kuala_Lumpur":"Asia/Kuala_Lumpur",
"Asia/Kuching":"Asia/Kuching",
"Asia/Kuwait":"Asia/Kuwait",
"Asia/Macao":"Asia/Macao",
"Asia/Macau":"Asia/Macao",
"Asia/Magadan":"Asia/Magadán",
"Asia/Makassar":"Asia/Macasar",
"Asia/Manila":"Asia/Manila",
"Asia/Muscat":"Asia/Mascate",
"Asia/Nicosia":"Asia/Nicosia",
"Asia/Novokuznetsk":"Asia/Novokuznetsk",
"Asia/Novosibirsk":"Asia/Novosibirsk",
"Asia/Omsk":"Asia/Omsk",
"Asia/Oral":"Asia/Oral",
"Asia/Phnom_Penh":"Asia/Phnom_Penh",
"Asia/Pontianak":"Asia/Pontianak",
"Asia/Pyongyang":"Asia/Pyongyang",
"Asia/Qatar":"Asia/Qatar",
"Asia/Qostanay":"Asia/Qostanay",
"Asia/Qyzylorda":"Asia/Quyzylorda",
"Asia/Rangoon":"Asia/Rangún",
"Asia/Riyadh":"Asia/Riad",
"Asia/Saigon":"Asia/Saigón",
"Asia/Sakhalin":"Asia/Sajalín",
"Asia/Samarkand":"Asia/Samarcanda",
"Asia/Seoul":"Asia/Seúl",
"Asia/Shanghai":"Asia/Shanghai",
"Asia/Singapore":"Asia/Singapur",
"Asia/Srednekolymsk":"Asia/Srednekolymsk",
"Asia/Taipei":"Asia/Taipéi",
"Asia/Tashkent":"Asia/Taskent",
"Asia/Tbilisi":"Asia/Tiflis",
"Asia/Tehran":"Asia/Teherán",
"Asia/Tel_Aviv":"Asia/Tel Aviv",
"Asia/Thimbu":"Asia/Thimbu",
"Asia/Thimphu":"Asia/Thimbu",
"Asia/Tokyo":"Asia/Tokio",
"Asia/Tomsk":"Asia/Tomsk",
"Asia/Ujung_Pandang":"Asia/Ujung_Pandang",
"Asia/Ulaanbaatar":"Asia/Ulán Bator",
"Asia/Ulan_Bator":"Asia/Ulan_Bator",
"Asia/Urumqi":"Asia/Urumqi",
"Asia/Ust-Nera":"Asia/Ust-Nera",
"Asia/Vientiane":"Asia/Vientián",
"Asia/Vladivostok":"Asia/Vladivostok",
"Asia/Yakutsk":"Asia/Yakutsk",
"Asia/Yangon":"Asia/Yangon",
"Asia/Yekaterinburg":"Asia/Ekaterimburgo",
"Asia/Yerevan":"Asia/Ereván",
"Atlantic/Azores":"Atlántico/Azores",
"Atlantic/Bermuda":"Atlántico/Bermudas",
"Atlantic/Canary":"Atlántico/Canario",
"Atlantic/Cape_Verde":"Atlántico/Cabo Verde",
"Atlantic/Faeroe":"Atlántico/Feroe",
"Atlantic/Faroe":"Atlántico/Islas Feroe",
"Atlantic/Jan_Mayen":"Atlántico/Jan_Mayen",
"Atlantic/Madeira":"Atlántico/Madeira",
"Atlantic/Reykjavik":"Atlántic/Reikiavik",
"Atlantic/South_Georgia":"Atlántico/Sur_Georgia",
"Atlantic/St_Helena":"Atlántic/Santa Helena",
"Atlantic/Stanley":"Atlántico/Stanley",
"Australia/ACT":"Australia/Act",
"Australia/Adelaide":"Australia/Adelaida",
"Australia/Brisbane":"Australia/Brisbane",
"Australia/Broken_Hill":"Australia/Broken_Hill",
"Australia/Canberra":"Australia/Camberra",
"Australia/Currie":"Australia/Currie",
"Australia/Darwin":"Australia/Darwin",
"Australia/Eucla":"Australia/Eucla",
"Australia/Hobart":"Australia/Hobart",
"Australia/LHI":"Australia/LHI",
"Australia/Lindeman":"Australia/Lindeman",
"Australia/Lord_Howe":"Australia/Lord_Howe",
"Australia/Melbourne":"Australia/Melbourne",
"Australia/NSW":"Australia/Nueva Gales del Sur",
"Australia/North":"Australia/Norte",
"Australia/Perth":"Australia/Perth",
"Australia/Queensland":"Australia/Queensland",
"Australia/South":"Australia/Sur",
"Australia/Sydney":"Australia/Sydney",
"Australia/Tasmania":"Australia/Tasmania",
"Australia/Victoria":"Australia/Victoria",
"Australia/West":"Australia/Oeste",
"Australia/Yancowinna":"Australia/Yancowinna",
"Brazil/Acre":"Brasil/Acre",
"Brazil/DeNoronha":"Brasil/Denoronha",
"Brazil/East":"Brasil/Este",
"Brazil/West":"Brasil/Oeste",
"CET":"CIENTO",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Canadá/Atlantic",
"Canada/Central":"Canadá/Central",
"Canada/Eastern":"Canadá/Este",
"Canada/Mountain":"Canadá/Montaña",
"Canada/Newfoundland":"Canadá/Terranova",
"Canada/Pacific":"Canadá/Pacífico",
"Canada/Saskatchewan":"Canadá/Saskatchewan",
"Canada/Yukon":"Canadá/Yukon",
"Chile/Continental":"Chile/Continental",
"Chile/EasterIsland":"Chile/Isla de Pascua",
"Cuba":"Cuba",
"EET":"CONOCE",
"EST":"EST",
"EST5EDT":"EST5EDT",
"Egypt":"Egipto",
"Eire":"Eira",
"Etc/GMT":"Etc/GMT",
"Etc/GMT+0":"ETC/GMT+0",
"Etc/GMT+1":"ETC/GMT+1",
"Etc/GMT+10":"ETC/GMT+10",
"Etc/GMT+11":"ETC/GMT+11",
"Etc/GMT+12":"ETC/GMT+12",
"Etc/GMT+2":"ETC/GMT+2",
"Etc/GMT+3":"ETC/GMT+3",
"Etc/GMT+4":"ETC/GMT+4",
"Etc/GMT+5":"ETC/GMT+5",
"Etc/GMT+6":"ETC/GMT+6",
"Etc/GMT+7":"ETC/GMT+7",
"Etc/GMT+8":"ETC/GMT+8",
"Etc/GMT+9":"ETC/GMT+9",
"Etc/GMT-0":"ETC/GMT-0",
"Etc/GMT-1":"ETC/GMT-1",
"Etc/GMT-10":"ETC/GMT-10",
"Etc/GMT-11":"ETC/GMT-11",
"Etc/GMT-12":"ETC/GMT-12",
"Etc/GMT-13":"ETC/GMT-13",
"Etc/GMT-14":"ETC/GMT-14",
"Etc/GMT-2":"ETC/GMT-2",
"Etc/GMT-3":"ETC/GMT-3",
"Etc/GMT-4":"ETC/GMT-4",
"Etc/GMT-5":"ETC/GMT-5",
"Etc/GMT-6":"ETC/GMT-6",
"Etc/GMT-7":"ETC/GMT-7",
"Etc/GMT-8":"ETC/GMT-8",
"Etc/GMT-9":"ETC/GMT-9",
"Etc/GMT0":"ETC/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"ETC/UCT",
"Etc/UTC":"Etc/UTC",
"Etc/Universal":"Etc/Universal",
"Etc/Zulu":"Etc/Zulú",
"Europe/Amsterdam":"Europa/Amsterdam",
"Europe/Andorra":"Europa/Andorra",
"Europe/Astrakhan":"Europa/Astracán",
"Europe/Athens":"Europa/Atenas",
"Europe/Belfast":"Europa/Belfast",
"Europe/Belgrade":"Europa/Belgrado",
"Europe/Berlin":"Europa/Berlín",
"Europe/Bratislava":"Europa/Bratislava",
"Europe/Brussels":"Europa/Bruselas",
"Europe/Bucharest":"Europa/Bucarest",
"Europe/Budapest":"Europa/Budapest",
"Europe/Busingen":"Europa/Busingen",
"Europe/Chisinau":"Europa/Chisinau",
"Europe/Copenhagen":"Europa/Copenhague",
"Europe/Dublin":"Europa/Dublín",
"Europe/Gibraltar":"Europa/Gibraltar",
"Europe/Guernsey":"Europa/Guernsey",
"Europe/Helsinki":"Europa/Helsinki",
"Europe/Isle_of_Man":"Europa/Isle_of_Man",
"Europe/Istanbul":"Europa/Estambul",
"Europe/Jersey":"Europa/Jersey",
"Europe/Kaliningrad":"Europa/Kaliningrado",
"Europe/Kiev":"Europa/Kiev",
"Europe/Kirov":"Europa/Kirov",
"Europe/Kyiv":"Europa/Kyiv",
"Europe/Lisbon":"Europa/Lisboa",
"Europe/Ljubljana":"Europa/Liubliana",
"Europe/London":"Europa/Londres",
"Europe/Luxembourg":"Europa/Luxemburgo",
"Europe/Madrid":"Europa/Madrid",
"Europe/Malta":"Europa/Malta",
"Europe/Mariehamn":"Europa/Mariehamn",
"Europe/Minsk":"Europa/Minsk",
"Europe/Monaco":"Europa/Mónaco",
"Europe/Moscow":"Europa/Moscú",
"Europe/Nicosia":"Europa/Nicosia",
"Europe/Oslo":"Europa/Oslo",
"Europe/Paris":"Europa/París",
"Europe/Podgorica":"Europa/Podgorica",
"Europe/Prague":"Europa/Praga",
"Europe/Riga":"Europa/Riga",
"Europe/Rome":"Europa/Roma",
"Europe/Samara":"Europa/Samara",
"Europe/San_Marino":"Europa/San_Marino",
"Europe/Sarajevo":"Europa/Sarajevo",
"Europe/Saratov":"Europa/Saratov",
"Europe/Simferopol":"Europa/Simferopol",
"Europe/Skopje":"Europa/Skopje",
"Europe/Sofia":"Europa/Sofía",
"Europe/Stockholm":"Europa/Estocolmo",
"Europe/Tallinn":"Europa/Tallin",
"Europe/Tirane":"Europa/Tirana",
"Europe/Tiraspol":"Europa/Tiraspol",
"Europe/Ulyanovsk":"Europa/Ulyanovsk",
"Europe/Uzhgorod":"Europa/Uzhgorod",
"Europe/Vaduz":"Europa/Vaduz",
"Europe/Vatican":"Europa/Vaticano",
"Europe/Vienna":"Europa/Viena",
"Europe/Vilnius":"Europa/Vilnius",
"Europe/Volgograd":"Europa/Volgogrado",
"Europe/Warsaw":"Europa/Varsovia",
"Europe/Zagreb":"Europa/Zagreb",
"Europe/Zaporozhye":"Europa/Zaporozhye",
"Europe/Zurich":"Europa/Zurich",
"GB":"GB",
"GB-Eire":"GB",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HST",
"Hongkong":"Hong Kong",
"Iceland":"Islandia",
"Indian/Antananarivo":"India/Antananarivo",
"Indian/Chagos":"India/Chagos",
"Indian/Christmas":"India/Navidad",
"Indian/Cocos":"India/Cocos",
"Indian/Comoro":"India/Comoro",
"Indian/Kerguelen":"India/Kerguelen",
"Indian/Mahe":"India/Mahe",
"Indian/Maldives":"India/Maldivas",
"Indian/Mauritius":"India/Mauricio",
"Indian/Mayotte":"India/Mayotte",
"Indian/Reunion":"India/Reunión",
"Iran":"Irán",
"Israel":"Israel",
"Jamaica":"Jamaica",
"Japan":"Japón",
"Kwajalein":"Kwajalein",
"Libya":"Libia",
"MET":"CON",
"MST":"MÁS",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"México/BajaNorte",
"Mexico/BajaSur":"México/Bajasur",
"Mexico/General":"México/General",
"NZ":"NZ",
"NZ-CHAT":"NO HAY CHAT",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Pacífico/Apia",
"Pacific/Auckland":"Pacífico/Auckland",
"Pacific/Bougainville":"Pacífico/Bougainville",
"Pacific/Chatham":"Pacífico/Chatham",
"Pacific/Chuuk":"Pacífico/Chuuk",
"Pacific/Easter":"Pacífico/Pascua",
"Pacific/Efate":"Pacífico/Efate",
"Pacific/Enderbury":"Pacífico/Enderbury",
"Pacific/Fakaofo":"Pacífico/Fakaofo",
"Pacific/Fiji":"Pacífico/Fiyi",
"Pacific/Funafuti":"Pacífico/Funafuti",
"Pacific/Galapagos":"Pacífico/Galápagos",
"Pacific/Gambier":"Pacífico/Gambier",
"Pacific/Guadalcanal":"Pacífico/Guadalcanal",
"Pacific/Guam":"Pacífico/Guam",
"Pacific/Honolulu":"Pacífico/Honolulu",
"Pacific/Johnston":"Pacífico/Johnston",
"Pacific/Kanton":"Pacífico/Kanton",
"Pacific/Kiritimati":"Pacífico/Kiritimati",
"Pacific/Kosrae":"Pacífico/Kosrae",
"Pacific/Kwajalein":"Pacífico/Kwajalein",
"Pacific/Majuro":"Pacífico/Majuro",
"Pacific/Marquesas":"Pacífico/Marquesas",
"Pacific/Midway":"Pacífico/Medio camino",
"Pacific/Nauru":"Pacífico/Nauru",
"Pacific/Niue":"Pacífico/Niue",
"Pacific/Norfolk":"Pacífico/Norfolk",
"Pacific/Noumea":"Pacífico/Numea",
"Pacific/Pago_Pago":"Pacífico/Pago_Pago",
"Pacific/Palau":"Pacífico/Palau",
"Pacific/Pitcairn":"Pacífico/Pitcairn",
"Pacific/Pohnpei":"Pacífico/Pohnpei",
"Pacific/Ponape":"Pacífico/Ponape",
"Pacific/Port_Moresby":"Pacífico/Port_Moresby",
"Pacific/Rarotonga":"Pacífico/Rarotonga",
"Pacific/Saipan":"Pacífico/Saipán",
"Pacific/Samoa":"Pacífico/Samoa",
"Pacific/Tahiti":"Pacífico/Tahití",
"Pacific/Tarawa":"Pacífico/Tarawa",
"Pacific/Tongatapu":"Pacífico/Tongatapu",
"Pacific/Truk":"Pacífico/Camión",
"Pacific/Wake":"Pacífico/Wake",
"Pacific/Wallis":"Pacífico/Wallis",
"Pacific/Yap":"Pacífico/Yap",
"Poland":"Polonia",
"Portugal":"Portugal",
"ROC":"ROC",
"ROK":"ROCK",
"Singapore":"Singapur",
"Turkey":"Turquía",
"UCT":"CORTE",
"US/Alaska":"Estados Unidos/Alaska",
"US/Aleutian":"EEUU/Aleutiano",
"US/Arizona":"Estados Unidos/Arizona",
"US/Central":"Estados Unidos/Central",
"US/East-Indiana":"Estados Unidos/Indiana Oriental",
"US/Eastern":"Estados Unidos/Este",
"US/Hawaii":"Estados Unidos/Hawaii",
"US/Indiana-Starke":"Estados Unidos/Indiana-Starke",
"US/Michigan":"Estados Unidos/Michigan",
"US/Mountain":"Estados Unidos/Montaña",
"US/Pacific":"Estados Unidos/Pacífico",
"US/Samoa":"Estados Unidos/Samoa",
"UTC":"EN LA SIGUIENTE",
"Universal":"Universal",
"W-SU":"W-SU",
"WET":"MOJADO",
"Zulu":"Zulú",
}