import { Feather, FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Container, Content, List, ListItem } from 'native-base'
import React, { Component } from 'react'
import { Alert, BackHandler, Dimensions, Platform, StyleSheet, Text, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeLanguageSuccess } from '../actions/changeLanguage'
import { retrieveHealthkitData } from '../actions/healthkit'
import { updateDeviceToken } from '../actions/login'
import { buttonBackgroundColor } from '../containers/NavigationScreens'
import { persistor } from '../store/configStore'
import api from '../utils/api'
import { Authorize } from '../utils/appPrivileges'
import CustomModal from '../utils/customModal'
import { deviceConfig } from '../utils/deviceConfig'
import { putItem, removeCredentials, removeSubjectDeviceToken } from '../utils/secureStorageUtils'
import storage from '../utils/storage/storage'
import WebAlert from '../utils/WebAlert'
import styles from './sideBar/styles'
import constants from '../constants/constants'
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')
import _ from 'lodash'
import { clearStorageAndStoreSyncQueue } from '../actions/syncQueue'
import { isDataExistsToSync } from '../selectors/syncQueue'
import { clearAndStopBackGroundJob } from '../utils/backgroundTask/downloadImageUtil'

class Sidebar extends Component {
  state = {
    isModalVisible: false,
  }

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    title: navigation.state.params ? navigation.state.params.title : t('Actn_sheetMore'),
  })

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', () => false)
  }

  componentDidUpdate(prevProps) {
    const {
      selectedLanguage,
      screenProps: { t },
    } = this.props
    if (selectedLanguage !== prevProps.selectedLanguage) {
      this.props.navigation.setParams({ title: t('Actn_sheetMore') })
    }
  }

  logoutAction = async () => {
    const {
      navigation,
      screenProps: { t, setLocale },
      clientID,
      subject,
      changeLanguageSuccess,
      subjectPhoneNo,
      currentUser,
      clearStorageAndStoreSyncQueue,
      downloadImageTimerId
    } = this.props

    try {
      // api.defaults.headers.common.Authorization = generateBasicAuthToken(subject.phoneNo, subject.password);
      // api.defaults.headers.common['Accept-Language'] = locale;
      const obj = deviceConfig()
      const data = {
        subject: {
          studySite: { client: { id: clientID } },
          phoneNo: currentUser.userId,
          id: currentUser.id,
        },
        mobileInfo: {
          systemVersion: obj.systemVersion,
          os: obj.os,
        },
      }
      await api.post(`${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${currentUser.id}/logout`, data)
      await removeCredentials()
      await removeSubjectDeviceToken()
      await clearStorageAndStoreSyncQueue()  
      await clearAndStopBackGroundJob(downloadImageTimerId)
      changeLanguageSuccess('en_US')
      putItem('en_US', 'locale')
      setLocale('en_US')
      navigation.navigate('Login')
    } catch (error) {
      console.log(error)
    }
  }

  signoutFromDevice = async () => {
    try {
      // api.defaults.headers.common.Authorization = generateBasicAuthToken(subject.phoneNo, subject.password);
      // api.defaults.headers.common['Accept-Language'] = locale;
      const {
        navigation,
        screenProps: { t, setLocale },
        clientID,
        subject,
        updateDeviceToken: updateSubjectDeviceToken,
        currentUser,
        clearStorageAndStoreSyncQueue,
        downloadImageTimerId
      } = this.props

      const obj = deviceConfig()
      const data = {
        subject: {
          studySite: { client: { id: clientID } },
          phoneNo: currentUser.phoneNo,
          id: currentUser.id,
        },
        mobileInfo: {
          systemVersion: obj.systemVersion,
          os: obj.os,
        },
      }
      // await updateSubjectDeviceToken({subjectId: subject.id, deviceToken: null});
      await api.post(`${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${currentUser.id}/logout`, data)
      this.hideModal()
      await removeCredentials()
      await removeSubjectDeviceToken()
      await clearStorageAndStoreSyncQueue()
      await clearAndStopBackGroundJob(downloadImageTimerId)
      changeLanguageSuccess('en_US')
      setLocale('en_US')
      putItem('en_US', 'locale')
      navigation.navigate('Login')
    } catch (error) {
      console.log(error)
    }
  }
  signOut = () => {
    const {
      navigation,
      screenProps: { t, setLocale },
      clientID,
      subject,
      updateDeviceToken: updateSubjectDeviceToken,
      currentUser,
    } = this.props
    this.setState({
      isModalVisible: true,
    })
    // if (Platform.OS != 'web') {
    //   this.setState({
    //     isModalVisible: true,
    //   })
    // Alert.alert(
    //   '',
    //   t('LogoutMessage'),
    //   [
    //     {
    //       text: t('NO'),
    //       onPress: () => true,
    //       style: 'cancel',

    //     },
    //     {
    //       text: t('YES'),
    //       onPress: async () => {
    //         try {
    //           // api.defaults.headers.common.Authorization = generateBasicAuthToken(subject.phoneNo, subject.password);
    //           // api.defaults.headers.common['Accept-Language'] = locale;
    //           const obj = deviceConfig()
    //           const data = {
    //             subject: {
    //               studySite: { client: { id: clientID } },
    //               phoneNo: currentUser.phoneNo,
    //               id: currentUser.id,
    //             },
    //             mobileInfo: {
    //               systemVersion: obj.systemVersion,
    //               os: obj.os,
    //             },
    //           }
    //           // await updateSubjectDeviceToken({subjectId: subject.id, deviceToken: null});
    //           await api.post('/subject/logout/app', data)
    //           await removeCredentials()
    //           await removeSubjectDeviceToken()
    //           changeLanguageSuccess('en_US')
    //           setLocale('en_US')
    //           putItem('en_US', 'locale')
    //           navigation.navigate('Login')
    //         } catch (error) {
    //           console.log(error)
    //         }
    //       },
    //     },
    //   ],
    //   { cancelable: false }
    // )
    // } else {
    //   this.setState({
    //     isModalVisible: true,
    //   })
    // }
  }

  hideModal = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  render() {
    const {
      clientID,
      close,
      screenProps,
      closeDrawer,
      history,
      changeLoading,
      isDeviceOnline,
      navigation,
      screenProps: { t },
      retrieveHealthkitData,
      subject,
      isOfflineDataExists
    } = this.props
    const { isModalVisible } = this.state
    return (
      <Container>
        {/* <NavigationEvents
            onWillFocus={() => {
              const currentDate = new Date();
              retrieveHealthkitData(subject.id, currentDate);
              }}
          /> */}
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          {/* <List style={{alignItems:'center',alignContent:'center',alignSelf:'center'}}> */}
          <List>
            <Authorize privilege="VIEW_HEALTH_DATA">
              <ListItem
                style={{ height: 60 }}
                icon
                onPress={() => {
                  setTimeout(() => {
                    navigation.navigate('Health')
                  }, 0)
                }}
              >
                <View style={styles.listItem}>
                  <View style={styles.iconContainer}>
                    <FontAwesome name="heart" size={24} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.text}>{t('HealthMenu')}</Text>
                  </View>
                </View>
              </ListItem>
            </Authorize>
            {/* User Data */}

            {/* Messages */}
            <Authorize privilege="VIEW_NOTIFICATIONS">
              {isDeviceOnline == true && (
                <ListItem
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('Messages')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <MaterialCommunityIcons name="bell" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('Notifications')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>
            {/* <ListItem button style={{ height: 60 }} icon onPress={() => { setTimeout(() => { navigation.navigate('EventCalender') }, 0) }}>
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialCommunityIcons name="calendar-check" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('HomeTabCalndr')}</Text>
                </View>
              </View>
            </ListItemphoneNoyle={styles.listItem}>
            </ListItem> */}

            {/* Change Language */}

            {/* <ListItem style={{ height: 60 }} icon icon onPress={() => { setTimeout(() => { navigation.navigate('ChangeLanguage') }, 0) }}>
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="translate" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('Actn_sheetChange_Language')}</Text>
                </View>
              </View>
            </ListItem> */}

            {/* Change Theme */}

            {/* <ListItem button style={{ height: 60 }} icon onPress={() => { setTimeout(() => { navigation.navigate('ChangeTheme') }, 0) }}>
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="color-lens" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('ChangeTheme')}</Text>
                </View>
              </View>
            </ListItem> */}
            <Authorize privilege="SETUP_APPLOCK">
              {Platform.OS !== 'web' && (
                <ListItem
                  button
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('PinChange')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <MaterialIcons name="lock" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('AppLock')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>

            {/* Change TimeZone */}
            <Authorize privilege="VIEW_TIMEZONE">
              {isDeviceOnline == true && (
                <ListItem
                  button
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('SubjectTimeZone')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <Feather name="globe" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('Timezone')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>

            {/* Contact */}

            <ListItem
              style={{ height: 60 }}
              icon
              onPress={() => {
                setTimeout(() => {
                  navigation.navigate('Contact')
                }, 0)
              }}
            >
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="perm-contact-calendar" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('Actn_sheetContact')}</Text>
                </View>
              </View>
            </ListItem>

            {/* FAQ */}
            <Authorize privilege="VIEW_FAQ">
              {isDeviceOnline == true && (
                <ListItem
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('FAQ')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <FontAwesome name="question" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('Actn_sheetFAQ')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>
            {Platform.OS !== 'web' && (
              <ListItem
                style={{ height: 60 }}
                icon
                onPress={() => {
                  setTimeout(() => {
                    navigation.navigate('VersionInfo')
                  }, 0)
                }}
              >
                <View style={styles.listItem}>
                  <View style={styles.iconContainer}>
                    <FontAwesome name="info" size={24} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.text}>{`Version Info`}</Text>
                  </View>
                </View>
              </ListItem>
            )}
            {/* Logout */}

            <ListItem
              style={{ height: 60 }}
              icon
              onPress={() => {
                setTimeout(() => {
                  navigation.navigate('SubjectDetails')
                }, 0)
              }}
            >
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="person" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('myProfile')}</Text>
                </View>
              </View>
            </ListItem>

            {isDeviceOnline == true && (
              <ListItem
                button
                style={{ height: 60 }}
                icon
                onPress={() => {
                  setTimeout(() => {
                    this.signOut()
                  }, 0)
                }}
              >
                <View style={styles.listItem}>
                  <View style={styles.iconContainer}>
                    <MaterialCommunityIcons name="logout" size={24} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.text}>{t('Logout')}</Text>
                  </View>
                </View>
              </ListItem>
            )}
          </List>
          {isModalVisible && (
            <CustomModal
              title={''}
              onCancel={this.hideModal}
              // t={screenProps.t}
              onConfirm={isOfflineDataExists? this.hideModal :Platform.OS != 'web' ? this.signoutFromDevice : this.logoutAction}
              message={isOfflineDataExists? t('UnsyncedData') : t('LogoutMessage')}
              confirmText={isOfflineDataExists? t('OK') :t('YES')}
              cancelText={t('NO')}
              showCancelButton={!isOfflineDataExists}
              confirmButtonColor={buttonBackgroundColor}
            />
          )}
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  subject: state.subjectStudyMetaData.subject,
  clientID: state.subjectStudyMetaData.studySite.client.id,
  selectedLanguage: state.changeLanguage.selectedLanguage,
  isDeviceOnline: state.appStatus.isDeviceOnline,
  currentUser: state.users.currentUser,
  isOfflineDataExists: isDataExistsToSync(state),
  downloadImageTimerId: state?.appStatus?.timers?.downloadImageTimerId
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveHealthkitData,
      changeLanguageSuccess,
      updateDeviceToken,
      clearStorageAndStoreSyncQueue,
    },
    dispatch
  )

const linkStyles = StyleSheet.create({
  sidebarText: {
    // textAlign: 'center',
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
