import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { retrieveSubjects, updateSelectedSubject, updateSubjectSearchTerm } from '../actions/subject'
import { updateSelectedFormType } from '../actions/users'
import { retrieveSubjectsOfCaregiver, retrieveSubjectById } from '../actions/caregiver'
import SubjectList from '../components/subject/list'
import { getSubjectsOfStudy } from '../selectors/subject'
import constants from '../constants/constants'
import { NavigationEvents } from 'react-navigation'
import moment from 'moment-timezone'

class SubjectListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.getSubjects()
  }

  getSubjects = async () => {
    const {
      retrieveSubjects: retrieveSubjectsOfSite,
      siteId,
      currentUser,
      retrieveSubjectsOfCaregiver,
    } = this.props

    try {
      if (currentUser && currentUser.role === 'CAREGIVER') {
        await retrieveSubjectsOfCaregiver(currentUser.id)
      } else {
        await retrieveSubjectsOfSite(siteId)
      }
    } catch (error) {
      console.log(error)
    }
  }

  clearSubject = async () => {
    const { updateSelectedSubject: updateSelectedSubjectInStore, updateSubjectSearchTerm} = this.props
    updateSubjectSearchTerm('')
    updateSelectedSubjectInStore(null)
  }

  onSubjectSelect = async (subject) => {
    const {
      updateSelectedSubject: updateSelectedSubjectInStore,
      navigation,
      retrieveSubjectById,
      currentUser,
    } = this.props
    const { formCategory } = constants
    if (currentUser && currentUser.role === 'CAREGIVER') {
      await retrieveSubjectById(subject.id)
      navigation.navigate('Visits')
    } else {
      updateSelectedSubjectInStore({ ...subject, lastUpdatedDateOn: null })
      updateSelectedFormType(formCategory.RATER_ASSESSMENT)
      moment.tz.setDefault(subject?.timeZone)
      navigation.navigate('ClinicianDiary', { screen: 'Visits' })
    }
  }

  render() {
    const { list, loading, appType } = this.props
    return (
      <View style={{ flex: 1 }}>
        <NavigationEvents
          onDidFocus={() => {
            appType === constants.AppType.SITESTAFF && this.clearSubject()
          }}
        />
        <SubjectList list={list} onSubjectSelect={this.onSubjectSelect} loading={loading} />
      </View>
    )
  }
}

SubjectListPage.defaultProps = {
  list: [],
  siteId: '',
  caregiverMetaData: [],
  updateSubjectSearchTerm: () => null,
}

SubjectListPage.propTypes = {
  list: PropTypes.arrayOf(Object),
  siteId: PropTypes.string,
  caregiverMetaData: PropTypes.arrayOf(Object),
  updateSubjectSearchTerm: PropTypes.func,
}
const mapStateToProps = (state) => {
  return {
    siteId: state.site.id,
    list: getSubjectsOfStudy(state),
    loading: state.subject.loading,
    caregiverMetaData: state.caregiverMetaData,
    currentUser: state.users.currentUser,
    appType: state.appStatus.appType,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveSubjects,
      updateSelectedSubject,
      retrieveSubjectsOfCaregiver,
      retrieveSubjectById,
      updateSelectedFormType,
      updateSubjectSearchTerm
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SubjectListPage)
