import {
  STUDIES_RETRIEVE_SUCCESS,
  STUDIES_RETRIEVE_REQUEST,
  STUDIES_RETRIEVE_FAILURE,
  UPDATE_STUDY_SEARCH_TERM,
  UPDATE_SELECTED_STUDY,
} from '../actions/study'
import { CLINRO_LOGOUT_SUCCESS } from '../actions/users'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

export default (state = initialState.study, action) => {
  switch (action.type) {
    case STUDIES_RETRIEVE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.studies,
          [action.userId]: [...action.studies],
        },
        loading: false,
      }
    case STUDIES_RETRIEVE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case STUDIES_RETRIEVE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_STUDY_SEARCH_TERM:
      return {
        ...state,
        searchTerm: {
          ...state.searchTerm,
          [action.userId]: action.searchTerm,
        },
      }
    case UPDATE_SELECTED_STUDY:
      return {
        ...state,
        selectedStudyId: action.studyId,
      }
    case CLINRO_LOGOUT_SUCCESS:
      return {
        ...state,
        selectedStudyId: null,
      }
    case PURGE:
      return {
        data: {},
        loading: false,
        selectedStudyId: '',
      }
    default:
      return state
  }
}
