import { Platform, StyleSheet } from 'react-native'
import { buttonBackgroundColor } from './containers/NavigationScreens'

const customStyles = StyleSheet.create({
  container: {
    ...Platform.select({
      android: {
        // marginTop: StatusBar.currentHeight
      },
    }),
  },
  spinnerTextStyle: {
    color: 'white',
  },
  fontStyle: {
    fontFamily: 'Inter',
  },
  // Bold & 24px
  orgHeaderStyle: {
    fontWeight: 'bold',
    fontSize: 24,
    fontFamily: 'Inter',
  },
  pageHeaderStyle: {
    fontWeight: 'bold',
    fontSize: 22,
    fontFamily: 'Inter',
  },
  menuLabelStyle: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Inter',
  },
  columnLabelStyle: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Inter',
  },
  dataLabelStyle: {
    fontSize: 16,
    fontFamily: 'Inter',
  },
  headerBarStyle: {
    fontSize: 14,
    fontFamily: 'Inter',
  },
  headerBarUserNameStyle: {
    fontSize: 14,
    fontFamily: 'Inter',
  },
  headerBarLanguageLabelStyle: {
    fontSize: 15,
    fontFamily: 'Inter',
  },
  footerBarLabel: {
    fontSize: 14,
    fontFamily: 'Inter',
  },
  passcodeSubmitButton: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 20,
    borderRadius: 5,
    backgroundColor: '#9155FD',
    width: '30%',
    marginTop: 30,
  },
  activityIndicatorView: {
    flex: 1,
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'center',
  },
})

export default customStyles
