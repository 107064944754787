import React, { Component } from 'react'
import { View } from 'react-native'
import { cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setCurrentScreen } from '../actions/storeAppStatus'
import { updateSubjectTimezone } from '../actions/timeZone'
import { updateIsAcknowledged } from '../actions/storeAppStatus'
import ChangeSubjectTimezone from '../components/ChangeSubjectTimezone'
import { updateIsSubjectRegistered } from '../actions/subjectStudyMetaData'
import moment from 'moment-timezone'
import _ from 'lodash'

class SubjectTimeZone extends Component {
  static navigationOptions = ({ screenProps: { t } }) => ({
    title: t('SelectTimezone'),
  })

  constructor(props) {
    super(props)
  }

  submitTimezone = async  (timezone) => {
    const { subjectId, updateSubjectTimezone, subjectStudyMetaData, updateIsAcknowledged,screenProps:{t,e} } = this.props
    const subject = {
      id: subjectId,
      timeZone: timezone,
      lastUpdatedDateOn: subjectStudyMetaData?.subject?.lastUpdatedDateOn
    }
    const mutatedSubjectStudyMetaData = cloneDeep(subjectStudyMetaData)
    mutatedSubjectStudyMetaData.subject = {
      ...subjectStudyMetaData.subject,
      timeZone: timezone,
    }
    await updateSubjectTimezone(subject, mutatedSubjectStudyMetaData,t)
    await updateIsAcknowledged(false)
  }

  render() {
    const { loading, navigation, screenProps, timezoneList, subjectStudyMetaData,updateIsSubjectRegistered } = this.props
    const fromLogin = navigation.getParam('fromLogin')
    const timezonesFromMoment = moment.tz.names();
    const deprecatedTimezones = ['Asia/Calcutta', 'Asia/Macau', 'Africa/Asmera', 'Asia/Dacca', 'Asia/Katmandu',
    'Asia/Thimbu','Atlantic/Faeroe','Europe/Kiev','Pacific/Ponape', 'Asia/Ashkhabad', 'Asia/Ulan_Bator', 'Asia/Chungking'];
    const allTimeZones = timezonesFromMoment.filter(element =>!deprecatedTimezones.includes(element));
    return (
      <View style={{ flex: 1, marginTop: 0, backgroundColor: '#fff' }}>
        <ChangeSubjectTimezone
          fromLogin={fromLogin}
          loading={loading}
          navigation={navigation}
          screenProps={screenProps}
          timezoneList={allTimeZones}
          subjectStudyMetaData={subjectStudyMetaData}
          timezone={subjectStudyMetaData?.subject?.timeZone}
          submitTimezone={this.submitTimezone}
          updateIsSubjectRegistered={updateIsSubjectRegistered}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  subjectId: state.subjectStudyMetaData.subject.id,
  subjectStudyMetaData: state.subjectStudyMetaData,
  timezoneList: state.timeZone.list,
  appStatus: state.appStatus,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSubjectTimezone,
      setCurrentScreen,
      updateIsAcknowledged,
      updateIsSubjectRegistered,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SubjectTimeZone)
