import _ from 'lodash'
import createActionType from '../utils/action'
import api from '../utils/api'
import Constants from 'expo-constants'
import appConstants from '../constants/constants'
import constants from '../constants/constants'
import { store } from '../store/configStore'

export const SVFS_RETRIEVE_REQUEST = createActionType('SVFS_RETRIEVE_REQUEST')
export const SVFS_RETRIEVE_SUCCESS = createActionType('SVFS_RETRIEVE_SUCCESS')
export const SVFS_RETRIEVE_FAILURE = createActionType('SVFS_RETRIEVE_FAILURE')

export const SVFSLIST_RETRIEVE_REQUEST = createActionType('SVFSLIST_RETRIEVE_REQUEST')
export const SVFSLIST_RETRIEVE_SUCCESS = createActionType('SVFSLIST_RETRIEVE_SUCCESS')
export const SVFSLIST_RETRIEVE_FAILURE = createActionType('SVFSLIST_RETRIEVE_FAILURE')

export const SVF_RETRIEVE_REQUEST = createActionType('SVF_RETRIEVE_REQUEST')
export const SVF_RETRIEVE_SUCCESS = createActionType('SVF_RETRIEVE_SUCCESS')
export const SVF_RETRIEVE_FAILURE = createActionType('SVF_RETRIEVE_FAILURE')

export const STORE_SELECTED_SVF = createActionType('STORE_SELECTED_SVF')
export const UPDATE_TRAINING_FORMS = createActionType('UPDATE_TRAINING_FORMS')
export const STORE_SELECTED_SVF_UPDATE = createActionType('STORE_SELECTED_SVF_UPDATE')

const retrieveSvfsByScheduledDateRequest = () => ({
  type: SVFS_RETRIEVE_REQUEST,
})

const retrieveSvfsByScheduledDateFailure = (message) => ({
  type: SVFS_RETRIEVE_FAILURE,
  message,
})

const retrieveSvfsByScheduledDateSuccess = (res) => ({
  type: SVFS_RETRIEVE_SUCCESS,
  svfs: _.keyBy(res.data, (svf) => svf.id) || [],
})

export const updateTrainingForms = (data) => ({
  type: UPDATE_TRAINING_FORMS,
  trainingForms: data,
})

export const retrieveSvfsByScheduledDate =
  (subject, scheduleDate, locale, t, subjectVisit) => async (dispatch, getState) => {
    const {
      users: {
        currentUser: { selectedFormType },
      },
    } = getState()
    const applicationType = store.getState().appStatus.appType
    const formType =
      applicationType === appConstants.AppType.SITESTAFF ? selectedFormType : 'SUBJECT_DIARY'
    dispatch(retrieveSvfsByScheduledDateRequest())
    try {
      api.defaults.headers.common['Accept-Language'] = locale
      const res = await api.get(
        `/subjectVisitForms/${formType}/${subjectVisit}/${scheduleDate}?subjectId=${subject.id}`
      )
      dispatch(retrieveSvfsByScheduledDateSuccess(res))
    } catch (error) {
      const message = t('FailedRetrieveForms')
      dispatch(retrieveSvfsByScheduledDateFailure(message))
      console.log(error)
    }
  }

const retrieveSvfsForASubjectRequest = () => ({
  type: SVFS_RETRIEVE_REQUEST,
})

const retrieveSvfsForASubjectFailure = (error) => ({
  type: SVFS_RETRIEVE_FAILURE,
  error,
})

const retrieveSvfsForASubjectSuccess = (res) => ({
  type: SVFS_RETRIEVE_SUCCESS,
  svfs: _.keyBy(res.data, (svf) => svf.id) || [],
})

const retrieveAllSvfsForASubjectRequest = () => ({
  type: SVFSLIST_RETRIEVE_REQUEST,
})

const retrieveAllSvfsForASubjectFailure = (error) => ({
  type: SVFSLIST_RETRIEVE_FAILURE,
  error,
})

const retrieveAllSvfsForASubjectSuccess = (res) => ({
  type: SVFSLIST_RETRIEVE_SUCCESS,
  svfs: _.keyBy(res.data, (svf) => svf.id) || [],
})

const retrieveSingleSvfRequest = () => ({
  type: SVF_RETRIEVE_REQUEST,
})

const retrieveSingleSvfFailure = (error) => ({
  type: SVF_RETRIEVE_FAILURE,
  error,
})

const retrieveSingleSvfSuccess = (res) => ({
  type: SVF_RETRIEVE_SUCCESS,
  svf: res.data,
})

const _storeSelectedSvf = (svf) => ({
  type: STORE_SELECTED_SVF,
  svf,
})

export const storeSelectedSvf = (svf) => (dispatch) => {
  dispatch(_storeSelectedSvf(svf))
}

export const retrieveTrainingSvfs = (subject, locale, t) => async (dispatch) => {
  dispatch(retrieveSvfsByScheduledDateRequest())
  try {
    // api.defaults.headers.common.Authorization = generateBasicAuthToken(subject.phoneNo, subject.password);
    api.defaults.headers.common['Accept-Language'] = locale
    const res = await api.get(
      `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subject.id}/trainingForms`
    )
    // alert('retrieveSvfsByScheduledDate success');
    dispatch(retrieveSvfsByScheduledDateSuccess(res))
    dispatch(
      updateTrainingForms(
        _.map(
          _.filter(res.data, (f) => f.training && !f.filled),
          (svf) => svf.id
        )
      )
    )
  } catch (error) {
    const message = t('FailedRetrieveForms')
    dispatch(retrieveSvfsByScheduledDateFailure(message))
    console.log(error)
  }
}

export const updateSelectedSvf = (isSvfUpdated) => ({
  type: STORE_SELECTED_SVF_UPDATE,
  isSvfUpdated,
})
