import _ from 'lodash'
import { createSelector } from 'reselect'
import { Platform } from 'react-native'

export const getCurrentUserId = (state) => _.get(state.users.currentUser, 'id', null)
export const getUsers = (state) => state.users

export const getCurrentUser = createSelector([getCurrentUserId, getUsers], (userId, users) => {
  const userMaybe = users[userId]
  return _.isEmpty(userMaybe) ? users.currentUser : { ...userMaybe }
})

const getMobileDeviceStatus = (state) => state.appStatus.isDeviceOnline
const getWebDeviceStatus = () => navigator.onLine

export const getDeviceOnlieStatus = createSelector([getMobileDeviceStatus, getWebDeviceStatus], (mobileStatus, webStatus) => {
  return Platform.OS === 'web' ? webStatus : mobileStatus
})

export const getFullName = createSelector([getCurrentUserId, getUsers], (userId, users) => {
  const userMaybe = users[userId];
  const names = [
    userMaybe?.userData?.data?.firstName,
    userMaybe?.userData?.data?.middleName,
    userMaybe?.userData?.data?.lastName,
  ].filter(Boolean).map((n) => n.trim())

  const fullName = names.length ? names.join(' ') : ''
  return fullName
})

export const getRoleDetails = createSelector([getCurrentUserId, getUsers], (userId, users) => {
  const userMaybe = users[userId];
  const rolesMaybe = userMaybe?.userData?.data?.userRoles
  const roleNames = Array.isArray(rolesMaybe)
    ? rolesMaybe.map((role) => role.roleName).filter(Boolean).map((n) => n.trim()).join(',')
    : ''
  return roleNames
})
