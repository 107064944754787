import {
  LOGIN_RETRIEVE_SUCCESS,
  LOGIN_RETRIEVE_REQUEST,
  LOGIN_RETRIEVE_FAILURE,
  STORE_SUBJECT_SELECTED_LANGUAGE,
} from '../actions/login'
import {
  SUBJECTS_RETRIEVE_SUCCESS,
  SUBJECTS_RETRIEVE_REQUEST,
  SUBJECTS_RETRIEVE_FAILURE,
  UPDATE_SUBJECT_SEARCH_TERM,
  UPDATE_SELECTED_SUBJECT,
} from '../actions/subject'
import { CLINRO_LOGOUT_SUCCESS } from '../actions/users'
import { UPDATE_TIMEZONE_SUCCESS } from '../actions/timeZone'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

export default (state = initialState.subject, action) => {
  switch (action.type) {
    case LOGIN_RETRIEVE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_RETRIEVE_SUCCESS:
      return {
        ...state,
        ...action.subject,
        loading: false,
      }
    case LOGIN_RETRIEVE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case STORE_SUBJECT_SELECTED_LANGUAGE:
      return {
        ...state,
        locale: action.subject.selectedLanguage,
        loading: false,
      }
    case SUBJECTS_RETRIEVE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.subjects,
          [action.userId]: [...action.subjects],
        },
        loading: false,
      }
    case SUBJECTS_RETRIEVE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SUBJECTS_RETRIEVE_FAILURE:
      return {
        ...state,
        data: {
          ...state.subjects,
          [action.userId]: [],
        },
        loading: false,
      }
    case UPDATE_SUBJECT_SEARCH_TERM:
      return {
        ...state,
        searchTermSubject: {
          ...state.searchTermSubject,
          [action.userId]: action.searchTermSubject,
        },
      }
    case UPDATE_SELECTED_SUBJECT:
      return {
        ...state,
        selectedSubjectId: action.subjectId,
      }
    case CLINRO_LOGOUT_SUCCESS:
      return {
        ...state,
        selectedSubjectId: null,
      }
    case UPDATE_TIMEZONE_SUCCESS:
      return {
        ...state,
        timeZone: action?.timezoneData?.timeZone,
      }
    case PURGE:
      return {
        rsaPublicKey: '',
        data: {},
        loading: false,
        selectedSubjectId: '',
      }
    default:
      return state
  }
}
