import _ from 'lodash'
import moment from 'moment'
import { DATE, DATETIME12 } from '../utils/dateUtils'
import fieldTypes from './fieldTypes'

export const frFieldTypes = {
  num: 'num',
  singleslct: 'singleslct',
  multislct: 'multislct',
  date: 'date',
  datetime: 'date',
  datetime12: 'date',
  datetime24: 'date',
  yesno: 'singleslct',
  yesnona: 'singleslct',
  ps: 'num',
  nrs: 'nrs',
  vas: 'vas',
  clkimg: 'multislct',
}

export const validateFieldRule = {
  singleslct: {
    eqls: (field, fieldRule) =>
      field?.crfData?.optionOid && field?.crfData?.optionOid === JSON.parse(fieldRule.value1)[0],
    noteqls: (field, fieldRule) =>
      field?.crfData?.optionOid && field?.crfData?.optionOid !== JSON.parse(fieldRule.value1)[0],
    in: (field, fieldRule) =>
      field?.crfData?.optionOid &&
      !!_.includes(JSON.parse(fieldRule.value1), field?.crfData?.optionOid),
    notin: (field, fieldRule) =>
      field?.crfData?.optionOid &&
      !_.includes(JSON.parse(fieldRule.value1), field?.crfData?.optionOid),
    notSelected: (field, fieldRule) => _.isEmpty(field?.crfData?.optionOid),
  },
  multislct: {
    eqls: (field, fieldRule) =>
      field?.crfData?.optionOid &&
      eqlsMultiSelect(
        JSON.parse(fieldRule.value1),
        field?.crfData?.optionOid ? JSON.parse(field.crfData.optionOid) : []
      ),
    noteqls: (field, fieldRule) =>
      field?.crfData?.optionOid &&
      notEqlsMultiSelect(
        JSON.parse(fieldRule.value1),
        field?.crfData?.optionOid ? JSON.parse(field.crfData.optionOid) : []
      ),
    in: (field, fieldRule) =>
      field?.crfData?.optionOid &&
      inMultiSelect(
        JSON.parse(fieldRule.value1),
        field?.crfData?.optionOid ? JSON.parse(field.crfData.optionOid) : []
      ),
    notin: (field, fieldRule) =>
      field?.crfData?.optionOid &&
      notInMultiSelect(
        JSON.parse(fieldRule.value1),
        field?.crfData?.optionOid ? JSON.parse(field.crfData.optionOid) : []
      ),
    notSelected: (field, fieldRule) => _.isEmpty(field?.crfData?.optionOid),
  },
  date: {
    eqls: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isSame(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType))),
    noteqls: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      !moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isSame(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType))),
    range: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isSameOrBefore(moment(getFieldRuleComparisonDateValue(fieldRule.value2, field?.fieldType))) &&
      moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isSameOrAfter(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType))),
    lsr: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isBefore(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType))),
    gtr: (field, fieldRule) =>{
      if(field?.crfData?.fieldValue) {
        return moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isAfter(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType)))
      }
    },
      
    lsreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isSameOrBefore(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType))),
    gtreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      moment(getFieldRuleComparisonDateValue(field?.crfData?.fieldValue, field.fieldType)).isSameOrAfter(moment(getFieldRuleComparisonDateValue(JSON.parse(fieldRule.value1)[0], field?.fieldType))),
    notSelected: (field, fieldRule) => _.isEmpty(field?.crfData?.fieldValue),
  },
  num: {
    eqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue === JSON.parse(fieldRule.value1)[0],
    noteqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue !== JSON.parse(fieldRule.value1)[0],
    range: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      !!(
        field?.crfData?.fieldValue <= fieldRule.value2 &&
        field?.crfData?.fieldValue >= JSON.parse(fieldRule.value1)[0]
      ),
    lsr: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue < JSON.parse(fieldRule.value1)[0],
    gtr: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue > JSON.parse(fieldRule.value1)[0],
    lsreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue <= JSON.parse(fieldRule.value1)[0],
    gtreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue >= JSON.parse(fieldRule.value1)[0],
    notSelected: (field, fieldRule) => field?.crfData?.fieldValue == null,
  },
  vas: {
    eqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue === JSON.parse(fieldRule.value1)[0],
    noteqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue !== JSON.parse(fieldRule.value1)[0],
    range: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      !!(
        field?.crfData?.fieldValue <= JSON.parse(fieldRule.value2) &&
        field?.crfData?.fieldValue >= JSON.parse(fieldRule.value1)[0]
      ),
    lsr: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue < JSON.parse(fieldRule.value1)[0],
    gtr: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue > JSON.parse(fieldRule.value1)[0],
    lsreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue <= JSON.parse(fieldRule.value1)[0],
    gtreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue >= JSON.parse(fieldRule.value1)[0],
    notSelected: (field, fieldRule) => field?.crfData?.fieldValue == null,
  },
  nrs: {
    eqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue === JSON.parse(fieldRule.value1)[0],
    noteqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue !== JSON.parse(fieldRule.value1)[0],
    range: (field, fieldRule) =>
      field?.crfData?.fieldValue &&
      !!(
        field?.crfData?.fieldValue <= fieldRule.value2 && //JSON.parse(fieldRule.value2) &&
        field?.crfData?.fieldValue >= JSON.parse(fieldRule.value1)[0]
      ),
    lsr: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue < JSON.parse(fieldRule.value1)[0],
    gtr: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue > JSON.parse(fieldRule.value1)[0],
    lsreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue <= JSON.parse(fieldRule.value1)[0],
    gtreqls: (field, fieldRule) =>
      field?.crfData?.fieldValue && field?.crfData?.fieldValue >= JSON.parse(fieldRule.value1)[0],
    notSelected: (field, fieldRule) => field?.crfData?.fieldValue == null,
  },
}

const eqlsMultiSelect = (fieldRuleValues, enteredValues) => {
  if (enteredValues.length === 1) {
    return enteredValues[0] === fieldRuleValues[0]
  }
  const sfieldRule = _.find(enteredValues, (ev) => ev === fieldRuleValues[0])
  return !_.isEmpty(sfieldRule)
}

const notEqlsMultiSelect = (fieldRuleValues, enteredValues) => {
  if (enteredValues.length === 1) {
    return enteredValues[0] !== fieldRuleValues[0]
  }
  const sfieldRule = _.find(enteredValues, (ev) => ev === fieldRuleValues[0])
  return _.isEmpty(sfieldRule)
}

const inMultiSelect = (fieldRuleValue, enteredValue) =>
  !!enteredValue.every((value) => fieldRuleValue.includes(value))
const notInMultiSelect = (fieldRuleValue, enteredValue) =>
  !enteredValue.some((value) => fieldRuleValue.includes(value))

const getFieldRuleComparisonDateValue = (dateValue, fieldType) => {
  if(fieldTypes.DATE === fieldType){
    return moment(dateValue, DATE)
  } if(fieldTypes.DATETIME12 === fieldType){
    return moment(dateValue, DATETIME12)
  } if(fieldTypes.DATETIME24 === fieldType){
    return moment(dateValue, DATETIME12)
  }
}