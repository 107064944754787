import _ from 'lodash'
import { getUniqueId } from 'react-native-device-info'
import uuid from 'react-uuid'
import createActionType from '../utils/action'
import api from '../utils/api'
import formPrintApi from '../utils/formPrintApi'
import { disableForm, changeToDefaultNextFieldOrdinal } from '../utils/fieldutils/field'
import { placeFieldValue } from '../utils/fieldutils/fieldDecorator'
import { fetchNextFieldByFieldRule } from '../utils/fieldutils/fieldRule'
import { getDraftData, getItem, storeDraftData } from '../utils/secureStorageUtils'
import { retrieveFieldsSuccess, updateFieldList } from './field'
import { saveFieldListOfMultipleForms } from './field'
import { UPDATE_IMAGE_CODES, getFormIds, updateSelectedVisitFormOidAndSelectedSvfId } from './metaData'
import fieldTypes from '../constants/fieldTypes'
import constants from '../constants/constants'
const { ContextProperties: {PRIMARY_ORG_CODE, STUDY_ID, SITE_ID} } = constants;

export const FORM_FIELD_LIST_BY_FORM_ID_REQUEST = createActionType(
  'FORM_FIELD_LIST_BY_FORM_ID_REQUEST'
)
export const FORM_FIELD_LIST_BY_FORM_ID_SUCCESS = createActionType(
  'FORM_FIELD_LIST_BY_FORM_ID_SUCCESS'
)
export const FORM_FIELD_LIST_BY_FORM_ID_FAILURE = createActionType(
  'FORM_FIELD_LIST_BY_FORM_ID_FAILURE'
)

export const FORM_SVF_DATA_BY_FORM_ID_REQUEST = createActionType('FORM_SVF_DATA_BY_FORM_ID_REQUEST')
export const FORM_SVF_DATA_BY_FORM_ID_SUCCESS = createActionType('FORM_SVF_DATA_BY_FORM_ID_SUCCESS')
export const FORM_SVF_DATA_BY_FORM_ID_FAILURE = createActionType('FORM_SVF_DATA_BY_FORM_ID_FAILURE')
export const RETRIEVE_FORMS_WITH_FIELDS_REQUEST = createActionType(
  'RETRIEVE_FORMS_WITH_FIELDS_REQUEST'
)
export const RETRIEVE_FORMS_WITH_FIELDS_SUCCESS = createActionType(
  'RETRIEVE_FORMS_WITH_FIELDS_SUCCESS'
)
export const RETRIEVE_FORMS_WITH_FIELDS_FAILURE = createActionType(
  'RETRIEVE_FORMS_WITH_FIELDS_FAILURE'
)

const defaultSchema = {
  layout: 'scroll',
  styles: {
    field: {
      containerBorder: false,
      containerWidth: '50%',
    },
    options: {
      containerBorder: false,
      direction: 'vertical',
      labelPosition: 'right',
      containerWidth: '50%',
    },
  },
}
export const prepareFormItems = (selectedSvf, fgsWithFields, mode, isFormDisabled, formAnswers) => {
  let fieldGroups = _.orderBy(fgsWithFields, 'ordinal')
  let fieldList = _.flatten(_.map(fgsWithFields, (fg) => fg.fields))
  const fieldsByfieldGroup = _.groupBy(fieldList, 'fieldGroup.id')

  const flatListData = []
  selectedSvf.form.instruction &&
    flatListData.push({
      key: `${selectedSvf.form.id}-instruction`,
      type: 'instruction',
      data: selectedSvf.form.instruction,
      formId: selectedSvf.form.id,
    })

  for (let index = 0; index < fieldGroups.length; index++) {
    const element = fieldGroups[index]
    const uiSchema = element.uiSchema ? JSON.parse(element.uiSchema) : defaultSchema
    let updatedFieldList = []
    if (fieldsByfieldGroup[element.id]) {
      updatedFieldList = setDisabledFields(
        fieldsByfieldGroup[element.id],
        formAnswers[selectedSvf.svfId]
      )
    }
    if (uiSchema && uiSchema.properties) {
      const hasKey = uiSchema.properties.hasOwnProperty('labelOrder')
      if (!hasKey) {
        uiSchema.properties.labelOrder = ['optionLabel', 'optionScore']
      }
    }

    if (
      (uiSchema.showSectionName &&
        (!_.isEqual(_.toUpper(element.fieldType), 'RESULT') || element.selfScored)) ||
      mode === 'printView'
    ) {
      flatListData.push({
        isHidden: !uiSchema.showSectionName,
        key: `${element.id}-sectionName`,
        type: 'label',
        data: element.fieldGroupName,
        fieldGroupOid: element.fieldGroupOid,
        layout: uiSchema.layout,
      })
    }
    if (
      !_.isEqual(_.toUpper(element.fieldType), 'RESULT') ||
      element.selfScored ||
      mode === 'printView'
    ) {
      flatListData.push({
        key: `${element.id}-section-instruction`,
        type: 'instruction',
        data: element.instruction,
        fieldGroupOid: element.fieldGroupOid,
      })
    }

    if (
      uiSchema.properties &&
      (uiSchema.properties.commonOptions || uiSchema.properties.commonOptionsScore) &&
      uiSchema.properties.labelOrder &&
      uiSchema.layout === 'grid'
    ) {
      const fields = _.filter(updatedFieldList, (f) => f.fieldType != 'result')
      if (!_.isEmpty(fields)) {
        flatListData.push({
          key: `${element.id}-header`,
          type: 'header',
          data: fields[0].dictionary.options,
          fieldGroupOid: element.fieldGroupOid,
          scoringEnabled: element.scoringEnabled,
          uiSchema,
        })
      }
    }
    var resultField
    _.forEach(updatedFieldList, (field, index) => {
      const fields = {
        key: field.id,
        type: 'field',
        data: {
          ...field,
          disabled: field.disabled || isFormDisabled,
          fieldGroup: {
            ...field.fieldGroup,
            uiSchema,
          },
        },
      }
      if (field.fieldType !== 'result') {
        flatListData.push(fields)
      } else if (
        field.fieldType === 'result' &&
        (field.fieldGroup.selfScored || mode === 'printView')
      ) {
        resultField = fields
      }
    })
    resultField && flatListData.push(resultField)
    resultField = null
    flatListData.push({
      key: `${element.id}-divider`,
      type: 'divider',
      fieldGroupOid: element.fieldGroupOid,
    })
  }

  selectedSvf.form.licenseHolder &&
    flatListData.push({
      key: `${selectedSvf.form.id}-footer`,
      type: 'footer',
      data: selectedSvf.form.licenseHolder.split('\r\n').join('<br/>'),
      formId: selectedSvf.form.id,
      isHidden: true,
    })

  return flatListData
}

export const prepareFormItemsForMobile = (
  selectedSvf,
  fgsWithFields,
  mode,
  isFormDisabled,
  formAnswers,
  currentPage, // current page on the mobile
  isNewPage // true if page is changed and not first page, false if any field is answered or first page
) => {
  let fieldGroups = _.orderBy(fgsWithFields, 'ordinal')
  let fieldList = _.flatten(_.map(fgsWithFields, (fg) => fg.fields))
  const fieldsByfieldGroup = _.groupBy(fieldList, 'fieldGroup.id')
  const headers = []
  const footer = []
  const sections = []
  let pageNo = 1

  for (let index = 0; index < fieldGroups.length; index++) {
    let flatListData = []
    let fieldIndex = 0
    const sectionHeaders = []
    const element = fieldGroups[index]
    const uiSchema = element.uiSchema ? JSON.parse(element.uiSchema) : defaultSchema
    uiSchema.mobilePageSize = uiSchema?.mobilePageSize || 5
    uiSchema.skipType = uiSchema?.skipType || 'hide'
    let updatedFieldList = []
    if (fieldsByfieldGroup[element.id]) {
      updatedFieldList = setDisabledFields(
        fieldsByfieldGroup[element.id],
        formAnswers[selectedSvf.svfId]
      )
    }
    if (uiSchema && uiSchema.properties) {
      const hasKey = uiSchema.properties.hasOwnProperty('labelOrder')
      if (!hasKey) {
        uiSchema.properties.labelOrder = ['optionLabel', 'optionScore']
      }
    }
    if (!_.isEmpty(headers)) {
      _.forEach(headers, (h) => sectionHeaders.push(h))
    }
    if (
      (uiSchema.showSectionName &&
        (!_.isEqual(_.toUpper(element.fieldType), 'RESULT') || element.selfScored)) ||
      mode === 'printView'
    ) {
      sectionHeaders.push({
        isHidden: !uiSchema.showSectionName,
        key: `${element.id}-sectionName`,
        type: 'label',
        data: element.fieldGroupName,
        fieldGroupOid: element.fieldGroupOid,
        layout: uiSchema.layout,
      })
    }
    if (
      (!_.isEqual(_.toUpper(element.fieldType), 'RESULT') ||
        element.selfScored ||
        mode === 'printView') &&
      element.instruction
    ) {
      sectionHeaders.push({
        key: `${element.id}-section-instruction`,
        type: 'instruction',
        data: element.instruction,
        fieldGroupOid: element.fieldGroupOid,
      })
    }

    if (
      uiSchema.properties &&
      (uiSchema.properties.commonOptions || uiSchema.properties.commonOptionsScore) &&
      uiSchema.properties.labelOrder &&
      uiSchema.layout === 'grid'
    ) {
      const fields = _.filter(updatedFieldList, (f) => f.fieldType != 'result')
      if (!_.isEmpty(fields)) {
        flatListData.push({
          key: `${element.id}-header`,
          type: 'header',
          data: fields[0].dictionary.options,
          fieldGroupOid: element.fieldGroupOid,
          scoringEnabled: element.scoringEnabled,
          uiSchema,
        })
      }
    }
    var resultField
    _.forEach(updatedFieldList, (field, index) => {
      const fields = {
        key: field.id,
        type: 'field',
        data: {
          ...field,
          disabled: field.disabled || isFormDisabled,
          fieldGroup: {
            ...field.fieldGroup,
            uiSchema,
          },
        },
      }
      if (field.fieldType !== 'result') {
        if (fieldIndex >= uiSchema?.mobilePageSize) {
          // Changes made to display only clickable image in page for mobile, if field type is clickable image
          sections.push({
            headers: sectionHeaders,
            data: [...flatListData],
          })
          pageNo = pageNo + 1

          flatListData = []
          fieldIndex = 0
        }
        const isOnSamePage = field.linkedFieldId
          ? isFieldOnSamePage(flatListData, field.linkedFieldId)
          : true
        // add field if field is not disabled
        //or if field is disabled but skip type is disabled
        // or if field is disabled and current page is greater than or equal to pageNo

        if (
          !field.disabled ||
          (uiSchema?.skipType === 'hide' && field.disabled && isOnSamePage) ||
          uiSchema?.skipType !== 'hide'
        ) {
          flatListData.push(fields)
          fieldIndex = fieldIndex + 1
        }
      } else if (
        field.fieldType === 'result' &&
        (field.fieldGroup.selfScored || mode === 'printView')
      ) {
        resultField = fields
      }
    })
    if (!_.isEmpty(flatListData)) {
      sections.push({
        headers: sectionHeaders,
        data: [...flatListData],
      })
      pageNo = pageNo + 1
      flatListData = []
    }
    resultField && flatListData.push(resultField)
    resultField = null
    // flatListData.push({
    //   key: `${element.id}-divider`,
    //   type: 'divider',
    //   fieldGroupOid: element.fieldGroupOid,
    // })
  }

  selectedSvf.form.licenseHolder &&
    footer.push({
      key: `${selectedSvf.form.id}-footer`,
      type: 'footer',
      data: selectedSvf.form.licenseHolder.split('\r\n').join('<br/>'),
      formId: selectedSvf.form.id,
      isHidden: true,
    })
  return {
    headers,
    sections,
    footer,
  }
}
const isFieldOnSamePage = (fields, linkedFieldId) => {
  const linkedField = _.find(fields, (field) => {
    return field.data.id === linkedFieldId
  })
  return !_.isEmpty(linkedField)
}

const isFieldDisabled = (field, currentField, destFieldOrdinal) => {
  return field.ordinal < currentField.ordinal
    ? field.disabled
    : !!(field.ordinal > currentField.ordinal && field.ordinal < destFieldOrdinal)
}

const setDisabledFields = (fieldList, currentFormAnswers) => {
  var loFieldList = [...fieldList]
  fieldList.forEach((currentField) => {
    if (!_.isEmpty(currentField.fieldRules)) {
      const field = {
        ...currentField,
        crfData: { ...currentFormAnswers?.[currentField.fieldOid] },
      }
      const existedDisabledField = _.filter(
        loFieldList,
        (fd) => fd.id == currentField.id && fd.disabled
      )
      if (field.crfData && _.isEmpty(existedDisabledField)) {
        const destFieldOrdinal = fetchNextFieldByFieldRule(field, fieldList)
        if (destFieldOrdinal) {
          loFieldList = _.map(loFieldList, (field) => ({
            ...field,
            disabled: isFieldDisabled(field, currentField, destFieldOrdinal),
            linkedFieldId: isFieldDisabled(field, currentField, destFieldOrdinal)
              ? currentField.id
              : null,
          }))
        }
      }
    }
  })
  return loFieldList
}

const storeDraftDebounce = (field, formId, fieldValue) => {
  storeDraftData(field, formId, fieldValue)
}
export const storeDraftFormData = (field, formId, fieldValue) => {
  storeDraftDebounce(field, formId, fieldValue)
}
export const getDraftFormData = (formId) => {
  const data = getDraftData(formId)
  return data || {}
}
export const prepareFormInstruction = (selectedSvf) => {
  // get the formData
  // get the fields [{id,type,label,value,options}]
  // if the form has step layout then push only the first field into the flat list data
  const formInstruction = []
  if (selectedSvf.form.instruction) {
    const instruction = {
      key: selectedSvf.formId,
      type: 'instruction',
      data: selectedSvf.form.instruction,
    }
    formInstruction.push(instruction)
  }
  return formInstruction
}

export const updateFormItems = (optionId) => async (dispatch) => {
  // compute field rule
  // and push the next valid field into flatListData
}

// if(fo.value == 'x') then go to f(n)
// just at f(n) into flat list data
// disable all fields which are greater than current field ordinal to ordinal of f(n)

const validationDebounce = (selectedField, props, dispatch) => {
  const fieldId = Object.keys(selectedField)[0]
  const selectedFieldValue = selectedField[fieldId]
  const currentField = _.find(props.fieldList, (fl) => {
    return fl.id === fieldId
  })
  const field = placeFieldValue(
    currentField,
    selectedFieldValue,
    props.formAnswers[props.selectedSvf.svfId][fieldId]?.optionOid
  )
  const nextFieldOrdinal =
    fetchNextFieldByFieldRule(field, props.fieldList) ||
    changeToDefaultNextFieldOrdinal(currentField, props.fieldList)
  if (nextFieldOrdinal) {
    dispatch(updateFieldList(props.fieldList, currentField.ordinal, nextFieldOrdinal))
  }
  if (!disableForm(props.selectedSvf)) {
    storeDraftFormData(selectedField, props.selectedSvf.svfId, field)
  }
}

export const validateRules = (selectedField, props) => async (dispatch) => {
  validationDebounce(selectedField, props, dispatch)
}

export const getFormAndFieldsByFormIdRequest = () => async (dispatch) => {
  dispatch({
    type: FORM_FIELD_LIST_BY_FORM_ID_REQUEST,
  })
}

export const getFormAndFieldsByFormIdSuccess = (data) => async (dispatch) => {
  dispatch({
    type: FORM_FIELD_LIST_BY_FORM_ID_SUCCESS,
    form: data,
  })
}

export const getFormAndFieldsByFormIdFailure = (error) => async (dispatch) => {
  dispatch({
    type: FORM_FIELD_LIST_BY_FORM_ID_FAILURE,
    payload: error,
  })
}

export const getFormAndSvfDataByFormIdRequest = () => async (dispatch) => {
  dispatch({
    type: FORM_SVF_DATA_BY_FORM_ID_REQUEST,
  })
}

export const getFormAndSvfDataByFormIdSuccess = (data) => async (dispatch) => {
  dispatch({
    type: FORM_SVF_DATA_BY_FORM_ID_SUCCESS,
    form: data,
  })
}
export const getFormAndSvfDataByFormIdFailure = (error) => async (dispatch) => {
  dispatch({
    type: FORM_SVF_DATA_BY_FORM_ID_FAILURE,
    payload: error,
  })
}

export const sendPrintData = async (printRequest) => {
  try {
    const res = await formPrintApi.post(`/${printRequest.primaryOrgCode}/studies/${printRequest.studyId}/sites/${printRequest.siteId}/forms/${printRequest.formId}/print`, printRequest)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const uploadImageToBucket = async (formData) => {
  try {
    const res = await api.post(`/upload/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
  }
}

/* This method is to store clickable images for form when the app is preview mode or print mode */
export const updateImages = (fields, key) => async (dispatch) => {
  const fieldGroups = !_.isEmpty(fields) ?  fields[key]: [];
  const fieldList = [];
  _.forEach(fieldGroups, (fg) => {
    const clkFields = _.filter(fg.fields, (field) => _.isEqual(field.fieldType, fieldTypes.CLICKIMAGE));
    _.map(clkFields, (field) => {
      fieldList.push(field);
    });
  })
  const clickableImageFields = _.filter(fieldList, field => _.isEqual(field?.fieldType, fieldTypes.CLICKIMAGE))
  const imagesOfPreview = {
  }
  _.forEach(clickableImageFields, fld => {
    imagesOfPreview[fld?.dictionary?.imageCode] = {
        fieldUrl: fld?.dictionary?.img?.src
      }
  })
  dispatch({type: UPDATE_IMAGE_CODES, data: imagesOfPreview, subjectId: constants.AppType.PREVIEW})
}

export const retrieveFormsWithFields =
  (svId = null, formId = null, formPreview = false, svfId = null, printRequestProps={}) =>
  async (dispatch, getState) => {
    try {
      const locale = getItem('locale') || 'en_US';
      const formIdList = formPreview ? [formId] : getFormIds(getState().metaData, svId, getState().appStatus?.appType)
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST })
        const res = await api.post(`/${printRequestProps.primaryOrgCode || constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${printRequestProps.studyId || constants.ContextProperties.STUDY_ID}/forms`, {
          formIds: _.uniqBy(formIdList),
          locale
        })
        saveFormDataInStore(res.data, formPreview, dispatch, svfId) 
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE })
    }
  }

  export const retrieveFormsWithFieldsByFormLevel =
  (clientId, formId, primaryOrgCode) =>
  async (dispatch, getState) => {
    try {
      const locale = getItem('locale') || 'en_US';
      const formIdList =  [formId];
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST })
        const res = await api.post(`/${primaryOrgCode}/forms/${formId}?clientId=${clientId}`, {locale})
        saveFormDataInStore(res.data, true, dispatch, null)      
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE })
    }
  }

  const saveFormDataInStore = (data, formPreview, dispatch, svfId) => {
    var formList = _.map(data, (ele) => {
      return { ...ele, fieldGroups: null }
    })
    dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_SUCCESS, formList: formList })
    const fieldListOfMultipleForms = getFieldListFromForms(data)
    dispatch(saveFieldListOfMultipleForms(fieldListOfMultipleForms))
    if (formPreview && !_.isEmpty(data)) {
      dispatch(updateImages(fieldListOfMultipleForms, data[0].id))   
      dispatch(updateSelectedVisitFormOidAndSelectedSvfId(data[0].id, svfId))
    }
  }

export const getFieldListFromForms = (formList) => {
  var formKeyWithFields = {}
  _.forEach(
    formList,
    (ele) =>
      (formKeyWithFields[ele.id] = _.map(ele.fieldGroups, (fg) => {
        const fields = _.map(fg.fields, (field) => {
          if (field.dictionary) {
            return {
              ...field,
              dictionary:
                typeof field.dictionary !== 'string'
                  ? field.dictionary
                  : JSON.parse(field.dictionary),
              enDictionary:
                typeof field.enDictionary !== 'string'
                  ? field.enDictionary
                  : JSON.parse(field.enDictionary),
            }
          }
          return field
        })
        fg.fields = fields
        return fg
      }))
  )
  return formKeyWithFields
}

export const retrieveFormsIfNotExist = () => async (dispatch, getState) => {
  let exisitingFormIds = []
  if (getState().form && getState().form.formList) {
    exisitingFormIds = _.map(getState().form.formList, (f) => f.id)
  }
  const metadataFormIds = getFormIds(getState().metaData, null, getState().appStatus?.appType)
  const formIdsDiff1 = _.difference(metadataFormIds, exisitingFormIds)
  const formIdsDiff2 = _.difference(exisitingFormIds, metadataFormIds)
  if (!_.isEmpty(formIdsDiff1) || !_.isEmpty(formIdsDiff2)) {
    dispatch(retrieveFormsWithFields())
  }
}

export const submitForm =(crfVersionId, subjectVisitId, subjectId, svfList) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/${PRIMARY_ORG_CODE}/studies/${STUDY_ID}/crfVersions/${crfVersionId}/sites/${SITE_ID}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms`, svfList)
    return res;
  } catch (error) {
    throw error
  }
}