export default {
    "Africa/Abidjan": "Afrika/Abidjan",
"Africa/Accra": "Afrika/Accra",
"Africa/Addis_Ababa": "Afrika/Addis_Abeba",
"Africa/Algiers": "Afrika/Alžír",
"Africa/Asmara": "Afrika/Asmara",
"Africa/Asmera": "Afrika/Asmera",
"Africa/Bamako": "Afrika/Bamako",
"Africa/Bangui": "Afrika/Bangui",
"Africa/Banjul": "Afrika/Banjul",
"Africa/Bissau": "Afrika/Bissau",
"Africa/Blantyre": "Afrika/Blantyre",
"Africa/Brazzaville": "Afrika/Brazzaville",
"Africa/Bujumbura": "Afrika/Bujumbura",
"Africa/Cairo": "Afrika/Káhira",
"Africa/Casablanca": "Afrika/Casablanca",
"Africa/Ceuta": "Afrika/Ceuta",
"Africa/Conakry": "Afrika/Conakry",
"Africa/Dakar": "Afrika/Dakar",
"Africa/Dar_es_Salaam": "Afrika/Dar_es_Salaam",
"Africa/Djibouti": "Afrika/Džibutsko",
"Africa/Douala": "Afrika/Douala",
"Africa/El_Aaiun": "Afrika/El_Aaiun",
"Africa/Freetown": "Afrika/Freetown",
"Africa/Gaborone": "Afrika/Gaborone",
"Africa/Harare": "Afrika/Harare",
"Africa/Johannesburg": "Afrika/Johannesburg",
"Africa/Juba": "Afrika/Juba",
"Africa/Kampala": "Afrika/Kampala",
"Africa/Khartoum": "Afrika/Chartúm",
"Africa/Kigali": "Afrika/Kigali",
"Africa/Kinshasa": "Afrika/Kinshasa",
"Africa/Lagos": "Afrika/Lagos",
"Africa/Libreville": "Afrika/Libreville",
"Africa/Lome": "Afrika/Lome",
"Africa/Luanda": "Afrika/Luanda",
"Africa/Lubumbashi": "Afrika/Lubumbashi",
"Africa/Lusaka": "Afrika/Lusaka",
"Africa/Malabo": "Afrika/Malabo",
"Africa/Maputo": "Afrika/Maputo",
"Africa/Maseru": "Afrika/Maseru",
"Africa/Mbabane": "Afrika/Mbabane",
"Africa/Mogadishu": "Afrika/Mogadišo",
"Africa/Monrovia": "Afrika/Monrovia",
"Africa/Nairobi": "Afrika/Nairobi",
"Africa/Ndjamena": "Afrika/Ndjamena",
"Africa/Niamey": "Afrika/Niamey",
"Africa/Nouakchott": "Afrika/Nouakchott",
"Africa/Ouagadougou": "Afrika/Ouagadougou",
"Africa/Porto-Novo": "Afrika/Porto-Novo",
"Africa/Sao_Tome": "Afrika/Sao_Tome",
"Africa/Timbuktu": "Afrika/Timbuktu",
"Africa/Tripoli": "Afrika/Tripolis",
"Africa/Tunis": "Afrika/Tunis",
"Africa/Windhoek": "Afrika/Windhoek",
"America/Adak": "Amerika/Adak",
"America/Anchorage": "Amerika/Anchorage",
"America/Anguilla": "Amerika/Anguilla",
"America/Antigua": "Amerika/Antigua",
"America/Araguaina": "Amerika/Araguaina",
"America/Argentina/Buenos_Aires": "Amerika/Argentina/Buenos_Aires",
"America/Argentina/Catamarca": "Amerika/Argentina/Catamarca",
"America/Argentina/ComodRivadavia": "Amerika/Argentina/Comodrivadavia",
"America/Argentina/Cordoba": "Amerika/Argentina/Cordoba",
"America/Argentina/Jujuy": "Amerika/Argentina/Jujuy",
"America/Argentina/La_Rioja": "Amerika/Argentina/La_Rioja",
"America/Argentina/Mendoza": "Amerika/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos": "Amerika/Argentina/Rio_Gallegos",
"America/Argentina/Salta": "Amerika/Argentina/Salta",
"America/Argentina/San_Juan": "Amerika/Argentina/San_Juan",
"America/Argentina/San_Luis": "Amerika/Argentina/San_Luis",
"America/Argentina/Tucuman": "Amerika/Argentina/Tucuman",
"America/Argentina/Ushuaia": "Amerika/Argentina/Ushuaia",
"America/Aruba": "Amerika/Aruba",
"America/Asuncion": "Amerika/Asuncion",
"America/Atikokan": "Amerika/Atikokan",
"America/Atka": "Amerika/Atka",
"America/Bahia": "Amerika/Bahia",
"America/Bahia_Banderas": "Amerika/Bahia_Banderas",
"America/Barbados": "Amerika/Barbados",
"America/Belem": "Amerika/Belém",
"America/Belize": "Amerika/Belize",
"America/Blanc-Sablon": "Amerika/Blanc-Sablon",
"America/Boa_Vista": "Amerika/Boa_Vista",
"America/Bogota": "Amerika/Bogota",
"America/Boise": "Amerika/Boise",
"America/Buenos_Aires": "Amerika/Buenos_Aires",
"America/Cambridge_Bay": "Amerika/Cambridge_Bay",
"America/Campo_Grande": "Amerika/Campo_Grande",
"America/Cancun": "Amerika/Cancún",
"America/Caracas": "Amerika/Caracas",
"America/Catamarca": "Amerika/Katamarca",
"America/Cayenne": "Amerika/Cayenne",
"America/Cayman": "Amerika/Kajman",
"America/Chicago": "Amerika/Chicago",
"America/Chihuahua": "Amerika/Chihuahua",
"America/Ciudad_Juarez": "Amerika/Ciudad_Juarez",
"America/Coral_Harbour": "Amerika/Coral_Harbour",
"America/Cordoba": "Amerika/Cordoba",
"America/Costa_Rica": "Amerika/Kosta_Rica",
"America/Creston": "Amerika/Creston",
"America/Cuiaba": "Amerika/Cuiaba",
"America/Curacao": "Amerika/Curacao",
"America/Danmarkshavn": "Amerika/Danmarkshavn",
"America/Dawson": "Amerika/Dawson",
"America/Dawson_Creek": "Amerika/Dawson_Creek",
"America/Denver": "Amerika/Denver",
"America/Detroit": "Amerika/Detroit",
"America/Dominica": "Amerika/Dominika",
"America/Edmonton": "Amerika/Edmonton",
"America/Eirunepe": "Amerika/Eirunepe",
"America/El_Salvador": "Amerika/El_Salvador",
"America/Ensenada": "America/Ensenada",
"America/Fort_Nelson": "Amerika/Fort_Nelson",
"America/Fort_Wayne": "Amerika/Fort_Wayne",
"America/Fortaleza": "Amerika/Fortaleza",
"America/Glace_Bay": "Amerika/Glace_Bay",
"America/Godthab": "Amerika/Godthab",
"America/Goose_Bay": "Amerika/Goose_Bay",
"America/Grand_Turk": "Amerika/Grand_Turk",
"America/Grenada": "Amerika/Grenada",
"America/Guadeloupe": "Amerika/Guadeloupe",
"America/Guatemala": "Amerika/Guatemala",
"America/Guayaquil": "Amerika/Guayaquil",
"America/Guyana": "Amerika/Guyana",
"America/Halifax": "Amerika/Halifax",
"America/Havana": "Amerika/Havana",
"America/Hermosillo": "Amerika/Hermosillo",
"America/Indiana/Indianapolis": "Amerika/Indiana/Indianapolis",
"America/Indiana/Knox": "Amerika/Indiana/Knox",
"America/Indiana/Marengo": "Amerika/Indiana/Marengo",
"America/Indiana/Petersburg": "Amerika/Indiana/Petersburg",
"America/Indiana/Tell_City": "Amerika/Indiana/Tell_City",
"America/Indiana/Vevay": "Amerika/Indiana/Vevay",
"America/Indiana/Vincennes": "Amerika/Indiana/Vincennes",
"America/Indiana/Winamac": "Amerika/Indiana/Winamac",
"America/Indianapolis": "Amerika/Indianapolis",
"America/Inuvik": "Amerika/Inuvik",
"America/Iqaluit": "Amerika/Iqaluit",
"America/Jamaica": "Amerika/Jamajka",
"America/Jujuy": "Amerika/Jujuy",
"America/Juneau": "Amerika/Juneau",
"America/Kentucky/Louisville": "Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello": "Amerika/Kentucky/Monticello",
"America/Knox_IN": "Amerika/Knox_in",
"America/Kralendijk": "Amerika/Kralendijk",
"America/La_Paz": "Amerika/La_Paz",
"America/Lima": "Amerika/Lima",
"America/Los_Angeles": "Amerika/Los_Angeles",
"America/Louisville": "Amerika/Louisville",
"America/Lower_Princes": "Amerika/Lower_Princes",
"America/Maceio": "Amerika/Maceio",
"America/Managua": "Amerika/Managua",
"America/Manaus": "Amerika/Manaus",
"America/Marigot": "Amerika/Marigot",
"America/Martinique": "Amerika/Martinik",
"America/Matamoros": "Amerika/Matamoros",
"America/Mazatlan": "Amerika/Mazatlan",
"America/Mendoza": "Amerika/Mendoza",
"America/Menominee": "Amerika/Menominee",
"America/Merida": "Amerika/Merida",
"America/Metlakatla": "Amerika/Metlakatla",
"America/Mexico_City": "America/Mexico_City",
"America/Miquelon": "Amerika/Miquelon",
"America/Moncton": "Amerika/Moncton",
"America/Monterrey": "Amerika/Monterrey",
"America/Montevideo": "Amerika/Montevideo",
"America/Montreal": "Amerika/Montreal",
"America/Montserrat": "Amerika/Montserrat",
"America/Nassau": "Amerika/Nassau",
"America/New_York": "Amerika/New_York",
"America/Nipigon": "Amerika/Nipigon",
"America/Nome": "America/Nome",
"America/Noronha": "Amerika/Noronha",
"America/North_Dakota/Beulah": "Amerika/Severní Dakota/Beulah",
"America/North_Dakota/Center": "Amerika/Severní Dakota/Centrum",
"America/North_Dakota/New_Salem": "Amerika/Severní Dakota/New_Salem",
"America/Nuuk": "Amerika/Nuuk",
"America/Ojinaga": "Amerika/Ojinaga",
"America/Panama": "Amerika/Panama",
"America/Pangnirtung": "Amerika/Pangnirtung",
"America/Paramaribo": "Amerika/Paramaribo",
"America/Phoenix": "Amerika/Phoenix",
"America/Port-au-Prince": "Amerika/Port-au-Prince",
"America/Port_of_Spain": "Amerika/přístav Španělska",
"America/Porto_Acre": "Amerika/Porto_Acre",
"America/Porto_Velho": "America/Porto_Velho",
"America/Puerto_Rico": "Amerika/Puerto_Rico",
"America/Punta_Arenas": "Amerika/Punta_Arenas",
"America/Rainy_River": "Amerika/Rainy_River",
"America/Rankin_Inlet": "Amerika/Rankin_Inlet",
"America/Recife": "Amerika/Recife",
"America/Regina": "Amerika/Regina",
"America/Resolute": "Amerika/Resolute",
"America/Rio_Branco": "Amerika/Rio_Branco",
"America/Rosario": "Amerika/Rosario",
"America/Santa_Isabel": "Amerika/Santa_Isabel",
"America/Santarem": "Amerika/Santarem",
"America/Santiago": "Amerika/Santiago",
"America/Santo_Domingo": "Amerika/Santo_Domingo",
"America/Sao_Paulo": "Amerika/Sao_Paulo",
"America/Scoresbysund": "Amerika/Scoresbysund",
"America/Shiprock": "Amerika/Shiprock",
"America/Sitka": "Amerika/Sitka",
"America/St_Barthelemy": "Amerika/St_Barthelemy",
"America/St_Johns": "Amerika/St_Johns",
"America/St_Kitts": "Amerika/St_Kitts",
"America/St_Lucia": "Amerika/St_Lucia",
"America/St_Thomas": "Amerika/St_Thomas",
"America/St_Vincent": "Amerika/St_Vincent",
"America/Swift_Current": "Amerika/Swift_Current",
"America/Tegucigalpa": "Amerika/Tegucigalpa",
"America/Thule": "Amerika/Thule",
"America/Thunder_Bay": "Amerika/Thunder_Bay",
"America/Tijuana": "Amerika/Tijuana",
"America/Toronto": "Amerika/Toronto",
"America/Tortola": "Amerika/Tortola",
"America/Vancouver": "Amerika/Vancouver",
"America/Virgin": "Amerika/Virgin",
"America/Whitehorse": "Amerika/Whitehorse",
"America/Winnipeg": "Amerika/Winnipeg",
"America/Yakutat": "Amerika/Yakutat",
"America/Yellowknife": "Amerika/Yellowknife",
"Antarctica/Casey": "Antarktida/Casey",
"Antarctica/Davis": "Antarktida/Davis",
"Antarctica/DumontDUrville": "Antarktida/Dumontdurville",
"Antarctica/Macquarie": "Antarktida/Macquarie",
"Antarctica/Mawson": "Antarktida/Mawson",
"Antarctica/McMurdo": "Antarktida/McMurdo",
"Antarctica/Palmer": "Antarktida/Palmer",
"Antarctica/Rothera": "Antarktida/Rothera",
"Antarctica/South_Pole": "Antarktida/jižní_pól",
"Antarctica/Syowa": "Antarktida/Syowa",
"Antarctica/Troll": "Antarktida/Troll",
"Antarctica/Vostok": "Antarktida/Vostok",
"Arctic/Longyearbyen": "Arktika/Longyearbyen",
"Asia/Aden": "Asie/Aden",
"Asia/Almaty": "Asie/Almaty",
"Asia/Amman": "Asie/Ammán",
"Asia/Anadyr": "Asie/Anadyr",
"Asia/Aqtau": "Asie/Aqtau",
"Asia/Aqtobe": "Asie/Aqtobe",
"Asia/Ashgabat": "Asie/Ašchabad",
"Asia/Ashkhabad": "Asie/Ashkhabad",
"Asia/Atyrau": "Asie/Atyrau",
"Asia/Baghdad": "Asie/Bagdád",
"Asia/Bahrain": "Asie/Bahrajn",
"Asia/Baku": "Asie/Baku",
"Asia/Bangkok": "Asie/Bangkok",
"Asia/Barnaul": "Asie/Barnaul",
"Asia/Beirut": "Asie/Bejrút",
"Asia/Bishkek": "Asie/Biškek",
"Asia/Brunei": "Asie/Brunej",
"Asia/Calcutta": "Asie/Kalkata",
"Asia/Chita": "Asie/Čita",
"Asia/Choibalsan": "Asie/Choibalsan",
"Asia/Chongqing": "Asie/Chongqing",
"Asia/Chungking": "Asie/Chungking",
"Asia/Colombo": "Asie/Kolombo",
"Asia/Dacca": "Asie/Dacca",
"Asia/Damascus": "Asie/Damašek",
"Asia/Dhaka": "Asie/Dháka",
"Asia/Dili": "Asie/Dili",
"Asia/Dubai": "Asie/Dubaj",
"Asia/Dushanbe": "Asie/Dušanbe",
"Asia/Famagusta": "Asie/Famagusta",
"Asia/Gaza": "Asie/Gaza",
"Asia/Harbin": "Asie/Harbin",
"Asia/Hebron": "Asie/Hebron",
"Asia/Ho_Chi_Minh": "Asie/Ho_Chi_Minh",
"Asia/Hong_Kong": "Asie/Hongkong",
"Asia/Hovd": "Asie/Hovd",
"Asia/Irkutsk": "Asie/Irkutsk",
"Asia/Istanbul": "Asie/Istanbul",
"Asia/Jakarta": "Asie/Jakarta",
"Asia/Jayapura": "Asie/Jayapura",
"Asia/Jerusalem": "Asie/Jeruzalém",
"Asia/Kabul": "Asie/Kábul",
"Asia/Kamchatka": "Asie/Kamčatka",
"Asia/Karachi": "Asie/Karáčí",
"Asia/Kashgar": "Asie/Kašgar",
"Asia/Kathmandu": "Asie/Káthmándú",
"Asia/Katmandu": "Asie/Káthmándú",
"Asia/Khandyga": "Asie/Khandyga",
"Asia/Kolkata": "Asie/Kalkata",
"Asia/Krasnoyarsk": "Asie/Krasnojarsk",
"Asia/Kuala_Lumpur": "Asie/Kuala_Lumpur",
"Asia/Kuching": "Asie/Kuching",
"Asia/Kuwait": "Asie/Kuvajt",
"Asia/Macao": "Asie/Macao",
"Asia/Macau": "Asie/Macao",
"Asia/Magadan": "Asie/Magadan",
"Asia/Makassar": "Asie/Makassar",
"Asia/Manila": "Asie/Manila",
"Asia/Muscat": "Asie/Maskat",
"Asia/Nicosia": "Asie/Nikósie",
"Asia/Novokuznetsk": "Asie/Novokuznetsk",
"Asia/Novosibirsk": "Asie/Novosibirsk",
"Asia/Omsk": "Asie/Omsk",
"Asia/Oral": "Asie/Orální",
"Asia/Phnom_Penh": "Asie/Phnom_Penh",
"Asia/Pontianak": "Asie/Pontianak",
"Asia/Pyongyang": "Asie/Pchjongjang",
"Asia/Qatar": "Asie/Katar",
"Asia/Qostanay": "Asie/Qostanay",
"Asia/Qyzylorda": "Asie/Qyzylorda",
"Asia/Rangoon": "Asie/Rangún",
"Asia/Riyadh": "Asie/Rijád",
"Asia/Saigon": "Asie/Saigon",
"Asia/Sakhalin": "Asie/Sachalin",
"Asia/Samarkand": "Asie/Samarkand",
"Asia/Seoul": "Asie/Soul",
"Asia/Shanghai": "Asie/Šanghaj",
"Asia/Singapore": "Asie/Singapur",
"Asia/Srednekolymsk": "Asie/Srednekolymsk",
"Asia/Taipei": "Asie/Tchaj-pej",
"Asia/Tashkent": "Asie/Taškent",
"Asia/Tbilisi": "Asie/Tbilisi",
"Asia/Tehran": "Asie/Teherán",
"Asia/Tel_Aviv": "Asie/Tel_Aviv",
"Asia/Thimbu": "Asie/Thimbu",
"Asia/Thimphu": "Asie/Thimphu",
"Asia/Tokyo": "Asie/Tokio",
"Asia/Tomsk": "Asie/Tomsk",
"Asia/Ujung_Pandang": "Asie/Ujung_Pandang",
"Asia/Ulaanbaatar": "Asie/Ulánbátar",
"Asia/Ulan_Bator": "Asie/Ulan_Bator",
"Asia/Urumqi": "Asie/Urumqi",
"Asia/Ust-Nera": "Asie/Ust-Nera",
"Asia/Vientiane": "Asie/Vientiane",
"Asia/Vladivostok": "Asie/Vladivostok",
"Asia/Yakutsk": "Asie/Jakutsk",
"Asia/Yangon": "Asie/Yangon",
"Asia/Yekaterinburg": "Asie/Jekatěrinburg",
"Asia/Yerevan": "Asie/Jerevan",
"Atlantic/Azores": "Atlantický oceán/Azory",
"Atlantic/Bermuda": "Atlantický/Bermudy",
"Atlantic/Canary": "Atlantický/Kanárský",
"Atlantic/Cape_Verde": "Atlantic/Cape Verde",
"Atlantic/Faeroe": "Atlantic/Faerské ostrovy",
"Atlantic/Faroe": "Atlantik/Faerské ostrovy",
"Atlantic/Jan_Mayen": "Atlantický/Jan_Mayen",
"Atlantic/Madeira": "Atlantik/Madeira",
"Atlantic/Reykjavik": "Atlantic/Reykjavík",
"Atlantic/South_Georgia": "Atlantický/jižní_Gruzie",
"Atlantic/St_Helena": "Atlantický/St_Helena",
"Atlantic/Stanley": "Atlantic/Stanley",
"Australia/ACT": "Austrálie/ACT",
"Australia/Adelaide": "Austrálie/Adelaide",
"Australia/Brisbane": "Austrálie/Brisbane",
"Australia/Broken_Hill": "Austrálie/Broken_Hill",
"Australia/Canberra": "Austrálie/Canberra",
"Australia/Currie": "Austrálie/Currie",
"Australia/Darwin": "Austrálie/Darwin",
"Australia/Eucla": "Austrálie/Eucla",
"Australia/Hobart": "Austrálie/Hobart",
"Australia/LHI": "Austrálie/LHI",
"Australia/Lindeman": "Austrálie/Lindeman",
"Australia/Lord_Howe": "Austrálie/Lord_Howe",
"Australia/Melbourne": "Austrálie/Melbourne",
"Australia/NSW": "Austrálie/NSW",
"Australia/North": "Austrálie/Sever",
"Australia/Perth": "Austrálie/Perth",
"Australia/Queensland": "Austrálie/Queensland",
"Australia/South": "Austrálie/jih",
"Australia/Sydney": "Austrálie/Sydney",
"Australia/Tasmania": "Austrálie/Tasmánie",
"Australia/Victoria": "Austrálie/Victoria",
"Australia/West": "Austrálie/Západ",
"Australia/Yancowinna": "Austrálie/Yancowinna",
"Brazil/Acre": "Brazílie/Acre",
"Brazil/DeNoronha": "Brazílie/Denoronha",
"Brazil/East": "Brazílie/východ",
"Brazil/West": "Brazílie/Západ",
"CET": "TENHLE",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Kanada/Atlantik",
"Canada/Central": "Kanada/Střední",
"Canada/Eastern": "Kanada/Východ",
"Canada/Mountain": "Kanada/hora",
"Canada/Newfoundland": "Kanada/Newfoundland",
"Canada/Pacific": "Kanada/Pacifik",
"Canada/Saskatchewan": "Kanada/Saskatchewan",
"Canada/Yukon": "Kanada/Yukon",
"Chile/Continental": "Chile/kontinentální",
"Chile/EasterIsland": "Chile/Velikonoční ostrov",
"Cuba": "Kuba",
"EET": "JÍST",
"EST": "EST",
"EST5EDT": "EST5EDT",
"Egypt": "Egypt",
"Eire": "Irsko",
"Etc/GMT": "Etc/GMT",
"Etc/GMT+0": "Etc/GMT+0",
"Etc/GMT+1": "Etc/GMT+1",
"Etc/GMT+10": "Etc/GMT+10",
"Etc/GMT+11": "Etc/GMT+11",
"Etc/GMT+12": "Etc/GMT+12",
"Etc/GMT+2": "Etc/GMT+2",
"Etc/GMT+3": "Etc/GMT+3",
"Etc/GMT+4": "Etc/GMT+4",
"Etc/GMT+5": "Etc/GMT+5",
"Etc/GMT+6": "Etc/GMT+6",
"Etc/GMT+7": "Etc/GMT+7",
"Etc/GMT+8": "Etc/GMT+8",
"Etc/GMT+9": "Etc/GMT+9",
"Etc/GMT-0": "Etc/GMT-0",
"Etc/GMT-1": "Etc/GMT-1",
"Etc/GMT-10": "Etc/GMT-10",
"Etc/GMT-11": "Etc/GMT-11",
"Etc/GMT-12": "Etc/GMT-12",
"Etc/GMT-13": "Etc/GMT-13",
"Etc/GMT-14": "Etc/GMT-14",
"Etc/GMT-2": "Etc/GMT-2",
"Etc/GMT-3": "Etc/GMT-3",
"Etc/GMT-4": "Etc/GMT-4",
"Etc/GMT-5": "Etc/GMT-5",
"Etc/GMT-6": "Etc/GMT-6",
"Etc/GMT-7": "Etc/GMT-7",
"Etc/GMT-8": "Etc/GMT-8",
"Etc/GMT-9": "Etc/GMT-9",
"Etc/GMT0": "Etc/GMT0",
"Etc/Greenwich": "Etc/Greenwich",
"Etc/UCT": "Etc/UCT",
"Etc/UTC": "Etc/UTC",
"Etc/Universal": "Etc/Univerzální",
"Etc/Zulu": "Etc/Zulu",
"Europe/Amsterdam": "Evropa/Amsterdam",
"Europe/Andorra": "Evropa/Andorra",
"Europe/Astrakhan": "Evropa/Astrachaň",
"Europe/Athens": "Evropa/Athény",
"Europe/Belfast": "Evropa/Belfast",
"Europe/Belgrade": "Evropa/Bělehrad",
"Europe/Berlin": "Evropa/Berlín",
"Europe/Bratislava": "Evropa/Bratislava",
"Europe/Brussels": "Evropa/Brusel",
"Europe/Bucharest": "Evropa/Bukurešť",
"Europe/Budapest": "Evropa/Budapešť",
"Europe/Busingen": "Evropa/Busingen",
"Europe/Chisinau": "Evropa/Kišiněv",
"Europe/Copenhagen": "Evropa/Kodaň",
"Europe/Dublin": "Evropa/Dublin",
"Europe/Gibraltar": "Evropa/Gibraltar",
"Europe/Guernsey": "Evropa/Guernsey",
"Europe/Helsinki": "Evropa/Helsinky",
"Europe/Isle_of_Man": "Evropa/Isle_of_Man",
"Europe/Istanbul": "Evropa/Istanbul",
"Europe/Jersey": "Evropa/Jersey",
"Europe/Kaliningrad": "Evropa/Kaliningrad",
"Europe/Kiev": "Evropa/Kyjev",
"Europe/Kirov": "Evropa/Kirov",
"Europe/Kyiv": "Evropa/Kyjev",
"Europe/Lisbon": "Evropa/Lisabon",
"Europe/Ljubljana": "Evropa/Lublaň",
"Europe/London": "Evropa/Londýn",
"Europe/Luxembourg": "Evropa/Lucembursko",
"Europe/Madrid": "Evropa/Madrid",
"Europe/Malta": "Evropa/Malta",
"Europe/Mariehamn": "Evropa/Mariehamn",
"Europe/Minsk": "Evropa/Minsk",
"Europe/Monaco": "Evropa/Monako",
"Europe/Moscow": "Evropa/Moskva",
"Europe/Nicosia": "Evropa/Nikósie",
"Europe/Oslo": "Evropa/Oslo",
"Europe/Paris": "Evropa/Paříž",
"Europe/Podgorica": "Evropa/Podgorica",
"Europe/Prague": "Evropa/Praha",
"Europe/Riga": "Evropa/Riga",
"Europe/Rome": "Evropa/Řím",
"Europe/Samara": "Evropa/Samara",
"Europe/San_Marino": "Evropa/San_Marino",
"Europe/Sarajevo": "Evropa/Sarajevo",
"Europe/Saratov": "Evropa/Saratov",
"Europe/Simferopol": "Evropa/Simferopol",
"Europe/Skopje": "Evropa/Skopje",
"Europe/Sofia": "Evropa/Sofie",
"Europe/Stockholm": "Evropa/Stockholm",
"Europe/Tallinn": "Evropa/Tallinn",
"Europe/Tirane": "Evropa/Tirane",
"Europe/Tiraspol": "Evropa/Tiraspol",
"Europe/Ulyanovsk": "Evropa/Uljanovsk",
"Europe/Uzhgorod": "Evropa/Užhorod",
"Europe/Vaduz": "Evropa/Vaduz",
"Europe/Vatican": "Evropa/Vatikán",
"Europe/Vienna": "Evropa/Vídeň",
"Europe/Vilnius": "Evropa/Vilnius",
"Europe/Volgograd": "Evropa/Volgograd",
"Europe/Warsaw": "Evropa/Varšava",
"Europe/Zagreb": "Evropa/Záhřeb",
"Europe/Zaporozhye": "Evropa/Záporoží",
"Europe/Zurich": "Evropa/Curych",
"GB": "GB",
"GB-Eire": "GB Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "HST",
"Hongkong": "Hongkong",
"Iceland": "Island",
"Indian/Antananarivo": "Indický/Antananarivo",
"Indian/Chagos": "Indický/Chagos",
"Indian/Christmas": "Indické/Vánoce",
"Indian/Cocos": "Indický/Kokos",
"Indian/Comoro": "Indické/Komory",
"Indian/Kerguelen": "Indický/Kerguelen",
"Indian/Mahe": "Indický/Mahe",
"Indian/Maldives": "Indiánské/Maledivy",
"Indian/Mauritius": "Indický/Mauricius",
"Indian/Mayotte": "Indická/Mayotte",
"Indian/Reunion": "Indické/Reunion",
"Iran": "Írán",
"Israel": "Izrael",
"Jamaica": "Jamajka",
"Japan": "Japonsko",
"Kwajalein": "Kwajalein",
"Libya": "Libye",
"MET": "S",
"MST": "MST",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Mexiko/bajanorte",
"Mexico/BajaSur": "Mexiko/Bajasur",
"Mexico/General": "Mexiko/Obecné",
"NZ": "NEW",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Pacific/Apia",
"Pacific/Auckland": "Pacific/Auckland",
"Pacific/Bougainville": "Pacific/Bougainville",
"Pacific/Chatham": "Pacific/Chatham",
"Pacific/Chuuk": "Pacific/Chuuk",
"Pacific/Easter": "Pacific/Velikonoce",
"Pacific/Efate": "Pacific/Efate",
"Pacific/Enderbury": "Pacific/Enderbury",
"Pacific/Fakaofo": "Pacific/Fakaofo",
"Pacific/Fiji": "Pacific/Fidži",
"Pacific/Funafuti": "Pacific/Funafuti",
"Pacific/Galapagos": "Pacific/Galapágy",
"Pacific/Gambier": "Pacific/Gambier",
"Pacific/Guadalcanal": "Pacific/Guadalcanal",
"Pacific/Guam": "Pacific/Guam",
"Pacific/Honolulu": "Pacific/Honolulu",
"Pacific/Johnston": "Pacific/Johnston",
"Pacific/Kanton": "Pacific/Kanton",
"Pacific/Kiritimati": "Pacific/Kiritimati",
"Pacific/Kosrae": "Pacific/Kosrae",
"Pacific/Kwajalein": "Pacific/Kwajalein",
"Pacific/Majuro": "Pacific/Majuro",
"Pacific/Marquesas": "Pacific/Marquesas",
"Pacific/Midway": "Pacific/Midway",
"Pacific/Nauru": "Pacific/Nauru",
"Pacific/Niue": "Pacific/Niue",
"Pacific/Norfolk": "Pacific/Norfolk",
"Pacific/Noumea": "Pacific/Noumea",
"Pacific/Pago_Pago": "Pacific/Pago_Pago",
"Pacific/Palau": "Pacific/Palau",
"Pacific/Pitcairn": "Pacific/Pitcairn",
"Pacific/Pohnpei": "Pacific/Pohnpei",
"Pacific/Ponape": "Pacific/Ponape",
"Pacific/Port_Moresby": "Pacific/Port_Moresby",
"Pacific/Rarotonga": "Pacific/Rarotonga",
"Pacific/Saipan": "Pacific/Saipan",
"Pacific/Samoa": "Pacific/Samoa",
"Pacific/Tahiti": "Pacific/Tahiti",
"Pacific/Tarawa": "Pacific/Tarawa",
"Pacific/Tongatapu": "Pacific/Tongatapu",
"Pacific/Truk": "Pacific/Truk",
"Pacific/Wake": "Pacific/Wake",
"Pacific/Wallis": "Pacific/Wallis",
"Pacific/Yap": "Pacific/Yap",
"Poland": "Polsko",
"Portugal": "Portugalsko",
"ROC": "ROC",
"ROK": "ROK",
"Singapore": "Singapur",
"Turkey": "Turecko",
"UCT": "UBT",
"US/Alaska": "US/Aljaška",
"US/Aleutian": "US/Aleutian",
"US/Arizona": "US/Arizona",
"US/Central": "US/Centrální",
"US/East-Indiana": "USA/Východní Indiana",
"US/Eastern": "US/Východ",
"US/Hawaii": "US/Havaj",
"US/Indiana-Starke": "US/Indiana-Starke",
"US/Michigan": "US/Michigan",
"US/Mountain": "US/hora",
"US/Pacific": "USA/Pacifik",
"US/Samoa": "US/Samoa",
"UTC": "UTC",
"Universal": "Univerzální",
"W-SU": "W-SU",
"WET": "MOKRÝ",
"Zulu": "Zulu",
}