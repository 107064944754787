import { View } from 'react-native'
import React from 'react'
import TimeZoneMismatchAlert from './TimeZoneMismatchAlert'
import TimeZoneNoAlert from './TimeZoneNoAlert'
import NoEnoughDiskSpaceAlert from './NoEnoughDiskSpaceAlert'
import PropTypes from 'prop-types'

const AppAlerts = (props) => {
    const {t, navigation, appStatus, subjectStudyMetaData, setOrAckTimezoneChange, isTimeZoneNoClicked, configuredValue, isTimeZoneNoClickedProp, isStorageAvailable,
    isSpaceAlertAcknowledged}  = props
  return (
    <View style={{ marginTop: 5 }}>
        {/* ----------When subject's timezone is different from selected timezone----------  */}
        <TimeZoneMismatchAlert
            t={t}
            navigation={navigation}
            appStatus ={appStatus}
            subjectStudyMetaData={subjectStudyMetaData}
            setOrAckTimezoneChange ={setOrAckTimezoneChange}
            isTimeZoneNoClicked = {isTimeZoneNoClicked}
          />
        
        { isTimeZoneNoClickedProp &&
                (<TimeZoneNoAlert
                t={t}
                navigation={navigation}
                configuredValue ={configuredValue}
                appStatus ={appStatus}
                subjectStudyMetaData={subjectStudyMetaData}
                setOrAckTimezoneChange ={setOrAckTimezoneChange}
                isTimeZoneNoClicked = {isTimeZoneNoClicked}
                />)}

        {/* ----------When there is no enough room to download diary images in background---------- 
          Restricting the popup to display in PIN screen
        */}
        <NoEnoughDiskSpaceAlert 
        t={t}
        isStorageAvailable={isStorageAvailable}
        isSpaceAlertAcknowledged={isSpaceAlertAcknowledged}
        currentScreen ={appStatus.currentScreen}
        />
    </View>
  )
}

AppAlerts.defaultProps = {
  appStatus: { currentScreen: ''},
}

AppAlerts.propTypes = {
  appStatus: PropTypes.instanceOf(Object),
}


export default AppAlerts