import React from "react"
import { PixelRatio, StyleSheet, View } from "react-native"
import { alignments } from "../../scales/alignments"
import { Slider } from '@react-native-assets/slider'
import TrackMarksContainer from "./TrackMarksContainer"
import constants from "./constants"
import PropTypes from 'prop-types'
const {SHOW_MARKS} = constants
const BasicVASScale = (props) => {
    const {step, min, max, onValueChange, disabled, id, value, isPercentage, indicationConfig, showMarks, orientation, fontConfig, scaleProps} = props
    const {scaleWidth} = scaleProps
    const [selectedValue, setSelectedValue] = React.useState(value);
    React.useEffect(() => {
        setSelectedValue(value)
    }, [value])
    const displayMarks = (obj) => {
        return (
            <TrackMarksContainer
                value={value}
                index={obj.value}
                min={min}
                max={max}
                showMarks={showMarks}
                step={step}
                orientation={orientation}
                indicationConfig={indicationConfig}
                isPercentage={isPercentage}
                requiredFontForMarks={fontConfig.requiredFontForMarks}
                numberOfTerms={fontConfig.numberOfTerms}
                {...props}
            />
        )
    }
    
    const displayThumb = ({value: thumbVal}) => {
        return (<View style={{
            borderRightWidth: 0,
            height: thumbVal === value ? 0 : 5,
            width: thumbVal === value ? 0 : 5,
            borderRadius: 1,
            backgroundColor: '#c5c5c5',
            alignSelf: 'flex-start'
        }}
            accessible = {!disabled}
            testID= {`${id}-slider-thumb`}></View>)
    }
    const onValueSelect = (v) => {
      onValueChange(v)
    }

    let sliderStyle = {width: scaleWidth}
    if(orientation === alignments.VERTICAL){
        sliderStyle = {height: scaleWidth}
    }


    return (
        <View style={styles.container}>
            <Slider 
                minimumValue={min}
                maximumValue={max}
                step={0.1}
                minimumTrackTintColor="#c5c5c5"
                maximumTrackTintColor="#c5c5c5"
                vertical={orientation === alignments.VERTICAL}                
                inverted={orientation === alignments.VERTICAL}              
                enabled={!disabled}
                trackHeight={5}
                thumbSize={5}
                slideOnTap={true}
                style={sliderStyle}
                CustomMark={displayMarks}
                onValueChange={onValueSelect}
                CustomThumb={displayThumb}
                value={selectedValue}
                accessible = {!disabled}
                testID= {`${id}-scale-container`}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
BasicVASScale.defaultProps = {
    step: 1,
    min: 0,
    max: 10,
    onValueChange: ()=> null,
    id: null,
    disabled: false,
    value: null,
    isPercentage: false,
    indicationConfig: {
        indicationMultiple: null,
        indicationNumbers: null
    },
    showMarks: SHOW_MARKS.NO_MARKS,
    orientation: alignments.HORIZONTAL,
    fontConfig: {
        requiredFontForMarks: 10/PixelRatio.getFontScale(),
        numberOfTerms: 0,
      },
      scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: 10/(PixelRatio.getFontScale()),
        scale: 1,
      } ,

  }
  BasicVASScale.propTypes = {
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    isPercentage: PropTypes.bool,
    indicationConfig: PropTypes.shape({
        indicationMultiple: PropTypes.number,
        indicationNumbers: PropTypes.string,
    }),
    showMarks: PropTypes.string,
    orientation: PropTypes.string,
    id: PropTypes.string,
    fontConfig: PropTypes.shape({
        requiredFontForMarks: PropTypes.number,
        numberOfTerms: PropTypes.number,
      }),
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
    }),


  }
export default BasicVASScale