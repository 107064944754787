import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FcCalendar } from 'react-icons/fc'
import './DatePicker.css'
import moment from 'moment'
import './customDatePickerWidth.css'

function WebDatePicker({ onDateChange, date, testID }) {
  function CustomInput({ value, onClick }) {
    return (
      <div className="dateInput" onClick={onClick} style={{ width: 180 }}>
        <div style={{ display: 'inline-block', paddingRight: 10 }}>
          <FcCalendar size="1.1em" />
        </div>
        <div style={{ display: 'inline-block' }}>{moment.utc(date).format('DD-MMM-yyyy')}</div>
      </div>
    )
  }

  return (
    <div
      className="customWdth"
      style={{
        alignContent: 'center',
        width: 185,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      testID={testID}
      accessible={true}
    >
      <DatePicker
        selected={moment.utc(date).toDate()}
        dateFormat="dd-MMM-yyyy"
        onChange={(date) => {
          onDateChange(date)
        }}
        customInput={<CustomInput />}
        showMonthDropdown
        showYearDropdown
        style={{ fontSize: 16, fontFamily: 'Inter' }}
        portalId="root-calendar"
      />
    </div>
  )
}

export default WebDatePicker
