import timezoneLocaleEn from "./timezoneLocale-en"
export default {
  ...timezoneLocaleEn,
  LoginTitle: 'Alpha Clinical Systems',
  LoginACSUserID: 'Enter your 10-Digit Phone Number.',
  LoginACSPwd: 'Password',
  LoginACSLogin: 'Login',
  LoginACSFrgtPwd: 'Forgot Password ?',
  LoginACSVersn: 'Version ',
  HomeTitle: 'Home',
  HomePull: 'Pull to refresh',
  HomeGM: 'Good Morning',
  HomeTxt:
    'Your answers are very important for the research of the study you are participating in.',
  HomeCompli: 'Compliance for each period',
  HomeTotal: 'Total',
  HomeToday: 'Today',
  HomeCrntWeek: 'This Week',
  HomeCrntMon: 'This Month',
  Homemsgs: 'Messages',
  HomeNewmsgs: 'You have new messages',
  'Homemsgs sub txt': 'No new messages',
  HomePatnt_Diary: 'Diary',
  HomePatnt_Diary_sub_txt: 'No new reminders',
  Calls: 'Calls',
  Chat: 'Chat',
  'HomeQlty of Life': 'Health Tracker',
  'HomeQlty of Life sub txt': 'No recent activity',
  'HomeVst Remnders': 'Visit Reminders',
  'HomeVst Remnders sub txt': 'No new reminders',
  HomeTabHome: 'Home',
  HomeTabmsgs: 'Messages',
  Form: 'Form',
  HomeTabCalndr: 'Calendar',
  MessagesTitle: 'Messages',
  'Patient DiaryTitle': 'Diary',
  'Visit CalendarTitle': 'Visit Calendar',
  'Qlty of LfeChoose optn': 'Please choose your option',
  'Qlty of LfeWithings': 'Withings',
  'Qlty of LfeFitbit': 'Fitbit',
  WithingsTitle: 'Activity',
  'WithingsActivity Tab': 'Activity',
  'WithingsHeart Rate Tab': 'Heart Rate',
  'WithingsHeart Rte TabHeart Rate': 'Heart Rate',
  'WithingsHeart Rte TabThis week': 'This Week',
  'WithingsHeart Rte TabSubmit': 'Submit',
  FitbitTitle: 'Activity',
  'FitbitActivity Tab': 'Activity',
  'FitbitHeart Rate Tab': 'Heart Rate',
  'FitbitHeart Rte TabHeart  Rate': 'Heart Rate',
  'FitbitHeart Rte TabThis week': 'This Week',
  'FitbitHeart Rte TabSubmit': 'Submit',
  Actn_sheetMore: 'More',
  Actn_sheetStudy: 'Study',
  Actn_sheetContact: 'Contact',
  Actn_sheetFAQ: 'FAQ',
  Actn_sheetChange_Language: 'Select Language',
  ChangeLanguage: 'Select language',
  StudyTab1Title: 'Study Information',
  StudyTab1Txt: 'Welcome to our study',
  'StudyTab2Brf Dctn': 'Brief Description',
  'StudyTab3Detail Dctn': 'Detailed Description',
  'StudyTab4Anticipated prblms': 'Anticipated Problems',
  'StudyTab5Withdrn rule': 'Withdrawal Rules',
  StudyTab6Video: 'Video',
  'StudyTab6View Video': 'View video about study',
  StudyTab6Joined: 'Already joined',
  ContactTitle: 'Contact Information',
  ContactSiteAddress: 'Site Address',
  ContactPI: 'Principal Investigator',
  FAQTitle: 'FAQ',
  FieldPrevious: 'Previous',
  FieldNext: 'Next',
  PainscaleText1: 'We would like to know how good or bad your health is TODAY.',
  PainscaleText2: 'Please tap on the scale to indicate how your health is TODAY.',
  PainscaleSubmit: 'Submit',
  PainscaleHealthToday: 'YOUR HEALTH TODAY',
  PainscaleHealthToday1: 'YOUR',
  PainscaleHealthToday2: 'HEALTH',
  PainscaleHealthToday3: 'TODAY',
  ClickableImageMessage: 'Please tap on the image to select the response.',
  tmlnallday: 'All Day',
  painmax: 'Worst Possible Pain',
  painmin: 'No Pain',
  completedat: 'Completed at ',
  EventsforDay: 'Events for the day',
  NoEventsforDay: 'No event for the selected date.',
  TODAY: 'Today',
  MONTH: 'Month',
  WEEK: 'Week',
  DAY: 'Day',
  ChangeTheme: 'Change Theme',
  MessageSent: 'Sent on',
  MessageRead: 'Read on',
  EventCalendar: 'Event Calendar',
  Logout: 'Logout',
  USRNAMEWARNING: 'Please enter phone number.',
  PWDWARNING: 'Please enter password.',
  NoFormToFill: 'No forms are available on the selected date.',
  PIN: 'PIN',
  EnterOTP: 'Enter OTP',
  OtpEmpty: 'OTP must not be empty.',
  WrongOTP: 'Please enter valid OTP.',
  VerifyOTP: 'Verify OTP',
  ResendOTP: 'Resend OTP',
  NumberValidate1: 'Enter value between',
  NumberValidate2: 'and',
  ResetPassword: 'Reset password',
  ChangePassword: 'Change password',
  NewPassword: 'New Password',
  ConfirmPassword: 'Confirm Password',
  PSWD_NOT_MATCH: 'Passwords did not match.',
  PSWD_VALIDATION: 'Password did not match criteria.',
  PSWD_MIN_LENGTH: '- Password must be at least 8 and not exceed 64 characters.',
  PSWD_MAX_LENGTH: '- Password should not exceed 64 characters.',
  PSWD_ONE_NUMBER: '- Password needs to include at least one number.',
  PSWD_ONE_SC: '- Password needs to include at least one special character.',
  PSWD_ONE_UPPER: '- Password needs to include at least one uppercase letter.',
  PSWD_NO_SPACES: '- Password should not include spaces.',
  PSWD_RESTRICT: '- Password should not be sequential and repetitive characters',
  PSWD_NO_USERNAME: '- Password should not include name of the user.',
  PSWD_NO_EMAILNAME: '- Password should not include email account name.',
  PSWD_NO_SITENAME: '- Password should not include site name.',
  PSWD_NO_ORGNAME: '- Password should not include organization name.',
  PSWD_NOTE: 'Password Criteria:',
  Save: 'Save',
  ForgotPSWD: 'Forgot your password ?',
  OTPMSG: 'We will send an SMS to your phone number with One Time Password to reset your password.',
  FP_PNO: 'Submit your registered phone number with country code.',
  PNO_Vald: 'Phone number must not be empty.',
  Submit: 'Submit',
  Cancel: 'Cancel',
  Prev: 'Previous',
  Next: 'Next',
  NoPain: 'No Pain',
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
  Worst: 'Worst',
  SetAppLock: 'Set Passcode',
  AppLock: 'Passcode',
  EnterPIN: 'Enter PIN',
  MinPin: 'Your PIN must be between 4 and 6 digits.',
  WrongPIN: 'Wrong PIN.',
  PINS_NOT_MATCH: 'PINs did not match.',
  ConfirmPIN: 'Confirm PIN',
  OK: 'OK',
  Reset: 'Reset',
  Confirm: 'Confirm',
  SelTimezone: 'Select Timezone',
  CompletedOn: 'Completed on',
  BScaleValMsg: 'Please rate your pain.',
  BarcodeValMsg: 'Please scan a barcode to continue.',
  DateValMsg: 'Please select a date.',
  SingSelValMsg: 'Please select an option.',
  MultSelValMsg: 'Please select at least one option.',
  TextValMsg: 'Please fill this field.',
  PswdEmpty: 'Password must not be empty.',
  CnfPswdEmpty: 'Please confirm your password.',
  SelectTimezone: 'Select Timezone',
  Timezone: 'Timezone',
  LoginPhPW: 'Login with phone number and password',
  PhoneNotReg: 'Your phone number is not registered. Please check the number and try again.',
  SomethingWrong: 'Something went wrong. Please contact your investigator.',
  PswdResetSuccess: 'Password has been reset successfully.',
  PswdResetFailure: 'Failed to reset the password.',
  SavedMessage: 'Saved successfully.',
  FailedMessage: 'Failed to save. Please try again after sometime.',
  FailedRetrieve: 'Failed to retrieve.',
  FailedResetPWD: 'Failed to reset password.',
  FailedRetrieveMsgs: 'Failed to retrieve messages.',
  FailedRetrieveFaqs: 'Failed to retrieve faqs.',
  FailedRetrieveFields: 'Failed to retrieve fields.',
  FailedRetrieveForms: 'Failed to retrieve forms.',
  FailedRetrieveTimezones: 'Failed to retrieve timezones.',
  FailedRetrieveSubjects: 'Failed to retrieve subjects.',
  FailedChangeLanguage: 'Failed to change language.',
  FailedUpdateTimeZone: 'Failed to update timezone.',
  NetworkError: 'Network error.',
  LogoutMessage: 'You are logging out of Tryal eCOA. Do you want to continue?',
  YES: 'Yes',
  NO: 'No',
  InvPhonePSWD: 'Invalid phone number or password.',
  InvPhonePSWDWeb: '<p style="font-weight:normal"><b>Invalid phone number or password.</b><br/>If you are a clinician, click the link below to access the clinician login page.</p>',
  NotValidPhone: 'You did not enter a valid phone number.',
  ReqNumericField: 'Please fill this numeric field.',
  OTPResend: 'OTP Resent.',
  SelectDate: 'Select Date',
  EnterHere: 'Enter here',
  NOMSG: 'Sorry, no message to show.',
  NoData: 'No data.',
  UserLocked: 'User Locked. Please contact your administrator.',
  LanguageValidate: 'Please select a language',
  OfflineMessage: 'Network connection Lost. Switching to offline mode.',
  Offline: 'OFFLINE',
  Online: 'ONLINE',
  savedLocally: 'Saved locally.',
  formsToSync: 'Form(s) to sync.',
  formToSync: 'Form To Sync.',
  showingOfflineForms: 'Showing Offline Data. Please check your internet connection.',
  NoForm: 'No form to fill.',
  NoFaqs: 'No faqs available.',
  HealthMenu: 'Health Data',
  myProfile: 'My Profile',
  subjectInformation: 'Subject Information',
  studyInformation: 'Study Information',
  versionInformation: 'Version Information',
  LoggedUser: 'Logged in User',
  StudyDetails: 'Study',
  VersionDetails: 'Version Info',
  LoggedInUser: 'Logged In User',
  Study: 'Study',
  VersionDetails: 'Version Details',
  Syncing: 'Syncing . . .',
  SyncSuccess: 'Synced successfully.',
  MaxAttemptsDone: 'Max Attempts Done.',
  LastSynced: 'Last Synced',
  bmi: 'Body Mass Index',
  Height: 'Height',
  Weight: 'Weight',
  LeanBodyMass: 'Lean Body Mass',
  BodyFat: 'Body Fat Percentage',
  Activity: 'Activity',
  Vitals: 'Vitals',
  Biometrics: 'Biometrics',
  HeartRate: 'Heart Rate',
  BloodPressure: 'Blood Pressure',
  RespirationRate: 'Respiration Rate',
  BodyTemperature: 'Body Temperature',
  BloodGlucose: 'Blood Glucose',
  Sex: 'Sex',
  SleepAnalysis: 'Sleep Analysis',
  ActiveEnergy: 'Active Energy',
  FlightsClimbed: 'Flights Climbed',
  Distance: 'Distance',
  Steps: 'Steps',
  StepUnits: 'steps',
  DistanceUnits: 'miles',
  FloorUnits: 'floors',
  ActiveEnergyUnits: 'cals',
  SleepAnalysisUnits: 'hrs',
  MeetingSchedule: 'Meeting Schedule',
  UpcomingCalls: 'Upcoming Calls',
  PastCalls: 'Past Calls',
  NoCallScheduled: 'There are no upcoming calls right now.',
  NoPastCalls: 'There are no past calls.',
  NoInternet: 'No internet connection.',
  NoChats: 'No chats to display.',
  SelectChat: 'Please select a chat or start a new chat.',
  NoUsersFound: 'No Users Found',
  Done: 'Done',
  AddParticipants: 'Add Participants',
  SearchParticipants: 'Search Participants',
  LeaveGroup: 'Leave Group',
  Participants: 'Participants',
  participants: 'participants',
  Remove: 'Remove',
  Others: 'others',
  Add: 'Add',
  Notifications: 'Notifications',
  CallDetails: 'Call Details',
  Participants: 'Participants',
  CallHistory: 'Call History',
  JoinCall: 'Join Call',
  MissedCall: 'You missed this call.',
  CallInProgress: 'Call is in progress.',
  CallNotStarted: 'Call not yet started.',
  JoinedOn: 'Joined on',
  LeftOn: 'Left on',
  Duration: 'Duration',
  ScheduledDuration: 'Scheduled Duration',
  Title: 'Title',
  StartedOn: 'Started on',
  Status: 'Status',
  ScheduledTime: 'Scheduled Time',
  Alerts: 'Alerts',
  Reminders: 'Reminders',
  Notifications: 'Notifications',
  VersionInfo: 'Version Info',
  FormPreview: 'Form Preview',
  NRSNumber: 'Please select the number.',
  SubmitTraining: 'Submit Training Form',
  TrainingNotCompleted: 'Please complete the training before you start your diary.',
  StartTraining: 'Start Training',
  ViewCompletedTraining: 'View Completed Training',
  CompleteTraining: 'Please complete the training to access the diary forms.',
  Training: 'Training',
  SITESTAFFUSRNAMEWARNING: 'Please enter user name',
  SITESTAFFPINWARNING: 'Please enter PIN',
  LoginSiteStaffUsrName: 'User Name',
  LoginSiteStaffPin: 'PIN',
  InvUsrNmPIN: 'Invalid user name or PIN',
  InvPasscode: 'Enter valid Site Identifier.',
  EnterPasscode: 'Enter TRYAL Site Identifier',
  EnterUsername: 'Enter Username',
  EnterPassword: 'Enter Password',
  EnterValidUname: 'Enter valid Username',
  EnterValidPassword: 'Enter valid Password',
  FirstName: 'First Name',
  LastName: 'Last Name',
  UserID: 'User ID',
  Site: 'Site',
  MyAccount: 'My Account',
  StudyList: 'Study List',
  SubjectList: 'Subject List',
  SelectFormCategory: 'Select Form Category',
  LoginWithPIN: 'Login with Username and PIN',
  VersionInfo: 'Version Info',
  Email: 'Email',
  SessionTimeoutMessage: 'Your session has timed out',
  FillRequiredFields: 'Please fill all the required fields',
  InvalidPasscode: 'Invalid Site Identifier.',
  SomethingWentWrong: 'Something went wrong.',
  InvalidUsernamePassword: 'Invalid Username or Password.',
  InvalidUsernamePasswordWeb:
    '<p style="font-weight:normal"><b>Invalid username or password.</b><br/>If you are a participant or caregiver, click the link below to access the participant login page.</p>',
  ScheduleStartDate: 'Schedule Start Time',
  ScheduleEndDate: 'Schedule End Time',
  VisitNotStarted: 'Visit window has not yet started.',
  VisitWindowClosed: 'The visit window has passed, you may not enter data.',
  VisitCompleted: 'Visit is completed.You are not supposed to update any questionnaires.',
  ShowAlertMsg: 'You want to save your changes?',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  language: 'Language',
  study: 'Study',
  sponsor: 'Sponsor',
  versionName: 'Version Name',
  Visit: 'Visit',
  StartTime: 'Start Time',
  EndTime: 'End Time',
  VersionUpgradeInfo: 'eDiary Version has been upgraded to Version ',
  New: 'New',
  NoDiary: 'No Diary/Questionnaires are available. Please contact your Site Staff',
  UserInactive: 'Your access is inactivated. Please contact your Study Coordinator.',
  SubjectInactive: 'Subject access is inactivated. Please contact your Study Coordinator.',
  ProcessMessage: 'Processing the questionnaire.',
  LoadingCallData: 'Loading Call data',
  DownloadMetadata: 'Downloading meta data',
  DownloadStudydata: 'Downloading Study Data.',
  DownloadQuestionnaries: 'Downloading questionnaires',
  FailedRetrieveStudy: 'Failed to download study details.',
  SubjectCaregiverInactive:
    'Subject is not Active. Please contact your Study Coordinator for further information.',
  OnceFrequencyScheduleStartDate: 'Schedule Start Date',
  OnceFrequencyScheduleEndDate: 'Schedule End Date',
  Click: 'Click',
  LoginText: 'to log in again',
  TermsAndConditions: 'Privacy Policy and Terms & Conditions',
  TermsNConditionsCancel:
    ' You must agree to the privacy policy and terms & conditions to continue using this application',
  UnsyncedData:
    'You have unsynced data on this device. You cannot log out until you have synced your data. Please contact your site if you have questions.',
  ChangeToClinician: 'Change to Clinician Login',
  ParticipantLogin: 'Participant Login',
  ClinicianLogin: 'Clinician Login',
  SwitchToParticipant: 'Switch To Participant',
  OrgText1: 'If you would like to log into a different organization,',
  OrgText2: 'please access the link in the admin portal',
  BackToLogin: 'Back To Login',
  GoToPortal:
    'There are required actions on your account. Please login through the Admin Portal to complete your account actions.',
  Organization: 'Organization',
  NoClinroAccessPrivilege:
    'You do not have access to enter ClinRo data. Please contact your CRA or Administrator if you have questions.',
  DeviceTimezoneMismatchTitle:
    "It appears that your device's timezone does not match your configured timezone in this app.",
  DeviceTimezoneMismatchSubtitle: 'Would you like to change your timezone?',
  DeviceTimezoneMismatchTzConfigured: 'Your configured timezone: ',
  DeviceTimezoneMismatchTzInferred: "Your device's timezone: ",
  SiteIdentifierInfo: '<p>Find your TRYAL <b>Site Identifier</b> in the portal on the Subject List Page</p>',
  LoginUnsyncData: 'There is data that needs to be synced on your device. Please enter your password so the data can be transferred. Please contact support if you have questions.',
  DeviceTimezoneNoClicked: 'You can change your timezone at any time by going to "More" at the bottom of this screen.',
  AgreeingToPrivacypolicy: 'Agreeing to Privacy policy and terms & condtions is mandatory.',
  ReadAndAgree: 'I read and agree with the terms and conditions',
  IAgree: 'I Agree',
  SubjectNumber: 'Subject Number',
  Version: 'Version',
  Back: 'Back',
  Visits: 'Visits',
  DownloadImageFailedDeviceOffline: 'This diary contains images incompletely downloaded to your device, please connect to the internet and select this diary again.',
  DownloadImageFailedNoEnoughSpace: 'There is not enough space on your device to download one or more diaries. Please free up some space and try again.',
  ImageLoading: 'Loading image...',
  Barcode:'Barcode',
  BarcodeMessage:'Barcode scanning is not currently supported on web. Please use a mobile device to scan barcodes',
  ScanAgain:'Scan Again',
  SelectedValue:'Selected Value'
}
