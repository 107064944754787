import _ from 'lodash'
import { createSelector } from 'reselect'
import storage from '../utils/storage/storage'
import { getCurrentUser, getCurrentUserId } from './user'

const getStudyData = (state) => state.study.data
const getStudyState = (state) => state.study
export const getActiveStudyId = (state) => state.study.selectedStudyId
export const getStudyInContext = createSelector([getCurrentUser], (user) => {
  return user && user.sessionInfo ? user.sessionInfo.studyId : null
})
// export const getStudyInContext = (state) => {
//   console.log('from nav...')
//   return '922F5CBF-F13E-449F-8210-8AC1994513F5'
// }
export const getStudiesOfUser = createSelector([getStudyData, getCurrentUserId], (data, userId) => {
  return data[userId]
})
export const getSearchTerm = createSelector([getCurrentUserId, getStudyState], (userId, study) => {
  return _.get(study, `searchTerm[${userId}]`, '')
})

export const getFilteredStudies = createSelector(
  [getStudiesOfUser, getSearchTerm],
  (studies, searchTerm) => {
    if (!studies) {
      return []
    } 
    if (searchTerm) {
      return studies.filter((study) => _.includes(_.lowerCase(study?.studyNumber), _.lowerCase(searchTerm)))
    } else {
      return studies
    }
  }
)

export const getActiveStudy = createSelector(
  [getActiveStudyId, getStudiesOfUser],
  (studyId, studies) => {
    if (studyId) {
      return _.find(studies, (std) => std.id === studyId)
    }
    return []
  }
)
