import _ from 'lodash'
import {
    RETRIEVE_ALL_CRF_DATA_SUCCESS_FORMPRINT,
    RETRIEVE_ALL_CRF_DATA_REQUEST_FORMPRINT, 
    RETRIEVE_ALL_CRF_DATA_FAILURE_FORMPRINT,
    RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT,
    RETRIEVE_FORMS_WITH_FIELDS_SUCCESS_FORMPRINT,
    RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT,
    SAVE_FIELD_LIST_OF_MULTIPLE_FORM_FORMPRINT,
    UPDATE_SELECTED_VISIT_FROM_ID_FORMPRINT
} from '../actions/formPrint'
import initialState from '../store/initialState'

export default (state = initialState.formPrint, action) => {
  switch (action.type) {
    case RETRIEVE_ALL_CRF_DATA_SUCCESS_FORMPRINT: {
      return {
        ...state,
        formAnswers: {
        data: action.formAnswers,
        loading: false,
        }
      }
    }
    case RETRIEVE_ALL_CRF_DATA_REQUEST_FORMPRINT: {
        return {
          ...state,
          formAnswers: {
          ...state.formAnswers,
          loading: true,
          }
        }
      }
      
    case RETRIEVE_ALL_CRF_DATA_FAILURE_FORMPRINT: {
        return {
          ...state,
          formAnswers: {
            ...state.formAnswers,
          loading: false,
          }
        }
      }
      case RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT: {
        return{
            ...state,
            formListLoading: true,
        }
      }
      case RETRIEVE_FORMS_WITH_FIELDS_SUCCESS_FORMPRINT: {
        return{
            ...state,
            formListLoading: false,
            formList: action.formList
        }
      }
      case RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT: {
        return{
            ...state,
            formListLoading: false,
        }
      }
      case SAVE_FIELD_LIST_OF_MULTIPLE_FORM_FORMPRINT: {
        return{
            ...state,
            field: {
                fieldListOfMultipleForms: action.fieldListOfForms,
            }
        }
      }
      case UPDATE_SELECTED_VISIT_FROM_ID_FORMPRINT:
      return {
        ...state,
        selectedVisitFormOid: action.visitFormOid,
        selectedSvfId: action.svfId,
      }
    default:
      return state
  }
}
