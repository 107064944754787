import _ from 'lodash'
import { OFFLINE_DATA_RETRIEVE_SUCCESS } from '../actions/login'
import {
  UPDATE_FIELDS,
  UPDATE_SITE,
  UPDATE_TRAINING_STATUS,
  UPDATE_STUDY_META_DATA,
  STUDY_DETAILS_RETRIEVE_REQUEST,
  STUDY_DETAILS_RETRIEVE_SUCCESS,
  STUDY_DETAILS_RETRIEVE_FAILURE,
  UPDATE_PRIVACY_POLICY,
  UPDATE_RESET_PASSWORD,
  UPDATE_PASSWORD_EXPIRY,
  UPDATE_IS_SUBJECT_REGISTERED
} from '../actions/subjectStudyMetaData'
import {
  RETRIEVE_VISITS_FAILURE,
  RETRIEVE_VISITS_REQUEST,
  RETRIEVE_VISITS_SUCCESS,
  UPDATE_SELECTED_VISIT,
} from '../actions/visits'
import {
  CAREGIVER_SUBJECT_RETRIEVE_BY_ID_REQUEST,
  CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS,
} from '../actions/caregiver'
import { GET_META_DATA_SUCCESS } from '../actions/metaData'
import { UPDATE_TIMEZONE_SUCCESS } from '../actions/timeZone'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

export default (state = initialState.subjectStudyMetaData, action) => {
  switch (action.type) {
    case OFFLINE_DATA_RETRIEVE_SUCCESS:
      return {
        ...state,
        ...action.offlineData,
        loading: false,
      }
    case UPDATE_SITE: {
      // console.log(state)
      return {
        ...state,
        studySite: {
          ...state.studySite,
          client: action.client,
        },
      }
    }
    case UPDATE_FIELDS: {
      return {
        ...state,
        ...action.data,
      }
    }
    case UPDATE_TRAINING_STATUS: {
      return {
        ...state,
        trainingCompleted: action.status,
      }
    }
    case RETRIEVE_VISITS_REQUEST:
      return {
        ...state,
        subject: {
          ...state.subject,
          subjectVisits: {
            ...state.subject.subjectVisits,
            loading: true,
          },
          loading: true,
        },
      }
    case RETRIEVE_VISITS_SUCCESS:
      return {
        ...state,
        subject: {
          ...state.subject,
          subjectVisits: {
            ...state.subject.subjectVisits,
            loading: false,
            visits: action.visits,
            currentVisit: _.find(action.visits, (sv) => sv.isCurrent),
          },
        },
        loading: false,
      }
    case RETRIEVE_VISITS_FAILURE:
      return {
        ...state,
        subject: {
          ...state.subject,
          subjectVisits: {
            ...state.subject.subjectVisits,
            loading: false,
          },
          loading: false,
        },
      }
    case UPDATE_SELECTED_VISIT:
      return {
        ...state,
        subject: {
          ...state.subject,
          subjectVisits: {
            ...state.subject.subjectVisits,
            selectedVisit: action.selectedVisit,
          },
        },
      }
    case UPDATE_STUDY_META_DATA:
      return {
        ...state,
        loading: false,
        study: {
          ...action.data,
        },
      }
    case CAREGIVER_SUBJECT_RETRIEVE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CAREGIVER_SUBJECT_RETRIEVE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.subject,
        resetPassword: state.resetPassword,
        passwordExpired: state.passwordExpired,
        privacyPolicy : state.privacyPolicy,
      }
    case STUDY_DETAILS_RETRIEVE_REQUEST:
      return {
        ...state,
        loading: true,
        dataFailed: false,
      }
    case STUDY_DETAILS_RETRIEVE_SUCCESS:
      return {
        ...state,
        loading: false,
        dataFailed: false,
        study: action.data,
      }
    case STUDY_DETAILS_RETRIEVE_FAILURE:
      return {
        ...state,
        loading: false,
        dataFailed: true,
      }
    case GET_META_DATA_SUCCESS:
      return {
        ...state,
        study: {
          ...state.study,
          crfVersion: _.find(action.metaData?.versions, v => _.isEqual(v.id, state.subject?.currentCrfVersion?.id )),
        },
        subject: {
          ...state.subject,
          lastUpdatedDateOn: action.metaData?.lastUpdatedDateOn,
        }
      }
      case UPDATE_TIMEZONE_SUCCESS:
       return {
          ...state,
          subject: {
            ...state.subject,
            timeZone: action?.timezoneData?.timeZone,
          },
        }
    case PURGE:
      return {
        subject: {
          subjectVisits: { loading: false, selectedVisit: '', visits: [] },
        },
        studySite: {
          client: {},
        },
        subjectVisit: {},
        loading: false,
      }
      case UPDATE_PRIVACY_POLICY:
       return {
          ...state,
          privacyPolicy: {
            ...state.privacyPolicy,
            agreedToTerms: action?.agreedToTerms,
          },
        }
        case UPDATE_RESET_PASSWORD:
       return {
          ...state,
          resetPassword : action.resetPassword,
        }
        case UPDATE_PASSWORD_EXPIRY:
       return {
          ...state,
          passwordExpired : action.passwordExpired,
        }
        case UPDATE_IS_SUBJECT_REGISTERED:
          return {
             ...state,
             isSubjectRegistered : action.isSubjectRegistered,
           }
    default:
      return state
  }
}
