import { View , Text,  StyleSheet} from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux';


const PhoneNoTextStyles = StyleSheet.create({
  inputText: {
    color: 'grey',
    fontFamily: 'Inter',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 20,
  },
  containerStyle: {alignSelf: 'center', marginTop: 20, marginBottom: 20},
  msgText: {
    fontFamily: 'Inter',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 14,
    marginBottom: 20,
  },
})

const PhoneNoText = (props) => {
  const {t} = props
  const { phoneNo } = useSelector(state => state.syncQueue.data.sessionUser);
    return (
      <View>
        <Text style={PhoneNoTextStyles.msgText}>{t('LoginUnsyncData')}</Text>
        <View style={PhoneNoTextStyles.containerStyle}>
            <Text style={PhoneNoTextStyles.inputText}>Phone No: {phoneNo!=null ? phoneNo.replace(/\S(?=\S{4})/g, "\u2217"): '*********'}</Text>
        </View>
      </View>
    )
  
}

export default PhoneNoText