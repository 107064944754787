import timezoneLocaleSpanish from "./timezoneLocale-spanish";
export default {
  ...timezoneLocaleSpanish,
  LoginTitle: 'Alfa Clínico sistemas',
  LoginACSUserID: 'Número de teléfono',
  LoginACSPwd: 'Contraseña',
  LoginACSLogin: 'Iniciar sesión',
  LoginACSFrgtPwd: 'Olvidó Contraseña ?',
  LoginACSVersn: 'Versión',
  HomeTitle: 'Casa',
  HomePull: 'Halar a refrescar',
  HomeGM: 'Bueno Mañana',
  HomeTxt:
    'Sus respuestas son muy importantes para la investigación del estudio en el que está participando.',
  HomeCompli: 'Cumplimiento para cada período',
  HomeTotal: 'Total',
  HomeToday: 'Hoy',
  HomeCrntWeek: 'Esta semana',
  HomeCrntMon: 'Esta mes',
  Homemsgs: 'Mensajes',
  HomeNewmsgs: 'Tienes nuevos mensajes',
  'Homemsgs sub txt': 'no hay mensajes nuevos',
  HomePatnt_Diary: 'Diario',
  HomePatnt_Diary_sub_txt: 'Por favor complete los formularios.',
  Calls: 'llamadas',
  Chat: 'Charlar',
  'HomeQlty of Life': 'Rastreador de Salud',
  'HomeQlty of Life sub txt': 'No hay actividad reciente',
  'HomeVst Remnders': 'Visita Recordatorios',
  'HomeVst Remnders sub txt': 'No hay nuevos recordatorios',
  HomeTabHome: 'Casa',
  HomeTabmsgs: 'Mensajes',
  Form: 'Formar',
  HomeTabCalndr: 'Calendario',
  MessagesTitle: 'Mensajes',
  'Patient DiaryTitle': 'Diario del paciente',
  'Visit CalendarTitle': 'Visita el calendario',
  'Qlty of LfeChoose optn': 'Por favor elija su opción',
  'Qlty of LfeWithings': 'Withings',
  'Qlty of LfeFitbit': 'Fitbit',
  WithingsTitle: 'Actividad',
  'WithingsActivity Tab': 'Actividad',
  'WithingsHeart Rate Tab': 'Ritmo cardiaco',
  'WithingsHeart Rte TabHeart Rate': 'Ritmo cardiaco',
  'WithingsHeart Rte TabThis week': 'Esta semana',
  'WithingsHeart Rte TabSubmit': 'Enviar',
  FitbitTitle: 'Actividad',
  'FitbitActivity Tab': 'Actividad',
  'FitbitHeart Rate Tab': 'Ritmo cardiaco',
  'FitbitHeart Rte TabHeart  Rate': 'Ritmo cardiaco',
  'FitbitHeart Rte TabThis week': 'Esta semana',
  'FitbitHeart Rte TabSubmit': 'Enviar',
  Actn_sheetMore: 'Más',
  Actn_sheetStudy: 'Estudiar',
  Actn_sheetContact: 'Contacto',
  Actn_sheetFAQ: 'Preguntas más frecuentes',
  Actn_sheetChange_Language: 'Seleccione el idioma',
  ChangeLanguage: 'Seleccione el idioma',
  StudyTab1Title: 'Información del estudio',
  StudyTab1Txt: 'Bienvenido a nuestro estudio deslizar para ver',
  'StudyTab2Brf Dctn': 'Breve descripción',
  'StudyTab3Detail Dctn': 'Descripción detallada',
  'StudyTab4Anticipated prblms': 'Problemas anticipados',
  'StudyTab5Withdrn rule': 'Reglas de retiro',
  StudyTab6Video: 'Vídeo',
  'StudyTab6View Video': 'Ver video sobre el estudio',
  StudyTab6Joined: 'Ya se unió',
  ContactTitle: 'Información del contacto',
  ContactSiteAddress: 'Dirección del sitio',
  ContactPI: 'Investigador principal',
  FAQTitle: 'Preguntas más frecuentes',
  FieldPrevious: 'Anterior',
  FieldNext: 'Siguiente',
  PainscaleText1: 'Nos gustaría saber qué tan bueno o malo es su salud HOY.',
  PainscaleText2: 'Por favor, toque la escala para indicar cómo es su salud HOY.',
  PainscaleSubmit: 'Enviar',
  PainscaleHealthToday1: 'SU',
  PainscaleHealthToday2: 'SALUD',
  PainscaleHealthToday3: 'HOY',
  ClickableImageMessage: 'Por favor, toque la imagen para seleccionar la respuesta.',
  tmlnallday: 'Todo el dia',
  painmax: 'El peor dolor posible',
  painmin: 'Sin dolor',
  completedat: 'Completado en ',
  EventsforDay: 'Eventos para el día',
  NoEventsforDay: 'Ningún evento para la fecha seleccionada.',
  TODAY: 'Hoy',
  MONTH: 'Mes',
  WEEK: 'Semana',
  DAY: 'Día',
  ChangeTheme: 'Cambiar de tema',
  MessageSent: 'Enviado',
  MessageRead: 'Sigue leyendo',
  EventCalendar: 'Calendario de eventos',
  Logout: 'Cerrar sesión',
  USRNAMEWARNING: 'Por favor, introduzca el número de teléfono.',
  PWDWARNING: 'Por favor, ingrese contraseña.',
  NoFormToFill: 'No hay formularios disponibles en la fecha seleccionada.',
  PIN: 'PIN',
  EnterOTP: 'Ingrese la OTP',
  OtpEmpty: 'OTP no debe estar vacío.',
  WrongOTP: 'Por favor, introduzca una OTP válida.',
  VerifyOTP: 'Verificar OTP',
  ResendOTP: 'Reenviar OTP',
  NumberValidate1: 'Introduzca un valor entre',
  NumberValidate2: 'y',
  ResetPassword: 'Para restablecer la contraseña',
  ChangePassword: 'Cambiar la contraseña',
  NewPassword: 'Nueva contraseña',
  ConfirmPassword: 'Confirmar contraseña',
  PSWD_NOT_MATCH: 'Las contraseñas no coinciden.',
  PSWD_VALIDATION: 'La contraseña no coincide con los criterios.',
  PSWD_MIN_LENGTH: '- La contraseña debe ser de al menos 8 caracteres.',
  PSWD_MAX_LENGTH: '- La contraseña no debe exceder los 64 caracteres.',
  PSWD_ONE_NUMBER: '- La contraseña debe incluir al menos un número.',
  PSWD_ONE_SC: '- La contraseña debe incluir al menos un carácter especial.',
  PSWD_ONE_UPPER: '- La contraseña debe incluir al menos una letra mayúscula.',
  PSWD_NO_SPACES: '- La contraseña no debe incluir espacios.',
  PSWD_RESTRICT: '- La contraseña no debe contener caracteres secuenciales y repetitivos.',
  PSWD_NO_USERNAME: '- La contraseña no debe incluir el nombre del usuario.',
  PSWD_NO_EMAILNAME:
    '- La contraseña no debe incluir el nombre de la cuenta de correo electrónico.',
  PSWD_NO_SITENAME: '- La contraseña no debe incluir el nombre del sitio.',
  PSWD_NO_ORGNAME: '- La contraseña no debe incluir el nombre de la organización.',
  PSWD_NOTE: 'Criterios de contraseña:',
  Save: 'Guardar',
  ForgotPSWD: '¿Olvidó su contraseña ?',
  OTPMSG:
    'Vamos a enviar un SMS a tu número de teléfono con Una Contraseña para restablecer tu contraseña.',
  FP_PNO: 'Envíe su número de teléfono registrado con código de país.',
  PNO_Vald: 'Número de teléfono no debe estar vacío.',
  Submit: 'Enviar',
  Cancel: 'Cancelar',
  Prev: 'Anterior',
  Next: 'Siguiente',
  NoPain: 'No Hay Dolor',
  Mild: 'Leve',
  Moderate: 'Moderado',
  Severe: 'Grave',
  Worst: 'Peor',
  SetAppLock: 'Conjunto De acceso establecido',
  AppLock: 'Bloqueo de aplicación',
  EnterPIN: 'Introduzca el PIN',
  MinPin: 'Su PIN debe tener entre 4 y 6 dígitos.',
  WrongPIN: 'PIN equivocado.',
  PINS_NOT_MATCH: 'Los PIN no coincidieron.',
  ConfirmPIN: 'Confirmar PIN',
  OK: 'De acuerdo',
  Reset: 'Reiniciar',
  Confirm: 'Confirmar',
  SelTimezone: 'Seleccionar La Zona Horaria.',
  CompletedOn: 'Completado en',
  BScaleValMsg: 'Por favor califique su dolor.',
  BarcodeValMsg: 'Escanee un código de barras para continuar.',
  DateValMsg: 'Por favor seleccione una fecha.',
  SingSelValMsg: 'Por favor seleccione una opción.',
  MultSelValMsg: 'Por favor, seleccione al menos una opción.',
  TextValMsg: 'Por favor, rellene este campo.',
  PswdEmpty: 'La contraseña no debe estar vacía.',
  CnfPswdEmpty: 'Por favor, confirme su contraseña.',
  SelectTimezone: 'Selecciona la zona horaria',
  Timezone: 'Zona horaria',
  LoginPhPW: 'Inicia sesión con número de teléfono y contraseña.',
  PhoneNotReg:
    'Su número de teléfono no está registrado. Por favor, compruebe el número y vuelva a intentarlo.',
  SomethingWrong: 'Algo salió mal. Por favor, póngase en contacto con su investigador.',
  PswdResetSuccess: 'La contraseña ha sido restablecida con éxito.',
  PswdResetFailure: 'El restablecimiento de contraseña falló.',
  SavedMessage: 'Guardado correctamente',
  FailedMessage: 'Error al guardar. Por favor intente nuevamente después de algún tiempo.',
  FailedRetrieve: 'Error al recuperar',
  FailedResetPWD: 'El restablecimiento de contraseña falló.',
  FailedRetrieveMsgs: 'No se pueden recuperar los mensajes.',
  FailedRetrieveFaqs: 'No se pudieron recuperar las preguntas frecuentes.',
  FailedRetrieveFields: 'No se pueden recuperar los campos.',
  FailedRetrieveForms: 'No se pueden recuperar los formularios.',
  FailedRetrieveTimezones: 'Error al recuperar las zonas horarias.',
  FailedRetrieveSubjects: 'No se pueden recuperar los temas.',
  FailedChangeLanguage: 'No se pudo cambiar el idioma.',
  FailedUpdateTimeZone: 'Error al actualizar la zona horaria.',
  NetworkError: 'Error de red',
  LogoutMessage: 'Está cerrando sesión en ezpro. ¿Desea continuar?',
  YES: 'Sí',
  NO: 'No',
  InvPhonePSWD: 'Número de teléfono o contraseña no válidos.',
  NotValidPhone: 'No ingresó un número de teléfono válido',
  ReqNumericField: 'Complete este campo numérico',
  OTPResend: 'OTP Resentido.',
  SelectDate: 'Seleccionar fecha',
  EnterHere: 'Ingrese aquí',
  NOMSG: 'Lo sentimos, no hay mensaje para mostrar.',
  NoData: 'Sin datos.',
  UserLocked: 'Usuario bloqueado. Póngase en contacto con su administrador.',
  LanguageValidate: 'Por favor, seleccione un idioma',
  OfflineMessage: 'Conexión de red perdida. Cambiar al modo fuera de línea.',
  Offline: 'Desconectada',
  Online: 'En línea',
  savedLocally: 'Guardado localmente.',
  formsToSync: 'Formulario(s) para sincronizar.',
  formToSync: 'Formulario para sincronizar.',
  showingOfflineForms: 'Mostrando datos sin conexión. Por favor revise su conexion a internet.',
  NoForm: 'No hay que llenar formularios.',
  NoFaqs: 'No hay preguntas frecuentes disponibles.',
  HealthMenu: 'Datos de salud',
  myProfile: 'Mi perfil',
  subjectInformation: 'Información sobre el tema',
  studyInformation: 'información de estudio',
  versionInformation: 'Información de versión',
  LoggedUser: 'Usuario registrado',
  StudyDetails: 'Estudiar',
  VersionDetails: 'Información de versión',
  LoggedInUser: 'Usuario registrado',
  Study: 'Estudiar',
  VersionDetails: 'Detalles del lanzamiento',
  Syncing: 'Sincronizando . .',
  SyncSuccess: 'Sincronizado con éxito.',
  MaxAttemptsDone: 'Intentos máximos realizados.',
  LastSynced: 'Última sincronización',
  bmi: 'Índice de masa corporal',
  Height: 'Altura',
  Weight: 'Peso',
  LeanBodyMass: 'Masa corporal magra',
  BodyFat: 'Porcentaje de grasa corporal',
  Activity: 'Actividad',
  Vitals: 'Partes vitales',
  Biometrics: 'Biometría',
  HeartRate: 'Ritmo cardiaco',
  BloodPressure: 'Presión sanguínea',
  RespirationRate: 'Ritmo respiratorio',
  BodyTemperature: 'Temperatura corporal',
  BloodGlucose: 'Glucosa en sangre',
  Sex: 'Sexo',
  SleepAnalysis: 'Análisis de sueño',
  ActiveEnergy: 'Energía activa',
  FlightsClimbed: 'Vuelos subidos',
  Distance: 'Distancia',
  Steps: 'Pasos',
  StepUnits: 'pasos',
  DistanceUnits: 'millas',
  FloorUnits: 'pisos',
  ActiveEnergyUnits: 'calorías',
  SleepAnalysisUnits: 'horas',
  NoData: 'Sin datos',
  NOMSG: 'Lo sentimos, no hay mensaje para mostrar.',
  MeetingSchedule: 'Calendario de reuniones',
  UpcomingCalls: 'Próximas llamadas',
  PastCalls: 'Llamadas pasadas',
  NoCallScheduled: 'No hay llamadas próximas en este momento',
  NoPastCalls: 'No hay llamadas pasadas',
  NoInternet: 'Sin conexión a Internet.',
  NoChats: 'No hay gatos para mostrar.',
  SelectChat: 'Seleccione un chat o inicie un nuevo chat.',
  NoUsersFound: 'No se encontraron usuarios',
  Done: 'Hecho',
  AddParticipants: 'Agregar participantes',
  SearchParticipants: 'Buscar participantes',
  LeaveGroup: 'Salir del grupo',
  Participants: 'Los participantes',
  participants: 'Los participantes',
  Remove: 'Eliminar',
  Others: 'otros',
  Add: 'Añadir',
  Notifications: 'Notificaciones',
  CallDetails: 'Detalles de la llamada',
  Participants: 'Las participantes',
  CallHistory: 'Historial de llamadas',
  JoinCall: 'Unirse a la llamada',
  MissedCall: 'Perdiste esta llamada.',
  CallInProgress: 'La llamada está en curso.',
  CallNotStarted: 'La llamada aún no ha comenzado.',
  JoinedOn: 'Se unió el',
  LeftOn: 'Dejado en',
  Duration: 'Duración',
  ScheduledDuration: 'Duración programada',
  Title: 'Título',
  StartedOn: 'Comenzó en',
  Status: 'Estado',
  ScheduledTime: 'Hora programada',
  Alerts: 'Alertas',
  Reminders: 'Recordatorios',
  Notifications: 'Notificaciones',
  VersionInfo: 'Información de la versión',
  FormPreview: 'Vista previa del formulario',
  NRSNumber: 'Por favor seleccione el número.',
  SubmitTraining: 'Enviar formulario de capacitación',
  TrainingNotCompleted: 'Complete la capacitación antes de comenzar su diario.',
  StartTraining: 'Empezar a entrenar',
  ViewCompletedTraining: 'Ver entrenamiento completado',
  CompleteTraining: 'Complete la capacitación para acceder a los formularios del diario.',
  Training: 'Capacitación',
  SITESTAFFUSRNAMEWARNING: 'Por favor ingrese el nombre de usuario',
  SITESTAFFPINWARNING: 'Por favor ingrese PIN',
  LoginSiteStaffUsrName: 'Nombre de usuario',
  LoginSiteStaffPin: 'ALFILER',
  InvUsrNmPIN: 'Nombre de usuario o PIN no válido',
  InvPasscode: 'Ingrese un identificador de sitio válido.',
  EnterPasscode: 'Ingrese el identificador del sitio TRYAL',
  EnterUsername: 'Introduzca su nombre de usuario',
  EnterPassword: 'Introducir la contraseña',
  EnterValidUname: 'Ingrese un nombre de usuario válido',
  EnterValidPassword: 'Introduzca una contraseña válida',
  FirstName: 'Nombre de pila',
  LastName: 'Apellido',
  UserID: 'ID de usuario',
  Site: 'Sitio',
  MyAccount: 'Mi cuenta',
  StudyList: 'lista de estudio',
  SubjectList: 'Lista de temas',
  SelectFormCategory: 'Seleccionar categoría de formulario',
  LoginWithPIN: 'Iniciar sesión con nombre de usuario y PIN',
  VersionInfo: 'Información de versión',
  Email: 'Correo electrónico',
  SessionTimeoutMessage: 'Se ha agotado el tiempo de espera de la sesión',
  FillRequiredFields: 'Por favor, rellene todos los campos obligatorios',
  InvalidPasscode: 'Identificador de sitio no válido.',
  SomethingWentWrong: 'Algo salió mal.',
  InvalidUsernamePassword: 'Usuario o contraseña invalido.',
  InvalidUsernamePasswordWeb:
    '<p style="font-weight:normal"><b>Usuario o contraseña invalido.</b><br/>Si es participante o cuidador, haga clic en el enlace a continuación para acceder a la página de inicio de sesión del participante.</p>',
  ScheduleStartDate: 'Programar fecha de inicio',
  ScheduleEndDate: 'Fecha de finalización del programación',
  VisitNotStarted: 'La ventana de visita aún no ha comenzado.',
  VisitWindowClosed: 'La ventana de visita ha pasado, no puede ingresar datos.',
  VisitCompleted: 'La visita ha finalizado. Se supone que no debe actualizar ningún cuestionario.',
  ShowAlertMsg: '¿Quieres guardar tus cambios?',
  firstName: 'primer nombre',
  lastName: 'apellido',
  email: 'Correo electrónico',
  phone: 'teléfono',
  language: 'idioma',
  study: 'estudio',
  sponsor: 'patrocinador',
  versionName: 'nombre de la versión',
  Visit: 'Visita',
  StartTime: 'Hora de inicio',
  EndTime: 'Hora de finalización',
  VersionUpgradeInfo: 'La versión de eDiary se ha actualizado a la versión',
  New: 'Nueva',
  NoDiary:
    'No hay Diario/Cuestionarios disponibles. Póngase en contacto con el personal de su sitio',
  UserInactive:
    'Su acceso está desactivado. Por favor, póngase en contacto con su Coordinador de Estudios.',
  SubjectInactive:
    'El acceso de sujeto está desactivado. Comuníquese con su coordinador de estudios.',
  ProcessMessage: 'Procesamiento del cuestionario.',
  LoadingCallData: 'Cargando datos de llamadas',
  DownloadMetadata: 'Descargando metadata',
  DownloadStudydata: 'Descarga de datos del estudio.',
  DownloadQuestionnaries: 'Descarga de cuestionarios',
  FailedRetrieveStudy: 'Error al descargar los detalles del estudio.',

  SubjectCaregiverInactive:
    'E le toaga le mataupu. Faamolemole faafesootai lau Suesuega Faatonu mo nisi faamatalaga.',

  Click: 'Clic',
  LoginText: 'Para iniciar sesión de nuevo',
  OnceFrequencyScheduleStartDate: 'Programar fecha de inicio',
  OnceFrequencyScheduleEndDate: 'Programar fecha de finalización',
  TermsAndConditions: 'Política de Privacidad y Términos y Condiciones',
  TermsNConditionsCancel:
    'Debe aceptar la política de privacidad y los términos y condiciones para continuar usando esta aplicación',
  UnsyncedData:
    'Tienes datos no sincronizados en este dispositivo. No puede cerrar la sesión hasta que haya sincronizado sus datos. Póngase en contacto con su sitio si tiene preguntas.',
  ChangeToClinician: 'Cambiar al inicio de sesión del médico',
  ParticipantLogin: 'Inicio de sesión de los participantes',
  ClinicianLogin: 'Inicio de sesión del médico',
  SwitchToParticipant: 'Cambiar a participante',
  OrgText1: 'Si desea iniciar sesión en una organización diferente,',
  OrgText2: 'Acceda al enlace en el portal de administración',
  BackToLogin: 'Atrás para iniciar sesión',
  GoToPortal:
    'Hay acciones requeridas en su cuenta. Inicie sesión a través del Portal de administración para completar las acciones de su cuenta.',
  Organization: 'Organización',
  NoClinroAccessPrivilege:
    'No tiene acceso para ingresar datos de ClinRo. Comuníquese con su CRA o administrador si tiene preguntas.',
  DeviceTimezoneMismatchTitle:
    'Parece que la zona horaria de su dispositivo no coincide con su zona horaria configurada en esta aplicación.',
  DeviceTimezoneMismatchSubtitle: '¿Le gustaría cambiar su zona horaria?',
  DeviceTimezoneMismatchTzConfigured: 'Su zona horaria configurada:',
  DeviceTimezoneMismatchTzInferred: 'La zona horaria de su dispositivo:',
  SiteIdentifierInfo: '<p>Encuentra tu PRUEBA  <b>Identificador del sitio </b> en el portal en la página de lista de temas</p>',
  InvPhonePSWDWeb:'<p style="font-weight:normal"><b>Número de teléfono o contraseña no válidos.</b><br/>Si es médico, haga clic en el enlace siguiente para acceder a la página de inicio de sesión del médico.</p>',
  LoginUnsyncData: 'Hay datos que deben sincronizarse en su dispositivo. Por favor ingrese su contraseña para que los datos puedan ser transferidos. Comuníquese con el soporte si tiene preguntas.',
  DeviceTimezoneNoClicked: 'Puedes cambiar tu zona horaria en cualquier momento yendo a "Más" en la parte inferior de esta pantalla.',
  AgreeingToPrivacypolicy: 'Es obligatorio aceptar la política de privacidad y los términos y condiciones.',
  ReadAndAgree: 'Leí y acepto los términos y condiciones.',
  IAgree: 'Estoy de acuerdo',
  SubjectNumber: 'Número de asunto',
  Version: 'Versión',
  Back: 'Atrás',
  Visits: 'Visitas',
  DownloadImageFailedDeviceOffline: 'Este diario contiene imágenes descargadas de forma incompleta en su dispositivo. Conéctese a Internet y seleccione este diario nuevamente.',
  DownloadImageFailedNoEnoughSpace: 'No hay suficiente espacio en su dispositivo para descargar uno o más diarios. Libere espacio e inténtelo de nuevo.',
  ImageLoading: 'Cargando imagen...',
  Barcode:'Código de barras',
  BarcodeMessage:'Actualmente, el escaneo de códigos de barras no es compatible con la web. Utilice un dispositivo móvil para escanear códigos de barras',
  ScanAgain:'Escanea otra vez',
  SelectedValue:'Valor seleccionado'

}
