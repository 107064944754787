// import * as Localization from 'expo-localization';
import i18n from 'i18n-js'
import en from './locale-en'
import ru from './locale-ru'
import fr from './locale-fr'
import ja from './locale-ja'
import es from './locale-sa'
import timezoneLocaleAlbanian from './timezoneLocale-albanian'
import timezoneLocaleArabic from './timezoneLocale-arabic'
import timezoneLocaleArmenian from './timezoneLocale-armenian'
import timezoneLocaleBosnian from './timezoneLocale-bosnian'
import timezoneLocaleBulgarian from './timezoneLocale-bulgarian'
import timezoneLocaleChez from './timezoneLocale-checz'
import timezoneLocaleChinese from './timezoneLocale-chinese'
import timezoneLocaleCroatian from './timezoneLocale-croatian'
import timezoneLocaleDanish from './timezoneLocale-danish'
import timezoneLocaleDutch from './timezoneLocale-dutch'
import timezoneLocaleEstonian from './timezoneLocale-estonian'
import timezoneLocaleFinnish from './timezoneLocale-finnish'
import timezoneLocaleFrenchCanada from './timezoneLocale-french(canada)'
import timezoneLocaleGerman from './timezoneLocale-german'
import timezoneLocaleGreek from './timezoneLocale-greek'
import timezoneLocaleHebrew from './timezoneLocale-hebrew'
import timezoneLocaleHindi from './timezoneLocale-hindi'
import timezoneLocaleItalian from './timezoneLocale-italian'
import timezoneLocaleKorean from './timezoneLocale-korean'
import timezoneLocaleNorwegian from './timezoneLocale-norwegian'
import timezoneLocalePersian from './timezoneLocale-persian'
import timezoneLocalePolish from './timezoneLocale-polish'
import timezoneLocalePortuguese from './timezoneLocale-portuguese'
import timezoneLocalePortugueseBrazil from './timezoneLocale-portuguese(brazil)'
import timezoneLocaleSlovak from './timezoneLocale-slovak'
import timezoneLocaleSlovenian from './timezoneLocale-slovenian'
import timezoneLocaleSwedish from './timezoneLocale-swedish'
import timezoneLocaleTurkish from './timezoneLocale-turkish'
import timezoneLocaleUrdu from './timezoneLocale-urdu'
import timezoneLocaleSpanishMexico from './timezoneLocale-spanish(mexico)'

i18n.fallbacks = true
i18n.translations = {
  en_US: en,
  ru_RU: ru,
  fr_FR: fr,
  ja_JP: ja,
  es_ES: es,
  pl_PL: timezoneLocalePolish,
  zh_Hans_CN: timezoneLocaleChinese,
  de_DE: timezoneLocaleGerman,
  pt_PT: timezoneLocalePortuguese,
  it_IT: timezoneLocaleItalian,
  sq_AL: timezoneLocaleAlbanian,
  ar_SA: timezoneLocaleArabic,
  hy_AM: timezoneLocaleArmenian,
  bs_BA: timezoneLocaleBosnian,
  bg_BG: timezoneLocaleBulgarian,
  hr_HR: timezoneLocaleCroatian,
  cs_CZ: timezoneLocaleChez,
  da_DK: timezoneLocaleDanish,
  nl_N: timezoneLocaleDutch,
  et_EE: timezoneLocaleEstonian,
  fa_IR: timezoneLocalePersian,
  fi_FI:timezoneLocaleFinnish,
  fr_CA:timezoneLocaleFrenchCanada,
  el_GR: timezoneLocaleGreek,
  he_IL: timezoneLocaleHebrew,
  hi_IN: timezoneLocaleHindi,
  it_IT: timezoneLocaleItalian,
  ko_KR: timezoneLocaleKorean,
  nb_NO: timezoneLocaleNorwegian,
  sk_SK: timezoneLocaleSlovak,
  sl_SI: timezoneLocaleSlovenian,
  es_MX: timezoneLocaleSpanishMexico,
  sv_SE: timezoneLocaleSwedish,
  tr_TR:timezoneLocaleTurkish,
  ur_PK: timezoneLocaleUrdu,
  pt_BR: timezoneLocalePortugueseBrazil,
}

export default i18n
