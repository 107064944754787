import moment from 'moment'
import { DATE_FORMAT } from '../utils/dateUtils'
export default {
  appStatus: {
    isDeviceOnline: true,
    OfflineFormsToSync: 0,
    currentScreen: 'FALSE',
    isSyncing: false,
    syncCompleted: false,
    trainingForms: [],
    draftData: [],
    syncQueueTimerId: null,
    appType: null, 
    isAcknowledged: false,
    storage: {
      isStorageAvailable: true,
      isSpaceAlertAcknowledged: false,
    },
    timers: {
      downloadImageTimerId: null,
    },
    onMobile: false,
  },
  formPrint:{
    selectedVisitFormOid: '',
    selectedSvfId: '',
    formAnswers: {
      data: [],
      loading: false,
    },
    form: {},
    loading: false,
    formList: [],
    formListLoading: false,
    field: {
      fieldListOfMultipleForms: {}
    }
  },

  subjectStudyMetaData: {
    subject: {
      subjectVisits: { loading: false, selectedVisit: '', visits: [] },
    },
    studySite: {
      client: {},
    },
    subjectVisit: {},
    loading: false,
  },
  loading: false,
  language: {
    selectedLanguage: 'en_US',
  },
  subject: {
    rsaPublicKey: '',
    data: {},
    loading: false,
    selectedSubjectId: '',
  },

  timeZone: {
    list: [],
  },

  subjectVisitForm: {
    allSvfs: [],
    isRetrieved: false,
    list: [],
    scheduleDateList: [],
    loading: true,
    selectedSvf: null,
  },
  field: {
    list: [],
    loading: false,
    connectionFailed: false,
    fieldListOfMultipleForms: {},
  },
  crfData: {
    loading: false,
  },
  faq: {
    list: [],
    loading: true,
  },
  toast: {
    ref: null,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  dateTimeFormat: 'DD-MMM-YYYY hh:mm A z',
  visitForm: {
    selectedVisitFormOid: '',
  },
  healthkitData: {
    subjectHealthData: {
      id: null,
      bodyTemp: null,
      bloodGlucose: null,
      systolicBp: null,
      diastolicBp: null,
      respiratoryRate: null,
      bmi: null,
      heartRate: null,
      height: null,
      weight: null,
      bodyFat: null,
      leanBodyMass: null,
      sex: '',
    },
    subjectActivity: {
      id: null,
      activeEnergyBurned: null,
      restingEnergyBurned: null,
      distance: null,
      floors: null,
      steps: null,
      sleepDuration: null,
      updatedTs: null,
    },
    subjectActivityGoal: {
      activeEnergyBurned: null,
      restingEnergyBurned: null,
      distance: null,
      floors: null,
      steps: null,
      sleepingDuration: null,
    },
    lastSync: 'March 3',
  },
  deviceLocation: {
    ip: null,
    type: null,
    continent_code: null,
    continent_name: null,
    country_code: null,
    country_name: null,
    region_code: null,
    region_name: null,
    city: null,
    zip: null,
    latitude: null,
    longitude: null,
    location: {
      geoname_id: null,
      capital: null,
      languages: [],
      country_flag: null,
      country_flag_emoji: null,
      country_flag_emoji_unicode: null,
      calling_code: null,
      is_eu: false,
    },
  },
  chat: {
    recentUnReadMessages: [],
    selectedChat: {},
    messagesToDelete: [],
    unreadChats: {
      unreadChatIds: [],
      count: 0,
    },
    canClearUnreadCountOfSelectedChat: false,
  },
  call: {
    selectedCall: {},
  },
  subjectPrivileges: [],
  passcode: null,
  form: {},
  // Site Staff store
  users: {
    currentUser: {
      id: null,
      selectedFormType: '',
      privacyPolicy: {}
    },
    loading: false,
  },
  site: {
    id: null,
    name: null,
    loading: false,
  },
  study: {
    data: {},
    loading: false,
    selectedStudyId: '',
  },
  // Offline
  offlineData: {
    data: [],
  },
  session: {
    sessionTimeout: false,
  },
  caregiverMetaData: {},
  syncQueue: {
    data: {
      sessionUser: {}
    },
  },
  metaData: {
    data: {
      images: {}
    },
    loading: false,
    failed: false,
    selectedVisitId: '',
    selectedVisitFormOid: '',
    selectedSvfId: '',
    webappVisitDtLoading: false,
    selectedDate: moment().format(DATE_FORMAT),
  },
  formAnswers: {
    data: [],
    loading: false,
  },
}
