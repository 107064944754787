import createActionType from '../utils/action'
import api from '../utils/formPrintApi'
import constants from '../constants/constants'
import _ from 'lodash'
import { getItem } from '../utils/secureStorageUtils'
import { getFormIds } from './metaData'
import{ getFieldListFromForms, updateImages} from '../actions/form'

export const RETRIEVE_FORMS_WITH_FIELDS_SUCCESS_FORMPRINT = createActionType(
    'RETRIEVE_FORMS_WITH_FIELDS_SUCCESS_FORMPRINT'
  )
  export const RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT = createActionType(
    'RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT'
  )
  export const RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT = createActionType(
    'RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT'
  )
  
export const RETRIEVE_ALL_CRF_DATA_SUCCESS_FORMPRINT = createActionType(
    'RETRIEVE_ALL_CRF_DATA_SUCCESS_FORMPRINT'
  )
  export const RETRIEVE_ALL_CRF_DATA_REQUEST_FORMPRINT = createActionType(
    'RETRIEVE_ALL_CRF_DATA_REQUEST_FORMPRINT'
  )
  export const RETRIEVE_ALL_CRF_DATA_FAILURE_FORMPRINT = createActionType(
    'RETRIEVE_ALL_CRF_DATA_FAILURE_FORMPRINT'
  )
  export const UPDATE_SELECTED_VISIT_FROM_ID_FORMPRINT = createActionType(
    'UPDATE_SELECTED_VISIT_FROM_ID_FORMPRINT'
  )
  export const SAVE_FIELD_LIST_OF_MULTIPLE_FORM_FORMPRINT = createActionType(
    'SAVE_FIELD_LIST_OF_MULTIPLE_FORM_FORMPRINT'
  )

  
export const getCrfDataBySvfIdFormPrint = (svfId, primaryOrgCode, studyId, siteId, subjectId,subjectVisitId) => async (dispatch) => {
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_REQUEST_FORMPRINT })
    try {
      const res = await api.get(`/${primaryOrgCode}/studies/${studyId}/sites/${siteId}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms/${svfId}/crfData`)
      var crfDataByFieldKey = {}
      _.forEach(res.data, (crfData) => (crfDataByFieldKey[crfData.field.fieldOid] = crfData))
      const crfData = { [svfId]: crfDataByFieldKey }
      dispatch({ type: RETRIEVE_ALL_CRF_DATA_SUCCESS_FORMPRINT, formAnswers: crfData })
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_ALL_CRF_DATA_FAILURE_FORMPRINT })
    }
  }

  export const retrieveFormsWithFieldsFormPrint =
  (svId = null, formId = null, formPreview = false, svfId = null, printRequestProps={}) =>
  async (dispatch, getState) => {
    try {
      const locale = getItem('locale') || 'en_US';
      const formIdList = formPreview ? [formId] : getFormIds(getState().metaData, svId, getState().appStatus?.appType)
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT })
        const res = await api.post(`/${printRequestProps.primaryOrgCode || constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${printRequestProps.studyId || constants.ContextProperties.STUDY_ID}/forms`, {
          formIds: _.uniqBy(formIdList),
          locale
        })
        saveFormDataInStore(res.data, formPreview, dispatch, svfId) 
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT })
    }
  }


  export const getQuestionnaireMetaData =
  (svId = null, formId = null, formPreview = false, svfId = null, printRequestProps={}) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT })
        const res = await api.get(`/${printRequestProps.primaryOrgCode || constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${printRequestProps.studyId || constants.ContextProperties.STUDY_ID}/sites/${printRequestProps.siteId || constants.ContextProperties.SITE_ID}/subjectVisitForms/${svfId}`)
        let data = []
        data.push(
          res.data
        )
        saveFormDataInStore(data, formPreview, dispatch, svfId) 
      
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT })
    }
  }

  export const retrieveFormsWithFieldsByFormLevelFormPrint =
  (clientId, formId, primaryOrgCode) =>
  async (dispatch, getState) => {
    try {
      const locale = getItem('locale') || 'en_US';
      const formIdList =  [formId];
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST_FORMPRINT })
        const res = await api.post(`/${primaryOrgCode}/forms/${formId}?clientId=${clientId}`, {locale})
        saveFormDataInStore(res.data, true, dispatch, null)   
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE_FORMPRINT })
    }
  }

  const saveFormDataInStore = (data, formPreview, dispatch, svfId) => {
    var formList = _.map(data, (ele) => {
      return { ...ele, fieldGroups: null }
    })
    dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_SUCCESS_FORMPRINT, formList: formList })
    const fieldListOfMultipleForms = getFieldListFromForms(data)
    dispatch(saveFieldListOfMultipleForms(fieldListOfMultipleForms))
    dispatch(updateSelectedVisitFormOidAndSelectedSvfId(data[0].id, svfId))
  }
  
export const saveFieldListOfMultipleForms = (fieldListOfForms) => async (dispatch) => {
    dispatch({
      type: SAVE_FIELD_LIST_OF_MULTIPLE_FORM_FORMPRINT,
      fieldListOfForms: fieldListOfForms,
    })
  }

  
export const updateSelectedVisitFormOidAndSelectedSvfId = (formId, svfId) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_VISIT_FROM_ID_FORMPRINT,
    visitFormOid: formId,
    svfId: svfId,
  })
}