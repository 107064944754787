import _ from 'lodash'
import moment from 'moment-timezone'
import { Body, Card, CardItem } from 'native-base'
import React from 'react'
import { Text, View } from 'react-native'
import Timeline from 'react-native-timeline-flatlist'
import PhoneCallCard from '../components/PhoneCallCard'
import styles from '../components/styles/TimelineStyles'
import SubmissionIcon from '../components/submissionIcon'
import listStyles from '../styles/listStyles'
import { formatDateInTimezone } from './dateUtils'
import { openForm } from '../utils/fieldutils/field'

export function TimeLineComponent({
  navigation,
  svfs,
  screenProps: { t },
  selectVisitForm: storeSelectedVisitFormOidAndSvfId,
  isDeviceOnline,
  timeZone,
  deviceTokenData,
  refresh,
  loading,
  subjectId,
  updateSelectedCall,
  isTraining,
  isTrainingCompleted,
  callLoading,
  callData,
}) {
  return (
    <Timeline
      style={styles.list}
      data={callLoading ? svfs : svfs.concat(callData)}
      circleSize={14}
      circleColor="#455a64"
      lineColor="#455a64"
      lineWidth={0.8}
      timeStyle={styles.timeStyle}
      options={{
        style: { paddingTop: 10 },
        onRefresh: refresh,
        refreshing: loading,
      }}
      innerCircle="dot"
      onEventPress={async (item) => {
        if (
          (item.eventType !== 'CALL' &&
            (item.status != 'COMPLETED' || isTraining === 'true') &&
            openForm(item, item.allowDiaryCompletion ? true : false, timeZone)) ||
          (item.eventType !== 'CALL' && isTraining === 'true')
        ) {
          storeSelectedVisitFormOidAndSvfId(item.formId, item.svfId)
          setTimeout(
            () =>
              navigation.navigate('Form', {
                from: 'Diary',
                formName: item.title,
                isDeviceOnlineAtDiary: isDeviceOnline,
                isTraining: item.training,
              }),
            0
          )
        }
        // else {
        //     if(deviceTokenData) {
        //         Linking.openURL(`${appConstants.jitsiMeetUrl}/${item.accessCode}?subjectDeviceToken=${encodeURIComponent(deviceTokenData.subjectDeviceToken)}&subjectDeviceTokenId=${deviceTokenData.subjectDeviceTokenId}`);
        //     }
        // }
      }}
      titleStyle={styles.titleStyle}
      renderDetail={(rowData) =>
        buildTimelineItem(
          rowData,
          t,
          timeZone,
          subjectId,
          navigation,
          updateSelectedCall,
          deviceTokenData,
          rowData.training
        )
      }
      renderTime={(rowData) => buildTimelineTime(rowData, timeZone)}
    />
  )
}

export const buildTimelineItem = (
  rowData,

  t,
  timeZone,
  subjectId,
  navigation,
  updateSelectedCall,
  deviceTokenData,
  isTraining
) => {
  return (
    <View>
      {rowData.eventType === 'FORM' && (
        <Card style={[styles.details, { backgroundColor: '#BFF4F8' }]}>
          <CardItem style={[styles.details, { backgroundColor: '#BFF4F8' }]}>
            <Body>
              <View style={styles.header}>
                <View style={styles.headerTextContainer}>
                  <Text style={styles.headerText}>{rowData.title}</Text>
                </View>
                <SubmissionIcon svf={rowData} />
              </View>
              {!isTraining && (
                <View>
                  <Text style={listStyles.subTextStyle}>
                    {rowData.frequency === 4
                      ? t('OnceFrequencyScheduleStartDate')
                      : t('ScheduleStartDate')}
                    {': '}
                    {rowData.frequency === 4
                      ? moment(rowData.scheduleStartTime).format('DD-MMM-YYYY')
                      : moment(rowData.scheduleStartTime).format('DD-MMM-YYYY hh:mm A')
                    }
                  </Text>
                  {rowData.scheduleEndTime && (
                    <Text style={listStyles.subTextStyle}>
                      {rowData.frequency === 4
                        ? t('OnceFrequencyScheduleEndDate')
                        : t('ScheduleEndDate')}
                      {': '}
                      {rowData.frequency === 4
                        ? moment(rowData.scheduleEndTime).format('DD-MMM-YYYY')
                        : moment(rowData.scheduleEndTime).format('DD-MMM-YYYY hh:mm A')}
                    </Text>
                  )}
                  {rowData.frequency === 4 && (
                    <Text style={listStyles.subTextStyle}>
                      {'Schedule Time: '}
                      {_.isEqual(rowData.svfTimeslot?.startTime, '00:00:00') &&
                      _.isEqual(rowData.svfTimeslot?.endTime, '23:59:00')
                        ? 'All Day'
                        : formatDateInTimezone(
                            rowData.svfTimeslot?.startTime,
                            'hh:mm A',
                            null,
                            true
                          ) +
                          ' - ' +
                          formatDateInTimezone(rowData.svfTimeslot?.endTime, 'hh:mm A', null, true)}
                    </Text>
                  )}
                </View>
              )}
              {rowData.status === 'COMPLETED' ? (
                <Text style={listStyles.subTextStyle}>
                  {t('CompletedOn')}
                  {': '}
                  {moment
                    .utc(rowData.completedDateTime, 'YYYY-MM-DD HH:mm:ss')
                    .clone()
                    .tz(timeZone)
                    .format('DD-MMM-YYYY hh:mm A z')}
                </Text>
              ) : (
                <View />
              )}
            </Body>
          </CardItem>
        </Card>
      )}
      {rowData.eventType === 'CALL' && (
        <PhoneCallCard
          call={rowData}
          timeZone={timeZone}
          subjectId={subjectId}
          updateSelectedCall={updateSelectedCall}
          navigation={navigation}
          deviceTokenData={deviceTokenData}
          t={t}
        />
      )}
    </View>
  )
}

export const buildTimelineTime = (rowData, timeZone) => {
  const isForm = rowData.eventType === 'FORM'

  const scheduleStartTime = isForm
    ? rowData.svfTimeslot?.startTime
    : rowData.date

    if(!isForm){
      console.log("scheduleDateTimeUtc", rowData?.scheduleDateTimeUtc)
      console.log("converted", rowData?.date)
    }

  const isAllday =
    _.isEqual(rowData.svfTimeslot?.startTime, '00:00:00') &&
    _.isEqual(rowData.svfTimeslot?.endTime, '23:59:00')
  return (
    <View
      style={{
        width: 75,
        backgroundColor: 'rgba(13, 71, 161, .9)',
        height: 35,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
      }}
    >
      <Text
        style={{
          fontSize: rowData.training
            ? 13
            : scheduleStartTime?.length > 9 && !scheduleStartTime.includes(':')
            ? 9
            : 13,
          color: 'white',
          fontFamily: 'Inter',
          textAlign: 'center',
        }}
      >
        {isAllday || rowData.training
          ? 'All Day'
          : moment(scheduleStartTime, 'HH:mm').format('hh:mm A')
          }
      </Text>
    </View>
  )
}
