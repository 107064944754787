import React from "react"
import {View } from "react-native"
import { alignments } from "../../scales/alignments"
import ShowValue from "../../scales/ShowValue"
import { getFieldUISchemaData } from "../../../utils/fieldutils/fieldType"
import _ from 'lodash'
import PropTypes from 'prop-types'
import constants from "./constants"
import { removePercentageFromValue } from "../../../utils/util"
import {getVASScaleProperties, scaledFont } from "../../scales/utils"
import VerticalScaleContainerWrapper from "./VerticalScaleContainerWrapper"
import HorizontalScaleContainerWrapper from "./HorizontalScaleContainerWrapper"
import { useSelector } from 'react-redux';

const {SCALE_POSITION, SHOW_MARKS} = constants
const VASComponent = (props) => {

    const { orientation, value, isPercentage, fdUISchema,  showValueConfig: {showValue, labelText},  scaleVerticalAlignment, id, onValueChange, options, disabled, showMarks, indicationConfig, step, min, max} = props
    const percentageSymbol = isPercentage ? '%' : ''
    const { onMobile } = useSelector(state => state?.appStatus);
    const fieldValue = removePercentageFromValue(value)!=null ? Number(removePercentageFromValue(value)) : null

    const scaleProps = getVASScaleProperties(min, max, step, orientation, onMobile)


    const fontConfig = scaledFont(max, step, isPercentage, min, indicationConfig, scaleProps)

    return(
    <View
        style={{
          flex: 1,
          flexDirection: 'column',
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
        accessible = {true}
        testID= {`${id}-scale-container-view`}
      >
         {showValue && (
          <ShowValue 
            labelText={labelText}
            value= { _.isNumber(fieldValue) ? `${fieldValue}${percentageSymbol}` : ''}
            testID={`visual-analog-scale-slider-${id}-showValue`}
            accessible={!disabled}
          />
        )}

        {_.isEqual(_.toUpper(orientation), _.toUpper(alignments.HORIZONTAL)) && (
              <HorizontalScaleContainerWrapper 
                    min={min}
                    max={max}
                    step={step}
                    value={fieldValue}
                    disabled={disabled} 
                    id={id}
                    isPercentage={isPercentage}
                    onValueChange={onValueChange}
                    options={options}
                    showMarks={showMarks}
                    indicationConfig={indicationConfig}
                    orientation={orientation}
                    scaleProps={scaleProps}
                    fontConfig={fontConfig}
            />
        )} 

        {_.isEqual(_.toUpper(orientation), _.toUpper(alignments.VERTICAL)) && (
              <VerticalScaleContainerWrapper 
                    min={min}
                    max={max}
                    step={step}
                    value={fieldValue}
                    disabled={disabled} 
                    id={id}
                    isPercentage={isPercentage}
                    onValueChange={onValueChange}
                    options={options}
                    showMarks={showMarks}
                    indicationConfig={indicationConfig}
                    orientation={orientation}
                    scaleVerticalAlignment={scaleVerticalAlignment}
                    scaleProps={scaleProps}
                    fontConfig={fontConfig}
            />
        )} 

      </View>
    )
}


VASComponent.defaultProps = {
  step: 1,
  min: 0,
  max: 10,
  onValueChange: ()=> null,
  id: null,
  disabled: false,
  value: null,
  isPercentage: false,
  indicationConfig: {
      indicationMultiple: null,
      indicationNumbers: null
  },
  showMarks: SHOW_MARKS.NO_MARKS,
  orientation: alignments.HORIZONTAL,  
  options: [],
  scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE,
  fdUISchema: {},
  showValueConfig: {
    showValue: false, 
    labelText: null, 
  },
}

VASComponent.propTypes = {
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  isPercentage: PropTypes.bool,
  indicationConfig: PropTypes.shape({
      indicationMultiple: PropTypes.number,
      indicationNumbers: PropTypes.string,
  }),
  showMarks: PropTypes.string,
  orientation: PropTypes.string,    
  id: PropTypes.string,
  options: PropTypes.arrayOf(Object),
  scaleVerticalAlignment: PropTypes.string,
  fdUISchema: PropTypes.instanceOf(Object),
  showValueConfig: PropTypes.shape({
    showValue: PropTypes.bool,
    labelText: PropTypes.string,
}),
}

export default VASComponent