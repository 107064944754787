import _ from 'lodash'
import { storage } from './storage'
import { decryptString, encryptString } from './cryptoUtil'

export const putItem = (value, key) => {
  storage.set(key, value)
}

export const getItem = (itemKey) => {
  return storage.getString(itemKey)
}

export const isPinExists = (userId) => {
  const storedPins = getStoredPins();
  return !_.isEmpty(storedPins[userId])
}

export const getPin = (userId) => {
  const storedPins = getStoredPins();
  return decryptString(storedPins[userId]);
}

const getStoredPins = () => {
  const storedPins = storage.getItem('appPin');
  return storedPins || {};
}

export const addPin = (userId, pin) => {
  const storedPins = getStoredPins();
  storedPins[userId] = encryptString(pin);
  storage.setItem("appPin", storedPins)
}

export const setSubjectCredentials = async (value) => {
  try {
    storage.setItem('credentials', value)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const retrieveSubjectCredentials = async () => {
  try {
    const data = storage.getItem('credentials')
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const removeCredentials = async () => {
  try {
    storage.delete('appPin')
    storage.delete('credentials')
    storage.delete('locale')
  } catch (error) {}
}

export const setSubjectDeviceToken = async (token, id) => {
  try {
    storage.set('DeviceToken', token)
    storage.set('DeviceTokenId', id)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const setSubjectAuthToken = (token, refreshToken) => {
  try {
    storage.set('SubjectAuthorizationToken', token)
    storage.set('SubjectRefreshToken', refreshToken)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const setPrintToken = (token) => {
  try {
    storage.set('PrintToken', token)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const setPreviewToken = (token) => {
  try {
    storage.set('PreviewToken', token)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const retrieveSubjectDeviceToken = async () => {
  try {
    const data = {
      subjectDeviceToken: storage.getString('DeviceToken'),
      subjectDeviceTokenId: storage.getString('DeviceTokenId'),
    }
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const removeSubjectDeviceToken = async () => {
  try {
    storage.delete('DeviceToken')
    storage.delete('DeviceTokenId')
  } catch (error) {
    console.log(error)
  }
}
export const storeDraftData = async (field, formId, fieldValue) => {
  let existingFormData = storage.getItem('formData')
  if (!existingFormData) {
    existingFormData = {}
  }
  let values = existingFormData[formId]
  if (!values) values = {}
  const key = Object.keys(field)[0]
  let fieldV = { ...fieldValue }
  fieldV.crfData.field = null
  values[key] = {
    ...values[key],
    crfData: fieldV.crfData,
  }
  existingFormData = {
    ...existingFormData,
    [formId]: values,
  }
  // console.log(existingFormData)
  try {
    storage.setItem('formData', existingFormData)
  } catch (error) {
    console.log(error)
    console.log('failed to save draft data to localstorage')
  }
}
export const getDraftData = (formId) => {
  const existingFormData = storage.getItem('formData')
  return existingFormData ? existingFormData[formId] : null
}
export const setFormData = (selectedSvf, fieldData) => {
  try {
    const existingFormData = storage.getItem('formData')
    if (_.isEmpty(existingFormData)) {
      existingFormData = []
    }
    const existingFormIndex = _.findIndex(
      existingFormData,
      (d) =>
        d.scheduleDate === selectedSvf.scheduleDate &&
        d.scheduleTime === selectedSvf.scheduleTime &&
        d.visitFormOid === selectedSvf.visitFormOid &&
        selectedSvf.training === d.training
    )
    if (existingFormIndex >= 0) {
      draftData[existingFormIndex] = {
        ...draftData[existingFormIndex],
        data: {
          ...fieldData,
        },
      }
    } else {
      existingFormData.push({
        scheduleDate: selectedSvf.scheduleDate,
        scheduleTime: selectedSvf.scheduleTime,
        data: {
          ...fieldData,
        },
        training: selectedSvf.training,
      })
    }
    storage.setItem('formData', existingFormData)
  } catch (error) {
    console.log(error)
  }
}

export const getFormData = async (selectedSvf) => {
  try {
    const existingFormData = storage.getItem('formData')
    if (_.isEmpty(existingFormData)) {
      return {}
    }
    const existingFormIndex = _.findIndex(
      existingFormData,
      (d) =>
        d.scheduleDate === selectedSvf.scheduleDate &&
        d.scheduleTime === selectedSvf.scheduleTime &&
        d.visitFormOid === selectedSvf.visitFormOid &&
        selectedSvf.training === d.training
    )
    if (existingFormIndex >= 0) {
      return existingFormData[existingFormIndex].data
    }
    return {}
  } catch (error) {
    console.log(error)
  }
}

export const removeFormData = async (selectedSvf) => {
  try {
    const existingFormData = storage.getItem('formData')
    if (!_.isEmpty(existingFormData)) {
      delete existingFormData[selectedSvf.id]
    }
    storage.setItem('formData', existingFormData)
  } catch (error) {
    console.log(error)
  }
}
