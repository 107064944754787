import React, { Component } from 'react'
import { connect } from 'react-redux'
import { View, Platform, Text, Dimensions } from 'react-native'
import { bindActionCreators } from 'redux'
import Diary from '../components/Diary'
import { retrieveSvfsByScheduledDate, retrieveTrainingSvfs } from '../actions/subjectVisitForm'
import moment from 'moment'
import {
  setCurrentScreen,
  storeSyncStatus,
  updateSyncCompleteStatus,
} from '../actions/storeAppStatus'
import VisitLabel from '../components/visitLabel'
import appConstants from '../constants/constants'
import {
  getFilteredSvfs,
  getOfflineSVfsForToday,
  getSvfsOfCurrentVisitWithMinEntries,
} from '../selectors/subjectVisitForm'
import { getCurrentVisit } from '../selectors/visits'
import api from '../utils/api'
import _ from 'lodash'
import { getTodayCallSchedule } from '../utils/meetingScheduleUtils'
import { updateSelectedCall } from '../actions/call'
import Constants from 'expo-constants'
import { checkForDataSync } from '../utils/offline/dataSync'
import { Spinner } from 'native-base'
import { getOfflineFormsToSync } from '../selectors/syncQueue'
import { updateSelectedVisitFormOidAndSelectedSvfId } from '../actions/metaData'
import { updateSelectedDate } from '../actions/metaData'
import { NavigationEvents } from 'react-navigation'
import { fontResizer } from '../components/styles/fontResizer'
import { DATE_TIME_FORMAT, DATE_FORMAT, convertUtcToSubjectTimezone } from '../utils/dateUtils'
import constants from '../constants/constants'
import { store } from '../store/configStore'

class DiaryScreen extends Component {
  state = {
    callSchedules: [],
    loading: false,
  }

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    headerTitle: (
      <View style={{ left: 20 }}>
        <View>
          <Text
            style={{
              paddingTop: Platform.OS === 'web' ? 0 : 10,
              color: '#212121',
              fontSize: fontResizer(24),
              fontFamily: 'Inter',
              fontWeight: '400',
              paddingBottom: Platform.OS === 'web' ? 15 : 10,
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {navigation.state.params && navigation.state.params.title
              ? t('HomePatnt_Diary') + ' (' + navigation.state.params.title + ')'
              : t('HomePatnt_Diary')}
          </Text>
        </View>
        <View>
          {navigation.state.params && navigation.state.params.subTitle && (
            <View
              style={{ flexDirection: Dimensions.get('window').width >= 410 ? 'row' : 'column' }}
            >
              <Text
                style={{
                  color: '#212121',
                  fontSize: fontResizer(14),
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  paddingBottom: Platform.OS === 'web' ? 0 : 10,
                }}
                numberOfLines={1}
              >
                {'Visit Window : '}
              </Text>
              <Text
                style={{
                  color: '#212121',
                  fontSize: fontResizer(14),
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  paddingTop: Dimensions.get('window').width >= 390 ? 0 : 5,
                  paddingBottom: Platform.OS === 'web' ? 0 : 10,
                }}
                numberOfLines={1}
              >
                {navigation.state.params.subTitle}
              </Text>
            </View>
          )}
        </View>
      </View>
    ),
  })

  componentDidUpdate(prevProps) {
    const { navigation, scheduleDate } = this.props
    const isTraining = navigation.getParam('isTraining')

    if (prevProps.scheduleDate !== scheduleDate) {
      if (!isTraining) {
        this.retrieveCallSchedules()
      }
      this.refreshDiary()
    }

    const prevNavigation = prevProps.navigation
    const prevTraining = prevNavigation.getParam('isTraining')

    if (prevTraining !== isTraining) {
      this.refreshDiary()
    }
  }

  refreshDiary = () => {
    const { isDeviceOnline, setCurrentScreen, navigation, appType } = this.props

    const isTraining =
    appType === appConstants.AppType.SITESTAFF ? false : navigation.getParam('isTraining')

    const shouldRetrieveCallSchedules =
      !isTraining && isDeviceOnline && appType !== appConstants.AppType.SITESTAFF

    setCurrentScreen('DIARY_PAGE')

    if (shouldRetrieveCallSchedules) {
      this.retrieveCallSchedules()
    }
  }


  changeScheduleDate = (date) => {
    const { updateSelectedDate } = this.props
    updateSelectedDate(moment(date).utc().format(DATE_FORMAT))
  }

  sortSvfsAndCallsByTime = (svfs) => {
    if (svfs.length > 0) return _.sortBy(svfs, ['scTimeSort'])
    return svfs
  }

  retrieveCallSchedules = async () => {
    const {
      subject: { id: subjectId, timeZone },
      scheduleDate,
    } = this.props
    const selectedDate = scheduleDate
    try {
      this.setState({
        loading: true,
      })
      const res = await api.get(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/calls?subjectId=${subjectId}&date=${selectedDate}&timezone=${timeZone}`
      )
      const callSchedules = res.data
      this.setState({
        callSchedules: callSchedules || [],
      })

      this.setState({
        loading: false,
      })
    } catch (error) {
      console.log(error)
      this.setState({
        loading: false,
      })
    }
  }
  getCurrentVisitStatus = () => {
    const { currentVisit, screenProps: { t }, scheduleDate } = this.props

    const visitStartDate = convertUtcToSubjectTimezone(currentVisit.visitStartDate, DATE_TIME_FORMAT)
    const visitEndDate = convertUtcToSubjectTimezone(currentVisit.visitEndDate, DATE_TIME_FORMAT)

    let visitLabelTitle = null
      
    if (!currentVisit.isEnabled) {
      visitLabelTitle = t('VisitNotStarted')
    } else if (moment(scheduleDate).isSame(moment(visitStartDate), 'day')) {
      visitLabelTitle = null
    } else if (moment(scheduleDate).isAfter(moment(visitEndDate), 'day')) {
      visitLabelTitle = t('VisitWindowClosed')
    } else if (moment(scheduleDate).isBefore(moment(visitStartDate), 'day')) {
      visitLabelTitle = t('VisitNotStarted')
    }
    return visitLabelTitle
  }

  render() {
    const {
      syncCompleted,
      updateSyncCompleteStatus,
      setCurrentScreen,
      appStatus,
      navigation,
      storeSyncStatus,
      screenProps,
      isDeviceOnline,
      offlineFormsToSync,
      subject: { timeZone, id },
      updateSelectedCall,
      isTrainingCompleted,
      formListLoading,
      syncQueue,
      updateSelectedVisitFormOidAndSelectedSvfId,
      webappVstDataLoading,
      svfsOfCurrentVisit,
      scheduleDate,
      currentVisit,
      isSyncing,
      appType
    } = this.props

    const { callSchedules, loading: callLoading } = this.state
    const isTraining = navigation.getParam('isTraining')
    let svfs = []

    let loCallSchedules = []
    if (!isTraining && appType !== appConstants.AppType.SITESTAFF) {
      loCallSchedules = getTodayCallSchedule(callSchedules, timeZone)
    }
    if (formListLoading || webappVstDataLoading) {
      return <Spinner color="#4e89ae" />
    }

    svfs = svfsOfCurrentVisit
    svfs = this.sortSvfsAndCallsByTime(svfs)
    svfs = _.orderBy(svfs, 'scheduleStartTime')
    loCallSchedules = this.sortSvfsAndCallsByTime(loCallSchedules)
    const visitStatus = this.getCurrentVisitStatus()
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {!isTraining && visitStatus && (
          <VisitLabel
            screenProps={screenProps}
            title={visitStatus}
            testID={`visitdiary-visitstatuslabel-${currentVisit?.id}-view`}
          />
        )}
        <NavigationEvents />
        <Diary
          syncCompleted={syncCompleted}
          updateSyncCompleteStatus={updateSyncCompleteStatus}
          setCurrentScreen={setCurrentScreen}
          checkForDataSync={checkForDataSync}
          appStatus={appStatus}
          isSyncing={isSyncing}
          storeSyncStatus={storeSyncStatus}
          OfflineFormsToSync={offlineFormsToSync}
          screenProps={screenProps}
          navigation={navigation}
          svfs={svfs}
          callData={loCallSchedules}
          changeScheduleDate={this.changeScheduleDate}
          refreshDiary={this.refreshDiary}
          loading={false}
          isDeviceOnline={isDeviceOnline}
          selectVisitForm={updateSelectedVisitFormOidAndSelectedSvfId}
          retrieveCallSchedules={this.retrieveCallSchedules}
          callLoading={callLoading}
          timeZone={timeZone}
          subjectId={id}
          updateSelectedCall={updateSelectedCall}
          isTraining={isTraining}
          isTrainingCompleted={isTrainingCompleted}
          getCurrentVisitStatus={this.getCurrentVisitStatus}
          syncQueue={syncQueue}
          scheduleDate={scheduleDate}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  subject: state.subjectStudyMetaData.subject,
  subjectVisit: _.get(state.subjectStudyMetaData.subject.subjectVisits, 'selectedVisit', null),
  selectedLanguage: state.changeLanguage.selectedLanguage,
  onlineSvfs: getFilteredSvfs(state),
  loading: state.subjectVisitForm.loading,
  subjectStudyMetaData: state.subjectStudyMetaData,
  offlineSvfs: getOfflineSVfsForToday(state),
  isDeviceOnline: state.appStatus.isDeviceOnline,
  currentScreen: 'FORMS_LIST',
  isSyncing: state.appStatus.isSyncing,
  syncCompleted: state.appStatus.syncCompleted,
  appStatus: state.appStatus,
  currentVisit: getCurrentVisit(state),
  svfsOfCurrentVisit: getSvfsOfCurrentVisitWithMinEntries(state),
  isTrainingCompleted:
  state.appStatus?.appType === appConstants.AppType.SITESTAFF
      ? true
      : state.subjectStudyMetaData.trainingCompleted,
  formListLoading: state.form.formListLoading,
  syncQueue: state.syncQueue.data,
  offlineFormsToSync: getOfflineFormsToSync(state),
  webappVstDataLoading: state.metaData.webappVisitDtLoading,
  scheduleDate: state.metaData.selectedDate,
  appType: state.appStatus?.appType,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveSvfsByScheduledDate,
      setCurrentScreen,
      storeSyncStatus,
      updateSyncCompleteStatus,
      updateSelectedCall,
      retrieveTrainingSvfs,
      updateSelectedVisitFormOidAndSelectedSvfId,
      updateSelectedDate,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DiaryScreen)
