import React, { Component } from 'react'
import { StyleSheet, Text, View, TouchableOpacity, Platform } from 'react-native'
import _ from 'lodash'
import { fontResizer } from './styles/fontResizer'

class ChatHeader extends Component {
  render() {
    const {
      navigation,
      participantsNames,
      openGroupInfo = () => {},
      addNewParticipants = () => {},
    } = this.props
    const allNames = participantsNames.map((pn) => pn.split(' ')[0])
    const displayNames = allNames.length > 1 ? _.pullAt(allNames, [0, 1]) : allNames
    const joinedDisplayNames = _.join(displayNames, ', ')
    // console.log('joinedDisplayNames-----', joinedDisplayNames)
    return (
      <View style={styles.container}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            // alignItems: 'center',
            // flex: 1,
          }}
        >
          {/* <TouchableOpacity onPress={() => navigation.goBack()}>
                        <MaterialCommunityIcons name="arrow-left" size={22} color="white" />
                    </TouchableOpacity> */}
          <TouchableOpacity
            onPress={openGroupInfo}
            testID={'chatHeader-participants-info-header'}
            accessible={true}
          >
            <Text numberOfLines={1} style={[styles.headerNamesText, { fontSize: fontResizer(17) }]}>
              {joinedDisplayNames}{' '}
              {allNames.length > 0 && displayNames.length > 1 ? (
                <Text
                  style={[
                    styles.headerNamesText,
                    { fontSize: fontResizer(12), fontFamily: 'Inter' },
                  ]}
                >
                  & {allNames.length} others
                </Text>
              ) : (
                <Text />
              )}
            </Text>
            <Text style={[styles.headerNamesText, { fontSize: fontResizer(12) }]}>
              {participantsNames.length} participants
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    height: 24,
    // width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // left: 20,
    marginLeft: 20,
  },
  headerNamesText: {
    fontFamily: 'Inter',
    fontSize: 17,
    color: '#212121',
  },
  addButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#9155FD',
    textTransform: 'uppercase',
  },
})

export default ChatHeader
