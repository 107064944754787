import { SESSION_TIMEOUT } from '../actions/login'
import initialState from '../store/initialState'

export default (state = initialState.session, action) => {
  switch (action.type) {
    case SESSION_TIMEOUT:
      return {
        ...state,
        sessionTimeout: action.timeout,
      }
    default:
      return state
  }
}
