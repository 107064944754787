import React from 'react'
import momentTz from 'moment-timezone'
import moment from 'moment'
import api from '../../utils/api'
import { store } from '../../store/configStore'
import { storage } from '../storage'
import _ from 'lodash'

const convertDateTimeToUTC = (dateTime, timezone) => {
  const sourceDate = momentTz.tz(dateTime, timezone)
  return moment.utc(sourceDate)
}

const syncNotifications = async (notifications, canThrowError = false) => {
  const state = store.getState()
  const subject = state.subjectStudyMetaData.subject
  const { id, timeZone } = subject
  const notificationsData = _.map(notifications, (nf) => ({
    subject: {
      id: id,
    },
    originatingTimeZone: timeZone,
    sentTime: convertDateTimeToUTC(nf.date, timeZone),
    status: nf.status,
    title: nf.request.content.title,
    message: nf.request.content.body,
    notificationId: nf.request.identifier,
  }))
}

export const handleNotifications = (notification, status) => {
  const state = store.getState()
  const appStatus = state.appStatus
  notification.status = status
  if (appStatus.isDeviceOnline) {
    syncNotifications([{ ...notification }])
  } else {
    storeOfflineNotifications(notification)
  }
}

const storeOfflineNotifications = async (notification) => {
  try {
    let offlineNotifications = []
    offlineNotifications = storage.get('offlineNotifications')
    if (offlineNotifications.length != 0) {
      offlineNotifications = JSON.parse(offlineNotifications)
    }
    offlineNotifications.push(notification)
    const encryptedNotifications = JSON.stringify(offlineNotifications)
    storage.set('offlineNotifications', encryptedNotifications)
  } catch (error) {
    console.log('Failed to store notifications offline')
  }
}

export const syncOfflineNotifications = async () => {
  try {
    let offlineNotifications = []
    offlineNotifications = storage.get('offlineNotifications')
    if (offlineNotifications.length != 0) {
      offlineNotifications = JSON.parse(offlineNotifications)
    }
    await syncNotifications(offlineNotifications, true)
    console.log('Offline notifications synced successfully')
    storage.delete('offlineNotifications')
    syncOfflineFailedNotifications()
  } catch (error) {
    console.log('Failed to sync offline  notifications')
  }
}

const syncFailedNotifications = async (notificationIds, canThrowError = false) => {
}

export const syncOfflineFailedNotifications = async () => {
  try {
    let offlineFailedNotifications = []
    offlineFailedNotifications = storage.get('offlineFailedNotifications')
    if (offlineFailedNotifications.length != 0) {
      offlineFailedNotifications = JSON.parse(offlineFailedNotifications)
    }
    await syncFailedNotifications(offlineFailedNotifications, true)
    console.log('Offline failed notifications synced successfully')
    storage.delete('offlineFailedNotifications')
  } catch (error) {
    console.log('Failed to sync offline  failed notifications')
  }
}

export const storeOfflineFailedNotifications = async (notificationId) => {
  try {
    let offlineFailedNotifications = []
    offlineFailedNotifications = storage.get('offlineFailedNotifications')
    if (offlineFailedNotifications.length != 0) {
      offlineFailedNotifications = JSON.parse(offlineFailedNotifications)
    }
    offlineFailedNotifications.push(notificationId)
    const encryptedNotifications = JSON.stringify(offlineFailedNotifications)
    storage.set('offlineFailedNotifications', encryptedNotifications)
  } catch (error) {
    console.log('Failed to store failed notifications offline')
  }
}

export const handleFailedNotifications = (notificationId) => {
  const state = store.getState()
  const appStatus = state.appStatus
  if (appStatus.isDeviceOnline) {
    syncFailedNotifications([notificationId])
  } else {
    storeOfflineFailedNotifications(notificationId)
  }
}
