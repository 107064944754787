import React from 'react'
import constants from '../constants/constants'
import { store } from '../store/configStore'
import Constants from 'expo-constants'
import appConstants from '../constants/constants'

// export const authorize = (privilege, subjectPrivileges) => {
//     if(subjectPrivileges?.includes(privilege)) {
//         return true;
//     } return false;
// }

export const bottomTabs = [
  {
    privilege: 'VIEW_DIARY',
    tabName: 'diaryTab',
  },
  {
    privilege: 'VIEW_CALL',
    tabName: 'meetingScheduleTab',
  },
  //due to bottom tab navigation unable to handle dynamic tabs, so commented home tab  as privilege is not given for subject
  // {
  //   privilege: 'VIEW_COMPLIANCE',
  //   tabName: 'homeTab',
  // },
  {
    privilege: 'VIEW_CHAT',
    tabName: 'chatTab',
  },
]

export const authorizeTabs = (subjectPrivileges) => {
  const authorizedTabs = bottomTabs
  //due to bottom tab navigation unable to handle dynamic tabs
  // .filter((tab) => {
  //   if (subjectPrivileges?.includes(tab.privilege)) {
  //     return true
  //   }
  //   return false
  // })
  const authorizedTabNames = authorizedTabs?.map((tab) => {
    return tab.tabName
  })
  authorizedTabNames.push('moreTab') // This tab needs no permissions
  return authorizedTabNames
}

export const Authorize = ({ children, privilege }) => {
  const state = store.getState()
  const subjectPrivileges = state.subjectPrivileges
  const applicationType = state.appStatus.appType
  if (subjectPrivileges?.includes(privilege)) {
    return children
  }
  if (appConstants.AppType.SITESTAFF === applicationType) {
    return children
  }
  return <></>
}
