// import { default as Form } from '../components/FormPreview';
import momentTz from 'moment-timezone'
import { Spinner } from 'native-base'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormConsumer from '../components/form/FormConsumer'
import { getFieldsFromFieldGroupList, getFieldGroupList } from '../selectors/field'
import { retrieveFormsWithFields, retrieveFormsWithFieldsByFormLevel } from '../actions/form'
import _ from 'lodash'
import { setPreviewToken } from '../utils/secureStorageUtils'
import constants from '../constants/constants'

export class FormPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formId: null,
      fieldId: null,
      previewLoading: true,
      studyId: null,
      siteId: null,
      primaryOrgCode: null,
      clientId: null,
      formLevel: null,
    }
  }
  async componentDidMount() {
    const {
      actions,
      screenProps: { t },
    } = this.props
    const formId = this._getParamFromURL('formId')
    const fieldId = this._getParamFromURL('fieldId')
    const primaryOrgCode = this._getParamFromURL('primaryOrgCode')
    const studyId = this._getParamFromURL('studyId')
    const jwt = this._getParamFromURL('jwt')
    const siteId = this._getParamFromURL('siteId')
    const clientId = this._getParamFromURL('clientId')
    const formLevel = this._getParamFromURL('formLevel')
    this.setState(prevState => ({
      ...prevState,
      formId, fieldId, studyId, siteId, primaryOrgCode, clientId, formLevel
    }), () => this.getFieldsList(formId, primaryOrgCode, studyId, clientId, formLevel))
    
    setPreviewToken(jwt)

  }

  getFieldsList = async (formId,primaryOrgCode, studyId, clientId, formLevel) => {
    const { actions } = this.props
    if(_.isEqual(formLevel, constants.targetEntityType.STUDY)){
      await actions.retrieveFormsWithFields(null, formId, true, null,{primaryOrgCode, studyId})
    }else{
      const loClientId = _.isEqual(formLevel, constants.targetEntityType.GLOBAL) ? "" : clientId
      await actions.retrieveFormsWithFieldsByFormLevel(loClientId, formId, primaryOrgCode)
    }
    this.setState({ previewLoading: false })
  }

  _getParamFromURL = (param) => {
    console.log(window.location.search)
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }
  render() {
    const { formId, fieldId, previewLoading } = this.state
    const { screenProps, navigation, fieldList, form, loading, formAnswers, scheduleDate, isSvfUpdated, fieldGroupList } = this.props
    if (!formId || loading || previewLoading) {
      return (
        <View style={{ flex: 1 }}>
          <Spinner color="#4e89ae" />
        </View>
      )
    }
    const subjectTimezone = momentTz().tz('America/New_York').format('z')

    const selectedSvf = { status: 'COMPLETED', form: form, id: formId }

    if (!fieldList.length) {
      return <Text>This form does not have any fields</Text>
    }
    return (
      <View style={{ flex: 1 }}>
        <FormConsumer
          isDeviceOnline={true}
          mode={'preview'}
          subjectTimezone={subjectTimezone}
          navigation={navigation}
          loading={false}
          offlineSaveLoading={false}
          screenProps={screenProps}
          selectedSvf={selectedSvf}
          preview={true}
          previewFieldId={fieldId}
          formAnswers={formAnswers}
          scheduleDate={scheduleDate}
          isSvfUpdated={isSvfUpdated}
          fieldList={fieldList}
          fieldGroupList={fieldGroupList}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  console.log('state', state)
  return {
    form: !_.isEmpty(state.form.formList) ? state.form.formList[0] : {},
    loading: state.form.formListLoading, 
    formAnswers: state.formAnswers.data,
    fieldGroupList: getFieldGroupList(state),
    fieldList: getFieldsFromFieldGroupList(state),
    isSvfUpdated: state.subjectVisitForm.selectedSvf?.isSvfUpdated,
    scheduleDate: state.metaData.selectedDate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveFormsWithFields,
      retrieveFormsWithFieldsByFormLevel,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormPreview)
