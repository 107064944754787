// import { default as Form } from '../components/FormPreview';
import momentTz from 'moment-timezone'
import { Spinner } from 'native-base'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormConsumer from '../components/form/FormConsumer'
import { getFieldsFromFieldGroupListFormPrint, getFieldGroupListFormPrint } from '../selectors/field'
import { retrieveFormsWithFieldsFormPrint,getQuestionnaireMetaData,retrieveFormsWithFieldsByFormLevelFormPrint,getCrfDataBySvfIdFormPrint } from '../actions/formPrint'
import _ from 'lodash'
import { setPrintToken } from '../utils/secureStorageUtils'
import constants from '../constants/constants'

export class FormPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formId: null,
      loading: false,
      fieldId: null,
      svfId: null,
      subjectId: null,
      siteName: null,
      siteId: null,
      recordNo: null,
      selectedVisitId: null,
      clientId: null,
      formLevel: null,
    }
  }
  async componentDidMount() {
    const {
      actions,
      screenProps: { t },
    } = this.props
    const formId = this._getParamFromURL('formId')
    const fieldId = this._getParamFromURL('fieldId')
    const svfId = this._getParamFromURL('svfId')
    const subjectId = this._getParamFromURL('subjectId')
    const visitFormOid = this._getParamFromURL('visitFormOid')
    const isTraining = this._getParamFromURL('isTraining')
    const siteName = this._getParamFromURL('siteName')
    const siteId = this._getParamFromURL('siteId')
    const recordNo = this._getParamFromURL('recordNo')
    const selectedVisitId = this._getParamFromURL('selectedVisitId')
    const primaryOrgCode = this._getParamFromURL('primaryOrgCode')
    const studyId = this._getParamFromURL('studyId')
    const jwt = this._getParamFromURL('jwt')
    const clientId = this._getParamFromURL('clientId')
    const formLevel = this._getParamFromURL('formLevel')
    const printLocale = this._getParamFromURL('locale')

    /*let activeElements = this._getParamFromURL('activeElements')
    if (activeElements !== 'undefined') {
      activeElements = activeElements && JSON.parse(activeElements)
      siteName = activeElements.siteName
    } else {
      activeElements = {}
    }*/
    const printOptions = {
      printHeader: this._getParamFromURL('printHeader') === 'true',
      printFooter: this._getParamFromURL('printFooter') === 'true',
      printLayout: this._getParamFromURL('printLayout') || 'mobile',
      primaryOrgCode,
      studyId,
      siteId,
      formId,
      clientId,
      formLevel
      //...activeElements,
    }
    this.setState({
      formId,
      fieldId,
      svfId,
      printOptions,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      selectedVisitId,
      clientId,
      formLevel,
      printLocale
    })
    setPrintToken(jwt);
    if (svfId) {
      await actions.getQuestionnaireMetaData(null, formId, true, svfId, {primaryOrgCode, studyId, siteId})
      await actions.getCrfDataBySvfIdFormPrint(svfId, primaryOrgCode, studyId, siteId,subjectId,selectedVisitId)
    } else {
      if(_.isEqual(formLevel, constants.targetEntityType.STUDY)){
        await actions.retrieveFormsWithFieldsFormPrint(null, formId, true, svfId, {primaryOrgCode, studyId})
      }else{
        const loClientId = _.isEqual(formLevel, constants.targetEntityType.GLOBAL) ? "" : clientId
        await actions.retrieveFormsWithFieldsByFormLevelFormPrint(loClientId, formId, primaryOrgCode)
      }
    }
  }

  _getParamFromURL = (param) => {
    console.log(window.location.search)
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }
  render() {
    const {
      formId,
      fieldId,
      svfId,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      selectedVisitId,
      printLocale,
    } = this.state
    const { screenProps, navigation, fieldList, form, formAnswersLoading, formListLoading, formAnswers, scheduleDate, isSvfUpdated, fieldGroupList } =
      this.props
    let printOptions = { ...this.state.printOptions }
     printOptions = {
      ...printOptions,
      formName: form.formName,
    }
    if (!formId || formListLoading || formAnswersLoading) {
      return (
        <View style={{ flex: 1 }}>
          <Spinner color="#4e89ae" />
        </View>
      )
    }
    const subjectTimezone = momentTz().tz('America/New_York').format('z')

    const selectedSvf = { form: form, status: 'COMPLETED', svfId: svfId }

    if (!fieldList.length) {
      return <Text>This form does not have any fields</Text>
    }

    return (
      <View style={{ flex: 1 }}>
        <FormConsumer
        screenProps={{
          ...screenProps,
          t: (text) => screenProps.t(text, {locale:printLocale})
        }}
          isDeviceOnline={true}
          mode={'printView'}
          subjectTimezone={subjectTimezone}
          navigation={navigation}
          loading={false}
          offlineSaveLoading={false}
          selectedSvf={selectedSvf}
          printOptions={printOptions}
          preview={true}
          previewFieldId={fieldId}
          formId={formId}
          svfId={svfId}
          subjectId={subjectId}
          visitFormOid={visitFormOid}
          isTraining={isTraining}
          siteName={siteName}
          siteId={siteId}
          recordNo={recordNo}
          selectedVisitId={selectedVisitId}
          formAnswers={formAnswers}
          scheduleDate={scheduleDate}
          isSvfUpdated={isSvfUpdated}
          fieldList={fieldList}
          fieldGroupList={fieldGroupList}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  fieldList: getFieldsFromFieldGroupListFormPrint(state),
  form: !_.isEmpty(state.formPrint.formList) ? state.formPrint.formList[0] : {},
  formListLoading: state.formPrint.formListLoading,
  formAnswersLoading: state.formPrint.formAnswers.loading,
  formAnswers: state.formPrint.formAnswers.data,
  fieldGroupList: getFieldGroupListFormPrint(state),
  isSvfUpdated: state.subjectVisitForm.selectedSvf?.isSvfUpdated,
  scheduleDate: state.metaData.selectedDate,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveFormsWithFieldsFormPrint,
      getQuestionnaireMetaData,
      getCrfDataBySvfIdFormPrint,
      retrieveFormsWithFieldsByFormLevelFormPrint
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormPrint)
