import {
  PUSH_FORM_DATA_TO_QUEUE,
  STORE_SYNC_QUEUE_DATA,
  UPDATE_FORM_STATUS_IN_QUEUE,
  REMOVE_FROM_QUEUE,
} from '../actions/syncQueue'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'
import _ from 'lodash'
import { UPDATE_LOGGEDIN_USER } from '../actions/users'

export default (state = initialState.syncQueue, action) => {
  switch (action.type) {
    case PUSH_FORM_DATA_TO_QUEUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.currentUserId]: {
            ...state.data[action.currentUserId],
            [new Date().valueOf()]: {
              ...action.queueItem,
            },
          },
        },
      }
    case STORE_SYNC_QUEUE_DATA:
      return {
        ...state,
        data: action.data,
      }
    case UPDATE_FORM_STATUS_IN_QUEUE:
      return !_.isEmpty(state.data[action.currentUserId]?.[action.key]) ? {
        ...state,
        data: {
          ...state.data,
          [action.currentUserId]: {
            ...state.data[action.currentUserId],
            [action.key]: {
              ...state.data[action.currentUserId]?.[action.key],
              status: action.status,
              // attempts: action.attempts,
            },
          }
          
        },
      } : { ...state }
    case REMOVE_FROM_QUEUE: {
      delete state.data[action.currentUserId]?.[action.key]
      return {
        ...state,
      }
    }
   case UPDATE_LOGGEDIN_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          sessionUser: action.user
        }
      }
    }
    case PURGE: {
      return {
        data: {},
      }
    }
    default:
      return state
  }
}
