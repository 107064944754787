import React, { Component } from 'react'
import { connect } from 'react-redux'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import styles from '../components/styles/trainingStyles'
import sessionTimeoutLogo from '../constants/sessionTimeoutLogo'
import storage from '../utils/storage/storage'
import appConstants from '../constants/appConstants'
import NavigationService from './navigationService'
import btnStyles from '../components/securePIN/pinStyles'
import { buttonBackgroundColor } from '../containers/NavigationScreens'
class SessionTimeoutScreen extends Component {
  componentDidMount() {}

  redirectToLogin = () => {
    storage.delete('SubjectAuthorizationToken')
    NavigationService.navigate('Login', {fromLoginWithPin : true})
  }

  render() {
    const {
      screenProps: { t },
    } = this.props
    return (
      <View style={styles.sessionTimeoutContainer}>
        <Image
          source={{
            uri: `data:image/png;base64,${sessionTimeoutLogo}`,
          }}
          resizeMode="contain"
          style={[styles.image]}
        />
        <Text style={[styles.sessionLoginText, styles.sessionTextColor]}>
          {t('SessionTimeoutMessage')}
        </Text>
        <Text style={[styles.sessionLoginText, styles.sessionTextColor]}>
          <Text style={{ color: '#9155FD' }} onPress={this.redirectToLogin}>
            {t('Click')}
          </Text>{' '}
          <Text>{t('LoginText')}</Text>
        </Text>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.changeLanguage.selectedLanguage,
  loading: state.loading,
  currentScreen: state.appStatus.currentScreen,
  deviceLocation: state.deviceLocation,
})

export default connect(mapStateToProps)(SessionTimeoutScreen)
