import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt/lib'
import Constants from 'expo-constants'

/* id of Subject */

const KEY = Constants.expoConfig.extra.encryptionSecretKey;
export const encrypt = (data, userId, rsaPublicKey) => {
  // alert("Data Encrypting!");
  if (typeof data !== 'string') throw new Error(`expected string but got ${typeof data}`)
  const aesKey = genAESKey()
  const aesOptions = genAESOptions(userId)
  const aesEncData = CryptoJS.AES.encrypt(data, aesKey, aesOptions)
  const rsaEncryptedAesKey = encryptAESKey(aesKey, rsaPublicKey)
  const encryptedTransaction = {
    userId,
    payload: aesEncData.toString(),
    encAesKey: rsaEncryptedAesKey,
  }
  return encryptedTransaction
}

const encryptAESKey = (aesKey, rsaPublicKey) => {
  // encrypt AES key with RSA public key
  const rsaEncrypt = new JSEncrypt()
  rsaEncrypt.setPublicKey(rsaPublicKey)
  const rsaEncryptedAesKey = rsaEncrypt.encrypt(aesKey.toString())
  return rsaEncryptedAesKey
}
const genAESKey = () => {
  const secretPhrase = CryptoJS.lib.WordArray.random(16)
  const salt = CryptoJS.lib.WordArray.random(128 / 8)
  // aes key 128 bits (16 bytes) long
  const aesKey = CryptoJS.PBKDF2(secretPhrase.toString(), salt, {
    keySize: 128 / 32,
  })
  return aesKey
}

const genAESOptions = (vector) => {
  // initialization vector - 1st 16 chars of userId
  const iv = CryptoJS.enc.Utf8.parse(vector.slice(0, 16))
  const aesOptions = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv }
  return aesOptions
}

export const encryptString = (val) => {
  const encryptedVal = CryptoJS.AES.encrypt(val, KEY);
  return encryptedVal.toString();
}

export const decryptString = (val) => {
  if(!val){
    return val
  }
  const bytes = CryptoJS.AES.decrypt(val, KEY);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
}