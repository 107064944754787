import WebModal from 'modal-react-native-web'
import { Button } from 'native-base'
import React, { Component } from 'react'
import { Dimensions, Text, View } from 'react-native'
import { buttonBackgroundColor } from '../containers/NavigationScreens'
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

class WebAlert extends Component {
  renderLogoutDialogue = () => {
    const { message, t, headerText, buttons } = this.props
    return (
      <View
        style={{
          display: 'table',
          backgroundColor: 'white',
          borderWidth: 1,
          borderRadius: 5,
          flexDirection: 'column',
          width: SCREEN_WIDTH >= 1030 ? '35%' : '75%',
          height: '20%',
          marginTop: SCREEN_HEIGHT / 4,
          justifyContent: 'center',
          alignContent: 'center',
          alignSelf: 'center',
        }}
      >
        <View style={{ flex: 3, padding: 10 }}>
          <Text style={{ fontSize: 18, fontFamily: 'Inter' }}>{headerText}</Text>
          <Text style={{ alignSelf: 'center', padding: 10, fontSize: 16, fontFamily: 'Inter' }}>
            {message}
          </Text>
        </View>
        <View style={{ flex: 3, padding: 10, marginTop: 20, flexDirection: 'row', justifyContent:'center' }}>
        {buttons != null &&
        (buttons.map(
          (btn) => 
        <View style={{ flexDirection: 'row-reverse' , justifyContent: 'center'}}>
            <View style={{ paddingLeft: 10, alignContent:'center', flexWrap:'wrap' }}>
              <Button
              onPress={() => {
                btn.action()
              }}
              style={{
                paddingLeft: 10,
                height: 30,
                paddingRight: 10,
                backgroundColor: buttonBackgroundColor,
                borderRadius: 5,
              }}
            >
              <Text style={{ color: 'white', fontFamily: 'Inter', textTransform: 'uppercase' }}>
                {t(btn.title)}
              </Text>
            </Button>
          </View>
            </View>))}
        </View>
      </View>
    )
  }

  render() {
    return (
      <WebModal
        style={{ width: '95%', height: SCREEN_HEIGHT, alignContent: 'center', padding: 30 }}
        // onShow={()=>this.refresh()}
        transparent={true}
        animationType="slide"
        visible={true}
      >
        {this.renderLogoutDialogue()}
      </WebModal>
    )
  }
}

export default WebAlert
