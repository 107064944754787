import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import styles from '../components/styles/trainingStyles'
import storage from '../utils/storage/storage'
import NavigationService from './navigationService'
import btnStyles from '../components/securePIN/pinStyles'
import { buttonBackgroundColor } from '../containers/NavigationScreens'

const InvalidAccessPage = (props) => {
  const redirectToLogin = () => {
    storage.delete('SubjectAuthorizationToken')
    NavigationService.navigate('Login')
  }

  const {
    msg,
    screenProps: { t },
  } = props
  return (
    <View style={styles.sessionTimeoutContainer}>
      <Text style={styles.sessionText}>{msg}</Text>
      <TouchableOpacity
        style={[
          btnStyles.loginButton,
          {
            color: '#ffffff',
            marginTop: 10,
            textTransform: 'uppercase',
            backgroundColor: buttonBackgroundColor,
          },
        ]}
        onPress={redirectToLogin}
      >
        {t('LoginACSLogin')}
      </TouchableOpacity>
    </View>
  )
}

export default InvalidAccessPage
