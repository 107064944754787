import React, { Component } from 'react'
import { Container, ListItem, Item, Input, Body, Icon } from 'native-base'
import PropTypes from 'prop-types'
import { View, ScrollView, FlatList, Dimensions, ActivityIndicator, Text } from 'react-native'
import styles from '../../styles/listStyles'
import SearchInput, { createFilter } from 'react-native-search-filter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFilteredStudies, getSearchTerm } from '../../selectors/study'
import { updateStudySearchTerm } from '../../actions/study'

class StudyList extends Component {
  constructor(props) {
    super(props)
  }

  searchUpdated(term) {
    const { actions } = this.props
    actions.updateStudySearchTerm(term)
  }

  render() {
    const { loading, list, onStudySelect, studies, searchTerm } = this.props

    return (
      <Container style={styles.containerStyle}>
        {/* ------------- search bar ------------------------ */}
        <View style={{ margin: 10 }}>
          <Item searchBar rounded>
            <Icon name="ios-search" style={{ padding: 2 }} />
            <Input
              placeholder="Search"
              style={styles.textStyle}
              autoCorrect={false}
              value={searchTerm}
              onChangeText={(term) => {
                this.searchUpdated(term)
              }}
            />
          </Item>
        </View>

        {/* ------------- Loading ------------------------ */}
        {loading ? (
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} />
        ) : studies.length > 0 ? (
          /* ------------- Render list ------------------------ */
          <FlatList
            data={studies}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <ListItem
                thumbnail
                onPress={() => {
                  onStudySelect(item)
                }}
              >
                <Body>
                  <View style={{ justifyContent: 'flex-start' }}>
                    <Text style={styles.textStyle}>{item.studyNumber}</Text>
                    <Text style={styles.subTextStyle}>Sponsor : {item.sponsorName}</Text>
                  </View>
                </Body>
              </ListItem>
            )}
          />
        ) : (
          /* ------------- Render No data ------------------------ */
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={[styles.textStyle, { textAlign: 'center' }]}>No data.</Text>
          </View>
        )}
      </Container>
    )
  }
}

StudyList.defaultProps = {
  list: [],
  onStudySelect: () => null,
  loading: false,
  searchTerm: '',
}
StudyList.propTypes = {
  list: PropTypes.arrayOf(Object),
  onStudySelect: PropTypes.func,
  loading: PropTypes.bool,
  searchTerm: PropTypes.string,
}

const mapStateToProps = (state) => ({
  studies: getFilteredStudies(state),
  searchTerm: getSearchTerm(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateStudySearchTerm,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(StudyList)
// export default StudyList
