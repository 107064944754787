import React from "react"
import { StyleSheet } from "react-native"
import PropTypes from 'prop-types'
import ScalableScale from "./ScalableScale"
import { alignments } from "../alignments"

const NRSVerticalScale = (props) => {
    const { start, end, step, value, disabled, id, isPercentage, onNumberChange, orientation, fontSize } = props
    return (
        <ScalableScale 
            start={start}
            end={end}
            step={step}
            value={value}
            disabled={disabled}
            id={id}
            isPercentage={isPercentage}
            onNumberChange={onNumberChange}
            orientation={orientation} 
            fontSize={fontSize}
        />
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        marginBottom: 10
    }
});

NRSVerticalScale.defaultProps = {
    isPercentage: false,
    start: 0,
    end: 10,
    step: 1,
    value: null,
    onNumberChange: () => null,
    disabled: false,
    id: null,   
    orientation: alignments.VERTICAL
  }
  
NRSVerticalScale.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onNumberChange: PropTypes.func,
    isPercentage: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    orientation: PropTypes.string,
    fontSize: PropTypes.number.isRequired
  }
export default NRSVerticalScale