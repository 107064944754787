import React, { Component } from 'react'
import { Dimensions, View, Platform } from 'react-native'
import WebDateInput from '../webDatePicker/WebDateInput'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import DatePickerNative from '../appDatePicker/DatePickerNative'

export class DateInput extends Component {
  state = {
    date: null,
  }

  componentDidMount() {
    this.setInitialDateValue()
  }

  setInitialDateValue = () => {
    const {
      field: { crfData },
    } = this.props
    this.setState({
      date: crfData && crfData.fieldValue ? crfData.fieldValue : null,
    })
  }

  changeDate = (date) => {
    const {
      form,
      field: { id },
    } = this.props
    const { setFieldsValue, getFieldsValue } = form
    this.setState({ date })
    setFieldsValue({
      [id]: date,
    })
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema },
      t,
      disabled,
      fieldAnswer,
    } = this.props
    const { date } = this.state
    const crfData = { ...fieldAnswer }

    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {this.props.form.getFieldDecorator(`${id}`, {
          initialValue: crfData && crfData.fieldValue ? crfData.fieldValue : null,
          valuePropName: Platform.OS === 'web' ? 'date' : 'value',
          rules: [
            {
              required: isRequired,
              message: t('DateValMsg'),
              whitespace: true,
            },
          ],
        })(
          Platform.OS !== 'web' ? (
            <DatePickerNative
              mode="date"
              onChange={(date) => this.changeDate(date)}
              valueFormat="DD-MMM-YYYY"
              setFormat="DD-MMM-YYYY"
              disabled={disabled}
              testID={`DateInput-Field-${id}-view`}
            />
          ) : (
            <WebDateInput
              onDateChange={this.changeDate}
              format="dd-MMM-yyyy"
              showTimeInput={false}
              timeFormat="HH:mm"
              placeHolder={t('SelectDate')}
              valueFormat="DD-MMM-YYYY"
              disabled={disabled}
              testID={`DateInput-Field-${id}-view`}
            />
          )
        )}
      </View>
    )
  }
}
