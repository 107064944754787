import moment from 'moment'
import momentTz from 'moment-timezone'
import appConstants from '../constants/appConstants'

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE = 'DD-MMM-YYYY'
export const DATETIME12 = 'DD-MMM-YYYY hh:mm A'

const convertDateToServerTimezone = (date) => {
  if (appConstants.serverTimeZone !== 'UTC') {
    return moment.tz(date, appConstants.serverTimeZone).format(DATETIME12)
  }
  return moment.utc(date).format(DATETIME12)
}

export const formatDateInTimezone = (date, format, timeZone, isForm = false) => {
  const timezone = timeZone ? momentTz(date).tz(timeZone).format('z') : null
  const serverDate = convertDateToServerTimezone(date)
  return timeZone
    ? `${serverDate} ${timezone}`
    : isForm
    ? moment.utc(date, 'HH:mm:ss').format(format)
    : moment.utc(date).format(format)
}

export const convertUtcToSubjectTimezone = (date, format) => {
  const dateString = moment.utc(date).format(format);
  return moment(dateString)
}

function getMomentFromDateMaybe(date) {
  return typeof date === 'string' && date ? moment.utc(date) : null
}

export function isTimeIsInRange(start, end, now) {
  const visitStartDate = getMomentFromDateMaybe(start)
  const visitEndDate = getMomentFromDateMaybe(end)

  if (visitStartDate && visitEndDate) {
    const inRange = now.isBetween(visitStartDate, visitEndDate, 'second')
    return inRange
  }

  return false
}