import initialState from '../store/initialState'
// import { STORE_APP_STATUS } from '../actions/storeAppStatus';
import {
  UPDATE_SYNC_COMPLETE_STATUS,
  STORE_OFFLINE_FORMS_TO_SYNC,
  STORE_APP_STATUS,
  STORE_CURRENT_SCREEN,
  STORE_SYNC_STATUS,
  UPDATE_DRAFT_DATA,
  UPDATE_SYNC_QUEUE_TIMER,
  UPDATE_APP_TYPE,
  UPDATE_IS_ACKNOWLEDGED,
  UPDATE_SPACE_AVAILABLE,
  UPDATE_DOWNLOADED_IMAGE_TIMER,
  UPDATE_SPACE_ACKNOWLEDGEMENT,
  UPDATE_ON_MOBILE_STATUS
} from '../actions/storeAppStatus'
import { UPDATE_TRAINING_FORMS } from '../actions/subjectVisitForm'
import { PURGE } from 'redux-persist'

const storeAppStatus = (state = initialState.appStatus, action) => {
  switch (action.type) {
    case STORE_APP_STATUS:
      return { ...state, isDeviceOnline: action.isDeviceOnline }
    case STORE_CURRENT_SCREEN:
      return { ...state, currentScreen: action.screen }
    case STORE_OFFLINE_FORMS_TO_SYNC:
      return { ...state, OfflineFormsToSync: action.noOfForms }
    case STORE_SYNC_STATUS:
      return { ...state, isSyncing: action.value }
    case UPDATE_SYNC_COMPLETE_STATUS:
      return { ...state, syncCompleted: action.value }
    case UPDATE_TRAINING_FORMS:
      return { ...state, trainingForms: action.trainingForms }
    case UPDATE_DRAFT_DATA:
      return { ...state, draftData: action.data }
    case PURGE:
      return {
        isDeviceOnline: true,
        OfflineFormsToSync: 0,
        currentScreen: 'FALSE',
        isSyncing: false,
        syncCompleted: false,
        trainingForms: [],
        draftData: [],
        isAcknowledged: false,
        storage: {
          isStorageAvailable: true,
          isSpaceAlertAcknowledged: false,
        },
        timers: {
          downloadImageTimerId: null,
        }
      }
    case UPDATE_SYNC_QUEUE_TIMER: {
      return {
        ...state,
        syncQueueTimerId: action?.timerId
      }
    }
    case UPDATE_IS_ACKNOWLEDGED: {
      return {
        ...state,
        isAcknowledged: action?.isAcknowledged
      }
    }
    case UPDATE_APP_TYPE:
      return { ...state, appType: action.appType }
    case UPDATE_SPACE_AVAILABLE:
        return {
          ...state,
          storage : {
            ...state.storage,
            isStorageAvailable: action.data,
          }  
        }
    case UPDATE_DOWNLOADED_IMAGE_TIMER: {
        return {
            ...state,
            timers: {
              ...state.timers,
              downloadImageTimerId: action?.timerId
            }     
          }
      }
    case UPDATE_SPACE_ACKNOWLEDGEMENT: {
      return {
      ...state,
      storage : {
        ...state.storage,
        isSpaceAlertAcknowledged: action?.isAcknowledged,
      } 
    }
  }
  case UPDATE_ON_MOBILE_STATUS:
      return { ...state, onMobile: action.onMobile }
    default:
      return state
  }
}

export default storeAppStatus
