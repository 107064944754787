export default {
"Africa/Abidjan":"Afrika/Abidjan",
"Africa/Accra":"Afrika/Akra",
"Africa/Addis_Ababa":"Afrika/Addis_Ababa",
"Africa/Algiers":"Afrika/Cezayir",
"Africa/Asmara":"Afrika/Asmara",
"Africa/Asmera":"Afrika/Asmera",
"Africa/Bamako":"Afrika/Bamako",
"Africa/Bangui":"Afrika/Bangui",
"Africa/Banjul":"Afrika/Banjul",
"Africa/Bissau":"Afrika/Bissau",
"Africa/Blantyre":"Afrika/Blantyre",
"Africa/Brazzaville":"Afrika/Brazzaville",
"Africa/Bujumbura":"Afrika/Bujumbura",
"Africa/Cairo":"Afrika/Kahire",
"Africa/Casablanca":"Afrika/Kazablanka",
"Africa/Ceuta":"Afrika/Ceuta",
"Africa/Conakry":"Afrika/Conakry",
"Africa/Dakar":"Afrika/Dakar",
"Africa/Dar_es_Salaam":"Afrika/Dar_es_Selâm",
"Africa/Djibouti":"Afrika/Cibuti",
"Africa/Douala":"Afrika/Douala",
"Africa/El_Aaiun":"Afrika/El_Aaiun",
"Africa/Freetown":"Afrika/Freetown",
"Africa/Gaborone":"Afrika/Gaborone",
"Africa/Harare":"Afrika/Harare",
"Africa/Johannesburg":"Afrika/Johannesburg",
"Africa/Juba":"Afrika/Juba",
"Africa/Kampala":"Afrika/Kampala",
"Africa/Khartoum":"Afrika/Hartum",
"Africa/Kigali":"Afrika/Kigali",
"Africa/Kinshasa":"Afrika/Kinşasa",
"Africa/Lagos":"Afrika/Lagos",
"Africa/Libreville":"Afrika/Libreville",
"Africa/Lome":"Afrika/Lome",
"Africa/Luanda":"Afrika/Luanda",
"Africa/Lubumbashi":"Afrika/Lubumbashi",
"Africa/Lusaka":"Afrika/Lusaka",
"Africa/Malabo":"Afrika/Malabo",
"Africa/Maputo":"Afrika/Maputo",
"Africa/Maseru":"Afrika/Maseru",
"Africa/Mbabane":"Afrika/Mbabane",
"Africa/Mogadishu":"Afrika/Mogadişu",
"Africa/Monrovia":"Afrika/Monrovia",
"Africa/Nairobi":"Afrika/Nairobi",
"Africa/Ndjamena":"Afrika/Ndjamena",
"Africa/Niamey":"Afrika/Niamey",
"Africa/Nouakchott":"Afrika/Nouakchott",
"Africa/Ouagadougou":"Afrika/Ouagadougou",
"Africa/Porto-Novo":"Afrika/Porto-Novo",
"Africa/Sao_Tome":"Afrika/Sao_Tome",
"Africa/Timbuktu":"Afrika/Timbuktu",
"Africa/Tripoli":"Afrika/Trablus",
"Africa/Tunis":"Afrika/Tunus",
"Africa/Windhoek":"Afrika/Windhoek",
"America/Adak":"Amerika/Adak",
"America/Anchorage":"Amerika/Anchorage",
"America/Anguilla":"Amerika/Anguilla",
"America/Antigua":"Amerika/Antigua",
"America/Araguaina":"Amerika/Araguaina",
"America/Argentina/Buenos_Aires":"Amerika/Arjantin/Buenos_Aires",
"America/Argentina/Catamarca":"Amerika/Arjantin/Katamarca",
"America/Argentina/ComodRivadavia":"Amerika/Arjantin/Comodrivadavia",
"America/Argentina/Cordoba":"Amerika/Arjantin/Cordoba",
"America/Argentina/Jujuy":"Amerika/Arjantin/Jujuy",
"America/Argentina/La_Rioja":"Amerika/Arjantin/La_Rioja",
"America/Argentina/Mendoza":"Amerika/Arjantin/Mendoza",
"America/Argentina/Rio_Gallegos":"Amerika/Arjantin/Rio_Gallegos",
"America/Argentina/Salta":"Amerika/Arjantin/Salta",
"America/Argentina/San_Juan":"Amerika/Arjantin/San_Juan",
"America/Argentina/San_Luis":"Amerika/Arjantin/San_Luis",
"America/Argentina/Tucuman":"Amerika/Arjantin/Tucuman",
"America/Argentina/Ushuaia":"Amerika/Arjantin/Ushuaia",
"America/Aruba":"Amerika/Aruba",
"America/Asuncion":"Amerika/Asuncion",
"America/Atikokan":"Amerika/Atikokan",
"America/Atka":"Amerika/Atka",
"America/Bahia":"Amerika/Bahia",
"America/Bahia_Banderas":"Amerika/Bahia_Banderas",
"America/Barbados":"Amerika/Barbados",
"America/Belem":"Amerika/Belem",
"America/Belize":"Amerika/Belize",
"America/Blanc-Sablon":"Amerika/Blanc-Sablon",
"America/Boa_Vista":"Amerika/Boa_Vista",
"America/Bogota":"Amerika/Bogota",
"America/Boise":"Amerika/Boise",
"America/Buenos_Aires":"Amerika/Buenos_Aires",
"America/Cambridge_Bay":"Amerika/Cambridge_Bay",
"America/Campo_Grande":"Amerika/Campo_Grande",
"America/Cancun":"Amerika/Cancun",
"America/Caracas":"Amerika/Karakas",
"America/Catamarca":"Amerika/Katamarca",
"America/Cayenne":"Amerika/Cayenne",
"America/Cayman":"Amerika/Cayman",
"America/Chicago":"Amerika/Chicago",
"America/Chihuahua":"Amerika/Chihuahua",
"America/Ciudad_Juarez":"Amerika/Ciudad_Juarez",
"America/Coral_Harbour":"Amerika/Coral_Harbour",
"America/Cordoba":"Amerika/Cordoba",
"America/Costa_Rica":"Amerika/Costa_Rica",
"America/Creston":"Amerika/Creston",
"America/Cuiaba":"Amerika/Cuiaba",
"America/Curacao":"Amerika/Curacao",
"America/Danmarkshavn":"Amerika/Danmarkshavn",
"America/Dawson":"Amerika/Dawson",
"America/Dawson_Creek":"Amerika/Dawson_Creek",
"America/Denver":"Amerika/Denver",
"America/Detroit":"Amerika/Detroit",
"America/Dominica":"Amerika/Dominika",
"America/Edmonton":"Amerika/Edmonton",
"America/Eirunepe":"Amerika/Eirunepe",
"America/El_Salvador":"Amerika/El_Salvador",
"America/Ensenada":"Amerika/Ensenada",
"America/Fort_Nelson":"Amerika/Fort_Nelson",
"America/Fort_Wayne":"Amerika/Fort_Wayne",
"America/Fortaleza":"Amerika/Fortaleza",
"America/Glace_Bay":"Amerika/Glace_Bay",
"America/Godthab":"Amerika/Godthab",
"America/Goose_Bay":"Amerika/Goose_Bay",
"America/Grand_Turk":"Amerika/Grand_Turk",
"America/Grenada":"Amerika/Grenada",
"America/Guadeloupe":"Amerika/Guadeloupe",
"America/Guatemala":"Amerika/Guatemala",
"America/Guayaquil":"Amerika/Guayaquil",
"America/Guyana":"Amerika/Guyana",
"America/Halifax":"Amerika/Halifax",
"America/Havana":"Amerika/Havana",
"America/Hermosillo":"Amerika/Hermosillo",
"America/Indiana/Indianapolis":"Amerika/Indiana/Indianapolis",
"America/Indiana/Knox":"Amerika/Indiana/Knox",
"America/Indiana/Marengo":"Amerika/Indiana/Marengo",
"America/Indiana/Petersburg":"Amerika/Indiana/Petersburg",
"America/Indiana/Tell_City":"Amerika/Indiana/Tell_City",
"America/Indiana/Vevay":"Amerika/Indiana/Vevay",
"America/Indiana/Vincennes":"Amerika/Indiana/Vincennes",
"America/Indiana/Winamac":"Amerika/Indiana/Winamac",
"America/Indianapolis":"Amerika/Indianapolis",
"America/Inuvik":"Amerika/Inuvik",
"America/Iqaluit":"Amerika/Iqaluit",
"America/Jamaica":"Amerika/Jamaika",
"America/Jujuy":"Amerika/Jujuy",
"America/Juneau":"Amerika/Juneau",
"America/Kentucky/Louisville":"Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello":"Amerika/Kentucky/Monticello",
"America/Knox_IN":"Amerika/Knox_in",
"America/Kralendijk":"Amerika/Kralendijk",
"America/La_Paz":"Amerika/La_Paz",
"America/Lima":"Amerika/Lima",
"America/Los_Angeles":"Amerika/Los_Angeles",
"America/Louisville":"Amerika/Louisville",
"America/Lower_Princes":"Amerika/Lower_Princes",
"America/Maceio":"Amerika/Maceio",
"America/Managua":"Amerika/Managua",
"America/Manaus":"Amerika/Manaus",
"America/Marigot":"Amerika/Marigot",
"America/Martinique":"Amerika/Martinik",
"America/Matamoros":"Amerika/Matamoros",
"America/Mazatlan":"Amerika/Mazatlan",
"America/Mendoza":"Amerika/Mendoza",
"America/Menominee":"Amerika/Menominee",
"America/Merida":"Amerika/Merida",
"America/Metlakatla":"Amerika/Metlakatla",
"America/Mexico_City":"Amerika/Meksika_Şehir",
"America/Miquelon":"Amerika/Miquelon",
"America/Moncton":"Amerika/Moncton",
"America/Monterrey":"Amerika/Monterrey",
"America/Montevideo":"Amerika/Montevideo",
"America/Montreal":"Amerika/Montreal",
"America/Montserrat":"Amerika/Montserrat",
"America/Nassau":"Amerika/Nassau",
"America/New_York":"Amerika/New_York",
"America/Nipigon":"Amerika/Nipigon",
"America/Nome":"Amerika/İsim",
"America/Noronha":"Amerika/Noronha",
"America/North_Dakota/Beulah":"Amerika/Kuzey_Dakota/Beulah",
"America/North_Dakota/Center":"Amerika/Kuzey_Dakota/Merkez",
"America/North_Dakota/New_Salem":"Amerika/Kuzey_Dakota/Yeni_Salem",
"America/Nuuk":"Amerika/Nuuk",
"America/Ojinaga":"Amerika/Ojinaga",
"America/Panama":"Amerika/Panama",
"America/Pangnirtung":"Amerika/Pangnirtung",
"America/Paramaribo":"Amerika/Paramaribo",
"America/Phoenix":"Amerika/Phoenix",
"America/Port-au-Prince":"Amerika/Port-au-Prince",
"America/Port_of_Spain":"Amerika/İspanya Limanı",
"America/Porto_Acre":"Amerika/Porto_Acre",
"America/Porto_Velho":"Amerika/Porto_Velho",
"America/Puerto_Rico":"Amerika/Puerto_Rico",
"America/Punta_Arenas":"Amerika/Punta_Arenas",
"America/Rainy_River":"Amerika/Rainy_River",
"America/Rankin_Inlet":"Amerika/Rankin_Inlet",
"America/Recife":"Amerika/Recife",
"America/Regina":"Amerika/Regina",
"America/Resolute":"Amerika/Kararlı",
"America/Rio_Branco":"Amerika/Rio_Branco",
"America/Rosario":"Amerika/Rosario",
"America/Santa_Isabel":"Amerika/Santa_Isabel",
"America/Santarem":"Amerika/Santarem",
"America/Santiago":"Amerika/Santiago",
"America/Santo_Domingo":"Amerika/Santo_Domingo",
"America/Sao_Paulo":"Amerika/Sao_Paulo",
"America/Scoresbysund":"Amerika/Scoresbysund",
"America/Shiprock":"Amerika/Shiprock",
"America/Sitka":"Amerika/Sitka",
"America/St_Barthelemy":"Amerika/St_Barthelemy",
"America/St_Johns":"Amerika/St_Johns",
"America/St_Kitts":"Amerika/St_Kitts",
"America/St_Lucia":"Amerika/St_Lucia",
"America/St_Thomas":"Amerika/St_Thomas",
"America/St_Vincent":"Amerika/St_Vincent",
"America/Swift_Current":"Amerika/Swift_Current",
"America/Tegucigalpa":"Amerika/Tegucigalpa",
"America/Thule":"Amerika/Thule",
"America/Thunder_Bay":"Amerika/Thunder_Bay",
"America/Tijuana":"Amerika/Tijuana",
"America/Toronto":"Amerika/Toronto",
"America/Tortola":"Amerika/Tortola",
"America/Vancouver":"Amerika/Vancouver",
"America/Virgin":"Amerika/Virgin",
"America/Whitehorse":"Amerika/Whitehorse",
"America/Winnipeg":"Amerika/Winnipeg",
"America/Yakutat":"Amerika/Yakutat",
"America/Yellowknife":"Amerika/Yellowknife",
"Antarctica/Casey":"Antarktika/Casey",
"Antarctica/Davis":"Antarktika/Davis",
"Antarctica/DumontDUrville":"Antarktika/Dumontdurville",
"Antarctica/Macquarie":"Antarktika/Macquarie",
"Antarctica/Mawson":"Antarktika/Mawson",
"Antarctica/McMurdo":"Antarktika/McMurdo",
"Antarctica/Palmer":"Antarktika/Palmer",
"Antarctica/Rothera":"Antarktika/Rothera",
"Antarctica/South_Pole":"Antarktika/Güney Kutbu",
"Antarctica/Syowa":"Antarktika/Syowa",
"Antarctica/Troll":"Antarktika/Trol",
"Antarctica/Vostok":"Antarktika/Vostok",
"Arctic/Longyearbyen":"Arktik/Longyearbyen",
"Asia/Aden":"Asya/Aden",
"Asia/Almaty":"Asya/Almatı",
"Asia/Amman":"Asya/Amman",
"Asia/Anadyr":"Asya/Anadyr",
"Asia/Aqtau":"Asya/Aktau",
"Asia/Aqtobe":"Asya/Aktobe",
"Asia/Ashgabat":"Asya/Aşkabat",
"Asia/Ashkhabad":"Asya/Aşhabad",
"Asia/Atyrau":"Asya/Atırav",
"Asia/Baghdad":"Asya/Bağdat",
"Asia/Bahrain":"Asya/Bahreyn",
"Asia/Baku":"Asya/Bakü",
"Asia/Bangkok":"Asya/Bangkok",
"Asia/Barnaul":"Asya/Barnaul",
"Asia/Beirut":"Asya/Beyrut",
"Asia/Bishkek":"Asya/Bişkek",
"Asia/Brunei":"Asya/Brunei",
"Asia/Calcutta":"Asya/Kalküta",
"Asia/Chita":"Asya/Chita",
"Asia/Choibalsan":"Asya/Choibalsan",
"Asia/Chongqing":"Asya/Chongqing",
"Asia/Chungking":"Asya/Chungking",
"Asia/Colombo":"Asya/Kolombo",
"Asia/Dacca":"Asya/Dacca",
"Asia/Damascus":"Asya/Şam",
"Asia/Dhaka":"Asya/Dakka",
"Asia/Dili":"Asya/Dili",
"Asia/Dubai":"Asya/Dubai",
"Asia/Dushanbe":"Asya/Duşanbe",
"Asia/Famagusta":"Asya/Gazimağusa",
"Asia/Gaza":"Asya/Gazze",
"Asia/Harbin":"Asya/Harbin",
"Asia/Hebron":"Asya/Hebron",
"Asia/Ho_Chi_Minh":"Asya/Ho_Chi_Minh",
"Asia/Hong_Kong":"Asya/Hong Kong",
"Asia/Hovd":"Asya/Hovd",
"Asia/Irkutsk":"Asya/Irkutsk",
"Asia/Istanbul":"Asya/İstanbul",
"Asia/Jakarta":"Asya/Cakarta",
"Asia/Jayapura":"Asya/Jayapura",
"Asia/Jerusalem":"Asya/Kudüs",
"Asia/Kabul":"Asya/Kabil",
"Asia/Kamchatka":"Asya/Kamçatka",
"Asia/Karachi":"Asya/Karaçi",
"Asia/Kashgar":"Asya/Kaşgar",
"Asia/Kathmandu":"Asya/Katmandu",
"Asia/Katmandu":"Asya/Katmandu",
"Asia/Khandyga":"Asya/Khandyga",
"Asia/Kolkata":"Asya/Kalküta",
"Asia/Krasnoyarsk":"Asya/Krasnoyarsk",
"Asia/Kuala_Lumpur":"Asya/Kuala_Lumpur",
"Asia/Kuching":"Asya/Kuching",
"Asia/Kuwait":"Asya/Kuveyt",
"Asia/Macao":"Asya/Makao",
"Asia/Macau":"Asya/Makao",
"Asia/Magadan":"Asya/Magadan",
"Asia/Makassar":"Asya/Makassar",
"Asia/Manila":"Asya/Manila",
"Asia/Muscat":"Asya/Maskat",
"Asia/Nicosia":"Asya/Lefkoşa",
"Asia/Novokuznetsk":"Asya/Novokuznetsk",
"Asia/Novosibirsk":"Asya/Novosibirsk",
"Asia/Omsk":"Asya/Omsk",
"Asia/Oral":"Asya/Oral",
"Asia/Phnom_Penh":"Asya/Phnom_Penh",
"Asia/Pontianak":"Asya/Pontianak",
"Asia/Pyongyang":"Asya/Pyongyang",
"Asia/Qatar":"Asya/Katar",
"Asia/Qostanay":"Asya/Qostanay",
"Asia/Qyzylorda":"Asya/Qyzylorda",
"Asia/Rangoon":"Asya/Rangoon",
"Asia/Riyadh":"Asya/Riyad",
"Asia/Saigon":"Asya/Saygon",
"Asia/Sakhalin":"Asya/Sakhalin",
"Asia/Samarkand":"Asya/Semerkant",
"Asia/Seoul":"Asya/Seul",
"Asia/Shanghai":"Asya/Şangay",
"Asia/Singapore":"Asya/Singapur",
"Asia/Srednekolymsk":"Asya/Srednekolymsk",
"Asia/Taipei":"Asya/Taipei",
"Asia/Tashkent":"Asya/Taşkent",
"Asia/Tbilisi":"Asya/Tiflis",
"Asia/Tehran":"Asya/Tahran",
"Asia/Tel_Aviv":"Asya/Tel_Aviv",
"Asia/Thimbu":"Asya/Thimbu",
"Asia/Thimphu":"Asya/Thimphu",
"Asia/Tokyo":"Asya/Tokyo",
"Asia/Tomsk":"Asya/Tomsk",
"Asia/Ujung_Pandang":"Asya/Ujung_Pandang",
"Asia/Ulaanbaatar":"Asya/Ulaanbaatar",
"Asia/Ulan_Bator":"Asya/Ulan_Bator",
"Asia/Urumqi":"Asya/Urumçi",
"Asia/Ust-Nera":"Asya/Ust-Nera",
"Asia/Vientiane":"Asya/Vientiane",
"Asia/Vladivostok":"Asya/Vladivostok",
"Asia/Yakutsk":"Asya/Yakutsk",
"Asia/Yangon":"Asya/Yangon",
"Asia/Yekaterinburg":"Asya/Yekaterinburg",
"Asia/Yerevan":"Asya/Erivan",
"Atlantic/Azores":"Atlantik/Azorlar",
"Atlantic/Bermuda":"Atlantik/Bermuda",
"Atlantic/Canary":"Atlantik/Kanarya",
"Atlantic/Cape_Verde":"Atlantik/Cape_Verde",
"Atlantic/Faeroe":"Atlantik/Faroe",
"Atlantic/Faroe":"Atlantik/Faroe",
"Atlantic/Jan_Mayen":"Atlantik/Jan_Mayen",
"Atlantic/Madeira":"Atlantik/Madeira",
"Atlantic/Reykjavik":"Atlantik/Reykjavik",
"Atlantic/South_Georgia":"Atlantik/Güney_Gürcistan",
"Atlantic/St_Helena":"Atlantik/St Helena",
"Atlantic/Stanley":"Atlantik/Stanley",
"Australia/ACT":"Avustralya/ACT",
"Australia/Adelaide":"Avustralya/Adelaide",
"Australia/Brisbane":"Avustralya/Brisbane",
"Australia/Broken_Hill":"Avustralya/Broken_Hill",
"Australia/Canberra":"Avustralya/Canberra",
"Australia/Currie":"Avustralya/Currie",
"Australia/Darwin":"Avustralya/Darwin",
"Australia/Eucla":"Avustralya/Eucla",
"Australia/Hobart":"Avustralya/Hobart",
"Australia/LHI":"Avustralya/LHI",
"Australia/Lindeman":"Avustralya/Lindeman",
"Australia/Lord_Howe":"Avustralya/Lord_Howe",
"Australia/Melbourne":"Avustralya/Melbourne",
"Australia/NSW":"Avustralya/NSW",
"Australia/North":"Avustralya/Kuzey",
"Australia/Perth":"Avustralya/Perth",
"Australia/Queensland":"Avustralya/Queensland",
"Australia/South":"Avustralya/Güney",
"Australia/Sydney":"Avustralya/Sidney",
"Australia/Tasmania":"Avustralya/Tazmanya",
"Australia/Victoria":"Avustralya/Victoria",
"Australia/West":"Avustralya/Batı",
"Australia/Yancowinna":"Avustralya/Yancowinna",
"Brazil/Acre":"Brezilya/Acre",
"Brazil/DeNoronha":"Brezilya/Denoronha",
"Brazil/East":"Brezilya/Doğu",
"Brazil/West":"Brezilya/Batı",
"CET":"BU",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Kanada/Atlantik",
"Canada/Central":"Kanada/Merkez",
"Canada/Eastern":"Kanada/Doğu",
"Canada/Mountain":"Kanada/Dağ",
"Canada/Newfoundland":"Kanada/Newfoundland",
"Canada/Pacific":"Kanada/Pasifik",
"Canada/Saskatchewan":"Kanada/Saskatchewan",
"Canada/Yukon":"Kanada/Yukon",
"Chile/Continental":"Şili/Kıta",
"Chile/EasterIsland":"Şili/Paskalya Adası",
"Cuba":"Küba",
"EET":"YEMEK",
"EST":"EST",
"EST5EDT":"EST5EDT",
"Egypt":"Mısır",
"Eire":"Eire",
"Etc/GMT":"vb./GMT",
"Etc/GMT+0":"Etc/GMT+0",
"Etc/GMT+1":"Etc/GMT+1",
"Etc/GMT+10":"Etc/GMT+10",
"Etc/GMT+11":"Etc/GMT+11",
"Etc/GMT+12":"Etc/GMT+12",
"Etc/GMT+2":"Etc/GMT+2",
"Etc/GMT+3":"Etc/GMT+3",
"Etc/GMT+4":"Etc/GMT+4",
"Etc/GMT+5":"Etc/GMT+5",
"Etc/GMT+6":"Etc/GMT+6",
"Etc/GMT+7":"Etc/GMT+7",
"Etc/GMT+8":"Etc/GMT+8",
"Etc/GMT+9":"Etc/GMT+9",
"Etc/GMT-0":"Etc/GMT-0",
"Etc/GMT-1":"Etc/GMT-1",
"Etc/GMT-10":"Etc/GMT-10",
"Etc/GMT-11":"Etc/GMT-11",
"Etc/GMT-12":"Etc/GMT-12",
"Etc/GMT-13":"Etc/GMT-13",
"Etc/GMT-14":"Etc/GMT-14",
"Etc/GMT-2":"Etc/GMT-2",
"Etc/GMT-3":"Etc/GMT-3",
"Etc/GMT-4":"Etc/GMT-4",
"Etc/GMT-5":"Etc/GMT-5",
"Etc/GMT-6":"Etc/GMT-6",
"Etc/GMT-7":"Etc/GMT-7",
"Etc/GMT-8":"Etc/GMT-8",
"Etc/GMT-9":"Etc/GMT-9",
"Etc/GMT0":"Etc/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"Etc/UCT",
"Etc/UTC":"Etc/UTC",
"Etc/Universal":"Etc/Evrensel",
"Etc/Zulu":"Etc/Zulu",
"Europe/Amsterdam":"Avrupa/Amsterdam",
"Europe/Andorra":"Avrupa/Andorra",
"Europe/Astrakhan":"Avrupa/Astrakhan",
"Europe/Athens":"Avrupa/Atina",
"Europe/Belfast":"Avrupa/Belfast",
"Europe/Belgrade":"Avrupa/Belgrad",
"Europe/Berlin":"Avrupa/Berlin",
"Europe/Bratislava":"Avrupa/Bratislava",
"Europe/Brussels":"Avrupa/Brüksel",
"Europe/Bucharest":"Avrupa/Bükreş",
"Europe/Budapest":"Avrupa/Budapeşte",
"Europe/Busingen":"Avrupa/Otobüsler",
"Europe/Chisinau":"Avrupa/Kişinev",
"Europe/Copenhagen":"Avrupa/Kopenhag",
"Europe/Dublin":"Avrupa/Dublin",
"Europe/Gibraltar":"Avrupa/Cebelitarık",
"Europe/Guernsey":"Avrupa/Guernsey",
"Europe/Helsinki":"Avrupa/Helsinki",
"Europe/Isle_of_Man":"Avrupa/ADA_OF_MAN",
"Europe/Istanbul":"Avrupa/İstanbul",
"Europe/Jersey":"Avrupa/Jersey",
"Europe/Kaliningrad":"Avrupa/Kaliningrad",
"Europe/Kiev":"Avrupa/Kiev",
"Europe/Kirov":"Avrupa/Kirov",
"Europe/Kyiv":"Avrupa/Kiev",
"Europe/Lisbon":"Avrupa/Lizbon",
"Europe/Ljubljana":"Avrupa/Ljubljana",
"Europe/London":"Avrupa/Londra",
"Europe/Luxembourg":"Avrupa/Lüksemburg",
"Europe/Madrid":"Avrupa/Madrid",
"Europe/Malta":"Avrupa/Malta",
"Europe/Mariehamn":"Avrupa/Mariehamn",
"Europe/Minsk":"Avrupa/Minsk",
"Europe/Monaco":"Avrupa/Monako",
"Europe/Moscow":"Avrupa/Moskova",
"Europe/Nicosia":"Avrupa/Lefkoşa",
"Europe/Oslo":"Avrupa/Oslo",
"Europe/Paris":"Avrupa/Paris",
"Europe/Podgorica":"Avrupa/Podgorica",
"Europe/Prague":"Avrupa/Prag",
"Europe/Riga":"Avrupa/Riga",
"Europe/Rome":"Avrupa/Roma",
"Europe/Samara":"Avrupa/Samara",
"Europe/San_Marino":"Avrupa/San_Marino",
"Europe/Sarajevo":"Avrupa/Saraybosna",
"Europe/Saratov":"Avrupa/Saratov",
"Europe/Simferopol":"Avrupa/Simferopol",
"Europe/Skopje":"Avrupa/Üsküp",
"Europe/Sofia":"Avrupa/Sofya",
"Europe/Stockholm":"Avrupa/Stockholm",
"Europe/Tallinn":"Avrupa/Tallinn",
"Europe/Tirane":"Avrupa/Tirane",
"Europe/Tiraspol":"Avrupa/Tiraspol",
"Europe/Ulyanovsk":"Avrupa/Ulyanovsk",
"Europe/Uzhgorod":"Avrupa/Ujgorod",
"Europe/Vaduz":"Avrupa/Vaduz",
"Europe/Vatican":"Avrupa/Vatikan",
"Europe/Vienna":"Avrupa/Viyana",
"Europe/Vilnius":"Avrupa/Vilnius",
"Europe/Volgograd":"Avrupa/Volgograd",
"Europe/Warsaw":"Avrupa/Varşova",
"Europe/Zagreb":"Avrupa/Zagreb",
"Europe/Zaporozhye":"Avrupa/Zaporijya",
"Europe/Zurich":"Avrupa/Zürih",
"GB":"GB",
"GB-Eire":"GB Eire",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HST",
"Hongkong":"Hong Kong",
"Iceland":"İzlanda",
"Indian/Antananarivo":"Hintli/Antananarivo",
"Indian/Chagos":"Hintli/Chagos",
"Indian/Christmas":"Hintli/Noel",
"Indian/Cocos":"Hintli/Cocos",
"Indian/Comoro":"Hindistan/Komoro",
"Indian/Kerguelen":"Hintli/Kerguelen",
"Indian/Mahe":"Hintli/Mahe",
"Indian/Maldives":"Hindistan/Maldivler",
"Indian/Mauritius":"Hindistan/Mauritius",
"Indian/Mayotte":"Hindistan/Mayotte",
"Indian/Reunion":"Hintli/Reunion",
"Iran":"İran",
"Israel":"İsrail",
"Jamaica":"Jamaika",
"Japan":"Japonya",
"Kwajalein":"Kwajalein",
"Libya":"Libya",
"MET":"İLE",
"MST":"MST",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"Meksika/Bajanorte",
"Mexico/BajaSur":"Meksika/Bajasur",
"Mexico/General":"Meksika/Genel",
"NZ":"NEW NEW",
"NZ-CHAT":"NZ-SOHBET",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Pasifik/Apia",
"Pacific/Auckland":"Pasifik/Auckland",
"Pacific/Bougainville":"Pasifik/Bougainville",
"Pacific/Chatham":"Pasifik/Chatham",
"Pacific/Chuuk":"Pasifik/Chuuk",
"Pacific/Easter":"Pasifik/Paskalya",
"Pacific/Efate":"Pasifik/Efate",
"Pacific/Enderbury":"Pasifik/Enderbury",
"Pacific/Fakaofo":"Pasifik/Fakaofo",
"Pacific/Fiji":"Pasifik/Fiji",
"Pacific/Funafuti":"Pasifik/Funafuti",
"Pacific/Galapagos":"Pasifik/Galapagos",
"Pacific/Gambier":"Pasifik/Gambier",
"Pacific/Guadalcanal":"Pasifik/Guadalcanal",
"Pacific/Guam":"Pasifik/Guama",
"Pacific/Honolulu":"Pasifik/Honolulu",
"Pacific/Johnston":"Pasifik/Johnston",
"Pacific/Kanton":"Pasifik/Kanton",
"Pacific/Kiritimati":"Pasifik/Kiritimati",
"Pacific/Kosrae":"Pasifik/Kosrae",
"Pacific/Kwajalein":"Pasifik/Kwajalein",
"Pacific/Majuro":"Pasifik/Majuro",
"Pacific/Marquesas":"Pasifik/Marquesas",
"Pacific/Midway":"Pasifik/Midway",
"Pacific/Nauru":"Pasifik/Nauru",
"Pacific/Niue":"Pasifik/Niue",
"Pacific/Norfolk":"Pasifik/Norfolk",
"Pacific/Noumea":"Pasifik/Noumea",
"Pacific/Pago_Pago":"Pasifik/Pago_Pago",
"Pacific/Palau":"Pasifik/Palau",
"Pacific/Pitcairn":"Pasifik/Pitcairn",
"Pacific/Pohnpei":"Pasifik/Pohnpei",
"Pacific/Ponape":"Pasifik/Ponape",
"Pacific/Port_Moresby":"Pasifik/Port_Moresby",
"Pacific/Rarotonga":"Pasifik/Rarotonga",
"Pacific/Saipan":"Pasifik/Saipan",
"Pacific/Samoa":"Pasifik/Samoa",
"Pacific/Tahiti":"Pasifik/Tahiti",
"Pacific/Tarawa":"Pasifik/Tarawa",
"Pacific/Tongatapu":"Pasifik/Tongatapu",
"Pacific/Truk":"Pasifik/Truk",
"Pacific/Wake":"Pasifik/Uyanış",
"Pacific/Wallis":"Pasifik/Wallis",
"Pacific/Yap":"Pasifik/Yap",
"Poland":"Polonya",
"Portugal":"Portekiz",
"ROC":"ROC",
"ROK":"YIL",
"Singapore":"Singapur",
"Turkey":"Türkiye",
"UCT":"UT",
"US/Alaska":"ABD/Alaska",
"US/Aleutian":"ABD/Aleutian",
"US/Arizona":"ABD/Arizona",
"US/Central":"ABD/Merkez",
"US/East-Indiana":"ABD/Doğu Indiana",
"US/Eastern":"ABD/Doğu",
"US/Hawaii":"ABD/Hawaii",
"US/Indiana-Starke":"ABD/Indiana Starke",
"US/Michigan":"ABD/Michigan",
"US/Mountain":"ABD/Dağ",
"US/Pacific":"ABD/Pasifik",
"US/Samoa":"ABD/Samoa",
"UTC":"UTC",
"Universal":"Evrensel",
"W-SU":"W-SU",
"WET":"ISLAK",
"Zulu":"Zulu",
}