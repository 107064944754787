import React, { useState } from 'react'
import CheckBox from '../../components/fields/CheckBox'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { handlePrivacyPolicyAgreement } from '../../actions/privacyPolicyAndTerms'
import { updatePrivacyPolicy } from '../../actions/subjectStudyMetaData'
import _ from 'lodash'
import {openMeeting} from '../../actions/login'
import { connect } from 'react-redux'
import appConstants from '../../constants/constants'
import { bindActionCreators } from 'redux'
export const TermsAndConditionButtonStyles = StyleSheet.create({
  buttonContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonStyle: {
    padding: 4,
    backgroundColor: '#9155FD',
    borderRadius: 5,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  buttonTxtStyle: {
    minWidth: 70,
    padding: 5,
    marginHorizontal: 16,
    color: '#fff',
    fontSize: 14,
    fontFamily: 'Inter',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  submitContainer: {
    right: 10,
    left: 10,
    position: 'absolute',
    bottom: 10,
  },
  checkBoxStyle: {
    fillColor: '#ffffff',
    tickColor: '#9155FD',
    borderColor: '#9e9e9e',
  },
  errorInfoText: { color: 'red', fontSize: 12, paddingLeft: 5 },
})

const TermsAndConditionButtons = (props) => {
  const {
    navigation,
    firstTimeLogin = false,
    phoneNumber = null,
    enableAgreeBtn,
    setLoading,
    updatePrivacyPolicy,
    appType,t
  } = props
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const { subject, users, subjectStudyMetaData } = useSelector((state) => state)
  const accessCode = navigation.state.params?.accessCode
  const handleAgree = async (values) => {
    const siteId = _.get(subjectStudyMetaData?.studySite?.client, 'id', null)
    setLoading()
    if (values.agreed) {
      const req = {
        participantId: users?.currentUser.id,
        termsId: users?.privacyPolicy?.termsId,
        agreed: 1,
      }
      try {
        await dispatch(handlePrivacyPolicyAgreement(req, siteId))
        updatePrivacyPolicy(true)
        setLoading()
      } catch (error) {
        setLoading()
      }
    }
    if (users?.currentUser?.resetPassword) {
      let params = {phoneNumber: users?.currentUser?.phoneNo,fromLogin: true}
      if (accessCode && appConstants.AppType.SITESTAFF !== appType) {
        params.accessCode = accessCode
      }
      navigation.navigate('ResetPassword', params)
    } else {
      if (accessCode) {
        return openMeeting(accessCode)
      }
      if (users?.currentUser?.role == 'SUBJECT') {
        navigation.navigate('OfflineDataLoader')
      } else if (users?.currentUser?.role == 'CAREGIVER') {
        navigation.navigate('SubjectDataLoaderPage')
      }
    }
  }

  const handleCancel = () => {
    navigation.navigate('TermsAndConditionsCancel')
  }

  return (
    <View>
      <Formik
        initialValues={{ agreed: false }}
        validationSchema={Yup.object({
          agreed: Yup.boolean().test(
            'Not-Filled',
            t('AgreeingToPrivacypolicy'),
            (val) => {
              return val
            }
          ),
        })}
        onSubmit={(values, formikActions) => {
          handleAgree(values)
        }}
      >
        {(formikProps) => {
          const { setFieldValue, setValues } = formikProps
          const setCheckBoxValue = (value) => {
            setFieldValue('agreed', value)
            setChecked(value)
          }
          return (
            <React.Fragment>
              <TouchableOpacity
                onPress={() => setCheckBoxValue(!checked)}
                style={{ alignItems: 'flex-start', flexDirection: 'row' }}
              >
                <CheckBox
                  disabled={false}
                  checked={checked}
                  style={TermsAndConditionButtonStyles.checkBoxStyle}
                  testID={'terms-checkbox'}
                />
                <Text>{t('ReadAndAgree')}</Text>
              </TouchableOpacity>
              {formikProps.errors.agreed && (
                <Text style={TermsAndConditionButtonStyles.errorInfoText}>
                  {' '}
                  {formikProps.errors.agreed}
                </Text>
              )}
              <View style={TermsAndConditionButtonStyles.buttonContainerStyle}>
                <TouchableOpacity
                  style={[TermsAndConditionButtonStyles.buttonStyle, { backgroundColor: 'grey' }]}
                  onPress={() => handleCancel()}
                >
                  <Text style={TermsAndConditionButtonStyles.buttonTxtStyle}>{t('Cancel')}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    TermsAndConditionButtonStyles.buttonStyle,
                    {
                      backgroundColor: !enableAgreeBtn ? '#fff' : '#9155FD',
                      border: '1px solid #9155FD',
                    },
                  ]}
                  onPress={formikProps.handleSubmit}
                  disabled={!enableAgreeBtn}
                >
                  <Text
                    style={[
                      TermsAndConditionButtonStyles.buttonTxtStyle,
                      { color: !enableAgreeBtn ? '#9155FD' : '#fff' },
                    ]}
                  >
                    {t('IAgree')}
                  </Text>
                </TouchableOpacity>
              </View>
            </React.Fragment>
          )
        }}
      </Formik>
    </View>
  )
}
const mapStateToProps = (state) => {
  return {
    appType: state.appStatus?.appType,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePrivacyPolicy,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionButtons)
