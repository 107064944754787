export default {
"Africa/Abidjan":"Afrikka/Abidjan",
"Africa/Accra":"Afrikka/Accra",
"Africa/Addis_Ababa":"Afrikka/Addis_Abeba",
"Africa/Algiers":"Afrikka/Alger",
"Africa/Asmara":"Afrikka/Asmara",
"Africa/Asmera":"Afrikka/Asmera",
"Africa/Bamako":"Afrikka/Bamako",
"Africa/Bangui":"Afrikka/Bangui",
"Africa/Banjul":"Afrikka/Banjul",
"Africa/Bissau":"Afrikka/Bissau",
"Africa/Blantyre":"Afrikka/Blantyre",
"Africa/Brazzaville":"Afrikka/Brazzaville",
"Africa/Bujumbura":"Afrikka/Bujumbura",
"Africa/Cairo":"Afrikka/Kairo",
"Africa/Casablanca":"Afrikka/Casablanca",
"Africa/Ceuta":"Afrikka/Ceuta",
"Africa/Conakry":"Afrikka/Conakry",
"Africa/Dakar":"Afrikka/Dakar",
"Africa/Dar_es_Salaam":"Afrikka/Dar_es_Salaam",
"Africa/Djibouti":"Afrikka/Djibouti",
"Africa/Douala":"Afrikka/Douala",
"Africa/El_Aaiun":"Afrikka/El_Aaiun",
"Africa/Freetown":"Afrikka/Freetown",
"Africa/Gaborone":"Afrikka/Gaborone",
"Africa/Harare":"Afrikka/Harare",
"Africa/Johannesburg":"Afrikka/Johannesburg",
"Africa/Juba":"Afrikka/Juba",
"Africa/Kampala":"Afrikka/Kampala",
"Africa/Khartoum":"Afrikka/Khartoum",
"Africa/Kigali":"Afrikka/Kigali",
"Africa/Kinshasa":"Afrikka/Kinshasa",
"Africa/Lagos":"Afrikka/Lagos",
"Africa/Libreville":"Afrikka/Libreville",
"Africa/Lome":"Afrikka/Lome",
"Africa/Luanda":"Afrikka/Luanda",
"Africa/Lubumbashi":"Afrikka/Lubumbashi",
"Africa/Lusaka":"Afrikka/Lusaka",
"Africa/Malabo":"Afrikka/Malabo",
"Africa/Maputo":"Afrikka/Maputo",
"Africa/Maseru":"Afrikka/Maseru",
"Africa/Mbabane":"Afrikka/Mbabane",
"Africa/Mogadishu":"Afrikka/Mogadishu",
"Africa/Monrovia":"Afrikka/Monrovia",
"Africa/Nairobi":"Afrikka/Nairobi",
"Africa/Ndjamena":"Afrikka/Ndjamena",
"Africa/Niamey":"Afrikka/Niamey",
"Africa/Nouakchott":"Afrikka/Nouakchott",
"Africa/Ouagadougou":"Afrikka/Ouagadougou",
"Africa/Porto-Novo":"Afrikka/Porto-Novo",
"Africa/Sao_Tome":"Afrikka/Sao_Tome",
"Africa/Timbuktu":"Afrikka/Timbuktu",
"Africa/Tripoli":"Afrikka/Tripoli",
"Africa/Tunis":"Afrikka/Tunis",
"Africa/Windhoek":"Afrikka/Windhoek",
"America/Adak":"Amerikka/Adak",
"America/Anchorage":"Amerikka/Anchorage",
"America/Anguilla":"Amerikka/Anguilla",
"America/Antigua":"Amerikka/Antigua",
"America/Araguaina":"Amerikka/Araguaina",
"America/Argentina/Buenos_Aires":"Amerikka/Argentiina/Buenos_Aires",
"America/Argentina/Catamarca":"Amerikka/Argentiina/Catamarca",
"America/Argentina/ComodRivadavia":"Amerikka/Argentiina/Comodrivadavia",
"America/Argentina/Cordoba":"Amerikka/Argentiina/Cordoba",
"America/Argentina/Jujuy":"Amerikka/Argentiina/Jujuy",
"America/Argentina/La_Rioja":"Amerikka/Argentiina/La_Rioja",
"America/Argentina/Mendoza":"Amerikka/Argentiina/Mendoza",
"America/Argentina/Rio_Gallegos":"Amerikka/Argentiina/Rio_Gallegos",
"America/Argentina/Salta":"Amerikka/Argentiina/Salta",
"America/Argentina/San_Juan":"Amerikka/Argentiina/San_Juan",
"America/Argentina/San_Luis":"Amerikka/Argentiina/San_Luis",
"America/Argentina/Tucuman":"Amerikka/Argentiina/Tucuman",
"America/Argentina/Ushuaia":"Amerikka/Argentiina/Ushuaia",
"America/Aruba":"Amerikka/Aruba",
"America/Asuncion":"Amerikka/Asuncion",
"America/Atikokan":"Amerikka/Atikokan",
"America/Atka":"Amerikka/Atka",
"America/Bahia":"Amerikka/Bahia",
"America/Bahia_Banderas":"Amerikka/Bahia_Banderas",
"America/Barbados":"Amerikka/Barbados",
"America/Belem":"Amerikka/Belem",
"America/Belize":"Amerikka/Belize",
"America/Blanc-Sablon":"Amerikka/Blanc-Sablon",
"America/Boa_Vista":"Amerikka/Boa_Vista",
"America/Bogota":"Amerikka/Bogota",
"America/Boise":"Amerikka/Boise",
"America/Buenos_Aires":"Amerikka/Buenos_Aires",
"America/Cambridge_Bay":"Amerikka/Cambridge_Bay",
"America/Campo_Grande":"Amerikka/Campo_Grande",
"America/Cancun":"Amerikka/Cancun",
"America/Caracas":"Amerikka/Caracas",
"America/Catamarca":"Amerikka/Catamarca",
"America/Cayenne":"Amerikka/Cayenne",
"America/Cayman":"Amerikka/Cayman",
"America/Chicago":"Amerikka/Chicago",
"America/Chihuahua":"Amerikka/Chihuahua",
"America/Ciudad_Juarez":"Amerikka/Ciudad_Juarez",
"America/Coral_Harbour":"Amerikka/Coral_Harbour",
"America/Cordoba":"Amerikka/Cordoba",
"America/Costa_Rica":"Amerikka/Costa_Rica",
"America/Creston":"Amerikka/Creston",
"America/Cuiaba":"Amerikka/Cuiaba",
"America/Curacao":"Amerikka/Curacao",
"America/Danmarkshavn":"Amerikka/Danmarkshavn",
"America/Dawson":"Amerikka/Dawson",
"America/Dawson_Creek":"Amerikka/Dawson_Creek",
"America/Denver":"Amerikka/Denver",
"America/Detroit":"Amerikka/Detroit",
"America/Dominica":"Amerikka/Dominica",
"America/Edmonton":"Amerikka/Edmonton",
"America/Eirunepe":"Amerikka/Eirunepe",
"America/El_Salvador":"Amerikka/El_Salvador",
"America/Ensenada":"Amerikka/Ensenada",
"America/Fort_Nelson":"Amerikka/Fort_Nelson",
"America/Fort_Wayne":"Amerikka/Fort_Wayne",
"America/Fortaleza":"Amerikka/Fortaleza",
"America/Glace_Bay":"Amerikka/Glace_Bay",
"America/Godthab":"Amerikka/Godthab",
"America/Goose_Bay":"Amerikka/Goose_Bay",
"America/Grand_Turk":"Amerikka/Grand_Turk",
"America/Grenada":"Amerikka/Grenada",
"America/Guadeloupe":"Amerikka/Guadeloupe",
"America/Guatemala":"Amerikka/Guatemala",
"America/Guayaquil":"Amerikka/Guayaquil",
"America/Guyana":"Amerikka/Guyana",
"America/Halifax":"Amerikka/Halifax",
"America/Havana":"Amerikka/Havana",
"America/Hermosillo":"Amerikka/Hermosillo",
"America/Indiana/Indianapolis":"Amerikka/Indiana/Indianapolis",
"America/Indiana/Knox":"Amerikka/Indiana/Knox",
"America/Indiana/Marengo":"Amerikka/Indiana/Marengo",
"America/Indiana/Petersburg":"Amerikka/Indiana/Pietari",
"America/Indiana/Tell_City":"Amerikka/Indiana/Tell_City",
"America/Indiana/Vevay":"Amerikka/Indiana/Vevay",
"America/Indiana/Vincennes":"Amerikka/Indiana/Vincennes",
"America/Indiana/Winamac":"Amerikka/Indiana/Winamac",
"America/Indianapolis":"Amerikka/Indianapolis",
"America/Inuvik":"Amerikka/Inuvik",
"America/Iqaluit":"Amerikka/Iqaluit",
"America/Jamaica":"Amerikka/Jamaika",
"America/Jujuy":"Amerikka/Jujuy",
"America/Juneau":"Amerikka/Juneau",
"America/Kentucky/Louisville":"Amerikka/Kentucky/Louisville",
"America/Kentucky/Monticello":"Amerikka/Kentucky/Monticello",
"America/Knox_IN":"Amerikka/Knox_in",
"America/Kralendijk":"Amerikka/Kralendijk",
"America/La_Paz":"Amerikka/La_Paz",
"America/Lima":"Amerikka/Lima",
"America/Los_Angeles":"Amerikka/Los_Angeles",
"America/Louisville":"Amerikka/Louisville",
"America/Lower_Princes":"Amerikka/Lower_Princes",
"America/Maceio":"Amerikka/Maceio",
"America/Managua":"Amerikka/Managua",
"America/Manaus":"Amerikka/Manaus",
"America/Marigot":"Amerikka/Marigot",
"America/Martinique":"Amerikka/Martinique",
"America/Matamoros":"Amerikka/Matamoros",
"America/Mazatlan":"Amerikka/Mazatlan",
"America/Mendoza":"Amerikka/Mendoza",
"America/Menominee":"Amerikka/Menominee",
"America/Merida":"Amerikka/Merida",
"America/Metlakatla":"Amerikka/Metlakatla",
"America/Mexico_City":"Amerikka/Meksiko_kaupunki",
"America/Miquelon":"Amerikka/Miquelon",
"America/Moncton":"Amerikka/Moncton",
"America/Monterrey":"Amerikka/Monterrey",
"America/Montevideo":"Amerikka/Montevideo",
"America/Montreal":"Amerikka/Montreal",
"America/Montserrat":"Amerikka/Montserrat",
"America/Nassau":"Amerikka/Nassau",
"America/New_York":"Amerikka/New_York",
"America/Nipigon":"Amerikka/Nipigon",
"America/Nome":"Amerikka/Nimi",
"America/Noronha":"Amerikka/Noronha",
"America/North_Dakota/Beulah":"Amerikka/Pohjois-Dakota/Beulah",
"America/North_Dakota/Center":"Amerikka/Pohjois-Dakota/Keski",
"America/North_Dakota/New_Salem":"Amerikka/Pohjois-Dakota/New_Salem",
"America/Nuuk":"Amerikka/Nuuk",
"America/Ojinaga":"Amerikka/Ojinaga",
"America/Panama":"Amerikka/Panama",
"America/Pangnirtung":"Amerikka/Pangnirtung",
"America/Paramaribo":"Amerikka/Paramaribo",
"America/Phoenix":"Amerikka/Phoenix",
"America/Port-au-Prince":"Amerikka/Port-au-Prince",
"America/Port_of_Spain":"Amerikka/Espanjan satama",
"America/Porto_Acre":"Amerikka/Porto_Acre",
"America/Porto_Velho":"Amerikka/Porto_Velho",
"America/Puerto_Rico":"Amerikka/Puerto_Rico",
"America/Punta_Arenas":"Amerikka/Punta_Arenas",
"America/Rainy_River":"Amerikka/Rainy_River",
"America/Rankin_Inlet":"Amerikka/Rankin_Inlet",
"America/Recife":"Amerikka/Recife",
"America/Regina":"Amerikka/Regina",
"America/Resolute":"Amerikka/Resolute",
"America/Rio_Branco":"Amerikka/Rio_Branco",
"America/Rosario":"Amerikka/Rosario",
"America/Santa_Isabel":"Amerikka/Santa_Isabel",
"America/Santarem":"Amerikka/Santarem",
"America/Santiago":"Amerikka/Santiago",
"America/Santo_Domingo":"Amerikka/Santo_Domingo",
"America/Sao_Paulo":"Amerikka/Sao_Paulo",
"America/Scoresbysund":"Amerikka/Scoresbysund",
"America/Shiprock":"Amerikka/Shiprock",
"America/Sitka":"Amerikka/Sitka",
"America/St_Barthelemy":"Amerikka/St_Barthelemy",
"America/St_Johns":"Amerikka/St_Johns",
"America/St_Kitts":"Amerikka/St_Kitts",
"America/St_Lucia":"Amerikka/St_Lucia",
"America/St_Thomas":"Amerikka/St_Thomas",
"America/St_Vincent":"Amerikka/St_Vincent",
"America/Swift_Current":"Amerikka/Swift_Current",
"America/Tegucigalpa":"Amerikka/Tegucigalpa",
"America/Thule":"Amerikka/Thule",
"America/Thunder_Bay":"Amerikka/Thunder_Bay",
"America/Tijuana":"Amerikka/Tijuana",
"America/Toronto":"Amerikka/Toronto",
"America/Tortola":"Amerikka/Tortola",
"America/Vancouver":"Amerikka/Vancouver",
"America/Virgin":"Amerikka/Virgin",
"America/Whitehorse":"Amerikka/Whitehorse",
"America/Winnipeg":"Amerikka/Winnipeg",
"America/Yakutat":"Amerikka/Yakutat",
"America/Yellowknife":"Amerikka/Yellowknife",
"Antarctica/Casey":"Antarktis/Casey",
"Antarctica/Davis":"Antarktis/Davis",
"Antarctica/DumontDUrville":"Etelämanner/Dumontdurville",
"Antarctica/Macquarie":"Etelämanterea/Macquarie",
"Antarctica/Mawson":"Etelämanterea/Mawson",
"Antarctica/McMurdo":"Etelämanner/McMurdo",
"Antarctica/Palmer":"Antarktis/Palmer",
"Antarctica/Rothera":"Antarktis/Rothera",
"Antarctica/South_Pole":"Etelänapa/Etelänapa",
"Antarctica/Syowa":"Antarktis/Syowa",
"Antarctica/Troll":"Antarktis/peikko",
"Antarctica/Vostok":"Antarktis/Vostok",
"Arctic/Longyearbyen":"Arctic/Longyearbyen",
"Asia/Aden":"Aasia/Aden",
"Asia/Almaty":"Aasia/Almaty",
"Asia/Amman":"Aasia/Amman",
"Asia/Anadyr":"Aasia/Anadyr",
"Asia/Aqtau":"Aasia/Aqtau",
"Asia/Aqtobe":"Aasia/Aqtobe",
"Asia/Ashgabat":"Aasia/Ashgabat",
"Asia/Ashkhabad":"Aasia/Ashkhabad",
"Asia/Atyrau":"Aasia/Atyrau",
"Asia/Baghdad":"Aasia/Bagdad",
"Asia/Bahrain":"Aasia/Bahrain",
"Asia/Baku":"Aasia/Baku",
"Asia/Bangkok":"Aasia/Bangkok",
"Asia/Barnaul":"Aasia/Barnaul",
"Asia/Beirut":"Aasia/Beirut",
"Asia/Bishkek":"Aasia/Bishkek",
"Asia/Brunei":"Aasia/Brunei",
"Asia/Calcutta":"Aasia/Kalkutta",
"Asia/Chita":"Aasia/Chita",
"Asia/Choibalsan":"Aasia/Choibalsan",
"Asia/Chongqing":"Aasia/Chongqing",
"Asia/Chungking":"Aasia/Chungking",
"Asia/Colombo":"Aasia/Colombo",
"Asia/Dacca":"Aasia/Dacca",
"Asia/Damascus":"Aasia/Damaskos",
"Asia/Dhaka":"Aasia/Dhaka",
"Asia/Dili":"Aasia/Dili",
"Asia/Dubai":"Aasia/Dubai",
"Asia/Dushanbe":"Aasia/Dushanbe",
"Asia/Famagusta":"Aasia/Famagusta",
"Asia/Gaza":"Aasia/Gaza",
"Asia/Harbin":"Aasia/Harbin",
"Asia/Hebron":"Aasia/Hebron",
"Asia/Ho_Chi_Minh":"Aasia/Ho_Chi_Minh",
"Asia/Hong_Kong":"Aasia/Hongkong",
"Asia/Hovd":"Aasia/Hovd",
"Asia/Irkutsk":"Aasia/Irkutsk",
"Asia/Istanbul":"Aasia/Istanbul",
"Asia/Jakarta":"Aasia/Jakarta",
"Asia/Jayapura":"Aasia/Jayapura",
"Asia/Jerusalem":"Aasia/Jerusalem",
"Asia/Kabul":"Aasia/Kabul",
"Asia/Kamchatka":"Aasia/Kamtšatka",
"Asia/Karachi":"Aasia/Karachi",
"Asia/Kashgar":"Aasia/Kashgar",
"Asia/Kathmandu":"Aasia/Kathmandu",
"Asia/Katmandu":"Aasia/Katmandu",
"Asia/Khandyga":"Aasia/Khandyga",
"Asia/Kolkata":"Aasia/Kolkata",
"Asia/Krasnoyarsk":"Aasia/Krasnojarsk",
"Asia/Kuala_Lumpur":"Aasia/Kuala_Lumpur",
"Asia/Kuching":"Aasia/Kuching",
"Asia/Kuwait":"Aasia/Kuwait",
"Asia/Macao":"Aasia/Macao",
"Asia/Macau":"Aasia/Macao",
"Asia/Magadan":"Aasia/Magadan",
"Asia/Makassar":"Aasia/Makassar",
"Asia/Manila":"Aasia/Manila",
"Asia/Muscat":"Aasia/Muscat",
"Asia/Nicosia":"Aasia/Nikosia",
"Asia/Novokuznetsk":"Aasia/Novokuznetsk",
"Asia/Novosibirsk":"Aasia/Novosibirsk",
"Asia/Omsk":"Aasia/Omsk",
"Asia/Oral":"Aasia/Suullinen",
"Asia/Phnom_Penh":"Aasia/Phnom_Penh",
"Asia/Pontianak":"Aasia/Pontianak",
"Asia/Pyongyang":"Aasia/Pjongyang",
"Asia/Qatar":"Aasia/Qatar",
"Asia/Qostanay":"Aasia/Qostanay",
"Asia/Qyzylorda":"Aasia/Qyzylorda",
"Asia/Rangoon":"Aasia/Rangoon",
"Asia/Riyadh":"Aasia/Riad",
"Asia/Saigon":"Aasia/Saigon",
"Asia/Sakhalin":"Aasia/Sahalin",
"Asia/Samarkand":"Aasia/Samarkand",
"Asia/Seoul":"Aasia/Soul",
"Asia/Shanghai":"Aasia/Shanghai",
"Asia/Singapore":"Aasia/Singapore",
"Asia/Srednekolymsk":"Aasia/Keski-Kolymsk",
"Asia/Taipei":"Aasia/Taipei",
"Asia/Tashkent":"Aasia/Taškent",
"Asia/Tbilisi":"Aasia/Tbilisi",
"Asia/Tehran":"Aasia/Teheran",
"Asia/Tel_Aviv":"Aasia/Tel_Aviv",
"Asia/Thimbu":"Aasia/Thimbu",
"Asia/Thimphu":"Aasia/Thimphu",
"Asia/Tokyo":"Aasia/Tokio",
"Asia/Tomsk":"Aasia/Tomsk",
"Asia/Ujung_Pandang":"Aasia/Ujung_Pandang",
"Asia/Ulaanbaatar":"Aasia/Ulaanbaatar",
"Asia/Ulan_Bator":"Aasia/Ulan_Bator",
"Asia/Urumqi":"Aasia/Urumqi",
"Asia/Ust-Nera":"Aasia/Ust-Nera",
"Asia/Vientiane":"Aasia/Vientiane",
"Asia/Vladivostok":"Aasia/Vladivostok",
"Asia/Yakutsk":"Aasia/Jakutsk",
"Asia/Yangon":"Aasia/Yangon",
"Asia/Yekaterinburg":"Aasia/Jekaterinburg",
"Asia/Yerevan":"Aasia/Jerevan",
"Atlantic/Azores":"Atlantti/Azorit",
"Atlantic/Bermuda":"Atlantti/Bermuda",
"Atlantic/Canary":"Atlantik/Kanariansaaret",
"Atlantic/Cape_Verde":"Atlantic/Cape Verde",
"Atlantic/Faeroe":"Atlantic/Färsaaret",
"Atlantic/Faroe":"Atlantic/Färsaaret",
"Atlantic/Jan_Mayen":"Atlantilainen/Jan_Mayen",
"Atlantic/Madeira":"Atlantti/Madeira",
"Atlantic/Reykjavik":"Atlantic/Reykjavik",
"Atlantic/South_Georgia":"Atlanti/Etelä_Georgia",
"Atlantic/St_Helena":"Atlantic/St_Helena",
"Atlantic/Stanley":"Atlantilainen/Stanley",
"Australia/ACT":"Australia/ACT",
"Australia/Adelaide":"Australia/Adelaide",
"Australia/Brisbane":"Australia/Brisbane",
"Australia/Broken_Hill":"Australia/Broken_Hill",
"Australia/Canberra":"Australia/Canberra",
"Australia/Currie":"Australia/Currie",
"Australia/Darwin":"Australia/Darwin",
"Australia/Eucla":"Australia/Eucla",
"Australia/Hobart":"Australia/Hobart",
"Australia/LHI":"Australia/LHI",
"Australia/Lindeman":"Australia/Lindeman",
"Australia/Lord_Howe":"Australia/Lord_Howe",
"Australia/Melbourne":"Australia/Melbourne",
"Australia/NSW":"Australia/NSW",
"Australia/North":"Australia/Pohjois",
"Australia/Perth":"Australia/Perth",
"Australia/Queensland":"Australia/Queensland",
"Australia/South":"Australia/Etelä",
"Australia/Sydney":"Australia/Sydney",
"Australia/Tasmania":"Australia/Tasmania",
"Australia/Victoria":"Australia/Victoria",
"Australia/West":"Australia/Länsi",
"Australia/Yancowinna":"Australia/Yancowinna",
"Brazil/Acre":"Brasilia/Acre",
"Brazil/DeNoronha":"Brasilia/Denoronha",
"Brazil/East":"Brasilia/itä",
"Brazil/West":"Brasilia/Länsi",
"CET":"TÄMÄ",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Kanada/Atlantti",
"Canada/Central":"Kanada/Keski",
"Canada/Eastern":"Kanada/itäinen",
"Canada/Mountain":"Kanada/vuori",
"Canada/Newfoundland":"Kanada/Newfoundland",
"Canada/Pacific":"Kanada/Tyynenmeren alue",
"Canada/Saskatchewan":"Kanada/Saskatchewan",
"Canada/Yukon":"Kanada/Yukon",
"Chile/Continental":"Chile/Mannermainen",
"Chile/EasterIsland":"Chile/pääsiäissaari",
"Cuba":"Kuuba",
"EET":"SYÖDÄ",
"EST":"EST",
"EST5EDT":"EST5EDT",
"Egypt":"Egypti",
"Eire":"Eire",
"Etc/GMT":"Jne/GMT",
"Etc/GMT+0":"Jne/GMT+0",
"Etc/GMT+1":"Etc/GMT+1",
"Etc/GMT+10":"Etc/GMT+10",
"Etc/GMT+11":"Etc/GMT+11",
"Etc/GMT+12":"Etc/GMT+12",
"Etc/GMT+2":"Etc/GMT+2",
"Etc/GMT+3":"Etc/GMT+3",
"Etc/GMT+4":"Etc/GMT+4",
"Etc/GMT+5":"Etc/GMT+5",
"Etc/GMT+6":"Etc/GMT+6",
"Etc/GMT+7":"Etc/GMT+7",
"Etc/GMT+8":"Etc/GMT+8",
"Etc/GMT+9":"Jne/GMT+9",
"Etc/GMT-0":"Jne/GMT-0",
"Etc/GMT-1":"Etc/GMT-1",
"Etc/GMT-10":"jne/GMT-10",
"Etc/GMT-11":"jne/GMT-11",
"Etc/GMT-12":"jne/GMT-12",
"Etc/GMT-13":"jne/GMT-13",
"Etc/GMT-14":"jne/GMT-14",
"Etc/GMT-2":"Etc/GMT-2",
"Etc/GMT-3":"Etc/GMT-3",
"Etc/GMT-4":"Etc/GMT-4",
"Etc/GMT-5":"Etc/GMT-5",
"Etc/GMT-6":"Etc/GMT-6",
"Etc/GMT-7":"Etc/GMT-7",
"Etc/GMT-8":"Etc/GMT-8",
"Etc/GMT-9":"Jne/GMT-9",
"Etc/GMT0":"Jne/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"Jne/UCT",
"Etc/UTC":"Jne/UTC",
"Etc/Universal":"Etc/universaali",
"Etc/Zulu":"Etc/Zulu",
"Europe/Amsterdam":"Eurooppa/Amsterdam",
"Europe/Andorra":"Eurooppa/Andorra",
"Europe/Astrakhan":"Eurooppa/Astrakhan",
"Europe/Athens":"Eurooppa/Ateena",
"Europe/Belfast":"Eurooppa/Belfast",
"Europe/Belgrade":"Eurooppa/Belgrad",
"Europe/Berlin":"Eurooppa/Berliini",
"Europe/Bratislava":"Eurooppa/Bratislava",
"Europe/Brussels":"Eurooppa/Bryssel",
"Europe/Bucharest":"Eurooppa/Bukarest",
"Europe/Budapest":"Eurooppa/Budapest",
"Europe/Busingen":"Eurooppa/Busingen",
"Europe/Chisinau":"Eurooppa/Chisinau",
"Europe/Copenhagen":"Eurooppa/Kööpenhamina",
"Europe/Dublin":"Eurooppa/Dublin",
"Europe/Gibraltar":"Eurooppa/Gibraltar",
"Europe/Guernsey":"Eurooppa/Guernsey",
"Europe/Helsinki":"Eurooppa/Helsinki",
"Europe/Isle_of_Man":"Eurooppa/Isle_of_man",
"Europe/Istanbul":"Eurooppa/Istanbul",
"Europe/Jersey":"Eurooppa/Jersey",
"Europe/Kaliningrad":"Eurooppa/Kaliningrad",
"Europe/Kiev":"Eurooppa/Kiova",
"Europe/Kirov":"Eurooppa/Kirov",
"Europe/Kyiv":"Eurooppa/Kiev",
"Europe/Lisbon":"Eurooppa/Lissabon",
"Europe/Ljubljana":"Eurooppa/Ljubljana",
"Europe/London":"Eurooppa/Lontoo",
"Europe/Luxembourg":"Eurooppa/Luxemburg",
"Europe/Madrid":"Eurooppa/Madrid",
"Europe/Malta":"Eurooppa/Malta",
"Europe/Mariehamn":"Eurooppa/Maarianhamina",
"Europe/Minsk":"Eurooppa/Minsk",
"Europe/Monaco":"Eurooppa/Monaco",
"Europe/Moscow":"Eurooppa/Moskova",
"Europe/Nicosia":"Eurooppa/Nikosia",
"Europe/Oslo":"Eurooppa/Oslo",
"Europe/Paris":"Eurooppa/Pariisi",
"Europe/Podgorica":"Eurooppa/Podgorica",
"Europe/Prague":"Eurooppa/Praha",
"Europe/Riga":"Eurooppa/Riika",
"Europe/Rome":"Eurooppa/Rooma",
"Europe/Samara":"Eurooppa/Samara",
"Europe/San_Marino":"Eurooppa/San_Marino",
"Europe/Sarajevo":"Eurooppa/Sarajevo",
"Europe/Saratov":"Eurooppa/Saratov",
"Europe/Simferopol":"Eurooppa/Simferopol",
"Europe/Skopje":"Eurooppa/Skopje",
"Europe/Sofia":"Eurooppa/Sofia",
"Europe/Stockholm":"Eurooppa/Tukholma",
"Europe/Tallinn":"Eurooppa/Tallinna",
"Europe/Tirane":"Eurooppa/Tirane",
"Europe/Tiraspol":"Eurooppa/Tiraspol",
"Europe/Ulyanovsk":"Eurooppa/Uljanovsk",
"Europe/Uzhgorod":"Eurooppa/Uzhgorod",
"Europe/Vaduz":"Eurooppa/Vaduz",
"Europe/Vatican":"Eurooppa/Vatikaani",
"Europe/Vienna":"Eurooppa/Wien",
"Europe/Vilnius":"Eurooppa/Vilna",
"Europe/Volgograd":"Eurooppa/Volgograd",
"Europe/Warsaw":"Eurooppa/Varsova",
"Europe/Zagreb":"Eurooppa/Zagreb",
"Europe/Zaporozhye":"Eurooppa/Zaporozhye",
"Europe/Zurich":"Eurooppa/Zürich",
"GB":"GB",
"GB-Eire":"GB Eire",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HST",
"Hongkong":"Hongkong",
"Iceland":"Islanti",
"Indian/Antananarivo":"Intialainen/Antananarivo",
"Indian/Chagos":"Intialainen/Chagos",
"Indian/Christmas":"Intialainen/joulu",
"Indian/Cocos":"Intialainen/Kookos",
"Indian/Comoro":"Intialainen/Komoro",
"Indian/Kerguelen":"Intialainen/Kerguelen",
"Indian/Mahe":"Intialainen/Mahe",
"Indian/Maldives":"Intialainen/Malediivit",
"Indian/Mauritius":"Intialainen/Mauritius",
"Indian/Mayotte":"Intialainen/Mayotte",
"Indian/Reunion":"Intialainen/jälleennäkeminen",
"Iran":"Iran",
"Israel":"Israel",
"Jamaica":"Jamaika",
"Japan":"Japani",
"Kwajalein":"Kwajalein",
"Libya":"Libya",
"MET":"WITH",
"MST":"MST",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"Meksiko/bajanorte",
"Mexico/BajaSur":"Meksiko/Bajasur",
"Mexico/General":"Meksiko/Yleiset",
"NZ":"NEW",
"NZ-CHAT":"NZ-CHAT",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Tyynenmeren alue/Apia",
"Pacific/Auckland":"Tyynenmeren alue/Auckland",
"Pacific/Bougainville":"Tyynenmeren alue/Bougainville",
"Pacific/Chatham":"Tyynenmeren alue/Chatham",
"Pacific/Chuuk":"Tyynenmeren alue/Chuuk",
"Pacific/Easter":"Tyynenmeren alue/pääsiäinen",
"Pacific/Efate":"Tyynenmeren alue/Efate",
"Pacific/Enderbury":"Tyynenmeren alue/Enderbury",
"Pacific/Fakaofo":"Tyynenmeren alue/Fakaofo",
"Pacific/Fiji":"Tyynenmeren alue/Fidži",
"Pacific/Funafuti":"Tyynenmeren alue/Funafuti",
"Pacific/Galapagos":"Tyynenmeren alue/Galapagos",
"Pacific/Gambier":"Tyynenmeren alue/Gambier",
"Pacific/Guadalcanal":"Tyynenmeren alue/Guadalcanal",
"Pacific/Guam":"Tyynenmeren alue/Guam",
"Pacific/Honolulu":"Tyynenmeren alue/Honolulu",
"Pacific/Johnston":"Tyynenmeren alue/Johnston",
"Pacific/Kanton":"Tyynenmeren alue/Kanton",
"Pacific/Kiritimati":"Tyynenmeren alue/Kiritimati",
"Pacific/Kosrae":"Tyynenmeren alue/Kosrae",
"Pacific/Kwajalein":"Tyynenmeren alue/Kwajalein",
"Pacific/Majuro":"Tyynenmeren alue/Majuro",
"Pacific/Marquesas":"Tyynenmeren alue/Marquesas",
"Pacific/Midway":"Tyynenmeren alue/Midway",
"Pacific/Nauru":"Tyynenmeren alue/Nauru",
"Pacific/Niue":"Tyynenmeren alue/Niue",
"Pacific/Norfolk":"Tyynenmeren alue/Norfolk",
"Pacific/Noumea":"Tyynenmeren alue/Noumea",
"Pacific/Pago_Pago":"Tyynenmeren alue/Pago_Pago",
"Pacific/Palau":"Tyynenmeren alue/Palau",
"Pacific/Pitcairn":"Tyynenmeren alue/Pitcairn",
"Pacific/Pohnpei":"Tyynenmeren alue/Pohnpei",
"Pacific/Ponape":"Tyynenmeren alue/Ponape",
"Pacific/Port_Moresby":"Tyynenmeren alue/Port_Moresby",
"Pacific/Rarotonga":"Tyynenmeren alue/Rarotonga",
"Pacific/Saipan":"Tyynenmeren alue/Saipan",
"Pacific/Samoa":"Tyynenmeren alue/Samoa",
"Pacific/Tahiti":"Tyynenmeren alue/Tahiti",
"Pacific/Tarawa":"Tyynenmeren alue/Tarawa",
"Pacific/Tongatapu":"Tyynenmeren alue/Tongatapu",
"Pacific/Truk":"Tyynenmeren alue/Truk",
"Pacific/Wake":"Tyynenmeren alue/Wake",
"Pacific/Wallis":"Tyynenmeren alue/Wallis",
"Pacific/Yap":"Tyynenmeren alue/Yap",
"Poland":"Puola",
"Portugal":"Portugali",
"ROC":"ROC",
"ROK":"VUOSI",
"Singapore":"Singapore",
"Turkey":"Turkki",
"UCT":"UCT",
"US/Alaska":"Yhdysvallat/Alaska",
"US/Aleutian":"Yhdysvallat/Aleutian",
"US/Arizona":"Yhdysvallat/Arizona",
"US/Central":"USA/Keski",
"US/East-Indiana":"Yhdysvallat/Itä-Indiana",
"US/Eastern":"USA/itäinen",
"US/Hawaii":"USA/Havaiji",
"US/Indiana-Starke":"Yhdysvallat/Indiana-Starke",
"US/Michigan":"Yhdysvallat/Michigan",
"US/Mountain":"US/Vuori",
"US/Pacific":"USA/Tyynenmeren alue",
"US/Samoa":"USA/Samoa",
"UTC":"UTC",
"Universal":"Universaali",
"W-SU":"W-SU",
"WET":"MÄRKÄ",
"Zulu":"Zulu",
}