import { MaterialIcons } from '@expo/vector-icons'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { View } from 'react-native'
import { Agenda } from 'react-native-calendars'
// import svfs from '../constants/CalendarEventData';
import {
  buildCalendarData,
  buildMarkedDates,
  checkIfSvfsAreDifferent,
  loadItems,
  renderDay,
  renderEmptyDate,
  renderItem,
  rowHasChanged,
} from '../utils/eventCalendarUtils'

export default class AgendaScreen extends Component {
  state = {
    items: {},
    selectedDateItems: {},
    markedDates: {},
    selectedDate: null,
  }

  static navigationOptions = {
    title: 'Event Calendar',
  }

  componentDidUpdate(prevProps) {
    const { svfs, selectedLanguage, subject } = this.props
    if (checkIfSvfsAreDifferent(svfs, prevProps.svfs)) {
      const eventsData = buildCalendarData(svfs)
      const markedDates = buildMarkedDates(svfs)
      this.setState(
        {
          items: eventsData,
          markedDates,
        },
        () => {
          loadItems(
            {
              dateString: this.state.selectedDate || moment().format('YYYY-MM-DD'),
            },
            this
          )
        }
      )
    }
    if (selectedLanguage !== prevProps.selectedLanguage) {
      // retrieveSvfsForASubject(subject, selectedLanguage)
    }
  }

  render() {
    const { selectedDateItems, markedDates, items } = this.state
    const { loading, subject, selectedLanguage } = this.props
    return (
      <Agenda
        items={selectedDateItems}
        onDayPress={(month) => loadItems(month, this)}
        onDayChange={(month) => loadItems(month, this)}
        selected={moment().format('YYYY-MM-DD')}
        renderItem={(item) => renderItem(item, this)}
        renderEmptyDate={() => {
          return _.isEmpty(_.values(selectedDateItems)[0]) && loading ? <View /> : renderEmptyDate()
        }}
        rowHasChanged={(r1, r2) => rowHasChanged(r1, r2)}
        renderEmptyData={() => {
          return _.isEmpty(items) && loading ? <View /> : renderEmptyDate()
        }}
        renderKnob={() => <MaterialIcons name="drag-handle" color="#607d8b" size={30} />}
        renderDay={(day, item) => renderDay(day)}
        markedDates={markedDates}
        // onRefresh={() => retrieveSvfsForASubject(subject, selectedLanguage)}
        style={{ height: 250 }}
      />
    )
  }
}
