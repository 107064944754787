import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { View } from 'react-native'
import { HeaderText } from './HeaderText'
import _ from 'lodash'
import PropTypes from 'prop-types'

export class SubjectHeaderRight extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { subject, client, studyInfo, versionInfo, screenProps, role, user, onMobile } =
      this.props
    return !onMobile ? (
      <View style={{ flexDirection: 'row', paddingRight: 5 }}>
        <View style={{ flexDirection: 'column', padding: 5 }}>
          <HeaderText
            heading={screenProps.t('Study')}
            value={studyInfo ? studyInfo.studyNumber : ''}
            screenProps={screenProps}
          />
          <HeaderText
            heading={screenProps.t('Site')}
            value={client ? client.name : ''}
            screenProps={screenProps}
          />
          {user && <HeaderText heading={'User'} value={user} screenProps={screenProps} />}
        </View>
        <View style={{ flexDirection: 'column', padding: 5 }}>
          <HeaderText
            heading={screenProps.t('SubjectNumber')}
            value={subject ? subject.subjectNo : ''}
            screenProps={screenProps}
          />
          <HeaderText
            heading={screenProps.t('Version')}
            value={!_.isEmpty(versionInfo) ? versionInfo.versionNumber : ''}
            firstTimeLogin={subject.isFirstTimeLogin == 1}
            screenProps={screenProps}
          />
          {role && <HeaderText heading={'Role'} value={role} screenProps={screenProps} />}
        </View>
      </View>
    ) : (
      <View />
    )
  }
}


SubjectHeaderRight.defaultProps = {
  subject: {},
  client: {},
  studyInfo: {},
  versionInfo: {},
  role: '',
  user: '',
  onMobile: false,
  screenProps: {},
}

SubjectHeaderRight.propTypes = {
  subject: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
  studyInfo: PropTypes.instanceOf(Object),
  versionInfo: PropTypes.instanceOf(Object),
  role: PropTypes.string,
  user: PropTypes.string,
  onMobile: PropTypes.bool,
  screenProps:PropTypes.instanceOf(Object),
}
const mapStateToProps = (state) => ({
  subject: state.subjectStudyMetaData?.subject,
  studyInfo: state.subjectStudyMetaData?.study,
  versionInfo: state.subjectStudyMetaData?.study?.crfVersion,
  client: state.subjectStudyMetaData?.studySite?.client,
  onMobile: state.appStatus.onMobile
})
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SubjectHeaderRight)
