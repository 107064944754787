import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Home from '../components/Home'
import { BackHandler, Alert, Platform } from 'react-native'
// import { getDeviceToken } from '../utils/pushNotification/configurePushNotification';
import { retrieveSubjectCompliance } from '../utils/homeUtils'
import { withNavigationFocus } from 'react-navigation'
import { setCurrentScreen } from '../actions/storeAppStatus'
import { saveHealthkitData } from '../actions/healthkit'
import moment from 'moment'
import Stomp from 'stompjs'
import SockJS from 'sockjs-client'
import { retrieveSubjectDeviceToken } from '../utils/secureStorageUtils'
import properties from '../constants/appConstants'
import { setUnreadChats, retrieveUnreadChats as retrieveUnreadChatsAction } from '../actions/chat'
import * as Linking from 'expo-linking'
import { setupInitialHealthKit } from '../utils/healthKit/Healthkit'
import constants from '../constants/constants'
import _ from 'lodash'

class HomeScreen extends Component {
  state = {
    subjectCompliance: {
      dayCompliance: 0,
      weekCompliance: 0,
      monthCompliance: 0,
      totalCompliance: 0,
    },
    isAlertModalVisible: false,
    isJoinCallModalVisible: false,
    callDto: {
      accessCode: '',
      callId: '',
      callTitle: '',
      callType: '',
      duration: 0,
      eventType: '',
      scheduleDateTimeUtc: '',
      scheduledTimezone: '',
    },
    deviceTokenData: '',
  }
  subscription = null
  stompClient = null

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    title: navigation.state.params ? navigation.state.params.title : t('HomeTitle'),
  })

  componentDidMount() {
    const {
      setCurrentScreen,
      subject,
      saveHealthkitData: syncHealthData,
      retrieveUnreadChats,
      subjectPrivileges,
    } = this.props
    setCurrentScreen('')
    const date = moment().format('YYYY-MM-DD').toString()
    if (
      Platform.OS === 'ios' &&
      subjectPrivileges?.includes(constants.APP_PRIVILEGES.VIEW_HEALTH_DATA)
    ) {
      setupInitialHealthKit()
      syncHealthData(subject.id, date)
      setInterval(() => {
        syncHealthData(subject.id, date)
      }, 60000)
    }
    this.connectSocket()
    retrieveUnreadChats(subject.id)
    this.fetchSubjectDeviceToken()
  }

  componentDidUpdate(prevProps) {
    const {
      selectedLanguage,
      isFocused,
      screenProps: { t },
    } = this.props
    if (selectedLanguage !== prevProps.selectedLanguage) {
      this.props.navigation.setParams({ title: t('HomeTitle') })
    }
    if (isFocused === true) {
      // BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }
    if (isFocused === false) {
      // BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
  }

  handleBackPress = () => {
    // works best when the goBack is async
    this.exitApplication()
    return true
    // BackHandler.exitApp();
    // return true;
  }

  retrieveSubjectCompliance = async () => {
    const { subject } = this.props
    try {
      const subjectCompliance = await retrieveSubjectCompliance(subject)
      this.setState({
        subjectCompliance,
      })
    } catch (error) {
      console.log(error)
    }
  }

  exitApplication = () => {
    if (Platform.OS != 'web') {
      Alert.alert(
        '',
        'Exit ezpro?',
        [
          {
            text: 'Cancel',
            onPress: () => true,
            style: 'cancel',
          },
          { text: 'OK', onPress: () => BackHandler.exitApp() },
        ],
        { cancelable: false }
      )
    } else {
      this.setState({
        isAlertModalVisible: true,
      })
    }
  }

  connectSocket = async () => {
    const {
      subject: { id },
    } = this.props
    const data = await retrieveSubjectDeviceToken()
    var stompClient = null
    var socket = new SockJS(properties.socketUrl)
    stompClient = Stomp.over(socket)
    const header = JSON.stringify({
      'Device-Token-Id': data.subjectDeviceTokenId,
      'Device-Token': data.subjectDeviceToken,
    })
    if (stompClient) {
      stompClient.connect(header, null, () => {
        this.subscription = stompClient.subscribe(
          `/ezPro/chat/unread/count/subscribe/${id}`,
          (message) => {
            this.onMessageReceived(message.body)
          },
          { login: header }
        )
        this.callSubscription = stompClient.subscribe(
          `/ezPro/call/subscribe/${id}`,
          (message) => {
            this.onCallMessageReceived(message.body)
          },
          { login: header }
        )
      })
    }
  }

  onMessageReceived = (chatIds) => {
    const { setUnreadChats: loSetUnreadChats, selectedChat } = this.props
    // console.log('chatIds:----', chatIds)
    let loChatIds = JSON.parse(chatIds)
    _.remove(loChatIds, (chatId) => selectedChat.id === chatId)
    const count = loChatIds.length
    loSetUnreadChats({ unreadChatIds: loChatIds, count })
  }

  onCallMessageReceived = (callDto) => {
    let loCallDto = JSON.parse(callDto)
    this.setState({
      callDto: loCallDto,
      isJoinCallModalVisible: true,
    })
  }

  componentWillUnmount = () => {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect()
    }
  }

  //   componentDidUpdate (prevProps) {
  //     const { selectedLanguage, isFocused, screenProps: { t } } = this.props;
  //     if ( selectedLanguage !== prevProps.selectedLanguage) {
  //         this.props.navigation.setParams({ title: t('HomeTitle') });
  //     } if ( isFocused === true) {
  //         // BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
  //     } if ( isFocused === false) {
  //         // BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  //     }

  // }

  handleBackPress = () => {
    // works best when the goBack is async
    this.exitApplication()
    return true
    // BackHandler.exitApp();
    // return true;
  }

  hideModal = () => {
    this.setState({
      isAlertModalVisible: false,
    })
  }

  showCallJoinRequestModal = () => {
    this.setState((prevState) => ({
      isJoinCallModalVisible: true,
    }))
  }

  closeCallJoinRequestModal = () => {
    this.setState((prevState) => ({
      isJoinCallModalVisible: false,
    }))
  }

  fetchSubjectDeviceToken = async () => {
    try {
      const deviceTokenData = await retrieveSubjectDeviceToken()
      this.setState({
        deviceTokenData,
      })
    } catch (error) {
      console.log(error)
    }
  }

  openCallLink = () => {
    const { deviceTokenData, callDto } = this.state
    const callUrl = `${properties.jitsiMeetUrl}/${
      callDto.accessCode
    }?subjectDeviceToken=${encodeURIComponent(
      deviceTokenData.subjectDeviceToken
    )}&subjectDeviceTokenId=${deviceTokenData.subjectDeviceTokenId}`
    if (Platform.OS == 'web') {
      window.open(callUrl, '_blank')
    } else {
      Linking.openURL(callUrl)
    }
    this.closeCallJoinRequestModal()
  }

  render() {
    const {
      subjectCompliance,
      isAlertModalVisible,
      isJoinCallModalVisible,
      callDto: call,
    } = this.state
    const {
      navigation,
      loading,
      screenProps,
      subject: { timeZone },
      isTrainingCompleted,
    } = this.props
    return (
      <Home
        isAlertModalVisible={isAlertModalVisible}
        hideModal={this.hideModal}
        navigation={navigation}
        loading={loading}
        subjectCompliance={subjectCompliance}
        retrieveSubjectCompliance={this.retrieveSubjectCompliance}
        screenProps={screenProps}
        closeCallJoinRequestModal={this.closeCallJoinRequestModal}
        isJoinCallModalVisible={isJoinCallModalVisible}
        call={call}
        openCallLink={this.openCallLink}
        timeZone={timeZone}
        isTrainingCompleted={isTrainingCompleted}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.changeLanguage.selectedLanguage,
  loading: state.loading,
  subject: state.subjectStudyMetaData.subject,
  selectedChat: state.chat.selectedChat,
  subjectPrivileges: state.subjectPrivileges,
  isTrainingCompleted: state.subjectStudyMetaData.trainingCompleted,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
      saveHealthkitData,
      setUnreadChats,
      retrieveUnreadChats: retrieveUnreadChatsAction,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationFocus(HomeScreen))
