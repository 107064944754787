export default {
"Africa/Abidjan":"Afrika/Abidjan",
"Africa/Accra":"Afrika/Accra",
"Africa/Addis_Ababa":"Afrika/Addis_Ababa",
"Africa/Algiers":"Afrika/Algeria",
"Africa/Asmara":"Afrika/Asmara",
"Africa/Asmera":"Afrika/Asmera",
"Africa/Bamako":"Afrika/Afrika",
"Africa/Bangui":"Afrika/Bangui",
"Africa/Banjul":"Afrika/Banjul",
"Africa/Bissau":"Afrika/Bissau",
"Africa/Blantyre":"Afrika/Blantyre",
"Africa/Brazzaville":"Afrika/Brazzaville",
"Africa/Bujumbura":"Afrika/Bujumbura",
"Africa/Cairo":"Afrika/Kairo",
"Africa/Casablanca":"Afrika/Casablanca",
"Africa/Ceuta":"Afrika/Ceuta",
"Africa/Conakry":"Afrika/Conakry",
"Africa/Dakar":"Afrika/Dakar",
"Africa/Dar_es_Salaam":"Afrika/Dar_es_Salam",
"Africa/Djibouti":"Afrika/Djibouti",
"Africa/Douala":"Afrika/Douala",
"Africa/El_Aaiun":"Afrika/El_Aaiun",
"Africa/Freetown":"Afrika/Freetown",
"Africa/Gaborone":"Afrika/Gaboron",
"Africa/Harare":"Afrika/Harare",
"Africa/Johannesburg":"Afrika/Johannesburg",
"Africa/Juba":"Afrika/Juba",
"Africa/Kampala":"Afrika/Kampala",
"Africa/Khartoum":"Afrika/Khartoum",
"Africa/Kigali":"Afrika/Kigali",
"Africa/Kinshasa":"Afrika/Kinshasa",
"Africa/Lagos":"Afrika/Lagos",
"Africa/Libreville":"Afrika/Libreville",
"Africa/Lome":"Afrika/Lomé",
"Africa/Luanda":"Afrika/Luanda",
"Africa/Lubumbashi":"Afrika/Lubumbashi",
"Africa/Lusaka":"Afrika/Lusaka",
"Africa/Malabo":"Afrika/Blur",
"Africa/Maputo":"Afrika/Maputo",
"Africa/Maseru":"Afrika/Maseru",
"Africa/Mbabane":"Afrika/Mbabane",
"Africa/Mogadishu":"Afrika/Mogadishu",
"Africa/Monrovia":"Afrika/Monrovia",
"Africa/Nairobi":"Afrika/Nairobi",
"Africa/Ndjamena":"Afrika/Ndjamena",
"Africa/Niamey":"Afrika/Niamey",
"Africa/Nouakchott":"Afrika/Nouakchott",
"Africa/Ouagadougou":"Afrika/Ouagadougou",
"Africa/Porto-Novo":"Afrika/Porto-Novo",
"Africa/Sao_Tome":"Afrika/Sao_Tome",
"Africa/Timbuktu":"Afrika/Timbuktu",
"Africa/Tripoli":"Afrika/Tripoli",
"Africa/Tunis":"Afrika/Tunisia",
"Africa/Windhoek":"Afrika/Windhoek",
"America/Adak":"Amerika/Adak",
"America/Anchorage":"Amerika/Anchorage",
"America/Anguilla":"Amerika/Anguilla",
"America/Antigua":"Amerika/Antigua",
"America/Araguaina":"Amerika/Araguaina",
"America/Argentina/Buenos_Aires":"Amerika/Arjantin/Buenos_Aires",
"America/Argentina/Catamarca":"Amerika/argentinsk/katamarin",
"America/Argentina/ComodRivadavia":"Amerika/Argentina/Comodrivadavia",
"America/Argentina/Cordoba":"Amerika/Argentina/Cordoba",
"America/Argentina/Jujuy":"Amerika/Arjantin/Jujuy",
"America/Argentina/La_Rioja":"Amerika/Argentina/La_Rioja",
"America/Argentina/Mendoza":"Amerika/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos":"Amerika/Arjantin/Rio_Gallegos",
"America/Argentina/Salta":"Amerika/Argentina/Salta",
"America/Argentina/San_Juan":"Amerika/Arjantin/San_Juan",
"America/Argentina/San_Luis":"Amerika/Arjantin/San_Luis",
"America/Argentina/Tucuman":"Amerika/Argentina/Tucuman",
"America/Argentina/Ushuaia":"Amerika/Arjantin/Ushuaia",
"America/Aruba":"Amerika/Aruba",
"America/Asuncion":"Amerika / antagelse",
"America/Atikokan":"Amerika/Atikokan",
"America/Atka":"Amerika/Atka",
"America/Bahia":"Amerika/Bahia",
"America/Bahia_Banderas":"Amerika/Bahia_Banderas",
"America/Barbados":"Amerika/Barbados",
"America/Belem":"Amerika/Belem",
"America/Belize":"Amerika/Belize",
"America/Blanc-Sablon":"Amerikansk/Blanc-Screen Printing",
"America/Boa_Vista":"Amerika/Boa_Vista",
"America/Bogota":"Amerika/Bogota",
"America/Boise":"Amerika/Boise",
"America/Buenos_Aires":"Amerika/Buenos_Aires",
"America/Cambridge_Bay":"Amerika/Cambridge_Bay",
"America/Campo_Grande":"Amerika/Campo_Grande",
"America/Cancun":"Amerika/Cancun",
"America/Caracas":"Amerika/Caracas",
"America/Catamarca":"Amerika/Catamarca",
"America/Cayenne":"Amerika/Cayenne",
"America/Cayman":"Amerika/Cayman",
"America/Chicago":"Amerika/Chicago",
"America/Chihuahua":"Amerikansk/Chihuahua",
"America/Ciudad_Juarez":"Amerika/Ciudad_Juarez",
"America/Coral_Harbour":"Amerika/Coral_Harbour",
"America/Cordoba":"USA/Cordoba",
"America/Costa_Rica":"Amerika/Costa_Rica",
"America/Creston":"Amerika/Creston",
"America/Cuiaba":"Amerika/Cuiaba",
"America/Curacao":"Amerika/Curacao",
"America/Danmarkshavn":"Amerika/Danmarkshavn",
"America/Dawson":"Amerika/Dawson",
"America/Dawson_Creek":"Amerika/Dawson_Creek",
"America/Denver":"Amerika/Denver",
"America/Detroit":"Amerika/Detroit",
"America/Dominica":"Amerika/Dominica",
"America/Edmonton":"Amerika/Edmonton",
"America/Eirunepe":"Amerika/Eirunepe",
"America/El_Salvador":"Amerika/El_Salvador",
"America/Ensenada":"Amerika/Ensenada",
"America/Fort_Nelson":"Amerika/Fort_Nelson",
"America/Fort_Wayne":"Amerika/Fort_Wayne",
"America/Fortaleza":"Amerika/Fortaleza",
"America/Glace_Bay":"America/Glace_Bay",
"America/Godthab":"Amerika/Godthab",
"America/Goose_Bay":"America/Goose_Bay",
"America/Grand_Turk":"Amerika/Grand_Turk",
"America/Grenada":"Amerika/Grenada",
"America/Guadeloupe":"Amerika/Guadeloupe",
"America/Guatemala":"Amerika/Guatemala",
"America/Guayaquil":"Amerika/Guayaquil",
"America/Guyana":"USA/Guyana",
"America/Halifax":"Amerika/Halifax",
"America/Havana":"Amerika/slektninger",
"America/Hermosillo":"Amerika/Hermosillo",
"America/Indiana/Indianapolis":"Amerika/Indiana/Indianapolis",
"America/Indiana/Knox":"American/Indiana/Knox",
"America/Indiana/Marengo":"Amerika/Indiana/Marengo",
"America/Indiana/Petersburg":"Amerika/Indiana/Petersburg",
"America/Indiana/Tell_City":"Amerika/Indiana/Tell_City",
"America/Indiana/Vevay":"Amerika/Indiana/Vevay",
"America/Indiana/Vincennes":"Amerika/Indiana/Vincennes",
"America/Indiana/Winamac":"Amerika/indisk/Winamac",
"America/Indianapolis":"Amerika/Indianapolis",
"America/Inuvik":"USA/Inuvik",
"America/Iqaluit":"Amerika/Iqaluit",
"America/Jamaica":"Amerika/Jamaika",
"America/Jujuy":"Amerika/Jujuy",
"America/Juneau":"Amerika/Juneau",
"America/Kentucky/Louisville":"Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello":"Amerika/Kentucky/Monticello",
"America/Knox_IN":"Amerika/Knox_in",
"America/Kralendijk":"Amerika/Kralendijk",
"America/La_Paz":"Amerika/La_Paz",
"America/Lima":"Amerika/fem",
"America/Los_Angeles":"Amerika/Los_Angeles",
"America/Louisville":"Amerika/Louisville",
"America/Lower_Princes":"Amerika/Lower_Princes",
"America/Maceio":"Amerika/Maceio",
"America/Managua":"Amerika/Managua",
"America/Manaus":"Amerika/Manaus",
"America/Marigot":"Amerika/Marigot",
"America/Martinique":"Amerika/Martinique",
"America/Matamoros":"Amerika/Matamoros",
"America/Mazatlan":"Amerika/Mazatlan",
"America/Mendoza":"Amerika/Mendoza",
"America/Menominee":"Amerika/Menominee",
"America/Merida":"Amerika/Merida",
"America/Metlakatla":"Amerika/studenter",
"America/Mexico_City":"America/Mexico_City",
"America/Miquelon":"Amerika/Miquelon",
"America/Moncton":"Amerika/Moncton",
"America/Monterrey":"Amerika/Monterrey",
"America/Montevideo":"Amerika/Montevideo",
"America/Montreal":"Amerika/Montreal",
"America/Montserrat":"Amerika/Montserrat",
"America/Nassau":"Amerika/Nassau",
"America/New_York":"Amerika/New_York",
"America/Nipigon":"Amerika/Nipigon",
"America/Nome":"Amerika/navn",
"America/Noronha":"Amerika/Noronha",
"America/North_Dakota/Beulah":"Amerika/North_Dakota/Beulah",
"America/North_Dakota/Center":"Amerika/North_Dakota/Central",
"America/North_Dakota/New_Salem":"Amerika/North_Dakota/New_Salem",
"America/Nuuk":"Amerika/Nuuk",
"America/Ojinaga":"Amerika/Ojinaga",
"America/Panama":"Amerika/Panama",
"America/Pangnirtung":"Amerika/Pangridung",
"America/Paramaribo":"Amerika/Paramaribo",
"America/Phoenix":"Amerika/Phoenix",
"America/Port-au-Prince":"Amerika/Port-au-Prince",
"America/Port_of_Spain":"America/Spain Port",
"America/Porto_Acre":"Amerika/Porto_Acre",
"America/Porto_Velho":"Amerika/Porto_Velho",
"America/Puerto_Rico":"Amerika/Puerto_Rico",
"America/Punta_Arenas":"Amerika/Punta_Arenas",
"America/Rainy_River":"Amerika/Rainy_River",
"America/Rankin_Inlet":"America/Rankin_Inlet",
"America/Recife":"Amerika/Recife",
"America/Regina":"USA/Regina",
"America/Resolute":"Amerika/bestemt",
"America/Rio_Branco":"Amerika/Rio_Branco",
"America/Rosario":"Amerika/rosenkrans",
"America/Santa_Isabel":"Amerika/Santa_Isabel",
"America/Santarem":"Amerika/Santarem",
"America/Santiago":"Amerika/Santiago",
"America/Santo_Domingo":"Amerika/Santo_Domingo",
"America/Sao_Paulo":"Amerika/Sao_Paulo",
"America/Scoresbysund":"Amerika/Scoresbysund",
"America/Shiprock":"Amerika/Shiprock",
"America/Sitka":"Amerika/Sitka",
"America/St_Barthelemy":"Amerika/St_Barthelemy",
"America/St_Johns":"Amerika/St_Johns",
"America/St_Kitts":"Amerika/St_Kitts",
"America/St_Lucia":"Amerika/St_Lucia",
"America/St_Thomas":"Amerika/St_Thomas",
"America/St_Vincent":"Amerika/St_Vincent",
"America/Swift_Current":"Amerika/Swift_Current",
"America/Tegucigalpa":"Amerika/Tegucigalpa",
"America/Thule":"Amerika/Thule",
"America/Thunder_Bay":"Amerika/Thunder_Bay",
"America/Tijuana":"Amerika/Tijuana",
"America/Toronto":"Amerika/Toronto",
"America/Tortola":"Amerikansk/Tortola",
"America/Vancouver":"Amerika/Vancouver",
"America/Virgin":"Amerikansk/jomfru",
"America/Whitehorse":"USA/Whitehorse",
"America/Winnipeg":"Amerika/Winnipeg",
"America/Yakutat":"Amerika/Yakutat",
"America/Yellowknife":"Amerika/Yellowknife",
"Antarctica/Casey":"Antarktika/Casey",
"Antarctica/Davis":"Antarktis/Davis",
"Antarctica/DumontDUrville":"Antarktis/Dumontdurville",
"Antarctica/Macquarie":"Antarktis/Macquarie",
"Antarctica/Mawson":"Antarktis/Mawson",
"Antarctica/McMurdo":"Antarktis/McMurdo",
"Antarctica/Palmer":"Antarktika/Palmer",
"Antarctica/Rothera":"Antarktika/Rothera",
"Antarctica/South_Pole":"Antarktis/Sydpolen",
"Antarctica/Syowa":"Antarktis/Syowa",
"Antarctica/Troll":"Antarktika/Trol",
"Antarctica/Vostok":"Antarktika/Vostok",
"Arctic/Longyearbyen":"Arktik/Longyearbyen",
"Asia/Aden":"Asia/Eden",
"Asia/Almaty":"Asia/Almaty",
"Asia/Amman":"Asia/Amman",
"Asia/Anadyr":"Asia/Anadyr",
"Asia/Aqtau":"Asia/Aktau",
"Asia/Aqtobe":"Asya/Aktobe",
"Asia/Ashgabat":"Asia/Ashgabat",
"Asia/Ashkhabad":"Asia/Ashabad",
"Asia/Atyrau":"Asia/Atyrau",
"Asia/Baghdad":"Asia/Bagdad",
"Asia/Bahrain":"Asia/Bahrain",
"Asia/Baku":"Asia/Baku",
"Asia/Bangkok":"Asia/Bangkok",
"Asia/Barnaul":"Asia/Barnaul",
"Asia/Beirut":"Asia/Beirut",
"Asia/Bishkek":"Asia/Bishkek",
"Asia/Brunei":"Asia/Brunei",
"Asia/Calcutta":"Asia/Calcutta",
"Asia/Chita":"Asia/Sittende",
"Asia/Choibalsan":"Asia/Choibalsan",
"Asia/Chongqing":"Asya/Chongqing",
"Asia/Chungking":"Asia/Chungking",
"Asia/Colombo":"Asia/Columbus",
"Asia/Dacca":"Asia/Dacca",
"Asia/Damascus":"Asia/Damaskus",
"Asia/Dhaka":"Asia/Dhaka",
"Asia/Dili":"asiatisk/ikke",
"Asia/Dubai":"Asia/Dubai",
"Asia/Dushanbe":"Asia/mandag",
"Asia/Famagusta":"Asia/Famagusta",
"Asia/Gaza":"Asia/Gaza",
"Asia/Harbin":"Asya/Harbin",
"Asia/Hebron":"Asia/Hebron",
"Asia/Ho_Chi_Minh":"Asya/Ho_Chi_Minh",
"Asia/Hong_Kong":"Asia/Hong Kong",
"Asia/Hovd":"Asya/Hovd",
"Asia/Irkutsk":"Asya/Irkutsk",
"Asia/Istanbul":"Asia/Istanbul",
"Asia/Jakarta":"Asia/Jakarta",
"Asia/Jayapura":"Asya/Jayapura",
"Asia/Jerusalem":"Asia/Jerusalem",
"Asia/Kabul":"Asia/Kabul",
"Asia/Kamchatka":"Asia/Kamchatka",
"Asia/Karachi":"Asia/Karachi",
"Asia/Kashgar":"Asia/Kashgar",
"Asia/Kathmandu":"Asia/Kathmandu",
"Asia/Katmandu":"Asia/Kathmandu",
"Asia/Khandyga":"Asya/Khandyga",
"Asia/Kolkata":"Asia/Calcutta",
"Asia/Krasnoyarsk":"Asia/Krasnoyarsk",
"Asia/Kuala_Lumpur":"Asia/Kuala_Lumpur",
"Asia/Kuching":"Asia/Kuching",
"Asia/Kuwait":"Asia/Kuwait",
"Asia/Macao":"Asya/Makao",
"Asia/Macau":"Asya/Makao",
"Asia/Magadan":"Asia/Magadan",
"Asia/Makassar":"Asya/Makassar",
"Asia/Manila":"Asia/Manila",
"Asia/Muscat":"Asia/Muscat",
"Asia/Nicosia":"Asia/Nicosia",
"Asia/Novokuznetsk":"Asya/Novokuznetsk",
"Asia/Novosibirsk":"Asia/Novosibirsk",
"Asia/Omsk":"Asia/Omsk",
"Asia/Oral":"Asiatisk/Oral",
"Asia/Phnom_Penh":"Asya/Phnom_Penh",
"Asia/Pontianak":"Asya/Pontianak",
"Asia/Pyongyang":"Asia/Pyongyang",
"Asia/Qatar":"Asia/Qatar",
"Asia/Qostanay":"Asia/kysten",
"Asia/Qyzylorda":"Asia/Rød",
"Asia/Rangoon":"Asia/Rangoon",
"Asia/Riyadh":"Asya/Riyad",
"Asia/Saigon":"Asia/Saigon",
"Asia/Sakhalin":"Asya/Sakhalin",
"Asia/Samarkand":"Asia/Samarkand",
"Asia/Seoul":"Asya/Alene",
"Asia/Shanghai":"Asia/Shanghai",
"Asia/Singapore":"Asya/Singapore",
"Asia/Srednekolymsk":"Asya/Srednekolymsk",
"Asia/Taipei":"Asia/Taipei",
"Asia/Tashkent":"Asia/Tashkent",
"Asia/Tbilisi":"Asia/Tbilisi",
"Asia/Tehran":"Asia/Teheran",
"Asia/Tel_Aviv":"Asia/Tel_Aviv",
"Asia/Thimbu":"Asya/Thimbu",
"Asia/Thimphu":"Asia/Thimphu",
"Asia/Tokyo":"Asia/Tokyo",
"Asia/Tomsk":"Asia/Tomsk",
"Asia/Ujung_Pandang":"Asya/Ujung_Pandang",
"Asia/Ulaanbaatar":"Asia/Ulaanbaatar",
"Asia/Ulan_Bator":"Asia/Ulan_Bator",
"Asia/Urumqi":"Asia/Urumqi",
"Asia/Ust-Nera":"Asia/Ust-Nera",
"Asia/Vientiane":"Asia/Vientiane",
"Asia/Vladivostok":"Asya/Vladivostok",
"Asia/Yakutsk":"Asya/Yakutsk",
"Asia/Yangon":"Asia/Yangon",
"Asia/Yekaterinburg":"Asya/Yekaterinburg",
"Asia/Yerevan":"Asia/Jerevan",
"Atlantic/Azores":"Atlanteren/Azorene",
"Atlantic/Bermuda":"Atlanterhavet/Bermuda",
"Atlantic/Canary":"Atlanterhavet/Kanarihavet",
"Atlantic/Cape_Verde":"Atlanterhavet/Kapp_Verde",
"Atlantic/Faeroe":"Atlanterhavet/færøysk",
"Atlantic/Faroe":"Atlanterhavet/færøysk",
"Atlantic/Jan_Mayen":"Atlantik/Jan_Mayen",
"Atlantic/Madeira":"Atlantik/Madeira",
"Atlantic/Reykjavik":"Atlanterhavet/Reykjavik",
"Atlantic/South_Georgia":"Atlanterhavet/Sør_Georgia",
"Atlantic/St_Helena":"Atlantik/St Helena",
"Atlantic/Stanley":"Atlantic/Stanley",
"Australia/ACT":"Australia/ACT",
"Australia/Adelaide":"Australia/Adelaide",
"Australia/Brisbane":"Australia/Brisbane",
"Australia/Broken_Hill":"Australia/Broken_Hill",
"Australia/Canberra":"Australia/Canberra",
"Australia/Currie":"Australia/Currie",
"Australia/Darwin":"Australia/Darwin",
"Australia/Eucla":"Australia/Eucla",
"Australia/Hobart":"Australia/Hobart",
"Australia/LHI":"Australia/LHI",
"Australia/Lindeman":"Australia/Lindeman",
"Australia/Lord_Howe":"Australia/Lord_Howe",
"Australia/Melbourne":"Australia/Melbourne",
"Australia/NSW":"Australia/NSW",
"Australia/North":"Australia/Nord",
"Australia/Perth":"Australia/Perth",
"Australia/Queensland":"Australia/Queensland",
"Australia/South":"Australia/Sør",
"Australia/Sydney":"Australia/Sydney",
"Australia/Tasmania":"Australia/Tasmania",
"Australia/Victoria":"Australia/Victoria",
"Australia/West":"Australia/Vest",
"Australia/Yancowinna":"Australia/Yancowinna",
"Brazil/Acre":"Brasil/Acre",
"Brazil/DeNoronha":"Brasil/Denoronha",
"Brazil/East":"Brasil/Øst",
"Brazil/West":"Brasil/Vest",
"CET":"DETTE",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Canada/Atlanterhavet",
"Canada/Central":"Canada/Central",
"Canada/Eastern":"Canada/Øst",
"Canada/Mountain":"Canada/fjell",
"Canada/Newfoundland":"Canada/Newfoundland",
"Canada/Pacific":"Canada/Stillehavet",
"Canada/Saskatchewan":"Canada/Saskatchewan",
"Canada/Yukon":"Kanada/Yukon",
"Chile/Continental":"Chile/kontinent",
"Chile/EasterIsland":"Chile/Påskeøya",
"Cuba":"Cuba",
"EET":"MAT",
"EST":"ØST",
"EST5EDT":"EST5EDT",
"Egypt":"Søt mais",
"Eire":"Eire",
"Etc/GMT":"vb./GMT",
"Etc/GMT+0":"Etc/GMT+0",
"Etc/GMT+1":"Etc/GMT+1",
"Etc/GMT+10":"Etc/GMT+10",
"Etc/GMT+11":"Etc/GMT+11",
"Etc/GMT+12":"Etc/GMT+12",
"Etc/GMT+2":"Etc/GMT+2",
"Etc/GMT+3":"Etc/GMT+3",
"Etc/GMT+4":"Etc/GMT+4",
"Etc/GMT+5":"Etc/GMT+5",
"Etc/GMT+6":"Etc/GMT+6",
"Etc/GMT+7":"Etc/GMT+7",
"Etc/GMT+8":"Etc/GMT+8",
"Etc/GMT+9":"Etc/GMT+9",
"Etc/GMT-0":"Etc/GMT-0",
"Etc/GMT-1":"Etc/GMT-1",
"Etc/GMT-10":"Etc/GMT-10",
"Etc/GMT-11":"Etc/GMT-11",
"Etc/GMT-12":"Etc/GMT-12",
"Etc/GMT-13":"Etc/GMT-13",
"Etc/GMT-14":"Etc/GMT-14",
"Etc/GMT-2":"Etc/GMT-2",
"Etc/GMT-3":"Etc/GMT-3",
"Etc/GMT-4":"Etc/GMT-4",
"Etc/GMT-5":"Etc/GMT-5",
"Etc/GMT-6":"Etc/GMT-6",
"Etc/GMT-7":"Etc/GMT-7",
"Etc/GMT-8":"Etc/GMT-8",
"Etc/GMT-9":"Etc/GMT-9",
"Etc/GMT0":"Etc/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"osv/UCT",
"Etc/UTC":"Etc/UTC",
"Etc/Universal":"Etc/Universal",
"Etc/Zulu":"osv/zulu",
"Europe/Amsterdam":"Europa/Amsterdam",
"Europe/Andorra":"Europa/Andorra",
"Europe/Astrakhan":"Europa/Astrakhan",
"Europe/Athens":"Europa/Aten",
"Europe/Belfast":"Europa/Belfast",
"Europe/Belgrade":"Europa/Beograd",
"Europe/Berlin":"Europa/Berlin",
"Europe/Bratislava":"Europa/Bratislava",
"Europe/Brussels":"Europa/Brussel",
"Europe/Bucharest":"Europa/Bukarest",
"Europe/Budapest":"Europa/Budapest",
"Europe/Busingen":"Europa/busser",
"Europe/Chisinau":"Europa/Chisinau",
"Europe/Copenhagen":"Europa/København",
"Europe/Dublin":"Europa/Dublin",
"Europe/Gibraltar":"Europa/Gibraltar",
"Europe/Guernsey":"Europa/Guernsey",
"Europe/Helsinki":"Europa/Helsingfors",
"Europe/Isle_of_Man":"Europa/ADA_OF_MAN",
"Europe/Istanbul":"Europa istanbul",
"Europe/Jersey":"Europa/Jersey",
"Europe/Kaliningrad":"Europa/Kaliningrad",
"Europe/Kiev":"Europa/Kiev",
"Europe/Kirov":"Europa/Kirov",
"Europe/Kyiv":"Europa/Kiev",
"Europe/Lisbon":"Europa/Lisboa",
"Europe/Ljubljana":"Europa/Ljubljana",
"Europe/London":"Europa/London",
"Europe/Luxembourg":"Europa/Luxembourg",
"Europe/Madrid":"Europa/Madrid",
"Europe/Malta":"Europa/Malta",
"Europe/Mariehamn":"Europa/Mariehamn",
"Europe/Minsk":"Europa/Minsk",
"Europe/Monaco":"Europa/Monaco",
"Europe/Moscow":"Europa/Moskva",
"Europe/Nicosia":"Europa/Nicosia",
"Europe/Oslo":"Europa/Oslo",
"Europe/Paris":"Europa/Paris",
"Europe/Podgorica":"Europa/Podgorica",
"Europe/Prague":"Europa/Praha",
"Europe/Riga":"Europa/Riga",
"Europe/Rome":"Europa/Roma",
"Europe/Samara":"Europa/Samara",
"Europe/San_Marino":"Europa/San_Marino",
"Europe/Sarajevo":"Europa/Sarajevo",
"Europe/Saratov":"Avrupa/Saratov",
"Europe/Simferopol":"Europa/Simferopol",
"Europe/Skopje":"Europa/Skopje",
"Europe/Sofia":"Europa/Sofia",
"Europe/Stockholm":"Europa/Stockholm",
"Europe/Tallinn":"Europa/Tallinn",
"Europe/Tirane":"Europa/Tirana",
"Europe/Tiraspol":"Europa/Tiraspol",
"Europe/Ulyanovsk":"Avrupa/Ulyanovsk",
"Europe/Uzhgorod":"Europa/Uzhgorod",
"Europe/Vaduz":"Europa/Vaduz",
"Europe/Vatican":"Europa/Vatikanet",
"Europe/Vienna":"Europa/Wien",
"Europe/Vilnius":"Europa/Vilnius",
"Europe/Volgograd":"Europa/Volgograd",
"Europe/Warsaw":"Europa/Warszawa",
"Europe/Zagreb":"Europa/Zagreb",
"Europe/Zaporozhye":"Europa/Zaporizhia",
"Europe/Zurich":"Europa/Zürich",
"GB":"GB",
"GB-Eire":"GB Eire",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HST",
"Hongkong":"Hong Kong",
"Iceland":"Island",
"Indian/Antananarivo":"Hintli/Antananarivo",
"Indian/Chagos":"Indisk/Chagos",
"Indian/Christmas":"Indisk/jul",
"Indian/Cocos":"Indisk/kokos",
"Indian/Comoro":"India/Comoro",
"Indian/Kerguelen":"Indisk/Kerguelen",
"Indian/Mahe":"Indisk/Mahe",
"Indian/Maldives":"India/Maldivene",
"Indian/Mauritius":"India/Mauritius",
"Indian/Mayotte":"India/Mayotte",
"Indian/Reunion":"Indisk/Reunion",
"Iran":"İran",
"Israel":"Israel",
"Jamaica":"Jamaica",
"Japan":"Japan",
"Kwajalein":"Kwajalein",
"Libya":"Libya",
"MET":"MED",
"MST":"MST",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"Mexico/Bajanorte",
"Mexico/BajaSur":"Mexika/Bajasur",
"Mexico/General":"Mexico/General",
"NZ":"NYTT NYTT",
"NZ-CHAT":"NZ-CHAT",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Pacific/Apia",
"Pacific/Auckland":"Pacific/Auckland",
"Pacific/Bougainville":"Pasifik/Bougainville",
"Pacific/Chatham":"Pacific/Chatham",
"Pacific/Chuuk":"Pacific/Chuuk",
"Pacific/Easter":"Stillehavet/påske",
"Pacific/Efate":"Pacific/Efate",
"Pacific/Enderbury":"Pacific/Enderbury",
"Pacific/Fakaofo":"Pasifik/Fakaofo",
"Pacific/Fiji":"Stillehavet/Fiji",
"Pacific/Funafuti":"Stillehavet/skogen",
"Pacific/Galapagos":"Stillehavet/Galapagos",
"Pacific/Gambier":"Pacific/Gambier",
"Pacific/Guadalcanal":"Stillehavet/Guadalcanal",
"Pacific/Guam":"Stillehavet/Guama",
"Pacific/Honolulu":"Pacific/Honolulu",
"Pacific/Johnston":"Pacific/Johnston",
"Pacific/Kanton":"Stillehavet/kantonen",
"Pacific/Kiritimati":"Pacific/Kiritimati",
"Pacific/Kosrae":"Stillehavet/Kosrae",
"Pacific/Kwajalein":"Pacific/Kwajalein",
"Pacific/Majuro":"Pacific/Majuro",
"Pacific/Marquesas":"Pasifik/Marquesas",
"Pacific/Midway":"Pacific/Midway",
"Pacific/Nauru":"Stillehavet/Nauru",
"Pacific/Niue":"Pacific/Niue",
"Pacific/Norfolk":"Pacific/Norfolk",
"Pacific/Noumea":"Pacific/Noumea",
"Pacific/Pago_Pago":"Pasifik/Pago_Pago",
"Pacific/Palau":"Stillehavet/Palau",
"Pacific/Pitcairn":"Pacific/Pitcairn",
"Pacific/Pohnpei":"Pacific/Pohnpei",
"Pacific/Ponape":"Pasifik/Ponape",
"Pacific/Port_Moresby":"Pacific/Port_Moresby",
"Pacific/Rarotonga":"Pasifik/Rarotonga",
"Pacific/Saipan":"Pacific/Saipan",
"Pacific/Samoa":"Stillehavet/Samoa",
"Pacific/Tahiti":"Stillehavet/Tahiti",
"Pacific/Tarawa":"Pacific/Tarawa",
"Pacific/Tongatapu":"Pasifik/Tongatapu",
"Pacific/Truk":"Stillehavet/lastebil",
"Pacific/Wake":"Pacific/Awakening",
"Pacific/Wallis":"Pacific/Wallis",
"Pacific/Yap":"Pacific/Do",
"Poland":"Polen",
"Portugal":"Portugal",
"ROC":"ROC",
"ROK":"ÅR",
"Singapore":"Singapore",
"Turkey":"Türkiye",
"UCT":"UT",
"US/Alaska":"ABD/Alaska",
"US/Aleutian":"ABD/Aleutian",
"US/Arizona":"ABD/Arizona",
"US/Central":"USA/Sentral",
"US/East-Indiana":"USA/Østlige Indiana",
"US/Eastern":"USA/Øst",
"US/Hawaii":"ABD/Hawaii",
"US/Indiana-Starke":"ABD/Indiana Starke",
"US/Michigan":"ABD/Michigan",
"US/Mountain":"USA/fjell",
"US/Pacific":"USA/Stillehavet",
"US/Samoa":"ABD/Samoa",
"UTC":"UTC",
"Universal":"Universell",
"W-SU":"I SU",
"WET":"VÅT",
"Zulu":"Zulu",
}