import axios from 'axios'
import JSOG from 'jsog'
import _ from 'lodash'
import showToast from '../utils/toast'
import { getDeviceStatus } from '../actions/users'
import appConstants from '../constants/appConstants'
import { store } from '../store/configStore'
import constants from '../constants/constants'
import storage from './storage/storage'

let baseUrl = `${appConstants.baseUrl}/api/app`

const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': '*' },
  transformResponse: [].concat(axios.defaults.transformResponse, (data) => JSOG.decode(data)),
})

axiosConfig.interceptors.request.use(
  (config) => getConfig(config),
  (error) => {
    Promise.reject(error)
  }
)
axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      console.log('Request canceled', error)
      return Promise.resolve(error)
    } else {
      return Promise.reject(error)
    }
  }
)
const getConfig = async (config) => {
  const contextBasedUrl = getContextBasedUrl(config.url);
  config.url = contextBasedUrl;
  const isDeviceOnline = getDeviceStatus()
  if (isDeviceOnline) {  
    config.headers['SubjectAuthorization-Token'] = storage.getString('PrintToken')
    return config
  } else {
    config.cancelToken = new axios.CancelToken((cancel) => cancel('No Internet connection'))
    showToast("You're offline", 'danger', 5000)
    return config
  }
}
const getContextBasedUrl = (url) => {
  const state = store.getState();
  const appType = getAppType();
  let contextBasedUrl = url;
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.PRIMARY_ORG_CODE, _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(state.users?.currentUser,'primaryOrganizationCode', "") : _.get(state.site,'primaryOrganizationCode', ""))
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.STUDY_ID, _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(state.users?.currentUser,'studyId', "") : _.get(state.study,'selectedStudyId', ""))
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.SITE_ID,  _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(state.users?.currentUser,'siteId', "") : _.get(state.site,'id', ""))
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.SUBJECT_ID, _.get(state.subjectStudyMetaData?.subject,'id', ""))
  return contextBasedUrl;
}

const getAppType = () => {
  const state = store.getState();
  return state.appStatus.appType
}
export default axiosConfig
