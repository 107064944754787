import timezoneLocaleRussian from "./timezoneLocale-russian";
export default {
  ...timezoneLocaleRussian,
  LoginTitle: 'Альфа-Клинические Системы',
  LoginACSUserID: 'Номер телефона',
  LoginACSPwd: 'пароль',
  LoginACSLogin: 'Авторизоваться',
  LoginACSFrgtPwd: 'Забыли пароль ?',
  LoginACSVersn: 'Версия',
  HomeTitle: 'Главная',
  HomePull: 'Потяните, чтобы обновить',
  HomeGM: 'Доброе утро',
  HomeTxt: 'Ваши ответы очень важны для исследования исследования, в котором вы участвуете.',
  HomeCompli: 'Соответствие требованиям для каждого периода',
  HomeTotal: 'Всего',
  HomeToday: 'Cегодня',
  HomeCrntWeek: 'На этой неделе',
  HomeCrntMon: 'Этот месяц',
  Homemsgs: 'Сообщения',
  HomeNewmsgs: 'У вас есть новые сообщения',
  'Homemsgs sub txt': 'Нет новых сообщений',
  HomePatnt_Diary: 'Дневник',
  HomePatnt_Diary_sub_txt: 'Пожалуйста, заполните формы.',
  Calls: 'Звонки',
  Chat: 'Чат',
  'HomeQlty of Life': 'Здоровье',
  'HomeQlty of Life sub txt': 'Нет активности',
  'HomeVst Remnders': 'Посетите напоминания',
  'HomeVst Remnders sub txt': 'Нет новых напоминаний',
  HomeTabHome: 'Главная',
  HomeTabmsgs: 'Сообщения',
  Form: 'форма',
  HomeTabCalndr: 'Календарь',
  MessagesTitle: 'Сообщения',
  'Patient DiaryTitle': 'Дневник пациента',
  'Visit CalendarTitle': 'Посетите Календарь',
  'Qlty of LfeChoose optn': 'Выберите свой вариант',
  'Qlty of LfeWithings': 'Withings',
  'Qlty of LfeFitbit': 'Fitbit',
  WithingsTitle: 'Мероприятия',
  'WithingsActivity Tab': 'Мероприятия',
  'WithingsHeart Rate Tab': 'Частота сердцебиения',
  'WithingsHeart Rte TabHeart Rate': 'Частота сердцебиения',
  'WithingsHeart Rte TabThis week': 'На этой неделе',
  'WithingsHeart Rte TabSubmit': 'Отправить',
  FitbitTitle: 'Мероприятия',
  'FitbitActivity Tab': 'Мероприятия',
  'FitbitHeart Rate Tab': 'Частота сердцебиения',
  'FitbitHeart Rte TabHeart  Rate': 'Частота сердцебиения',
  'FitbitHeart Rte TabThis week': 'На этой неделе',
  'FitbitHeart Rte TabSubmit': 'Отправить',
  Actn_sheetMore: 'Больше',
  Actn_sheetStudy: 'Изучение',
  Actn_sheetContact: 'Контакт',
  Actn_sheetFAQ: 'Вопросы-Ответы',
  Actn_sheetChange_Language: 'Выберите язык',
  ChangeLanguage: 'Выберите язык',
  StudyTab1Title: 'Информация об учебе',
  StudyTab1Txt:
    'Добро пожаловать в наше исследование салфетки для просмотра Добро пожаловать в наше исследование салфетки для просмотра ',
  'StudyTab2Brf Dctn': 'Краткое описание',
  'StudyTab3Detail Dctn': 'Подробное описание',
  'StudyTab4Anticipated prblms': 'Ожидаемые проблемы',
  'StudyTab5Withdrn rule': 'Правила снятия средств',
  StudyTab6Video: 'видео',
  'StudyTab6View Video': 'Просмотр видео об учебе',
  StudyTab6Joined: 'Уже присоединился',
  ContactTitle: 'Контактная информация',
  ContactSiteAddress: 'Адрес сайта',
  ContactPI: 'Главный следователь',
  FAQTitle: ' Вопросы-Ответы',
  FieldPrevious: 'предыдущий',
  FieldNext: 'следующий',
  PainscaleText1: 'Мы хотели бы знать, насколько хорошо или плохо ваше здоровье СЕГОДНЯ.',
  PainscaleText2: 'Нажмите на шкалу, чтобы указать, как ваше здоровье СЕГОДНЯ.',
  PainscaleSubmit: 'Отправить',
  PainscaleHealthToday: 'ВАШЕ ЗДОРОВЬЕ СЕГОДНЯ',
  PainscaleHealthToday1: 'ВАШЕ',
  PainscaleHealthToday2: 'ЗДОРОВЬЕ',
  PainscaleHealthToday3: 'СЕГОДНЯ',
  ClickableImageMessage: 'Нажмите на изображение, чтобы выбрать ответ.',
  tmlnallday: 'Весь день',
  painmax: 'Наихудшая возможная боль',
  painmin: 'Нет боли',
  completedat: 'Завершено в ',
  EventsforDay: 'События за день',
  NoEventsforDay: 'Нет событий на выбранную дату.',
  TODAY: 'сегодня',
  MONTH: 'месяц',
  WEEK: 'неделя',
  DAY: 'день',
  ChangeTheme: 'Сменить тему',
  MessageSent: 'Отправлено на',
  MessageRead: 'Читать дальше',
  EventCalendar: 'Календарь событий',
  Logout: 'Выйти',
  USRNAMEWARNING: 'Пожалуйста, введите номер телефона.',
  PWDWARNING: 'Пожалуйста введите пароль.',
  NoFormToFill: 'На выбранную дату формы отсутствуют.',
  PIN: 'ПИН-код',
  EnterOTP: 'Введите ОТП',
  OtpEmpty: 'ОТП не должен быть пустым.',
  WrongOTP: 'Пожалуйста, введите действительный OTP.',
  VerifyOTP: 'Проверить ОТП',
  ResendOTP: 'Отправить ОТП',
  NumberValidate1: 'Введите значение между',
  NumberValidate2: 'и',
  ResetPassword: 'Сброс пароля',
  ChangePassword: 'Изменить пароль',
  NewPassword: 'Новый пароль',
  ConfirmPassword: 'Подтверждение пароля',
  PSWD_NOT_MATCH: 'Пароли не совпадают.',
  PSWD_VALIDATION: 'Пароль не соответствует критериям.',
  PSWD_MIN_LENGTH: '- Пароль должен быть не менее 8 символов.',
  PSWD_MAX_LENGTH: '- Пароль не должен превышать 64 символов.',
  PSWD_ONE_NUMBER: '- Пароль должен содержать хотя бы одну цифру.',
  PSWD_ONE_SC: '- Пароль должен содержать хотя бы один специальный символ.',
  PSWD_ONE_UPPER: '- Пароль должен содержать хотя бы одну заглавную букву.',
  PSWD_NO_SPACES: '- Пароль не должен содержать пробелов.',
  PSWD_RESTRICT: '- Пароль не должен состоять из последовательных и повторяющихся символов.',
  PSWD_NO_USERNAME: '- Пароль не должен включать имя пользователя.',
  PSWD_NO_EMAILNAME: '- Пароль не должен включать имя учетной записи электронной почты.',
  PSWD_NO_SITENAME: '- Пароль не должен включать название сайта.',
  PSWD_NO_ORGNAME: '- Пароль не должен включать название организации.',
  PSWD_NOTE: 'Критерии пароля:',
  Save: 'Сохранить',
  ForgotPSWD: 'Забыли пароль ?',
  OTPMSG: 'Мы вышлем SMS на ваш номер телефона с одноразовым паролем для сброса пароля.',
  FP_PNO: 'Представить ваш зарегистрированный номер телефона с кодом страны.',
  PNO_Vald: 'Номер телефона не должен быть пустым.',
  Submit: 'Представить',
  Cancel: 'Отменить',
  Prev: 'Предыдущие',
  Next: 'Далее',
  NoPain: 'Никакой Боли',
  Mild: 'Мягкий',
  Moderate: 'Умеренный',
  Severe: 'Тяжелые',
  Worst: 'Хуже',
  SetAppLock: 'Установить пароль',
  AppLock: 'Блокировка приложения',
  EnterPIN: 'Введите PIN-код',
  MinPin: 'Ваш PIN-код должен содержать от 4 до 6 цифр.',
  WrongPIN: 'Неверный PIN-код.',
  PINS_NOT_MATCH: 'PIN-коды не совпадают.',
  ConfirmPIN: 'Подтвердите PIN-код',
  OK: 'Хорошо',
  Reset: 'Сброс',
  Confirm: 'Подтвердить',
  SelTimezone: 'Выберите Часовой Пояс ',
  CompletedOn: 'Завершен',
  BScaleValMsg: 'Оцените, пожалуйста, вашу боль.',
  BarcodeValMsg: 'Пожалуйста, отсканируйте штрих-код, чтобы продолжить.',
  DateValMsg: 'Пожалуйста, выберите дату.',
  SingSelValMsg: 'Пожалуйста, выберите вариант.',
  MultSelValMsg: 'Пожалуйста, выберите по крайней мере один параметр.',
  TextValMsg: 'Пожалуйста, заполните это поле.',
  PswdEmpty: 'Пароль не должен быть пустым.',
  CnfPswdEmpty: 'Пожалуйста, подтвердите ваш пароль.',
  SelectTimezone: 'Выберите часовой пояс',
  Timezone: 'Часовой пояс',
  LoginPhPW: 'Войдите в систему с помощью номера телефона и пароля.',
  PhoneNotReg:
    'Ваш номер телефона не зарегистрирован. Пожалуйста, проверьте номер и попробуйте снова.',
  SomethingWrong: 'Что-то пошло не так. Пожалуйста, свяжитесь с вашим следователем.',
  PswdResetSuccess: 'Пароль был успешно сброшен.',
  PswdResetFailure: 'Не удалось сбросить пароль.',
  SavedMessage: 'Сохранено успешно.',
  FailedMessage: 'Не удалось сохранить. Пожалуйста, попробуйте еще раз через некоторое время.',
  FailedRetrieve: 'Не удалось получить.',
  FailedResetPWD: 'Не удалось сбросить пароль.',
  FailedRetrieveMsgs: 'Не удалось получить сообщения.',
  FailedRetrieveFaqs: 'Не удалось получить ответы на часто задаваемые вопросы.',
  FailedRetrieveFields: 'Не удалось получить поля.',
  FailedRetrieveForms: 'Не удалось получить формы.',
  FailedRetrieveTimezones: 'Не удалось получить часовые пояса.',
  FailedRetrieveSubjects: 'Не удалось получить темы.',
  FailedChangeLanguage: 'Не удалось изменить язык.',
  FailedUpdateTimeZone: 'Не удалось обновить часовой пояс.',
  NetworkError: 'Ошибка сети.',
  LogoutMessage: 'Вы выходите из ezpro. Хотите продолжить?',
  YES: 'Да',
  NO: 'нет',
  InvPhonePSWD: 'Неверный номер телефона или пароль.',
  NotValidPhone: 'Вы не ввели правильный номер телефона.',
  ReqNumericField: 'Пожалуйста, заполните это числовое поле.',
  OTPResend: 'OTP возмущаться.',
  SelectDate: 'Выберите дату',
  EnterHere: 'Войти сюда',
  NOMSG: 'Извините, нет сообщения для отображения.',
  NoData: 'Нет данных.',
  UserLocked: 'Пользователь заблокирован. Пожалуйста, свяжитесь с вашим администратором.',
  LanguageValidate: 'Пожалуйста, выберите язык',
  OfflineMessage: 'Сетевое соединение потеряно. Переключение в автономный режим.',
  Offline: 'Не в сети',
  Online: 'В сети',
  savedLocally: 'Сохранено локально.',
  formsToSync: 'Форма(ы) для синхронизации.',
  formToSync: 'Форма для синхронизации.',
  showingOfflineForms:
    'Отображение автономных данных. Пожалуйста, проверьте ваше интернет-соединение.',
  NoForm: 'Нет форм для заполнения.',
  NoFaqs: 'Нет часто задаваемых вопросов.',
  HealthMenu: 'Данные о здоровье',
  myProfile: 'Мой профайл',
  subjectInformation: 'Информация по теме',
  studyInformation: 'изучить информацию',
  versionInformation: 'Информация о версии',
  LoggedUser: 'Пользователь вошел в систему',
  StudyDetails: 'Изучать',
  VersionDetails: 'Информация о версии',
  LoggedInUser: 'Пользователь вошел в систему',
  Study: 'Изучать',
  VersionDetails: 'Сведения о выпуске',
  Syncing: 'Синхронизации . . .',
  SyncSuccess: 'Синхронизировано успешно.',
  MaxAttemptsDone: 'Макс Попытки Готово.',
  LastSynced: 'Последняя синхронизация',
  bmi: 'Индекс массы тела',
  Height: 'Высота',
  Weight: 'Вес',
  LeanBodyMass: 'Мышечная масса тела',
  BodyFat: 'Процент жира в организме',
  Activity: 'Мероприятия',
  Vitals: 'наиболее важные части',
  Biometrics: 'биометрия',
  HeartRate: 'Частота сердцебиения',
  BloodPressure: 'Кровяное давление',
  RespirationRate: 'Частота дыхания',
  BodyTemperature: 'Температура тела',
  BloodGlucose: 'Глюкоза крови',
  Sex: 'секс',
  SleepAnalysis: 'Анализ сна',
  ActiveEnergy: 'Активная энергия',
  FlightsClimbed: 'Полеты поднялись',
  Distance: 'Расстояние',
  Steps: 'меры',
  StepUnits: 'меры',
  DistanceUnits: 'миль',
  FloorUnits: 'этажей',
  ActiveEnergyUnits: 'калорий',
  SleepAnalysisUnits: 'часов',
  MeetingSchedule: 'Расписание встреч',
  UpcomingCalls: 'Предстоящие звонки',
  PastCalls: 'Прошлые звонки',
  NoCallScheduled: 'Сейчас нет звонков',
  NoPastCalls: 'Нет прошлых звонков',
  NoInternet: 'Нет соединения с интернетом.',
  NoChats: 'Нет чата для отображения.',
  SelectChat: 'Пожалуйста, выберите чат или начните новый чат.',
  NoUsersFound: 'Пользователи не найдены',
  Done: 'Готово',
  AddParticipants: 'Добавить участников',
  SearchParticipants: 'Поиск участников',
  LeaveGroup: 'Покинуть группу',
  Participants: 'Участников',
  participants: 'Участников',
  Remove: 'удалять',
  Others: 'другие',
  Add: 'Добавить',
  Notifications: 'Уведомления',
  CallDetails: 'Детали звонка',
  Participants: 'Участников',
  CallHistory: 'История звонков',
  JoinCall: 'Присоединиться к звонку',
  MissedCall: 'Вы пропустили этот звонок.',
  CallInProgress: 'Звонок идет.',
  CallNotStarted: 'Звонок еще не начался.',
  JoinedOn: 'Присоединился',
  LeftOn: 'Вышел на',
  Duration: 'Продолжительность',
  ScheduledDuration: 'Запланированная продолжительность',
  Title: 'Заголовок',
  StartedOn: 'Начато',
  Status: 'Положение дел',
  ScheduledTime: 'Запланированное время',
  Alerts: 'Оповещения',
  Reminders: 'Напоминания',
  Notifications: 'Уведомления',
  VersionInfo: 'Информация о версии',
  FormPreview: 'Предварительный просмотр формы',
  NRSNumber: 'Пожалуйста, выберите номер.',
  SubmitTraining: 'Отправить форму обучения',
  TrainingNotCompleted: 'Пожалуйста, завершите обучение, прежде чем начать свой дневник.',
  StartTraining: 'Начать обучение',
  ViewCompletedTraining: 'Посмотреть завершенное обучение',
  CompleteTraining: 'Пожалуйста, пройдите обучение, чтобы получить доступ к дневниковым формам.',
  Training: 'Обучение',
  SITESTAFFUSRNAMEWARNING: 'Пожалуйста, введите имя пользователя',
  SITESTAFFPINWARNING: 'Пожалуйста, введите PIN-код',
  LoginSiteStaffUsrName: 'Имя пользователя',
  LoginSiteStaffPin: 'ПРИКОЛОТЬ',
  InvUsrNmPIN: 'Неверное имя пользователя или PIN-код',
  InvPasscode: 'Введите действительный идентификатор сайта.',
  EnterPasscode: 'Введите идентификатор сайта TRYAL',
  EnterUsername: 'Введите имя пользователя',
  EnterPassword: 'Введите пароль',
  EnterValidUname: 'Введите действительное имя пользователя',
  EnterValidPassword: 'Введите действительный пароль',
  FirstName: 'Имя',
  LastName: 'Фамилия',
  UserID: 'ID пользователя',
  Site: 'Сайт',
  MyAccount: 'Мой счет',
  StudyList: 'Список исследований',
  SubjectList: 'Список тем',
  SelectFormCategory: 'Выберите категорию формы',
  LoginWithPIN: 'Войти с именем пользователя и PIN-кодом',
  VersionInfo: 'Информация о версии',
  Email: 'Электронная почта',
  SessionTimeoutMessage: 'Истекло время ожидания сеанса',
  FillRequiredFields: 'Пожалуйста, заполните все обязательные поля',
  InvalidPasscode: 'Неверный идентификатор сайта.',
  SomethingWentWrong: 'Что-то пошло не так.',
  InvalidUsernamePassword: 'Неправильное имя пользователя или пароль.',
  InvalidUsernamePasswordWeb:
    '<p style="font-weight:normal"><b>Неправильное имя пользователя или пароль.</b><br/>Если вы являетесь участником или лицом, осуществляющим уход, щелкните ссылку ниже, чтобы перейти на страницу входа в систему участника.</p>',
  ScheduleStartDate: 'Дата начала расписания',
  ScheduleEndDate: 'Дата окончания расписания',
  VisitNotStarted: 'Окно посещения еще не началось.',
  VisitWindowClosed: 'Окно посещения прошло, вы не можете вводить данные.',
  VisitCompleted: 'Визит завершен. Вы не должны обновлять какие-либо анкеты.',
  ShowAlertMsg: 'Вы хотите сохранить изменения?',
  firstName: 'имя',
  lastName: 'фамилия',
  email: 'Эл. адрес',
  phone: 'Телефон',
  language: 'язык',
  study: 'исследование',
  sponsor: 'спонсор',
  versionName: 'название версии',
  Visit: 'Посещать',
  StartTime: 'Время начала',
  EndTime: 'Время окончания',
  VersionUpgradeInfo: 'Версия eDiary обновлена ​​до версии',
  New: 'Новый',
  NoDiary: 'Нет доступных дневников/опросников. Пожалуйста, свяжитесь с персоналом вашего сайта',
  UserInactive:
    'Ваш доступ неактивирован. Пожалуйста, свяжитесь с вашим координатором исследования.',
  SubjectInactive:
    'Субъектный доступ деактивирован. Пожалуйста, свяжитесь с вашим координатором исследования.',
  ProcessMessage: 'Обработка анкеты.',
  LoadingCallData: 'Загрузка данных вызова',
  DownloadMetadata: 'Загрузка метаданных',
  DownloadStudydata: 'Загрузка данных исследования.',
  DownloadQuestionnaries: 'Загрузка анкет',
  FailedRetrieveStudy: 'Не удалось загрузить сведения об исследовании.',

  SubjectCaregiverInactive:
    'Тема не активна. Пожалуйста, свяжитесь с вашим координатором исследования для получения дополнительной информации.',
  OnceFrequencyScheduleStartDate: 'Дата начала расписания',
  OnceFrequencyScheduleEndDate: 'Дата окончания расписания',
  Click: 'Щелчок',
  LoginText: 'для повторного входа в систему',
  TermsAndConditions: 'Политика конфиденциальности и Условия использования',
  TermsNConditionsCancel:
    'Вы должны согласиться с политикой конфиденциальности и условиями, чтобы продолжить использование этого приложения.',
  UnsyncedData:
    'На этом устройстве есть несинхронизированные данные. Вы не можете выйти из системы, пока не синхронизируете свои данные. Пожалуйста, свяжитесь с вашим сайтом, если у вас есть вопросы.',
  ChangeToClinician: 'Изменение входа в систему врача',
  ParticipantLogin: 'Вход участника',
  ClinicianLogin: 'Вход для врача',
  SwitchToParticipant: 'Переключиться на участника',
  OrgText1: 'Если вы хотите войти в другую организацию,',
  OrgText2: 'Пожалуйста, перейдите по ссылке на портале администратора',
  BackToLogin: 'Вернуться на страницу авторизации',
  GoToPortal:
    'В вашем аккаунте выполнены необходимые действия. Пожалуйста, войдите через портал администратора, чтобы завершить действия с вашей учетной записью.',
  Organization: 'Организация',
  NoClinroAccessPrivilege:
    'У вас нет доступа для ввода данных ClinRo. Если у вас есть вопросы, свяжитесь с вашим CRA или администратором.',
  DeviceTimezoneMismatchTitle:
    'Похоже, что часовой пояс вашего устройства не совпадает с настроенным часовым поясом в этом приложении.',
  DeviceTimezoneMismatchSubtitle: 'Хотите изменить ваш часовой пояс?',
  DeviceTimezoneMismatchTzConfigured: 'Ваш настроенный часовой пояс:',
  DeviceTimezoneMismatchTzInferred: 'Часовой пояс вашего устройства:',
  SiteIdentifierInfo: '<p>Найдите свой ТРИАЛ  <b>Идентификатор сайта </b> в портал на странице списка тем</p>',
  InvPhonePSWDWeb:'<p style="font-weight:normal"><b>Неверный номер телефона или пароль.</b><br/>Если вы врач, нажмите ссылку ниже, чтобы перейти на страницу входа в систему врача.</p>',
  LoginUnsyncData: 'На вашем устройстве есть данные, которые необходимо синхронизировать. Пожалуйста, введите свой пароль, чтобы данные могли быть переданы. Пожалуйста, свяжитесь со службой поддержки, если у вас есть вопросы.',
  DeviceTimezoneNoClicked: 'Вы можете изменить свой часовой пояс в любое время, перейдя в раздел «Еще» в нижней части этого экрана.',
  AgreeingToPrivacypolicy: 'Согласие с Политикой конфиденциальности и Условиями использования является обязательным.',
  ReadAndAgree: 'Я прочитал и согласен с условиями',
  IAgree: 'Я согласен',
  SubjectNumber: 'Номер субъекта',
  Version: 'Версия',
  Back: 'Назад',
  Visits: 'Посещения',
  DownloadImageFailedDeviceOffline: 'Этот дневник содержит изображения, не полностью загруженные на ваше устройство. Подключитесь к Интернету и выберите этот дневник еще раз.',
  DownloadImageFailedNoEnoughSpace: 'На вашем устройстве недостаточно места для загрузки одного или нескольких дневников. Пожалуйста, освободите место и повторите попытку.',
  ImageLoading: 'Загрузка изображения...',
  Barcode:'Штрих-код',
  BarcodeMessage:'Сканирование штрих-кода в настоящее время не поддерживается в Интернете. Пожалуйста, используйте мобильное устройство для сканирования штрих-кодов',
  ScanAgain:'Отсканируйте еще раз',
  SelectedValue:'Выбранное значение'

}
