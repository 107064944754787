export default {
    "Africa/Abidjan": "अफ्रीका/अबिदजान",
"Africa/Accra": "अफ्रीका/अक्करा",
"Africa/Addis_Ababa": "अफ्रीका/अदीस_अबाबा",
"Africa/Algiers": "अफ्रीका/अल्जीयर्स",
"Africa/Asmara": "अफ्रीका/अस्मारा",
"Africa/Asmera": "अफ्रीका/असमेरा",
"Africa/Bamako": "अफ्रीका/बमाको",
"Africa/Bangui": "अफ्रीका/बंगुई",
"Africa/Banjul": "अफ्रीका/बंजुल",
"Africa/Bissau": "अफ्रीका/बिसाऊ",
"Africa/Blantyre": "अफ्रीका/ब्लांटायर",
"Africa/Brazzaville": "अफ्रीका/ब्राज़ाविल",
"Africa/Bujumbura": "अफ्रीका/बुजंबुरा",
"Africa/Cairo": "अफ्रीका/काइरो",
"Africa/Casablanca": "अफ्रीका/कैसाब्लांका",
"Africa/Ceuta": "अफ्रीका/सेउटा",
"Africa/Conakry": "अफ्रीका/कोनाक्री",
"Africa/Dakar": "अफ्रीका/डकार",
"Africa/Dar_es_Salaam": "अफ्रीका/दार_एस_सलाम",
"Africa/Djibouti": "अफ्रीका/जिबूती",
"Africa/Douala": "अफ्रीका/डौला",
"Africa/El_Aaiun": "अफ्रीका/एल_ऐयुन",
"Africa/Freetown": "अफ्रीका/फ़्रीटाउन",
"Africa/Gaborone": "अफ्रीका/गैबोरोन",
"Africa/Harare": "अफ्रीका/हरारे",
"Africa/Johannesburg": "अफ्रीका/जोहान्सबर्ग",
"Africa/Juba": "अफ्रीका/जुबा",
"Africa/Kampala": "अफ्रीका/कंपाला",
"Africa/Khartoum": "अफ्रीका/खार्तूम",
"Africa/Kigali": "अफ्रीका/किगाली",
"Africa/Kinshasa": "अफ्रीका/किंशासा",
"Africa/Lagos": "अफ्रीका/लागोस",
"Africa/Libreville": "अफ्रीका/लिब्रेविल",
"Africa/Lome": "अफ्रीका/लोम",
"Africa/Luanda": "अफ्रीका/लुआण्डा",
"Africa/Lubumbashi": "अफ्रीका/लुबुम्बाशी",
"Africa/Lusaka": "अफ्रीका/लुसाका",
"Africa/Malabo": "अफ्रीका/मालाबो",
"Africa/Maputo": "अफ्रीका/मापुटो",
"Africa/Maseru": "अफ्रीका/मासेरू",
"Africa/Mbabane": "अफ्रीका/म्बाबेन",
"Africa/Mogadishu": "अफ्रीका/मोगादिशू",
"Africa/Monrovia": "अफ्रीका/मोनरोविया",
"Africa/Nairobi": "अफ्रीका/नैरोबी",
"Africa/Ndjamena": "अफ्रीका/नजामेना",
"Africa/Niamey": "अफ्रीका/नियामी",
"Africa/Nouakchott": "अफ्रीका/नोआखाचॉट",
"Africa/Ouagadougou": "अफ्रीका/औगाडौगौ",
"Africa/Porto-Novo": "अफ्रीका/पोर्टो-नोवो",
"Africa/Sao_Tome": "अफ्रीका/साओ_टोम",
"Africa/Timbuktu": "अफ्रीका/टिम्बकटू",
"Africa/Tripoli": "अफ्रीका/त्रिपोली",
"Africa/Tunis": "अफ्रीका/ट्यूनिस",
"Africa/Windhoek": "अफ्रीका/विंडहोक",
"America/Adak": "अमेरिका/अदक",
"America/Anchorage": "अमेरिका/एंकरेज",
"America/Anguilla": "अमेरिका/एंगुइला",
"America/Antigua": "अमेरिका/एंटीगुआ",
"America/Araguaina": "अमेरिका/अरागुआइना",
"America/Argentina/Buenos_Aires": "अमेरिका/अर्जेंटीना/ब्यूनस_आयर्स",
"America/Argentina/Catamarca": "अमेरिका/अर्जेंटीना/कैटामार्का",
"America/Argentina/ComodRivadavia": "अमेरिका/अर्जेंटीना/कोमोड्रिवाडविया",
"America/Argentina/Cordoba": "अमेरिका/अर्जेंटीना/कॉर्डोबा",
"America/Argentina/Jujuy": "अमेरिका/अर्जेंटीना/जुजुय",
"America/Argentina/La_Rioja": "अमेरिका/अर्जेंटीना/ला_रियोजा",
"America/Argentina/Mendoza": "अमेरिका/अर्जेंटीना/मेंडोज़ा",
"America/Argentina/Rio_Gallegos": "अमेरिका/अर्जेंटीना/रिओ_गैलेगोस",
"America/Argentina/Salta": "अमेरिका/अर्जेंटीना/साल्टा",
"America/Argentina/San_Juan": "अमेरिका/अर्जेंटीना/सैन_जुआन",
"America/Argentina/San_Luis": "अमेरिका/अर्जेंटीना/सैन_लुइस",
"America/Argentina/Tucuman": "अमेरिका/अर्जेंटीना/टुकुमन",
"America/Argentina/Ushuaia": "अमेरिका/अर्जेंटीना/उशुआइया",
"America/Aruba": "अमेरिका/अरूबा",
"America/Asuncion": "अमेरिका/असुनसियन",
"America/Atikokan": "अमेरिका/एटिकोकन",
"America/Atka": "अमेरिका/अटका",
"America/Bahia": "अमेरिका/बाहिया",
"America/Bahia_Banderas": "अमेरिका/बाहिया_बंडारस",
"America/Barbados": "अमेरिका/बारबाडोस",
"America/Belem": "अमेरिका/बेलेम",
"America/Belize": "अमेरिका/बेलीज",
"America/Blanc-Sablon": "अमेरिका/ब्लैंक-सबलोन",
"America/Boa_Vista": "अमेरिका/बोआ_विस्टा",
"America/Bogota": "अमेरिका/बोगोटा",
"America/Boise": "अमेरिका/बोइस",
"America/Buenos_Aires": "अमेरिका/ब्यूनस_आयर्स",
"America/Cambridge_Bay": "अमेरिका/कैम्ब्रिज_बे",
"America/Campo_Grande": "अमेरिका/कैम्पो_ग्रांडे",
"America/Cancun": "अमेरिका/कैनकन",
"America/Caracas": "अमेरिका/काराकस",
"America/Catamarca": "अमेरिका/कैटामार्का",
"America/Cayenne": "अमेरिका/केयेन",
"America/Cayman": "अमेरिका/केमैन",
"America/Chicago": "अमेरिका/शिकागो",
"America/Chihuahua": "अमेरिका/चिहुआहुआ",
"America/Ciudad_Juarez": "अमेरिका/स्यूडाड_जुआरेज़",
"America/Coral_Harbour": "अमेरिका/कोरल_हार्बर",
"America/Cordoba": "अमेरिका/कॉर्डोबा",
"America/Costa_Rica": "अमेरिका/कोस्टा_रिका",
"America/Creston": "अमेरिका/क्रेस्टन",
"America/Cuiaba": "अमेरिका/कुइबा",
"America/Curacao": "अमेरिका/कुराकाओ",
"America/Danmarkshavn": "अमेरिका/डेनमार्क",
"America/Dawson": "अमेरिका/डॉसन",
"America/Dawson_Creek": "अमेरिका/डॉसन_क्रीक",
"America/Denver": "अमेरिका/डेनवर",
"America/Detroit": "अमेरिका/डेट्रायट",
"America/Dominica": "अमेरिका/डोमिनिका",
"America/Edmonton": "अमेरिका/एडमॉन्टन",
"America/Eirunepe": "अमेरिका/इरुनेपे",
"America/El_Salvador": "अमेरिका/अल सल्वाडोर",
"America/Ensenada": "अमेरिका/एनसेनाडा",
"America/Fort_Nelson": "अमेरिका/फोर्ट_नेल्सन",
"America/Fort_Wayne": "अमेरिका/फोर्ट_वेन",
"America/Fortaleza": "अमेरिका/फ़ोर्टालेज़ा",
"America/Glace_Bay": "अमेरिका/ग्लेस_बे",
"America/Godthab": "अमेरिका/गॉडथब",
"America/Goose_Bay": "अमेरिका/गूज़_बे",
"America/Grand_Turk": "अमेरिका/ग्रैंड_तुर्क",
"America/Grenada": "अमेरिका/ग्रेनाडा",
"America/Guadeloupe": "अमेरिका/ग्वाडेलोप",
"America/Guatemala": "अमेरिका/ग्वाटेमाला",
"America/Guayaquil": "अमेरिका/ग्वायाक्विल",
"America/Guyana": "अमेरिका/गुयाना",
"America/Halifax": "अमेरिका/हैलिफ़ैक्स",
"America/Havana": "अमेरिका/हवाना",
"America/Hermosillo": "अमेरिका/हर्मोसिलो",
"America/Indiana/Indianapolis": "अमेरिका/इंडियाना/इंडियानापोलिस",
"America/Indiana/Knox": "अमेरिका/इंडियाना/नॉक्स",
"America/Indiana/Marengo": "अमेरिका/इंडियाना/मारेंगो",
"America/Indiana/Petersburg": "अमेरिका/इंडियाना/पीटर्सबर्ग",
"America/Indiana/Tell_City": "अमेरिका/इंडियाना/टेल_सिटी",
"America/Indiana/Vevay": "अमेरिका/इंडियाना/वेवे",
"America/Indiana/Vincennes": "अमेरिका/इंडियाना/विन्सेनेस",
"America/Indiana/Winamac": "अमेरिका/इंडियाना/विनमैक",
"America/Indianapolis": "अमेरिका/इंडियानापोलिस",
"America/Inuvik": "अमेरिका/इनुविक",
"America/Iqaluit": "अमेरिका/इकालुइट",
"America/Jamaica": "अमेरिका/जमैका",
"America/Jujuy": "अमेरिका/जुजुय",
"America/Juneau": "अमेरिका/जुनो",
"America/Kentucky/Louisville": "अमेरिका/केंटकी/लुइसविल",
"America/Kentucky/Monticello": "अमेरिका/केंटकी/मोंटीसेलो",
"America/Knox_IN": "अमेरिका/नॉक्स_इन",
"America/Kralendijk": "अमेरिका/क्रालेंडिज्क",
"America/La_Paz": "अमेरिका/ला_पाज़",
"America/Lima": "अमेरिका/लीमा",
"America/Los_Angeles": "अमेरिका/लॉस_एंजेल्स",
"America/Louisville": "अमेरिका/लुइसविल",
"America/Lower_Princes": "अमेरिका/लोअर_प्रिंसेस",
"America/Maceio": "अमेरिका/मैसिओ",
"America/Managua": "अमेरिका/मानागुआ",
"America/Manaus": "अमेरिका/मनौस",
"America/Marigot": "अमेरिका/मैरीगोट",
"America/Martinique": "अमेरिका/मार्टिनिक",
"America/Matamoros": "अमेरिका/मैटामोरोस",
"America/Mazatlan": "अमेरिका/मज़ातलान",
"America/Mendoza": "अमेरिका/मेंडोज़ा",
"America/Menominee": "अमेरिका/मेनोमिनी",
"America/Merida": "अमेरिका/मेरिडा",
"America/Metlakatla": "अमेरिका/मेटलाकाटला",
"America/Mexico_City": "अमेरिका/मेक्सिको_सिटी",
"America/Miquelon": "अमेरिका/मिकेलॉन",
"America/Moncton": "अमेरिका/मॉन्कटन",
"America/Monterrey": "अमेरिका/मॉन्टेरी",
"America/Montevideo": "अमेरिका/मोंटेवीडियो",
"America/Montreal": "अमेरिका/मॉन्ट्रियल",
"America/Montserrat": "अमेरिका/मोंटसेराट",
"America/Nassau": "अमेरिका/नासाउ",
"America/New_York": "अमेरिका/न्यू_यॉर्क",
"America/Nipigon": "अमेरिका/निपिगॉन",
"America/Nome": "अमेरिका/नोम",
"America/Noronha": "अमेरिका/नोरोन्हा",
"America/North_Dakota/Beulah": "अमेरिका/नॉर्थ_डकोटा/बेउला",
"America/North_Dakota/Center": "अमेरिका/नॉर्थ_डकोटा/सेंटर",
"America/North_Dakota/New_Salem": "अमेरिका/नॉर्थ_डकोटा/न्यू_सलेम",
"America/Nuuk": "अमेरिका/नुउक",
"America/Ojinaga": "अमेरिका/ओजिनागा",
"America/Panama": "अमेरिका/पनामा",
"America/Pangnirtung": "अमेरिका/पंग्निरतुंग",
"America/Paramaribo": "अमेरिका/पैरामारिबो",
"America/Phoenix": "अमेरिका/फीनिक्स",
"America/Port-au-Prince": "अमेरिका/पोर्ट-ऑ-प्रिंस",
"America/Port_of_Spain": "अमेरिका/पोर्ट_ऑफ़_स्पेन",
"America/Porto_Acre": "अमेरिका/पोर्टो_एकर",
"America/Porto_Velho": "अमेरिका/पोर्टो_वेल्हो",
"America/Puerto_Rico": "अमेरिका/प्यूर्टो_रिको",
"America/Punta_Arenas": "अमेरिका/पुंटा_एरेनास",
"America/Rainy_River": "अमेरिका/रेनी_रिवर",
"America/Rankin_Inlet": "अमेरिका/रैंकिन_इनलेट",
"America/Recife": "अमेरिका/रेसिफ़",
"America/Regina": "अमेरिका/रेजिना",
"America/Resolute": "अमेरिका/रेसोल्यूट",
"America/Rio_Branco": "अमेरिका/रिओ_ब्रैंको",
"America/Rosario": "अमेरिका/रोसारियो",
"America/Santa_Isabel": "अमेरिका/सांता_इसाबेल",
"America/Santarem": "अमेरिका/सैंटारेम",
"America/Santiago": "अमेरिका/सैंटियागो",
"America/Santo_Domingo": "अमेरिका/सैंटो_डोमिंगो",
"America/Sao_Paulo": "अमेरिका/साओ_पाउलो",
"America/Scoresbysund": "अमेरिका/स्कोर्सबीसंड",
"America/Shiprock": "अमेरिका/शिप्रॉक",
"America/Sitka": "अमेरिका/सीटका",
"America/St_Barthelemy": "अमेरिका/सेंट_बार्थेलेमी",
"America/St_Johns": "अमेरिका/सेंट_जॉन्स",
"America/St_Kitts": "अमेरिका/सेंट_किट्स",
"America/St_Lucia": "अमेरिका/सेंट_लूसिया",
"America/St_Thomas": "अमेरिका/सेंट_थॉमस",
"America/St_Vincent": "अमेरिका/सेंट_विंसेंट",
"America/Swift_Current": "अमेरिका/स्विफ्ट_करंट",
"America/Tegucigalpa": "अमेरिका/तेगुसिगलपा",
"America/Thule": "अमेरिका/थुले",
"America/Thunder_Bay": "अमेरिका/थंडर_बे",
"America/Tijuana": "अमेरिका/तिजुआना",
"America/Toronto": "अमेरिका/टोरंटो",
"America/Tortola": "अमेरिका/टोर्टोला",
"America/Vancouver": "अमेरिका/वैंकूवर",
"America/Virgin": "अमेरिका/वर्जिन",
"America/Whitehorse": "अमेरिका/व्हाइटहॉर्स",
"America/Winnipeg": "अमेरिका/विन्निपेग",
"America/Yakutat": "अमेरिका/याकुतत",
"America/Yellowknife": "अमेरिका/येलोनाइफ़",
"Antarctica/Casey": "अंटार्कटिका/केसी",
"Antarctica/Davis": "अंटार्कटिका/डेविस",
"Antarctica/DumontDUrville": "अंटार्कटिका/डुमोंटडुरविल",
"Antarctica/Macquarie": "अंटार्कटिका/मैक्वेरी",
"Antarctica/Mawson": "अंटार्कटिका/मावसन",
"Antarctica/McMurdo": "अंटार्कटिका/मैकमुर्डो",
"Antarctica/Palmer": "अंटार्कटिका/पामर",
"Antarctica/Rothera": "अंटार्कटिका/रोथेरा",
"Antarctica/South_Pole": "अंटार्कटिका/साउथ_पोल",
"Antarctica/Syowa": "अंटार्कटिका/सियोवा",
"Antarctica/Troll": "अंटार्कटिका/ट्रोल",
"Antarctica/Vostok": "अंटार्कटिका/वोस्तोक",
"Arctic/Longyearbyen": "आर्कटिक/लोंगयेरब्येन",
"Asia/Aden": "एशिया/अदन",
"Asia/Almaty": "एशिया/अल्माटी",
"Asia/Amman": "एशिया/अम्मान",
"Asia/Anadyr": "एशिया/अनादिर",
"Asia/Aqtau": "एशिया/अक़्ताऊ",
"Asia/Aqtobe": "एशिया/अक्तोब",
"Asia/Ashgabat": "एशिया/अश्गाबात",
"Asia/Ashkhabad": "एशिया/अश्खाबाद",
"Asia/Atyrau": "एशिया/अतिराऊ",
"Asia/Baghdad": "एशिया/बगदाद",
"Asia/Bahrain": "एशिया/बहरीन",
"Asia/Baku": "एशिया/बाकू",
"Asia/Bangkok": "एशिया/बैंकॉक",
"Asia/Barnaul": "एशिया/बर्नौल",
"Asia/Beirut": "एशिया/बेरूत",
"Asia/Bishkek": "एशिया/बिश्केक",
"Asia/Brunei": "एशिया/ब्रुनेई",
"Asia/Calcutta": "एशिया/कलकत्ता",
"Asia/Chita": "एशिया/चिता",
"Asia/Choibalsan": "एशिया/चोइबालसन",
"Asia/Chongqing": "एशिया/चोंगकिंग",
"Asia/Chungking": "एशिया/चुंगकिंग",
"Asia/Colombo": "एशिया/कोलंबो",
"Asia/Dacca": "एशिया/ढाका",
"Asia/Damascus": "एशिया/दमिश्क",
"Asia/Dhaka": "एशिया/ढाका",
"Asia/Dili": "एशिया/दिली",
"Asia/Dubai": "एशिया/दुबई",
"Asia/Dushanbe": "एशिया/दुशांबे",
"Asia/Famagusta": "एशिया/फ़ामागुस्टा",
"Asia/Gaza": "एशिया/गाजा",
"Asia/Harbin": "एशिया/हार्बिन",
"Asia/Hebron": "एशिया/हेब्रोन",
"Asia/Ho_Chi_Minh": "एशिया/हो_ची_मिन्ह",
"Asia/Hong_Kong": "एशिया/हांग_कोंग",
"Asia/Hovd": "एशिया/होव्ड",
"Asia/Irkutsk": "एशिया/इरकुत्स्क",
"Asia/Istanbul": "एशिया/इस्तांबुल",
"Asia/Jakarta": "एशिया/जकार्ता",
"Asia/Jayapura": "एशिया/जयापुरा",
"Asia/Jerusalem": "एशिया/जेरूसलम",
"Asia/Kabul": "एशिया/काबुल",
"Asia/Kamchatka": "एशिया/कामचटका",
"Asia/Karachi": "एशिया/कराची",
"Asia/Kashgar": "एशिया/काशगर",
"Asia/Kathmandu": "एशिया/काठमांडू",
"Asia/Katmandu": "एशिया/काठमांडू",
"Asia/Khandyga": "एशिया/खंड्यागा",
"Asia/Kolkata": "एशिया/कोलकाता",
"Asia/Krasnoyarsk": "एशिया/क्रास्नोयार्स्क",
"Asia/Kuala_Lumpur": "एशिया/कुआला_लंपुर",
"Asia/Kuching": "एशिया/कुचिंग",
"Asia/Kuwait": "एशिया/कुवैत",
"Asia/Macao": "एशिया/मकाओ",
"Asia/Macau": "एशिया/मकाऊ",
"Asia/Magadan": "एशिया/मगदान",
"Asia/Makassar": "एशिया/मकास्सर",
"Asia/Manila": "एशिया/मनीला",
"Asia/Muscat": "एशिया/मस्कट",
"Asia/Nicosia": "एशिया/निकोसिया",
"Asia/Novokuznetsk": "एशिया/नोवोज़नेट्सक",
"Asia/Novosibirsk": "एशिया/नोवोसिबिर्स्क",
"Asia/Omsk": "एशिया/ओम्स्क",
"Asia/Oral": "एशिया/ओरल",
"Asia/Phnom_Penh": "एशिया/नाम_पेन्ह",
"Asia/Pontianak": "एशिया/पोंटियानक",
"Asia/Pyongyang": "एशिया/प्योंगयांग",
"Asia/Qatar": "एशिया/कतर",
"Asia/Qostanay": "एशिया/क़ोस्ताने",
"Asia/Qyzylorda": "एशिया/क्यूज़लोर्डा",
"Asia/Rangoon": "एशिया/रंगून",
"Asia/Riyadh": "एशिया/रियाद",
"Asia/Saigon": "एशिया/साइगॉन",
"Asia/Sakhalin": "एशिया/सखालिन",
"Asia/Samarkand": "एशिया/समरकंद",
"Asia/Seoul": "एशिया/सियोल",
"Asia/Shanghai": "एशिया/शंघाई",
"Asia/Singapore": "एशिया/सिंगापुर",
"Asia/Srednekolymsk": "एशिया/श्रेडनेकोलिमस्क",
"Asia/Taipei": "एशिया/ताइपे",
"Asia/Tashkent": "एशिया/ताशकंद",
"Asia/Tbilisi": "एशिया/त्बिलिसी",
"Asia/Tehran": "एशिया/तेहरान",
"Asia/Tel_Aviv": "एशिया/तेल_अवीव",
"Asia/Thimbu": "एशिया/थिम्बू",
"Asia/Thimphu": "एशिया/थिम्फू",
"Asia/Tokyo": "एशिया/टोक्यो",
"Asia/Tomsk": "एशिया/टॉम्स्क",
"Asia/Ujung_Pandang": "एशिया/उजंग_पंडांग",
"Asia/Ulaanbaatar": "एशिया/उलानबातर",
"Asia/Ulan_Bator": "एशिया/उलान_बटोर",
"Asia/Urumqi": "एशिया/उरुमकी",
"Asia/Ust-Nera": "एशिया/उस्त-नेरा",
"Asia/Vientiane": "एशिया/वियनतियाने",
"Asia/Vladivostok": "एशिया/व्लादिवोस्तोक",
"Asia/Yakutsk": "एशिया/याकुत्स्क",
"Asia/Yangon": "एशिया/यांगून",
"Asia/Yekaterinburg": "एशिया/येकातेरिनबर्ग",
"Asia/Yerevan": "एशिया/येरेवन",
"Atlantic/Azores": "अटलांटिक/अज़ोरेस",
"Atlantic/Bermuda": "अटलांटिक/बरमूडा",
"Atlantic/Canary": "अटलांटिक/कैनरी",
"Atlantic/Cape_Verde": "अटलांटिक/केप_वर्डे",
"Atlantic/Faeroe": "अटलांटिक/फ़ेरो",
"Atlantic/Faroe": "अटलांटिक/फरो",
"Atlantic/Jan_Mayen": "अटलांटिक/जन_मायेन",
"Atlantic/Madeira": "अटलांटिक/मदीरा",
"Atlantic/Reykjavik": "अटलांटिक/रेकजाविक",
"Atlantic/South_Georgia": "अटलांटिक/साउथ_जॉर्जिया",
"Atlantic/St_Helena": "अटलांटिक/सेंट_हेलेना",
"Atlantic/Stanley": "अटलांटिक/स्टेनली",
"Australia/ACT": "ऑस्ट्रेलिया/एसीटी",
"Australia/Adelaide": "ऑस्ट्रेलिया/एडिलेड",
"Australia/Brisbane": "ऑस्ट्रेलिया/ब्रिसबेन",
"Australia/Broken_Hill": "ऑस्ट्रेलिया/ब्रोकन_हिल",
"Australia/Canberra": "ऑस्ट्रेलिया/कैनबरा",
"Australia/Currie": "ऑस्ट्रेलिया/करी",
"Australia/Darwin": "ऑस्ट्रेलिया/डार्विन",
"Australia/Eucla": "ऑस्ट्रेलिया/यूक्ला",
"Australia/Hobart": "ऑस्ट्रेलिया/होबार्ट",
"Australia/LHI": "ऑस्ट्रेलिया/एलएचआई",
"Australia/Lindeman": "ऑस्ट्रेलिया/लिंडमैन",
"Australia/Lord_Howe": "ऑस्ट्रेलिया/लॉर्ड_होवे",
"Australia/Melbourne": "ऑस्ट्रेलिया/मेलबोर्न",
"Australia/NSW": "ऑस्ट्रेलिया/एनएसडब्ल्यू",
"Australia/North": "ऑस्ट्रेलिया/नॉर्थ",
"Australia/Perth": "ऑस्ट्रेलिया/पर्थ",
"Australia/Queensland": "ऑस्ट्रेलिया/क्वींसलैंड",
"Australia/South": "ऑस्ट्रेलिया/दक्षिण",
"Australia/Sydney": "ऑस्ट्रेलिया/सिडनी",
"Australia/Tasmania": "ऑस्ट्रेलिया/तस्मानिया",
"Australia/Victoria": "ऑस्ट्रेलिया/विक्टोरिया",
"Australia/West": "ऑस्ट्रेलिया/वेस्ट",
"Australia/Yancowinna": "ऑस्ट्रेलिया/यांकोविना",
"Brazil/Acre": "ब्राज़िल/एकर",
"Brazil/DeNoronha": "ब्राज़िल/डेनोरोन्हा",
"Brazil/East": "ब्राज़िल/ईस्ट",
"Brazil/West": "ब्राज़िल/वेस्ट",
"CET": "सीईटी",
"CST6CDT": "सीएसटी6सीडीटी",
"Canada/Atlantic": "कनाडा/अटलांटिक",
"Canada/Central": "कनाडा/सेंट्रल",
"Canada/Eastern": "कनाडा/पूर्वी",
"Canada/Mountain": "कनाडा/माउंटेन",
"Canada/Newfoundland": "कनाडा/न्यूफाउंडलैंड",
"Canada/Pacific": "कनाडा/प्रशांत",
"Canada/Saskatchewan": "कनाडा/सास्काचेवान",
"Canada/Yukon": "कनाडा/युकोन",
"Chile/Continental": "चिली/कॉन्टिनेंटल",
"Chile/EasterIsland": "चिली/ईस्टर द्वीप",
"Cuba": "क्यूबा",
"EET": "पेट",
"EST": "ईएसटी",
"EST5EDT": "एस्ट5ईडीटी",
"Egypt": "इजिप्ट",
"Eire": "वायर",
"Etc/GMT": "ईटीसी/जीएमटी",
"Etc/GMT+0": "ईटीसी/जीएमटी+0",
"Etc/GMT+1": "ईटीसी/जीएमटी+1",
"Etc/GMT+10": "ईटीसी/जीएमटी+10",
"Etc/GMT+11": "ईटीसी/जीएमटी+11",
"Etc/GMT+12": "ईटीसी/जीएमटी+12",
"Etc/GMT+2": "ईटीसी/जीएमटी+2",
"Etc/GMT+3": "ईटीसी/जीएमटी+3",
"Etc/GMT+4": "ईटीसी/जीएमटी+4",
"Etc/GMT+5": "ईटीसी/जीएमटी+5",
"Etc/GMT+6": "ईटीसी/जीएमटी+6",
"Etc/GMT+7": "ईटीसी/जीएमटी+7",
"Etc/GMT+8": "ईटीसी/जीएमटी+8",
"Etc/GMT+9": "ईटीसी/जीएमटी+9",
"Etc/GMT-0": "ईटीसी/जीएमटी-0",
"Etc/GMT-1": "ईटीसी/जीएमटी-1",
"Etc/GMT-10": "ईटीसी/जीएमटी-10",
"Etc/GMT-11": "ईटीसी/जीएमटी-11",
"Etc/GMT-12": "ईटीसी/जीएमटी-12",
"Etc/GMT-13": "ईटीसी/जीएमटी-13",
"Etc/GMT-14": "ईटीसी/जीएमटी-14",
"Etc/GMT-2": "ईटीसी/जीएमटी-2",
"Etc/GMT-3": "ईटीसी/जीएमटी-3",
"Etc/GMT-4": "ईटीसी/जीएमटी-4",
"Etc/GMT-5": "ईटीसी/जीएमटी-5",
"Etc/GMT-6": "ईटीसी/जीएमटी-6",
"Etc/GMT-7": "ईटीसी/जीएमटी-7",
"Etc/GMT-8": "ईटीसी/जीएमटी-8",
"Etc/GMT-9": "ईटीसी/जीएमटी-9",
"Etc/GMT0": "ईटीसी/जीएमटी0",
"Etc/Greenwich": "आदि/ग्रीनविच",
"Etc/UCT": "ईटीसी/यूसीटी",
"Etc/UTC": "ईटीसी/यूटीसी",
"Etc/Universal": "आदि/यूनिवर्सल",
"Etc/Zulu": "आदि/ज़ुलु",
"Europe/Amsterdam": "यूरोप/एम्स्टर्डम",
"Europe/Andorra": "यूरोप/अंडोरा",
"Europe/Astrakhan": "यूरोप/आस्ट्राखान",
"Europe/Athens": "यूरोप/एथेंस",
"Europe/Belfast": "यूरोप/बेलफ़ास्ट",
"Europe/Belgrade": "यूरोप/बेलग्रेड",
"Europe/Berlin": "यूरोप/बर्लिन",
"Europe/Bratislava": "यूरोप/ब्रातिस्लावा",
"Europe/Brussels": "यूरोप/ब्रुसेल्स",
"Europe/Bucharest": "यूरोप/बुखारेस्ट",
"Europe/Budapest": "यूरोप/बुडापेस्ट",
"Europe/Busingen": "यूरोप/बुसिंगन",
"Europe/Chisinau": "यूरोप/चिशिनाउ",
"Europe/Copenhagen": "यूरोप/कोपनहेगन",
"Europe/Dublin": "यूरोप/डबलिन",
"Europe/Gibraltar": "यूरोप/जिब्राल्टर",
"Europe/Guernsey": "यूरोप/ग्वेर्नसे",
"Europe/Helsinki": "यूरोप/हेलसिंकी",
"Europe/Isle_of_Man": "यूरोप/आइल_ऑफ़_मैन",
"Europe/Istanbul": "यूरोप/इस्तांबुल",
"Europe/Jersey": "यूरोप/जर्सी",
"Europe/Kaliningrad": "यूरोप/कैलिनिनग्राद",
"Europe/Kiev": "यूरोप/कीव",
"Europe/Kirov": "यूरोप/किरोव",
"Europe/Kyiv": "यूरोप/कीव",
"Europe/Lisbon": "यूरोप/लिस्बन",
"Europe/Ljubljana": "यूरोप/जुब्लजाना",
"Europe/London": "यूरोप/लंदन",
"Europe/Luxembourg": "यूरोप/लक्ज़मबर्ग",
"Europe/Madrid": "यूरोप/मैड्रिड",
"Europe/Malta": "यूरोप/माल्टा",
"Europe/Mariehamn": "यूरोप/मैरीहैमन",
"Europe/Minsk": "यूरोप/मिन्स्क",
"Europe/Monaco": "यूरोप/मोनाको",
"Europe/Moscow": "यूरोप/मॉस्को",
"Europe/Nicosia": "यूरोप/निकोसिया",
"Europe/Oslo": "यूरोप/ओस्लो",
"Europe/Paris": "यूरोप/पेरिस",
"Europe/Podgorica": "यूरोप/पॉडगोरिका",
"Europe/Prague": "यूरोप/प्राग",
"Europe/Riga": "यूरोप/रीगा",
"Europe/Rome": "यूरोप/रोम",
"Europe/Samara": "यूरोप/समारा",
"Europe/San_Marino": "यूरोप/सैन_मैरिनो",
"Europe/Sarajevo": "यूरोप/साराजेवो",
"Europe/Saratov": "यूरोप/सेराटोव",
"Europe/Simferopol": "यूरोप/सिम्फ़रोपोल",
"Europe/Skopje": "यूरोप/स्कोपजे",
"Europe/Sofia": "यूरोप/सोफिया",
"Europe/Stockholm": "यूरोप/स्टॉकहोम",
"Europe/Tallinn": "यूरोप/तेलिन",
"Europe/Tirane": "यूरोप/तिराने",
"Europe/Tiraspol": "यूरोप/तिरस्पोल",
"Europe/Ulyanovsk": "यूरोप/उल्यानोवस्क",
"Europe/Uzhgorod": "यूरोप/उज़गोरोड",
"Europe/Vaduz": "यूरोप/वाडुज़",
"Europe/Vatican": "यूरोप/वेटिकन",
"Europe/Vienna": "यूरोप/वियना",
"Europe/Vilnius": "यूरोप/विलनियस",
"Europe/Volgograd": "यूरोप/वोल्गोग्राड",
"Europe/Warsaw": "यूरोप/वारसॉ",
"Europe/Zagreb": "यूरोप/ज़गरेब",
"Europe/Zaporozhye": "यूरोप/ज़ापोरोज़े",
"Europe/Zurich": "यूरोप/ज़्यूरिख़",
"GB": "जीबी",
"GB-Eire": "GB-Eire",
"GMT": "जीएमटी",
"GMT+0": "जीएमटी+0",
"GMT-0": "जीएमटी-0",
"GMT0": "जीएमटी0",
"Greenwich": "ग्रीनविच",
"HST": "होस्ट",
"Hongkong": "हॉगकॉग",
"Iceland": "आइसलैंड",
"Indian/Antananarivo": "इंडियन/एंटानानारिवो",
"Indian/Chagos": "इंडियन/चागोस",
"Indian/Christmas": "इंडियन/क्रिसमस",
"Indian/Cocos": "इंडियन/कोकोस",
"Indian/Comoro": "इंडियन/कोमोरो",
"Indian/Kerguelen": "इंडियन/केर्गुएलन",
"Indian/Mahe": "इंडियन/माहे",
"Indian/Maldives": "इंडियन/मालदीव",
"Indian/Mauritius": "इंडियन/मॉरीशस",
"Indian/Mayotte": "इंडियन/मैयट",
"Indian/Reunion": "इंडियन/रीयूनियन",
"Iran": "ईरान",
"Israel": "इज़राइल",
"Jamaica": "जमैका",
"Japan": "जापान",
"Kwajalein": "क्वाजालीन",
"Libya": "लीबिया",
"MET": "मेट",
"MST": "एमएसटी",
"MST7MDT": "एमएसटी7एमडीटी",
"Mexico/BajaNorte": "मेक्सिको/बजानोर्टे",
"Mexico/BajaSur": "मेक्सिको/बजासूर",
"Mexico/General": "मेक्सिको/जनरल",
"NZ": "एनजेड",
"NZ-CHAT": "एनजेड-चैट",
"Navajo": "नवाजो",
"PRC": "पीआरसी",
"PST8PDT": "पीएसटी8पीडीटी",
"Pacific/Apia": "प्रशांत/एपिया",
"Pacific/Auckland": "प्रशांत/ऑकलैंड",
"Pacific/Bougainville": "प्रशांत/बोगेनविल",
"Pacific/Chatham": "प्रशांत/चैथम",
"Pacific/Chuuk": "प्रशांत/चुउक",
"Pacific/Easter": "प्रशांत/ईस्टर",
"Pacific/Efate": "प्रशांत/इफ़ेट",
"Pacific/Enderbury": "पैसिफिक/एंडरबरी",
"Pacific/Fakaofo": "प्रशांत/फकाओफ़ो",
"Pacific/Fiji": "प्रशांत/फ़िजी",
"Pacific/Funafuti": "प्रशांत/फ़नाफ़ुटि",
"Pacific/Galapagos": "प्रशांत/गैलापागोस",
"Pacific/Gambier": "प्रशांत/गैंबियर",
"Pacific/Guadalcanal": "प्रशांत/ग्वाडलकैनाल",
"Pacific/Guam": "प्रशांत/गुआम",
"Pacific/Honolulu": "प्रशांत/होनोलूलू",
"Pacific/Johnston": "प्रशांत/जॉन्सटन",
"Pacific/Kanton": "प्रशांत/कैंटन",
"Pacific/Kiritimati": "प्रशांत/किरीटीमती",
"Pacific/Kosrae": "प्रशांत/कोसरे",
"Pacific/Kwajalein": "प्रशांत/क्वाजालीन",
"Pacific/Majuro": "प्रशांत/माजुरो",
"Pacific/Marquesas": "प्रशांत/मार्केसस",
"Pacific/Midway": "प्रशांत/मिडवे",
"Pacific/Nauru": "प्रशांत/नाउरू",
"Pacific/Niue": "प्रशांत/नियू",
"Pacific/Norfolk": "प्रशांत/नॉरफ़ॉक",
"Pacific/Noumea": "प्रशांत/नौमिया",
"Pacific/Pago_Pago": "प्रशांत/पागो_पागो",
"Pacific/Palau": "प्रशांत/पलाऊ",
"Pacific/Pitcairn": "प्रशांत/पिटकेर्न",
"Pacific/Pohnpei": "प्रशांत/पोनपेई",
"Pacific/Ponape": "प्रशांत/पोनपे",
"Pacific/Port_Moresby": "प्रशांत/पोर्ट_मोरेस्बी",
"Pacific/Rarotonga": "प्रशांत/रारोटोंगा",
"Pacific/Saipan": "प्रशांत/सायपन",
"Pacific/Samoa": "प्रशांत/समोआ",
"Pacific/Tahiti": "प्रशांत/ताहिती",
"Pacific/Tarawa": "प्रशांत/तरावा",
"Pacific/Tongatapu": "प्रशांत/टोंगाटापू",
"Pacific/Truk": "प्रशांत/ट्रक",
"Pacific/Wake": "पैसिफिक/वेक",
"Pacific/Wallis": "प्रशांत/वालिस",
"Pacific/Yap": "प्रशांत/याप",
"Poland": "पोलैंड",
"Portugal": "पुर्तगाल",
"ROC": "रॉक",
"ROK": "रॉक",
"Singapore": "सिंगापुर",
"Turkey": "तुर्की",
"UCT": "कट",
"US/Alaska": "यूएस/अलास्का",
"US/Aleutian": "यूएस/अलेउतियन",
"US/Arizona": "यूएस/एरिज़ोना",
"US/Central": "यूएस/सेंट्रल",
"US/East-Indiana": "यूएस/ईस्ट-इंडियाना",
"US/Eastern": "यूएस/ईस्टर्न",
"US/Hawaii": "यूएस/हवाई",
"US/Indiana-Starke": "यूएस/इंडियाना-स्टार्क",
"US/Michigan": "यूएस/मिशिगन",
"US/Mountain": "यूएस/माउंटेन",
"US/Pacific": "यूएस/पैसिफिक",
"US/Samoa": "यूएस/समोआ",
"UTC": "यूटीसी",
"Universal": "यूनिवर्सल",
"W-SU": "डब्ल्यू-सु",
"WET": "गीला",
"Zulu": "ज़ुलु",
}