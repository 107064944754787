import React, { Component } from 'react'
import { Dimensions, Image, Text, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import SyncImage from '../../assets/SyncImage.png'
import styles from '../components/styles/TimelineStyles'
import { retrieveSubjectDeviceToken } from '../utils/secureStorageUtils'
import FormsToSync from './FormsToSync'
import _ from 'lodash'
import Sync, { SyncFinished } from './Sync'
import Training from './training'
import TrainingLabel from './trainingLabel'
import { TimeLineComponent } from '../utils/timelineUtils'
import DatePickerLayout from './DatePickerLayout'
import PropTypes from 'prop-types'
class Diary extends Component {
  state = {
    syncing: false,
    NoOfFormsToSync: 0,
    isSyncCompleted: '',
    showSyncingCompleted: false,
    deviceTokenData: null,
  }

  stopSyncing = () => {
    const { storeSyncStatus } = this.props
    this.setState({ syncing: false })
    storeSyncStatus(true)
  }

  componentDidUpdate(prevProps) {
    const { refreshDiary, syncCompleted } =
      this.props
    if (syncCompleted == true && syncCompleted != prevProps.syncCompleted) {
      refreshDiary()
    }

    if (prevProps.syncCompleted !== syncCompleted && syncCompleted === true) {
      this.displaySyncCompleted()
    }
  }

  displaySyncCompleted = () => {
    this.setState({
      showSyncingCompleted: true,
    })
    setTimeout(() => {
      this.closeSyncCompleted()
    }, 2500)
  }

  closeSyncCompleted = () => {
    this.setState({
      showSyncingCompleted: false,
    })
  }

  async componentDidMount() {
    const { storeSyncStatus, OfflineFormsToSync, refreshDiary, isDeviceOnline } =
      this.props
    if (OfflineFormsToSync > 0 && !isDeviceOnline) {
      storeSyncStatus(true)
    } else {
      storeSyncStatus(false)
    }

    refreshDiary()

    const deviceTokenData = await retrieveSubjectDeviceToken()
    this.setState({
      deviceTokenData,
    })
  }

  onChangeLanguage = () => {
    const {
      screenProps: { t },
    } = this.props
    this.props.navigation.setParams({ title: t('HomePatnt_Diary') })
  }

  changeDate = (selectedDate) => {
    const { changeScheduleDate } = this.props
    changeScheduleDate(selectedDate)
  }

  renderTimeline = () => {
    const {
      navigation,
      screenProps,
      isDeviceOnline,
      selectVisitForm,
      timeZone,
      subjectId,
      updateSelectedCall,
      svfs,
      callData,
      callLoading,
    } = this.props
    let { loading, refreshDiary, isTraining, isTrainingCompleted } = this.props
    loading = !isDeviceOnline ? isDeviceOnline : loading
    const { t } = screenProps
    const { deviceTokenData } = this.state
    if (svfs.length === 0) {
      return (
        <View
          style={{
            flex: 1,
            height: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          testID={'diary-NoFormToFill-view'}
          accessible={true}
        >
          <Text style={{ color: '#546e7a', fontFamily: 'Inter', fontSize: 16 }}>
            {isDeviceOnline == true ? t('NoFormToFill') : t('NoForm')}
          </Text>
        </View>
      )
    } else if (svfs.length > 0) {
      return (
        <TimeLineComponent
          screenProps={screenProps}
          navigation={navigation}
          svfs={svfs}
          selectVisitForm={selectVisitForm}
          isDeviceOnline={isDeviceOnline}
          timeZone={timeZone}
          deviceTokenData={deviceTokenData}
          loading={loading}
          refresh={refreshDiary}
          subjectId={subjectId}
          updateSelectedCall={updateSelectedCall}
          isTraining={isTraining}
          isTrainingCompleted={isTrainingCompleted}
          callLoading={callLoading}
          callData={callData}
        />
      )
    }
    return <View />
  }

  renderDatePicker = () => {
    const {
      screenProps: { t },
      scheduleDate,
      timeZone,
    } = this.props

    return (
      <DatePickerLayout
        onDateChange={(date) => this.changeDate(date)}
        initialDiaryDate={scheduleDate}
        t={t}
        subjectTimezone={timeZone}
        testID={`diarydatepicker-select-date-view`}
      />
    )
  }

  render() {
    const {
      syncCompleted,
      isSyncing,
      navigation,
      refreshDiary,
      screenProps: { t },
      screenProps,
      isDeviceOnline,
      OfflineFormsToSync,
      isTrainingCompleted,
      isTraining,
    } = this.props
    let { loading, callLoading } = this.props
    loading = !isDeviceOnline ? isDeviceOnline : loading
    const { NoOfFormsToSync, showSyncingCompleted } = this.state
    var { height, width } = Dimensions.get('window')

    return (
      <View style={{ flex: 1 }}>
        <NavigationEvents
          onDidFocus={() => {
            const isFormSubmitted = navigation.getParam('isFormSubmitted')
            if (isFormSubmitted && !isSyncing) {
              refreshDiary()
            }
          }}
        />

        <View style={styles.container}>
          <Image source={SyncImage} style={{ width: 0, height: 0 }} />
          {isTraining ? (
            <TrainingLabel />
          ) : isDeviceOnline == true && isSyncing == false ? (
            this.renderDatePicker()
          ) : (
            <FormsToSync
              t={t}
              SyncCompleted={syncCompleted}
              OfflineFormsToSync={OfflineFormsToSync}
              stopSyncing={this.stopSyncing}
              loading={loading}
              NoOfFormsToSync={NoOfFormsToSync}
              isConnected={isDeviceOnline}
              syncing={isSyncing}
            />
          )}
          {!isTrainingCompleted && !isTraining && (
            <Training screenProps={screenProps} showTrainingNotCompleted={true} />
          )}
          {this.renderTimeline()}
          {callLoading && (
            <React.Fragment>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 16,
                  fontFamily: 'Inter',
                  justifyContent: 'center',
                }}
              >
                {t('LoadingCallData')}...
              </Text>
            </React.Fragment>
          )}
          {isDeviceOnline == true && isSyncing == true && (
            <View
              style={{
                height: height,
                backgroundColor: 'transparent',
              }}
            >
              {/* <BlurView tint="light" intensity={60} style={{position:'absolute', justifyContent:'center',height:'100%',width:'105%'}}> */}
              <Sync
                SyncCompleted={syncCompleted}
                OfflineFormsToSync={OfflineFormsToSync}
                stopSyncing={this.stopSyncing}
                loading={loading}
                NoOfFormsToSync={NoOfFormsToSync}
                isConnected={isDeviceOnline}
                syncing={true}
                showSyncingCompleted={showSyncingCompleted}
                screenProps={screenProps}
              />
            </View>
          )}
          {<SyncFinished showSyncingCompleted={showSyncingCompleted} screenProps={screenProps} />}
        </View>
      </View>
    )
  }
}

Diary.defaultProps = {
  isSyncing: false,
  syncCompleted: false,
  isDeviceOnline: false,
  isTraining: false,
  isTrainingCompleted: false,
  callLoading: false,
  loading: false,
  OfflineFormsToSync: 0,
  svfs: [],
  callData: [],
  timeZone: '',
}
Diary.propTypes = {
  storeSyncStatus: PropTypes.func.isRequired,
  refreshDiary: PropTypes.func.isRequired,
  syncCompleted: PropTypes.bool,
  OfflineFormsToSync: PropTypes.number,
  isDeviceOnline: PropTypes.bool,
  screenProps: PropTypes.object.isRequired,
  changeScheduleDate: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  selectVisitForm: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
  subjectId: PropTypes.string.isRequired,
  updateSelectedCall: PropTypes.func.isRequired,
  svfs: PropTypes.array,
  callData: PropTypes.array,
  callLoading: PropTypes.bool,
  isSyncing: PropTypes.bool,
  isTraining: PropTypes.bool,
  isTrainingCompleted: PropTypes.bool,
  loading: PropTypes.bool,
}
export default Diary
