import React, { Component } from 'react'
import { Container, ListItem, Item, Input, Body, Icon } from 'native-base'
import PropTypes, { object } from 'prop-types'
import { View, ScrollView, FlatList, Dimensions, ActivityIndicator, Text } from 'react-native'
import styles from '../../styles/listStyles'
import SearchInput, { createFilter } from 'react-native-search-filter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateSubjectSearchTerm } from '../../actions/subject'
import { getFilteredSubjects, getSearchTerm } from '../../selectors/subject'
import appConstants from '../../constants/constants'
import moment from 'moment'

class SubjectList extends Component {
  constructor(props) {
    super(props)
  }

  searchUpdated(term) {
    const { actions } = this.props
    actions.updateSubjectSearchTerm(term)
  }

  render() {
    const { loading, list, onSubjectSelect, subjects, searchTermSubject } = this.props
    // console.log(this.props)

    return (
      <Container style={styles.containerStyle}>
        {/* ------------- search bar ------------------------ */}
        <View style={{ margin: 10 }}>
          <Item searchBar rounded>
            <Icon name="ios-search" style={{ padding: 2 }} />
            <Input
              placeholder="Search"
              style={styles.textStyle}
              autoCorrect={false}
              value={searchTermSubject}
              onChangeText={(term) => {
                this.searchUpdated(term)
              }}
            />
          </Item>
        </View>

        {/* ------------- Loading ------------------------ */}
        {loading ? (
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} />
        ) : subjects.length > 0 ? (
          /* ------------- Render list ------------------------ */
          <FlatList
            data={subjects}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <ListItem
                thumbnail
                onPress={() => {
                  onSubjectSelect(item)
                }}
              >
                <Body>
                  <View style={{ justifyContent: 'flex-start' }}>
                    <Text style={styles.textStyle}>{item.subjectNo}</Text>
                    <Text style={styles.subTextStyle}>
                      Status: {appConstants.subjectStatus[item.status]}
                    </Text>
                    <Text style={styles.subTextStyle}>
                      Diary Start Date:{' '}
                      {item.diaryStartDate ? moment(item.diaryStartDate).format('DD-MMM-YYYY') : ''}
                    </Text>
                  </View>
                </Body>
              </ListItem>
            )}
          />
        ) : (
          /* ------------- Render No data ------------------------ */
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={[styles.textStyle, { textAlign: 'center' }]}>No data.</Text>
          </View>
        )}
      </Container>
    )
  }
}

SubjectList.defaultProps = {
  list: [],
  onSubjectSelect: () => null,
  loading: false,
  searchTermSubject: '',
}
SubjectList.propTypes = {
  list: PropTypes.arrayOf(Object),
  onSubjectSelect: PropTypes.func,
  loading: PropTypes.bool,
  searchTermSubject: PropTypes.arrayOf(object),
}

const mapStateToProps = (state) => ({
  subjects: getFilteredSubjects(state),
  searchTermSubject: getSearchTerm(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateSubjectSearchTerm,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(SubjectList)
// export default SubjectList
