import React, { Component } from 'react'
import { Text, View, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { backgroundColor } from './NavigationScreens'
import { setCurrentScreen } from '../actions/storeAppStatus'
import { updateDeviceToken } from '../actions/login'
import { changeLanguageSuccess } from '../actions/changeLanguage'
import { storePin } from '../actions/users'
// import AcsLogo from './AcsLogo';
// import { putItem } from '../utils/secureStorageUtils';
import PIN from '../components/securePIN/PIN'

class PinSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appPin: null,
      loading: false,
    }
  }

  // static navigationOptions = ({ navigation, screenProps: { t } }) => ({
  //   title: t('AppLock'),
  // })

  componentDidMount() {
    setCurrentScreen('PIN')
  }

  changeLoading = () => {
    const { loading } = this.state
    this.setState({
      loading: !loading,
    })
  }

  render() {
    const {
      navigation,
      screenProps,
      isSubjectRegistered,
      updateDeviceToken: updateSubjectDeviceToken,
      changeLanguageSuccess: changeSubjectLanguageSuccess,
      subject,
      clientID,
      storePin,
      loggedInUserId,
      appType
    } = this.props
    const { loading } = this.state
    const changePin = navigation.getParam('changePin') || false
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator size="large" color="#d7dadb" overlayColor="#d7dadb" />
          </View>
        )}
        <PIN
          navigation={navigation}
          changeLoading={this.changeLoading}
          mode="choose"
          changePin={changePin}
          screenProps={screenProps}
          isSubjectRegistered={isSubjectRegistered}
          updateDeviceToken={updateSubjectDeviceToken}
          changeLanguageSuccess={changeSubjectLanguageSuccess}
          subject={subject}
          clientID={clientID}
          storePin={storePin}
          loggedInUserId = {loggedInUserId}
          appType = {appType}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  currentScreen: state.appStatus.currentScreen,
  isSubjectRegistered: state.subjectStudyMetaData.isSubjectRegistered,
  subject: state.subjectStudyMetaData.subject,
  clientID: state.subjectStudyMetaData.studySite.client.id,
  loggedInUserId: state.users.currentUser?.phoneNo,
  appType: state.appStatus.appType
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
      updateDeviceToken,
      changeLanguageSuccess,
      storePin,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PinSetup)
