import _ from 'lodash'
import { Container, Content, Input, List, ListItem } from 'native-base'
import React, { Component } from 'react'
import { ActivityIndicator, Dimensions, Text, View } from 'react-native'
import { backgroundColor } from '../containers/NavigationScreens'
import styles from './sideBar/styles'
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

//
class ChangeTimeZone extends Component {
  state = {
    input: '',
  }

  checkDataPresent = () => {
    const { timeZones } = this.props
    const { input } = this.state
    if (timeZones != null) {
      var checkData = timeZones.map((data) => {
        if (_.includes(data.timeZoneFullName, input)) {
          return true
        } else {
          return false
        }
      })

      if (!checkData.includes(true)) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  render() {
    const {
      navigation,
      loading,
      setTimeZone,
      changeLanguage,
      timeZones,
      selectedLang,
      screenProps,
      screenProps: { t },
    } = this.props
    const { setLocale } = screenProps
    const fromLogin = navigation.getParam('fromLogin')
    const { input } = this.state
    return (
      <Container style={{ flex: 1, marginTop: fromLogin ? 0 : 0, flexDirection: 'column' }}>
        {/* <View >
        <Input onChangeText={(value)=>{
 this.setState({
   input: value
 })         
         }} autoFocus style={{marginleft:10,borderWidth:1,borderRadius:2,borderColor:'grey',padding:20}} />
          </View> */}
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          <Input
            onChangeText={(value) => {
              this.setState({
                input: value,
              })
            }}
            autoFocus
            style={{
              marginleft: 10,
              borderWidth: 1,
              borderRadius: 2,
              borderColor: 'grey',
              padding: 10,
              fontFamily: 'Inter',
            }}
          />
          {!this.checkDataPresent() && loading && (
            <View style={{ height: SCREEN_HEIGHT / 2, justifyContent: 'center' }}>
              <ActivityIndicator
                size="large"
                color={backgroundColor}
                overlayColor="rgba(0, 0, 0, 0.07)"
              />
            </View>
          )}
          {!this.checkDataPresent() && !loading && (
            <View
              style={{ justifyContent: 'center', alignItems: 'center', height: SCREEN_HEIGHT / 2 }}
            >
              <Text style={{ textAlign: 'center', alignSelf: 'center' }}>No Data Present</Text>
            </View>
          )}
          {!loading && (
            <List style={{ marginTop: 20 }}>
              {timeZones != null &&
                timeZones.map(
                  (data) =>
                    _.includes(_.toUpper(data.timeZoneFullName), _.toUpper(input)) && (
                      <ListItem
                        selected={selectedLang === data.value ? true : false}
                        style={{ height: 60 }}
                        onPress={() => {
                          setTimeout(() => {
                            //   changeLanguage(data.value, setLocale)
                            setTimeZone(data.timeZoneName, data.timeZoneFullName)
                            navigation.goBack()
                          }, 0)
                        }}
                      >
                        <Text style={styles.text}>{data.timeZoneFullName}</Text>
                      </ListItem>
                    )
                )}
            </List>
          )}
        </Content>
      </Container>
    )
  }
}

export default ChangeTimeZone
