import React, { Component } from 'react'
import { connect } from 'react-redux'
import CallDetails from '../../components/scheduledCalls/callDetails'

class CallDetailsPage extends Component {
  state = {}

  render() {
    const { selectedCall } = this.props
    return <CallDetails {...this.props} call={selectedCall} />
  }
}

const mapStateToProps = (state) => ({
  selectedCall: state.call.selectedCall,
  timeZone: state.subjectStudyMetaData.subject.timeZone,
})

export default connect(mapStateToProps)(CallDetailsPage)
