import _ from 'lodash'
import moment from 'moment'
import momentTz from 'moment-timezone'
import EventRepetition from '../../constants/constants'
import {
  scheduleNotification,
  setNotificationChannel,
} from '../pushNotification/localNotifications'

export const scheduleTestNotifications = () => {
  setNotificationChannel()
  console.log('notifying')
  const notification = {
    content: {
      title: 'Notification',
      body: 'Notification Body',
    },
    trigger: {
      seconds: 5,
      repeats: true,
    },
  }
  scheduleNotification(notification)
}

export const scheduleNotifications = (subjectMetaData) => {
  const visits = _.get(subjectMetaData.study, 'visits')
  setNotificationChannel()
  _.forEach(visits, (v) => {
    _.forEach(v.visitForms, (vf) => {
      if (_.isEqual(_.toUpper(vf.eventRepetition), _.toUpper(EventRepetition.DAILY))) {
        scheduleDailyNotifications(vf, subjectMetaData.subject)
      } else if (_.isEqual(_.toUpper(vf.eventRepetition), _.toUpper(EventRepetition.WEEKLY))) {
        scheduleWeeklyNotifications(vf, subjectMetaData.subject)
      } else if (_.isEqual(_.toUpper(vf.eventRepetition), _.toUpper(EventRepetition.MONTHLY))) {
        scheduleMonthlyNotifications(vf, subjectMetaData.subject)
      }
    })
  })
}

const scheduleDailyNotifications = (visitForm, subject) => {
  _.forEach(visitForm.visitFormEvents, (vfe) => {
    const utcStartDate = momentTz.utc(subject.diaryStartDate).format('YYYY-MM-DD')
    const startDateInSubTimezone = momentTz
      .utc(utcStartDate)
      .tz(subject.timeZone)
      .format('YYYY-MM-DD')
    const endDateInSubTimezone = momentTz
      .utc(utcStartDate)
      .tz(subject.timeZone)
      .add(visitForm.duration, visitForm.durationUnit)
      .format('YYYY-MM-DD')
    const startDate = moment(startDateInSubTimezone)
    const endDate = moment(endDateInSubTimezone)

    const currentUtcDate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    const dateInSubTimezone = momentTz.utc(currentUtcDate).tz(subject.timeZone).format('YYYY-MM-DD')
    const nextDate = moment(dateInSubTimezone)
    const notificationInput = {
      content: {
        title: `Event Reminder: ${visitForm.form.formName} ${vfe.eventTime}`,
        body: 'Please complete the above Event by the Scheduled Time. Pleae ignore if completed.',
      },
    }
    while (nextDate.isSameOrAfter(startDate) && nextDate.isSameOrBefore(endDate)) {
      const loDate = nextDate
      if (_.isEqual(_.toUpper(vfe.eventTime), _.toUpper(EventRepetition.AllDay))) {
        dailyNotification(loDate, subject, notificationInput)
      } else {
        const reqTime = moment(vfe.eventTime, 'HH:mm A')
        loDate.add(reqTime.hours(), 'hours')
        loDate.add(reqTime.minutes(), 'minutes')
        if (visitForm.eventNotificationTime) {
          loDate.subtract(Number(visitForm.eventNotificationTime), 'minute')
        }
        const dateStr = loDate.format('YYYY-MM-DD HH:mm:ss')
        const dateinSubTimezone = momentTz.utc().tz(subject.timeZone).format('YYYY-MM-DD HH:mm:ss')
        const notification = {
          ...notificationInput,
          trigger: {
            seconds: moment(dateStr).diff(moment(dateinSubTimezone), 'seconds'),
            repeats: false,
          },
        }
        scheduleNotification(notification)
      }

      nextDate.add(1, 'days')
    }
  })
}

const scheduleWeeklyNotifications = (visitForm, subject) => {
  _.forEach(visitForm.visitFormEvents, (vfe) => {
    if (_.isEqual(_.toUpper(vfe.eventTime), _.toUpper(EventRepetition.AllDay))) {
      const notificationInput = {
        content: {
          title: `Event Reminder: ${visitForm.form.formName} ${vfe.eventTime}`,
          body: 'Please complete the above Event by the Scheduled Time. Pleae ignore if completed.',
        },
      }
      const eventDayNumber = Number(EventRepetition.WEEK_DAYS[vfe.eventDay].order)
      const utcStartDate = momentTz.utc(subject.diaryStartDate).format('YYYY-MM-DD')
      const startDateInSubTimezone = momentTz
        .utc(utcStartDate)
        .tz(subject.timeZone)
        .format('YYYY-MM-DD')
      const endDateInSubTimezone = momentTz
        .utc(utcStartDate)
        .tz(subject.timeZone)
        .add(visitForm.duration, visitForm.durationUnit)
        .format('YYYY-MM-DD')
      const startDate = moment(startDateInSubTimezone)
      const endDate = moment(endDateInSubTimezone)
      let nextDateStr = moment.utc().format('YYYY-MM-DD')
      const currentDay = moment.utc().weekday()
      if (currentDay < eventDayNumber) {
        nextDateStr = moment
          .utc(nextDateStr)
          .add(eventDayNumber - currentDay, 'days')
          .format('YYYY-MM-DD')
      } else if (eventDayNumber < currentDay) {
        nextDateStr = moment
          .utc(nextDateStr)
          .add(7 - (currentDay - eventDayNumber), 'days')
          .format('YYYY-MM-DD')
      }

      const dateInSubTimezone = momentTz.utc(nextDateStr).tz(subject.timeZone).format('YYYY-MM-DD')
      const nextDate = moment(dateInSubTimezone)
      while (nextDate.isSameOrAfter(startDate) && nextDate.isSameOrBefore(endDate)) {
        const loDate = nextDate
        dailyNotification(loDate, subject, notificationInput)
        nextDate.add(7, 'days')
      }
    }
  })
}

const scheduleMonthlyNotifications = (visitForm, subject) => {
  _.forEach(visitForm.visitFormEvents, (vfe) => {
    if (_.isEqual(_.toUpper(vfe.eventTime), _.toUpper(EventRepetition.AllDay))) {
      const notificationInput = {
        content: {
          title: `Event Reminder: ${visitForm.form.formName} ${vfe.eventTime}`,
          body: 'Please complete the above Event by the Scheduled Time. Pleae ignore if completed.',
        },
      }
      const eventDayNumber = vfe.eventDay
      const utcStartDate = momentTz.utc(subject.diaryStartDate).format('YYYY-MM-DD')
      const startDateInSubTimezone = momentTz
        .utc(utcStartDate)
        .tz(subject.timeZone)
        .format('YYYY-MM-DD')
      const endDateInSubTimezone = momentTz
        .utc(utcStartDate)
        .tz(subject.timeZone)
        .add(visitForm.duration, visitForm.durationUnit)
        .format('YYYY-MM-DD')
      const startDate = moment(startDateInSubTimezone)
      const endDate = moment(endDateInSubTimezone)
      const utcDate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      const dateInSubTimezone = momentTz
        .utc(utcDate)
        .tz(subject.timeZone)
        .format('YYYY-MM-DD HH:mm:ss')
      const nextDateStr = moment(dateInSubTimezone)
        .date(1)
        .add(moment(dateInSubTimezone).date() > eventDayNumber ? 1 : 0, 'months')
        .add(eventDayNumber - 1, 'days')
        .format('YYYY-MM-DD')
      const nextDate = moment(nextDateStr)
      while (nextDate.isSameOrAfter(startDate) && nextDate.isSameOrBefore(endDate)) {
        const loDate = nextDate
        dailyNotification(loDate, subject, notificationInput)
        nextDate.add(1, 'months')
      }
    }
  })
}

const dailyNotification = (loDate, subject, notificationInput) => {
  const date1 = loDate
  date1.add(17, 'hours')
  date1.add(0, 'minutes')
  const date1Str = date1.format('YYYY-MM-DD HH:mm:ss')
  const dateinSubTimezone = momentTz.utc().tz(subject.timeZone).format('YYYY-MM-DD HH:mm:ss')
  const notification = {
    ...notificationInput,
    trigger: {
      seconds: moment(date1Str).diff(moment(dateinSubTimezone), 'seconds'),
      repeats: false,
    },
  }
  scheduleNotification(notification)
  const date2 = loDate
  date2.hours(20)
  date2.minutes(0)
  const date2Str = date2.format('YYYY-MM-DD HH:mm:ss')
  const dateinSubTimezone2 = momentTz.utc().tz(subject.timeZone).format('YYYY-MM-DD HH:mm:ss')
  const notification2 = {
    ...notificationInput,
    trigger: {
      seconds: moment(date2Str).diff(moment(dateinSubTimezone2), 'seconds'),
      repeats: false,
    },
  }
  scheduleNotification(notification2)
}
