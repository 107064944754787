import { downloadClickableImage, getImagesAvailableForDownload, updateDownloadedUrl } from "../../actions/metaData"
import { updateSpaceAvailabilityInStore } from "../../actions/storeAppStatus"
import { getDeviceStatus } from "../../actions/users"
import constants from "../../constants/constants"
import { blobToBase64 } from "../util"


const downloadImage = async (state,  dispatch) => {
    const tobeDownloadedImages = await dispatch(getImagesAvailableForDownload())
    const tobeDownloadedPreviewImages = await dispatch(getImagesAvailableForDownload(true))
    await processImages(state,tobeDownloadedPreviewImages, dispatch, true)
    await processImages(state,tobeDownloadedImages, dispatch, false)
    dispatch(stopJobWhenNoImagesToBeDownloaded())
}

const processImages = async (state,images, dispatch, isPreview) => {
  const downloadKeys = Object.keys(images)
  const timerId = state?.appStatus?.timers?.downloadImageTimerId;
  for(var i=0; i <downloadKeys.length; i++ ){
    await downloadAndStoreImages(images[downloadKeys[i]]?.fieldUrl, downloadKeys[i], timerId, dispatch, isPreview)
  }
}


const downloadAndStoreImages = async (imageUrl, key, timerId, dispatch, isPreview) => {
    try {
      const blob = await downloadAndReturnImageUrl(imageUrl, false)
      const blobUrl = isPreview ? await blobToBase64(blob) :URL.createObjectURL(blob);
      await dispatch(updateDownloadedUrl(blobUrl, key, isPreview ? constants.AppType.PREVIEW : null))
    } catch (e) {
      console.log(e)
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
          // Notify the user that storage is full
          console.error('Storage quota exceeded. Cannot save state.');
          dispatch(updateSpaceAvailabilityInStore(false));
          stopBackGroundJob(timerId);
      } 
  }
}


export const startBackGroundJob = () =>  async (dispatch, getState) =>  {
    if(getDeviceStatus()){
      downloadImage(getState(), dispatch);
    }
}

export const stopBackGroundJob = async (downloadImageTimerId) => {
  clearInterval(downloadImageTimerId);
}

export const readImage = async (imageUrl) => {
  return imageUrl;
}

export const clearAndStopBackGroundJob =  (downloadImageTimerId) => async(dispatch, getState) =>{
  stopBackGroundJob(downloadImageTimerId)
}

export const stopJobWhenNoImagesToBeDownloaded =  () => async(dispatch, getState) =>{
    const images = await dispatch(getImagesAvailableForDownload())
    if(_.isEmpty(images)){
      await stopBackGroundJob(getState()?.appStatus?.timers?.downloadImageTimerId)
    }
}

//Downloading image and returning blob if it is called from scheduler to handle preview and regular flow
//Returning url to handle the not enough storage available scenario
export const downloadAndReturnImageUrl = async(imageUrl, isStorageIssue = false) => {
  const fileName = imageUrl.split('/').pop();
  try{
    const response = await downloadClickableImage(fileName)
    const blob = new Blob([response.data], { type: 'image/png' }); 
    return  isStorageIssue ? URL.createObjectURL(blob) : blob;
  }catch(e){
    console.log(e)
  }
}