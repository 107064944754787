import React from 'react'
import { View } from 'react-native'
import Profile from '../clinroHeader/components/profile'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../../../actions/users'
import { getCurrentUser, getFullName, getRoleDetails } from '../../../selectors/user'
import { backgroundColor } from '../../../containers/NavigationScreens'
import constants from '../../../constants/constants'
import { getActiveStudy } from '../../../selectors/study'
import { getActiveSubject } from '../../../selectors/subject'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const ProfileHeader = (props) => {
  const {
    navigation,
    screenProps,
    logout: signoutUser,
    user,
    fullName,
    roleDetails,
    study,
    subject,
    site,
    versionInfo,
  } = props
  const userId = user?.currentUser?.id
  
  const { appStatus } = useSelector((state) => state)

  const logoutAction = () => {
    try {
      signoutUser()
      navigation.navigate('Login', { appType: constants.AppType.SITESTAFF })
    } catch (error) {
      console.log(error)
    }
  }

  const getUserName = () => {
    const userFullName = fullName
    const userNameArr = userFullName.split(' ')
    return userNameArr[0].charAt(0) + '' + userNameArr[1].charAt(0)
  }

  if(_.isEqual(appStatus.appType, constants.AppType.SITESTAFF)){
  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        height: null,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: 5,
        }}
      >
        <Profile
          name={getUserName()}
          fullName={fullName}
          role={roleDetails}
          organizationName={user[userId]?.userData.data.keycloakRealm}
          title={user[userId].userData.data.jobTitle || 'Title'}
          logoutAction={() => logoutAction()}
          screenProps={screenProps}
          study={study}
          subject={subject}
          site={site}
          versionInfo={versionInfo}
        />
      </View>
    </View>
  )
 }

 return(<View></View>)
}


const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  fullName: getFullName(state),
  site: state.site,
  appType: state.appStatus.appType,
  user: state.users,
  roleDetails: getRoleDetails(state),
  site: state.site,
  study: getActiveStudy(state),
  subject: getActiveSubject(state),
  versionInfo: state.subjectStudyMetaData?.study?.crfVersion,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader)
