import { Platform } from 'react-native'
import createActionType from '../utils/action'
import api from '../utils/api'
import { storeData, retrieveLoginOfflineSuccess } from './login'
import showToast from '../utils/toast'
import appConstants from '../constants/appConstants'
import moment from 'moment-timezone'
import constants from '../constants/constants'
import { getMetaData } from './metaData'
import { retrieveFormsIfNotExist } from './form'

export const SELECT_TIMEZONE = 'SELECT_TIMEZONE'
export const RETRIEVE_TIMEZONES = createActionType('RETRIEVE_TIMEZONES')
export const UPDATE_TIMEZONE_SUCCESS = 'UPDATE_TIMEZONE_SUCCESS'
export const UPDATE_TIMEZONE_REQUEST = 'UPDATE_TIMEZONE_REQUEST'
export const UPDATE_TIMEZONE_FAILURE = 'UPDATE_TIMEZONE_FAILURE'

const selectTimeZone = (timeZone) => ({
  type: SELECT_TIMEZONE,
  value: timeZone,
})

export const timeZoneSelected = () => (dispatch) => {
  dispatch(selectTimeZone())
}

const retrieveTimeZones = (timeZones) => ({
  type: RETRIEVE_TIMEZONES,
  timeZones,
})

const updateSubjectTimeZone = (timezoneData) => ({
  type: UPDATE_TIMEZONE_SUCCESS,
  timezoneData,
})

const updateSubjectTimeZoneReq = () => ({
  type: UPDATE_TIMEZONE_REQUEST,
})

const updateSubjectTimeZoneFailure = (message) => ({
  type: UPDATE_TIMEZONE_FAILURE,
  message,
})

export const updateSubjectTimezone = (subject, subjectStudyMetaData,t) => async (dispatch) => {
  try {
    const subjectUrl = `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}`
    dispatch(updateSubjectTimeZoneReq())
    await api.put(subjectUrl, subject)
    moment.tz.setDefault(subject.timeZone)
    await dispatch(updateSubjectTimeZone({ 
      timeZone: subject.timeZone,
    }))
    dispatch(getMetaData(subjectStudyMetaData?.subject.id, subjectStudyMetaData?.subject.lastUpdatedDateOn ? moment(subjectStudyMetaData?.subject.lastUpdatedDateOn).utc(): null, false))
    dispatch(retrieveFormsIfNotExist())
    await storeData(subjectStudyMetaData)
    showToast(t('SavedMessage'), 'success', 1000)
  } catch (error) {
    console.error('ERROR: updateSubjectTimezone: ', error)
    console.log(error)
  }
}

