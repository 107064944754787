import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api'
import constants from '../constants/constants'

export const RETRIEVE_ALL_CRF_DATA_REQUEST = createActionType('RETRIEVE_ALL_CRF_DATA_REQUEST')
export const RETRIEVE_ALL_CRF_DATA_SUCCESS = createActionType('RETRIEVE_ALL_CRF_DATA_SUCCESS')
export const RETRIEVE_ALL_CRF_DATA_FAILURE = createActionType('RETRIEVE_ALL_CRF_DATA_FAILURE')
export const UPDATE_FIELD_ANSWER = createActionType('UPDATE_FIELD_ANSWER')

export const getAllCrfDataOfSubject = (subjectId) => async (dispatch) => {
  dispatch({ type: RETRIEVE_ALL_CRF_DATA_REQUEST })

  try {
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/crfData`)
    var subjectVisitForms = _.groupBy(res.data, 'subjectVisitForm.id')
    _.forEach(subjectVisitForms, (svfCrfDataList, index) => {
      var fieldList = {}
      _.forEach(svfCrfDataList, (crfData) => (fieldList[crfData.field.fieldOid] = crfData))
      subjectVisitForms[index] = fieldList
    })
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_SUCCESS, formAnswers: subjectVisitForms })
  } catch (error) {
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_FAILURE })
    console.error(error)
  }
}

export const updateFieldAnswer = (selectedField, svfId) => async (dispatch) => {
  dispatch({
    type: UPDATE_FIELD_ANSWER,
    selectedField: selectedField,
    svfId: svfId,
  })
}

export const getCrfDataBySvfId = (svfId, primaryOrgCode, studyId, siteId, subjectId,subjectVisitId) => async (dispatch) => {
  dispatch({ type: RETRIEVE_ALL_CRF_DATA_REQUEST })
  try {
    const res = await api.get(`/${primaryOrgCode}/studies/${studyId}/sites/${siteId}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms/${svfId}/crfData`)
    var crfDataByFieldKey = {}
    _.forEach(res.data, (crfData) => (crfDataByFieldKey[crfData.field.fieldOid] = crfData))
    const crfData = { [svfId]: crfDataByFieldKey }
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_SUCCESS, formAnswers: crfData })
  } catch (error) {
    console.log(error)
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_FAILURE })
  }
}
