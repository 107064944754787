import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import CustomTextInput from '../common/CustomTextInput'

// import { InputItem } from '@ant-design/react-native';

export class NumericInput extends Component {
  state = {}

  changeFieldValue = (value) => {
    const {
      form,
      field: { id },
    } = this.props
    const { setFieldsValue, getFieldsValue } = form
    setFieldsValue({
      [id]: value ? value.replace(/[^0-9]/g, '') : '',
    })
  }

  validateNumberRange = (rule, value, callBack) => {
    const {
      field: {
        dictionary: { range },
      },
      t,
    } = this.props
    if (Number(value) < Number(range.min) || Number(value) > Number(range.max)) {
      callBack(`${t('NumberValidate1')} ${range.min} ${t('NumberValidate2')} ${range.max}.`)
    }
    callBack()
  }

  render() {
    const { getFieldError } = this.props.form

    const {
      field: {
        dictionary: { range },
        id,
        isRequired,
        uiSchema: fdUISchema,
      },
      t,
      disabled,
      fieldAnswer,
    } = this.props
    const crfData = { ...fieldAnswer }
    return (
      <View
        style={{
          marginHorizontal: 10,
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {this.props.form.getFieldDecorator(`${id}`, {
          initialValue: crfData ? crfData.fieldValue : null,
          rules: [
            {
              required: isRequired,
              message: t('ReqNumericField'),
            },
            {
              validator: this.validateNumberRange,
            },
          ],
        })(
          <CustomTextInput
            keyboardType="numeric"
            placeholder={t('EnterHere')}
            onChangeText={this.changeFieldValue}
            editable={!disabled}
            // maxLgetFieldErrorength={10}
            style={{
              paddingVertical: 10,
              paddingHorizontal: 5,
              borderWidth: 2,
              borderColor: '#cccccc',
            }}
            accessible={!disabled}
            testID={`${id}-numeric-input`}
          />
        )}
        <Text style={{ paddingLeft: 15, paddingVertical: 7, color: '#9155FD' }}>
          {getFieldError(id)}
        </Text>
      </View>
    )
  }
}
