import { CHANGE_LANGUAGE_SUCCESS, REMOVE_SELECTED_LANGUAGE } from '../actions/changeLanguage'
import initialState from '../store/initialState'
import { PURGE } from 'redux-persist'

const changeLanguage = (state = initialState.language, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_SUCCESS:
      return { ...state, selectedLanguage: action.value }
    case REMOVE_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.value,
      }
    case PURGE:
      return {
        selectedLanguage: 'en_US',
      }
    default:
      return state
  }
}

export default changeLanguage
