import timezoneLocaleFrench from "./timezoneLocale-french";

export default {
  ...timezoneLocaleFrench,
  LoginTitle: 'Alpha Clinique systèmes',
  LoginACSUserID: 'Numéro de téléphone',
  LoginACSPwd: 'Mot de passe',
  LoginACSLogin: "S'identifier",
  LoginACSFrgtPwd: 'Oublié Mot de passe ?',
  LoginACSVersn: 'Version',
  HomeTitle: 'Accueil',
  HomePull: 'tirer à rafraîchirtirer',
  HomeGM: 'Bien Matin',
  HomeTxt:
    "Vos réponses sont très importantes pour la recherche de l'étude à laquelle vous participez.",
  HomeCompli: 'Conformité pour chaque période',
  HomeTotal: 'Total',
  HomeToday: "Aujourd''hui",
  HomeCrntWeek: 'Cette semaine',
  HomeCrntMon: 'Ce mois-ci',
  Homemsgs: 'messages',
  HomeNewmsgs: 'Vous avez de nouveaux messages',
  'Homemsgs sub txt': 'Pas de nouveaux messages',
  HomePatnt_Diary: 'Journal',
  HomePatnt_Diary_sub_txt: "S'il vous plaît remplir les formulaires.",
  Calls: 'Appels',
  Chat: 'Chatte',
  'HomeQlty of Life': 'Suivi de la santé',
  'HomeQlty of Life sub txt': 'Aucune activité récente',
  'HomeVst Remnders': 'Visitez les rappels',
  'HomeVst Remnders sub txt': 'Pas de nouveaux rappels',
  HomeTabHome: 'Accueil',
  HomeTabmsgs: 'messages',
  Form: 'Forme',
  HomeTabCalndr: 'Calendrier',
  MessagesTitle: 'messages',
  'Patient DiaryTitle': 'Journal du patient',
  'Visit CalendarTitle': 'Calendrier des visites',
  'Qlty of LfeChoose optn': 'Veuillez choisir votre option',
  'Qlty of LfeWithings': 'Withings',
  'Qlty of LfeFitbit': 'Fitbit',
  WithingsTitle: 'Activité',
  'WithingsActivity Tab': 'Activité',
  'WithingsHeart Rate Tab': 'Rythme cardiaque',
  'WithingsHeart Rte TabHeart Rate': 'Rythme cardiaque',
  'WithingsHeart Rte TabThis week': 'Cette semaine',
  'WithingsHeart Rte TabSubmit': 'Soumettre',
  FitbitTitle: 'Activité',
  'FitbitActivity Tab': 'Activité',
  'FitbitHeart Rate Tab': 'Rythme cardiaque',
  'FitbitHeart Rte TabHeart  Rate': 'Rythme cardiaque',
  'FitbitHeart Rte TabThis week': 'Cette semaine',
  'FitbitHeart Rte TabSubmit': 'Soumettre',
  Actn_sheetMore: 'Plus',
  Actn_sheetStudy: 'Étude',
  Actn_sheetContact: 'Contact',
  Actn_sheetFAQ: 'FAQ',
  Actn_sheetChange_Language: 'Choisir la langue',
  ChangeLanguage: 'Choisir la langue',
  StudyTab1Title: "Informations sur l'étude",
  StudyTab1Txt: 'Bienvenue dans notre étude balayez pour voir',
  'StudyTab2Brf Dctn': 'Brève description',
  'StudyTab3Detail Dctn': 'Description détaillée',
  'StudyTab4Anticipated prblms': 'Problèmes anticipés',
  'StudyTab5Withdrn rule': 'Règles de retrait',
  StudyTab6Video: 'Vidéo',
  'StudyTab6View Video': "Voir la vidéo sur l'étude",
  StudyTab6Joined: 'Déjà rejoint',
  ContactTitle: 'Informations de contact',
  ContactSiteAddress: 'Adresse du',
  siteContactPI: 'Chercheur principal',
  FAQTitle: 'FAQ',
  FieldPrevious: 'précédent',
  FieldNext: 'Prochain',
  PainscaleText1: "Nous aimerions savoir si votre santé est bonne ou mauvaise AUJOURD'HUI.",
  PainscaleText2:
    "S'il vous plaît appuyez sur l'échelle pour indiquer comment votre santé est AUJOURD'HUI.",
  PainscaleSubmit: 'Soumettre',
  PainscaleHealthToday: "VOTRE SANTÉ AUJOURD'HUI",
  PainscaleHealthToday1: 'VOTRE',
  PainscaleHealthToday2: 'SANTÉ',
  PainscaleHealthToday3: "AUJOURD'HUI",
  ClickableImageMessage: "S'il vous plaît appuyez sur l'image pour sélectionner la réponse.",
  tmlnallday: 'Toute la journée',
  painmax: 'Pire douleur possible',
  painmin: 'Pas de douleur',
  completedat: 'Terminé à ',
  EventsforDay: 'Événements pour la journée',
  NoEventsforDay: 'Aucun événement pour la date sélectionnée.',
  TODAY: "Aujourd'hui",
  MONTH: 'Mois',
  WEEK: 'La semaine',
  DAY: 'Jour',
  ChangeTheme: 'Change le thème',
  MessageSent: 'Envoyé sur',
  MessageRead: 'Continuer à lire',
  EventCalendar: 'Calendrier des événements',
  Logout: 'Connectez - Out',
  USRNAMEWARNING: 'Veuillez entrer le numéro de téléphone.',
  PWDWARNING: 'Veuillez entrer le mot de passe.',
  NoFormToFill: 'Aucun formulaire n’est disponible à la date sélectionnée.',
  PIN: 'PIN',
  EnterOTP: 'Entrez OTP',
  OtpEmpty: 'OTP ne doit pas être vide.',
  WrongOTP: 'Veuillez entrer un OTP valide.',
  VerifyOTP: 'Vérifier OTP',
  ResendOTP: 'Renvoyer OTP',
  NumberValidate1: 'Entrer une valeur entre',
  NumberValidate2: 'et',
  ResetPassword: 'Réinitialiser le mot de passe',
  ChangePassword: 'Changer le mot de passe',
  NewPassword: 'Nouveau mot de passe',
  ConfirmPassword: 'Confirmer le mot de passe',
  PSWD_NOT_MATCH: 'Les mots de passe ne correspondent pas.',
  PSWD_VALIDATION: 'Le mot de passe ne correspond pas aux critères.',
  PSWD_MIN_LENGTH: "- Le mot de passe doit être d'au moins 8 caractères.",
  PSWD_MAX_LENGTH: '- Le mot de passe ne doit pas dépasser 64 caractères.',
  PSWD_ONE_NUMBER: '- Le mot de passe doit inclure au moins un numéro.',
  PSWD_ONE_SC: '- Le mot de passe doit inclure au moins un caractère spécial.',
  PSWD_ONE_UPPER: '- Le mot de passe doit inclure au moins une lettre majuscule.',
  PSWD_NO_SPACES: "- Le mot de passe ne doit pas inclure d'espaces.",
  PSWD_RESTRICT: '- Le mot de passe ne doit pas contenir de caractères séquentiels et répétitifs',
  PSWD_NO_USERNAME: "- Le mot de passe ne doit pas inclure le nom de l'utilisateur.",
  PSWD_NO_EMAILNAME: '- Le mot de passe ne doit pas inclure le nom du compte de messagerie.',
  PSWD_NO_SITENAME: '- Le mot de passe ne doit pas inclure le nom du site.',
  PSWD_NO_ORGNAME: "- Le mot de passe ne doit pas inclure le nom de l'organisation.",
  PSWD_NOTE: 'Critères de mot de passe :',
  Save: 'Enregistrer',
  ForgotPSWD: 'Vous avez oublié votre mot de passe ?',
  OTPMSG:
    'Nous allons envoyer un SMS à votre numéro de téléphone avec Un Mot de passe pour réinitialiser votre mot de passe.',
  FP_PNO: 'Soumettre votre numéro de téléphone enregistré avec le code du pays.',
  PNO_Vald: 'Numéro de téléphone ne doit pas être vide.',
  Submit: 'Soumettre',
  Cancel: 'Annuler',
  Prev: 'Précédente',
  Next: 'Prochaine',
  NoPain: 'Pas De Douleur',
  Mild: 'Doux',
  Moderate: 'Modéré',
  Severe: 'Grave',
  Worst: 'Pire',
  SetAppLock: "Jeu De code d'accès",
  AppLock: "Verrou d'application",
  EnterPIN: 'Entrez le PIN',
  MinPin: 'Votre code PIN doit comporter entre 4 et 6 chiffres.',
  WrongPIN: 'Mauvais code PIN.',
  PINS_NOT_MATCH: 'Les codes PIN ne correspondent pas.',
  ConfirmPIN: 'Confirmer le code PIN',
  OK: "D'accord",
  Reset: 'Réinitialiser',
  Confirm: 'Confirmer',
  SelTimezone: 'Sélectionner un fuseau horaire',
  CompletedOn: 'Terminé sur',
  BScaleValMsg: 'Veuillez évaluer votre douleur.',
  BarcodeValMsg: 'Veuillez scanner un code-barres pour continuer.',
  DateValMsg: 'Veuillez sélectionner une date.',
  SingSelValMsg: 'Veuillez sélectionner une option.',
  MultSelValMsg: 'Merci de sélectionner au moins une option.',
  TextValMsg: 'Veuillez remplir ce champ.',
  PswdEmpty: 'Le mot de passe ne doit pas être vide.',
  CnfPswdEmpty: 'Veuillez confirmer votre mot de passe.',
  SelectTimezone: 'Sélectionner un fuseau horaire',
  Timezone: 'Fuseau horaire',
  LoginPhPW: 'Connectez-vous avec votre numéro de téléphone et votre mot de passe.',
  PhoneNotReg:
    "Votre numéro de téléphone n'est pas enregistré. Veuillez vérifier le numéro et réessayer.",
  SomethingWrong: "Quelque chose s'est mal passé. Veuillez contacter votre enquêteur.",
  PswdResetSuccess: 'Le mot de passe a été réinitialisé avec succès.',
  PswdResetFailure: 'Échec de la réinitialisation du mot de passe.',
  SavedMessage: 'Enregistré avec succès.',
  FailedMessage: "Échec de l'enregistrement. Veuillez réessayer après un certain temps.",
  FailedRetrieve: 'Echec de la récupération.',
  FailedResetPWD: 'Échec de la réinitialisation du mot de passe.',
  FailedRetrieveMsgs: 'Impossible de récupérer les messages.',
  FailedRetrieveFaqs: 'Échec de la récupération des FAQ.',
  FailedRetrieveFields: 'Impossible de récupérer les champs.',
  FailedRetrieveForms: 'Impossible de récupérer les formulaires.',
  FailedRetrieveTimezones: 'Échec de la récupération des fuseaux horaires.',
  FailedRetrieveSubjects: 'Impossible de récupérer les sujets.',
  FailedChangeLanguage: 'Échec du changement de langue.',
  FailedUpdateTimeZone: 'Échec de la mise à jour du fuseau horaire.',
  NetworkError: 'Erreur réseau.',
  LogoutMessage: "Vous vous déconnectez d'ezpro. Voulez-vous continuer?",
  YES: 'Oui',
  NO: 'Non',
  InvPhonePSWD: 'Numéro de téléphone ou mot de passe invalide.',
  NotValidPhone: "Vous n'avez pas entré de numéro de téléphone valide.",
  ReqNumericField: 'Veuillez remplir ce champ numérique.',
  OTPResend: 'OTP Renvoyé.',
  SelectDate: 'Sélectionner une date',
  EnterHere: ' Entrez ici',
  NOMSG: 'Désolé, aucun message à afficher.',
  NoData: 'Pas de données.',
  UserLocked: 'Utilisateur verrouillé. Veuillez contacter votre administrateur.',
  LanguageValidate: 'Veuillez sélectionner une langue',
  OfflineMessage: 'Connexion réseau perdue. Passer en mode hors ligne.',
  Offline: 'Hors ligne',
  Online: 'En ligne',
  savedLocally: 'Enregistré localement.',
  formsToSync: 'Formulaire(s) à synchroniser.',
  formToSync: 'Formulaire à synchroniser.',
  showingOfflineForms:
    "Affichage des données hors ligne. S'il vous plait, vérifiez votre connexion internet.",
  NoForm: 'Aucun formulaire à remplir.',
  NoFaqs: 'Aucune FAQ disponible.',
  HealthMenu: 'Données de santé',
  myProfile: 'Mon profil',
  subjectInformation: 'Informations sur le sujet',
  studyInformation: 'Informations sur l étude',
  versionInformation: 'Information sur la version',
  LoggedUser: 'Utilisateur connecté',
  StudyDetails: 'Étude',
  VersionDetails: 'Informations sur la version',
  LoggedInUser: 'Utilisateur connecté',
  Study: 'Étude',
  VersionDetails: 'Détails de la version',
  Syncing: 'Synchronisation . . .',
  SyncSuccess: 'Synchronisé avec succès.',
  MaxAttemptsDone: 'Max tentatives effectuées.',
  LastSynced: 'Dernière synchronisation',
  bmi: 'Indice de masse corporelle',
  Height: 'la taille',
  Weight: 'Poids',
  LeanBodyMass: 'Masse corporelle mince',
  BodyFat: 'Pourcentage de graisse corporelle',
  Activity: 'Activité',
  Vitals: 'Vitals',
  Biometrics: 'Biométrie',
  HeartRate: 'Rythme cardiaque',
  BloodPressure: 'Pression artérielle',
  RespirationRate: 'Taux de respiration',
  BodyTemperature: 'Température corporelle',
  BloodGlucose: 'Glucose sanguin',
  Sex: 'Sexe',
  SleepAnalysis: 'Analyse du sommeil',
  ActiveEnergy: 'Énergie active',
  FlightsClimbed: 'Vols montés',
  Distance: 'Distance',
  Steps: 'Pas',
  StepUnits: 'pas',
  DistanceUnits: 'miles',
  FloorUnits: 'planchers',
  ActiveEnergyUnits: 'calories',
  SleepAnalysisUnits: 'heures',
  MeetingSchedule: 'Calendrier des réunions',
  UpcomingCalls: 'Appels à venir',
  PastCalls: 'Appels passés',
  NoCallScheduled: "Il n'y a aucun appel à venir pour le moment",
  NoPastCalls: "Il n'y a pas d'appels passés",
  NoInternet: 'Pas de connexion Internet.',
  NoChats: 'Aucun chat à afficher.',
  SelectChat: 'Veuillez sélectionner un chat ou démarrer un nouveau chat.',
  NoUsersFound: 'Aucun utilisateur trouvé',
  Done: 'Terminé',
  AddParticipants: 'Ajouter des participants',
  SearchParticipants: 'Rechercher des participants',
  LeaveGroup: 'Quitter le groupe',
  Participants: 'Les participants',
  participants: 'Les participants',
  Remove: 'Retirer',
  Others: 'autres',
  Add: 'Ajouter',
  Notifications: 'Notifications',
  CallDetails: "Détails de l'appel",
  Participants: 'Les participants',
  CallHistory: 'Historique des appels',
  JoinCall: "Rejoindre l'appel",
  MissedCall: 'Vous avez manqué cet appel.',
  CallInProgress: "L'appel est en cours.",
  CallNotStarted: "L'appel n'a pas encore commencé.",
  JoinedOn: 'Rejoint sur',
  LeftOn: 'À gauche sur',
  Duration: 'Durée',
  ScheduledDuration: 'Durée programmée',
  Title: 'Titre',
  StartedOn: 'Commencé le',
  Status: 'Statut',
  ScheduledTime: 'Heure prévue',
  Alerts: 'Alertes',
  Reminders: 'Rappels',
  Notifications: 'Notifications',
  VersionInfo: 'Informations sur la version',
  FormPreview: 'Aperçu du formulaire',
  NRSNumber: 'Veuillez sélectionner le numéro.',
  SubmitTraining: 'Soumettre le formulaire de formation',
  TrainingNotCompleted: 'Veuillez terminer la formation avant de commencer votre journal.',
  StartTraining: "Commence l'entraînement",
  ViewCompletedTraining: 'Afficher la formation terminée',
  CompleteTraining: 'Veuillez suivre la formation pour accéder aux formulaires de journal.',
  Training: 'Entraînement',
  SITESTAFFUSRNAMEWARNING: "Veuillez entrer le nom d'utilisateur",
  SITESTAFFPINWARNING: 'Veuillez entrer le NIP',
  LoginSiteStaffUsrName: "Nom d'utilisateur",
  LoginSiteStaffPin: 'ÉPINGLER',
  InvUsrNmPIN: "Nom d'utilisateur ou code PIN invalide",
  InvPasscode: 'Entrez un identifiant de site valide.',
  EnterPasscode: 'Entrez l’identifiant de site TRYAL',
  EnterUsername: 'Saisissez votre nom d utilisateur',
  EnterPassword: 'Entrer le mot de passe',
  EnterValidUname: 'Entrez un nom d utilisateur valide',
  EnterValidPassword: 'Entrez un mot de passe valide',
  FirstName: 'Prénom',
  LastName: 'Nom de famille',
  UserID: 'ID de l`utilisateur',
  Site: 'Site',
  MyAccount: 'Mon compte',
  StudyList: 'Liste d`étude',
  SubjectList: 'Liste des sujets',
  SelectFormCategory: 'Sélectionnez la catégorie de formulaire',
  LoginWithPIN: 'Connectez-vous avec le nom d`utilisateur et le code PIN',
  VersionInfo: 'Informations sur la version',
  Email: 'E-mail',
  SessionTimeoutMessage: 'votre session a expiré',
  FillRequiredFields: 'Veuillez remplir tous les champs obligatoires',
  InvalidPasscode: 'Identifiant de site invalide.',
  SomethingWentWrong: 'Quelque chose s est mal passé.',
  InvalidUsernamePassword: 'Nom d`utilisateur ou mot de passe invalide.',
  InvalidUsernamePasswordWeb:
    '<p style="font-weight:normal"><b>Nom d`utilisateur ou mot de passe invalide.</b><br/>Si vous êtes un participant ou un soignant, cliquez sur le lien ci-dessous pour accéder à la page de connexion des participants.</p>',
  ScheduleStartDate: 'Date de début de la planification',
  ScheduleEndDate: 'Date de fin de l’horaire',
  VisitNotStarted: 'La fenêtre de visite n’a pas encore commencé.',
  VisitWindowClosed: 'La fenêtre de visite est passée, vous ne pouvez pas saisir de données.',
  VisitCompleted: 'La visite est terminée. Vous n’êtes pas censé mettre à jour les questionnaires.',
  ShowAlertMsg: 'Vous souhaitez enregistrer vos modifications ?',
  firstName: 'prénom',
  lastName: 'nom de famille',
  email: 'e-mail',
  phone: 'téléphoner',
  language: 'Langue',
  study: 'étude',
  sponsor: 'parrainer',
  versionName: 'nom de la version',
  Visit: 'Visite',
  StartTime: 'Heure de début',
  EndTime: 'Heure de fin',
  VersionUpgradeInfo: 'La version eDiary a été mise à niveau vers la version ',
  New: 'Nouveau',
  NoDiary:
    'Aucun journal/questionnaire n`est disponible. Veuillez contacter le personnel de votre site',
  UserInactive: 'Votre accès est inactivé. Veuillez communiquer avec votre coordonnateur d’étude.',
  SubjectInactive:
    'L’accès au sujet est désactivé. Veuillez contacter votre coordinateur d’études.',
  ProcessMessage: 'Traitement du questionnaire.',
  LoadingCallData: "Chargement des données d'appel",
  DownloadMetadata: 'Téléchargement des métadonnées',
  DownloadStudydata: "Téléchargement des données d'étude.",
  DownloadQuestionnaries: 'Téléchargement des questionnaires',
  FailedRetrieveStudy: "Échec du téléchargement des détails de l'étude.",
  SubjectCaregiverInactive:
    'Le sujet n’est pas actif. Veuillez contacter votre coordinateur d’études pour plus d’informations.',
  OnceFrequencyScheduleStartDate: "Date de début de l'horaire",
  OnceFrequencyScheduleEndDate: 'Date de fin du programme',
  Click: 'Cliquer',
  LoginText: 'pour vous reconnecter',
  TermsAndConditions: 'Politique de confidentialité et conditions générales',
  TermsNConditionsCancel:
    'Vous devez accepter la politique de confidentialité et les termes et conditions pour continuer à utiliser cette application',
  UnsyncedData:
    "Vous avez des données non synchronisées sur cet appareil. Vous ne pouvez pas vous déconnecter tant que vous n'avez pas synchronisé vos données. Veuillez contacter votre site si vous avez des questions.",
  ChangeToClinician: 'Passer à la connexion du clinicien',
  ParticipantLogin: 'Connexion des participants',
  ClinicianLogin: 'Connexion clinicien',
  SwitchToParticipant: 'Passer au participant',
  OrgText1: 'Si vous souhaitez vous connecter à une autre organisation,',
  OrgText2: 'Veuillez accéder au lien dans le portail d’administration',
  BackToLogin: 'Retour connexion',
  GoToPortal:
    'Certaines actions sont requises sur votre compte. Veuillez vous connecter via le portail d’administration pour terminer les actions de votre compte.',
  Organization: 'Organisation',
  NoClinroAccessPrivilege:
    "Vous n'avez pas accès à la saisie des données ClinRo. Veuillez contacter votre ARC ou votre administrateur si vous avez des questions.",
  DeviceTimezoneMismatchTitle:
    "Il semble que le fuseau horaire de votre appareil ne correspond pas à votre fuseau horaire configuré dans cette application.",
  DeviceTimezoneMismatchSubtitle: 'Souhaitez-vous changer votre fuseau horaire?',
  DeviceTimezoneMismatchTzConfigured: 'Votre fuseau horaire configuré:',
  DeviceTimezoneMismatchTzInferred: 'Le fuseau horaire de votre appareil:',
  SiteIdentifierInfo: '<p>Trouvez votre TRYAL <b>Identifiant du site </b> dans le portail sur la page Liste des sujets</p>',
  InvPhonePSWDWeb: '<p style="font-weight:normal"><b>Numéro de téléphone ou mot de passe invalide.</b><br/>Si vous êtes un clinicien, cliquez sur le lien ci-dessous pour accéder à la page de connexion du clinicien.</p>',
  LoginUnsyncData: 'Certaines données doivent être synchronisées sur votre appareil. Veuillez saisir votre mot de passe afin que les données puissent être transférées. Veuillez contacter le support si vous avez des questions.',
  DeviceTimezoneNoClicked: 'Vous pouvez modifier votre fuseau horaire à tout moment en allant dans « Plus » en bas de cet écran.',
  AgreeingToPrivacypolicy: "L'acceptation de la politique de confidentialité et des conditions générales est obligatoire.",
  ReadAndAgree: "J'ai lu et j'accepte les termes et conditions",
  IAgree: "Je suis d'accord",
  SubjectNumber: 'Numéro de sujet',
  Version: 'Version',
  Back: 'Dos',
  Visits: 'Visites',
  DownloadImageFailedDeviceOffline: 'Ce journal contient des images incomplètement téléchargées sur votre appareil, veuillez vous connecter à Internet et sélectionner à nouveau ce journal.',
  DownloadImageFailedNoEnoughSpace: "Il n'y a pas assez d'espace sur votre appareil pour télécharger un ou plusieurs agendas. Veuillez libérer de l'espace et réessayer.",
  ImageLoading: "Chargement de l'image...",
  Barcode:'code à barre',
  BarcodeMessage:"La lecture de codes-barres n'est actuellement pas prise en charge sur le Web. Veuillez utiliser un appareil mobile pour scanner les codes-barres",
  ScanAgain:'Numériser à nouveau',
  SelectedValue:'Valeur sélectionnée'
}
